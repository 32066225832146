const featuresFlagsEnum = {
  questionForm: 'time-movimentacao.additional-questions',
  additionalQuestions: 'additional_questions',
  addMarkerOnEngageSurveySetup: 'time-triagem.add-marker-on-engage-survey-setup',
  addressZipCode: 'address_zip_code',
  admission: 'admission',
  admissionContracts: 'admission_bulk_contract',
  admissionPremiumClient: 'admission_premium_client',
  admissionFieldMapping: 'admission_field_mapping',
  admissionOnly: 'admission_only',
  admissionManageAcceptanceTerms: 'manage-acceptance-terms',
  advancedTests: 'AdvancedTests',
  applicationsHiringObservations: 'applications_hiring_observations',
  applicationsReport: 'applications_report',
  approvalWorkflows: 'approval-workflows',
  authenticationRebranding: 'time-autenticacao.companies-legacy-authentication-rebranding',
  batchEmployeeManagement: 'batch-employee-management',
  businessWhatsApp: 'business-whatsapp',
  candidateArea: 'candidate_area',
  candidateProfileTestFilter: 'candidate-profile-test-filter',
  careerPageColorsTheme: 'time-atratividade.career-page-colors-theme',
  careerPageSections: 'time-atratividade.career-page-sections',
  coin: 'coin',
  community: 'community',
  companyBranch: 'company-branch',
  companyProfileTestGroupFilter: 'company_profile_test_group_filter',
  companyWithUnlimitedAdditionalQuestions: 'time-curriculo.unlimited-additional-questions',
  confidentialJobs: 'confidential-jobs',
  createJobStepListSummary: 'time-qualificacao.create-job-step-list-summary',
  culturalFitMapping: 'time-qualificacao.cultural-fit-mapping',
  culturalFitMappingReports: 'time-qualificacao.cultural-fit-mapping-reports',
  customTestRandomQuestions: 'time-qualificacao.custom-test-random-questions',
  deletedAccountsReport: 'deleted_accounts_report',
  descriptionErrorsJobInsights: 'description-errors-job-insights',
  disableAdvancedSearch: 'disable_advanced_search',
  disableCulturalFitReportFilters: 'disable-cultural-fit-report-filters',
  disableHiredReportJobFilter: 'disable_hired_report_job_filter',
  disableJobBoardsPublication: 'time-captacao.disable-job-boards-publication',
  disableSetupOrganizationalStructure: 'disable_setup_organizational_structure',
  diversity: 'diversity',
  diversityCompanies: 'diversity-companies',
  diversityDashboard: 'diversity-dashboard',
  diversitySelectionProcess: 'diversity-selection-process',
  emailTemplateTabs: 'time-comunicacao.email-template-tabs-enabled',
  engageSurvey: 'time-contratacao.engage-surveys',
  experimentApplicationTips: 'experiment_application_tips',
  experimentLinkedinPdf: 'experiment_linkedin_pdf',
  experimentPositionsClosingTimeAvg: 'experiment-positions-closing-time-avg',
  flexibleRegistrationForm: 'flexible_registration_form',
  generatePdfOfferLetter: 'time-triagem.generate-pdf-offer-letter',
  gaiaWriteJob: 'time-vagas.gaia-write-job',
  guidedOnboardingChameleon: 'time-vagas.guided-onboarding-chameleon',
  gupyIACandidatesOrdering: 'gupyia-candidates-ordering',
  hideApplicationHistory: 'hide-application-history',
  hideNavbarUpgradeButton: 'hide_navbar_upgrade_button',
  homeJobsCloseToExpire: 'time-analytics.home-jobs-close-to-expire',
  homeJobsWithQualifiedApplications: 'time-analytics.home-jobs-with-qualified-applications',
  indicatorsHomeInsights: 'indicators_home_insights',
  insights: 'insights',
  internalJobStep: 'time-movimentacao.internal-screening-job-step',
  internalRecruitment: 'internal-recruitment',
  interviewVideoConference: 'interview_video_conference',
  jobApplicationNewPagination: 'job-application-new-pagination',
  jobOffer: 'time-contratacao.job-offer',
  legacyApplicationsPage: 'legacy_applications_page',
  legacyExperiencePage: 'legacy_experience_page',
  legacyHiringsPage: 'legacy_hirings_page',
  legacyJobsPage: 'legacy_jobs_page',
  legacyProfileTest: 'legacy_profile_test',
  legacySignOn: 'time-autenticacao.legacy-sign-on',
  legacyTimeAndConversionPage: 'legacy_time_and_conversion_page',
  loadEmailTemplateWithoutBody: 'load_email_templates_without_body',
  oldAddressXp: 'old-address-xp',
  newAnalyticsDashboard: 'new_analytics_dashboard',
  newAnalyticsExperience: 'new_analytics_experience',
  newFrontendReports: 'new_frontend_reports',
  newHandicappedForm: 'new-handicapped-form',
  newMarkersOnWhatsAppTemplate: 'time-autenticacao.new-markers-on-whatsapp-template',
  noDisabilitySpecification: 'time-curriculo.no-disability-specification',
  oldPrimeiraEscolha: 'old_primeira_escolha', // TODO: Delete after ambev is migrated
  onboardingGaiaApplications: 'time-contratacao.onboarding_gaia_applications',
  preHireStep: 'pre-hire-step',
  preLaunchAdmission: 'pre_launch_admission',
  productAlert: 'product-alert',
  productAlertMoreInfo: 'product-alert-more-info',
  productAlertOwner: 'product-alert-owner',
  profileTestAsApplicationStep: 'time-qualificacao.profile-test-as-application-step',
  rebranding: 'rebranding',
  recaptchaV3: 'recaptcha_v3',
  recommendJobs: 'recommend_jobs',
  redesignSelecao: 'redesign-selecao',
  registrationDeadlineExperiment: 'time-qualificacao.registration-deadline-experiment',
  repackaging: 'repackaging',
  reports: 'reports',
  republishJob: 'time-vagas.republish-job',
  roleBulkUpload: 'role_bulk_upload',
  rscLinkedinIntegration: 'time-captacao.rsc',
  samlLogin: 'saml_login',
  isSamlEnabled: 'time-autenticacao.enable-saml',
  selectCareerPagesLanguage: 'select_career_pages_language',
  setupOrganizationalStructure: 'setup_organizational_structure',
  showApplicationHighlights: 'show_application_highlights',
  showBannerFreezing: 'banner_congelando',
  showBannerHiring: 'banner_contratando',
  showBannerReengaging: 'banner_reengajando',
  showCompanyProfileTestCompatibility: 'show_company_profile_test_compatibility',
  showCrmBanner: 'time-inbound.show-crm-banner',
  showCrmBannerSmb: 'time-inbound.show-crm-banner-smb',
  showDecouplingProfileTestMessage: 'time-qualificacao.show-decoupling-profile-test-message',
  showDisabledProfileTestDeadlineWarning: 'time-qualificacao.show-disable-profile-test-deadline-warning',
  showDisabledPortfolioTestDeadlineWarning: 'time-qualificacao.show-disable-portfolio-test-deadline-warning',
  showDisabledFitCultural: 'time-qualificacao.show-disabled-fit-cultural',
  showDistanceRatioTalentRecommendation: 'talent-inbound.distance-ratio-talent-recommendation',
  showGupyAdmissionBanner: 'time-contratacao.show-gupy-admission-banner',
  showGupyEdCorpBanner: 'time-contratacao.show-gupy-ed-corp-banner',
  showGupyNewEduCorpBanner: 'time-contratacao.show-gupy-new-edu-corp-banner',
  showGupySearch: 'time-contratacao.show-gupy-search-banner',
  showGupyFridayBanner: 'time-inbound.show-gupy-friday-banner',
  showGupyServicesBanner: 'time-inbound.show-gupy-services-banner',
  showGupySolutionsBanner: 'time-inbound.show-gupy-solutions-banner',
  showHandtalkPluginForCandidates: 'show_handtalk_plugin_for_candidates',
  showInboundTalentRecommendation: 'time-inbound.show-talent-recommendation',
  showReprocessRecommendation: 'time-inbound.reprocess-recommendation',
  showReprocessRecommendationCards: 'time-inbound.reprocess-recommendation-cards',
  showJobHiringTipModal: 'show-job-hiring-tip-modal',
  showLoginWithEmailButton: 'time-autenticacao.enable-companies-login-with-email-and-password',
  showLoginWithSecondProviderButton: 'time-autenticacao.enable-companies-login-with-second-provider',
  showMainHeaderOnCommunity: 'show_main_header_on_community',
  showRankPosition: 'show-rank-position',
  showRelativePosition: 'time-contratacao.show-relative-position',
  affinitySorting: 'time-contratacao.affinity-sorting',
  showRecommendation: 'time-inbound.show-recommendation',
  showSchedulingIntegration: 'show-scheduling-integration',
  showTestsHistory: 'time-contratacao.show_tests_history',
  showTogetherCampaign: 'show_together_campaign',
  singleSignOn: 'time-autenticacao.single-sign-on',
  skillTests: 'skill-tests',
  skillsTaxonomy: 'time-movimentacao.skills-taxonomy',
  talentAcquisitionModule: 'time-inbound.talent-acquisition-module',
  talentInboundConnection: 'time-inbound.talent-inbound-connection',
  taskManagerAssignApplicants: 'time-movimentacao.atribuicao-em-massa',
  taxpayerRegistryRequirementOption: 'taxpayer_registry_requirement_option',
  testProviders: 'test-providers',
  testProviderFilter: 'test_provider_filter',
  userApplicationReview: 'user_application_review',
  userIdentifier: 'user_identifier',
  vacancyCode: 'vacancy-code',
  validateVacancyCode: 'validate_vacancy_code',
  whatsappTemplateMessageType: 'time-movimentacao.whatsapp-template-message-type',
  weekHomeJobsInsights: 'week-home-jobs-insights',
  widgetZendeskCompanies: 'widget_zendesk_companies',
  workflow: 'time-vagas.workflow',
  workflowSimplified: 'time-vagas.workflow-simplificado',
  showMoveByScore: 'time-movimentacao.show-move-by-score',
  interviewHereIntegration: 'time-movimentacao.interview-here-integration',
  skillsEvaluation: 'time-contratacao.skills-evaluation',
  disableProfileTest: 'time-qualificacao.disable-profile-test',
  talentManagementBanner: 'time-contratacao.show-talent-management-banner',
  topOfMindBanner: 'time-contratacao.show-top-of-mind-banner',
  newHome: 'time-contratacao.new-home',
  timelineSignedUploadToS3: 'time-contratacao.timeline-signed-upload-to-s3',
  forceShowContactInfo: 'time-contratacao.force-show-contact-info',
  forceFetchProfileTest: 'time-qualificacao.force-fetch-profile-test',
  manageTalentPortalCompanies: 'time-inbound.manage-talent-portal-companies',
  annotationOnEvaluate: 'time-triagem.annotation-on-evaluate',
  skillsForCompany: 'time-curriculo.skills-for-company',
  engageSurveyLinkOnFeedbackModal: 'time-triagem.engage-survey-link-on-feedback-modal',
  engageSurveyCheckboxOnFeedbackModal: 'time-triagem.engage-survey-checkbox-on-feedback-modal',
  showTalentPortalJobConfig: 'time-inbound.show-talent-portal-job-config',
  verifyImproperMove: 'time-triagem.verify-improper-move',
  navEcosystem: 'gupy-navegacao-ecossistema',
  hideInactiveTalentRecommendationCards: 'time-inbound.hide-inactive-talent-recommendation-cards',
  hideSendToAdmissionButton: 'time-triagem.hide-send-to-admission-button',
  useSendToAdmissionAlternativeLink: 'time-triagem.send-to-admission-button-alternative-link',
  newOrderingDropdown: 'time-triagem.new-applications-ordering-dropdown',
  enableOrderingByName: 'time-triagem.enable-ordering-applications-by-name',
  customTests: 'custom-tests',
  copilot: 'time-visionarios.copilot',
  feedbackCopilot: 'time-visionarios.feedback-copilot',
  videoTest: 'video-test',
  disableTestingCompaniesFeatures: 'disable-testing-companies-features',
  jobCustomFields: 'job-custom-fields',
  situationalTest: 'situational-test',
  jobManagementInsights: 'job-management-insights',
  linkedinRscDeleteChildApp: 'time-captacao.linkedin-rsc-delete-child-app',
  genAiInterviewScript: 'genAI-interview-script',
  homeSatisfactionSurveyModal: 'show-home-satisfaction-survey-modal',
  disableRequiredDistanceField: 'disable-required-distance-field',
  gupyIAOrdering: 'gupyia-candidates-ordering',
  formationLevelFilterRecommendationConfigJobSetup: 'formation-level-filter-recommendation-config-job-setup',
  publicApiAccessPolicies: 'public-api-access-policies',
  communicationConfigJobRecommendationSetup: 'communication-config-job-recommendation-setup',
  planInformation: 'plan-information',
  showAddToListInJob: 'show-add-to-list-in-job',
  enableVideoTranscoder: 'enable-video-transcoder',
  jobBoardsMultiPublishing: 'job-boards-rollout',
  newDialogFeedbackBadge: 'new_dialog_feedback_badge',
};

export default featuresFlagsEnum;
