import moment from 'moment';

const add = ({ days = 0 } = {}) => {
  const now = new Date();
  const expiresAt = new Date(now.getFullYear(), now.getMonth(), now.getDate() + days);
  expiresAt.setHours(now.getHours(), now.getMinutes(), now.getSeconds());
  return expiresAt;
};

export const CommentStorage = ({ storage }) => ({
  getKeyName: applicationId => `timeline-comment-${applicationId}`,

  async create(comment) {
    const storedComment = await this.findByApplicationId(comment.applicationId);
    const record = {
      expiresAt: add({ days: 1 }).toISOString(),
      ...storedComment,
      ...comment,
    };
    return storage.setItem(this.getKeyName(comment.applicationId), record);
  },

  findByApplicationId(applicationId) {
    return storage.getItem(this.getKeyName(applicationId));
  },

  async findAllExpiredKeys() {
    const now = new Date().toISOString();
    const keys = await storage.keys();
    const promises = keys.map(key => storage.getItem(key));
    const comments = await Promise.all(promises);
    return comments
      .filter(comment => moment(comment.expiresAt).isBefore(now))
      .map(comment => this.getKeyName(comment.applicationId));
  },

  deleteByKey(key) {
    return storage.removeItem(key);
  },

  deleteByApplicationId(applicationId) {
    const key = this.getKeyName(applicationId);
    return this.deleteByKey(key);
  },

  async deleteAllExpiredRecords() {
    const keys = await this.findAllExpiredKeys();
    const promises = keys.map(key => this.deleteByKey(key));
    await Promise.all(promises);
  },

  toDTO(comment) {
    const commentKey = `commentary-${comment.candidateId}`;
    const commentValue = comment[commentKey];
    return {
      ...(commentValue ? {
        commentary: commentValue,
        [commentKey]: commentValue,
      } : {}),
      ...(comment.id ? { id: comment.id } : {}),
      ...(comment.isPrivate ? { isPrivate: comment.isPrivate } : {}),
      ...(comment.files ? { files: comment.files } : {}),
      ...(comment.fileName ? { fileName: comment.fileName } : {}),
    };
  },
});
