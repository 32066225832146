import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, intlShape } from 'react-intl';

import { Button } from '@gupy/design-system';

import {
  SetupGridLayout,
} from '../../CustomTestComponents';
import CustomTestForm from '../../forms/CustomTestForm';
import { TestsPath } from '../../CustomTestRoutes';

import {
  readCompanyCustomTest,
  updateCompanyCustomTest,
  onCustomTestUpdate,
} from '../../redux/CustomTest/CustomTestActions';

const messages = defineMessages({
  updateTestSuccess: {
    id: 'update_test_success_message',
    defaultMessage: 'Teste atualizado com sucesso.',
  },
  updateTestError: {
    id: 'update_test_error_message',
    defaultMessage: 'Erro ao atualizar teste.',
  },
  editTestTitle: {
    id: 'edit_test_title',
    defaultMessage: 'Editar teste',
  },
  editTestCancel: {
    id: 'edit_test_cancel',
    defaultMessage: 'Cancelar',
  },
  editTestSave: {
    id: 'edit_test_save',
    defaultMessage: 'Salvar',
  },
  viewTestDetailsBack: {
    id: 'view_test_details_back',
    defaultMessage: 'Voltar',
  },
  viewTestDetailsGoToQuestions: {
    id: 'view_test_details_go_to_questions',
    defaultMessage: 'Ver questões',
  },
});

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  CustomTest: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  requestReadCompanyCustomTest: PropTypes.func.isRequired,
  requestOnCustomTestUpdate: PropTypes.func.isRequired,
  requestUpdateCompanyCustomTest: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const TestEdit = ({
  Authentication,
  CustomTest,
  match,
  requestReadCompanyCustomTest,
  requestOnCustomTestUpdate,
  requestUpdateCompanyCustomTest,
  intl,
}) => {
  const isViewOnlyMode = CustomTest.data.inUse;

  useEffect(() => {
    requestReadCompanyCustomTest(match.params.id);
  }, []);

  const handleFieldUpdate = (event) => {
    const { name, value } = event.target;
    requestOnCustomTestUpdate({ [name]: value });
  };

  const handleSaveTest = () => {
    const { data } = CustomTest;

    requestUpdateCompanyCustomTest(
      data,
      {
        success: intl.formatMessage(messages.updateTestSuccess),
        error: intl.formatMessage(messages.updateTestError),
      },
    );
  };

  const renderEditModeButtons = () => (
    <div className="toolbar__right">
      <Link to={'/companies/setup/tests?expanded=custom-tests'}>
        <Button
          id="cancel"
          type="text"
        >
          {intl.formatMessage(messages.editTestCancel)}
        </Button>
      </Link>
      <Button
        id="continue"
        onClick={handleSaveTest}
        disabled={isViewOnlyMode}
      >
        {intl.formatMessage(messages.editTestSave)}
      </Button>
    </div>
  );

  const renderViewOnlyModeButtons = () => (
    <div className="toolbar__right">
      <Link to={'/companies/setup/tests?expanded=custom-tests'}>
        <Button
          id="cancel"
          type="text"
        >
          {intl.formatMessage(messages.viewTestDetailsBack)}
        </Button>
      </Link>
      <Link to={`${TestsPath}/${CustomTest.data.id}/questions`}>
        <Button
          id="questions"
        >
          {intl.formatMessage(messages.viewTestDetailsGoToQuestions)}
        </Button>
      </Link>
    </div>
  );

  const renderButtons = () => {
    if (isViewOnlyMode) {
      return renderViewOnlyModeButtons();
    }

    return renderEditModeButtons();
  };

  return (
    <SetupGridLayout
      Authentication={Authentication}
      title={intl.formatMessage(messages.editTestTitle)}
    >
      <section>
        <CustomTestForm
          isSaving={false}
          isRandomQuestions={CustomTest.data.isRandomQuestions}
          model={CustomTest.data}
          onFieldChange={handleFieldUpdate}
          validation={CustomTest.validation}
          isViewOnlyMode={isViewOnlyMode}
        />
        <div className="toolbar toolbar--form">
          {renderButtons()}
        </div>
      </section>
    </SetupGridLayout>
  );
};

TestEdit.propTypes = propTypes;

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
  CustomTest: state.reducers.CustomTest,
});

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    requestReadCompanyCustomTest: readCompanyCustomTest,
    requestOnCustomTestUpdate: onCustomTestUpdate,
    requestUpdateCompanyCustomTest: updateCompanyCustomTest,
  },
)(TestEdit)));
