import { SetPasswordActionTypes, SigninActionTypes, ForgotPasswordActionTypes, AuthenticationActionTypes } from './ActionTypes';
import adaptUserPermissions from '../../helpers/AuthenticationHelper';

export const validateToken = (token, userId) => ({
  type: SetPasswordActionTypes.VALIDATE_TOKEN,
  token,
  userId,
});

export const postSetPassword = (modelForm, messages) => ({
  type: SetPasswordActionTypes.POST,
  modelForm,
  messages,
});

export const postSetPasswordSuccess = currentUser => ({
  type: SetPasswordActionTypes.POST_SUCCESS,
  currentUser: { ...currentUser, permissions: adaptUserPermissions(currentUser.permissions) },
});

export const initCompanyLanguage = subdomain => ({
  type: SetPasswordActionTypes.INIT_COMPANY_LANGUAGE,
  subdomain,
});

export const postSignin = (payload, messages) => ({
  type: SigninActionTypes.POST,
  payload,
  messages,
});

export const postSigninSuccess = currentUser => ({
  type: SigninActionTypes.POST_SUCCESS,
  currentUser: { ...currentUser, permissions: adaptUserPermissions(currentUser.permissions) },
});

export const signinWithCookie = () => ({
  type: SigninActionTypes.SIGNIN_WITH_COOKIE,
});

export const signinWithCookieSuccess = currentUser => ({
  type: SigninActionTypes.SIGNIN_WITH_COOKIE_SUCCESS,
  currentUser: { ...currentUser, permissions: adaptUserPermissions(currentUser.permissions) },
});

export const signinWithSocialOrSaml = token => ({
  type: SigninActionTypes.SIGNIN_WITH_SOCIAL_OR_SAML,
  token: decodeURIComponent(token),
});

export const signinWithSocialOrSamlSuccess = currentUser => ({
  type: SigninActionTypes.SIGNIN_WITH_SOCIAL_OR_SAML_SUCCESS,
  currentUser: { ...currentUser, permissions: adaptUserPermissions(currentUser.permissions) },
});

export const postSignout = payload => ({
  type: SigninActionTypes.SIGNOUT,
  payload,
});

export const postSignoutSuccess = () => ({
  type: SigninActionTypes.SIGNOUT_SUCCESS,
});

export const initSignin = subdomain => ({
  type: SigninActionTypes.INIT_SIGNIN,
  subdomain,
});

export const reloadCurrentUserSuccess = currentUser => ({
  type: SigninActionTypes.RELOAD_CURRENT_USER_SUCCESS,
  currentUser: { ...currentUser, permissions: adaptUserPermissions(currentUser.permissions) },
});

export const createSamlLoginRequest = (subdomain, secondProvider = false) => ({
  type: SigninActionTypes.CREATE_SAML_LOGIN_REQUEST,
  payload: {
    subdomain,
    secondProvider,
  },
});

export const getCompanyIdBySubdomain = subdomain => ({
  type: SigninActionTypes.GET_COMPANY_ID_BY_SUBDOMAIN,
  subdomain,
});

export const exchangeToken = (keycloak, subdomain) => ({
  type: SigninActionTypes.EXCHANGE_TOKEN,
  keycloak,
  subdomain,
});

export const postForgot = payload => ({
  type: ForgotPasswordActionTypes.POST,
  payload,
});

export const initForgotPassword = (subdomain, currentCompany) => ({
  type: ForgotPasswordActionTypes.INIT_FORGOT_PASSWORD,
  subdomain,
  currentCompany,
});

export const postIDPSignout = payload => ({
  type: SigninActionTypes.IDP_SIGNOUT,
  payload,
});

export const setSsoLogin = useSsoLogin => ({
  type: AuthenticationActionTypes.SET_SSO_LOGIN,
  useSsoLogin,
});

export const defineLastSignInAt = () => ({
  type: SigninActionTypes.DEFINE_LAST_SIGN_IN_AT,
});
