import React from 'react';
import PropTypes from 'prop-types';
import { useFlag } from '@unleash/proxy-client-react';

const propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
};

const WithUnleash = WrappedComponent => featureFlags => (props) => {
  const featureFlagProps = {};

  featureFlags.forEach(featureFlag => Object.assign(
    // TODO: FRONT-75 - Regra de hook quebrada
    // eslint-disable-next-line react-hooks/rules-of-hooks
    featureFlagProps, { [featureFlag.propName]: useFlag(featureFlag.name) },
  ));

  return (
    <WrappedComponent {...props} {...featureFlagProps} />
  );
};

WithUnleash.propTypes = propTypes;

export default WithUnleash;
