import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  messages: PropTypes.object.isRequired,
};

export const IntroContent = ({ messages }) => (
  <div>
    <h2 className="diversity-term__heading">
      {messages.introTitle}
    </h2>
    <p className="diversity-term__paragraph">
      {messages.introDescription}
      {' '}<strong>{messages.introDescriptionAccess}</strong>
      {' '}{messages.introDescriptionPermission}
      {' '}<a
        href="https://support-companies.gupy.io/hc/pt-br/articles/11155009017499-O-que-%C3%A9-um-perfil-de-acesso-"
        className="diversity-term__link"
        target="_blank"
      >{messages.introDescriptionFAQ}</a>.
    </p>
  </div>
);

IntroContent.propTypes = propTypes;
