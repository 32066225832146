const JobStepTestIds = {
  profile: 'profile',
  english: 'english',
  advancedEnglish: 'advanced_english',
  logic: 'logic',
  logicSpanish: 'logic_es',
  advancedLogic: 'advanced_logic',
  advancedLogicSpanish: 'advanced_logic_es',
  video: 'video',
  external: 'external',
};

export default JobStepTestIds;
