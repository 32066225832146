import { useMutation } from '@tanstack/react-query';
import IntegrationsService from '../../../services/Integrations/IntegrationsService';
import { IntegrationsQueryKeyEnum } from './IntegrationsQueryKeyEnum';

export const useDeleteChildApp = ({ onSuccess, onError }) => {
  const { mutate: deleteChildApp } = useMutation({
    mutationFn: ({ childAppId, token }) => IntegrationsService
      .deleteRscLinkedinIntegrationChildApp(childAppId, token),
    mutationKey: [IntegrationsQueryKeyEnum.deleteRscLinkedinIntegrationChildApp],
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }
    },
  });

  return { deleteChildApp };
};
