const CurriculumDataTabs = {
  initial: 'initial',
  register: 'register',
  candidateInsertion: 'candidate_insertion',
  tests: 'tests',
  timeline: 'timeline',
  messages: 'messages',
  jobOffer: 'job_offer',
  linkedinRecruiter: 'linkedin_recruiter',
  skillsEvaluation: 'skills_evaluation',
};

export default CurriculumDataTabs;
