import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import classNames from 'classnames';

import { Dropdown, DialogMaterial, Spinner, Tip } from '@gupy/design-system';

import VerticalLinearStepper from './components/VerticalLinearStepper';
import {
  getFinalApplicationsCount,
  getAllApplicationsWithNoFeedback,
} from '../../../actions/Job/JobApplication/JobApplicationAction';
import {
  setJobClosureModalStep,
  getFinalJobStepId,
  doNoSendFeedbackEmail,
  getAllTemplateEmails,
  sendFeedbackEmails,
  getBindSurveyCareerPages,
} from './JobClosureAction';
import { getMessages } from './messages';
import TemplateEmailPreview from '../../../components/TemplateEmailPreview/TemplateEmailPreview';
import { translateTemplateToView } from './helpers/EmailTemplateTranslator';

import './JobClosureModal.scss';

const propTypes = {
  JobApplication: PropTypes.object.isRequired,
  JobClosure: PropTypes.object.isRequired,
  Authentication: PropTypes.object.isRequired,
  getFinalApplicationsCount: PropTypes.func.isRequired,
  job: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onConfirmFinishJob: PropTypes.func.isRequired,
  setJobClosureModalStep: PropTypes.func.isRequired,
  doNoSendFeedbackEmail: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  getFinalJobStepId: PropTypes.func.isRequired,
  getAllTemplateEmails: PropTypes.func.isRequired,
  sendFeedbackEmails: PropTypes.func.isRequired,
  getAllApplicationsWithNoFeedback: PropTypes.func.isRequired,
  getBindSurveyCareerPages: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  hasErrorFinishingJob: PropTypes.bool.isRequired,
};

const defaultProps = {
  job: null,
};

const modalFormStyles = {
  display: 'flex',
  justifyContent: 'center',
};

class JobClosureModal extends React.Component {
  state = {
    selectedTemplate: null,
    showValidationMessage: false,
    disableCloseJobButton: true,
  };

  componentWillUpdate(nextProps) {
    const { isOpen, JobClosure } = this.props;
    const { templateEmails, surveys } = JobClosure;

    if (!isOpen && !!nextProps.isOpen) {
      if (!templateEmails) {
        this.props.getAllTemplateEmails();
      }
      if (!surveys) {
        this.props.getBindSurveyCareerPages();
      }

      this.props.getFinalApplicationsCount(nextProps.job.id);
      this.props.getFinalJobStepId(nextProps.job.id);
      this.props.getAllApplicationsWithNoFeedback(nextProps.job.id);
    }
  }

  handleTemplateChange = (value) => {
    this.setState({
      selectedTemplate: value,
      showValidationMessage: false,
      disableCloseJobButton: true,
    });
  };

  getSteps(hasFeedbackToSend) {
    const { job } = this.props;
    const { finalApplicationsCount } = this.props.JobApplication;
    const { templateEmails, markers, surveys } = this.props.JobClosure;
    const { showValidationMessage } = this.state;
    const survey = job && surveys.find(s => s.careerPageId === job.careerPageId);

    let step1Label = '';
    let step1ContentLabel = '';

    if (finalApplicationsCount === 0) {
      step1Label = this.messages.moveToFinalStep;
      step1ContentLabel = this.messages.noHiredCandidatesFound;
    } else if (finalApplicationsCount > 0) {
      step1Label = this.messages.moveMoreToFinalStep;
      step1ContentLabel = this.messages.thereIsXcandidatesInThisStep;
    }

    const data = templateEmails
      .filter(t => !!survey || !t.body || !t.body.includes('[engagementSurveyLink]'))
      .map(t => (
        { value: `${t.id}`, label: `${t.name} | ${t.user.name}` }
      ));

    const selectedTemplate = templateEmails
      .find(template => template.id === parseInt(this.state.selectedTemplate, 10));

    const sendEmailFeedbackStepNode = (
      <React.Fragment>
        <Dropdown
          label={this.messages.chooseTemplate}
          id="job-closure-modal-template"
          name="emailTemplateName"
          nonSelectText={this.messages.emailTemplate}
          options={data}
          selectedOptionValue={this.state.selectedTemplate}
          onChange={this.handleTemplateChange}
        />
        { this.state.selectedTemplate
          ? (
            <TemplateEmailPreview
              templateEmail={translateTemplateToView(selectedTemplate, markers)}
            />
          )
          : null }
        <Tip
          ariaLive="polite"
          id="feedback_is_very_important"
          className="job-closure__tip"
          showIcon
          type="warning"
          text={(
            <FormattedHTMLMessage
              id="feedback_is_very_important"
              defaultMessage="<b>Atenção</b>: Este feedback é essencial para que os(as) candidatos(as) estejam atualizados sobre os processos de que estão participando.<br><br>Candidatos desistentes, com endereço de e-mail inválido ou inseridos manualmente que não realizaram o cadastro não receberão esse e-mail."
            />
          )}
        />
      </React.Fragment>
    );

    const noEmailFeedbackPendingToSendStepNode = (
      <React.Fragment>
        <Tip
          ariaLive="polite"
          className="job-closure__tip"
          showIcon
          type="info"
          id="no-feedback-pending-to-send-tip"
          text={this.messages.noFeedbackPendingToSend}
        />
      </React.Fragment>
    );

    const sendEmailFeedbackStepControlsNode = [{
      label: this.messages.doNotSendEmail,
      onClick: this.handleDoNotSendEmail,
      type: 'text',
    }, {
      disabled: !this.state.selectedTemplate,
      label: this.messages.sendEmail,
      onClick: this.handleSendEmail,
    }];

    const noEmailFeedbackPendingToSendStepControlsNode = [{
      label: this.messages.next,
      onClick: this.handleNext,
    }];

    const validationMessageNode = (
      <div className={classNames('validation', { show: showValidationMessage })}>
        {this.messages.chooseATemplate}
      </div>
    );

    const step1 = {
      label: step1Label,
      content: step1ContentLabel,
      onTitleClick: () => this.props.setJobClosureModalStep(0),
      buttons: [{
        label: (<span>
          {this.messages.verify}
          <span className="hidden-mobile">&nbsp;{this.messages.candidates}</span>
        </span>),
        onClick: this.handleGoToFinalCandidates,
        type: 'text',
      }, {
        label: this.messages.next,
        onClick: this.handleNext,
      }],
    };

    const step2 = hasFeedbackToSend
      ? ({
        label: this.messages.doYouWantToSendFeedback,
        onTitleClick: () => this.props.setJobClosureModalStep(1),
        preChildren: sendEmailFeedbackStepNode,
        buttons: sendEmailFeedbackStepControlsNode,
        postChildren: validationMessageNode,
      })
      : ({
        label: this.messages.doYouWantToSendFeedback,
        onTitleClick: () => this.props.setJobClosureModalStep(1),
        preChildren: noEmailFeedbackPendingToSendStepNode,
        buttons: noEmailFeedbackPendingToSendStepControlsNode,
        postChildren: null,
      });

    const step3 = {
      label: this.messages.doYouConfirmJobClosure,
      content: this.messages.thisJobCannotBeReactivated,
      onTitleClick: () => this.props.setJobClosureModalStep(2),
    };

    return [step1, step2, step3];
  }

  handleSendEmail = () => {
    const {
      JobClosure,
      JobApplication,
      job: { id: currentJobId, careerPageId },
      Authentication,
    } = this.props;
    const { selectedTemplate } = this.state;
    const { templateEmails, surveys } = JobClosure;
    const { applicationsToSendFeedback } = JobApplication;
    const from = Authentication.currentUser.company.email;
    const survey = surveys.find(s => s.careerPageId === careerPageId);

    if (!selectedTemplate) {
      this.setState({ showValidationMessage: true });
    } else {
      const template = templateEmails.find(t => t.id === parseInt(selectedTemplate, 10));

      this.props.sendFeedbackEmails(
        applicationsToSendFeedback,
        template,
        currentJobId,
        from,
        {
          success: this.messages.success,
          error: this.messages.error,
        },
        template.body && template.body.includes('[engagementSurveyLink]') ? survey.engageSurveyId : null,
      );
      this.handleNext();
    }
  };

  handleDoNotSendEmail = () => {
    this.handleNext();
    this.props.doNoSendFeedbackEmail();
  };

  handleNext = () => {
    if (this.state.showValidationMessage) {
      this.setState({ showValidationMessage: false });
    }
    const nextStep = this.props.JobClosure.currentStep + 1;
    if (nextStep === 2) {
      this.setState({ disableCloseJobButton: false });
    }
    this.props.setJobClosureModalStep(nextStep);
  };

  handleGoToFinalCandidates = () => {
    const { history, job: { id: currentJobId }, onCancelButtonClick, JobClosure } = this.props;
    const { finalJobStepId } = JobClosure;

    if (currentJobId && finalJobStepId) {
      onCancelButtonClick();
      history.push(`/companies/jobs/${currentJobId}/candidates?step=${finalJobStepId}`);
    }
  };

  handleCancelButtonClick = () => {
    this.setState({ disableCloseJobButton: true });
    this.props.onCancelButtonClick();
  };

  handleConfirmButtonClick = () => {
    this.setState({ disableCloseJobButton: true });
    const { isFeedbackSent } = this.props.JobClosure;
    this.props.onConfirmFinishJob(isFeedbackSent);
  };

  render() {
    const {
      isOpen,
      JobClosure,
      JobApplication,
      hasErrorFinishingJob,
    } = this.props;
    const { currentStep, finalJobStepId, templateEmails } = JobClosure;
    const { finalApplicationsCount, applicationsToSendFeedback } = JobApplication;
    const hasFeedbackToSend = applicationsToSendFeedback && applicationsToSendFeedback.length > 0;

    this.messages = getMessages(this.props.intl, finalApplicationsCount);

    const { disableCloseJobButton } = this.state;

    const isLoading = finalJobStepId === null
      || finalApplicationsCount === null
      || templateEmails === null
      || applicationsToSendFeedback === null;

    return (
      <DialogMaterial
        id="job-closure__modal"
        open={isOpen}
        maxWidth="lg"
        title={this.messages.jobClosureModalTitle}
        footer={{
          buttons: [
            {
              id: 'button-cancel-job-closure',
              text: this.messages.cancel,
              onClick: this.handleCancelButtonClick,
              type: 'text',
            },
            {
              id: 'button-close-job',
              text: this.messages.closeJob,
              onClick: this.handleConfirmButtonClick,
              disabled: disableCloseJobButton,
            },
          ],
        }}
      >
        <div id="job-closure__modal__body" style={modalFormStyles}>
          {isLoading ? (
            <Spinner color="secondary" />
          ) : (
            <div>
              {hasErrorFinishingJob && (
                <Tip
                  ariaLive="polite"
                  type="danger"
                  id="error-finishing-job"
                  text={this.messages.errorCloseJob}
                />
              )}
              <VerticalLinearStepper
                steps={this.getSteps(hasFeedbackToSend)}
                activeStep={currentStep}
              />
            </div>
          )}
        </div>
      </DialogMaterial>
    );
  }
}

JobClosureModal.propTypes = propTypes;
JobClosureModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  JobApplication: state.reducers.JobApplication,
  JobClosure: state.reducers.JobClosureReducer,
  Authentication: state.reducers.Authentication,
});

const mapDispatchToProps = {
  getFinalApplicationsCount,
  setJobClosureModalStep,
  getFinalJobStepId,
  doNoSendFeedbackEmail,
  getAllTemplateEmails,
  sendFeedbackEmails,
  getAllApplicationsWithNoFeedback,
  getBindSurveyCareerPages,
};

export default withRouter(injectIntl(connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobClosureModal)));
