import React from 'react';
import PropTypes from 'prop-types';

import { MainSelectList as SelectList } from '@gupy/front-commons';
import { isValidFieldType } from '@gupy/front-commons/helpers/SchemaFormHelper';
import {
  InputGroup,
  DialogMaterial,
  SwitchButton,
  TextAreaWithChip,
  InputItemList,
  Icon,
  IconEnum,
  H,
  P,
  Button,
  Tip,
} from '@gupy/design-system';
import Dialogs from '@gupy/design-system/Dialogs';

import './JobCustomFormTemplateModal.scss';
import classNames from 'classnames';
import JobCustomFormTemplateConditionalForm from './JobCustomFormTemplateConditionalForm';
import JobCustomFormTemplateConditionalCard from './JobCustomFormTemplateConditionalCard';
import { ItemSortEnum } from '../ItemSortEnum';
import JobCustomFormTemplateConditionalFilter from './JobCustomFormTemplateConditionalFilter';

const initialState = {
  fieldType: 'text',
  fieldName: '',
  fieldMandatory: false,
  disableSaveButton: true,
  fieldSelectItems: [],
  templateConditionalFormActive: false,
  children: [],
  childToEdit: '',
  editFormData: {
    fieldName: '',
    fieldType: '',
    selectedOption: [],
    selectedItems: [],
  },
  childrenByOption: {},
  filterSelectedValue: '',
  hasFilter: false,
};

class JobCustomFormTemplateModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.onMoveTo = this.onMoveTo.bind(this);
  }

  hasConditionalRestrictions = (fieldType, fieldSelectedItems, childrenByOption) => {
    const isConditional = fieldType === 'conditional';

    if (!isValidFieldType) {
      return true;
    }

    if (!isConditional) {
      return false;
    }

    const conditionalHasNoChildren = Object.values(childrenByOption).flat().length === 0;
    const conditionalHasInsuficientOptions = !fieldSelectedItems || fieldSelectedItems.length < 2;
    const hasConditionalProblems = isConditional
      && (conditionalHasInsuficientOptions || conditionalHasNoChildren);

    return hasConditionalProblems;
  }

  handleDisableSaveBtn = (name, value) => {
    const fieldType = name === 'fieldType' ? value : this.state.fieldType;
    const fieldSelectedItems = name === 'fieldSelectItems' ? value : this.state.fieldSelectItems;

    if (!isValidFieldType(fieldType)) {
      this.setState({
        disableSaveButton: true,
      });
      return;
    }

    const isSelect = fieldType === 'select' || fieldType === 'multiSelect';

    const conditionalFieldHasRestrictions = this.hasConditionalRestrictions(
      fieldType,
      fieldSelectedItems,
      this.state.childrenByOption,
    );

    const disableSaveButton = (isSelect
      && fieldSelectedItems.filter(Boolean).length === 0)
      || conditionalFieldHasRestrictions;

    this.setState({
      disableSaveButton,
    });
  };

  resetConditionalFieldSelects = (fieldToChange, newFieldType) => {
    const { fieldType, fieldSelectItems } = this.state;

    const isChangingFieldType = fieldToChange === 'fieldType' && fieldType !== newFieldType;
    const isChangingToOrFromConditional = newFieldType === 'conditional' || fieldType === 'conditional';

    if (isChangingFieldType && isChangingToOrFromConditional) {
      this.setState({
        fieldSelectItems: fieldSelectItems || [],
        children: [],
        childrenByOption: {},
      });
    }
  }

  fieldChange = (event, callback = () => { }) => {
    const { name, value } = event.target;
    this.resetConditionalFieldSelects(name, value);
    this.setState({
      [name]: value,
    }, callback);

    this.handleDisableSaveBtn(name, value);
  };

  removeFieldsByOption = (optionsArray) => {
    const childrenByOption = Object.assign({}, this.state.childrenByOption);
    optionsArray.forEach(option => delete childrenByOption[option]);

    this.setState({ childrenByOption },
      () => {
        const disableSaveButton = this.hasConditionalRestrictions(
          this.state.fieldType,
          this.state.fieldSelectItems,
          childrenByOption,
        );

        this.setState({ disableSaveButton });
        if (this.state.hasFilter && optionsArray.includes(this.state.filterSelectedValue)) {
          this.setState({ hasFilter: false, filterSelectedValue: '' });
        }
      });
  }

  onRemoveField = ({ fieldIndex, dependentOption }) => {
    const { childrenByOption } = this.state;
    const newChildrenByOption = childrenByOption;
    newChildrenByOption[dependentOption[0]].splice(fieldIndex - 1, 1);
    this.setState({ childrenByOption: newChildrenByOption });

    const disableSaveButton = this.hasConditionalRestrictions(
      this.state.fieldType,
      this.state.fieldSelectItems,
      newChildrenByOption,
    );

    this.setState({ disableSaveButton });
  }

  onEditField = ({ fieldIndex, fieldTitle, fieldType, dependentOption, selectedItems, items }) => {
    this.setState({ optionChildren: items });
    const newEditFormData = {
      fieldName: fieldTitle,
      fieldType,
      selectedOption: dependentOption,
      selectedItems,
    };
    this.setState({ editFormData: newEditFormData });
    this.setState({ childToEdit: `${dependentOption}-${fieldIndex}` });
  }

  cancelEditing = () => {
    this.setState({ childToEdit: '' });
  }

  componentDidMount() {
    const { companySchemaField } = this.props;
    if (companySchemaField) {
      this.setState({ ...companySchemaField });
    }
  }

  handleSave = () => {
    const { childrenByOption } = this.state;
    const children = Object.values(childrenByOption).flat();

    const updatedRequiredFields = children.map((item) => {
      // eslint-disable-next-line no-param-reassign
      item.required = this.state.fieldMandatory;
      return item;
    });

    this.props.onSubmit({
      fieldToAdd: { ...this.state, children: updatedRequiredFields },
    });
  };

  onUpdateConditionalChild = ({
    selectedOption,
    fieldName,
    fieldType,
    selectedItems,
    fieldIndex,
    fieldKey,
    oldSelectedOption,
  }) => {
    const conditionalField = this.state;
    const optionSelected = typeof selectedOption === 'object' ? selectedOption[0] : selectedOption;
    const oldOptionSelected = oldSelectedOption ? oldSelectedOption[0] : '';

    const complement = ['multiSelect', 'select'].includes(fieldType) ? { fieldSelectItems: selectedItems } : {};
    const conditionalChild = {
      fieldName,
      fieldType,
      fieldKey,
      required: conditionalField.required,
      dependentOption: [optionSelected],
      ...complement,
    };
    const currentOptionChildrenItem = this.state.optionChildren[fieldIndex];
    const childrenByOption = { ...this.state.childrenByOption };

    const oldOptionFields = childrenByOption[oldOptionSelected];
    const currentIndex = oldOptionFields.findIndex(item => item.fieldName
      === currentOptionChildrenItem.fieldName
      && item.fieldType === currentOptionChildrenItem.fieldType
      && item.fieldKey === currentOptionChildrenItem.fieldKey
      && item.dependentOption === currentOptionChildrenItem.dependentOption);
    let newChildrenByOption;

    if (oldOptionSelected === optionSelected) {
      oldOptionFields.splice(currentIndex, 1, conditionalChild);
      newChildrenByOption = { ...childrenByOption, [oldOptionSelected]: oldOptionFields };
    } else {
      oldOptionFields.splice(currentIndex, 1);
      const newOptionFields = childrenByOption[optionSelected] || [];
      newOptionFields.push(conditionalChild);
      newChildrenByOption = {
        ...childrenByOption,
        [oldOptionSelected]: oldOptionFields,
        [optionSelected]: newOptionFields,
      };
    }

    const disableSaveButton = this.hasConditionalRestrictions(
      conditionalField.fieldType,
      conditionalField.fieldSelectItems,
      newChildrenByOption,
    );

    this.setState({
      childrenByOption: newChildrenByOption,
      childToEdit: '',
      disableSaveButton,
    });
  };

  onSaveConditionalChild = ({
    selectedOption,
    fieldName,
    fieldType,
    selectedItems,
  }) => {
    const { fieldMandatory, childrenByOption, ...conditionalField } = this.state;

    const complement = ['multiSelect', 'select'].includes(fieldType) ? { fieldSelectItems: selectedItems } : {};
    const conditionalChild = {
      fieldName,
      fieldType,
      required: fieldMandatory,
      dependentOption: [selectedOption],
      ...complement,
    };
    const currentOptionFields = childrenByOption[selectedOption] || [];

    const newChildrenByOption = {
      ...childrenByOption,
      [selectedOption]: [...currentOptionFields, conditionalChild],
    };

    this.setState(
      {
        childrenByOption: newChildrenByOption,
        templateConditionalFormActive: false,
        hasFilter: false,
        filterSelectedValue: '',
      },
    );

    const disableSaveButton = this.hasConditionalRestrictions(
      conditionalField.fieldType,
      conditionalField.fieldSelectItems,
      newChildrenByOption,
    );

    this.setState({ disableSaveButton });
  };

  onMoveTo = (sortType, index, option) => {
    const { childrenByOption } = this.state;
    const currentOptionFields = [...childrenByOption[option]];
    const currentField = currentOptionFields[index];

    let nextCurrentPosition;
    switch (sortType) {
    case ItemSortEnum.MOVE_TO_TOP:
      nextCurrentPosition = 0;
      break;
    case ItemSortEnum.MOVE_UP:
      nextCurrentPosition = index - 1;
      break;
    case ItemSortEnum.MOVE_DOWN:
      nextCurrentPosition = index + 1;
      break;
    case ItemSortEnum.MOVE_TO_END:
      nextCurrentPosition = currentOptionFields.length - 1;
      break;
    default:
      nextCurrentPosition = index;
      break;
    }
    const COUNT_REPLACE = 1;
    const currentPosition = index;

    currentOptionFields.splice(currentPosition, COUNT_REPLACE);
    currentOptionFields.splice(nextCurrentPosition, 0, currentField);

    const disableSaveButton = this.hasConditionalRestrictions(
      this.state.fieldType,
      this.state.fieldSelectItems,
      this.state.childrenByOption,
    );

    this.setState({
      childrenByOption: {
        ...this.state.childrenByOption,
        [option]: currentOptionFields,
      },
      disableSaveButton,
    });
  };

  renderConditionalCardsSection = ({ fieldTypes }) => {
    const { fieldSelectItems, childrenByOption, hasFilter, filterSelectedValue } = this.state;
    const { messages } = this.props;
    const hasNoOptionsAndChildren = !fieldSelectItems
      || !fieldSelectItems.length;

    if (hasNoOptionsAndChildren) {
      return null;
    }

    if (hasFilter) {
      const optionField = childrenByOption[filterSelectedValue];
      if (!optionField || !optionField.length) {
        return null;
      }

      return (
        <div key={`conditional-section-${filterSelectedValue}`}>
          <p className="conditional_cards__title">{messages.conditionalOption} <strong>{filterSelectedValue}</strong></p>
          {optionField.map((child, index) => (
            <JobCustomFormTemplateConditionalCard
              key={`conditional-card-${filterSelectedValue}-${index + 1}`}
              messages={this.props.messages}
              items={optionField}
              fieldTitle={child.fieldName}
              fieldIndex={index + 1}
              fieldType={fieldTypes.find(({ value }) => value === child.fieldType)}
              selectedItems={child.fieldSelectItems}
              dependentOption={child.dependentOption}
              fieldKey={child.fieldKey}
              onRemoveField={this.onRemoveField}
              onEditField={this.onEditField}
              childToEdit={this.state.childToEdit}
              cancelEditing={this.cancelEditing}
              fieldTypes={fieldTypes}
              answerOptions={this.state.fieldSelectItems}
              onUpdateConditionalChild={this.onUpdateConditionalChild}
              editFormData={this.state.editFormData}
              onMoveTo={this.onMoveTo}
              option={filterSelectedValue}
            />
          ))}
        </div>
      );
    }

    const cards = fieldSelectItems
      .map((option) => {
        const optionField = childrenByOption[option];
        if (!optionField || !optionField.length) {
          return null;
        }

        return (
          <div key={`conditional-section-${option}`}>
            <p className="conditional_cards__title">{messages.conditionalOption} <strong>{option}</strong></p>
            {optionField.map((child, index) => (
              <JobCustomFormTemplateConditionalCard
                key={`conditional-card-${option}-${index + 1}`}
                messages={this.props.messages}
                items={optionField}
                fieldTitle={child.fieldName}
                fieldIndex={index + 1}
                fieldType={fieldTypes.find(({ value }) => value === child.fieldType)}
                selectedItems={child.fieldSelectItems}
                dependentOption={child.dependentOption}
                fieldKey={child.fieldKey}
                onRemoveField={this.onRemoveField}
                onEditField={this.onEditField}
                childToEdit={this.state.childToEdit}
                cancelEditing={this.cancelEditing}
                fieldTypes={fieldTypes}
                answerOptions={this.state.fieldSelectItems}
                onUpdateConditionalChild={this.onUpdateConditionalChild}
                editFormData={this.state.editFormData}
                onMoveTo={this.onMoveTo}
                option={option}
              />
            ))}
          </div>
        );
      });

    return cards;
  }

  renderTemplateConditionalForm = ({ fieldTypes }) => {
    const { messages } = this.props;

    return this.state.templateConditionalFormActive && (
      <JobCustomFormTemplateConditionalForm
        messages={messages}
        answerOptions={this.state.fieldSelectItems}
        fieldTypes={fieldTypes}
        onSave={this.onSaveConditionalChild}
        onCancel={() => this.setState({ templateConditionalFormActive: false })}
      />
    );
  }

  setFilterSelectedValue = (value) => {
    const filterEnabled = value !== '';
    this.setState({ filterSelectedValue: value });
    this.setState({ hasFilter: filterEnabled });
  }

  renderTemplateConditionalFilter = () => {
    const { fieldSelectItems, filterSelectedValue, childrenByOption, hasFilter } = this.state;
    const { messages } = this.props;

    const onlySelectItemsWithChildren = fieldSelectItems.filter(
      item => childrenByOption[item] && childrenByOption[item].length,
    );
    const hasEnoughChildren = onlySelectItemsWithChildren.length > 1;

    const formattedFieldSelectItems = onlySelectItemsWithChildren.map(item => ({
      label: item,
      value: item,
    }));

    if (this.state.fieldType !== 'conditional' || !hasEnoughChildren) {
      if (hasFilter) {
        this.setState({ hasFilter: false });
      }
      return null;
    }

    return (
      <JobCustomFormTemplateConditionalFilter
        messages={messages}
        filterOptions={formattedFieldSelectItems}
        filterSelectedValue={filterSelectedValue}
        setFilterSelectedValue={this.setFilterSelectedValue}
      />
    );
  }

  changeRequiredFields(required) {
    this.fieldChange({ target: { name: 'fieldMandatory', value: required } });
  }

  changeOptionName = (params) => {
    const { old: oldName, new: newName } = params;
    const { childrenByOption } = this.state;

    if (oldName && !newName) {
      this.removeFieldsByOption([oldName]);
      return;
    }

    const children = childrenByOption[oldName] || [];
    const newChildren = children.map(field => ({ ...field, dependentOption: [newName] }));

    const newChildrenByOption = { ...childrenByOption, [newName]: newChildren };
    delete newChildrenByOption[oldName];

    this.setState({ childrenByOption: newChildrenByOption });
  }

  handleCloseModal = () => {
    if (this.state.disableSaveButton || this.state.fieldType !== 'conditional') {
      this.props.onClose();
      return;
    }

    this.openEditCancelConfirm();
  }

  openEditCancelConfirm = () => {
    const { messages } = this.props;
    Dialogs.confirm({
      title: messages.cancelEditConditionalFieldConfirm,
      description: messages.editConditionalFieldConfirmDescription,
      confirmButton: {
        customColor: {},
        label: messages.editConditionalFieldConfirmCancel,
        action: this.props.onClose,
      },
      cancelButton: {
        label: messages.editConditionalFieldConfirmBack,
      },
    });
  }

  render() {
    const {
      messages,
      formErrors,
    } = this.props;

    const OPTIONS_MIN_ITEMS = 2;

    const fieldTypes = [
      {
        label: messages.date,
        value: 'date',
        icon: 'Event',
      },
      {
        label: messages.shortText,
        value: 'text',
        icon: 'TextFormat',
      },
      {
        label: messages.number,
        value: 'number',
        icon: 'LooksOne',
      },
      {
        label: messages.yesNo,
        value: 'boolean',
        icon: 'ToggleOn',
      },
      {
        label: messages.time,
        value: 'time',
        icon: 'Schedule',
      },
      {
        label: messages.dropDownList,
        value: 'select',
        icon: 'ArrowDropDownCircle',
      },
      {
        label: messages.multiSelect,
        value: 'multiSelect',
        icon: 'CheckBox',
      },
      {
        label: messages.attachment,
        value: 'attachment',
        icon: 'AttachFile',
      },
      {
        label: messages.conditionalField,
        value: 'conditional',
        icon: 'ConditionalField',
      },
    ];

    const isFieldTypeSelect = this.state.fieldType === 'select';
    const isFieldTypeMultiSelect = this.state.fieldType === 'multiSelect';
    const isFieldTypeConditional = this.state.fieldType === 'conditional';
    const showSelectTextArea = isFieldTypeSelect
      || isFieldTypeMultiSelect
      || isFieldTypeConditional;
    const isFieldSelectItemsEmpty = this.state.fieldSelectItems.length === 0;

    return (<DialogMaterial
      className="job-custom-form-template-modal"
      title={this.state.fieldKey
        ? messages.jobCustomFormTemplateEditModalTitle
        : messages.jobCustomFormTemplateModalTitle
      }
      open
      footer={{
        buttons: [
          {
            id: 'button-cancel-custom-form-field',
            type: 'text',
            text: messages.jobCustomFormTemplateCancelButton,
            onClick: this.handleCloseModal,
          },
          {
            id: 'button-save-custom-form-field',
            text: this.state.fieldKey
              ? messages.jobCustomFormTemplateFinishEditionButton
              : messages.jobCustomFormTemplateIncludeFieldButton,
            onClick: this.handleSave,
            disabled: this.state.disableSaveButton,
          },
        ],
      }}
    >
      <div className="row">
        <div className="col-xs-12 col-sm-12 job-custom-form-template-modal__block">
          <InputGroup
            id="fieldName"
            value={this.state.fieldName}
            label={messages.jobCustomFormTemplateFieldName}
            onChange={this.fieldChange}
            validation={formErrors.fieldName}
            required
            block
          />
        </div>
        <div className="col-xs-12 col-sm-12">
          <SelectList
            label={messages.jobCustomFormTemplateSelectFieldType}
            noResultsLabel={messages.noResultsLabel}
            filterLabel={messages.filterLabel}
            id="fieldType"
            name="fieldType"
            value={this.state.fieldType}
            onChange={this.fieldChange}
            data={fieldTypes}
            responsive
            disableClearValue
            multiSelect={false}
            validation={formErrors.fieldType}
          />
        </div>
        {showSelectTextArea
          && (
            <div className={classNames(
              'col-xs-12',
              'col-sm-12',
              'job-custom-form-template-modal__textarea',
              { 'job-custom-form-template-modal__textarea--placeholder': isFieldSelectItemsEmpty },
            )}
            >

              {this.state.fieldType === 'conditional'
                && (
                  <InputItemList
                    label={messages.jobCustomFormTemplateFieldSelectItems}
                    options={this.state.fieldSelectItems}
                    onChangeOptions={value => this.fieldChange({ target: { name: 'fieldSelectItems', value } })}
                    onDeleteOption={value => this.removeFieldsByOption(value)}
                    onEditOption={this.changeOptionName}
                    buttonText={messages.jobCustomFormTemplateAddNewOptionButton}
                    deleteButtonText={messages.deleteAllOptionsButton}
                    minItems={OPTIONS_MIN_ITEMS}
                    required
                    placeholder={messages.jobCustomFormTemplateFieldSelectOptionsPlaceholder}
                  />
                )}

              {this.state.fieldType !== 'conditional' && (
                <React.Fragment>
                  <TextAreaWithChip
                    id="fieldSelectItemsWithChip"
                    selectedOptions={this.state.fieldSelectItems}
                    handleSelectedOptions={value => this.fieldChange({
                      target: { name: 'fieldSelectItems', value },
                    })
                    }
                    helperText={(
                      <span className="job-custom-form-template-modal__textarea-helper">
                        <Icon icon={IconEnum.InfoOutlined} fontSize="small" />
                        <span className="job-custom-form-template-modal__textarea-helper-text">
                          {messages.jobCustomFormTemplateFieldSelectItemsInfo}
                        </span>
                      </span>
                    )}
                    conditionalField={this.state.fieldType === 'conditional'}
                    placeholder={
                      messages.jobCustomFormTemplateFieldSelectItemsPlaceholder
                    }
                  />
                  <div className="job-custom-form-template-modal__clear-all-btn">
                    <Button
                      icon="Delete"
                      type="text"
                      onClick={() => this.fieldChange({
                        target: { name: 'fieldSelectItems', value: [] },
                      })
                      }
                    >
                      {messages.jobCustomFormTemplateFieldSelectItemsClearButton}
                    </Button>
                  </div>
                </React.Fragment>
              )}

            </div>
          )}
        <div className="col-xs-12 col-sm-12">
          <hr className="job-custom-form-template-modal__divisor" />
        </div>
        <div className="col-xs-12 col-sm-12">
          <div className="job-custom-form-template-modal__required-div">
            <div>
              {messages.requiredJsonSchemaField}
            </div>
            <SwitchButton
              id="fieldMandatory"
              name="fieldMandatory"
              onChange={event => this.changeRequiredFields(event)}
              checked={this.state.fieldMandatory}
            />
          </div>
        </div>
        <div className="col-xs-12 col-sm-12">
          {isFieldTypeConditional && this.state.fieldMandatory && (
            <div>
              <Tip
                id="tip-required"
                type="info"
                showIcon
                text={messages.jobCustomFormTemplateConditionalFieldRequiredTip}
              />
            </div>
          )}
          <hr className="job-custom-form-template-modal__divisor" />
        </div>
        <div className="col-xs-12 col-sm-12">
          {isFieldTypeConditional && (
            <div>
              <Tip
                id="tip-add-conditional"
                type="normal"
                text={(
                  <div>
                    <H variant="4">
                      {messages.jobCustomFormTemplateConditionalFieldAddTipTitle}
                    </H>
                    <P>
                      {messages.jobCustomFormTemplateConditionalFieldAddTipSubtitle}
                    </P>
                  </div>
                )}
                inverted
              />
              {!this.state.templateConditionalFormActive && (
                <Button
                  id="add-conditional-child-btn"
                  icon="Add"
                  type="outlined"
                  onClick={() => this.setState({ templateConditionalFormActive: true })}
                >
                  {messages.jobCustomFormTemplateConditionalFieldAdd}
                </Button>
              )}
              <div className="col-xs-12 col-sm-12">
                <hr className="job-custom-form-template-modal__divisor" />
              </div>
              {this.renderTemplateConditionalForm({ fieldTypes })}
              {this.renderTemplateConditionalFilter()}
              {this.renderConditionalCardsSection({ fieldTypes })}
            </div>
          )}
        </div>
      </div>
    </DialogMaterial>
    );
  }
}

JobCustomFormTemplateModal.propTypes = {
  messages: PropTypes.object.isRequired,
  companySchemaField: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
};

export default JobCustomFormTemplateModal;
