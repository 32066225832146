class FormatVideoURLHelper {
  static formatVideoURL = (videoURL) => {
    const pattern1 = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g;
    const pattern2 = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|watch\?.+&v=)?(.+)/g;
    let resultURL = videoURL;

    if (pattern1.test(videoURL)) {
      resultURL = videoURL.replace(pattern1, '//player.vimeo.com/video/$1');
    } else if (pattern2.test(videoURL)) {
      const youtubeId = videoURL.replace(pattern2, '$1').split('&')[0];
      resultURL = `https://www.youtube.com/embed/${youtubeId}?rel=0&amp;showinfo=0`;
    } else {
      resultURL = '';
    }

    return resultURL;
  }
}

export default FormatVideoURLHelper;
