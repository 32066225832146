import { put, takeLatest } from 'redux-saga/effects';

import * as types from './RootSetupTypes';
import {
  saveFeatureFlagsSuccess,
  saveFeatureFlagsFailure,
} from './RootSetupActions';
import RootSetupService from './RootSetupService';

function* saveFeatureFlags({ payload, messages }) {
  try {
    const response = yield RootSetupService.saveFeatureFlags(payload);

    yield put(saveFeatureFlagsSuccess(response, messages));
  } catch (error) {
    yield put(saveFeatureFlagsFailure(messages));
  }
}

function* RootSetupSagas() {
  yield takeLatest(types.SAVE_FEATURE_FLAGS_REQUEST, saveFeatureFlags);
}

export default RootSetupSagas;
