import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Card, H, Button } from '@gupy/design-system';
import getKnowMoreLinkForPlan from '../../constants/CompaniesPlansToKnowMoreLinks';
import './styles.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  shouldBlock: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  btnText: PropTypes.string.isRequired,
  cardTitle: PropTypes.string.isRequired,
  cardDescription: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.node.isRequired,
  ]),
  onBtnClick: PropTypes.func,
  currentUser: PropTypes.object.isRequired,
  subDescription: PropTypes.string,
};

const BlockedComponent = ({
  children,
  shouldBlock,
  title,
  description,
  btnText,
  cardTitle,
  cardDescription,
  onBtnClick,
  currentUser,
  subDescription,
}) => {
  const showUpgradePlanButton = currentUser && ['root', 'owner'].includes(currentUser.userType);
  let companyPlan = '';
  if (currentUser && currentUser.products) {
    const companyProduct = currentUser.products.find(product => product.plan !== '');
    if (companyProduct) {
      companyPlan = companyProduct.plan;
    }
  }
  const knowMoreLink = getKnowMoreLinkForPlan(companyPlan);
  return shouldBlock ? (
    <Fragment>
      <div style={{ position: 'relative', width: '100%' }}>
        <main className="blocked-component-container">
          <section>
            <img
              className="blocked-component-container__image-plan"
              src={`${process.env.REACT_APP_ASSETS_URL}/repacking/higher-plan-logo.svg`}
              height="60px"
              alt="Logo"
            />
            <H variant="1" className="blocked-component-container__header">
              {title}
            </H>
            <p className="blocked-component-container__description">
              {description}
            </p>
            {
              subDescription && (
                <p className="blocked-component-container__subDescription">
                  {subDescription}
                </p>
              )
            }
            {
              showUpgradePlanButton && (
                <div style={{ width: '100%' }}>
                  <Button
                    size="lg"
                    className="blocked-component-container__button"
                    onClick={onBtnClick || (() => window.open(knowMoreLink, '_blank'))}
                  >
                    {btnText}
                  </Button>
                </div>
              )
            }
            <Card
              padding="md"
              className="blocked-component-container__card"
            >
              <H variant="3" baseSpacing={false}>
                {cardTitle}
              </H>
              <p>
                {cardDescription}
              </p>
            </Card>
          </section>
        </main>
      </div>
    </Fragment>
  ) : (children);
};

BlockedComponent.propTypes = propTypes;

export {
  BlockedComponent,
};
