import api from '../../api/DarthVaderClient';

const companySubsidiariesApi = '/company-management/companies/subsidiaries';

export default {

  post(payload) {
    return api.post(companySubsidiariesApi, payload);
  },

  put(id, payload) {
    return api.put(`${companySubsidiariesApi}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${companySubsidiariesApi}/${id}`);
  },

  getAll() {
    return api.get(`${companySubsidiariesApi}?order=name&page=0&perPage=9999`);
  },

};
