import { combineReducers } from 'redux';

import JobPublicationTypeActionTypes from '../../../constants/Job/JobPublicationType/JobPublicationTypeActionTypes';

export const modelForm = (state = {}, action) => {
  switch (action.type) {
  case JobPublicationTypeActionTypes.GET_SUCCESS: {
    const newState = {
      id: action.modelForm.id,
      status: action.modelForm.status,
      name: action.modelForm.name,
      publicationType: action.modelForm.publicationType,
      permissions: action.modelForm.permissions,
      careerPage: action.modelForm.careerPage,
    };
    return newState;
  }
  case JobPublicationTypeActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  default:
    return state;
  }
};

const validation = (state = { validation: {}, error: {} }, action) => {
  switch (action.type) {
  case JobPublicationTypeActionTypes.PUBLISH_FAIL:
    return { validation: action.validation, error: action.error };
  case JobPublicationTypeActionTypes.CLEAR_VALIDATION_ERRORS:
    return { validation: {}, error: {} };
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobPublicationTypeActionTypes.PUBLISH:
    return true;
  case JobPublicationTypeActionTypes.PUBLISH_SUCCESS:
  case JobPublicationTypeActionTypes.PUBLISH_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobPublicationTypeActionTypes.GET:
  case JobPublicationTypeActionTypes.PUBLISH:
    return true;
  case JobPublicationTypeActionTypes.GET_SUCCESS:
  case JobPublicationTypeActionTypes.GET_FAIL:
  case JobPublicationTypeActionTypes.PUBLISH_SUCCESS:
  case JobPublicationTypeActionTypes.PUBLISH_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  validation,
  modelForm,
  isSaving,
  isLoading,
});
