import { BaseForm } from '@gupy/front-commons';

export class InterviewEventModalValidator extends BaseForm {
  constructor(messages) {
    super({
      title: {
        validate: {
          required: true,
          maxLength: 500,
        },
      },
      description: {
        validate: {
          required: true,
          maxLength: 2 * 1024 * 1024,
        },
      },
      participants: {
        validate: {
          arrayGreaterOrEqualTo: {
            value: 1,
            msg: messages.interviewEventParticipantsMinValue,
          },
        },
      },
      candidatesIds: {
        validate: {
          notEmptyArray: true,
        },
      },
      format: {
        validate: {
          required: true,
        },
      },
      startTime: {
        validate: {
          required: true,
        },
      },
      endTime: {
        validate: {
          greaterTimeToAnotherField: {
            anotherFieldName: 'startTime',
            anotherFieldDescription: 'Início',
          },
        },
      },
    });
  }
}
export class InterviewEventModalDateValidator extends BaseForm {
  constructor() {
    super({
      endTime: {
        validate: {
          greaterTimeToAnotherField: {
            anotherFieldName: 'startTime',
            anotherFieldDescription: 'Início',
          },
        },
      },
    });
  }
}
