import PartnerTypes from '../../../../src/candidates/src/constants/PartnerTypes';

class UrlHelper {
  static queryStringFromApplicationStep = (step) => {
    let queryString;
    if (step.id) {
      queryString = `?step=${step.id}`;
    } else if (step.type) {
      queryString = `?type=${step.type}`;
    }
    return queryString;
  }

  static insertHttp = (url) => {
    if (url) {
      const pattern = /^[!@#$%^&*()_+\-=[\]{};':"\\|<>/?]*/g;
      const formatedUrl = url.replace(pattern, '');

      if (formatedUrl.startsWith('http')) {
        return formatedUrl;
      }
      return `http://${formatedUrl}`;
    }
    return url;
  }

  /**
   * It builds a url for the desired career page.
   * @param {object} data - Object containing useful data to build the url
   * @param {string} data.careerPageSubdomain - Subdomain of desired career page
   * @param {object} data.location - Object present in every browser through the object window
   * @param {string} data.location.hostname - Current hostname
   * @param {string} data.location.protocol - Current used protocol by user
   * @param {string} [data.location.port] - Current used port, if any
   * @param {string|number} [data.jobId] - Current jobId. If available, the job suffix route will
   * be appended to the url
  */
  static buildCareerPageSubdomainUrl = ({
    careerPageSubdomain,
    location,
    jobId,
  }) => {
    let shareUrl = this.buildBaseUrl({ careerPageSubdomain, location });

    if (careerPageSubdomain && jobId) {
      shareUrl += `/jobs/${jobId}`;
    }

    return shareUrl;
  }

  static buildInternalJobUrl = ({
    careerPageSubdomain,
    location,
    jobId,
  }) => {
    const baseUrl = this.buildBaseUrl({ careerPageSubdomain, location });
    if (!baseUrl || !jobId) return '';
    return `${baseUrl}/jobs/${jobId}?jobBoardSource=${PartnerTypes.gupyInternalJobs}`;
  }

  static buildJobUrlWithSource = ({
    careerPageSubdomain,
    location,
    jobId,
    source,
  }) => {
    const baseUrl = this.buildBaseUrl({ careerPageSubdomain, location });
    if (!baseUrl || !jobId || !source) return '';

    return `${baseUrl}/jobs/${jobId}?jobBoardSource=${source}`;
  }

  static buildBaseUrl = ({
    careerPageSubdomain,
    location,
  }) => {
    const urlArray = location.hostname.split('.');
    urlArray.shift();
    let baseUrl = '';

    if (careerPageSubdomain) {
      const urlSuffix = urlArray.join('.');
      baseUrl = `${location.protocol}//${careerPageSubdomain}.${urlSuffix}`;

      if (location.port) {
        baseUrl += `:${location.port}`;
      }
    }

    return baseUrl;
  };

  static getQueryParam = (name, location) => {
    if (!location || !location.search) {
      return null;
    }

    const results = new RegExp(`[\\?&]${name}=([^&#]*)`).exec(location.search);

    if (!results) {
      return null;
    }

    return decodeURIComponent(results[1]) || null;
  };
}

export default UrlHelper;
