import { put, takeLatest, call, all } from 'redux-saga/effects';

import history from '../../history';
import JobInternalDataActionTypes from '../../constants/Job/JobInternalDataActionTypes';
import { initJobInternalDataSuccess } from '../../actions/Job/JobInternalDataAction';
import JobService from '../../services/Job/JobService';
import JobInternalDataForm from '../../forms/Job/JobInternalDataForm';

function* initJobInternalData(action) {
  const { jobId } = action;

  try {
    const [
      reasonTypesResponse,
      recruitersListResponse,
      jobResponse,
      managersListResponse,
    ] = yield all([
      JobService.getJobReasonTypes(),
      JobService.getJobRecruitersList(jobId),
      JobService.get(jobId),
      JobService.getJobManagersList(jobId),
    ]);

    const additionalApproversIds = jobResponse.body.approvers.map(a => a.id) || undefined;
    const approversListResponse = yield JobService.getJobApproversList(
      additionalApproversIds,
    );
    const {
      onlyDefaultUsers: onlyDefaultManagers,
      list: managersList,
    } = managersListResponse.body;
    const {
      onlyDefaultUsers: onlyDefaultRecruiters,
      list: recruitersList,
    } = recruitersListResponse.body;

    yield put(initJobInternalDataSuccess(
      approversListResponse.body.data,
      recruitersList,
      reasonTypesResponse.body.data,
      jobResponse.body,
      managersList,
      onlyDefaultManagers,
      onlyDefaultRecruiters,
    ));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL,
        error,
      });
    }
  }
}

function* get(action) {
  const form = new JobInternalDataForm();

  try {
    const response = yield JobService.get(action.id);
    yield put({
      type: JobInternalDataActionTypes.GET_SUCCESS,
      modelForm: response.body,
    });
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobInternalDataActionTypes.GET_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* patch(action) {
  const { payload, jobPath, form } = action;

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobInternalDataActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.patch(payload);

      yield put({
        type: JobInternalDataActionTypes.PATCH_SUCCESS,
        modelForm: response.body,
      });
      yield call(history.push, jobPath);
    } catch (error) {
      yield put({
        type: JobInternalDataActionTypes.PATCH_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* JobInternalDataSaga() {
  yield takeLatest(JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA, initJobInternalData);
  yield takeLatest(JobInternalDataActionTypes.PATCH, patch);
  yield takeLatest(JobInternalDataActionTypes.GET, get);
}

export default JobInternalDataSaga;
