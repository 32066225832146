import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { injectIntl, defineMessages, intlShape } from 'react-intl';

import { FeatureCard } from '@gupy/front-commons';

import { TestsPath } from '../CustomTestRoutes';

const formatDate = (strDate, locale = 'pt-BR') => (new Date(strDate)).toLocaleDateString(locale);

const messages = defineMessages({
  createdAt: {
    id: 'tests-created-at',
    defaultMessage: 'Criado em',
  },
  by: {
    id: 'tests-by',
    defaultMessage: 'Por',
  },
  question: {
    id: 'tests-question',
    defaultMessage: '{questionsCount, plural, one {questão} other {questões}}',
  },
  none: {
    id: 'tests-none',
    defaultMessage: 'Nenhuma',
  },
  minutes: {
    id: 'tests-minutes',
    defaultMessage: 'minutos',
  },
  time: {
    id: 'tests-time',
    defaultMessage: 'Tempo',
  },
  inlimited: {
    id: 'tests-inlimited',
    defaultMessage: 'ilimitado',
  },
  actions: {
    id: 'tests-actions',
    defaultMessage: '{text, select, Favoritar {Favoritar} Duplicar {Duplicar} Editar {Editar} Excluir {Excluir} other {} }',
  },
});

const translatedActionsNames = (actions, intl) => actions.map(({ text, ...rest }) => ({
  text: intl.formatMessage(messages.actions, { text }), ...rest,
}));

const CustomTestCard = ({ actions, checked, customTest, onCheck, intl, ...props }) => (
  <FeatureCard
    checked={checked}
    onCheck={onCheck}
    actions={translatedActionsNames(actions, intl)}
    header={(
      <NavLink to={`${TestsPath}/${customTest.id}/questions`} >
        <h6>{customTest.title}</h6>
      </NavLink>
    )}
    {...props}
  >
    <div className="testMeta">
      <div className="testMeta-item">
        <span>{intl.formatMessage(messages.createdAt)}:</span>
        <span>{formatDate(customTest.createdAt)}</span>
      </div>
      <div className="testMeta-item">
        <span>{intl.formatMessage(messages.by)}:</span>
        <span>{customTest.createdBy}</span>
      </div>
    </div>
    <div className="testNumbers">
      <div className="testNumbers-item">
        {customTest.questionsCount > 0 ? ([
          <div key={`customTest-${customTest.id}-numbers1`} className="testNumbers-number">{customTest.questionsCount}</div>,
          <div key={`customTest-${customTest.id}-text1`} className="testNumbers-text">
            {intl.formatMessage(messages.question, { questionsCount: customTest.questionsCount })}
          </div>,
        ]) : ([
          <div key={`customTest-${customTest.id}-numbers1`} className="testNumbers-text">{intl.formatMessage(messages.none)}</div>,
          <div key={`customTest-${customTest.id}-text1`} className="testNumbers-text">{intl.formatMessage(messages.question, { questionsCount: 1 })}</div>,
        ])}
      </div>
      <div className="testNumbers-item">
        {customTest.timeLimit ? ([
          <div key={`customTest-${customTest.id}-numbers2`} className="testNumbers-number">{customTest.timeLimit.toString().padStart(2, '0')}</div>,
          <div key={`customTest-${customTest.id}-text2`} className="testNumbers-text">{intl.formatMessage(messages.minutes)}</div>,
        ]) : ([
          <div key={`customTest-${customTest.id}-numbers2`} className="testNumbers-text">{intl.formatMessage(messages.time)}</div>,
          <div key={`customTest-${customTest.id}-text2`} className="testNumbers-text">{intl.formatMessage(messages.inlimited)}</div>,
        ])}
      </div>
    </div>
  </FeatureCard>
);
CustomTestCard.propTypes = {
  actions: PropTypes.array,
  checked: PropTypes.bool,
  customTest: PropTypes.object.isRequired,
  onCheck: PropTypes.func,
  intl: intlShape,
};
CustomTestCard.defaultProps = {
  actions: [],
  checked: false,
  onCheck: null,
};

export default injectIntl(CustomTestCard);
