import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('JobInternalData',
  'CHANGE_FORM',
  'CLEAR_FORM',
  'TOGGLE_VACANCIES_CODES_IS_OPEN',
  asyncActionType('INIT_JOB_INTERNAL_DATA'),
  asyncActionType('GET'),
  asyncActionType('POST'),
  asyncActionType('PATCH'));
