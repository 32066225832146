import { combineReducers } from 'redux';

import { JobOfferTemplateActionTypes } from './Actions';

const initialModelForm = {
  allowReply: false,
  body: '',
  createdAt: '',
  id: undefined,
  name: '',
  public: false,
  subject: 'JobOffer',
  type: 'job_offer',
  fileName: '',
  files: [],
  userId: null,
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.GET:
  case JobOfferTemplateActionTypes.POST:
  case JobOfferTemplateActionTypes.PUT:
    return true;
  case JobOfferTemplateActionTypes.GET_SUCCESS:
  case JobOfferTemplateActionTypes.GET_FAIL:
  case JobOfferTemplateActionTypes.POST_SUCCESS:
  case JobOfferTemplateActionTypes.POST_FAIL:
  case JobOfferTemplateActionTypes.PUT_SUCCESS:
  case JobOfferTemplateActionTypes.PUT_FAIL:
    return false;
  default:
    return state;
  }
};

export const searched = (state = false, action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.SEARCH:
    return true;
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.POST_SUCCESS:
  case JobOfferTemplateActionTypes.PUT_SUCCESS:
    return initialModelForm;
  case JobOfferTemplateActionTypes.GET_SUCCESS:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  case JobOfferTemplateActionTypes.CLEAR:
    return initialModelForm;
  default:
    return state;
  }
};

export const validation = (state = {}, action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.POST:
  case JobOfferTemplateActionTypes.PUT:
    return {};
  case JobOfferTemplateActionTypes.POST_FAIL:
  case JobOfferTemplateActionTypes.PUT_FAIL:
  case JobOfferTemplateActionTypes.GET_ALL_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.GET_ALL:
  case JobOfferTemplateActionTypes.GET:
  case JobOfferTemplateActionTypes.TYPE_SEARCH:
  case JobOfferTemplateActionTypes.SEARCH:
    return true;
  case JobOfferTemplateActionTypes.GET_ALL_SUCCESS:
  case JobOfferTemplateActionTypes.GET_SUCCESS:
  case JobOfferTemplateActionTypes.GET_ALL_FAIL:
  case JobOfferTemplateActionTypes.GET_FAIL:
  case JobOfferTemplateActionTypes.SEARCH_SUCCESS:
  case JobOfferTemplateActionTypes.SEARCH_FAIL:
    return false;
  default:
    return state;
  }
};

export const jobOfferTemplates = (state = [], action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.GET_ALL:
  case JobOfferTemplateActionTypes.SEARCH:
  case JobOfferTemplateActionTypes.GET_ALL_FAIL:
    return [];
  case JobOfferTemplateActionTypes.GET_ALL_SUCCESS:
    return action.jobOfferTemplates;
  case JobOfferTemplateActionTypes.SEARCH_SUCCESS:
    return action.jobOfferTemplates.length > 0 ? action.jobOfferTemplates : [];
  default:
    return state;
  }
};

export const variables = (state = [], action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.GET_ALL_SUCCESS:
  case JobOfferTemplateActionTypes.GET_VARIABLES_SUCCESS:
    return action.variables;
  case JobOfferTemplateActionTypes.GET_ALL_FAIL:
  case JobOfferTemplateActionTypes.GET_VARIABLES_FAIL:
    return [];
  default:
    return state;
  }
};

export const isLoadedSelectedTemplate = (state = false, action) => {
  switch (action.type) {
  case JobOfferTemplateActionTypes.GET_SUCCESS:
  case JobOfferTemplateActionTypes.GET_FAIL:
    return true;
  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  isLoading,
  searched,
  validation,
  jobOfferTemplates,
  variables,
  isLoadedSelectedTemplate,
});
