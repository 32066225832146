const GroupTypeEnum = {
  recruitment: 'recruitment',
  management: 'management',
  noGroup: 'no-group',
  admission: 'admission',
};

const Branches = {
  admission: 'admission',
};

const Groups = {
  [Branches.admission]: {
    admission_view_only_group: 'admission_view_only_group',
  },
};

const TipMessages = {
  [Branches.admission]: 'tipMessageAdmissionViewOnly',
};

export { GroupTypeEnum, Groups, Branches, TipMessages };
