import { BaseForm } from '@gupy/front-commons';

class JobStepRegisterForm extends BaseForm {
  constructor() {
    super({
      endDate: {
        validate: {
          requiredIf: {
            fieldName: 'hasEndDate',
            value: true,
          },
          validateDateIf: {
            fieldName: 'hasEndDate',
            value: true,
          },
        },
      },
    });
  }
}

export default JobStepRegisterForm;
