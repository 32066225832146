import update from 'react-addons-update';
import { combineReducers } from 'redux';

import ProfileTestActionTypes from '../../constants/ProfileTest/ProfileTestActionTypes';
import ProfileTestStep from '../../constants/ProfileTest/ProfileTestStep';

export const currentStep = (state = null, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET_SUCCESS: {
    const { profileTest } = action;
    if (profileTest.testUrl) {
      return {};
    }
    const ret = {
      type: ProfileTestStep.introduction,
      index: 0,
      slideLastIndex: profileTest.slideQuestions.length - 1,
      sortLastIndex: profileTest.sortQuestions.length - 1,
      isClosed: profileTest.closed,
    };
    let fallowNext = true;

    if (ret.isClosed) {
      return ret;
    }

    for (let i = 0; i < profileTest.slideQuestions.length; i += 1) {
      if (!profileTest.slideQuestions[i].answered) {
        if (i > 0) {
          ret.type = ProfileTestStep.slideQuestion;
          ret.index = i;
        }
        fallowNext = false;
        break;
      }
    }

    if (fallowNext) {
      ret.type = ProfileTestStep.sortIntro;
      ret.index = 0;

      for (let i = 0; i < profileTest.sortQuestions.length; i += 1) {
        if (!profileTest.sortQuestions[i].answered) {
          if (i > 0) {
            ret.type = ProfileTestStep.sortQuestion;
            ret.index = i;
          }
          fallowNext = false;
          break;
        }
      }
    }

    if (fallowNext) {
      ret.type = ProfileTestStep.conclusion;
      ret.index = 0;
    }

    return ret;
  }
  case ProfileTestActionTypes.TOPIC_GO_NEXT: {
    let type;

    if (state.type === ProfileTestStep.introduction) {
      type = ProfileTestStep.slideIntro;
    } else if (state.type === ProfileTestStep.slideIntro) {
      type = ProfileTestStep.slideQuestion;
    } else if (state.type === ProfileTestStep.sortIntro) {
      type = ProfileTestStep.sortQuestion;
    }

    return {
      ...state,
      type,
    };
  }
  case ProfileTestActionTypes.SAVE_QUESTION_SUCCESS: {
    const nextIndex = state.index + 1;

    if (state.type === ProfileTestStep.slideQuestion) {
      if (nextIndex > state.slideLastIndex) {
        return {
          ...state,
          type: ProfileTestStep.sortIntro,
          index: 0,
        };
      }
    } else if (state.type === ProfileTestStep.sortQuestion) {
      if (nextIndex > state.sortLastIndex) {
        return {
          ...state,
          type: ProfileTestStep.conclusion,
          index: 0,
        };
      }
    }

    return {
      ...state,
      index: nextIndex,
    };
  }
  case ProfileTestActionTypes.GO_PREVIOUS: {
    const previousIndex = state.index - 1;

    if (state.type === ProfileTestStep.slideIntro) {
      if (previousIndex === -1) {
        return {
          ...state,
          type: ProfileTestStep.introduction,
          index: 0,
        };
      }
    } else if (state.type === ProfileTestStep.slideQuestion) {
      if (previousIndex === -1) {
        return {
          ...state,
          type: ProfileTestStep.slideIntro,
          index: 0,
        };
      }
    } else if (state.type === ProfileTestStep.sortIntro) {
      if (previousIndex === -1) {
        return {
          ...state,
          type: ProfileTestStep.slideQuestion,
          index: state.slideLastIndex,
        };
      }
    } else if (state.type === ProfileTestStep.sortQuestion) {
      if (previousIndex === -1) {
        return {
          ...state,
          type: ProfileTestStep.sortIntro,
          index: 0,
        };
      }
    } else if (state.type === ProfileTestStep.conclusion) {
      return {
        ...state,
        type: ProfileTestStep.sortQuestion,
        index: state.sortLastIndex,
      };
    }

    return {
      ...state,
      index: previousIndex,
    };
  }
  default:
    return state;
  }
};

export const diagnostic = (state = {}, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET_DIAGNOSTIC_SUCCESS:
    return action.payload;
  case ProfileTestActionTypes.CHANGE_GROUP:
  case ProfileTestActionTypes.CHANGE_GROUP_ITEM:
    return {};
  default:
    return state;
  }
};

export const matching = (state = {}, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET_MATCHING_SUCCESS:
    return action.payload;
  case ProfileTestActionTypes.GET_MATCHING_FAIL:
    return {};
  default:
    return state;
  }
};

export const isLoadingMatching = (state = false, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET_MATCHING:
    return true;
  case ProfileTestActionTypes.GET_MATCHING_SUCCESS:
  case ProfileTestActionTypes.GET_MATCHING_FAIL:
    return false;
  default:
    return state;
  }
};

export const profileTest = (state = {}, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET:
  case ProfileTestActionTypes.GET_AVERAGE:
  case ProfileTestActionTypes.GET_USER_TEST:
    return {};
  case ProfileTestActionTypes.GET_SUCCESS:
  case ProfileTestActionTypes.GET_AVERAGE_SUCCESS:
  case ProfileTestActionTypes.GET_USER_TEST_SUCCESS:
    return action.profileTest;
  case ProfileTestActionTypes.CHANGE_ANSWER: {
    if (action.currentStep.type === ProfileTestStep.slideQuestion) {
      return {
        ...state,
        slideQuestions: state.slideQuestions.map((question, questionIndex) => {
          if (questionIndex === action.currentStep.index) {
            return {
              ...question,
              answered: true,
              choices: question.choices.map((answer, answerIndex) => {
                if (answerIndex === action.answerIndex) {
                  return {
                    ...answer,
                    answer: action.answerValue,
                  };
                }

                return {
                  ...answer,
                  answer: 3 - action.answerValue,
                };
              }),
            };
          }

          return question;
        }),
      };
    }

    if (action.currentStep.type === ProfileTestStep.sortQuestion) {
      return {
        ...state,
        sortQuestions: state.sortQuestions.map((question, questionIndex) => {
          if (questionIndex === action.currentStep.index) {
            const choice = question.choices[action.answerValue.dragIndex];
            return update(question, {
              answered: {
                $set: true,
              },
              choices: {
                $splice: [
                  [action.answerValue.dragIndex, 1],
                  [action.answerValue.hoverIndex, 0, choice],
                ],
              },
            });
          }

          return question;
        }),
      };
    }

    return state;
  }
  default:
    return state;
  }
};

export const isLoading = (state = true, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET:
  case ProfileTestActionTypes.GET_AVERAGE:
  case ProfileTestActionTypes.GET_DIAGNOSTIC:
  case ProfileTestActionTypes.CLOSE_TEST:
    return true;
  case ProfileTestActionTypes.GET_SUCCESS:
  case ProfileTestActionTypes.GET_FAIL:
  case ProfileTestActionTypes.GET_AVERAGE_SUCCESS:
  case ProfileTestActionTypes.GET_AVERAGE_FAIL:
  case ProfileTestActionTypes.GET_DIAGNOSTIC_SUCCESS:
  case ProfileTestActionTypes.CLOSE_TEST_SUCCESS:
  case ProfileTestActionTypes.CLOSE_TEST_FAIL:
    return false;
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.SAVE_QUESTION:
  case ProfileTestActionTypes.FINISH_TEST:
    return true;
  case ProfileTestActionTypes.SAVE_QUESTION_SUCCESS:
  case ProfileTestActionTypes.SAVE_QUESTION_FAIL:
  case ProfileTestActionTypes.FINISH_TEST_SUCCESS:
  case ProfileTestActionTypes.FINISH_TEST_FAIL:
    return false;
  default:
    return state;
  }
};

const profileTestStatisticsInitialState = {
  isTestStatisticsLoaded: false,
};

export const statistics = (state = profileTestStatisticsInitialState, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS:
    return {
      ...state,
      isTestStatisticsLoaded: false,
    };
  case ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS_SUCCESS: {
    return {
      ...state,
      ...action.payload,
      isTestStatisticsLoaded: true,
    };
  }
  case ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS_FAIL:
    return {
      ...state,
      error: action.error,
      isTestStatisticsLoaded: false,
    };

  default:
    return state;
  }
};

export const group = (state = 'company', action) => {
  switch (action.type) {
  case ProfileTestActionTypes.CHANGE_GROUP:
    return action.payload.group;
  default:
    return state;
  }
};

export const groupItem = (state = '', action) => {
  switch (action.type) {
  case ProfileTestActionTypes.CHANGE_GROUP_ITEM:
    return action.payload.groupItem;

  case ProfileTestActionTypes.CHANGE_GROUP:
    return '';
  default:
    return state;
  }
};

export const shouldDisplayEmptyMessage = (state = false, action) => {
  switch (action.type) {
  case ProfileTestActionTypes.CHANGE_GROUP: {
    if (action.payload.group !== 'company') {
      return false;
    }
    return true;
  }

  case ProfileTestActionTypes.CHANGE_GROUP_ITEM:
  case ProfileTestActionTypes.GET:
  case ProfileTestActionTypes.GET_AVERAGE:
  case ProfileTestActionTypes.GET_DIAGNOSTIC:
  case ProfileTestActionTypes.CLOSE_TEST:
    return false;

  case ProfileTestActionTypes.GET_SUCCESS:
  case ProfileTestActionTypes.GET_FAIL:
  case ProfileTestActionTypes.GET_AVERAGE_SUCCESS:
  case ProfileTestActionTypes.GET_AVERAGE_FAIL:
  case ProfileTestActionTypes.GET_DIAGNOSTIC_SUCCESS:
  case ProfileTestActionTypes.CLOSE_TEST_SUCCESS:
  case ProfileTestActionTypes.CLOSE_TEST_FAIL:
    return true;

  default:
    return state;
  }
};

export default combineReducers({
  matching,
  isLoadingMatching,
  isLoading,
  isSaving,
  currentStep,
  profileTest,
  diagnostic,
  statistics,
  group,
  groupItem,
  shouldDisplayEmptyMessage,
});
