import {
  openJobClosureModal,
  closeJobClosureModal,
  setJobClosureModalStep,
  getFinalJobStepId,
  doNoSendFeedbackEmail,
  getAllTemplateEmails,
  sendFeedbackEmails,
} from './JobClosureAction';
import JobClosureModal from './JobClosureModal';
import JobClosureActionTypes from './JobClosureActionTypes';
import JobClosureReducer from './JobClosureReducer';
import JobClosureSaga from './JobClosureSaga';

const JobClosureAction = {
  openJobClosureModal,
  closeJobClosureModal,
  setJobClosureModalStep,
  getFinalJobStepId,
  doNoSendFeedbackEmail,
  getAllTemplateEmails,
  sendFeedbackEmails,
};

export {
  JobClosureModal,
  JobClosureAction,
  JobClosureReducer,
  JobClosureSaga,
  JobClosureActionTypes,
};
