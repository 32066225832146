import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const EmailTemplateActionTypes = createActionTypes(
  'EmailTemplate',
  asyncActionType('POST'),
  asyncActionType('PUT'),
  asyncActionType('DELETE'),
  asyncActionType('GET'),
  asyncActionType('GET_TYPES'),
  asyncActionType('GET_VARIABLES'),
  asyncActionType('GET_ALL'),
  asyncActionType('GET_ALL_BY_TYPE'),
  asyncActionType('SEARCH'),
  asyncActionType('TYPE_SEARCH'),
  asyncActionType('CHECK_HAS_ASSOCIATED_JOB'),
  'CHECK_HAS_ASSOCIATED_JOB_CLEAR',
  asyncActionType('GET_ASSOCIATED_JOBS'),
  asyncActionType('UNLINK_TEMPLATE_ASSOCIATED_JOBS'),
  'CLEAR',
);

const getAssociatedJobsByTemplateId = (id, {
  limit, offset,
} = {}) => ({
  type: EmailTemplateActionTypes.GET_ASSOCIATED_JOBS,
  id,
  limit,
  offset,
});

const typeSearchEmailTemplates = () => ({
  type: EmailTemplateActionTypes.TYPE_SEARCH,
});

const postEmailTemplate = (payload, redirectUrl, messages) => ({
  type: EmailTemplateActionTypes.POST,
  payload,
  redirectUrl,
  messages,
});

const putEmailTemplate = (payload, redirectUrl, messages) => ({
  type: EmailTemplateActionTypes.PUT,
  payload,
  redirectUrl,
  messages,
});

const deleteEmailTemplate = (id, messages) => ({
  type: EmailTemplateActionTypes.DELETE,
  id,
  messages,
});

const getAllEmailTemplates = ({ publicOnly } = {}) => ({
  type: EmailTemplateActionTypes.GET_ALL,
  publicOnly,
});

const getAllEmailTemplatesByType = emailType => ({
  type: EmailTemplateActionTypes.GET_ALL_BY_TYPE,
  emailType,
});

const getEmailTemplateTypes = () => ({
  type: EmailTemplateActionTypes.GET_TYPES,
});

const getEmailTemplateVariables = () => ({
  type: EmailTemplateActionTypes.GET_VARIABLES,
});

const getEmailTemplate = id => ({
  type: EmailTemplateActionTypes.GET,
  id,
});

const searchEmailTemplates = searchString => ({
  type: EmailTemplateActionTypes.SEARCH,
  searchString,
});

const clearEmailTemplate = () => ({
  type: EmailTemplateActionTypes.CLEAR,
});

const checkEmailTemplateHasAssociatedJob = id => ({
  type: EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB,
  id,
});

const clearAssociatedJob = () => ({
  type: EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_CLEAR,
});

const unlinkTemplateAssociatedJobs = (id, payload, messages) => ({
  type: EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS,
  id,
  payload,
  messages,
});

export {
  EmailTemplateActionTypes,
  postEmailTemplate,
  putEmailTemplate,
  deleteEmailTemplate,
  getAllEmailTemplates,
  getAllEmailTemplatesByType,
  getEmailTemplate,
  getEmailTemplateTypes,
  getEmailTemplateVariables,
  searchEmailTemplates,
  typeSearchEmailTemplates,
  clearEmailTemplate,
  checkEmailTemplateHasAssociatedJob,
  clearAssociatedJob,
  getAssociatedJobsByTemplateId,
  unlinkTemplateAssociatedJobs,
};
