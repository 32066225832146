import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  logo: {
    id: 'gupy-logo',
    defaultMessage: 'Gupy Recrutamento & Seleção, plataforma de contratação para empresas.',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
