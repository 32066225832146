import { combineReducers } from 'redux';
import { TimelineActionTypes } from './TimelineAction';
import JobApplicationActionTypes from '../../../../../constants/Job/JobApplication/JobApplicationActionTypes';

export const isLoadingByProp = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_TIMELINE:
    return true;
  case JobApplicationActionTypes.GET_TIMELINE_SUCCESS:
  case JobApplicationActionTypes.GET_TIMELINE_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLoadingByApplicationId = (state = false, action) => {
  switch (action.type) {
  case TimelineActionTypes.GET_TIMELINE:
    return true;
  case TimelineActionTypes.GET_TIMELINE_SUCCESS:
  case TimelineActionTypes.GET_TIMELINE_FAIL:
    return false;
  default:
    return state;
  }
};

export const timelineEventsByApplicationId = (state = [], action) => {
  switch (action.type) {
  case TimelineActionTypes.GET_TIMELINE_SUCCESS:
    return action.payload;
  case TimelineActionTypes.GET_TIMELINE_FAIL:
    return [];
  default:
    return state;
  }
};

export const lastByApplicationId = (state = null, action) => {
  switch (action.type) {
  case TimelineActionTypes.GET_TIMELINE_SUCCESS:
    return action.applicationId;
  case TimelineActionTypes.GET_TIMELINE_FAIL:
    return null;
  default:
    return state;
  }
};

export const hasError = (state = false, action) => {
  switch (action.type) {
  case TimelineActionTypes.GET_TIMELINE_SUCCESS:
    return false;
  case TimelineActionTypes.GET_TIMELINE_FAIL:
    return true;
  default:
    return state;
  }
};

export default combineReducers({
  isLoadingByProp,
  isLoadingByApplicationId,
  timelineEventsByApplicationId,
  lastByApplicationId,
  hasError,
});
