import axios from 'axios';
import LocalStorageManager from '../../managers/LocalstorageManager';

const MARKETPLACE_APPS_URL = process.env.REACT_APP_MARKETPLACE_API_URL || 'https://marketplace.gupy.io';

const getAuthorizationHeaders = () => {
  const localStorageManager = new LocalStorageManager(window.localStorage);
  const headers = { Authorization: localStorageManager.getCompanyToken() };

  return headers;
};

export default {
  getAppsList() {
    return axios.get(`${MARKETPLACE_APPS_URL}/apps`);
  },
  getAppById(appId) {
    return axios.get(`${MARKETPLACE_APPS_URL}/apps/${appId}`);
  },
  getCompanyAppSettings(appId) {
    const headers = getAuthorizationHeaders();

    return axios.get(`${MARKETPLACE_APPS_URL}/apps/${appId}/options`, { headers, validateStatus: false });
  },
  putCompanyAppSettings(appId, options) {
    const headers = getAuthorizationHeaders();

    return axios.put(`${MARKETPLACE_APPS_URL}/apps/${appId}/options`, options, { headers });
  },
};
