import moment from 'moment';
import { put, call, takeLatest } from 'redux-saga/effects';
import { ACCEPT_COOKIE_FAIL, ACCEPT_COOKIE_REQUEST, ACCEPT_COOKIE_SUCCESS } from './AcceptCookieDialogActions';
import { update } from './AcceptCookieDialogService';

export function* acceptCookie() {
  try {
    yield call(update);
    const cookieAcceptedAt = moment().toISOString();
    yield put({ type: ACCEPT_COOKIE_SUCCESS, payload: cookieAcceptedAt });
  } catch (error) {
    yield put({ type: ACCEPT_COOKIE_FAIL });
  }
}

function* AcceptCookieDialogSaga() {
  yield takeLatest(ACCEPT_COOKIE_REQUEST, acceptCookie);
}

export default AcceptCookieDialogSaga;
