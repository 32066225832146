import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const HomeActionTypes = createActionTypes(
  'Home',
  asyncActionType('GET_CURRENT_USER_EMAIL_DELIVERABILITY'),
);

const getCurrentUserEmailDeliverability = () => ({
  type: HomeActionTypes.GET_CURRENT_USER_EMAIL_DELIVERABILITY,
});

const getCurrentUserEmailDeliverabilitySuccess = ({ isEmailDeliverable }) => ({
  type: HomeActionTypes.GET_CURRENT_USER_EMAIL_DELIVERABILITY_SUCCESS,
  payload: isEmailDeliverable,
});

const getCurrentUserEmailDeliverabilityFail = () => ({
  type: HomeActionTypes.GET_CURRENT_USER_EMAIL_DELIVERABILITY_FAIL,
});

export {
  HomeActionTypes,
  getCurrentUserEmailDeliverability,
  getCurrentUserEmailDeliverabilitySuccess,
  getCurrentUserEmailDeliverabilityFail,
};
