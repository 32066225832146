import apiDV from '../../api/DarthVaderClient';

const consentEnginePath = '/consent-engine/company/user-consent';

export default {
  getConsent(payload) {
    return apiDV.get(`${consentEnginePath}?field=${payload.field}`);
  },
  saveConsent(payload) {
    return apiDV.put(consentEnginePath, payload);
  },
};
