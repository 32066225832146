import api from '../../api/DarthVaderClient';

const apiPath = '/interview-script/companies/interview-scripts/v2';

export default {
  create(payload) {
    return api.post(apiPath, payload);
  },
  createAnswer({
    applicationId,
    applicationStepId,
    payload,
    questionId,
  }) {
    return api.post(`${apiPath}/applications/${applicationId}/application-steps/${applicationStepId}/questions/${questionId}/answers`, payload);
  },
  get(id) {
    return api.get(`${apiPath}/${id}`);
  },
  getByApplicationAndStepId(applicationId, applicationStepId) {
    return api.get(`${apiPath}/applications/${applicationId}/application-steps/${applicationStepId}`);
  },
  list() {
    return api.get(apiPath);
  },
  delete(id) {
    return api.delete(`${apiPath}/${id}`);
  },
  listByApplicationId(applicationId) {
    return api.get(`${apiPath}/applications/${applicationId}`);
  },
  simpleList() {
    return api.get(`${apiPath}/list`);
  },
  update(payload) {
    const script = { ...payload };
    const scriptId = script.id;
    delete script.id;
    return api.put(`${apiPath}/${scriptId}`, script);
  },
  updateAnswer({
    answerId,
    applicationId,
    applicationStepId,
    payload,
    questionId,
  }) {
    return api.patch(`${apiPath}/applications/${applicationId}/application-steps/${applicationStepId}/questions/${questionId}/answers/${answerId}`, payload);
  },
};
