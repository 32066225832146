import { call, put, takeLatest } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';

import Service from './Service';
import { WhatsappBusinessTemplateActionTypes } from './Actions';

export function* getTemplates() {
  try {
    const { body: { data } } = yield call(Service.getTemplates);

    yield put({
      type: WhatsappBusinessTemplateActionTypes.GET_TEMPLATES_SUCCESS,
      templates: data,
    });
  } catch (error) {
    yield put({
      type: WhatsappBusinessTemplateActionTypes.GET_TEMPLATES_FAIL,
    });
  }
}

export function* saveTemplate(action) {
  const { messages, payload } = action;

  try {
    if (payload.id) {
      yield call(Service.updateTemplate, payload);
    } else {
      yield call(Service.createTemplate, payload);
    }

    const toastMessage = payload.isDraft
      ? messages.toastSaveDraft
      : messages.toastSaveTemplate;

    yield put({
      type: WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE_SUCCESS,
      toast: buildToast(toastMessage, ToastTypes.success),
    });

    yield call(getTemplates);
  } catch (error) {
    yield put({
      type: WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE_FAIL,
      toast: buildToast(messages.toastSaveTemplateError, ToastTypes.error),
    });
  }
}

function* WhatsappBusinessTemplateSaga() {
  yield takeLatest(WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE, saveTemplate);
  yield takeLatest(WhatsappBusinessTemplateActionTypes.GET_TEMPLATES, getTemplates);
}

export default WhatsappBusinessTemplateSaga;
