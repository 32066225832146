import { createElement } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  component: PropTypes.func.isRequired,
};

const HomeListItemTemplate = props => createElement(props.component, props);

HomeListItemTemplate.propTypes = propTypes;

export default HomeListItemTemplate;
