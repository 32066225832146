import React from 'react';
import { Tip } from '@gupy/design-system';
import PropTypes from 'prop-types';

import isClickOrEnterKeyEvent from '../../../../helpers/isClickOrEnterKeyEvent';

import childAppPropTypes from '../../../../prop-types/childAppPropTypes';

const ChildAppErrorTip = ({
  childApp,
  childAppName,
  handleChildAppSyncRestartSubmit,
  handleChildAppDesyncRestartSubmit,
}) => {
  const handleChildAppSyncRestart = ({ keyCode, type }) => {
    if (!isClickOrEnterKeyEvent({ keyCode, type })) return;

    handleChildAppSyncRestartSubmit(
      childApp.id,
      childAppName,
    );
  };

  const handleChildAppDesyncRestart = ({ keyCode, type }) => {
    if (!isClickOrEnterKeyEvent({ keyCode, type })) return;

    handleChildAppDesyncRestartSubmit(
      childApp.id,
      childAppName,
    );
  };

  const tipActionHandlers = {
    handleChildAppSyncRestart,
    handleChildAppDesyncRestart,
  };

  const {
    type,
    showIcon,
    text,
    actionText,
    actionHandler,
  } = childApp.getTipData();

  return (
    <tr className={`job-board-panel-rsc-child-apps__table-row-tip ${childApp.status}`}>
      <td className="actions" colSpan="4">
        <Tip
          id={`child-app-tip-${childApp.id}`}
          type={type}
          text={(
            <span>
              {`${text} `}
              <span
                role="button"
                tabIndex={0}
                onClick={tipActionHandlers[`${actionHandler}`]}
                onKeyDown={tipActionHandlers[`${actionHandler}`]}
                className="clickable"
              >
                {actionText}
              </span>
            </span>
          )}
          {...(showIcon && { showIcon: true })}
        />
      </td>
    </tr>
  );
};

ChildAppErrorTip.propTypes = {
  childApp: childAppPropTypes.isRequired,
  childAppName: PropTypes.string.isRequired,
  handleChildAppSyncRestartSubmit: PropTypes.func.isRequired,
  handleChildAppDesyncRestartSubmit: PropTypes.func.isRequired,
};

export default ChildAppErrorTip;
