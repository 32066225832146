import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { buildToast, getSuggestions, ToastTypes } from '@gupy/front-commons';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import { GenderEnum } from '@gupy/enums/gender-enum';
import { commentStorage } from '../../../containers/Job/JobApplication/storage';
import EngageSurvey from '../../../services/EngageSurvey';
import JobOfferService from '../../../containers/JobOffer/JobOfferService';
import moveApplicationErrorEnum from '../../../constants/MoveApplicationErrorEnum';
import { GET_INTERVIEW_EVENT } from '../../../containers/Job/JobApplication/components/InterviewEvent/InterviewEventActionTypes';
import JobApplicationActionTypes from '../../../constants/Job/JobApplication/JobApplicationActionTypes';
import {
  applicationUndoReproveSuccess,
  bulkDisqualifyFailed,
  bulkDisqualifySucceeded,
  bulkMoveFailed,
  bulkMoveSucceeded,
  disqualifyFailed,
  disqualifySucceeded,
  getAddressSuggestionsSuccess,
  getTimeline as requestGetTimeline,
  hiringFeedbackRequested,
  initFiltersSuccess,
  initJobApplication,
  initJobApplicationCandidateSuccess,
  initJobApplicationSuccess,
  moveFailed,
  moveSucceeded,
  openMoveApplicationModal,
  curriculumChangeApplicationStepSuccess,
  curriculumDisqualifyApplicationSuccess,
  curriculumUndoReproveSuccess,
  changeCommentForm,
  postLinkedRscMemberSucceeded,
  postLinkedRscMemberFailed,
  postUnlinkedRscMemberSucceeded,
  postUnlinkedRscMemberFailed,
} from '../../../actions/Job/JobApplication/JobApplicationAction';
import JobApplicationStatus from '../../../constants/Job/JobApplication/JobApplicationStatus';
import JobService from '../../../services/Job/JobService';
import CompanyService from '../../../services/Company/CompanyService';
import InterviewEventService
from '../../../containers/Job/JobApplication/components/InterviewEvent/InterviewEventService';
import JobApplicationEmailForm from '../../../forms/Job/JobApplication/JobApplicationEmailForm';
import ApplicationTagForm from '../../../forms/Job/JobApplication/ApplicationTagForm';
import JobApplicationCommentForm from '../../../forms/Job/JobApplication/JobApplicationCommentForm';
import TagModalForm from '../../../forms/Job/JobApplication/TagModalForm';
import history from '../../../history';
import CurriculumHistoryActionTypes from '../../../constants/Job/JobApplication/CurriculumHistoryActionTypes';
import GeolocationService from '../../../services/Geolocation/GeolocationService';
import WhatsSetupService from '../../../containers/WhatsAppSetup/WhatsAppSetupService';
import featuresFlagsEnum from '../../../containers/Authentication/FeaturesFlagsEnum';
import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';
import FeebackAnalyticsInfoTypes from '../../../constants/Job/JobApplication/FeebackAnalyticsInfoTypes';
import FeedbackAnalyticsService from '../../../services/Job/FeedbackInfoService/FeedbackInfoService';
import JobApplicationCurrentStepType from '../../../constants/Job/JobApplication/JobApplicationCurrentStepType';
import RelocateCandidateActionTypes
from '../../../containers/Job/JobApplication/components/RelocateCandidate/RelocateCandidateActionTypes';
import { HiringInformationActionTypes }
from '../../../containers/Job/JobApplication/components/HiringInformationModal/HiringInformationAction';

export const getJobApplication = state => state.reducers.JobApplication;
export const getAuthentication = state => state.reducers.Authentication;
const buildCustomTestIds = steps => steps
  .filter(({ testId }) => testId && !isNaN(testId))
  .map(({ testId }) => parseInt(testId, 10));
const buildTestProviders = steps => steps
  .filter(({ type, newTestId }) => type === JobStepTypes.online && !!newTestId)
  .map(({ name, newTestId }) => ({ id: newTestId, title: name }));

const sendEmailSuccessEventToGA = (payload) => {
  const emailCount = payload
    && payload.applications
    && payload.applications.length;

  if (emailCount) {
    GoogleAnalyticsHelper.sendEvent({
      category: 'CANDIDATE_EMAIL',
      action: 'SEND_SUCESS',
      label: emailCount === 1 ? 'SINGLE' : 'MULTIPLE',
      value: emailCount,
    });
  }
};

const adaptSteps = steps => (
  steps
    .sort((currentStep, nextStep) => currentStep.order - nextStep.order)
    .map(step => ({
      id: step.id,
      name: step.name,
      type: step.type,
      testId: step.testId,
      newTestId: step.newTestId,
      endDate: step.endDate,
    }))
);

const adaptStatusList = applicantCount => (
  applicantCount.applicantCountByStatus
    .map(status => ({
      name: status.status,
      applicationsCount: status.count,
    }))
);

const getApplicantCounter = applicantCountBySteps => (
  (step) => {
    const emptyCount = 0;
    const result = applicantCountBySteps.find(applicantCountStep => (
      applicantCountStep.stepId === step.id
    ));
    return (result && result.count) ? result.count : emptyCount;
  }
);

const getApplicationsToBeIncludedInSearch = ({ applicationsNotIndexed, currentStep }) => {
  const currentStepKey = currentStep.id ? currentStep.id : currentStep.type;
  const hasApplicationsNotIndexed = !!applicationsNotIndexed[`${currentStepKey}`];
  return hasApplicationsNotIndexed ? applicationsNotIndexed[`${currentStepKey}`].applicationIds : [];
};

const getApplicationsToBeExcludedInSearch = ({ applicationsNotIndexed, currentStep }) => {
  const currentStepKey = currentStep.id ? currentStep.id : currentStep.type;
  if (!currentStepKey || currentStepKey === JobApplicationCurrentStepType.all) return [];
  const recentlyChangedSteps = Object.keys(applicationsNotIndexed);
  return recentlyChangedSteps
    .filter(step => parseInt(step, 10) !== currentStepKey)
    .reduce((currentIds, stepKey) => {
      const { applicationIds } = applicationsNotIndexed[`${stepKey}`];
      return applicationIds && applicationIds.length > 0 ? applicationIds : currentIds;
    }, []);
};

export const getDataForSelectionProcess = (state) => {
  const {
    job,
    filter,
    pagination,
    tableOrder,
    currentStep,
    applicationsNotIndexed,
  } = getJobApplication(state);

  const params = { applicationsNotIndexed, currentStep };
  const includeApplicationIds = getApplicationsToBeIncludedInSearch(params);
  const excludeApplicationIds = getApplicationsToBeExcludedInSearch(params);

  return {
    filter,
    pagination,
    tableOrder,
    currentStep,
    jobId: job.id,
    includeApplicationIds,
    excludeApplicationIds,
  };
};

function additionalQuestionsV2Adapter(schema) {
  const listSchema = schema.listSchemas[0];
  if (schema && listSchema) {
    const questions = listSchema.jsonSchema.properties;
    const { uiSchema } = listSchema;
    return Object.keys(questions).map((questionId) => {
      const question = questions[questionId];
      const additionalQuestionDescription = document.createElement('div');
      additionalQuestionDescription.innerHTML = question.title;
      return {
        id: questionId,
        questionType: uiSchema[questionId]['ui:field'],
        description: additionalQuestionDescription.innerText,
        options: question.items ? question.items.enum : question.enum,
      };
    });
  }
  return schema;
}

function isSelectionProcessClosedError(error) {
  if (!error.body) return false;

  const {
    cannotHireInCanceledJob,
    cannotHireInClosedSelectionProcess,
    cannotMoveInClosedSelectionProcess,
  } = moveApplicationErrorEnum;

  const isError = [
    cannotHireInCanceledJob,
    cannotHireInClosedSelectionProcess,
    cannotMoveInClosedSelectionProcess,
  ].some(enumError => error.body.message === enumError);

  return error.body && isError;
}

export function* handleMoveApplicationFailed(error, elseAction) {
  if (isSelectionProcessClosedError(error)) {
    yield put(openMoveApplicationModal(error.body.message));
  } else {
    yield put(elseAction);
  }
}

function buildNewFilterData(filtersResponse) {
  const hasFilters = filtersResponse.body && !!filtersResponse.body.length;
  const applicationsFilters = hasFilters ? { ...filtersResponse.body[0] } : {};
  return applicationsFilters;
}

const APPLICATIONS_NOT_INDEXED_KEY = 'applications_not_indexed';

export function* saveApplicationsNotIndexed() {
  const { applicationsNotIndexed } = yield select(getJobApplication);
  localStorage.setItem(APPLICATIONS_NOT_INDEXED_KEY, JSON.stringify(applicationsNotIndexed));
}

export function getApplicationsNotIndexed() {
  const serializedState = localStorage.getItem(APPLICATIONS_NOT_INDEXED_KEY);
  return JSON.parse(serializedState) || {};
}

export function* initApplications(action) {
  try {
    const applicationsNotIndexed = getApplicationsNotIndexed();
    const { jobId, step: currentStep, pagination, filter, order: tableOrder } = action;

    const params = { applicationsNotIndexed, currentStep };
    const includeApplicationIds = getApplicationsToBeIncludedInSearch(params);
    const excludeApplicationIds = getApplicationsToBeExcludedInSearch(params);

    const payload = {
      jobId,
      currentStep,
      pagination,
      filter,
      tableOrder,
      includeApplicationIds,
      excludeApplicationIds,
    };

    const [
      jobResponse,
      applicantCountResponse,
      stepsResponse,
      applicationResponse,
      filtersResponse,
    ] = yield all([
      call(JobService.get, jobId),
      call(JobService.getApplicantCount, jobId),
      call(JobService.getJobSteps, jobId),
      call(JobService.getApplications, payload),
      currentStep.id
        ? call(JobService.getStepFilters, jobId, currentStep.id)
        : call(JobService.getFilters, jobId),
    ]);

    const { applicantCountBySteps } = applicantCountResponse.body;
    const countApplicantByStep = getApplicantCounter(applicantCountBySteps);
    const steps = adaptSteps(stepsResponse.body)
      .map(step => ({ ...step, applicationsCount: countApplicantByStep(step) }));

    const statusList = adaptStatusList(applicantCountResponse.body);
    const applicationsFilters = buildNewFilterData(filtersResponse);
    const job = jobResponse.body;

    const GA_CATEGORY_KEY = 'JOB_APPLICATION_STATUSES';
    GoogleAnalyticsHelper.sendEvent({
      category: GA_CATEGORY_KEY,
      action: job.status,
    });

    yield put(initJobApplicationSuccess(
      {
        steps,
        statusList,
        job,
        applicationsSummary: applicationResponse.body.summary,
        summary: applicationResponse.body.summary,
        applications: applicationResponse.body.data,
        filters: [],
        applicationsFilters,
        excludedApplications: applicantCountResponse.body.excludedApplications,
        applicationsNotIndexed,
      },
    ));
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.INIT_FAIL,
      error,
    });
  }
}

export function* initFilters() {
  try {
    const ALLOWED_QUESTION_TYPES = ['choice_answer', 'check_answer'];
    const { job: { id: jobId }, steps } = yield select(getJobApplication);
    const registerStepId = parseInt(steps[0].id, 10);
    const jobCustomTests = buildCustomTestIds(steps);
    const jobExternalTests = buildTestProviders(steps);

    const { currentUser } = yield select(getAuthentication);
    const isAdditionalQuestionsEnabled = currentUser && currentUser.featureFlags
      .includes(featuresFlagsEnum.additionalQuestions);

    const [
      criteriasResponse,
      jobAddressResponse,
      availableFiltersResponse,
      instructionResponse,
      coursesListResponse,
      languageListResponse,
      languageLevelsListResponse,
      additionalQuestionsListResponse,
      tagsListResponse,
      customTestResponse,
      messageTypeListResponse,
    ] = yield all([
      JobService.getCriterias(jobId),
      JobService.getJobAddressToFilter(jobId),
      JobService.getAvailableFilters(),
      JobService.getSchoolingLevels(),
      JobService.getCoursesList(),
      JobService.getLanguageList(),
      JobService.getLanguageLevelsList(),
      JobService.getAdditionalQuestions(
        jobId,
        registerStepId,
        ALLOWED_QUESTION_TYPES,
      ),
      CompanyService.getTagsNameByJob(jobId),
      JobService.getCustomTests(),
      JobService.getMessageTypeList(),
    ]);

    const customTests = customTestResponse.body.data
      .filter(({ id }) => jobCustomTests.includes(id));

    const genderList = Object.entries(GenderEnum).map(([label, value]) => ({ value, label }));

    yield put(initFiltersSuccess(
      {
        additionalQuestionsList: isAdditionalQuestionsEnabled ? additionalQuestionsV2Adapter(
          additionalQuestionsListResponse.body,
        ) : additionalQuestionsListResponse.body.data,
        availableFilters: availableFiltersResponse.body.data,
        coursesList: coursesListResponse.body,
        criterias: criteriasResponse.body.data,
        customTests,
        externalTests: jobExternalTests,
        genderList,
        jobAddress: jobAddressResponse.body.address,
        languageLevelsList: languageLevelsListResponse.body.data,
        languageList: languageListResponse.body.data,
        schoolingLevels: instructionResponse.body.data,
        tagsList: tagsListResponse.body,
        messageTypeList: messageTypeListResponse.body.data,
      },
    ));
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.INIT_FILTERS_FAIL,
      error,
    });
  }
}

function* initApplicationsReport(action) {
  try {
    const { jobId } = action;

    const [
      jobResponse,
      analyticsUniqueDataResponse,
      candidatesByStepDataResponse,
      ageGroupDataResponse,
      genderDataResponse,
      referredCandidatesDataResponse,
      internalCandidatesDataResponse,
      degreeLevelDataResponse,
      stateDataResponse,
      sharingChannelDataResponse,
      profileTestDataResponse,
    ] = yield all([
      JobService.get(jobId),
      JobService.getAnalyticsUniqueData(jobId),
      JobService.getCandidatesByStepData(jobId),
      JobService.getAgeGroupData(jobId),
      JobService.getGenderData(jobId),
      JobService.getReferredCadidatesData(jobId),
      JobService.getInternalCandidatesData(jobId),
      JobService.getDegreeLevelData(jobId),
      JobService.getStateData(jobId),
      JobService.getSharingChannelData(jobId),
      JobService.getProfileTestData(jobId),
    ]);

    yield put({
      type: JobApplicationActionTypes.INIT_REPORT_SUCCESS,
      job: jobResponse.body,
      analyticsUniqueData: analyticsUniqueDataResponse.body,
      candidatesByStepData: candidatesByStepDataResponse.body,
      ageGroupData: ageGroupDataResponse.body,
      genderData: genderDataResponse.body,
      referredCandidatesData: referredCandidatesDataResponse.body,
      internalCandidatesData: internalCandidatesDataResponse.body,
      degreeLevelData: degreeLevelDataResponse.body,
      stateData: stateDataResponse.body,
      sharingChannelData: sharingChannelDataResponse.body,
      profileTestData: profileTestDataResponse.body,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.INIT_REPORT_FAIL,
      error,
    });
  }
}

function* initDisapprovalReasons() {
  try {
    const response = yield call(JobService.getDisapprovalReasons);

    yield put({
      type: JobApplicationActionTypes.INIT_DISAPPROVAL_REASONS_SUCCESS,
      disapprovalReasons: response.body,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.INIT_DISAPPROVAL_REASONS_FAIL,
      error,
    });
  }
}

function getMoveInterval(lastMove) {
  const now = new Date();
  const difference = (now - lastMove) / 1000;
  const intervalLabels = ['0-10', '11-20', '21-30', '31-40', '41-50'];
  const labelIndex = Math.floor(difference / 10);
  return labelIndex < intervalLabels.length ? intervalLabels[`${labelIndex}`] : '51-60';
}

export function* sendStepViewAfterMoveToGA({ currentStep }) {
  const isVirtualStep = !currentStep.id;
  if (isVirtualStep) return;

  const { candidateMoveTimeByStep } = yield select(getJobApplication);
  const hasMoveToStep = candidateMoveTimeByStep[currentStep.id];

  if (hasMoveToStep) {
    const { lastUpdate } = candidateMoveTimeByStep[currentStep.id];
    const interval = getMoveInterval(new Date(lastUpdate));

    const GA_CATEGORY = 'STEP_VIEW_AFTER_CANDIDATE_MOVE';
    GoogleAnalyticsHelper.sendEvent({
      category: GA_CATEGORY,
      action: `${interval} seconds`,
    });
  }
}

function* getApplicationEmailMessages(action) {
  try {
    const { jobId, applicationId } = action;

    yield put({
      type: JobApplicationActionTypes.TAB_MESSAGE_IS_LOADING,
    });

    const { body } = yield JobService.getEmailsMessages(jobId, applicationId);

    const emailsMessages = yield Promise.all(body.map(async (thread) => {
      try {
        const { body: interviewEvent } = await InterviewEventService.getByEmailThreadId(thread.id);
        return { ...thread, interviewEvent };
      } catch (_) {
        return thread;
      }
    }));

    yield put({
      type: JobApplicationActionTypes.GET_APPLICATION_EMAIL_MESSAGES_SUCCESS,
      emailsMessages,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_APPLICATION_EMAIL_MESSAGES_FAIL,
      error,
    });
  } finally {
    yield put({
      type: JobApplicationActionTypes.TAB_MESSAGE_IS_LOADED,
    });
  }
}

function* getApplicationHistory(action) {
  const { applicationId } = action;

  try {
    yield put(requestGetTimeline(applicationId));
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_APPLICATION_HISTORY_FAIL,
      error,
    });
  }
}

export function* initApplicationCandidate(action) {
  const {
    jobId,
    applicationId,
    currentStep,
    keepNavigation,
    filter,
    featureFlags,
    shouldDisplayLinkedinRecruiterTab,
    token,
  } = action;

  try {
    const [
      jobResponse,
      stepsResponse,
      applicationResponse,
    ] = yield all([
      JobService.getSummary(jobId),
      JobService.getJobSteps(jobId),
      JobService.getApplication(jobId, applicationId, currentStep, filter),
    ]);

    let rscLinkedinIntegrationChildAppResponse;
    let rscLinkedinIntegrationInMailsResponse;
    let rscLinkedinIntegrationCandidateProfileResponse;
    let isRscLinkedinIntegrationServiceOnError = false;

    if (
      shouldDisplayLinkedinRecruiterTab
      && jobResponse.body
      && jobResponse.body.careerPage
      && jobResponse.body.careerPage.id
      && applicationResponse.body
      && applicationResponse.body.application
      && applicationResponse.body.application.candidate
      && applicationResponse.body.application.candidate.id
    ) {
      const { body: { careerPage: { id: careerPageId } } } = jobResponse;
      const { body: { application: { candidate: { id: candidateId } } } } = applicationResponse;

      try {
        rscLinkedinIntegrationChildAppResponse = yield JobService
          .getRscLinkedinIntegrationChildAppByCareerPageId(token, careerPageId);
      } catch (error) {
        if (error.statusCode !== 404) {
          isRscLinkedinIntegrationServiceOnError = true;
        }
      }

      if (rscLinkedinIntegrationChildAppResponse) {
        const { body: { id: childAppId } } = rscLinkedinIntegrationChildAppResponse;

        [
          rscLinkedinIntegrationInMailsResponse,
          rscLinkedinIntegrationCandidateProfileResponse,
        ] = yield all([
          JobService.getRscLinkedinIntegrationCandidateProfileInMails(
            token,
            candidateId,
            childAppId,
          ),
          (function* getCandidateProfile() {
            try {
              return yield JobService.getRscLinkedinIntegrationCandidateProfile(
                token,
                candidateId,
                childAppId,
              );
            } catch (error) {
              if (error.statusCode !== 404) {
                throw error;
              }
              return null;
            }
          }()),
        ]);
      }
    }

    const comment = yield commentStorage.findByApplicationId(applicationId);
    if (comment) {
      yield put(changeCommentForm(commentStorage.toDTO(comment)));
    }

    const { application } = applicationResponse.body;
    const navigation = keepNavigation ? {} : { application };
    const steps = adaptSteps(stepsResponse.body);

    yield put(initJobApplicationCandidateSuccess({
      steps,
      job: jobResponse.body,
      application,
      navigation,
      featureFlags,
      isRscLinkedinIntegrationServiceOnError,
      rscLinkedinIntegrationChildApp: rscLinkedinIntegrationChildAppResponse
          && rscLinkedinIntegrationChildAppResponse.body
        ? rscLinkedinIntegrationChildAppResponse.body
        : null,
      rscLinkedinIntegrationInMails: rscLinkedinIntegrationInMailsResponse
          && rscLinkedinIntegrationInMailsResponse.body
        ? rscLinkedinIntegrationInMailsResponse.body
        : [],
      rscLinkedinIntegrationCandidateProfile: rscLinkedinIntegrationCandidateProfileResponse
          && rscLinkedinIntegrationCandidateProfileResponse.body
        ? rscLinkedinIntegrationCandidateProfileResponse.body
        : null,
    }));

    yield JobService.postApplicationViewed(
      jobId,
      applicationId,
      {
        candidateId: application.candidate.id,
        currentCandidateStepId: application.jobStep.id,
      },
    );
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.INIT_CANDIDATE_FAIL,
      error,
    });
  }
}

export function* getProfilePagination(action) {
  const { jobId, applicationId, currentStep, filter } = action;

  try {
    const parameters = { jobId, applicationId, currentStep, filter };
    const profilePaginationResponse = yield JobService.getProfilePagination(parameters);

    yield put({
      type: JobApplicationActionTypes.GET_PROFILE_PAGINATION_SUCCESS,
      profilePagination: profilePaginationResponse.body,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_PROFILE_PAGINATION_FAIL,
      error,
    });
  }
}

export function* initCandidateCurriculum(action) {
  const { candidateId, groupFilters } = action;

  try {
    const { body } = yield call(JobService.getCurriculum, candidateId, groupFilters);

    yield put({
      type: JobApplicationActionTypes.INIT_CANDIDATE_CURRICULUM_SUCCESS,
      payload: body.profile,
    });
  } catch (e) {
    throw e;
  }
}

function* getApplications() {
  const {
    jobId,
    filter,
    pagination,
    tableOrder,
    currentStep,
    includeApplicationIds,
    excludeApplicationIds,
  } = yield select(getDataForSelectionProcess);
  return yield JobService.getApplications({
    jobId,
    filter,
    pagination,
    tableOrder,
    currentStep,
    includeApplicationIds,
    excludeApplicationIds,
  });
}

function adaptFiltersToNewExperience(filtersResponse) {
  const hasFilters = filtersResponse.body && filtersResponse.body.length > 0;
  return hasFilters ? { ...filtersResponse.body[0] } : {};
}

function* getFilters() {
  const { job, currentStep } = yield select(getJobApplication);

  const filtersResponse = yield (currentStep && currentStep.id
    ? JobService.getStepFilters(job.id, currentStep.id)
    : JobService.getFilters(job.id));

  return {
    filtersResponse,
    applicationsFilters: adaptFiltersToNewExperience(filtersResponse),
  };
}

export function* reloadFilters() {
  try {
    yield put({
      type: JobApplicationActionTypes.RELOAD_FILTERS,
    });

    const { applicationsFilters } = yield getFilters();

    yield put({
      type: JobApplicationActionTypes.RELOAD_FILTERS_SUCCESS,
      filters: [],
      applicationsFilters,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.RELOAD_FILTERS_FAIL,
      error,
    });
  }
}

export function* reloadApplications() {
  try {
    const applicationsResponse = yield getApplications();
    yield put({
      type: JobApplicationActionTypes.RELOAD_SUCCESS,
      applications: applicationsResponse.body.data,
      applicationsSummary: applicationsResponse.body.summary,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.RELOAD_FAIL,
      error,
    });
  }
}

const URL_TASK_MANAGER = '/companies/task';

function* redirect(url) {
  yield call(history.push, url);
}

export function* curriculumChangeApplicationStep(action) {
  const {
    jobId,
    nextStep,
    applicationId,
    application,
    filter,
    messages,
  } = action;

  try {
    const moveResponse = yield call(JobService.moveApplications, {
      jobId,
      nextStep,
      applicationIds: [applicationId],
      filter,
    });

    const { body } = moveResponse;
    const feedbackRequest = body ? body.feedbackRequest : null;

    yield put(curriculumChangeApplicationStepSuccess({
      jobId,
      applicationId,
      nextStep,
      feedbackRequest,
      messages,
    }));

    yield put({
      type: GET_INTERVIEW_EVENT,
      application: { candidate: application.candidate, jobStep: nextStep },
    });
  } catch (error) {
    if (isSelectionProcessClosedError(error)) {
      yield put(openMoveApplicationModal(error.body.message));
    }
    yield put({
      type: JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_FAIL,
      error,
      toast: messages ? buildToast(messages.candidateNotMoved, ToastTypes.error) : undefined,
    });
  }
}

export function* undoApplicationReprovement(action) {
  const { jobId, currentStep, applications, filter, pagination, messages } = action;
  try {
    for (let i = 0; i < applications.length; i += 1) {
      yield call(
        JobService.undoApplicationReprovement,
        jobId,
        applications[i],
        currentStep.id,
        JobApplicationStatus.inProcess,
      );
    }

    const [
      applicantCountResponse,
      stepsResponse,
    ] = yield all([
      JobService.getApplicantCount(jobId),
      JobService.getJobSteps(jobId),
    ]);

    const statusList = adaptStatusList(applicantCountResponse.body);

    const { applicantCountBySteps } = applicantCountResponse.body;
    const countApplicantByStep = getApplicantCounter(applicantCountBySteps);
    const steps = adaptSteps(stepsResponse.body)
      .map(step => ({ ...step, applicationsCount: countApplicantByStep(step) }));

    yield put(applicationUndoReproveSuccess({
      jobId,
      currentStep,
      filter,
      pagination,
      steps,
      statusList,
      toast: buildToast(messages.success, ToastTypes.success),
      excludedApplications: applicantCountResponse.body.excludedApplications,
    }));
  } catch (error) {
    yield* handleMoveApplicationFailed(error, {
      type: JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_FAIL,
      error,
      applications,
    });
  }
}

export function* curriculumUndoApplicationReprove(action) {
  const { jobId, nextStep, applicationId, messages } = action;

  try {
    yield call(JobService.undoApplicationReprovement,
      jobId,
      applicationId,
      nextStep.id,
      JobApplicationStatus.inProcess);

    yield put(curriculumUndoReproveSuccess({
      jobId,
      applicationId,
      nextStep,
      messages,
    }));
  } catch (error) {
    yield* handleMoveApplicationFailed(error, {
      type: JobApplicationActionTypes.CURRICULUM_UNDO_REPROVE_FAIL,
      error,
      toast: messages ? buildToast(
        messages.undoCandidateReprovalFailed,
        ToastTypes.error,
      ) : undefined,
    });
  }
}

function* curriculumDisqualifyApplication(action) {
  const {
    jobId,
    applicationId,
    feedbackPayload,
    messages,
    disapprovalReason,
    disapprovalReasonObservation,
  } = action;
  try {
    yield JobService.disqualifyApplications({
      jobId,
      applicationIds: [applicationId],
      disapprovalReason,
      disapprovalReasonObservation,
      isAllApplicationsSelected: false,
      feedbackMessage: feedbackPayload,
    });

    yield put(curriculumDisqualifyApplicationSuccess({
      jobId, applicationId, messages, disapprovalReason,
    }));
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.CURRICULUM_DISQUALIFY_APPLICATION_FAIL,
      error,
      toast: messages ? buildToast(messages.candidateNotDisqualified, ToastTypes.error) : undefined,
    });
  }
}

function* sendEmailToSingleApplication(action) {
  const { jobId, messages } = action;
  const form = new JobApplicationEmailForm();
  let cc = [];

  if (action.payload.cc && action.payload.cc !== '') {
    cc = action.payload.cc
      .split(',').map(email => email.trim().toLowerCase());
  }

  const payload = {
    ...action.payload,
    cc,
  };

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_FAIL,
      validation,
    });
  } else {
    try {
      yield JobService.sendApplicationsEmail({ jobId, payload });
      yield call(sendEmailSuccessEventToGA, payload);

      yield put({
        type: JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
        jobId,
        applicationId: payload.applications[0],
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* getSingleApplicantEmails(action) {
  try {
    yield put({
      type: JobApplicationActionTypes.TAB_MESSAGE_IS_LOADING,
    });
    const { jobId, applicationId } = action;
    const { body } = yield JobService.getEmailsMessages(jobId, applicationId);

    const emailsMessages = yield Promise.all(body.map(async (thread) => {
      try {
        const { body: interviewEvent } = await InterviewEventService.getByEmailThreadId(thread.id);
        return { ...thread, interviewEvent };
      } catch (_) {
        return thread;
      }
    }));

    yield put({
      type: JobApplicationActionTypes.GET_SINGLE_APPLICANT_EMAILS_SUCCESS,
      emailsMessages,
    });
  } catch (err) {
    yield put({
      type: JobApplicationActionTypes.GET_SINGLE_APPLICANT_EMAILS_FAIL,
    });
  } finally {
    yield put({
      type: JobApplicationActionTypes.TAB_MESSAGE_IS_LOADED,
    });
  }
}

export function* sendEmailToApplications(action) {
  const { messages } = action;
  const {
    jobId,
    includeApplicationIds,
    filter: { search: searchQuery },
  } = yield select(getDataForSelectionProcess);

  const form = new JobApplicationEmailForm();
  let cc = [];

  if (action.payload.cc && action.payload.cc !== '') {
    cc = action.payload.cc
      .split(',').map(email => email.trim().toLowerCase());
  }

  const payload = {
    ...action.payload,
    cc,
  };

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_FAIL,
      validation,
    });
  } else {
    try {
      yield put({
        type: JobApplicationActionTypes.CLOSE_SEND_EMAIL_MODAL,
        toast: buildToast(messages.sending, ToastTypes.warning),
      });

      yield JobService.sendApplicationsEmail({
        jobId, payload, searchQuery, includeApplicationIds,
      });
      yield call(sendEmailSuccessEventToGA, payload);

      yield put({
        type: JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_SUCCESS,
        toastUpdate: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_FAIL,
        validation: form.validateErrorResponse(error),
        toastUpdate: buildToast(messages.error, ToastTypes.error),
      });
    }
  }
}

function* postApplicationComment(action) {
  const {
    jobId,
    applicationId,
    candidateId,
    currentCandidateStepId,
    modelForm,
    currentStep,
    messages,
  } = action;
  const form = new JobApplicationCommentForm();
  form.populate(modelForm);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobApplicationActionTypes.POST_COMMENT_FAIL,
      validation,
      toast: messages ? buildToast(messages.errorAddComment, ToastTypes.error) : undefined,
    });
  } else {
    const dto = commentStorage.toDTO({ ...modelForm, candidateId });
    const payload = {
      ...dto,
      candidateId,
      currentCandidateStepId,
    };

    try {
      yield JobService.postApplicationComment(jobId, applicationId, payload);
      yield commentStorage.deleteByApplicationId(applicationId);
      yield put({
        type: JobApplicationActionTypes.POST_COMMENT_SUCCESS,
        jobId,
        applicationId,
        currentStep,
        toast: messages ? buildToast(messages.successAddComment, ToastTypes.success) : undefined,
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.POST_COMMENT_FAIL,
        validation: form.validateErrorResponse(error),
        toast: messages ? buildToast(messages.errorAddComment, ToastTypes.error) : undefined,
      });
    }
  }
}

export function* postApplicationAttachment(action) {
  const {
    jobId,
    applicationId,
    candidateId,
    currentCandidateStepId,
    modelForm,
    currentStep,
    messages,
  } = action;
  const form = new JobApplicationCommentForm();
  form.populate(modelForm);

  const hasOneFileWithoutTempName = files => files.find(file => file.tempName === null);

  const validation = form.validateModel();

  if (validation || hasOneFileWithoutTempName(modelForm.files)) {
    yield put({
      type: JobApplicationActionTypes.POST_ATTACHMENT_FAIL,
      validation,
      toast: messages ? buildToast(messages.errorAddComment, ToastTypes.error) : undefined,
    });
  } else {
    const payload = {
      ...modelForm,
      candidateId,
      currentCandidateStepId,
    };

    try {
      yield JobService.postApplicationAttachment(jobId, applicationId, payload);

      yield put({
        type: JobApplicationActionTypes.POST_ATTACHMENT_SUCCESS,
        jobId,
        applicationId,
        currentStep,
        toast: messages ? buildToast(messages.successAddComment, ToastTypes.success) : undefined,
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.POST_ATTACHMENT_FAIL,
        validation: form.validateErrorResponse(error),
        toast: messages ? buildToast(messages.errorAddComment, ToastTypes.error) : undefined,
      });
    }
  }
}

function* changeApplicationFavorite(action) {
  try {
    const {
      jobId,
      applicationId,
      candidateId,
      currentCandidateStepId,
      favoriteCount,
    } = action;

    if (favoriteCount > 0) {
      yield JobService.deleteApplicationFavorite(jobId, applicationId, { candidateId });
    } else {
      yield JobService.postApplicationFavorite(
        jobId,
        applicationId,
        {
          candidateId,
          currentCandidateStepId,
        },
      );
    }

    yield put({
      type: JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE_FAIL,
      error,
    });
  }
}

function* changeApplicationCandidateFavorite(action) {
  try {
    const {
      jobId,
      applicationId,
      candidateId,
      currentCandidateStepId,
      favoriteCount,
      currentStep,
    } = action;

    if (favoriteCount > 0) {
      yield JobService.deleteApplicationFavorite(jobId, applicationId, { candidateId });
    } else {
      yield JobService.postApplicationFavorite(
        jobId,
        applicationId,
        {
          candidateId,
          currentCandidateStepId,
        },
      );
    }

    yield put({
      type: JobApplicationActionTypes.CHANGE_APPLICATION_CANDIDATE_FAVORITE_SUCCESS,
      jobId,
      applicationId,
      currentStep,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.CHANGE_APPLICATION_CANDIDATE_FAVORITE_FAIL,
      error,
    });
  }
}

function* changeApplicationLike(action) {
  const {
    jobId,
    applicationId,
    candidateId,
    currentCandidateStepId,
    value,
    messages,
    currentStep,
  } = action;

  try {
    if (value) {
      yield JobService.postApplicationLike(
        jobId,
        applicationId,
        {
          candidateId,
          currentCandidateStepId,
          value,
        },
      );
    } else {
      yield JobService.deleteApplicationLike(jobId, applicationId);
    }

    yield put({
      type: JobApplicationActionTypes.CHANGE_APPLICATION_LIKE_SUCCESS,
      jobId,
      applicationId,
      currentStep,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.CHANGE_APPLICATION_LIKE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

export function* postApplicationTag(action) {
  const form = new ApplicationTagForm();

  try {
    const {
      jobId,
      applicationId,
      name,
      currentStep,
      filter,
    } = action;
    form.populate({ name });

    const validation = form.validateModel();

    if (validation) {
      yield put({
        type: JobApplicationActionTypes.ADD_TAG_FAIL,
        validation,
      });
    } else {
      const payload = {
        applications: [
          { id: applicationId },
        ],
        tags: [
          { name },
        ],
        allApplications: false,
      };
      yield JobService.postManyTags({ jobId, payload });

      yield put({
        type: JobApplicationActionTypes.ADD_TAG_SUCCESS,
        jobId,
        applicationId,
        currentStep,
        filter,
        keepNavigation: true,
      });
    }
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.ADD_TAG_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

export function* deleteApplicationTag(action) {
  try {
    const {
      jobId,
      applicationId,
      name,
      currentStep,
      filter,
    } = action;

    yield JobService.deleteApplicationTag(
      jobId,
      applicationId,
      name,
    );

    yield put({
      type: JobApplicationActionTypes.DELETE_TAG_SUCCESS,
      jobId,
      applicationId,
      currentStep,
      filter,
      keepNavigation: true,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.DELETE_TAG_FAIL,
      error,
    });
  }
}

export function* removeApplicationsFilter() {
  const {
    job,
    currentStep,
    applicationsFilters: { filterV2Value },
  } = yield select(getJobApplication);
  const payload = { filters: [{ filterV2Value }] };

  try {
    const response = yield JobService.patchFilters(job.id, payload, currentStep.id);
    const hasFilters = response.body && !!response.body.length;

    yield put({
      type: JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS_SUCCESS,
      applicationsFilters: hasFilters ? response.body[0] : {},
    });
  } catch (error) {
    yield put({ type: JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS_FAIL });
  }
}

export function* saveApplicationsFilters(action) {
  const {
    job,
    currentStep,
  } = yield select(getJobApplication);
  if (!job || !job.id) return;
  const {
    filterV2Value,
    messages,
  } = action;

  const filterValue = {
    current: 1,
    sortField: '',
    searchTerm: '',
    sortDirection: '',
    resultsPerPage: 20,
    ...filterV2Value,
  };

  const payload = { filters: [{ filterV2Value: filterValue }] };

  try {
    const response = yield JobService.patchFilters(job.id, payload, currentStep.id);
    const hasFilters = response.body && !!response.body.length;

    yield put({
      type: JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS_SUCCESS,
      applicationsFilters: hasFilters ? response.body[0] : {},
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS_FAIL,
      validation: {},
      toast: buildToast(messages.saveApplicationFilterError, ToastTypes.error),
      error,
    });
  }
}

export function* addManyTags(action) {
  const { modelForm, messages } = action;
  const {
    jobId,
    includeApplicationIds,
    filter: { search: searchQuery },
  } = yield select(getDataForSelectionProcess);

  const form = new TagModalForm();

  try {
    form.populate(modelForm);

    const validation = form.validateModel();

    if (validation) {
      yield put({
        type: JobApplicationActionTypes.SUBMIT_TAG_MODAL_FAIL,
        validation,
      });
    } else {
      const params = { jobId, payload: modelForm, searchQuery, includeApplicationIds };
      yield JobService.postManyTags(params);

      yield put({
        type: JobApplicationActionTypes.SUBMIT_TAG_MODAL_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    }
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.SUBMIT_TAG_MODAL_FAIL,
      validation: form.validateErrorResponse(error),
      error,
    });
  }
}

function* putApplicationCriteria(action) {
  const {
    jobId,
    applicationId,
    criteriaId,
    candidateId,
    rating,
  } = action;

  if (rating === 0) {
    try {
      yield JobService.deleteApplicationCriteria(
        jobId,
        applicationId,
        criteriaId,
      );

      yield put({
        type: JobApplicationActionTypes.CHANGE_CRITERIA_RATING_SUCCESS,
        jobId,
        applicationId,
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.CHANGE_CRITERIA_RATING_FAIL,
        error,
      });
    }
  } else if (rating > 0 && rating <= 5) {
    try {
      yield JobService.putApplicationCriteria(
        jobId,
        applicationId,
        criteriaId,
        {
          candidateId,
          rating,
        },
      );

      yield put({
        type: JobApplicationActionTypes.CHANGE_CRITERIA_RATING_SUCCESS,
        jobId,
        applicationId,
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.CHANGE_CRITERIA_RATING_FAIL,
        error,
      });
    }
  }
}

function* getAvailableJobs(action) {
  try {
    const { searchQuery, currentJobId } = action;
    const response = yield JobService.getAvailableJobs(searchQuery, currentJobId);
    yield put({
      type: RelocateCandidateActionTypes.GET_AVAILABLE_JOBS_SUCCESS,
      availableJobs: response.body,
    });
  } catch (error) {
    yield put({
      type: RelocateCandidateActionTypes.GET_AVAILABLE_JOBS_FAIL,
    });
  }
}

function* relocateSingleCandidate(action) {
  const {
    currentJob,
    newJob,
    candidates,
  } = action;
  const payload = {
    currentJob,
    newJob,
    candidates,
  };

  try {
    yield JobService.relocateCandidates(payload);

    yield put({
      type: RelocateCandidateActionTypes.RELOCATE_CANDIDATES_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: RelocateCandidateActionTypes.RELOCATE_CANDIDATES_FAIL,
      error,
    });
  }
}

function* relocateCandidates(action) {
  const {
    currentJob,
    newJob,
    candidates,
    currentStep,
    filter,
    pagination,
  } = action;

  const payload = {
    currentJob,
    newJob,
    candidates,
  };

  try {
    yield JobService.relocateCandidates(payload);

    yield put({
      type: RelocateCandidateActionTypes.RELOCATE_CANDIDATES_SUCCESS,
      jobId: currentJob.id,
      currentStep,
      filter,
      pagination,
    });
  } catch (error) {
    yield put({
      type: RelocateCandidateActionTypes.RELOCATE_CANDIDATES_FAIL,
      error,
    });
  }
}

export function* relocateAllCandidates(action) {
  const { currentJob, newJob, isTaskManagerEnabled } = action;

  const {
    currentStep,
    filter,
    pagination,
    includeApplicationIds,
  } = yield select(getDataForSelectionProcess);

  const params = {
    payload: {
      currentJob,
      newJob,
      stepId: currentStep.id,
      stepType: currentStep.type,
    },
    searchQuery: filter.search,
    includeApplicationIds,
  };

  try {
    yield JobService.relocateAllCandidates(params);

    if (isTaskManagerEnabled) {
      yield put({
        type: RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_BY_TASK_MANAGER_SUCCESS,
      });
    } else {
      yield put({
        type: RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_SUCCESS,
        jobId: currentJob.id,
        currentStep,
        filter,
        pagination,
      });
    }
  } catch (error) {
    yield put({
      type: RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_FAIL,
      error,
    });
  }
}

function* getAllApplicationsWithNoFeedback(action) {
  try {
    const applicationsResponse = yield JobService.getAllApplicationsWithNoFeedback(action.jobId);

    yield put({
      type: JobApplicationActionTypes.GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK_SUCCESS,
      applicationsToSendFeedback: applicationsResponse.body.applications,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK_FAIL,
      error,
    });
  }
}

export function* getFinalApplicationsCount(action) {
  try {
    const applicationsResponse = yield JobService.getApplicantCount(action.jobId);
    const { applicantCountBySteps } = applicationsResponse.body;
    const lastStep = applicantCountBySteps.length - 1;

    yield put({
      type: JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT_SUCCESS,
      finalApplicationsCount: applicantCountBySteps[lastStep].count,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT_FAIL,
      error,
    });
  }
}

function* sendMessageReadReceipt(action) {
  try {
    yield JobService.sendMessageReadReceipt(action.threadId, action.messageId, {
      applicationId: action.applicationId,
    });
    yield put({
      type: JobApplicationActionTypes.SEND_MESSAGE_READ_RECEIPT_SUCCESS,
      applicationId: action.applicationId,
      jobId: action.jobId,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.SEND_MESSAGE_READ_RECEIPT_FAIL,
      error,
    });
  }
}

function* patchThreadAllowReply(action) {
  const { threadId, allowReply } = action;
  try {
    yield JobService.patchThreadAllowReply(threadId, allowReply);
    yield put({
      type: JobApplicationActionTypes.PATCH_THREAD_ALLOW_REPLY_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.PATCH_THREAD_ALLOW_REPLY_FAIL,
      error,
      threadId,
      allowReply,
    });
  }
}

function* patchApplicationHiringInformation(action) {
  const {
    jobId,
    applicationId,
    payload,
    messages,
  } = action;

  try {
    yield JobService.patchApplicationHiringInformation(jobId, applicationId, payload);
    yield put({
      type: JobApplicationActionTypes.PATCH_APPLICATION_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.PATCH_APPLICATION_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* getPreHireInfo(action) {
  const { jobId, applicationId } = action;

  try {
    const preHireResponse = yield JobService.getPreHireInfo(jobId, applicationId);

    yield put({
      type: JobApplicationActionTypes.GET_PRE_HIRE_INFO_SUCCESS,
      preHireInfo: preHireResponse.body,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_PRE_HIRE_INFO_FAIL,
      error,
    });
  }
}

function* saveClickWhatsAppLink(action) {
  const { jobId, applicationId } = action;
  try {
    const actionType = CurriculumHistoryActionTypes.whatsAppLink;

    yield JobService.saveClickWhatsAppLink({ jobId, applicationId, actionType });

    yield put({
      type: JobApplicationActionTypes.SAVE_CLICK_WHATSAPP_LINK_SUCCESS,
      jobId,
      applicationId,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.SAVE_CLICK_WHATSAPP_LINK_FAIL,
      error,
    });
  }
}

function* getAddressSuggestions({ input }) {
  try {
    const suggestions = yield getSuggestions(
      input,
      'address',
      GeolocationService.getSuggestions,
    );

    GoogleAnalyticsHelper.sendEvent({
      category: 'ADDRESS',
      action: 'getAddressSuggestions',
    });

    yield put(getAddressSuggestionsSuccess(suggestions));
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL,
      error,
    });
  }
}

export function* getTimeline({ payload }) {
  try {
    const response = yield call(JobService.getTimeline, payload);
    const hasImproperMove = response.headers
    && response.headers['improper-move'];

    yield put({
      type: JobApplicationActionTypes.GET_TIMELINE_SUCCESS,
      improperMove: !!hasImproperMove,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_TIMELINE_FAIL,
      payload: error,
    });
  }
}

// TODO: Update attachedFiles correctly
// const getAttachment = (event) => {
//   if (!event.files || !event.files.length) {
//     return null;
//   }
//   return event.attachmentPath;
// };

export function* updateTimelineEvent({ payload }) {
  const { messages } = payload;
  const form = new JobApplicationCommentForm();
  form.populate({
    commentary: payload[`commentary-${payload.candidateId}`].replace(/(<.+?>)/gi, '').trim(),
  });
  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobApplicationActionTypes.UPDATE_USER_COMMENT_FAIL,
      payload: validation,
      toast: messages ? buildToast(messages.errorEditComment, ToastTypes.error) : undefined,
    });
  } else {
    try {
      const eventId = payload.id;
      const userComment = {
        commentary: payload[`commentary-${payload.candidateId}`],
        isPrivate: payload.isPrivate,
        // TODO: Update attachedFiles correctly
        // attachedFile: getAttachment(payload),
      };

      yield call(JobService.updateUserComment, eventId, userComment);
      yield commentStorage.deleteByApplicationId(payload.applicationId);
      yield put({
        type: JobApplicationActionTypes.UPDATE_USER_COMMENT_SUCCESS,
        payload: payload.applicationId,
        toast: messages ? buildToast(messages.successEditComment, ToastTypes.success) : undefined,
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.UPDATE_USER_COMMENT_FAIL,
        payload: error,
        toast: messages ? buildToast(messages.errorEditComment, ToastTypes.error) : undefined,
      });
    }
  }
}

export function* move({ jobId, currentStep, nextStep, applications, messages }) {
  try {
    const applicationIds = applications.map(app => app.id);
    const response = yield call(JobService.moveApplications, { jobId, nextStep, applicationIds });
    yield put(moveSucceeded({ messages, currentStep, jobId, nextStep, applications }));
    if (response.body && response.body.feedbackRequest) {
      yield put(hiringFeedbackRequested(response.body.feedbackRequest));
    }
  } catch (error) {
    yield* handleMoveApplicationFailed(error, moveFailed(messages.failed, applications));
  }
}

export function* disqualify(jobService) {
  yield takeEvery([
    JobApplicationActionTypes.DISQUALIFY,
  ], function* saga({
    jobId,
    applications,
    reason,
    observation,
    feedbackMessage,
    messages,
  }) {
    try {
      if (applications.length) {
        const applicationIds = applications.map(app => app.id);
        yield call(jobService.disqualifyApplications, {
          jobId,
          applicationIds,
          disapprovalReason: reason,
          disapprovalReasonObservation: observation,
          feedbackMessage,
        });
      }
      yield put(disqualifySucceeded(messages.succeeded));
    } catch (error) {
      yield put(disqualifyFailed(messages.failed, applications));
    }
  });
}

export function* refresh() {
  yield takeLatest([
    JobApplicationActionTypes.MOVE_FAIL,
    JobApplicationActionTypes.DISQUALIFY_FAIL,
  ], function* saga() {
    const { job, currentStep, pagination, filter, tableOrder } = yield select(getJobApplication);
    yield put(initJobApplication(job.id, currentStep, pagination, filter, tableOrder));
  });
}

export function* redirectToBeginning() {
  yield takeLatest([
    JobApplicationActionTypes.MOVE,
    JobApplicationActionTypes.DISQUALIFY,
  ], function* saga() {
    const {
      applications, pagination,
    } = yield select(getJobApplication);
    if (applications.length === 0 && pagination.page > 0) {
      const nextPagination = {
        page: 0,
        perPage: pagination.perPage,
        pageCount: pagination.pageCount,
      };
      yield put({
        type: JobApplicationActionTypes.PAGE_CHANGED,
        pagination: nextPagination,
      });
    }
  });
}

export function* bulkMove({ nextStep, messages }) {
  try {
    const {
      jobId,
      currentStep,
      filter: { search: searchQuery },
      includeApplicationIds,
    } = yield select(getDataForSelectionProcess);
    const payload = { jobId, searchQuery, currentStep, nextStep, includeApplicationIds };
    yield call(JobService.bulkMove, payload);
    yield put(bulkMoveSucceeded());
  } catch (error) {
    yield* handleMoveApplicationFailed(error, bulkMoveFailed(messages.failed));
  }
}

export function* bulkDisqualify(jobService) {
  yield takeLatest([
    JobApplicationActionTypes.BULK_DISQUALIFY,
  ], function* saga({ reason, observation, feedbackMessage, messages }) {
    try {
      const {
        jobId,
        currentStep,
        filter: { search: searchQuery },
        includeApplicationIds,
      } = yield select(getDataForSelectionProcess);
      const payload = {
        jobId,
        searchQuery,
        currentStep,
        disapprovalReason: reason,
        disapprovalReasonObservation: observation,
        feedbackMessage,
        includeApplicationIds,
      };
      yield call(jobService.bulkDisqualify, payload);
      yield put(bulkDisqualifySucceeded());
    } catch (error) {
      yield put(bulkDisqualifyFailed(messages.failed));
    }
  });
}

export function* getWhatsAppBusinessAvaibility(action) {
  const { companyEmail } = action;

  try {
    const response = yield call(WhatsSetupService.getIntegration, companyEmail);
    const isWhatsAppBusinessAvailable = !!response.body.data.whatsAppToken;

    yield put({
      type: JobApplicationActionTypes.GET_WHATSAPP_BUSINESS_AVAILABLE_SUCCESS,
      isWhatsAppBusinessAvailable,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_WHATSAPP_BUSINESS_AVAILABLE_FAIL,
      isWhatsAppBusinessAvailable: false,
    });
  }
}

export function* redirectToTaskManager() {
  yield takeLatest([
    JobApplicationActionTypes.BULK_MOVE_SUCCESS,
    JobApplicationActionTypes.BULK_DISQUALIFY_SUCCESS,
    RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_BY_TASK_MANAGER_SUCCESS,
  ], function* saga() {
    yield* redirect(URL_TASK_MANAGER);
  });
}

export function* getCandidateDocuments(service) {
  yield takeLatest([
    JobApplicationActionTypes.GET_CANDIDATE_DOCUMENTS,
  ], function* saga({ payload: { applicationId, documentType } }) {
    try {
      const candidateDocumentsRequest = yield call(
        service.getCandidateDocuments, applicationId, documentType,
      );
      yield put({
        type: JobApplicationActionTypes.GET_CANDIDATE_DOCUMENTS_SUCCESS,
        payload: {
          documents: candidateDocumentsRequest.body,
          documentType,
        },
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.GET_CANDIDATE_DOCUMENTS_FAIL,
        error,
      });
    }
  });
}

export function* getCandidateDocumentSignedUrl(service) {
  yield takeLatest([
    JobApplicationActionTypes.GET_CANDIDATE_DOCUMENT_SIGNED_URL,
  ], function* saga({ payload: { applicationId, documentId } }) {
    try {
      const signedUrlResponse = yield call(
        service.getCandidateDocumentSignedUrl, applicationId, documentId,
      );
      const { url } = signedUrlResponse.body;
      yield call(window.open, url, '_blank');
      yield put({
        type: JobApplicationActionTypes.GET_CANDIDATE_DOCUMENT_SIGNED_URL_SUCCESS,
      });
    } catch (error) {
      yield put({
        type: JobApplicationActionTypes.GET_CANDIDATE_DOCUMENT_SIGNED_URL_FAIL,
        error,
      });
    }
  });
}

export function* getFeedbackAnalyticsInfo({ payload }) {
  try {
    const { jobId } = payload;
    const response = yield call(FeedbackAnalyticsService.get, jobId);
    yield put({
      type: FeebackAnalyticsInfoTypes.GET_INFO_SUCCESS,
      payload: { ...response.body },
    });
  } catch (error) {
    yield put({ type: FeebackAnalyticsInfoTypes.GET_INFO_FAIL, error });
  }
}

export function* getOffers(action) {
  try {
    const { jobId } = action.payload;
    const response = yield call(JobOfferService.getByJobId, { jobId });
    const { offers } = response.body;
    yield put({
      type: JobApplicationActionTypes.GET_OFFERS_SUCCESS,
      payload: { offers },
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_OFFERS_FAIL,
      error,
    });
  }
}

export function* getEngageSurveys() {
  try {
    const response = yield call(EngageSurvey.getSurveyCareerPageBinds);
    yield put({
      type: JobApplicationActionTypes.GET_ENGAGE_SURVEYS_SUCCESS,
      payload: { surveys: response.body },
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_ENGAGE_SURVEYS_FAIL,
      error,
    });
  }
}

export function* resetPagination({ filter: { hasChanged } }) {
  if (!hasChanged) return;

  const { pagination } = yield select(getJobApplication);

  if (pagination.page === 0) return;

  yield put({
    type: JobApplicationActionTypes.PAGE_CHANGED,
    pagination: { ...pagination, page: 0 },
  });
}

export function* postLinkedRscMember(action) {
  try {
    const {
      childAppId,
      candidateId,
      member,
      token,
      messages,
    } = action.payload;

    yield JobService.postLinkedRscMember(
      token,
      childAppId,
      candidateId,
      member,
    );

    const rscLinkedinIntegrationInMailsResponse = yield JobService
      .getRscLinkedinIntegrationCandidateProfileInMails(
        token,
        candidateId,
        childAppId,
      );

    yield put(postLinkedRscMemberSucceeded(
      rscLinkedinIntegrationInMailsResponse && rscLinkedinIntegrationInMailsResponse.body
        ? rscLinkedinIntegrationInMailsResponse.body
        : [], messages,
    ));
  } catch (error) {
    yield put(postLinkedRscMemberFailed(error));
  }
}

export function* postUnlinkedRscMember(action) {
  const {
    childAppId,
    candidateId,
    member,
    token,
    messages,
  } = action.payload;

  try {
    yield JobService.postUnlinkedRscMember(
      token,
      childAppId,
      candidateId,
      member,
    );

    const rscLinkedinIntegrationInMailsResponse = yield JobService
      .getRscLinkedinIntegrationCandidateProfileInMails(
        token,
        candidateId,
        childAppId,
      );

    yield put(postUnlinkedRscMemberSucceeded(
      rscLinkedinIntegrationInMailsResponse && rscLinkedinIntegrationInMailsResponse.body
        ? rscLinkedinIntegrationInMailsResponse.body
        : [], messages,
    ));
  } catch (error) {
    yield put(postUnlinkedRscMemberFailed(error));
  }
}

export function* getContentMessage(action) {
  try {
    const { messageId, threadId } = action;
    const { body } = yield JobService.getContentMessage(messageId);
    yield put({
      type: JobApplicationActionTypes.GET_CONTENT_MESSAGE_SUCCESS,
      body: body.body,
      attachments: body.attachments,
      messageId,
      threadId,
    });
  } catch (error) {
    yield put({
      type: JobApplicationActionTypes.GET_CONTENT_MESSAGE_FAIL,
      error,
    });
  }
}

function* JobApplicationSaga() {
  yield takeLatest(JobApplicationActionTypes.INIT, initApplications);
  yield takeLatest(JobApplicationActionTypes.INIT_SUCCESS, initFilters);
  yield takeLatest(JobApplicationActionTypes.INIT_REPORT, initApplicationsReport);
  yield takeLatest(JobApplicationActionTypes.INIT_DISAPPROVAL_REASONS, initDisapprovalReasons);

  yield takeLatest(JobApplicationActionTypes.INIT_CANDIDATE, initApplicationCandidate);
  yield takeLatest(JobApplicationActionTypes.INIT_CANDIDATE, getProfilePagination);
  yield takeLatest(JobApplicationActionTypes.INIT_CANDIDATE, getApplicationEmailMessages);
  yield takeLatest(JobApplicationActionTypes.INIT_CANDIDATE, getApplicationHistory);
  yield takeLatest(JobApplicationActionTypes.INIT_CANDIDATE, getPreHireInfo);

  yield takeLatest(JobApplicationActionTypes.INIT_CANDIDATE_CURRICULUM, initCandidateCurriculum);

  yield takeLatest(JobApplicationActionTypes.POST_ATTACHMENT_SUCCESS, getApplicationHistory);

  yield takeLatest(
    JobApplicationActionTypes.SAVE_CLICK_WHATSAPP_LINK_SUCCESS,
    getApplicationHistory,
  );

  yield takeLatest(JobApplicationActionTypes.POST_COMMENT_SUCCESS, getApplicationHistory);
  yield takeLatest(JobApplicationActionTypes.CHANGE_CRITERIA_RATING_SUCCESS, getApplicationHistory);
  yield takeLatest(JobApplicationActionTypes.ADD_TAG_SUCCESS, getApplicationHistory);
  yield takeLatest(JobApplicationActionTypes.DELETE_TAG_SUCCESS, getApplicationHistory);
  yield takeLatest(
    JobApplicationActionTypes.CHANGE_APPLICATION_LIKE_SUCCESS, getApplicationHistory,
  );
  yield takeLatest(
    JobApplicationActionTypes.CHANGE_APPLICATION_CANDIDATE_FAVORITE_SUCCESS,
    getApplicationHistory,
  );

  yield takeLatest(
    JobApplicationActionTypes.GET_APPLICATION_EMAIL_MESSAGES, getApplicationEmailMessages,
  );
  yield takeLatest(JobApplicationActionTypes.POST_COMMENT, postApplicationComment);
  yield takeLatest(JobApplicationActionTypes.POST_ATTACHMENT, postApplicationAttachment);
  yield takeLatest(JobApplicationActionTypes.CHANGE_CRITERIA_RATING, putApplicationCriteria);

  yield takeLatest(JobApplicationActionTypes.PATCH_APPLICATION, patchApplicationHiringInformation);

  yield takeLatest([
    JobApplicationActionTypes.STEP_CHANGED,
    JobApplicationActionTypes.PAGE_CHANGED,
    JobApplicationActionTypes.CHANGE_FORM_FILTER,
    JobApplicationActionTypes.CHANGE_ORDER,
    JobApplicationActionTypes.RELOAD_APPLICATIONS,
    JobApplicationActionTypes.SUBMIT_TAG_MODAL_SUCCESS,
    JobApplicationActionTypes.PATCH_APPLICATION_SUCCESS,
    RelocateCandidateActionTypes.RELOCATE_CANDIDATES_SUCCESS,
    RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_SUCCESS,
    JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_SUCCESS,
    JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS_SUCCESS,
    JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_SUCCESS,
    JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE_SUCCESS,
    JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS_SUCCESS,
    JobApplicationActionTypes.MOVE_SUCCESS,
    JobApplicationActionTypes.DISQUALIFY_SUCCESS,
    HiringInformationActionTypes.OPEN_HIRING_INFORMATION_MODAL,
  ], reloadApplications);

  yield takeLatest(JobApplicationActionTypes.STEP_CHANGED, sendStepViewAfterMoveToGA);

  yield takeLatest([
    JobApplicationActionTypes.STEP_CHANGED,
    JobApplicationActionTypes.GET_FILTERS,
    JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS_SUCCESS,
  ], reloadFilters);

  yield takeLatest(JobApplicationActionTypes.APPLICATION_UNDO_REPROVE, undoApplicationReprovement);
  yield takeLatest(JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS, sendEmailToApplications);
  yield takeLatest(
    JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION, sendEmailToSingleApplication,
  );
  yield takeLatest(
    JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_SUCCESS,
    getSingleApplicantEmails,
  );
  yield takeLatest(
    JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_SUCCESS, initFilters,
  );
  yield takeLatest(JobApplicationActionTypes.ADD_TAG, postApplicationTag);
  yield takeLatest(JobApplicationActionTypes.ADD_TAG_SUCCESS, initApplicationCandidate);
  yield takeLatest(JobApplicationActionTypes.DELETE_TAG, deleteApplicationTag);
  yield takeLatest(JobApplicationActionTypes.DELETE_TAG_SUCCESS, initApplicationCandidate);
  yield takeLatest(JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS, saveApplicationsFilters);
  yield takeLatest(JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS,
    removeApplicationsFilter);
  yield takeLatest(JobApplicationActionTypes.CLEAR_ALL_FILTERS,
    removeApplicationsFilter);
  yield takeLatest(JobApplicationActionTypes.SUBMIT_TAG_MODAL, addManyTags);
  yield takeLatest(JobApplicationActionTypes.SUBMIT_TAG_MODAL_SUCCESS, initFilters);
  yield takeLatest(
    JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE, changeApplicationFavorite,
  );
  yield takeLatest(
    JobApplicationActionTypes.CHANGE_APPLICATION_CANDIDATE_FAVORITE,
    changeApplicationCandidateFavorite,
  );
  yield takeLatest(
    JobApplicationActionTypes.CHANGE_APPLICATION_LIKE, changeApplicationLike,
  );
  yield takeLatest(
    JobApplicationActionTypes.CURRICULUM_DISQUALIFY_APPLICATION,
    curriculumDisqualifyApplication,
  );
  yield takeLatest(
    JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP,
    curriculumChangeApplicationStep,
  );
  yield takeLatest(
    JobApplicationActionTypes.CURRICULUM_UNDO_REPROVE,
    curriculumUndoApplicationReprove,
  );
  yield takeLatest(
    RelocateCandidateActionTypes.GET_AVAILABLE_JOBS, getAvailableJobs,
  );
  yield takeLatest(
    RelocateCandidateActionTypes.RELOCATE_SINGLE_CANDIDATE, relocateSingleCandidate,
  );
  yield takeLatest(
    RelocateCandidateActionTypes.RELOCATE_CANDIDATES, relocateCandidates,
  );
  yield takeLatest(
    RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES, relocateAllCandidates,
  );
  yield takeLatest(JobApplicationActionTypes.GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK,
    getAllApplicationsWithNoFeedback);
  yield takeLatest(JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT,
    getFinalApplicationsCount);
  yield takeLatest(JobApplicationActionTypes.SEND_MESSAGE_READ_RECEIPT, sendMessageReadReceipt);
  yield takeLatest(JobApplicationActionTypes.SEND_MESSAGE_READ_RECEIPT_SUCCESS,
    getSingleApplicantEmails);
  yield takeLatest(JobApplicationActionTypes.PATCH_THREAD_ALLOW_REPLY,
    patchThreadAllowReply);
  yield takeLatest(JobApplicationActionTypes.SAVE_CLICK_WHATSAPP_LINK, saveClickWhatsAppLink);
  yield takeLatest(JobApplicationActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS,
    getAddressSuggestions);
  yield takeLatest(JobApplicationActionTypes.GET_TIMELINE, getTimeline);
  yield takeLatest(JobApplicationActionTypes.UPDATE_USER_COMMENT, updateTimelineEvent);
  yield takeLatest(JobApplicationActionTypes.UPDATE_USER_COMMENT_SUCCESS, getTimeline);
  yield takeLatest(JobApplicationActionTypes.GET_WHATSAPP_BUSINESS_AVAILABLE,
    getWhatsAppBusinessAvaibility);
  yield takeLatest(JobApplicationActionTypes.MOVE, move);
  yield* disqualify(JobService);
  yield takeLatest(JobApplicationActionTypes.BULK_MOVE, bulkMove);
  yield* bulkDisqualify(JobService);
  yield* getCandidateDocuments(JobService);
  yield* getCandidateDocumentSignedUrl(JobService);
  yield* refresh();
  yield* redirectToBeginning();
  yield* redirectToTaskManager();

  yield takeLatest([
    JobApplicationActionTypes.PAGE_CHANGED,
    JobApplicationActionTypes.STEP_CHANGED,
    JobApplicationActionTypes.MOVE_SUCCESS,
    JobApplicationActionTypes.DISQUALIFY_SUCCESS,
    JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_SUCCESS,
  ], saveApplicationsNotIndexed);

  yield takeLatest(FeebackAnalyticsInfoTypes.GET_INFO, getFeedbackAnalyticsInfo);

  yield takeLatest(JobApplicationActionTypes.GET_OFFERS, getOffers);
  yield takeLatest(JobApplicationActionTypes.GET_ENGAGE_SURVEYS, getEngageSurveys);
  yield takeLatest(JobApplicationActionTypes.CHANGE_FORM_FILTER, resetPagination);
  yield takeLatest(JobApplicationActionTypes.POST_LINKED_RSC_MEMBER, postLinkedRscMember);
  yield takeLatest(JobApplicationActionTypes.POST_UNLINKED_RSC_MEMBER, postUnlinkedRscMember);
  yield takeLatest(JobApplicationActionTypes.GET_CONTENT_MESSAGE, getContentMessage);
}

export default JobApplicationSaga;
