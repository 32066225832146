import { combineReducers } from 'redux';

import * as types from './RootSetupTypes';

const isSavingFeatureFlags = (state = false, action) => {
  switch (action.type) {
  case types.SAVE_FEATURE_FLAGS_REQUEST:
    return true;
  case types.SAVE_FEATURE_FLAGS_SUCCESS:
  case types.SAVE_FEATURE_FLAGS_FAILURE:
    return false;
  default:
    return state;
  }
};

const featureFlagsForm = (state = [], action) => {
  switch (action.type) {
  case types.INIT_FEATURE_FLAGS:
    return action.payload;
  case types.ADD_FEATURE_FLAG:
    return [
      ...state,
      action.payload,
    ];
  case types.REMOVE_FEATURE_FLAG:
    return state.filter(featureFlag => featureFlag !== action.payload);
  default:
    return state;
  }
};

export default combineReducers({
  isSavingFeatureFlags,
  featureFlagsForm,
});
