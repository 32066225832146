import { Branches, Groups } from '../Enums';

const handleChangeTreeAdmissionBranch = (newStruct, handleSetShowGroupTip) => {
  const admissionBranchName = Branches.admission;
  const admissionViewOnlyGroupName = Groups[Branches.admission].admission_view_only_group;
  const admissionBranchValue = newStruct.tree[admissionBranchName];
  const admissionViewOnlyGroupValue = admissionBranchValue.groups[admissionViewOnlyGroupName];

  if (admissionViewOnlyGroupValue) admissionViewOnlyGroupValue.checked = false;
  handleSetShowGroupTip({
    [Branches.admission]: false,
  });
};

export const customHandleChangeTreeBranchs = {
  [Branches.admission]: handleChangeTreeAdmissionBranch,
};

const unCheckOtherGroupPermissions = (groups, permissionGroupName) => {
  Object.keys(groups)
    .forEach((g) => {
      if (g !== permissionGroupName) {
        // eslint-disable-next-line no-param-reassign
        groups[g].checked = false;
      }
    });
};

const onAdmissionTreeGroupChange = (
  group,
  groups,
  newStatus,
  handleSetShowGroupTip,
) => {
  const admissionViewOnlyGroup = Groups[Branches.admission].admission_view_only_group;
  const checkingAdmissionViewOnlyGroup = group === admissionViewOnlyGroup && newStatus;

  if (checkingAdmissionViewOnlyGroup) {
    handleSetShowGroupTip({
      [Branches.admission]: true,
    });

    unCheckOtherGroupPermissions(groups, admissionViewOnlyGroup);
  } else {
    handleSetShowGroupTip({
      [Branches.admission]: false,
    });

    const admissionViewOnlyItem = groups[admissionViewOnlyGroup];
    if (admissionViewOnlyItem) admissionViewOnlyItem.checked = false;
  }
};

export const treeGroupCallBacks = {
  [Branches.admission]: onAdmissionTreeGroupChange,
};
