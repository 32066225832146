import * as types from './ActionTypes';
import { formatCompanyBranchToTreeStruct } from './scripts/formater';

const getCompanyBranches = payload => ({
  type: types.GET_COMPANY_BRANCHES,
  payload,
});

const getCompanyBranchSuccess = payload => ({
  type: types.GET_COMPANY_BRANCHES_SUCCESS,
  payload: {
    ...payload,
    items: formatCompanyBranchToTreeStruct(payload.items),
  },
});

const getCSVTemplate = () => ({
  type: types.GET_COMPANY_BRANCHES_CSV_TEMPLATE,
});

const getCurrentCSV = () => ({
  type: types.GET_COMPANY_BRANCHES_CURRENT_CSV,
});

const patchCompanyBranch = payload => ({
  type: types.PATCH_COMPANY_BRANCH,
  payload,
});

const deleteCompanyBranch = payload => ({
  type: types.DELETE_COMPANY_BRANCH,
  payload,
});

const setNodeModalIsOpen = payload => ({
  type: types.SET_NODE_MODAL_IS_OPEN,
  payload,
});

const setDeleteNodeModalIsOpen = payload => ({
  type: types.SET_DELETE_NODE_MODAL_IS_OPEN,
  payload,
});

const postCompanyBranchesCSV = payload => ({
  type: types.POST_COMPANY_BRANCHES_CSV,
  payload,
});

export {
  getCompanyBranches,
  getCSVTemplate,
  getCurrentCSV,
  patchCompanyBranch,
  setNodeModalIsOpen,
  setDeleteNodeModalIsOpen,
  postCompanyBranchesCSV,
  deleteCompanyBranch,
  getCompanyBranchSuccess,
};
