import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export const WhatsappBusinessTemplateActionTypes = createActionTypes(
  'WhatsappBusinessTemplate',
  asyncActionType('SAVE_TEMPLATE'),
  asyncActionType('GET_TEMPLATES'),
);

export const saveTemplate = (payload, messages) => ({
  type: WhatsappBusinessTemplateActionTypes.SAVE_TEMPLATE,
  payload,
  messages,
});

export const getTemplates = () => ({
  type: WhatsappBusinessTemplateActionTypes.GET_TEMPLATES,
});
