import { call, put, takeLatest } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';

import JobCandidatesClassifiersActionTypes from '../../../constants/Job/JobCandidatesClassifiers/JobCandidatesClassifiersActionTypes';
import JobService from '../../../services/Job/JobService';
import JobCandidatesClassifiersForm from '../../../forms/Job/JobCandidatesClassifiers/JobCandidatesClassifiersForm';
import * as RealtimeActionTypes from '../../../containers/Realtime/RealtimeActionTypes';
import * as RealtimeEvents from '../../../containers/Authentication/RealtimesEvents';

function* getUsersList(action) {
  try {
    const response = yield JobService.getUsersList(action.user);
    yield put({
      type: JobCandidatesClassifiersActionTypes.GET_USERS_LIST_SUCCESS,
      usersList: response.body.data,
    });
  } catch (error) {
    yield put({
      type: JobCandidatesClassifiersActionTypes.GET_USERS_LIST_FAIL,
    });
  }
}

function* getClassifiersList(action) {
  try {
    const response = yield JobService.getClassifiersList(action.jobId);
    yield put({
      type: JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST_SUCCESS,
      classifiersList: response.body.data,
    });
  } catch (error) {
    yield put({
      type: JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST_FAIL,
    });
  }
}

function* deleteClassifier(action) {
  const { jobId, id } = action;

  try {
    yield JobService.deleteClassifier(jobId, id);

    yield put({
      type: JobCandidatesClassifiersActionTypes.DELETE_SUCCESS,
    });
    yield getClassifiersList({ jobId });
  } catch (error) {
    const form = new JobCandidatesClassifiersForm();
    yield put({
      type: JobCandidatesClassifiersActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* postClassifier(action) {
  const { jobId, payload } = action;
  const form = new JobCandidatesClassifiersForm();

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobCandidatesClassifiersActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      const classifierPayload = { ...payload, classifierType: 'approver' };
      const response = yield JobService.postClassifier(jobId, classifierPayload);

      yield put({
        type: JobCandidatesClassifiersActionTypes.POST_SUCCESS,
        modelForm: response.body,
      });
      yield getClassifiersList({ jobId });
    } catch (error) {
      yield put({
        type: JobCandidatesClassifiersActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* patchClassifiers(action) {
  const { jobId, classifierId, classifierType } = action;

  try {
    const classifierPayload = { classifierType };
    yield JobService.patchClassifier(jobId, classifierId, classifierPayload);

    yield put({
      type: JobCandidatesClassifiersActionTypes.CHANGE_CLASSIFIERS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: JobCandidatesClassifiersActionTypes.CHANGE_CLASSIFIERS_LIST_FAIL,
    });
  }
}

function* postClassifiersToJobs(action) {
  const { payload,
    summary,
    messages,
  } = action;

  try {
    yield call(JobService.postClassifiersToJobs, payload, summary);
    yield put({
      type: JobCandidatesClassifiersActionTypes.POST_TO_JOBS_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success, { autoClose: 5000 }),
    });
  } catch (error) {
    yield put({
      type: JobCandidatesClassifiersActionTypes.POST_TO_JOBS_FAIL,
      error,
      toast: buildToast(messages.error, ToastTypes.error, { autoClose: 5000 }),
    });
  }
}

function* asyncAssignClassifiersSuccess({ data }) {
  if (data.type === RealtimeEvents.NOTIFY_ASSIGN_JOBS_CLASSIFIERS_ENDED) {
    yield put({
      type: JobCandidatesClassifiersActionTypes.POST_TO_JOBS_ASYNC_SUCCESS,
      toast: buildToast(data.message, ToastTypes.success, { autoClose: 5000 }),
    });
  }
}

function* JobCandidatesClassifiersSaga() {
  yield takeLatest(JobCandidatesClassifiersActionTypes.POST, postClassifier);
  yield takeLatest(JobCandidatesClassifiersActionTypes.CHANGE_CLASSIFIERS_LIST, patchClassifiers);
  yield takeLatest(JobCandidatesClassifiersActionTypes.DELETE, deleteClassifier);
  yield takeLatest(JobCandidatesClassifiersActionTypes.GET_USERS_LIST, getUsersList);
  yield takeLatest(JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST, getClassifiersList);
  yield takeLatest(JobCandidatesClassifiersActionTypes.POST_TO_JOBS, postClassifiersToJobs);
  yield takeLatest(RealtimeActionTypes.REALTIME_DIGEST, asyncAssignClassifiersSuccess);
}

export {
  postClassifiersToJobs,
  asyncAssignClassifiersSuccess,
};

export default JobCandidatesClassifiersSaga;
