import React from 'react';
import PropTypes from 'prop-types';

import { ButtonLink } from './ButtonLink';
import getKnowMoreLinkForPlan from '../../constants/CompaniesPlansToKnowMoreLinks';
import * as HigherPlanWarningStyles from './HigherPlanWarning.styles';

const propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  buttonMessage: PropTypes.string,
  buttonUrl: PropTypes.string,
  currentUser: PropTypes.object.isRequired,
  noMargin: PropTypes.bool,
  subDescription: PropTypes.string,
};

const HigherPlanWarning = ({
  title,
  description,
  buttonMessage,
  buttonUrl,
  currentUser,
  noMargin,
  subDescription,
}) => {
  const showUpgradePlanButton = currentUser && ['root', 'owner'].includes(currentUser.userType);
  let companyPlan = '';
  if (currentUser && currentUser.products) {
    const companyProduct = currentUser.products.find(product => product.plan !== '');
    if (companyProduct) {
      companyPlan = companyProduct.plan;
    }
  }

  const knowMoreLink = buttonUrl || getKnowMoreLinkForPlan(companyPlan);

  return (
    <HigherPlanWarningStyles.Tip
      className="higher-plan-warning"
      inverted
      type="hint"
      noIcon
      noMargin={noMargin}
    >
      <HigherPlanWarningStyles.Content>
        <HigherPlanWarningStyles.Logo
          className="higher-plan-warning-logo"
          src={`${process.env.REACT_APP_ASSETS_URL}/repacking/higher-plan-logo.svg`}
          alt=""
          role="presentation"
        />
        <div>
          {title && <HigherPlanWarningStyles.Title>{title}</HigherPlanWarningStyles.Title>}
          <HigherPlanWarningStyles.Paragraph>{description}</HigherPlanWarningStyles.Paragraph>
          {subDescription
         && (
           <HigherPlanWarningStyles.SubDescription>
             {subDescription}
           </HigherPlanWarningStyles.SubDescription>
         )
          }
        </div>
        {(buttonMessage && showUpgradePlanButton) && (
          <ButtonLink href={knowMoreLink} target="_blank">{buttonMessage}</ButtonLink>
        )}
      </HigherPlanWarningStyles.Content>
    </HigherPlanWarningStyles.Tip>
  );
};

HigherPlanWarning.propTypes = propTypes;

export default HigherPlanWarning;
