import { combineReducers } from 'redux';

import CustomTestQuestionActionTypes from './CustomTestQuestionActionTypes';

const CompanyCustomTestQuestionModel = {
  id: undefined,
  customTestId: undefined,
  text: '',
  required: true,
  weight: 1,
  questionType: '',
  multipleAnswers: false,
  correctAnswers: [],
  wrongAnswers: [],
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.CREATE:
  case CustomTestQuestionActionTypes.UPDATE:
    return true;
  case CustomTestQuestionActionTypes.CREATE_SUCCESS:
  case CustomTestQuestionActionTypes.CREATE_FAIL:
  case CustomTestQuestionActionTypes.UPDATE_SUCCESS:
  case CustomTestQuestionActionTypes.UPDATE_FAIL:
    return false;
  default:
    return state;
  }
};

const customTest = (state = {}, action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.DETAIL_SUCCESS:
    return action.CustomTest;
  case CustomTestQuestionActionTypes.DETAIL:
    return {};
  default:
    return state;
  }
};

const rearrangedQuestions = (state = {}, action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.SWAP_QUESTION: {
    const {
      dragQuestion,
      hoverQuestion,
    } = action;
    const {
      [dragQuestion.id]: newHoverIndex = dragQuestion.index,
      [hoverQuestion.id]: newDragIndex = hoverQuestion.index,
    } = state;
    const newState = {
      ...state,
      [dragQuestion.id]: newDragIndex,
      [hoverQuestion.id]: newHoverIndex,
    };
    if (dragQuestion.index === newDragIndex) delete (newState[dragQuestion.id]);
    if (hoverQuestion.index === newHoverIndex) delete (newState[hoverQuestion.id]);
    return newState;
  }
  case CustomTestQuestionActionTypes.SAVE_SORTED_SUCCESS:
    return {};
  default:
    return state;
  }
};

const questions = (state = [], action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.DETAIL_SUCCESS:
    return action.CustomTest.questions;
  case CustomTestQuestionActionTypes.CREATE_SUCCESS: {
    return [
      ...state,
      action.question,
    ];
  }
  case CustomTestQuestionActionTypes.UPDATE_SUCCESS:
    return state.map(question => (
      question.id !== action.question.id ? question : action.question));
  case CustomTestQuestionActionTypes.SAVE_SORTED_SUCCESS:
    return state.map(question => (
      !action.changed[question.id] ? question : {
        ...question,
        index: action.changed[question.id],
      }));
  case CustomTestQuestionActionTypes.DELETE_SUCCESS:
    return state.filter(question => (question.id !== action.id));
  case CustomTestQuestionActionTypes.DETAIL_FAIL:
    return [];
  default:
    return state;
  }
};

const modelForm = (state = CompanyCustomTestQuestionModel, action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.CHANGE_FORM:
    return {
      ...state,
      ...action.modelForm,
    };
  case CustomTestQuestionActionTypes.CLONE_QUESTION:
    return {
      ...action.question,
      id: undefined,
      customTestId: undefined,
    };
  case CustomTestQuestionActionTypes.LOAD_QUESTION:
    return {
      ...action.question,
    };
  case CustomTestQuestionActionTypes.DETAIL:
  case CustomTestQuestionActionTypes.MODEL_CLEAR:
  case CustomTestQuestionActionTypes.CREATE_SUCCESS:
  case CustomTestQuestionActionTypes.UPDATE_SUCCESS:
    return CompanyCustomTestQuestionModel;
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.CREATE:
  case CustomTestQuestionActionTypes.UPDATE:
  case CustomTestQuestionActionTypes.VALIDATE_QUESTION_CUSTOM:
    return {};
  case CustomTestQuestionActionTypes.CREATE_FAIL:
  case CustomTestQuestionActionTypes.UPDATE_FAIL:
  case CustomTestQuestionActionTypes.VALIDATE_QUESTION_CUSTOM_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
  case CustomTestQuestionActionTypes.DETAIL:
  case CustomTestQuestionActionTypes.CREATE:
  case CustomTestQuestionActionTypes.DELETE:
    return true;
  case CustomTestQuestionActionTypes.DETAIL_SUCCESS:
  case CustomTestQuestionActionTypes.DETAIL_FAIL:
  case CustomTestQuestionActionTypes.CREATE_FAIL:
  case CustomTestQuestionActionTypes.CREATE_SUCCESS:
  case CustomTestQuestionActionTypes.DELETE_FAIL:
  case CustomTestQuestionActionTypes.DELETE_SUCCESS:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  customTest,
  isSaving,
  modelForm,
  questions,
  validation,
  rearrangedQuestions,
  isLoading,
});
