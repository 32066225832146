import { combineReducers } from 'redux';
import * as ActionTypes from './ActionTypes';

export const appList = (state = [], action) => {
  switch (action.type) {
  case ActionTypes.GET_APP_LIST_SUCCESS:
    return action.payload;
  default:
    return state;
  }
};

export const appById = (state = {}, action) => {
  switch (action.type) {
  case ActionTypes.GET_APP_SUCCESS:
    return action.payload;
  default:
    return state;
  }
};

export const putCompanyAppSettings = (state = {}, action) => {
  switch (action.type) {
  case ActionTypes.PUT_APP_OPTIONS_SUCCESS:
    return action.payload;
  default:
    return state;
  }
};

export const isLoading = (state, action) => {
  switch (action.type) {
  case ActionTypes.GET_APP_LIST_REQUEST:
  case ActionTypes.GET_APP_REQUEST:
    return true;
  default:
    return false;
  }
};

export default combineReducers({
  appList,
  appById,
  isLoading,
  putCompanyAppSettings,
});
