import api from '../../../../api/DarthVaderClient';

export default {
  favorite(id, payload) {
    return api.put(`/custom-test/companies/${id}/favorite`, payload);
  },
  list({ page, title }) {
    const queryParams = title ? `title=${title}` : `page=${page}&perPage=15`;
    return api.get(`/custom-test/companies?${queryParams}`);
  },
  create(payload) {
    return api.post('/custom-test/companies', payload);
  },
  read(id) {
    return api.get(`/custom-test/companies/${id}`);
  },
  detail(id) {
    return api.get(`/custom-test/companies/${id}/detail`);
  },
  update(id, payload) {
    return api.put(`/custom-test/companies/${id}`, payload);
  },
  delete(payload) {
    return api.post('/custom-test/companies/delete', payload);
  },
  archive(id) {
    return api.patch(`/custom-test/companies/archive/${id}`);
  },
  duplicate(id) {
    return api.post(`/custom-test/companies/${id}/duplicate`);
  },
  listToJob(jobId) {
    return api.get(`/custom-test/companies/jobs/edit/${jobId}/add`);
  },
};
