import { combineReducers } from 'redux';
import WhatsAppSetupTypes from './ActionTypes';

export const whatsAppSetup = (state = {}, action) => {
  switch (action.type) {
  case WhatsAppSetupTypes.GET_WHATSAPP_SETUP_SUCCESS:
    return {
      ...action.payload,
      isLoading: false,
    };
  case WhatsAppSetupTypes.GET_WHATSAPP_SETUP:
  case WhatsAppSetupTypes.GET_WHATSAPP_SETUP_FAIL:
  default:
    return state;
  }
};

export const saveWhatsAppSetup = (state = {}, action) => {
  switch (action.type) {
  case WhatsAppSetupTypes.SAVE_WHATSAPP_SETUP_SUCCESS:
    return {
      ...action.payload,
      isLoading: false,
    };
  case WhatsAppSetupTypes.SAVE_WHATSAPP_SETUP:
  case WhatsAppSetupTypes.SAVE_WHATSAPP_SETUP_FAIL:
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case WhatsAppSetupTypes.GET_WHATSAPP_SETUP:
    return true;
  case WhatsAppSetupTypes.GET_WHATSAPP_SETUP_SUCCESS:
  case WhatsAppSetupTypes.GET_WHATSAPP_SETUP_FAIL:
    return false;
  default:
    return state;
  }
};

export const isSaveLoading = (state = false, action) => {
  switch (action.type) {
  case WhatsAppSetupTypes.SAVE_WHATSAPP_SETUP:
    return true;
  case WhatsAppSetupTypes.SAVE_WHATSAPP_SETUP_SUCCESS:
  case WhatsAppSetupTypes.SAVE_WHATSAPP_SETUP_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  whatsAppSetup,
  saveWhatsAppSetup,
  isLoading,
  isSaveLoading,
});
