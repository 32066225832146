import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const SkillsEvaluationActionTypes = createActionTypes(
  'SkillsEvaluation',
  asyncActionType('GET'),
);

const getSkillsEvaluation = ({ applicationId, jobId }) => ({
  type: SkillsEvaluationActionTypes.GET,
  applicationId,
  jobId,
});

export {
  SkillsEvaluationActionTypes,
  getSkillsEvaluation,
};
