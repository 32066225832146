import styled from 'styled-components';

const FooterComponent = styled.footer`
  background-color:  ${({ backgroundColor }) => backgroundColor};
  font-family: ${({ fontFamily }) => fontFamily};
  z-index: ${({ zIndex }) => zIndex};
  position: ${({ position }) => position};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  font-weight:  ${({ fontWeight }) => fontWeight};
  font-size:  ${({ fontSize }) => fontSize};
  @media screen and (min-width: 1024px) {
    flex-direction: ${({ desktopflexDirection }) => desktopflexDirection};
    justify-content: ${({ desktopjustifyContent }) => desktopjustifyContent};
    z-index: ${({ desktopZIndex }) => desktopZIndex};
  }
`;

const FooterItens = styled.div`
  @media screen and (max-width: 1024px) {
    padding:  ${({ mobilePadding }) => mobilePadding};
    display: ${({ mobileDisplay }) => mobileDisplay};
    flex-direction: ${({ mobileFlexDirection }) => mobileFlexDirection};
    align-items: ${({ mobileAlignItems }) => mobileAlignItems};
    };
  @media screen and (min-width: 1024px) {
    .footerItemsLinks {
      display: ${({ displayItemsLink }) => displayItemsLink};
      flex-direction: ${({ flexDirectionItemsLink }) => flexDirectionItemsLink};
      justify-content: ${({ justifyContentItemsLink }) => justifyContentItemsLink};
      align-items: ${({ alignItemsItemsLink }) => alignItemsItemsLink} ;
    }
    padding: ${({ desktopPadding }) => desktopPadding};
    display: ${({ desktopDisplay }) => desktopDisplay};
    justify-content: ${({ justifyContent }) => justifyContent};
  }
`;

const FooterLinkDiv = styled.div`
  @media screen and (max-width: 1024px) {
    display: ${({ mobileDisplay }) => mobileDisplay};
    flex-direction: ${({ mobileFlexDirection }) => mobileFlexDirection};
    gap: ${({ mobileGap }) => mobileGap};
  }`;

const FooterLink = styled.a`
  color: ${({ color }) => color};
  text-decoration: ${({ textDecoration }) => textDecoration};
  &:hover {
    text-decoration: ${({ hoverTextDecoration }) => hoverTextDecoration};
  };
  &:focus {
    border: ${({ focusBorder }) => focusBorder};
    border-radius: ${({ focusBorderRadius }) => focusBorderRadius};
  };
  &:visited {
    opacity: ${({ visitedOpacity }) => visitedOpacity};
  };

  @media screen and (min-width: 1024px) {
    margin-right: ${({ desktopMargin }) => desktopMargin};
    padding: ${({ padding }) => padding};
  }
`;

const CopyrightInfo = styled.p`
  text-align: ${({ desktopTextAlign }) => desktopTextAlign};
  color: ${({ color }) => color};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ mobileMargin }) => mobileMargin};
  @media screen and (max-width: 1024px) {
    text-align: ${({ mobileTextAlign }) => mobileTextAlign};
  }
`;

const FooterGupyLogo = styled.img`
  @media screen and (max-width: 1024px) {
    height: ${({ height }) => height};
    width: ${({ width }) => width};
    margin-right: ${({ marginRight }) => marginRight};
  };
  @media screen and (min-width: 1024px) {
    display: ${({ desktopDisplay }) => desktopDisplay};
  };
`;

export default { FooterComponent,
  FooterLinkDiv,
  FooterLink,
  CopyrightInfo,
  FooterGupyLogo,
  FooterItens,
};
