import { BaseForm } from '@gupy/front-commons';

class CareerPageConfidentialForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
      subdomain: {
        validate: {
          notEmpty: true,
          maxLength: 32,
        },
      },
    });
  }
}

export default CareerPageConfidentialForm;
