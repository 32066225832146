import { BaseForm } from '@gupy/front-commons';

class BasicInformationFormValidator extends BaseForm {
  constructor() {
    super({
      email: {
        validate: {
          email: true,
          required: true,
        },
      },
      name: {
        validate: {
          required: true,
        },
      },
      lastName: {
        validate: {
          required: true,
        },
      },
      currentStepId: {
        validate: {
          required: true,
        },
      },
      linkedinProfileUrl: {
        validate: {
          linkedinUrl: true,
        },
      },
      candidateInsertionSource: {
        validate: {
          required: true,
        },
      },
    });
  }
}

export default BasicInformationFormValidator;
