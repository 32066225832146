import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

const propTypes = {
  currentUser: PropTypes.object,
  intl: intlShape,
};

const BeamerInjector = ({ currentUser, intl }) => {
  const [isInjected, setIsInjected] = React.useState(false);

  React.useEffect(() => {
    const isPortugueseDefaultLanguage = intl.locale === 'pt';
    const isProduction = process.env.NODE_ENV === 'production';

    if (!isProduction
        || isInjected
        || !isPortugueseDefaultLanguage
        || !currentUser
        || !currentUser.id) {
      return;
    }
    const name = currentUser.name.split(' ');
    const script = document.createElement('script');
    script.src = 'https://app.getbeamer.com/js/beamer-embed.js';
    script.type = 'text/javascript';
    script.defer = true;
    script.onload = () => {
      if (window.Beamer) {
        window.Beamer.update({
          user_id: currentUser.id,
          user_firstname: name[0],
          user_lastname: name.pop(),
          user_email: currentUser.email,
          company_subdomain: currentUser.company.subdomain,
          filter_by_url: true,
        });
      }
    };
    document.body.appendChild(script);
    setIsInjected(true);
  }, [currentUser, intl]);

  return null;
};

BeamerInjector.propTypes = propTypes;

const mapStateToProps = ({ reducers }) => ({
  currentUser: reducers.Authentication && reducers.Authentication.currentUser,
});

export default injectIntl(connect(mapStateToProps)(BeamerInjector));
