import api from '../../api/DarthVaderClient';

export default {
  getAvailableProviders() {
    return api.get('/provider-test/companies/current/test-providers');
  },
  saveProviderToken(payload) {
    return api.post('/provider-test/companies/current/test-providers', payload);
  },
  getAllTestsFromProvider(providerId, language) {
    return api.get(`/provider-test/companies/current/test-providers/test?testProviderId=${providerId}&perPage=150&language=${language}`);
  },
};
