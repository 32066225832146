const jobStatusEnum = {
  draft: 'draft',
  waitingApproval: 'waiting_approval',
  approved: 'approved',
  disapproved: 'disapproved',
  published: 'published',
  frozen: 'frozen',
  closed: 'closed',
  template: 'template',
  canceled: 'canceled',
};

export default jobStatusEnum;
