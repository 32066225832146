import { ApiClient } from '@gupy/api-client';

const centralTalentos = new ApiClient({

  urlBase: process.env.REACT_APP_API_CENTRAL_TALENTOS || '',
});

export {
  centralTalentos,
};
