import { BaseForm } from '@gupy/front-commons';

class UserProfileLanguageForm extends BaseForm {
  constructor() {
    super({
      language: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default UserProfileLanguageForm;
