import { BaseForm } from '@gupy/front-commons';

class ProfileForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
      size: {
        validate: {
          notEmpty: true,
        },
      },
      companyType: {
        validate: {
          notEmpty: true,
        },
      },
      countryOfOrigin: {
        validate: {
          notEmpty: true,
        },
      },
      language: {
        validate: {
          notEmpty: true,
        },
      },
      celphone: {
        validate: {
          notEmpty: true,
          mobilePhone: true,
        },
      },
      email: {
        validate: {
          email: true,
          notEmpty: true,
        },
      },
    });
  }
}

export default ProfileForm;
