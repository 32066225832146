import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  jobBoardsCustomIdsFormSubTitle: {
    id: 'integrations-job-boards-custom-ids-form-sub-title',
    defaultMessage: 'Conecte a página da sua empresa para que suas vagas publicadas na Gupy também possam ser habilitadas e publicadas no Linkedin.',
  },
  jobBoardsCustomIdsFormLinkedinCustomIdLabel: {
    id: 'integrations-job-boards-custom-ids-form-linkedin-custom-id-label',
    defaultMessage: 'Linkedin Company ID',
  },
  jobBoardsCustomIdsFormLinkedinCustomIdPlaceholder: {
    id: 'integrations-job-boards-custom-ids-form-linkedin-custom-id-placeholder',
    defaultMessage: 'Digite o Company Id da sua empresa',
  },
  jobBoardsCustomIdsFormLinkedinCustomIdTipToSearch: {
    id: 'integrations-job-boards-custom-ids-form-linkedin-custom-id-tip-to-search',
    defaultMessage: 'O Company ID é um valor numérico',
  },
  jobBoardsCustomIdsFormLinkedinCustomIdTipToSearchHrefText: {
    id: 'integrations-job-boards-custom-ids-form-linkedin-custom-id-tip-to-search-href-text',
    defaultMessage: 'Saiba como encontrá-lo',
  },
  jobBoardsCustomIdsFormLinkedinCustomIdEmptyError: {
    id: 'integrations-job-boards-custom-ids-form-linkedin-custom-id-empty-error',
    defaultMessage: 'Uma vez cadastrado, este campo não pode ficar vazio.',
  },
  jobBoardsCustomIdsFormCareerPagesLabel: {
    id: 'integrations-job-boards-custom-ids-form-career-pages-label',
    defaultMessage: 'Páginas de carreiras vinculadas',
  },
  jobBoardsCustomIdsFormCareerPagesAllItemsSelectedLabel: {
    id: 'integrations-job-boards-custom-ids-form-career-pages-all-items-selected-label',
    defaultMessage: 'Todas as páginas de carreira selecionadas',
  },
  jobBoardsCustomIdsFormCareerPagesSelectAllLabel: {
    id: 'integrations-job-boards-custom-ids-form-career-pages-select-all-label',
    defaultMessage: 'Selecionar tudo',
  },
  jobBoardsCustomIdsFormCareerPagesPlaceholder: {
    id: 'integrations-job-boards-custom-ids-form-career-pages-placeholder',
    defaultMessage: 'Selecione ou busque uma opção',
  },
  jobBoardsCustomIdsFormCareerPagesEmptyError: {
    id: 'integrations-job-boards-custom-ids-form-career-pages-empty-error',
    defaultMessage: 'Deve haver ao menos uma página de carreira vinculada.',
  },
  jobBoardsCustomIdsFormRemoveBtn: {
    id: 'integrations-job-boards-custom-ids-remove-btn',
    defaultMessage: 'Excluir',
  },
  jobBoardsCustomIdsFormAddCustomIdBtn: {
    id: 'integrations-job-boards-custom-ids-form-add-custom-id-button',
    defaultMessage: 'Adicionar outra página do',
  },
  jobBoardsCustomIdsFormConfidentialAndInternalJobsAlert: {
    id: 'integrations-job-boards-custom-ids-form-confidential-and-internal-jobs-alert',
    defaultMessage: 'As vagas internas e confidenciais não serão exibidas no perfil da empresa no Linkedin',
  },
  jobBoardsCustomIdsFormConfidentialAndInternalJobsAlertHrefText: {
    id: 'integrations-job-boards-custom-ids-form-confidential-and-internal-jobs-alert-href-text',
    defaultMessage: 'Não mostrar novamente',
  },
  jobBoardsCustomIdsRemoveModalDialogContentTitle: {
    id: 'integrations-job-boards-custom-ids-remove-modal-dialog-content-title',
    defaultMessage: 'Deseja realmente excluir este Company ID?',
  },
  jobBoardsCustomIdsRemoveModalDialogActionsBtnConfirm: {
    id: 'integrations-job-boards-custom-ids-remove-modal-dialog-actions-btn-confirm',
    defaultMessage: 'Sim, excluir Company ID',
  },
  jobBoardsCustomIdsRemoveModalDialogActionsBtnCancel: {
    id: 'integrations-job-boards-custom-ids-remove-modal-dialog-actions-btn-cancel',
    defaultMessage: 'Cancelar',
  },
  saveIntegrationsSubmitBtn: {
    id: 'integrations-save-btn',
    defaultMessage: 'Salvar',
  },
  saveIntegrationsSubmitSuccess: {
    id: 'company_integrations_success_message',
    defaultMessage: 'Alterações salvas com sucesso.',
  },
  saveIntegrationsSubmitError: {
    id: 'company_integrations_error_message',
    defaultMessage: 'Não conseguimos gerar as informações necessárias. Reveja os valores inseridos e tente novamente.',
  },
  saml: {
    id: 'saml_grid_content_title',
    defaultMessage: 'SAML',
  },
  interviewEvent: {
    id: 'interview_event_grid_content_title',
    defaultMessage: 'Configure os campos abaixo para ativar o agendamento de entrevistas',
  },
  default: {
    id: 'company_integrations_grid_content_title',
    defaultMessage: 'Configure abaixo as informações de nossas parceiras',
  },
  rscCardTitle: {
    id: 'rsc_card_title',
    defaultMessage: 'LinkedIn Recruiter System Connect',
  },
  rscIntegrationDisabledMessage: {
    id: 'rsc_integration_disabled_message',
    defaultMessage: 'Conecte sua página de carreira ao RSC para exportar pessoas candidatas do LinkedIn diretamente para a Gupy (Disponível para administradores do LinkedIn Recruiter Corporate ou Professional Services).',
  },
  rscIntegrationDisabledKnowMore: {
    id: 'rsc_integration_disabled_know_more',
    defaultMessage: 'Saiba mais.',
  },
  rscConnectButton: {
    id: 'rsc_connect_button',
    defaultMessage: 'Conectar página via RSC',
  },
  rscIntegrationChildAppsLinkedinPageLabel: {
    id: 'rsc_integration_childApps_linkedin_page_label',
    defaultMessage: 'Integração no LinkedIn (ATS)',
  },
  rscIntegrationChildAppsLinkedinPagePlaceholder: {
    id: 'rsc_integration_childApps_linkedin_page_placeholder',
    defaultMessage: 'Crie um nome para integração',
  },
  rscIntegrationChildAppsLinkedinPageDuplicateError: {
    id: 'rsc_integration_childApps_linkedin_page_duplicate_error',
    defaultMessage: 'Nome já utilizado, tente outro.',
  },
  rscIntegrationChildAppsCareerPageLabel: {
    id: 'rsc_integration_childApps_career_page_label',
    defaultMessage: 'Página de carreira',
  },
  rscIntegrationChildAppsCareerPageNoItemsSelectedLabel: {
    id: 'rsc_integration_childApps_career_page_no_items_selected_label',
    defaultMessage: 'Selecione as páginas de carreira',
  },
  rscIntegrationChildAppsCareerPageSelectAllLabel: {
    id: 'rsc_integration_childApps_career_page_select_all_label',
    defaultMessage: 'Todos selecionados',
  },
  rscIntegrationChildAppsSyncStatusLabel: {
    id: 'rsc_integration_childApps_sync_status_label',
    defaultMessage: 'Sincronização',
  },
  rscIntegrationChildAppsEditButton: {
    id: 'rsc_integration_childApps_edit_button',
    defaultMessage: 'Editar',
  },
  rscIntegrationChildAppsDeleteButton: {
    id: 'rsc_integration_childApps_delete_button',
    defaultMessage: 'Excluir',
  },
  rscIntegrationChildAppSyncButton: {
    id: 'rsc_integration_childApp_sync_button',
    defaultMessage: 'Sincronizar',
  },
  rscIntegrationChildAppSaveEdit: {
    id: 'rsc_integration_childApp_save_edit_button',
    defaultMessage: 'Salvar edição',
  },
  rscIntegrationChildAppCancelEditButton: {
    id: 'rsc_integration_childApp_cancel_edit_button',
    defaultMessage: 'Cancelar edição',
  },
  rscIntegrationChildAppEnablingLinkedinFeaturesMessage: {
    id: 'rsc_integration_childApp_enabling_linkedin_features',
    defaultMessage: 'Carregando nova integração. Aguarde....',
  },
  rscGetChildAppsOnPollingError: {
    id: 'rsc_get_childApps_on_polling_error',
    defaultMessage: 'Não foi possível exibir sua lista atualizada de contratos da integração com o RSC Linkedin.',
  },
  rscChildAppCreated: {
    id: 'rsc_child_app_created',
    defaultMessage: 'A configuração inicial foi criada com sucesso',
  },
  rscIntegrationChildAppsStatusReadyToSyncData: {
    id: 'rsc_integration_childApps_status_ready_to_sync_data',
    defaultMessage: 'Não iniciada',
  },
  rscIntegrationChildAppsStatusSyncPending: {
    id: 'rsc_integration_childApps_status_sync_pending',
    defaultMessage: 'Em andamento',
  },
  rscIntegrationChildAppsStatusSyncSuccess: {
    id: 'rsc_integration_childApps_status_sync_success',
    defaultMessage: 'Concluída',
  },
  rscIntegrationChildAppsStatusSyncFail: {
    id: 'rsc_integration_childApps_status_sync_fail',
    defaultMessage: 'Erro na sincronização',
  },
  rscIntegrationChildAppsStatusSyncFailTipText: {
    id: 'rsc_integration_childApps_status_sync_fail_tip_text',
    defaultMessage: 'Ocorreu um erro durante a sincronização.',
  },
  rscIntegrationChildAppsStatusSyncFailTipActionText: {
    id: 'rsc_integration_childApps_status_sync_fail_tip_action_text',
    defaultMessage: 'Sincronizar novamente.',
  },
  rscIntegrationChildAppsStatusDesyncPending: {
    id: 'rsc_integration_childApps_status_desync_pending',
    defaultMessage: 'Excluindo na Gupy',
  },
  rscIntegrationChildAppsStatusDesyncSuccess: {
    id: 'rsc_integration_childApps_status_desync_success',
    defaultMessage: 'Exclusão Gupy concluída',
  },
  rscIntegrationChildAppsStatusDesyncFail: {
    id: 'rsc_integration_childApps_status_desync_fail',
    defaultMessage: 'Erro na exclusão',
  },
  rscIntegrationChildAppsStatusDesyncFailTipText: {
    id: 'rsc_integration_childApps_status_desync_fail_tip_text',
    defaultMessage: 'Ocorreu um erro na exclusão das páginas.',
  },
  rscIntegrationChildAppsStatusDesyncFailTipActionText: {
    id: 'rsc_integration_childApps_status_desync_fail_tip_action_text',
    defaultMessage: 'Tentar novamente.',
  },
  rscChildAppCreatedSuccess: {
    id: 'rsc_child_app_created_success',
    defaultMessage: 'Falta pouco! Você ainda precisa configurar a página de carreira para sincronizar os dados.',
  },
  rscChildAppCreatedError: {
    id: 'rsc_child_app_created_error',
    defaultMessage: 'Não foi possível conectar suas páginas via RSC.',
  },
  rscChildAppSyncSuccessSlice1: {
    id: 'rsc_child_app_sync_success_slice_1',
    defaultMessage: 'Sincronização da página',
  },
  rscChildAppSyncSuccessSlice2: {
    id: 'rsc_child_app_sync_success_slice_2',
    defaultMessage: 'iniciada com sucesso!',
  },
  rscChildAppSyncError: {
    id: 'rsc_child_app_sync_error',
    defaultMessage: 'Não foi possível iniciar a sincronização.',
  },
  rscChildAppEditSuccess: {
    id: 'rsc_child_app_edit_success',
    defaultMessage: 'Edição realizada com sucesso!',
  },
  rscChildAppEditError: {
    id: 'rsc_child_app_edit_error',
    defaultMessage: 'Não foi possível editar o nome da página do Linkedin.',
  },
  rscChildAppSyncSuccessSlice3: {
    id: 'rsc_child_app_sync_success_slice_3',
    defaultMessage: 'Essa ação pode demorar algumas horas.',
  },
  rscChildAppSyncSuccessSlice4: {
    id: 'rsc_child_app_sync_success_slice_4',
    defaultMessage: 'Você pode fechar esta página e a sincronização não será afetada.',
  },
  rscChildAppSyncSuccessSlice5: {
    id: 'rsc_child_app_sync_success_slice_5',
    defaultMessage: 'Avisaremos quando o processo for concluído.',
  },
  rscChildAppDialogCancelEditModeMessage: {
    id: 'rsc_child_app_dialog_cancel_edit_mode_message',
    defaultMessage: 'Tem certeza que deseja sair? Alterações não salvas serão perdidas.',
  },
  rscChildAppDialogCancelEditModeConfirm: {
    id: 'rsc_child_app_dialog_cancel_edit_mode_confirm',
    defaultMessage: 'Sair',
  },
  rscChildAppDialogCancelEditModeContinue: {
    id: 'rsc_child_app_dialog_cancel_edit_mode_conntinue',
    defaultMessage: 'Continuar editando',
  },
  rscChildAppSyncModalTitle: {
    id: 'rsc_child_app_sync_modal_title',
    defaultMessage: 'Iniciar sincronização',
  },
  rscChildAppSyncModalDescriptionP1: {
    id: 'rsc_child_app_sync_modal_description_p1',
    defaultMessage: 'Esta ação irá sincronizar as informações de todas as vagas, pessoas candidatas e aplicações já publicadas em suas páginas de carreira e pode levar algumas horas.',
  },
  rscChildAppSyncModalDescriptionP2: {
    id: 'rsc_child_app_sync_modal_description_p2',
    defaultMessage: 'Estas informações não ficarão públicas.',
  },
  rscChildAppSyncModalDescriptionP3: {
    id: 'rsc_child_app_sync_modal_description_p3',
    defaultMessage: 'Certifique-se de ter selecionado as páginas corretas.',
  },
  rscChildAppSyncModalConfirmButtonLabel: {
    id: 'rsc_child_app_sync_modal_confirm_button_label',
    defaultMessage: 'Iniciar',
  },
  rscChildAppSyncModalCancelButtonLabel: {
    id: 'rsc_child_app_sync_modal_cancel_button_label',
    defaultMessage: 'Cancelar',
  },
  rscTipForUserOfTypeRoot: {
    id: 'rsc_tip_for_user_of_type_root',
    defaultMessage: 'Pessoas com usuários do tipo root não possuem permissão para executar ações na integração com RSC.',
  },
  rscIntegrationColumnNameLabelInfotip: {
    id: 'rsc_integration_column_name_label_infotip',
    defaultMessage: 'Este nome aparecerá na seção exportar para ATS no Linkedin',
  },
  rscIntegrationChildAppReadyToConnectText: {
    id: 'rsc_integration_childApp_ready_to_connect_text',
    defaultMessage: 'Finalize a configuração no LinkedIn ATS Integration para continuar.',
  },
  rscIntegrationChildAppReadyToConnectButton: {
    id: 'rsc_integration_childApp_ready_to_connect_button',
    defaultMessage: 'Finalizar configuração no Linkedin',
  },
  rscIntegrationChildAppDesyncModalTitle: {
    id: 'rsc_integration_childApp_desync_modal_title',
    defaultMessage: 'Excluir conexão',
  },
  rscIntegrationChildAppDesyncModalCancelButton: {
    id: 'rsc_integration_childApp_desync_modal_cancel_button',
    defaultMessage: 'Cancelar',
  },
  rscIntegrationChildAppDesyncModalConfirmButton: {
    id: 'rsc_integration_childApp_desync_modal_confirm_button',
    defaultMessage: 'Iniciar processo',
  },
  rscIntegrationChildAppDesyncModalAlertText: {
    id: 'rsc_integration_childApp_desync_modal_alert_text',
    defaultMessage: 'Após iniciar o processo esta ação não pode ser desfeita',
  },
  rscIntegrationChildAppDesyncModalP1: {
    id: 'rsc_integration_childApp_desync_modal_p1',
    defaultMessage: 'Esta ação irá remover todas as páginas de carreira sincronizadas neste grupo.',
  },
  rscIntegrationChildAppDesyncModalP2: {
    id: 'rsc_integration_childApp_desync_modal_p2',
    defaultMessage: 'Este processo está dividido em 2 etapas (Gupy e Linkedin)',
  },
  rscIntegrationChildAppDesyncModalP3: {
    id: 'rsc_integration_childApp_desync_modal_p3',
    defaultMessage: 'Caso prossiga você receberá uma notificação com orientações para completar os próximos passos',
  },
  rscIntegrationChildAppDesyncModalP4: {
    id: 'rsc_integration_childApp_desync_modal_p4',
    defaultMessage: 'Deseja iniciar o processo?',
  },
  rscIntegrationChildAppsDeleteLinkedinButtondTipText: {
    id: 'rsc_integration_childApps_delete_linkedin_button_tip_text',
    defaultMessage: 'Só é possível iniciar a exclusão no Linkedin após a exclusão na Gupy',
  },
  rscIntegrationChildAppDeleteInLinkedinButton: {
    id: 'rsc_integration_childApp_delete_in_linkedin_button',
    defaultMessage: 'Excluir no Linkedin',
  },
  rscDeleteInLinkedinDialogTitle1: {
    id: 'rsc_delete_in_linkedin_dialog_title_1',
    defaultMessage: 'Excluir conexão Linkedin',
  },
  rscDeleteInLinkedinDialogTitle2: {
    id: 'rscc_delete_in_linkedin_dialog_title_2',
    defaultMessage: 'Aguardando aprovação',
  },
  rscDeleteInLinkedinDialogTitle3: {
    id: 'rsc_delete_in_linkedin_dialog_title_3',
    defaultMessage: 'Conexão excluída',
  },
  rscDeleteInLinkedinStep: {
    id: 'rsc_delete_in_linkedin_step',
    defaultMessage: 'Etapa',
  },
  rscDeleteInLinkedinDialogContinueButton: {
    id: 'rsc_delete_in_linkedin_dialog_continue_button',
    defaultMessage: 'Continuar',
  },
  rscDeleteInLinkedinDialogConfirmButton: {
    id: 'rscc_delete_in_linkedin_dialog_confirm_button',
    defaultMessage: 'Confirmar',
  },
  rscDeleteInLinkedinDialogWaitButton: {
    id: 'rsc_delete_in_linkedin_dialog_wait_button',
    defaultMessage: 'Aguardar',
  },
  rscDeleteInLinkedinDialogOkButton: {
    id: 'rscc_delete_in_linkedin_dialog_ok_button',
    defaultMessage: 'OK',
  },
  rscDeleteInLinkedinStep1Slice1: {
    id: 'rsc_delete_in_linkedin_step_1_slice_1',
    defaultMessage: 'Para dar continuidade ao processo é necessário solicitar ao Linkedin a exclusão ',
  },
  rscDeleteInLinkedinStep1Slice2: {
    id: 'rsc_delete_in_linkedin_step_1_slice_2',
    defaultMessage: 'Copie o texto abaixo, preencha as informações destacadas e envie para o contato indicado',
  },
  rscDeleteInLinkedinStep1Slice3: {
    id: 'rsc_delete_in_linkedin_step_1_slice_3',
    defaultMessage: 'O e-mail deve ser enviado pelo administrador da conta no Linkedin e deve conter o número do contrato e página (ATS) a ser deletada ',
  },
  rscDeleteInLinkedinStep1Slice4: {
    id: 'rsc_delete_in_linkedin_step_1_slice_4',
    defaultMessage: 'Saiba como localizar o número de contrato',
  },
  rscDeleteInLinkedinStep1Slice5: {
    id: 'rsc_delete_in_linkedin_step_1_slice_5',
    defaultMessage: 'Contato:',
  },
  rscDeleteInLinkedinStep1Slice6: {
    id: 'rsc_delete_in_linkedin_step_1_slice_6',
    defaultMessage: 'Mensagem sugerida:',
  },
  rscDeleteInLinkedinStep1CopyButton: {
    id: 'rsc_delete_in_linkedin_step_1_copy_button',
    defaultMessage: 'Copiar texto',
  },
  rscDeleteInLinkedinStep1EmailTemplate: {
    id: 'rsc_delete_in_linkedin_step_1_email_template',
    defaultMessage: 'Pedido de Exclusão da Conexão do Linkedin RSC<br><br>Estou escrevendo em nome da <b>[Nome da sua empresa]</b> para solicitar a exclusão da conexão da página <b>[Nome da página]</b> sob o contrato ID <b>[Número de contrato]</b> do serviço Linkedin Recruitment System utilizado no ATS Gupy.<br><br>Solicitamos que prossigam com a exclusão destes dados de conexão devido à necessidades internas <b>[explicar motivação de forma sucinta]</b><br><br><br>Atenciosamente,<br><b>[Assinatura]</b>',
  },
  rscDeleteInLinkedinStep1Checkbox: {
    id: 'rsc_delete_in_linkedin_step_1_checkbox',
    defaultMessage: 'Declaro que a solicitação de exclusão foi enviada ao Linkedin por e-mail conforme as orientações acima',
  },
  rscDeleteInLinkedinStep2Slice1: {
    id: 'rsc_delete_in_linkedin_step_2_slice_1',
    defaultMessage: 'O Linkedin tem um prazo médio de 5 dias úteis para responder seu pedido de exclusão via e-mail.',
  },
  rscDeleteInLinkedinStep2Slice2: {
    id: 'rsc_delete_in_linkedin_step_2_slice_2',
    defaultMessage: 'Caso não tenha recebido a aprovação, pedimos que aguarde.',
  },
  rscDeleteInLinkedinStep2Slice3: {
    id: 'rsc_delete_in_linkedin_step_2_slice_3',
    defaultMessage: 'Caso já tenha recebido a aprovação basta confirmar abaixo.',
  },
  rscDeleteInLinkedinStep2ErrorTip: {
    id: 'rsc_delete_in_linkedin_step_2_error_tip',
    defaultMessage: 'Não foi possível identificar a aprovação do Linkedin para excluir a conexão.',
  },
  rscDeleteInLinkedinStep3Slice1: {
    id: 'rsc_delete_in_linkedin_step_3_slice_1',
    defaultMessage: 'As páginas conectadas foram excluídas com sucesso!',
  },
  scheduleIntegrationRepackagingTitle: {
    id: 'schedule-integration-repacking-title',
    defaultMessage: 'Agendamento de entrevistas',
  },
  scheduleIntegrationRepackagingCardTitle: {
    id: 'schedule-integration-repacking-card-title',
    defaultMessage: 'Precisa de ajuda?',
  },
  scheduleIntegrationRepackagingKnowMore: {
    id: 'schedule-integration-repacking-know-more',
    defaultMessage: 'Quero saber mais',
  },

});

export const getMessages = intl => formatMessages(intl, messages);
