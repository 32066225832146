import { BaseForm } from '@gupy/front-commons';

class UserAccessProfileForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
      struct: {
        validate: {
          accessProfileStruct: true,
        },
      },
    });
  }
}

export default UserAccessProfileForm;
