const adaptPermissions = (job) => {
  const j = { ...job };
  const permissions = {};

  if (job.permissions && job.permissions.length > 0) {
    job.permissions.forEach((p) => { permissions[p] = true; });
  }

  j.permissions = permissions;
  return j;
};

class JobPermissionsManager {
  static adaptJobsPermissions(jobs) {
    return jobs.map(j => adaptPermissions(j));
  }

  static adaptJobPermissions(job) {
    return adaptPermissions(job);
  }

  static canCreate(permissions = {}) {
    return permissions.create_job_draft
      || permissions.create_job_draft_from_template
      || permissions.create_job_template;
  }

  static canDelete(permissions = {}) {
    return (
      permissions.delete_job_draft
      || permissions.delete_job_waiting_approval
      || permissions.delete_job_approved
      || permissions.delete_job_disapproved
      || permissions.delete_job_template
      || permissions.delete_job_published
      || permissions.delete_job_closed
      || permissions.delete_job_frozen
      || permissions.delete_job_canceled
    );
  }

  static canEdit(permissions = {}) {
    return permissions.edit_job_draft
      || permissions.edit_job_waiting_approval
      || permissions.edit_job_approved
      || permissions.edit_job_disapproved
      || permissions.edit_job_published
      || permissions.edit_job_frozen
      || permissions.edit_job_template
      || permissions.edit_job_closed
      || permissions.edit_job_canceled;
  }

  static canDuplicate(permissions = {}) {
    return permissions.create_job_draft_from_another_job;
  }

  static canShare(permissions = {}) {
    return permissions.share_job;
  }

  static canRequestApproval(permissions = {}) {
    return permissions.move_job_draft_to_waiting_approval;
  }

  static canApprove(permissions = {}) {
    return permissions.move_job_waiting_approval_to_disapproved
      || permissions.move_job_waiting_approval_to_approved;
  }

  static canFreeze(permissions = {}) {
    return permissions.move_job_published_to_frozen;
  }

  static canClose(permissions = {}) {
    return permissions.move_job_published_to_closed
      || permissions.move_job_frozen_to_closed;
  }

  static canPublish(permissions = {}) {
    return permissions.move_job_draft_to_published
      || permissions.move_job_waiting_approval_to_published
      || permissions.move_job_approved_to_published
      || permissions.move_job_disapproved_to_published
      || permissions.move_job_frozen_to_published;
  }

  static canRepublish(permissions = {}) {
    return permissions.move_job_frozen_to_published;
  }

  static canView(permissions = {}) {
    return permissions.view_job
      || permissions.view_job_template;
  }

  static canDuplicateAsTemplate(permissions = {}) {
    return permissions.make_job_as_template;
  }

  static canCancel(permissions = {}) {
    return permissions.move_job_published_to_canceled
      || permissions.move_job_frozen_to_canceled
      || permissions.move_job_closed_to_canceled;
  }
}

export default JobPermissionsManager;
