import PropTypes from 'prop-types';

const childAppPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  clientKey: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  careerPages: PropTypes.arrayOf(PropTypes.shape({
    careerPageId: PropTypes.number.isRequired,
  })).isRequired,
});

export default childAppPropTypes;
