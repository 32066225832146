import { combineReducers } from 'redux';

import { EmailTemplateTypeEnum } from '@gupy/enums';

import JobApplicationActionTypes from '../../../constants/Job/JobApplication/JobApplicationActionTypes';
import { EmailTemplateActionTypes } from '../../../containers/EmailTemplate/Actions';
import JobApplicationCurrentStepType from '../../../constants/Job/JobApplication/JobApplicationCurrentStepType';
import CurriculumDataTabs from '../../../constants/Job/JobApplication/CurriculumDataTabs';
import { OrdableAttributes, ModifierType } from '../../../constants/Job/JobApplication/TableOrdableConstants';
import JobApplicationStatus from '../../../constants/Job/JobApplication/JobApplicationStatus';
import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';
import FeebackAnalyticsInfoTypes from '../../../constants/Job/JobApplication/FeebackAnalyticsInfoTypes';
import RelocateCandidateActionTypes
from '../../../containers/Job/JobApplication/components/RelocateCandidate/RelocateCandidateActionTypes';

const initJobReport = {
  analyticsUniqueData: {
    onlineEngagementPercentage: '',
    jobCountingDownDays: '',
    applicationsCount: '',
  },
  candidatesByStepData: {
    categories: [],
    data: [],
  },
  ageGroupData: [],
  genderData: [],
  referredCandidatesData: [],
  internalCandidatesData: [],
  degreeLevelData: {
    categories: [],
    data: [],
  },
  stateData: {
    categories: [],
    data: [],
  },
  sharingChannelData: {
    categories: [],
    data: [],
  },
  profileTestData: {
    categories: [],
    data: [],
  },
};

const initialDiversityData = {
  stateOfOrigin: null,
  cityOfOrigin: null,
  genderPronoun: null,
  genderIdentity: null,
  sexualOrientation: null,
  raceColor: null,
  nameType: null,
  isLoaded: false,
};

const findAndReplaceThread = (threads, threadId, allowReply) => (
  threads.map(thread => (
    thread.id === threadId
      ? { ...thread, allowReply }
      : thread
  ))
);

const groupApplicationsByCurrentStepId = applications => applications
  .reduce((map, app) => map.set(app.currentStepId, (map.get(app.currentStepId) || 0) + 1),
    new Map());

const canDisqualify = application => [
  JobApplicationStatus.inProcess,
  JobApplicationStatus.hired,
].includes(application.status);

const canMove = (application, nextStep) => (application.jobStep
  ? (application.jobStep.id !== nextStep.id)
  : (application.currentStepId !== nextStep.id));

const canUndoDisqualification = application => application.status === JobApplicationStatus.reproved;

const canUndoWithdrawal = application => application.status === JobApplicationStatus.giveUp;

export const job = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.INIT_REPORT_SUCCESS:
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return { ...state, ...action.job };
  case JobApplicationActionTypes.INIT_CANDIDATE:
    return { ...state, ...action.job, id: action.jobId };
  case JobApplicationActionTypes.INIT:
    return {};
  default:
    return state;
  }
};

export const report = (state = initJobReport, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_REPORT_SUCCESS: {
    const jobReport = {
      analyticsUniqueData: action.analyticsUniqueData,
      candidatesByStepData: action.candidatesByStepData,
      ageGroupData: action.ageGroupData,
      genderData: action.genderData,
      referredCandidatesData: action.referredCandidatesData,
      internalCandidatesData: action.internalCandidatesData,
      degreeLevelData: action.degreeLevelData,
      stateData: action.stateData,
      sharingChannelData: action.sharingChannelData,
      profileTestData: action.profileTestData,
    };
    return Object.assign(
      {},
      state,
      jobReport,
    );
  }
  default:
    return state;
  }
};

export const reportIsLoading = (state = true, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_REPORT_SUCCESS:
    return false;
  case JobApplicationActionTypes.INIT_REPORT:
    return true;
  default:
    return state;
  }
};

export const steps = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_SUCCESS:
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return action.steps;
  default:
    return state;
  }
};

const initialApplicationsCount = {
  all: 0,
  allExcluded: 0,
  disqualified: 0,
  withdrawn: 0,
  byStepId: {},
};

export const virtualStepCount = (state = initialApplicationsCount, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_SUCCESS:
    return {
      ...state,
      all: action.allCount,
      allExcluded: action.allExcludedCount,
      disqualified: action.reprovedCount,
      withdrawn: action.withdrawnCount,
      byStepId: action.steps.reduce((acc, step) => ({
        ...acc,
        [step.id]: step.applicationsCount,
      }), {}),
    };
  case JobApplicationActionTypes.MOVE_SUCCESS: {
    const movable = action.applications.filter(app => canMove(app, action.nextStep));
    const disqualificationsToUndo = movable.filter(canUndoDisqualification);
    const withdrawalsToUndo = movable.filter(canUndoWithdrawal);
    const movableCountByStepId = groupApplicationsByCurrentStepId(movable);
    const disqualificationCountByStepId = groupApplicationsByCurrentStepId(disqualificationsToUndo);
    const withdrawalCountByStepId = groupApplicationsByCurrentStepId(withdrawalsToUndo);
    return {
      ...state,
      disqualified: state.disqualified - disqualificationsToUndo.length,
      withdrawn: state.withdrawn - withdrawalsToUndo.length,
      byStepId: Object.entries(state.byStepId).reduce((acc, [stepIdStr, count]) => {
        const stepId = Number(stepIdStr);
        return {
          ...acc,
          [stepId]: count
          + (stepId === action.nextStep.id ? movable.length : 0)
          + (disqualificationCountByStepId.get(stepId) || 0)
          + (withdrawalCountByStepId.get(stepId) || 0)
          - (movableCountByStepId.get(stepId) || 0),
        };
      }, {}),
    };
  }
  case JobApplicationActionTypes.DISQUALIFY: {
    const disqualifiable = action.applications.filter(canDisqualify);
    const countByStepId = groupApplicationsByCurrentStepId(disqualifiable);
    return {
      ...state,
      disqualified: state.disqualified + disqualifiable.length,
      byStepId: Object.entries(state.byStepId).reduce((acc, [stepId, count]) => ({
        ...acc,
        [stepId]: count - (countByStepId.get(Number(stepId)) || 0),
      }), {}),
    };
  }
  default:
    return state;
  }
};

const initialCurrentStep = {
  type: JobApplicationCurrentStepType.all,
  id: 0,
};

export const currentStep = (state = initialCurrentStep, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT: {
    if (action.step) {
      return action.step;
    }
    return initialCurrentStep;
  }
  case JobApplicationActionTypes.STEP_CHANGED:
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_SUCCESS:
    return action.currentStep;
  default:
    return state;
  }
};

const PAGE_SIZE = 50;

const initialPaginationObject = {
  page: 0,
  perPage: PAGE_SIZE,
  pageCount: 0,
};

export const initialPagination = () => initialPaginationObject;

export const pagination = (state = initialPaginationObject, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.RELOAD_SUCCESS:
    return {
      page: action.applicationsSummary.page,
      perPage: action.applicationsSummary.perPage,
      pageCount: action.applicationsSummary.pageCount,
    };
  case JobApplicationActionTypes.PAGE_CHANGED:
    return action.pagination;
  case JobApplicationActionTypes.STEP_CHANGED:
    return initialPaginationObject;
  default:
    return state;
  }
};

const initialTableOrder = {
  attribute: OrdableAttributes.affinity,
  modifier: ModifierType.DESC,
};

export const tableOrder = (state = initialTableOrder, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_ORDER:
    return action.order;
  default:
    return state;
  }
};

export const applicationsSummary = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.RELOAD_SUCCESS:
    return action.applicationsSummary;
  case JobApplicationActionTypes.MOVE: {
    if (action.currentStep.type === JobApplicationCurrentStepType.all) return state;
    const total = action.applications.filter(app => canMove(app, action.nextStep)).length;
    return {
      ...state,
      recordsTotal: state.recordsTotal - total,
    };
  }
  case JobApplicationActionTypes.DISQUALIFY: {
    if (action.currentStep.type === JobApplicationCurrentStepType.all) return state;
    const total = action.applications.filter(canDisqualify).length;
    return {
      ...state,
      recordsTotal: state.recordsTotal - total,
    };
  }
  default:
    return state;
  }
};

export const isApplicationsLoading = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case JobApplicationActionTypes.BULK_MOVE:
  case JobApplicationActionTypes.STEP_CHANGED:
  case JobApplicationActionTypes.PAGE_CHANGED:
  case JobApplicationActionTypes.RELOAD_FILTERS:
  case JobApplicationActionTypes.BULK_DISQUALIFY:
  case JobApplicationActionTypes.CHANGE_FORM_FILTER:
  case JobApplicationActionTypes.CHANGE_ORDER:
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES:
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE:
  case JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS:
  case JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS:
  case JobApplicationActionTypes.CLEAR_ALL_FILTERS:
  case JobApplicationActionTypes.MOVE:
  case JobApplicationActionTypes.DISQUALIFY:
    return true;
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.INIT_FAIL:
  case JobApplicationActionTypes.RELOAD_SUCCESS:
  case JobApplicationActionTypes.RELOAD_FAIL:
  case JobApplicationActionTypes.CLOSE_MOVE_APPLICATION_MODAL:
  case JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS_FAIL:
  case JobApplicationActionTypes.BULK_MOVE_SUCCESS:
  case JobApplicationActionTypes.BULK_MOVE_FAIL:
  case JobApplicationActionTypes.BULK_DISQUALIFY_SUCCESS:
  case JobApplicationActionTypes.BULK_DISQUALIFY_FAIL:
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES_FAIL:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_FAIL:
    return false;
  default:
    return state;
  }
};

const removeApplications = (applications, idsToRemove) => {
  const removeSet = new Set(idsToRemove);
  return applications.filter(app => !removeSet.has(app.id));
};

const getStatusAfterMove = (action) => {
  if (action.nextStep.type === JobStepTypes.final) {
    return JobApplicationStatus.hired;
  }
  return JobApplicationStatus.inProcess;
};

export const applications = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.RELOAD_SUCCESS:
    return action.applications.map(application => ({
      ...application,
      recurrenceCount: application.boomerangCount,
    }));
  case JobApplicationActionTypes.CHANGE_APPLICATION:
    return state.map((item) => {
      if (item.id === action.application.id) {
        return {
          ...item,
          ...action.application,
        };
      }

      return item;
    });
  case JobApplicationActionTypes.CHANGE_HEADER: {
    const isChecked = action.header.checked;
    const isAllSelected = state.reduce((acc, app) => acc && app.checked, true);

    if (isChecked === true || (isChecked === false && isAllSelected)) {
      return state.map(item => ({
        ...item,
        checked: isChecked,
      }));
    }

    return state;
  }
  case JobApplicationActionTypes.INIT_FAIL:
  case JobApplicationActionTypes.RELOAD_FAIL:
    return [];
  case JobApplicationActionTypes.DISQUALIFY: {
    const applicationIds = action.applications.map(app => app.id);
    if (action.currentStep.type === JobApplicationCurrentStepType.all) {
      const disqualifySet = new Set(applicationIds);
      return state.map((app) => {
        if (!disqualifySet.has(app.id)) return app;
        if (canDisqualify(app)) {
          return {
            ...app,
            checked: false,
            status: JobApplicationStatus.reproved,
          };
        }
        return {
          ...app,
          checked: false,
        };
      });
    }
    return removeApplications(state, applicationIds);
  }
  case JobApplicationActionTypes.MOVE_SUCCESS: {
    const applicationIds = action.applications.map(app => app.id);
    if (action.currentStep.type === JobApplicationCurrentStepType.all) {
      const moveSet = new Set(applicationIds);
      return state.map((app) => {
        if (!moveSet.has(app.id)) return app;
        if (canMove(app, action.nextStep)) {
          return {
            ...app,
            checked: false,
            status: getStatusAfterMove(action),
            jobStep: {
              id: action.nextStep.id,
              name: action.nextStep.name,
              type: action.nextStep.type,
            },
          };
        }
        return {
          ...app,
          checked: false,
        };
      });
    }
    return removeApplications(state, applicationIds);
  }
  default:
    return state;
  }
};

export const header = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_HEADER:
    return {
      ...state,
      ...action.header,
    };
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS:
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_SUCCESS:
  case JobApplicationActionTypes.MOVE:
  case JobApplicationActionTypes.DISQUALIFY:
  case JobApplicationActionTypes.INIT:
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.RELOAD_SUCCESS: {
    return {
      ...state,
      checked: false,
    };
  }
  default:
    return state;
  }
};

export const isSendEmailModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_SEND_EMAIL_MODAL:
    return true;
  case JobApplicationActionTypes.CLOSE_SEND_EMAIL_MODAL:
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_SUCCESS:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_SUCCESS:
    return false;
  default:
    return state;
  }
};

export const isLoadingSelectedTemplate = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.LOAD_SELECTED_TEMPLATE:
    return true;
  case EmailTemplateActionTypes.GET_SUCCESS:
  case EmailTemplateActionTypes.GET_FAIL:
    return false;
  default:
    return state;
  }
};

const initialEmailFormModel = {
  applications: [],
  subject: '',
  htmlMessage: '',
  allowReply: false,
  fileName: '',
  files: [],
  template: null,
  isFeedback: false,
};

export const emailFormModel = (state = initialEmailFormModel, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_EMAIL_TEMPLATE:
    return {
      ...state,
      ...action.model,
      template: action.template && action.template.id ? action.template.id : undefined,
      templateType: action.template && action.template.type ? action.template.type : undefined,
      htmlMessage: action.template ? action.template.body : undefined,
      files: action.template ? action.template.files : [],
      subject: action.template ? action.template.subject : undefined,
      allowReply: action.template ? action.template.allowReply : undefined,
      isFeedback: !!(action.template && action.template.type === EmailTemplateTypeEnum.badFeedback),
    };
  case EmailTemplateActionTypes.GET_SUCCESS:
    return {
      ...state,
      ...action.model,
      htmlMessage: action.modelForm ? action.modelForm.body : undefined,
    };
  case JobApplicationActionTypes.OPEN_SEND_EMAIL_MODAL:
    return {
      ...state,
      subject: action.options && action.options.subject ? action.options.subject : '',
      allowReply: action.options && action.options.allowReply,
      applications: action.applications,
    };
  case JobApplicationActionTypes.CHANGE_EMAIL_FORM_MODEL:
    return {
      ...state,
      ...action.model,
    };
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_SUCCESS:
  case JobApplicationActionTypes.CLEAR_EMAIL_FORM_MODEL:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_SUCCESS:
    return initialEmailFormModel;
  default:
    return state;
  }
};

export const emailFormValidation = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_SEND_EMAIL_MODAL:
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION:
    return {};
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_FAIL:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export const isSendingEmail = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION:
    return true;
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_FAIL:
  case JobApplicationActionTypes.SEND_EMAIL_TO_APPLICATIONS_SUCCESS:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_FAIL:
  case JobApplicationActionTypes.SEND_EMAIL_TO_SINGLE_APPLICATION_SUCCESS:
    return false;
  default:
    return state;
  }
};

const initialTagModel = {
  name: '',
};

export const tagModel = (state = initialTagModel, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_FORM_TAG:
    return {
      ...state,
      ...action.model,
    };
  case JobApplicationActionTypes.ADD_TAG_SUCCESS:
    return initialTagModel;
  default:
    return state;
  }
};

export const tagValidation = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.ADD_TAG:
    return {};
  case JobApplicationActionTypes.CHANGE_FORM_TAG: {
    if (!action.model || !action.model.name || action.model.name === '') {
      return {};
    }

    return state;
  }
  case JobApplicationActionTypes.ADD_TAG_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const initialCompanyTags = {
  list: [],
  suggestions: [],
};

export const companyTags = (state = initialCompanyTags, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_COMPANY_TAGS_SUCCESS:
    return {
      list: action.companyTags.map(item => ({
        name: item.name,
        hash: item.name.toLowerCase(),
      })),
      suggestions: [],
    };
  case JobApplicationActionTypes.CHANGE_FORM_TAG_SUGGESTIONS: {
    const tagName = action.model.name.toLowerCase();
    const suggestions = [];
    const max = 5;

    for (let i = 0; i < state.list.length; i += 1) {
      if (state.list[i].hash.indexOf(tagName) > -1) {
        suggestions.push(state.list[i].name);

        if (suggestions.length === max) {
          break;
        }
      }
    }

    return {
      ...state,
      suggestions,
    };
  }
  case JobApplicationActionTypes.CHANGE_FORM_TAG_CLEAR_SUGGESTIONS:
    return {
      ...state,
      suggestions: [],
    };
  default:
    return state;
  }
};

export const isLoadingCompanyTags = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_TAG_MODAL:
  case JobApplicationActionTypes.GET_COMPANY_TAGS:
    return true;
  case JobApplicationActionTypes.INIT:
  case JobApplicationActionTypes.GET_COMPANY_TAGS_SUCCESS:
  case JobApplicationActionTypes.GET_COMPANY_TAGS_FAIL:
    return false;
  default:
    return state;
  }
};

export const disapprovalReasons = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_DISAPPROVAL_REASONS_SUCCESS:
    return action.disapprovalReasons;
  default:
    return state;
  }
};

const initialNavigatorPagination = {
  page: '',
  total: '',
};

export const curriculumDataTabIndex = (state = CurriculumDataTabs.initial, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_CURRICULUM_DATA_TAB:
    return action.tabIndex;
  default:
    return state;
  }
};

export const commentForm = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.POST_COMMENT_SUCCESS:
  case JobApplicationActionTypes.POST_ATTACHMENT_SUCCESS:
  case JobApplicationActionTypes.UPDATE_USER_COMMENT_SUCCESS:
  case JobApplicationActionTypes.INIT_CANDIDATE:
    return {};
  case JobApplicationActionTypes.CHANGE_FORM_COMMENT:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  default:
    return state;
  }
};

export const clickWhatsApp = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.SAVE_CLICK_WHATSAPP_LINK_SUCCESS:
    return {};
  default:
    return state;
  }
};

export const commentValidation = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.POST_COMMENT:
    return {};
  case JobApplicationActionTypes.POST_COMMENT_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export const favoriteFailed = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE:
  case JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE_SUCCESS:
    return false;
  case JobApplicationActionTypes.CHANGE_APPLICATION_FAVORITE_FAIL:
    return true;
  default:
    return state;
  }
};

export const isCandidateNotFound = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE:
    return false;
  case JobApplicationActionTypes.INIT_CANDIDATE_FAIL:
    return action.error.statusCode === 404;
  default:
    return state;
  }
};

export const hasError500 = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return false;
  case JobApplicationActionTypes.INIT_FAIL:
  case JobApplicationActionTypes.INIT_CANDIDATE_FAIL:
    return action.error.statusCode === 500;
  default:
    return state;
  }
};

export const hasError403 = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return false;
  case JobApplicationActionTypes.INIT_FAIL:
  case JobApplicationActionTypes.INIT_CANDIDATE_FAIL:
    return action.error.statusCode === 403;
  default:
    return state;
  }
};

export const error403Message = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return null;
  case JobApplicationActionTypes.INIT_FAIL:
  case JobApplicationActionTypes.INIT_CANDIDATE_FAIL: {
    if (action.error.statusCode === 403) return action.error.body.message;
    return null;
  }
  default:
    return state;
  }
};

export const jobHasError404 = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return false;
  case JobApplicationActionTypes.INIT_FAIL:
    return action.error.statusCode === 404;
  default:
    return state;
  }
};

export const isTagModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_TAG_MODAL:
    return true;
  case JobApplicationActionTypes.CLOSE_TAG_MODAL:
  case JobApplicationActionTypes.SUBMIT_TAG_MODAL_SUCCESS:
    return false;
  default:
    return state;
  }
};

export const isTagModalSubmiting = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.SUBMIT_TAG_MODAL:
    return true;
  case JobApplicationActionTypes.SUBMIT_TAG_MODAL_SUCCESS:
  case JobApplicationActionTypes.SUBMIT_TAG_MODAL_FAIL:
    return false;
  default:
    return state;
  }
};

const initialTagModalFormModel = {
  lastIndex: 0,
  tags: [],
};

const findTagIndex = (tags, name) => tags.reduce((accumulator, currentValue, currentIndex) => {
  if (name && currentValue.name.toLowerCase() === name.toLowerCase()) {
    return currentIndex;
  }
  return accumulator;
}, -1);

export const tagModalFormModel = (state = initialTagModalFormModel, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_TAG_MODAL:
    return {
      ...initialTagModalFormModel,
      ...action.modelForm,
    };
  case JobApplicationActionTypes.CHANGE_FORM_TAG_MODAL:
    return {
      ...state,
      ...action.modelForm,
    };
  case JobApplicationActionTypes.TAG_MODAL_ADD_TAG: {
    const tags = state.tags.slice();
    const findIndex = findTagIndex(state.tags, action.name);
    const lastIndex = state.lastIndex + 1;

    if (!action.name || action.name === '' || findIndex > -1) {
      return {
        ...state,
        name: '',
      };
    }

    tags.push({ index: lastIndex, name: action.name });

    return {
      ...state,
      name: '',
      lastIndex,
      tags,
    };
  }
  case JobApplicationActionTypes.TAG_MODAL_DELETE_TAG: {
    const tags = state.tags.slice();
    const index = findTagIndex(state.tags, action.name);

    if (index > -1) {
      tags.splice(index, 1);
    }

    return {
      ...state,
      tags,
    };
  }
  default:
    return state;
  }
};

export const tagModalFormValidation = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_TAG_MODAL:
    return {};
  case JobApplicationActionTypes.SUBMIT_TAG_MODAL_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const applicationsToSendFeedback = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK:
    return null;
  case JobApplicationActionTypes.GET_ALL_APPLICATIONS_WITH_NO_FEEDBACK_SUCCESS:
    return action.applicationsToSendFeedback;
  default:
    return state;
  }
};

const finalApplicationsCount = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT:
    return null;
  case JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT_SUCCESS:
    return action.finalApplicationsCount;
  default:
    return state;
  }
};

export const filter = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_FORM_FILTER:
    return {
      ...state,
      ...action.filter,
      hasChanged: state.search !== action.filter.search,
    };
  default:
    return state;
  }
};

export const filterModel = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return [];
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.RELOAD_FILTERS_SUCCESS:
    return action.filters;
  default:
    return state;
  }
};

export const isFilterSaving = (state = false) => state;

const initialCriteriasFilter = {
  averageOfCandidate: true,
  averageOfOthersCandidates: false,
};

export const criteriasFilter = (state = initialCriteriasFilter, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_CRITERIAS_FILTER: {
    if (action.filter.averageOfCandidate === true
        || action.filter.averageOfOthersCandidates === false) {
      return {
        ...state,
        averageOfCandidate: true,
        averageOfOthersCandidates: false,
      };
    }

    if (action.filter.averageOfOthersCandidates === true
        || action.filter.averageOfCandidate === false) {
      return {
        ...state,
        averageOfCandidate: false,
        averageOfOthersCandidates: true,
      };
    }

    return {
      ...state,
      ...action.filter,
    };
  }
  default:
    return state;
  }
};

// === CANDIDATE CURRICULUM
const initialApplication = {
  candidate: {},
};

export const application = (state = initialApplication, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return { ...action.application, recurrenceCount: action.application.boomerangCount };
  case JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS:
    return {
      ...state,
      jobStep: {
        ...state.jobStep,
        id: action.nextStep.id,
        name: action.nextStep.name,
      },
    };
  case JobApplicationActionTypes.CURRICULUM_DISQUALIFY_APPLICATION_SUCCESS:
    return {
      ...state,
      status: JobApplicationStatus.reproved,
      disapprovalReason: action.disapprovalReason,
      disapprovalReasonObservation: action.disapprovalReasonObservation,
    };
  case JobApplicationActionTypes.CURRICULUM_UNDO_REPROVE_SUCCESS:
    return {
      ...state,
      status: JobApplicationStatus.inProcess,
      disapprovalReason: null,
      disapprovalReasonObservation: null,
      jobStep: {
        ...state.jobStep,
        id: action.nextStep.id,
        name: action.nextStep.name,
      },
    };
  case JobApplicationActionTypes.CHANGE_CRITERIA_RATING: {
    if (action.rating < 0 || action.rating > 5) {
      return state;
    }

    return {
      ...state,
      criterias: state.criterias.map((criteria) => {
        if (criteria.id === action.criteriaId) {
          return {
            ...criteria,
            applicationRating: {
              ...criteria.applicationRating,
              rating: action.rating,
            },
          };
        }

        return criteria;
      }),
    };
  }
  case JobApplicationActionTypes.CHANGE_APPLICATION_LIKE: {
    const userLikeValue = state.userLike ? state.userLike.value : null;
    let likeCount = state.likeCount || 0;
    let unlikeCount = state.unlikeCount || 0;

    if (userLikeValue === 1) {
      if (action.value === -1) {
        likeCount -= 1;
        unlikeCount += 1;
      } else {
        likeCount -= 1;
      }
    } else if (userLikeValue === -1) {
      if (action.value === 1) {
        likeCount += 1;
        unlikeCount -= 1;
      } else {
        unlikeCount -= 1;
      }
    } else if (action.value === 1) {
      likeCount += 1;
    } else if (action.value === -1) {
      unlikeCount += 1;
    }

    return {
      ...state,
      likeCount,
      unlikeCount,
      userLike: {
        value: action.value,
      },
    };
  }
  case JobApplicationActionTypes.CHANGE_APPLICATION_CANDIDATE_FAVORITE_SUCCESS: {
    return {
      ...state,
      favoriteCount: state.favoriteCount && state.favoriteCount === 1 ? 0 : 1,
    };
  }
  default:
    return state;
  }
};

export const isCurriculumLoading = (state = true, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE:
    return true;
  case JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_SUCCESS:
  case JobApplicationActionTypes.CURRICULUM_CHANGE_APPLICATION_STEP_FAIL:
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
  case JobApplicationActionTypes.INIT_CANDIDATE_FAIL:
    return false;
  default:
    return state;
  }
};

export const tabMessageIsLoading = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.TAB_MESSAGE_IS_LOADING:
    return true;
  case JobApplicationActionTypes.TAB_MESSAGE_IS_LOADED:
    return false;
  default:
    return state;
  }
};

const updateBodyAndAttachmentMessageBody = (threads, threadId, messageId, data) => {
  const threadMessages = threads.find(thread => thread.id === threadId);
  const messages = threadMessages.messages.map((message) => {
    if (message.id === messageId) {
      return { ...message, ...data };
    }
    return message;
  });

  return threads.map((thread) => {
    if (thread.id === threadId) {
      return { ...thread, messages };
    }
    return thread;
  });
};

export const emailsMessages = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_SINGLE_APPLICANT_EMAILS_SUCCESS:
  case JobApplicationActionTypes.GET_APPLICATION_EMAIL_MESSAGES_SUCCESS:
    return action.emailsMessages;
  case JobApplicationActionTypes.PATCH_THREAD_ALLOW_REPLY:
    return findAndReplaceThread(state, action.threadId, action.allowReply);
  case JobApplicationActionTypes.PATCH_THREAD_ALLOW_REPLY_FAIL:
    return findAndReplaceThread(state, action.threadId, !action.allowReply);

  case JobApplicationActionTypes.GET_CONTENT_MESSAGE: {
    const { threadId, messageId } = action;
    const newData = { isLoading: true };
    const newState = updateBodyAndAttachmentMessageBody(state, threadId, messageId, newData);

    return newState;
  }
  case JobApplicationActionTypes.GET_CONTENT_MESSAGE_SUCCESS: {
    const { threadId, messageId, body, attachments = [] } = action;
    const newData = { body, attachments, isLoading: false };
    const newState = updateBodyAndAttachmentMessageBody(state, threadId, messageId, newData);

    return newState;
  }

  default:
    return state;
  }
};

export const applicationHistory = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_APPLICATION_HISTORY_SUCCESS:
    return action.history;
  default:
    return state;
  }
};

const initialNavigationState = {
  application: {},
  pagination: initialNavigatorPagination,
};

export const navigation = (state = initialNavigationState, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    if (!action.navigation || !action.navigation.application) {
      return state;
    }
    return {
      application: action.navigation && action.navigation.application
        ? action.navigation.application : {},
      pagination: action.navigation.pagination || initialNavigatorPagination,
    };
  case JobApplicationActionTypes.CURRICULUM_DISQUALIFY_APPLICATION_SUCCESS:
    return {
      ...state,
      application: {
        ...state.application,
        status: JobApplicationStatus.reproved,
        disapprovalReasons: action.disapprovalReason,
        disapprovalReasonObservation: action.disapprovalReasonObservation,
      },
    };
  case JobApplicationActionTypes.CURRICULUM_UNDO_REPROVE_SUCCESS:
    return {
      ...state,
      application: {
        ...state.application,
        status: JobApplicationStatus.inProcess,
        disapprovalReason: null,
        disapprovalReasonObservation: null,
      },
    };
  default:
    return state;
  }
};

export const isLoadingProfilePagination = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE:
    return true;
  case JobApplicationActionTypes.GET_PROFILE_PAGINATION_SUCCESS:
  case JobApplicationActionTypes.GET_PROFILE_PAGINATION_FAIL:
    return false;
  default:
    return state;
  }
};

export const profilePagination = (state = { page: 0, total: 0 }, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_PROFILE_PAGINATION_SUCCESS:
    return {
      total: action.profilePagination.total,
      page: action.profilePagination.page,
      previousApplicationId: action.profilePagination.previousApplicationId,
      nextApplicationId: action.profilePagination.nextApplicationId,
    };
  default:
    return state;
  }
};

const initialModalFiltersData = {
  additionalQuestionsList: [],
  availableFilters: [],
  coursesList: [],
  criterias: [],
  customTests: [],
  externalTests: [],
  genderList: [],
  jobAddress: {},
  languageLevelsList: [],
  languageList: [],
  schoolingLevels: [],
  tagsList: [],
  messageTypeList: [],
};

export const modalFiltersData = (state = initialModalFiltersData, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_FILTERS_SUCCESS:
    return {
      additionalQuestionsList: action.additionalQuestionsList,
      availableFilters: action.availableFilters,
      coursesList: action.coursesList,
      criterias: action.criterias,
      customTests: action.customTests,
      externalTests: action.externalTests,
      genderList: action.genderList,
      jobAddress: action.jobAddress,
      languageLevelsList: action.languageLevelsList,
      languageList: action.languageList,
      schoolingLevels: action.schoolingLevels,
      tagsList: action.tagsList,
      messageTypeList: action.messageTypeList,
    };
  default:
    return state;
  }
};

export const isLoadingModalFilters = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_FILTERS:
    return true;
  case JobApplicationActionTypes.INIT_FILTERS_SUCCESS:
  case JobApplicationActionTypes.INIT_FILTERS_FAIL:
    return false;
  default:
    return state;
  }
};

export const preHireInfo = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_PRE_HIRE_INFO_SUCCESS:
    return action.preHireInfo;
  default:
    return state;
  }
};

export const hasPreHireStep = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return action.shouldShowPreHireField;
  default:
    return state;
  }
};

export const addressSuggestions = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS:
    return action.suggestions;
  case JobApplicationActionTypes.SELECT_ADDRESS_SUGGESTION:
    return action.suggestion;
  case JobApplicationActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL:
    return [];
  default:
    return state;
  }
};

export const timeline = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_TIMELINE_SUCCESS:
    return action.payload;
  case JobApplicationActionTypes.GET_TIMELINE_FAIL:
    return [];
  default:
    return state;
  }
};

export const hasImproperMove = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_TIMELINE_SUCCESS:
    return action.improperMove;
  case JobApplicationActionTypes.GET_TIMELINE_FAIL:
    return false;
  default:
    return state;
  }
};

export const moveApplicationErrorMessage = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_MOVE_APPLICATION_MODAL:
    return action.errorMessage;
  default:
    return state;
  }
};

export const isMoveApplicationModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_MOVE_APPLICATION_MODAL:
    return true;
  case JobApplicationActionTypes.CLOSE_MOVE_APPLICATION_MODAL: {
    return false;
  }
  default:
    return state;
  }
};

export const isFilterMenuOpen = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case JobApplicationActionTypes.CLOSE_FILTER_MENU:
    return false;
  case JobApplicationActionTypes.OPEN_FILTER_MENU:
    return true;
  default:
    return state;
  }
};

export const applicationsFilters = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return {};
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.RELOAD_FILTERS_SUCCESS:
  case JobApplicationActionTypes.SAVE_APPLICATIONS_FILTERS_SUCCESS:
  case JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS_SUCCESS:
    return action.applicationsFilters;
  case JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS:
    return {
      ...state,
      filterV2Value: {
        ...state.filterV2Value,
        filters: state.filterV2Value.filters.filter(f => f.field !== action.fieldToRemove),
      },
    };
  case JobApplicationActionTypes.CLEAR_ALL_FILTERS:
    return {
      ...state,
      filterV2Value: {
        ...state.filterV2Value,
        filters: [],
      },
    };
  default:
    return state;
  }
};

export const backgroundOperationsCount = (state = 0, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.MOVE:
  case JobApplicationActionTypes.DISQUALIFY:
    return state + 1;
  case JobApplicationActionTypes.MOVE_SUCCESS:
  case JobApplicationActionTypes.MOVE_FAIL:
  case JobApplicationActionTypes.DISQUALIFY_SUCCESS:
  case JobApplicationActionTypes.DISQUALIFY_FAIL:
    return state - 1;
  default:
    return state;
  }
};

export const applicationsBuffer = (state = [], action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case JobApplicationActionTypes.STEP_CHANGED:
    return [];
  default:
    return state;
  }
};

export const isStepChanging = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.STEP_CHANGED:
    return true;
  case JobApplicationActionTypes.RELOAD_SUCCESS:
  case JobApplicationActionTypes.RELOAD_FAIL:
    return false;
  default:
    return state;
  }
};

export const isRemovingFilter = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS:
  case JobApplicationActionTypes.CLEAR_ALL_FILTERS:
    return true;
  case JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS_SUCCESS:
  case JobApplicationActionTypes.REMOVE_APPLICATIONS_FILTERS_FAIL:
  case JobApplicationActionTypes.CLEAR_ALL_FILTERS_SUCCESS:
  case JobApplicationActionTypes.CLEAR_ALL_FILTERS_FAIL:
    return false;
  default:
    return state;
  }
};

export const isInitialApplicationsLoading = (state = true, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return true;
  case JobApplicationActionTypes.INIT_SUCCESS:
  case JobApplicationActionTypes.INIT_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLoadingFiltersData = (state = true, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_FILTERS:
    return true;
  case JobApplicationActionTypes.INIT_FILTERS_SUCCESS:
  case JobApplicationActionTypes.INIT_FILTERS_FAIL:
    return false;
  default:
    return state;
  }
};
const initialWhatsAppBusinessModal = {
  applications: [],
};

export const whatsAppBusinessModal = (state = initialWhatsAppBusinessModal, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_SEND_WHATSAPP_BUSINESS_MODAL:
    return {
      ...state,
      applications: action.applications,
    };
  default:
    return state;
  }
};

export const isSendWhatsAppBusinessModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.OPEN_SEND_WHATSAPP_BUSINESS_MODAL:
    return true;
  case JobApplicationActionTypes.CLOSE_SEND_WHATSAPP_BUSINESS_MODAL:
    return false;
  default:
    return state;
  }
};

export const isWhatsAppBusinessAvailable = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_WHATSAPP_BUSINESS_AVAILABLE_SUCCESS:
    return action.isWhatsAppBusinessAvailable;
  case JobApplicationActionTypes.GET_WHATSAPP_BUSINESS_AVAILABLE_FAIL:
    return false;
  default:
    return state;
  }
};

export const diversityData = (state = initialDiversityData, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return {
      ...state,
      isLoaded: false,
    };
  case JobApplicationActionTypes.INIT_CANDIDATE_CURRICULUM_SUCCESS:
    return {
      ...state,
      ...action
        .payload,
      isLoaded: true,
    };
  case JobApplicationActionTypes.INIT_CANDIDADE_CURRICULUM_FAIL:
    return state;
  default:
    return state;
  }
};

export const candidateDocuments = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_CANDIDATE_DOCUMENTS_SUCCESS: {
    const { documents, documentType } = action.payload;
    return {
      ...state,
      [documentType]: documents,
    };
  }
  default:
    return state;
  }
};

const ONE_MINUTE_IN_MS = 60000;
const removeExpiredStepsFromState = (state = {}) => Object.keys(state)
  .filter(stepId => state[stepId].lastUpdate > new Date() - ONE_MINUTE_IN_MS)
  .reduce((curr, stepId) => ({ ...curr, [stepId]: state[stepId] }), {});

const removeApplicationsFromSteps = (state = {}, applicationIds = []) => {
  const applicationsSet = new Set(applicationIds);
  return Object.keys(state).reduce((curr, stepId) => ({
    ...curr,
    [stepId]: {
      lastUpdate: state[stepId].lastUpdate,
      applicationIds: state[stepId].applicationIds.filter(id => !applicationsSet.has(id)),
    },
  }), {});
};

export const getStepKeyByAction = (action) => {
  switch (action.type) {
  case JobApplicationActionTypes.MOVE:
    return action.nextStep && action.nextStep.id;
  case JobApplicationActionTypes.DISQUALIFY:
    return JobApplicationCurrentStepType.reproved;
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE:
    return action.currentStep ? action.currentStep.id : action.currentStep.type;
  default:
    throw new Error('applicationsNotIndexed: invalid action type');
  }
};

export const applicationsNotIndexed = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_SUCCESS:
    return removeExpiredStepsFromState(action.applicationsNotIndexed);
  case JobApplicationActionTypes.MOVE:
  case JobApplicationActionTypes.DISQUALIFY:
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE: {
    const targetStepKey = getStepKeyByAction(action);
    const stepsNotExpired = removeExpiredStepsFromState(state);
    const applicationIds = action.applications.map(app => app.id || app);
    const applicationsAlreadyInStep = state[targetStepKey]
      ? state[targetStepKey].applicationIds
      : [];

    return {
      ...removeApplicationsFromSteps(stepsNotExpired, applicationIds),
      [targetStepKey]: {
        lastUpdate: new Date(),
        applicationIds: [...applicationsAlreadyInStep, ...applicationIds],
      },
    };
  }
  case JobApplicationActionTypes.MOVE_FAIL:
  case JobApplicationActionTypes.DISQUALIFY_FAIL:
  case JobApplicationActionTypes.APPLICATION_UNDO_REPROVE_FAIL: {
    const applicationIds = action.applications.map(app => app.id || app);
    return removeApplicationsFromSteps(state, applicationIds);
  }
  default:
    return state;
  }
};

export const candidateMoveTimeByStep = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.MOVE:
    return {
      ...removeExpiredStepsFromState(state),
      [action.nextStep.id]: { lastUpdate: new Date() },
    };
  default:
    return state;
  }
};

const miliSecondsToDays = ms => Math.floor(ms / (1000 * 60 * 60 * 24));

const daysSinceDate = date => miliSecondsToDays(Date.now() - date);

const feedbackAnalyticsInfoInitialState = {
  daysSincePublished: 0,
  applicationsCountWithoutFeedback: 0,
  daysSinceOldestApplicationWithoutFeedback: 0,
};

export const feedbackAnalyticsInfo = (state = feedbackAnalyticsInfoInitialState, action) => {
  const { payload, type } = action;
  switch (type) {
  case FeebackAnalyticsInfoTypes.GET_INFO_SUCCESS:
    return { ...state,
      applicationsCountWithoutFeedback: payload.applicationsCountWithoutFeedback || 0,
      daysSinceOldestApplicationWithoutFeedback:
      payload.daysSinceOldestApplicationWithoutFeedback || 0,
    };
  case JobApplicationActionTypes.INIT_SUCCESS:
    return { ...state, daysSincePublished: daysSinceDate(new Date(action.job.publishedAt)) };
  case FeebackAnalyticsInfoTypes.GET_INFO_FAIL:
    return {};
  default:
    return state;
  }
};

export const offers = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_OFFERS_SUCCESS:
    return {
      isLoading: false,
      data: action.payload.offers,
    };
  case JobApplicationActionTypes.GET_OFFERS_FAIL:
    return {
      ...state,
      isLoading: false,
    };
  default:
    return state;
  }
};

export const surveys = (state = { isLoading: true, data: [] }, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_ENGAGE_SURVEYS_SUCCESS:
    return {
      isLoading: false,
      data: action.payload.surveys,
    };
  case JobApplicationActionTypes.GET_ENGAGE_SURVEYS_FAIL:
    return {
      ...state,
      isLoading: false,
    };
  default:
    return state;
  }
};

export const rscLinkedinIntegrationChildApp = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return action.rscLinkedinIntegrationChildApp;
  default:
    return state;
  }
};

export const rscLinkedinIntegrationInMails = (
  state = [],
  action,
) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
  case JobApplicationActionTypes.POST_LINKED_RSC_MEMBER_SUCCESS:
  case JobApplicationActionTypes.POST_UNLINKED_RSC_MEMBER_SUCCESS:
    return action.rscLinkedinIntegrationInMails;
  default:
    return state;
  }
};

export const rscLinkedinIntegrationCandidateProfile = (
  state = null,
  action,
) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return action.rscLinkedinIntegrationCandidateProfile;
  default:
    return state;
  }
};

export const isRscLinkedinIntegrationServiceOnError = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS:
    return action.isRscLinkedinIntegrationServiceOnError;
  default:
    return state;
  }
};

export const isRscLinkedinIntegrationInMailsLoading = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.POST_LINKED_RSC_MEMBER:
  case JobApplicationActionTypes.POST_UNLINKED_RSC_MEMBER:
    return true;
  case JobApplicationActionTypes.POST_LINKED_RSC_MEMBER_SUCCESS:
  case JobApplicationActionTypes.POST_LINKED_RSC_MEMBER_FAIL:
  case JobApplicationActionTypes.POST_UNLINKED_RSC_MEMBER_SUCCESS:
  case JobApplicationActionTypes.POST_UNLINKED_RSC_MEMBER_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLikeChangeLoading = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.CHANGE_APPLICATION_LIKE:
    return true;
  case JobApplicationActionTypes.CHANGE_APPLICATION_LIKE_SUCCESS:
  case JobApplicationActionTypes.CHANGE_APPLICATION_LIKE_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  job,
  offers,
  surveys,
  report,
  steps,
  virtualStepCount,
  currentStep,
  applications,
  application,
  isCurriculumLoading,
  header,
  initialPagination,
  pagination,
  isSendEmailModalOpen,
  emailFormModel,
  emailFormValidation,
  disapprovalReasons,
  tagModel,
  tagValidation,
  companyTags,
  isLoadingCompanyTags,
  curriculumDataTabIndex,
  applicationHistory,
  commentForm,
  commentValidation,
  hasError500,
  hasError403,
  error403Message,
  jobHasError404,
  applicationsSummary,
  reportIsLoading,
  isApplicationsLoading,
  isTagModalOpen,
  isTagModalSubmiting,
  isSendingEmail,
  tagModalFormModel,
  tagModalFormValidation,
  navigation,
  applicationsToSendFeedback,
  finalApplicationsCount,
  emailsMessages,
  filter,
  filterModel,
  isFilterSaving,
  criteriasFilter,
  modalFiltersData,
  isLoadingModalFilters,
  tableOrder,
  preHireInfo,
  hasPreHireStep,
  favoriteFailed,
  clickWhatsApp,
  addressSuggestions,
  timeline,
  hasImproperMove,
  isMoveApplicationModalOpen,
  moveApplicationErrorMessage,
  isCandidateNotFound,
  tabMessageIsLoading,
  isFilterMenuOpen,
  applicationsFilters,
  backgroundOperationsCount,
  applicationsBuffer,
  isStepChanging,
  isRemovingFilter,
  isInitialApplicationsLoading,
  isLoadingFiltersData,
  isSendWhatsAppBusinessModalOpen,
  whatsAppBusinessModal,
  isWhatsAppBusinessAvailable,
  diversityData,
  isLoadingProfilePagination,
  profilePagination,
  candidateDocuments,
  applicationsNotIndexed,
  isLoadingSelectedTemplate,
  feedbackAnalyticsInfo,
  candidateMoveTimeByStep,
  rscLinkedinIntegrationChildApp,
  rscLinkedinIntegrationInMails,
  rscLinkedinIntegrationCandidateProfile,
  isRscLinkedinIntegrationInMailsLoading,
  isRscLinkedinIntegrationServiceOnError,
  isLikeChangeLoading,
});
