import * as ActionTypes from './ActionTypes';

export const openAnalyticsCalloutModal = ({ id, name, publishedAt }) => ({
  type: ActionTypes.OPEN_CALLOUT_MODAL,
  payload: {
    id,
    name,
    publishedAt,
  },
});

export const closeAnalyticsCalloutModal = () => ({
  type: ActionTypes.CLOSE_CALLOUT_MODAL,
});
