import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useFlag } from '@unleash/proxy-client-react';

import { HotjarHelper } from '@gupy/front-commons';
import { Pagination } from '@gupy/design-system-v2';
import { Button, InputGroup, Spinner } from '@gupy/design-system';
import HigherPlanWarning from '../../../../components/HigherPlanWarning/HigherPlanWarning';

import { openConfirmDialog } from '../../../../actions/App/AppActions';
import {
  archiveCompanyCustomTest,
  deleteCompanyCustomTest,
  duplicateCompanyCustomTest,
  favoriteCompanyCustomTest,
  listCompanyCustomTest,
  onCustomTestSearchUpdate,
  selectCustomTestCard,
} from '../../redux/CustomTest/CustomTestActions';
import { CustomTestCard } from '../../CustomTestComponents';
import { TestsPath } from '../../CustomTestRoutes';
import { getMessages } from '../../Messages';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  CustomTest: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  requestOpenConfirmDialog: PropTypes.func.isRequired,
  requestListCompanyCustomTest: PropTypes.func.isRequired,
  requestDeleteCompanyCustomTest: PropTypes.func.isRequired,
  requestDuplicateCompanyCustomTest: PropTypes.func.isRequired,
  requestArchiveCompanyCustomTest: PropTypes.func.isRequired,
  requestFavoriteCompanyCustomTest: PropTypes.func.isRequired,
  requestSelectCustomTestCard: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  permissions: PropTypes.object.isRequired,
  isBlocked: PropTypes.bool,
};

const TestsManagement = ({
  Authentication,
  CustomTest,
  history,
  requestOpenConfirmDialog,
  requestListCompanyCustomTest,
  requestDeleteCompanyCustomTest,
  requestArchiveCompanyCustomTest,
  requestDuplicateCompanyCustomTest,
  requestFavoriteCompanyCustomTest,
  requestSelectCustomTestCard,
  intl,
  permissions,
  isBlocked,
}) => {
  const {
    customTestList,
    selectedCards,
    isLoading,
  } = CustomTest;
  const [activePage, setActivePage] = useState(1);
  const [searchString, setSearchString] = useState('');
  const messages = getMessages(intl);
  const showTests = customTestList.data;
  const isSurveyForKenobyMigratedCompaniesEnable = useFlag('time-qualificacao.survey-kenoby-migrated-companies');

  useEffect(() => {
    if (isSurveyForKenobyMigratedCompaniesEnable) {
      HotjarHelper.triggerPool('companies_migradas_k_pesquisa_qualificacao2023');
    }
  }, [isSurveyForKenobyMigratedCompaniesEnable]);

  useEffect(() => {
    requestListCompanyCustomTest({ page: activePage, title: searchString });
  }, [activePage, searchString]);

  const resultLabel = (
    <FormattedMessage
      id="search_one_result_message"
      defaultMessage="{count, plural, one {# resultado} other {# resultados}}"
      values={{ count: (customTestList && customTestList.totalRecords) || 0 }}
    />
  );

  const handleSearch = (event) => {
    setSearchString(event.target.value);
  };

  const handleCardCheck = (customTest, selected) => {
    if (customTest.inUse) {
      requestOpenConfirmDialog({
        title: messages.selectToDeleteTestTitle,
        message: messages.selectToDeleteTestMessage,
      });
    } else {
      requestSelectCustomTestCard(customTest.id, selected);
    }
  };

  const handleDeleteTest = (customTest) => {
    const archiveTestConfirmDescription = (
      <div>
        <FormattedMessage
          id="delete_test_top_description"
          defaultMessage="Ao excluir esse teste customizado, ele deixará de ser exibido na listagem de testes."
        />
        <br/><br/>
        <b>
          <FormattedMessage
            id="delete_test_bold_mid_description"
            defaultMessage="Em templates ou em vagas rascunho, o teste customizado continuará a ser exibido nas etapas que foram configuradas antes da exclusão."
          />
        </b>
        <br/><br/>
        <FormattedMessage
          id="delete_test_end_description"
          defaultMessage="Recomendamos que revise essas etapas com o teste customizado deletado antes de publicá-las."
        />
        <br/><br/>
      </div>
    );

    let dialogProps;
    const requestStatusMessages = {
      success: messages.deleteTestSuccess,
      error: messages.deleteTestError,
    };

    if (customTest.inUse) {
      dialogProps = {
        title: messages.deleteTestTitle,
        message: archiveTestConfirmDescription,
        confirmButtonText: messages.deleteTestConfirm,
        cancelButtonText: messages.deleteTestCancel,
        onConfirmClick: () => requestArchiveCompanyCustomTest(
          [customTest.id],
          requestStatusMessages,
        ),
      };
    } else {
      dialogProps = {
        title: messages.deleteTestMessage,
        confirmButtonText: messages.deleteTestConfirm,
        cancelButtonText: messages.deleteTestCancel,
        onConfirmClick: () => requestDeleteCompanyCustomTest(
          [customTest.id],
          requestStatusMessages,
        ),
      };
    }

    requestOpenConfirmDialog(dialogProps);
  };

  const handleDuplicateTest = (customTest) => {
    const {
      id,
      title,
      createdAt,
      instructions,
      questionsCount,
      timeLimit,
      isRandomQuestions,
    } = customTest;

    const duplicatesCustomTest = {
      id,
      createdBy: Authentication.currentUser.id,
      title: `${title} - Cópia`,
      createdAt,
      instructions,
      questionsCount,
      isRandomQuestions,
      timeLimit,
    };

    requestOpenConfirmDialog({
      title: messages.duplicateTestTitle,
      message: messages.duplicateTestMessage,
      confirmButtonText: messages.duplicateTestConfirm,
      cancelButtonText: messages.duplicateTestCancel,
      onConfirmClick: () => requestDuplicateCompanyCustomTest(
        duplicatesCustomTest,
        {
          success: messages.duplicateTestSuccess,
          error: messages.duplicateTestError,
        },
      ),
    });
  };

  const handleEditTest = (customTest) => {
    history.push(`${TestsPath}/${customTest.id}/edit`);
  };

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    isBlocked ? (
      <HigherPlanWarning
        buttonMessage={messages.customTestsRepackagingButtonMessage}
        description={messages.customTestsRepackagingDescription}
        key={1}
        currentUser={Authentication.currentUser}
      />
    ) : (
      <div className="custom-tests-management">
        <section>
          <div className="tests-management-search">
            <div className="tests-management-search__block">
              <InputGroup
                id="filter-title"
                name="filter-title"
                label={messages.searchTest}
                value={searchString}
                onChange={handleSearch}
                disable={isLoading}
                block
              />
            </div>
            {
              permissions.create_custom_test ? (
                <div className="tests-management-search__block">
                  <Button
                    size="md"
                    onClick={() => {
                      window.location.pathname = '/companies/setup/custom-tests/create';
                    }}
                  >
                    {messages.createNewTest}
                  </Button>

                </div>
              ) : null
            }
          </div>
        </section>
        <hr />
        {isLoading ? (
          <div
            className="custom-tests-management__loading"
            data-testid="custom-tests-management-loading"
          >
            <Spinner color="secondary" />
          </div>
        ) : null}
        {showTests && showTests.length
          ? (
            <div>
              {showTests.length > 0 ? resultLabel : null}
              <div className="test-cards row">
                {showTests.map(customTest => (
                  <CustomTestCard
                    customTest={customTest}
                    actions={[
                      {
                        icon: customTest.isFavorite ? 'star' : 'star-o',
                        text: 'Favoritar',
                        onClick: () => requestFavoriteCompanyCustomTest(
                          customTest.id,
                          !customTest.isFavorite,
                        ),
                        showButton: true,
                      },
                      {
                        icon: 'copy',
                        text: 'Duplicar',
                        onClick: () => handleDuplicateTest(customTest),
                        showButton: permissions.create_custom_test,
                      },
                      {
                        icon: 'edit',
                        text: 'Editar',
                        onClick: () => handleEditTest(customTest),
                        showButton: permissions.edit_custom_test,
                      },
                      {
                        icon: 'trash',
                        text: 'Excluir',
                        onClick: () => handleDeleteTest(customTest),
                        showButton: permissions.delete_custom_test,
                      },
                    ]}
                    checked={selectedCards.includes(customTest.id)}
                    onCheck={({ target }) => handleCardCheck(customTest, target.value)}
                    key={customTest.id.toString()}
                  />
                ))}
              </div>
              {customTestList && customTestList.pageCount > 1 && (
                <div
                  className="custom-tests-management__pagination"
                  data-testid="custom-tests-management-pagination"
                >
                  <Pagination
                    activePage={activePage}
                    ariaLabelNextButton="Vá para próxima página"
                    ariaLabelPageButton="Página"
                    ariaLabelPagination="Paginação do resultado da busca"
                    ariaLabelPrevButton="Vá para página anterior"
                    onPageChange={handlePageChange}
                    totalPages={customTestList && customTestList.pageCount}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className="emptyDatatable">
              <h4><i className="fa fa-file-text-o" /></h4>
              {searchString
                ? (
                  <p className="title">
                    {messages.noCustomTestFoundMessage}
                  </p>
                ) : (
                  <p className="title">
                    {messages.noCustomTestRegisteredMessage}
                  </p>
                )
              }
            </div>
          )
        }
      </div>
    )
  );
};

TestsManagement.propTypes = propTypes;

const mapStateToProps = state => ({
  CustomTest: state.reducers.CustomTest,
  Authentication: state.reducers.Authentication,
});

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    requestOpenConfirmDialog: openConfirmDialog,
    requestListCompanyCustomTest: listCompanyCustomTest,
    requestOnCustomTestSearchUpdate: onCustomTestSearchUpdate,
    requestDeleteCompanyCustomTest: deleteCompanyCustomTest,
    requestArchiveCompanyCustomTest: archiveCompanyCustomTest,
    requestDuplicateCompanyCustomTest: duplicateCompanyCustomTest,
    requestFavoriteCompanyCustomTest: favoriteCompanyCustomTest,
    requestSelectCustomTestCard: selectCustomTestCard,
  },
)(TestsManagement)));
