const ProfileTestStep = {
  introduction: 'introduction',
  slideIntro: 'slideIntro',
  slideQuestion: 'slideQuestion',
  sortIntro: 'sortIntro',
  sortQuestion: 'sortQuestion',
  conclusion: 'conclusion',
};

export default ProfileTestStep;
