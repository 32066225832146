class RegistrationSchemaHelper {
  static updateSchemaState(state, schema, key, value) {
    return {
      ...state,
      [schema]: { ...state[schema],
        [key]: {
          ...state[schema][key], ...value },
      },
    };
  }

  static adaptStateToPayload(state) {
    const { additionalInfo } = state;
    const {
      academicFormations, professionalExperiences, languages, achievements,
    } = state.experience;

    const {
      linkedinProfileUrl,
      ...personalInfo
    } = state.personalInfo;

    return {
      personalInfo,
      academicFormations: { academicFormations },
      professionalExperiences: { professionalExperiences },
      languages: { languages },
      achievements: { achievements },
      socialMedia: { linkedinProfileUrl },
      additionalInfo,
    };
  }

  static adaptPayloadToState(payload) {
    return {
      personalInfo: {
        ...payload.personalInfo,
        linkedinProfileUrl: payload.socialMedia.linkedinProfileUrl,
      },
      experience: {
        ...payload.academicFormations,
        ...payload.professionalExperiences,
        ...payload.languages,
        ...payload.achievements,
      },
      additionalInfo: {
        ...payload.additionalInfo,
      },
    };
  }
}

export default RegistrationSchemaHelper;
