import { put, takeLatest } from 'redux-saga/effects';
import * as ActionTypes from './ActionTypes';
import curriculumService from './Service';

export const adaptFromService = serviceResponse => ({
  ...serviceResponse,
  languages: serviceResponse.language,
  achievements: serviceResponse.achievement,
});

function* getCurriculumInfo({ payload }) {
  try {
    const { body } = yield curriculumService.getCurriculumInfo(payload);
    const curriculumInfo = adaptFromService(body);

    yield put({
      type: ActionTypes.GET_CURRICULUM_INFO_SUCCESS,
      curriculumInfo,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_CURRICULUM_INFO_FAIL,
      error,
    });
  }
}

function* CurriculumDownloadSaga() {
  yield takeLatest(ActionTypes.GET_CURRICULUM_INFO_REQUEST, getCurriculumInfo);
}

export default CurriculumDownloadSaga;
