export const listEmployeesPageLength = 10;

export const EMPLOYEE_FORM = {
  BRAZIL_CODE: 'BR',

  CREATE_MODE: 'create',
  UPDATE_MODE: 'update',

  INITIAL_ERRORS: {},
  INITIAL_VALUES: {
    name: '',
    countryOfOrigin: '',
    identificationDocument: '',
  },

  IDENTIFICATION_DOCUMENT_MASK: [
    /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,
  ],
};

export const SUPPORTED_EXTENSIONS = ['xls', 'xlsx'];

export const INTERNAL_RECRUITMENT_ENDPOINT = '/internal-recruitment';

export const TOAST_TYPES = {
  CREATE: 'create',
  DELETE: 'delete',
  UPDATE: 'update',
};

export const TOAST_TYPE_MESSAGES = {
  create: 'manualInsertionSuccessToastText',
  delete: 'manualDeletionSuccessToastText',
  update: 'updateSuccessToastText',
};
