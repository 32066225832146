const permissionsEnum = {
  /* SETUP */

  // Company config
  edit_company_profile: 'edit_company_profile',
  edit_company_career_page: 'edit_company_career_page',
  edit_company_social_media: 'edit_company_social_media',

  // Roles, departments and subsidiaries
  view_department: 'view_department',
  create_department: 'create_department',
  edit_department: 'edit_department',
  delete_department: 'delete_department',
  view_role: 'view_role',
  create_role: 'create_role',
  edit_role: 'edit_role',
  delete_role: 'delete_role',
  view_subsidiary: 'view_subsidiary',
  create_subsidiary: 'create_subsidiary',
  edit_subsidiary: 'edit_subsidiary',
  delete_subsidiary: 'delete_subsidiary',

  // Company user
  manage_owner_user: 'manage_owner_user',
  view_company_user: 'view_company_user',
  create_company_user: 'create_company_user',
  edit_company_user: 'edit_company_user',
  delete_company_user: 'delete_company_user',
  create_company_user_master: 'create_company_user_master',

  // Job template
  view_job_template: 'view_job_template',
  create_job_template: 'create_job_template',
  edit_job_template: 'edit_job_template',
  delete_job_template: 'delete_job_template',

  // Approval Workflow
  view_approval_workflows: 'view_approval_workflows',
  create_approval_workflows: 'create_approval_workflows',
  edit_approval_workflows: 'edit_approval_workflows',
  delete_approval_workflows: 'delete_approval_workflows',

  // Custom tests
  view_custom_test: 'view_custom_test',
  create_custom_test: 'create_custom_test',
  edit_custom_test: 'edit_custom_test',
  delete_custom_test: 'delete_custom_test',

  // Private email template
  view_email_template: 'view_email_template',
  create_email_template: 'create_email_template',
  edit_email_template: 'edit_email_template',
  delete_email_template: 'delete_email_template',

  // Public email template
  view_public_email_template: 'view_public_email_template',
  create_public_email_template: 'create_public_email_template',
  edit_public_email_template: 'edit_public_email_template',
  delete_public_email_template: 'delete_public_email_template',

  // Public email template
  create_whatsapp_business_template: 'create_whatsapp_business_template',
  edit_whatsapp_business_template: 'edit_whatsapp_business_template',
  view_whatsapp_business_template: 'view_whatsapp_business_template',

  // Company integrations (external tests)
  view_company_integrations: 'view_company_integrations',
  edit_company_integrations: 'edit_company_integrations',

  // PublicApi Access token management
  create_public_api_access_token: 'create_public_api_access_token',

  // Company user access profile
  view_company_user_access_profile: 'view_company_user_access_profile',
  create_company_user_access_profile: 'create_company_user_access_profile',
  edit_company_user_access_profile: 'edit_company_user_access_profile',
  delete_company_user_access_profile: 'delete_company_user_access_profile',

  // GDPR
  view_gdpr: 'view_gdpr',

  // Organizational Structure
  view_organizational_structure: 'view_organizational_structure',
  create_organizational_structure: 'create_organizational_structure',
  edit_organizational_structure: 'edit_organizational_structure',
  delete_organizational_structure: 'delete_organizational_structure',

  // Custom form template
  create_custom_form_template_setup: 'create_custom_form_template_setup',

  // Interview scripts
  view_interview_scripts: 'view_interview_scripts',
  create_interview_scripts: 'create_interview_scripts',
  edit_interview_scripts: 'edit_interview_scripts',
  delete_interview_scripts: 'delete_interview_scripts',

  // Manage email domains
  manage_recommendation_email_domain: 'manage_recommendation_email_domain',

  /* TALENT POOL */

  view_talent_pool: 'view_talent_pool',
  send_email_candidate: 'send_email_candidate',
  manage_segmented_list: 'manage_segmented_list',
  manage_communication_inbound: 'manage_communication_inbound',
  apply_diversity_filter: 'apply_diversity_filter',
  manage_capture_form: 'manage_capture_form',
  manage_talent_recommendation: 'manage_talent_recommendation',

  /* ANALYTICS */

  view_analytics: 'view_analytics',
  view_diversity_dashboard: 'view_diversity_dashboard',
  export_diversity_in_reports: 'export_diversity_in_reports',

  // Reports
  view_report: 'view_report',
  create_report: 'create_report',

  /* JOBS */

  // Creation management
  create_job_draft: 'create_job_draft',
  create_job_draft_from_template: 'create_job_draft_from_template',
  create_job_draft_from_another_job: 'create_job_draft_from_another_job',
  move_job_draft_to_published: 'move_job_draft_to_published',
  move_job_draft_to_waiting_approval: 'move_job_draft_to_waiting_approval',
  move_job_waiting_approval_to_published: 'move_job_waiting_approval_to_published',
  move_job_approved_to_published: 'move_job_approved_to_published',
  move_job_disapproved_to_published: 'move_job_disapproved_to_published',
  move_job_frozen_to_published: 'move_job_frozen_to_published',

  // Access and edit job
  view_job: 'view_job',
  share_job: 'share_job',
  view_diversity_information: 'view_diversity_information',

  // Manage applications
  move_application: 'move_application',

  // Admission
  view_all_admission: 'view_all_admission',
  view_branch_admission: 'view_branch_admission',

  // Edit Salary
  edit_internal_data_salary: 'edit_internal_data_salary',
  edit_job_approval_information_salary: 'edit_job_approval_information_salary',
  edit_job_disapproval_information_salary: 'edit_job_disapproval_information_salary',
  edit_hiring_information_salary: 'edit_hiring_information_salary',

  apply_diversity_selection_process_filter: 'apply_diversity_selection_process_filter',

  // Company Employee
  view_company_employees: 'view_company_employees',
  edit_company_employees: 'edit_company_employees',

  // Job Offer
  view_job_offer_template: 'view_job_offer_template',
  create_job_offer_template: 'create_job_offer_template',
  edit_job_offer_template: 'edit_job_offer_template',
  delete_job_offer_template: 'delete_job_offer_template',

  view_job_offer_candidate_profile: 'view_job_offer_candidate_profile',
  create_job_offer_candidate_profile: 'create_job_offer_candidate_profile',
  download_job_offer_candidate_profile: 'download_job_offer_candidate_profile',
  delete_job_offer_candidate_profile: 'delete_job_offer_candidate_profile',

  // Company Plan Informations
  view_company_plan_resources_comsumption: 'view_company_plan_resources_comsumption',
};

export default permissionsEnum;
