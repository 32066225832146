import React, { Component, Fragment } from 'react';
import { FormattedMessage, intlShape, FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { Dropdown, CustomQuestionTypes } from '@gupy/front-commons';
import { SwitchButton, IconEnum, Icon, Infotip, TextArea, Tip } from '@gupy/design-system';

import { getCustomTestQuestionTypesData } from './CustomTestQuestionTypes.label';
import ImageUploaderAsync from '../../../components/ImageUploaderAsync/ImageUploaderAsync';
import QuestionsCreateAnswers from './QuestionsCreateAnswers';
import CustomTestTempImageUploaderService from '../services/CustomTestTempImageUploaderService';

const propTypes = {
  isSaving: PropTypes.bool.isRequired,
  isViewOnlyMode: PropTypes.bool,
  model: PropTypes.object.isRequired,
  onImageChange: PropTypes.func.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
};

const IMAGE_CONTAINER_HEIGHT = 315;
const IMAGE_CONTAINER_WIDTH = 600;

class CustomTestQuestionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.checkIfEditingAndPreventAccidentalDeletion = (
      this.checkIfEditingAndPreventAccidentalDeletion.bind(this)
    );
    this.generateInputCommomProps = this.generateInputCommomProps.bind(this);
    this.generateQuestionTypeValue = this.generateQuestionTypeValue.bind(this);
    this.handleQuestionTypeDropDown = this.handleQuestionTypeDropDown.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAddImageClick = this.handleAddImageClick.bind(this);
    this.handleUploadStatusChange = this.handleUploadStatusChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.renderAddImageIcon = this.renderAddImageIcon.bind(this);
    this.renderToogleNode = this.renderToogleNode.bind(this);
  }

  generateInputCommomProps(name, defaultValue = '') {
    const { isSaving, onFieldChange, model, validation } = this.props;
    return {
      value: model[name] || defaultValue,
      validation: validation[name],
      onChange: onFieldChange,
      readOnly: isSaving,
      id: name,
      name,
    };
  }

  generateQuestionTypeValue(isMultipleAnswers) {
    const { questionType } = this.props.model;
    if (isMultipleAnswers && questionType === CustomQuestionTypes.choices) {
      return CustomQuestionTypes.multipleChoices;
    }
    return questionType;
  }

  checkIfEditingAndPreventAccidentalDeletion(newModel, prevModel) {
    const { onFieldChange } = this.props;
    if (newModel.urlImage && !prevModel.urlImage) {
      onFieldChange({
        target: {
          name: 'urlImageTempPath',
          value: newModel.urlImage,
        },
      });

      onFieldChange({
        target: {
          name: 'urlImageDelete',
          value: false,
        },
      });
    }
  }

  handleQuestionTypeDropDown(event) {
    const { onFieldChange } = this.props;
    const { value } = event.target;

    onFieldChange({
      target: {
        name: 'questionType',
        value: (
          value === CustomQuestionTypes.multipleChoices
            ? CustomQuestionTypes.choices
            : value
        ),
      },
    });

    onFieldChange({
      target: {
        name: 'multipleAnswers',
        value: (value === CustomQuestionTypes.multipleChoices),
      },
    });
  }

  handleFieldChange(event) {
    this.props.onFieldChange(event);
  }

  handleAddImageClick() {
    const { urlImageDelete, urlImageTempPath, urlImage } = this.props.model;
    const { isUploading } = this.state;

    const canAddUploadComponent = !!(urlImageDelete !== false);
    const canRemoveUploadComponent = !!(
      (urlImageDelete === false)
      && !urlImageTempPath
      && !isUploading
      && !urlImage
    );

    if (canAddUploadComponent) {
      this.props.onFieldChange({
        target: {
          name: 'urlImageDelete',
          value: false,
        },
      });
    }
    if (canRemoveUploadComponent) {
      this.props.onFieldChange({
        target: {
          name: 'urlImageDelete',
          value: true,
        },
      });
    }
  }

  handleUploadStatusChange(name, status, err) {
    if (name === 'urlImage') {
      if (status === 'start') this.setState({ isUploading: true });
      if (status === 'finish') this.setState({ isUploading: false });
      if (err) this.setState({ isUploading: false, uploadError: err });
    }
  }

  handleImageChange(event) {
    const { urlImageTempPath, urlImageDelete } = event;
    const { onFieldChange } = this.props;

    onFieldChange({
      target: {
        name: 'urlImageTempPath',
        value: urlImageTempPath,
      },
    });

    onFieldChange({
      target: {
        name: 'urlImageDelete',
        value: urlImageDelete,
      },
    });
  }

  componentDidUpdate(prevProps) {
    const { model } = this.props;
    const prevModel = prevProps.model || {};
    this.checkIfEditingAndPreventAccidentalDeletion(model, prevModel);
  }

  renderToogleNode() {
    const { model } = this.props;

    return (
      <div className="col-sm-6 col-xs-12">
        <SwitchButton
          id="required"
          name="required"
          label={(
            <FormattedMessage
              id="question_required_label"
              defaultMessage="Resposta obrigatória"
            />
          )}
          onChange={value => this.handleFieldChange({ target: { name: 'required', value } })}
          checked={model.required}
          disabled={this.props.isViewOnlyMode}
        />
      </div>
    );
  }

  renderAddImageIcon() {
    const { model } = this.props;
    const { urlImageDelete } = model;

    if (urlImageDelete === false) {
      return (<Icon className="add-img-icon" onClick={this.handleAddImageClick} icon={IconEnum.PhotoOutline} />);
    }
    return (<Icon className="add-img-icon" onClick={this.handleAddImageClick} icon={IconEnum.Photo} />);
  }

  renderStaticImage() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <image
            className="custom-test-questions__static-image"
            style={{
              height: IMAGE_CONTAINER_HEIGHT,
              width: IMAGE_CONTAINER_WIDTH,
              'background-image': `url("${this.props.model.urlImage}")`,
            }}
          />
        </div>
      </div>
    );
  }

  renderEditableImage() {
    return (
      <div className="row">
        <div className="col-xs-12">
          <ImageUploaderAsync
            tempImageSavingFunction={CustomTestTempImageUploaderService.saveTempImage}
            flip
            rotate
            modal
            name="urlImage"
            id="urlImage"
            height={IMAGE_CONTAINER_HEIGHT}
            width={IMAGE_CONTAINER_WIDTH}
            showUploadButton={false}
            src={this.props.model.urlImage}
            suggestionSizeText={(
              <FormattedMessage
                id="recommended_image_600_315"
                defaultMessage="Tamanho recomendado 600px x 315px"
              />
            )}
            selectButtonText={(
              <FormattedMessage
                id="select_image"
                defaultMessage="Selecionar imagem"
              />
            )}
            maxFileSize={1024}
            onChange={this.handleImageChange}
            onUploadStatusChange={this.handleUploadStatusChange}
            label={(
              <div className="custom-test-question-form__infotip-label">
                <span className="custom-test-question-form__infotip-message">
                  <FormattedMessage
                    id="include_picture_for_question"
                    defaultMessage="Inclua uma foto para sua pergunta"
                  />
                </span>
                <Infotip
                  id="include_picture_publication_tooltip"
                  message={(
                    <FormattedMessage
                      id="include_picture_publication_tooltip_message"
                      defaultMessage="Tamanho máximo do arquivo: 1MB"
                    />
                  )}
                >
                  <Icon icon={IconEnum.Info} />
                </Infotip>
              </div>
            )}
            uploadLabel={(
              <FormattedMessage
                id="click_here_to_upload_picture"
                defaultMessage="Clique aqui para carregar uma imagem"
              />
            )}
            invalidSizeMessage={(
              <FormattedMessage
                id="invalid_main_image_size_1mb"
                defaultMessage="Sua imagem não pode ter mais do que 1mb"
              />
            )}
            invalidTypeMessage={(
              <FormattedMessage
                id="invalid_image_extension_jpg_png"
                defaultMessage="Formato do arquivo inválido. Formatos aceitos: jpg, jpeg e png"
              />
            )}
          />
        </div>
      </div>
    );
  }

  renderImage() {
    if (this.props.isViewOnlyMode) {
      return this.renderStaticImage();
    }

    return this.renderEditableImage();
  }

  render() {
    const { model, validation, intl, isViewOnlyMode } = this.props;
    const { urlImageDelete } = model;

    const isMultipleAnswers = String(model.multipleAnswers || '').toLowerCase() === 'true';
    const questionTypeValue = this.generateQuestionTypeValue(isMultipleAnswers);

    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-12 custom-test-form-header">
            <FormattedMessage
              id="custom_test_edit_question_header"
              defaultMessage="Editar questões"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 tip-custom-test-wrapper">
            <Tip
              id="tip-hint"
              type="hint"
              showIcon
              inverted
              text={(
                <FormattedHTMLMessage
                  id="edit_custom_test_answer_tip"
                  defaultMessage="Defina qual é a melhor configuração para sua questão. <a href='{url}' target='_blank'>Saiba como escolher o melhor tipo de pergunta.</a>"
                  values={{ url: 'https://support-companies.gupy.io/hc/pt-br/articles/11174752061851-Como-criar-e-utilizar-testes-customizados' }}
                />
              )}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-xs-12 col-with-left-icon">
            <Dropdown
              required
              id="questionType"
              label={(
                <FormattedMessage
                  id="question_type_label"
                  defaultMessage="Escolha o tipo de pergunta"
                />
              )}
              name="questionType"
              data={getCustomTestQuestionTypesData(intl)}
              onChange={this.handleQuestionTypeDropDown}
              validation={validation.questionType}
              value={questionTypeValue}
              disabled={this.props.isViewOnlyMode}
            />
            {!this.props.isViewOnlyMode && this.renderAddImageIcon()}
          </div>
        </div>

        {(<div className="row">{this.renderToogleNode()}</div>)}

        <TextArea
          required
          disabled={this.props.isViewOnlyMode}
          label={(
            <FormattedMessage
              id="question_text_label"
              defaultMessage="Escreva a pergunta"
            />
          )}
          {...this.generateInputCommomProps('text')}
        />

        {(urlImageDelete === false) && this.renderImage()}

        {(model.questionType === CustomQuestionTypes.choices) && (
          <div className="row">
            <div className="col-xs-12">
              <QuestionsCreateAnswers
                onChangeAnswers={this.handleFieldChange}
                correctAnswers={model.correctAnswers}
                multipleAnswers={isMultipleAnswers}
                wrongAnswers={model.wrongAnswers}
                isViewOnlyMode={isViewOnlyMode}
              />
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

CustomTestQuestionForm.propTypes = propTypes;

export default CustomTestQuestionForm;
