import { buildToast, ToastTypes } from '@gupy/front-commons';
import * as types from './InterviewEventActionTypes';
import {
  adaptModalObjectToApiPayload,
  adaptApiToModalObject,
  adaptApiToSuggestedSpots,
  participantsToArray,
  adaptAvailability,
  adaptApiToPossibleParticipants,
} from './InterviewEventAdapters';
import { InterviewEventModalValidator, InterviewEventModalDateValidator } from './InterviewEventValidator';
import { initIntegrations } from '../../../../Integrations/IntegrationsAction';

export const upsertInterviewEvent = ({ interviewEvent, messages, currentUser }) => {
  const form = new InterviewEventModalValidator(messages);
  form.populate(interviewEvent);
  const validation = form.validateModel();

  const adaptedInterviewEvent = adaptModalObjectToApiPayload(interviewEvent);

  return validation ? {
    type: types.CREATE_INTERVIEW_EVENT_INVALID,
    validation,
  } : {
    type: types.CREATE_INTERVIEW_EVENT_REQUEST,
    payload: adaptedInterviewEvent,
    originalInterviewEvent: interviewEvent,
    messages,
    currentUser,
  };
};

export const createInterviewEventSuccess = ({ originalInterviewEvent, messages, currentUser }) => ({
  type: types.CREATE_INTERVIEW_EVENT_SUCCESS,
  toast: buildToast(
    messages.createInterviewEventSuccess,
    ToastTypes.success,
    { autoClose: 5000 },
  ),
  interviewEvent: originalInterviewEvent,
  currentUser,
});

export const createInterviewEventFailure = messages => ({
  type: types.CREATE_INTERVIEW_EVENT_FAILURE,
  toast: buildToast(
    messages.createInterviewEventFailure,
    ToastTypes.error,
    { autoClose: 5000 },
  ),
});

export const getInterviewEventSuccess = response => ({
  type: types.GET_INTERVIEW_EVENT_SUCCESS,
  interviewEvent: adaptApiToModalObject(response),
});

export const getInterviewEventFailure = messages => ({
  type: types.GET_INTERVIEW_EVENT_FAILURE,
  toast: buildToast(
    messages.getInterviewEventFailure,
    ToastTypes.error,
    { autoClose: 5000 },
  ),
});

export const getSuggestSpots = ({ participants, duration, startDate, messages }) => ({
  type: types.GET_SUGGEST_SPOTS_REQUEST,
  interviewersIds: participantsToArray(participants),
  duration,
  startDate,
  messages,
});

export const getSuggestSpotsFailure = messages => ({
  type: types.GET_SUGGEST_SPOTS_FAILURE,
  toast: buildToast(
    messages.getSuggestSpotsFailure,
    ToastTypes.error,
    { autoClose: 5000 },
  ),
});

export const getSuggestSpotsSuccess = response => ({
  type: types.GET_SUGGEST_SPOTS_SUCCESS,
  suggestedSpots: adaptApiToSuggestedSpots(response),
});

export const getUsersAvailability = ({ participants, date, startTime, endTime, messages }) => {
  const form = new InterviewEventModalDateValidator(messages);
  form.populate({ startTime, endTime });
  const validation = form.validateModel();
  return validation ? {
    type: types.GET_USERS_AVAILABILITY_INVALID,
    validation,
  } : {
    type: types.GET_USERS_AVAILABILITY_REQUEST,
    interviewersIds: participantsToArray(participants),
    startTime,
    messages,
    endTime,
    date,
  };
};

export const getUsersAvailabilityFailure = messages => ({
  type: types.GET_USERS_AVAILABILITY_FAILURE,
  toast: buildToast(
    messages.getUsersAvailabilityFailure,
    ToastTypes.error,
    { autoClose: 5000 },
  ),
});

export const getUsersAvailabilitySuccess = response => ({
  type: types.GET_USERS_AVAILABILITY_SUCCESS,
  usersAvailability: adaptAvailability(response),
});

export const openInterviewEventModal = () => ({
  type: types.OPEN_INTERVIEW_EVENT_MODAL,
});

export const cancelInterviewEvent = ({
  candidateId,
  jobStepId,
  jobId,
  applicationId,
  messages,
}) => ({
  type: types.CANCEL_INTERVIEW_EVENT,
  candidateId,
  jobStepId,
  jobId,
  applicationId,
  messages,
});

export const cancelInterviewEventSuccess = messages => ({
  type: types.CANCEL_INTERVIEW_EVENT_SUCCESS,
  toast: buildToast(
    messages.cancelInterviewEventSuccess,
    ToastTypes.success,
    { autoClose: 5000 },
  ),
});

export const cancelInterviewEventFailure = messages => ({
  type: types.CANCEL_INTERVIEW_EVENT_FAILURE,
  toast: buildToast(
    messages.cancelInterviewEventFailure,
    ToastTypes.error,
    { autoClose: 5000 },
  ),
});

export const closeInterviewEventModal = () => ({
  type: types.CLOSE_INTERVIEW_EVENT_MODAL,
});

export const cleanPossibleParticipants = () => ({
  type: types.CLEAN_POSSIBLE_PARTICIPANTS,
});

export const getPossibleParticipants = jobId => ({
  type: types.GET_POSSIBLE_PARTICIPANTS_REQUEST,
  jobId,
});

export const getPossibleParticipantsSuccess = response => ({
  type: types.GET_POSSIBLE_PARTICIPANTS_SUCCESS,
  possibleParticipants: adaptApiToPossibleParticipants(response.users),
});

export const getPossibleParticipantsFailure = error => ({
  type: types.GET_POSSIBLE_PARTICIPANTS_FAILURE,
  error,
});

export const openInterviewEventSummaryModal = () => ({
  type: types.OPEN_INTERVIEW_EVENT_SUMMARY_MODAL,
});

export const closeInterviewEventSummaryModal = () => ({
  type: types.CLOSE_INTERVIEW_EVENT_SUMMARY_MODAL,
});

export const saveClickWhatsAppLink = ({ applicationId, jobId }) => ({
  type: types.SAVE_CLICK_WHATSAPP_LINK,
  jobId,
  applicationId,
});

export const getCalendarIntegrationEnabled = () => initIntegrations('interviewEvent');
