import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import StringHelper from '@gupy/front-helpers/src/helpers/StringHelper';

import { ImageUploader, ImageUploaderValidationTypes, FileUploadStatus, showToast, ToastTypes } from '@gupy/front-commons';
import ImageUploaderAsyncService from './ImageUploaderAsyncService';

const propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rotate: PropTypes.bool,
  flip: PropTypes.bool,
  modal: PropTypes.bool,
  src: PropTypes.string,
  suggestionSizeText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  containerHeight: PropTypes.number,
  containerWidth: PropTypes.number,
  maxFileSize: PropTypes.number.isRequired,
  showUploadButton: PropTypes.bool,
  selectButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  invalidTypeMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  invalidSizeMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  uploadLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  validation: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(ImageUploaderValidationTypes)),
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  }),
  onChange: PropTypes.func,
  onUploadStatusChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  hideDeleteButton: PropTypes.bool,
  quality: PropTypes.number,
  tempImageSavingFunction: PropTypes.func,
};

const defaultProps = {
  src: undefined,
  rotate: false,
  flip: false,
  modal: false,
  uploadLabel: undefined,
  label: undefined,
  suggestionSizeText: undefined,
  validation: undefined,
  showUploadButton: false,
  selectButtonText: 'Select',
  containerHeight: undefined,
  containerWidth: undefined,
  hideDeleteButton: false,
  onChange: () => {
  },
  quality: undefined,
  tempImageSavingFunction: ImageUploaderAsyncService.saveTempImage,
};

class ImageUploaderAsync extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  handleFileChange(event) {
    const { target } = event;

    if (target.img && target.img.src) {
      this.setState({
        isSaving: true,
      });
      this.props.onUploadStatusChange(target.name, FileUploadStatus.start);

      this.props.tempImageSavingFunction({
        imageBase64: target.img.src || '',
      }).then((resp) => {
        const { body, statusCode } = resp;
        if (statusCode === 200) {
          this.props.onChange({
            [`${target.name}TempPath`]: body.tempPath || '',
            [`${target.name}Delete`]: false,
            [`current${StringHelper.capitalizeFirstLetter(target.name)}`]: target.img.src,
          });
        }
        this.setState({
          isSaving: false,
        });
        this.props.onUploadStatusChange(target.name, FileUploadStatus.finish);
      }).catch((err) => {
        const messages = defineMessages({
          error_uploading_image: {
            id: 'error_uploading_image',
            defaultMessage: 'Erro ao atualizar a imagem',
          },
        });

        this.props.showToast(
          this.props.intl.formatMessage(messages.error_uploading_image),
          ToastTypes.error,
        );

        this.props.onUploadStatusChange(target.name, FileUploadStatus.error, err);
        this.setState({
          isSaving: false,
        });
      });
    } else {
      this.props.onChange({
        [`${target.name}Delete`]: true,
        [`${target.name}TempPath`]: null,
        [`url${StringHelper.capitalizeFirstLetter(target.name)}`]: null,
        [`current${StringHelper.capitalizeFirstLetter(target.name)}`]: null,
      });
    }
  }

  render() {
    const {
      id, name, rotate, flip, modal, width, height, src, label, invalidSizeMessage,
      invalidTypeMessage, uploadLabel, suggestionSizeText, validation, showUploadButton,
      selectButtonText, containerHeight, containerWidth, maxFileSize, hideDeleteButton,
    } = this.props;

    return (
      <ImageUploader
        flip={flip}
        rotate={rotate}
        showUploadButton={showUploadButton}
        name={name}
        id={id}
        isSaving={this.state.isSaving}
        modal={modal}
        height={height}
        width={width}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
        src={src}
        validation={validation}
        suggestionSizeText={suggestionSizeText}
        selectButtonText={selectButtonText}
        maxFileSize={maxFileSize}
        onChange={this.handleFileChange}
        label={label}
        uploadLabel={uploadLabel}
        invalidSizeMessage={invalidSizeMessage}
        invalidTypeMessage={invalidTypeMessage}
        hideDeleteButton={hideDeleteButton}
        {...(this.props.quality && { quality: this.props.quality })}
      />
    );
  }
}

ImageUploaderAsync.propTypes = propTypes;
ImageUploaderAsync.defaultProps = defaultProps;

const mapStateToProps = () => ({});

export default injectIntl(connect(
  mapStateToProps,
  {
    showToast,
  },
)(ImageUploaderAsync));
