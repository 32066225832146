import { BaseForm } from '@gupy/front-commons';

class UserProfileForm extends BaseForm {
  constructor({ newPassword }) {
    super({
      currentPassword: {
        validate: {
          notEmpty: true,
        },
      },
      newPassword: {
        validate: {
          mustHaveAtLeast1Letter: newPassword,
          mustHaveAtLeast1SpecialCharacter: newPassword,
          mustHaveAtLeast1Number: newPassword,
          notEmpty: true,
          minLength: 8,
        },
      },
    });
  }
}

export default UserProfileForm;
