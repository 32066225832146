import { put, takeLatest } from 'redux-saga/effects';
import * as TermsAndPrivacyService from '../TermsAndPrivacyService';
import {
  GET_SHOW_NEW_TERMS_AND_PRIVACY_FAIL,
  GET_SHOW_NEW_TERMS_AND_PRIVACY_SUCCESS,
  GET_SHOW_NEW_TERMS_AND_PRIVACY_REQUEST } from './TermsAndPrivacyUpdateNoticeActions';

export function* getShowNewTermsAndPrivacy() {
  try {
    const { body: { value: termsResponse } } = yield TermsAndPrivacyService.getTermsConsent();
    const { body: { value: privacyResponse } } = yield TermsAndPrivacyService.getPrivacyConsent();
    const showNewTermsAndPrivacy = !(termsResponse && privacyResponse);
    yield put({ type: GET_SHOW_NEW_TERMS_AND_PRIVACY_SUCCESS, payload: showNewTermsAndPrivacy });
  } catch (error) {
    if (error.statusCode === 404) {
      yield put({ type: GET_SHOW_NEW_TERMS_AND_PRIVACY_SUCCESS, payload: true });
    } else {
      yield put({ type: GET_SHOW_NEW_TERMS_AND_PRIVACY_FAIL });
    }
  }
}

function* NewTermsAndPrivacySaga() {
  yield takeLatest(GET_SHOW_NEW_TERMS_AND_PRIVACY_REQUEST, getShowNewTermsAndPrivacy);
}

export default NewTermsAndPrivacySaga;
