import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { DialogConfirm, IconEnum, Button } from '@gupy/design-system';

import rscChildAppStatusEnum from '../../../../enums/RscChildAppStatusEnum';

export const SyncButton = ({
  handleChildAppSubmit,
  syncButtonLabel,
  disabled,
}) => (
  <Button
    type="outlined"
    icon={IconEnum.Sync}
    onClick={handleChildAppSubmit}
    {...(disabled && { disabled: true })}
  >
    {syncButtonLabel}
  </Button>
);

SyncButton.propTypes = {
  handleChildAppSubmit: PropTypes.func.isRequired,
  syncButtonLabel: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const CancelEditButton = ({ messages, handleChildAppCancelEdit }) => (
  <Button
    type="link"
    className="childapp-cancel-edit-button"
    onClick={handleChildAppCancelEdit}
  >
    {messages.rscIntegrationChildAppCancelEditButton}
  </Button>
);

CancelEditButton.propTypes = {
  messages: PropTypes.object.isRequired,
  handleChildAppCancelEdit: PropTypes.func.isRequired,
};

const ChildAppEditModeOptions = ({
  messages,
  childApp,
  childAppName,
  setChildAppName,
  childAppCareerPages,
  setChildAppCareerPages,
  setEditMode,
  handleChildAppEditSubmit,
  handleChildAppSyncSubmit,
}) => {
  const [isChildAppSyncModalOpen, setIsChildAppSyncModalOpen] = useState(false);

  const isChildAppStatusSyncSuccess = childApp.status === rscChildAppStatusEnum
    .SYNC_SUCCESS;

  const isChildAppStatusReadyToSyncData = childApp.status === rscChildAppStatusEnum
    .READY_TO_SYNC_DATA;

  const syncButtonLabel = isChildAppStatusSyncSuccess
    ? messages.rscIntegrationChildAppSaveEdit
    : messages.rscIntegrationChildAppSyncButton;

  const haveRequiredFieldsForReadyToSyncDataStatus = isChildAppStatusReadyToSyncData
    && childAppName.length > 0
    && childAppCareerPages.length > 0;

  const haveRequiredFieldsForOtherStatuses = !isChildAppStatusReadyToSyncData
    && childAppName.length > 0;

  const isSyncButtonEnabled = haveRequiredFieldsForReadyToSyncDataStatus
    || haveRequiredFieldsForOtherStatuses;

  const handleChildAppSyncModalOpen = open => setIsChildAppSyncModalOpen(open);

  const handleChildAppSubmit = () => {
    if (isChildAppStatusSyncSuccess) {
      handleChildAppEditSubmit(
        childApp.id,
        childAppName,
        childAppCareerPages,
      );
    } else {
      handleChildAppSyncModalOpen(true);
    }
  };

  const handleChildAppCancelEdit = () => {
    setEditMode(false);
    setChildAppName(childApp.name);
    setChildAppCareerPages(childApp.careerPages);
  };

  return (
    <tr className="job-board-panel-rsc-child-apps__table-row-edit-options">
      <td className="actions" colSpan="4">
        {isChildAppStatusSyncSuccess && (
          <CancelEditButton
            messages={messages}
            handleChildAppCancelEdit={handleChildAppCancelEdit}
          />
        )}
        <SyncButton
          handleChildAppSubmit={handleChildAppSubmit}
          syncButtonLabel={syncButtonLabel}
          {...(isSyncButtonEnabled ? {} : { disabled: true })}
        />
        <DialogConfirm
          id={`child-app-${childApp.id}-sync-modal`}
          className={'child-app-sync-modal'}
          open={isChildAppSyncModalOpen}
          title={messages.rscChildAppSyncModalTitle}
          description={(
            <Fragment>
              <p>{messages.rscChildAppSyncModalDescriptionP1}</p>
              <p>{messages.rscChildAppSyncModalDescriptionP2}</p>
              <p>{messages.rscChildAppSyncModalDescriptionP3}</p>
            </Fragment>
          )}
          cancelButton={{
            label: messages.rscChildAppSyncModalCancelButtonLabel,
            action: () => {
              handleChildAppSyncModalOpen(false);
            },
          }}
          confirmButton={{
            label: messages.rscChildAppSyncModalConfirmButtonLabel,
            action: () => {
              handleChildAppSyncModalOpen(false);
              handleChildAppSyncSubmit(
                childApp.id,
                childAppName,
                childAppCareerPages,
              );
            },
          }}
        />
      </td>
    </tr>
  );
};

ChildAppEditModeOptions.propTypes = {
  messages: PropTypes.object.isRequired,
  childApp: PropTypes.object.isRequired,
  childAppName: PropTypes.string.isRequired,
  setChildAppName: PropTypes.func.isRequired,
  childAppCareerPages: PropTypes.arrayOf(PropTypes.shape({
    careerPageId: PropTypes.number.isRequired,
  })).isRequired,
  setChildAppCareerPages: PropTypes.func.isRequired,
  setEditMode: PropTypes.func.isRequired,
  handleChildAppEditSubmit: PropTypes.func.isRequired,
  handleChildAppSyncSubmit: PropTypes.func.isRequired,
};

export default ChildAppEditModeOptions;
