import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl, intlShape } from 'react-intl';

import { Icon, IconEnum } from '@gupy/design-system';

import './NoPermission.scss';

const propTypes = {
  intl: intlShape.isRequired,
  noViewPermission: PropTypes.bool,
  noEditPermission: PropTypes.bool,
};

const defaultProps = {
  noViewPermission: false,
  noEditPermission: false,
};

const NoPermission = ({ intl, noEditPermission, noViewPermission }) => {
  const messages = defineMessages({
    haveNoEditPermission: {
      id: 'no-permission-to-edit',
      defaultMessage: 'Você não possui permissão para editar',
    },
    haveNoViewPermission: {
      id: 'no-permission-to-visualize',
      defaultMessage: 'Você não possui permissão para visualizar',
    },
  });

  const haveNoEditPermission = noEditPermission ? intl.formatMessage(messages.haveNoEditPermission) : '';
  const haveNoViewPermission = noViewPermission ? intl.formatMessage(messages.haveNoViewPermission) : '';

  const haveNoPermissionMessage = haveNoViewPermission || haveNoEditPermission;

  return haveNoPermissionMessage ? (
    <span className="no-permission">
      <Icon className="no-permission__icon" icon={IconEnum.Https}/>
      <span data-testid="no-permission__message">{haveNoPermissionMessage}</span>
    </span>
  ) : null;
};

NoPermission.propTypes = propTypes;
NoPermission.defaultProps = defaultProps;

export default injectIntl(NoPermission);
