import { defineMessages } from 'react-intl';

import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  setup: {
    id: 'setup',
    defaultMessage: 'Setup da Empresa',
  },
  setupDescription: {
    id: 'setup_description',
    defaultMessage: 'Configure todas as informações e dados referentes à sua empresa aqui',
  },
  profile: {
    id: 'profile',
    defaultMessage: 'Perfil da empresa',
  },
  careerPages: {
    id: 'career_pages',
    defaultMessage: 'Páginas de carreira',
  },
  marketplaceApps: {
    id: 'marketplace_apps',
    defaultMessage: 'Marketplace',
  },
  socialMedias: {
    id: 'social_medias',
    defaultMessage: 'Mídias sociais',
  },
  roles: {
    id: 'roles',
    defaultMessage: 'Cargos',
  },
  testSteps: {
    id: 'test_steps',
    defaultMessage: 'Testes',
  },
  departments: {
    id: 'departments',
    defaultMessage: 'Áreas',
  },
  subsidiaries: {
    id: 'subsidiaries',
    defaultMessage: 'Filiais',
  },
  users: {
    id: 'users',
    defaultMessage: 'Usuários',
  },
  jobTemplates: {
    id: 'job_templates',
    defaultMessage: 'Templates de vaga',
  },
  approvalWorkflow: { // Vagas: Remove in OTMZ-2495
    id: 'approval_workflows',
    defaultMessage: 'Workflows de aprovação',
  },
  workflows: {
    id: 'workflows',
    defaultMessage: 'Fluxos de aprovações',
  },
  emailTemplates: {
    id: 'email_templates',
    defaultMessage: 'Templates de e-mail',
  },
  jobOfferTemplates: {
    id: 'job_offer_templates',
    defaultMessage: 'Templates de carta oferta',
  },
  userProfiles: {
    id: 'user_profiles',
    defaultMessage: 'Perfis de acesso',
  },
  integrations: {
    id: 'integrations',
    defaultMessage: 'Integrações',
  },
  gdpr: {
    id: 'gdpr',
    defaultMessage: 'GDPR',
  },
  organizationalStructure: {
    id: 'organizational_structure',
    defaultMessage: 'Estrutura Organizacional',
  },
  interviewScripts: {
    id: 'interview_scripts',
    defaultMessage: 'Roteiros de entrevista',
  },
  saml: {
    id: 'saml',
    defaultMessage: 'SAML',
  },
  samlSSO: {
    id: 'saml_sso',
    defaultMessage: 'SAML - SSO',
  },
  interviewEvent: {
    id: 'interview_event',
    defaultMessage: 'Agendamento de entrevistas',
  },
  customFields: {
    id: 'custom_fields',
    defaultMessage: 'Campos customizados da vaga',
  },
  companyStructure: {
    id: 'company_structure',
    defaultMessage: ' Estrutura da empresa',
  },
  sharing: {
    id: 'sharing',
    defaultMessage: 'Divulgação',
  },
  accessToThePlatform: {
    id: 'access_to_the_platform',
    defaultMessage: 'Acesso à plataforma',
  },
  templates: {
    id: 'templates',
    defaultMessage: 'Templates',
  },
  advancedSettings: {
    id: 'advanced_settings',
    defaultMessage: 'Configurações avançadas',
  },
  partnersCustoms: {
    id: 'partners_customs',
    defaultMessage: 'Parceiros & Customizados',
  },
  behavioralResult: {
    id: 'behavioral_result_average',
    defaultMessage: 'Resultado do Perfil Comportamental',
  },
  preHireStep: {
    id: 'pre_hire_step',
    defaultMessage: 'Etapa de pré-contratação',
  },
  admissionSettings: {
    id: 'admission_settings',
    defaultMessage: 'Admissão',
  },
  admissionTemplates: {
    id: 'admission_templates',
    defaultMessage: 'Templates de admissão',
  },
  admissionSignatureTemplates: {
    id: 'admission_signature_templates',
    defaultMessage: 'Templates de assinatura',
  },
  admissionDocuments: {
    id: 'admission_documents',
    defaultMessage: 'Documentos',
  },
  admissionCustomFields: {
    id: 'admission_custom_fields',
    defaultMessage: 'Campos customizados de pré-admissão',
  },
  admissionIntegrations: {
    id: 'admission_integrations',
    defaultMessage: 'Integrações de Admissão',
  },
  admissionWorkflow: {
    id: 'admission_workflow',
    defaultMessage: 'Definições de etapas',
  },
  apiAccessToken: {
    id: 'api_access_token',
    defaultMessage: 'Geração de tokens',
  },
  emailDomainIndication: {
    id: 'email_domain_indication',
    defaultMessage: 'Domínio de e-mail de indicação',
  },
  setupInitialPage: {
    id: 'setup_initial_page',
    defaultMessage: 'Página Inicial',
  },
  whatsAppBusinessIntegration: {
    id: 'whatsapp_business_integration',
    defaultMessage: 'Integração do WhatsApp Empresa',
  },
  whatsAppBusinessPage: {
    id: 'whatsAppBusinessPage',
    defaultMessage: 'Templates de WhatsApp Business',
  },
  employees: {
    id: 'employees',
    defaultMessage: 'Pessoas colaboradoras',
  },
  new: {
    id: 'new',
    defaultMessage: 'Novo',
  },
  workflow: {
    id: 'approval-workflow',
    defaultMessage: 'Fluxo de Aprovação',
  },
  costCenters: {
    id: 'cost-centers',
    defaultMessage: 'Centros de custo',
  },
  workShifts: {
    id: 'work-shifts',
    defaultMessage: 'Turnos',
  },
  culturalFitMapping: {
    id: 'cultural-fit-mapping',
    defaultMessage: 'Fit Cultural',
  },
  engageSurvey: {
    id: 'engage-survey',
    defaultMessage: 'Pesquisa de satisfação',
  },
  admissionBenefitsSetting: {
    id: 'admission-benefits-settings',
    defaultMessage: 'Configuração de benefícios',
  },
  admissionContractsSetting: {
    id: 'admission-contracts-settings',
    defaultMessage: 'Configuração de contratos',
  },
  myCompanyPlan: {
    id: 'my-company-plan-information',
    defaultMessage: 'Meu plano Gupy',
  },
  admissionManageAcceptanceTerms: {
    id: 'admission-manage-acceptance-terms',
    defaultMessage: 'Configuração de termos',
  },
});

export default intl => formatMessages(intl, messages);
