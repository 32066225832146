import { call, put, takeLatest } from 'redux-saga/effects';
import Service from './Service';
import * as types from './ActionTypes';

export function* getCompanySize() {
  try {
    const response = yield call(Service.get);
    yield put({
      type: types.GET_COMPANY_SIZE_SUCCESS,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: types.GET_COMPANY_SIZE_FAIL,
      error,
    });
  }
}

function* SetupHomePageSaga() {
  yield takeLatest(types.GET_COMPANY_SIZE, getCompanySize);
}

export default SetupHomePageSaga;
