import { combineReducers } from 'redux';

import * as NotificationsActionTypes from './NotificationsActionTypesV2';
import NotificationTypes from './enums/NotificationTypes';

import {
  filterNotificationByIdAndType,
  setNotificationAsViewed,
} from './helpers/adapters';
import { sort } from './helpers/userNotificationSort';

const notificationsInitialState = {
  [NotificationTypes.userNotification]: [],
  [NotificationTypes.whatsNew]: [],
};

const isLoading = (state = false, action) => {
  switch (action.type) {
  case NotificationsActionTypes.GET_ALL_REQUEST_V2:
    return true;
  case NotificationsActionTypes.GET_ALL_SUCCESS_V2:
  case NotificationsActionTypes.GET_ALL_FAILURE_V2:
    return false;
  default:
    return state;
  }
};

const notifications = (state = notificationsInitialState, action) => {
  switch (action.type) {
  case NotificationsActionTypes.GET_ALL_SUCCESS_V2:
    return sort(action.notifications);
  case NotificationsActionTypes.GET_ALL_FAILURE_V2:
    return sort(notificationsInitialState);
  case NotificationsActionTypes.REMOVE_REQUEST_V2:
  case NotificationsActionTypes.REMOVE_SUCCESS_V2:
    return sort(filterNotificationByIdAndType(state, action.data));
  case NotificationsActionTypes.VIEW_SUCCESS_V2:
    return sort(setNotificationAsViewed(state, action.data));
  default:
    return state;
  }
};

const error = (state = null, action) => {
  if (action.type === NotificationsActionTypes.GET_ALL_FAILURE_V2) {
    return action.error;
  }
  return state;
};

export default combineReducers({
  isLoading,
  notifications,
  error,
});
