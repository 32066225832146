import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  noInterviewEventScheduled: {
    id: 'no-interview-event-scheduled',
    defaultMessage: 'Você não possui entrevistas agendadas',
  },
  interviewsOfTheDay: {
    id: 'interviews-of-the-day',
    defaultMessage: 'Entrevistas do dia',
  },
  currentWeekInterviews: {
    id: 'current-week-interviews',
    defaultMessage: 'Entrevistas da semana atual',
  },
  currentMonthInterviews: {
    id: 'current-month-interviews',
    defaultMessage: 'Entrevistas do mês atual',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
