import React from 'react';
import PropTypes from 'prop-types';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

import { InputValidationTypes } from '@gupy/front-helpers/src/enums/InputValidationTypes';
import { InputMask } from '@gupy/front-commons';

const propTypes = {
  mask: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  guide: PropTypes.bool,
  placeholderChar: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  iconLeft: PropTypes.bool,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  icon: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  className: PropTypes.string,
  groupClassName: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  inputRef: PropTypes.func,
  validation: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(InputValidationTypes)),
    message: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
  }),
};

function InputNumberMask(props) {
  const {
    mask,
    ...other } = props;
  return (
    <InputMask
      mask={createNumberMask({
        prefix: '',
        thousandsSeparatorSymbol: '',
        ...mask,
      })}
      {...other}
    />
  );
}
InputNumberMask.propTypes = propTypes;

export default InputNumberMask;
