import { put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import * as types from './InterviewEventActionTypes';
import {
  createInterviewEventSuccess,
  createInterviewEventFailure,
  getInterviewEventSuccess,
  getInterviewEventFailure,
  getSuggestSpotsSuccess,
  getSuggestSpotsFailure,
  getPossibleParticipantsSuccess,
  getPossibleParticipantsFailure,
  cancelInterviewEventSuccess,
  cancelInterviewEventFailure,
  getUsersAvailabilitySuccess,
  getUsersAvailabilityFailure,
  openInterviewEventSummaryModal,
} from './InterviewEventActions';
import InterviewEventService from './InterviewEventService';
import {
  getApplicationEmailMessages,
} from '../../../../../actions/Job/JobApplication/JobApplicationAction';
import JobApplicationActionTypes from '../../../../../constants/Job/JobApplication/JobApplicationActionTypes';
import { getByInterviewerId } from '../../../../InterviewEvents/InterviewEventAction';
import CurriculumHistoryActionTypes from '../../../../../constants/Job/JobApplication/CurriculumHistoryActionTypes';

function* createInterviewEvent({ originalInterviewEvent, payload, messages, currentUser }) {
  try {
    const response = yield InterviewEventService.createInterviewEvent(payload);
    const interviewEvent = { ...originalInterviewEvent, ...response.body };
    yield put(createInterviewEventSuccess({
      originalInterviewEvent: interviewEvent, messages, currentUser,
    }));
    const { jobId, applicationId } = originalInterviewEvent;
    yield put(getApplicationEmailMessages({ jobId, applicationId }));
    yield put(openInterviewEventSummaryModal());
  } catch (error) {
    yield put(createInterviewEventFailure(messages));
  }
}

function* updateInterviewEventsForCurrentUser({ currentUser }) {
  const { id: interviewerId } = currentUser;
  const timezone = moment.tz.guess(true);
  yield put(getByInterviewerId({ interviewerId, timezone }));
}

function* getInterviewEventByCandidate({ application }) {
  try {
    const response = yield InterviewEventService.getInterviewEvent({
      candidateId: application.candidate.id,
      jobStepId: application.jobStep.id,
    });
    yield put(getInterviewEventSuccess(response.body));
  } catch (error) {
    if (error.statusCode === 404) {
      yield put({
        type: types.GET_INTERVIEW_EVENT_SUCCESS,
        interviewEvent: null,
      });
    } else {
      yield put(getInterviewEventFailure());
    }
  }
}

function* getSuggestSpots({ interviewersIds, duration, startDate, messages }) {
  try {
    const response = yield InterviewEventService.getSuggestSpots({
      interviewersIds,
      duration,
      startDate,
    });
    yield put(getSuggestSpotsSuccess(response.body));
  } catch (error) {
    yield put(getSuggestSpotsFailure(messages));
  }
}

function* getUsersAvailability({ interviewersIds, date, startTime, endTime, messages }) {
  try {
    const response = yield InterviewEventService.getUsersAvailability({
      interviewersIds,
      startTime,
      endTime,
      date,
    });
    yield put(getUsersAvailabilitySuccess(response.body));
  } catch (error) {
    yield put(getUsersAvailabilityFailure(messages));
  }
}

function* getPossibleParticipants({ jobId }) {
  try {
    const response = yield InterviewEventService.getPossibleParticipants(jobId);
    yield put(getPossibleParticipantsSuccess(response.body));
  } catch (error) {
    yield put(getPossibleParticipantsFailure(error));
  }
}

function* cancelInterviewEvent({ candidateId, jobStepId, jobId, applicationId, messages }) {
  try {
    yield InterviewEventService.cancelInterviewEvent(candidateId, jobStepId);
    yield put(cancelInterviewEventSuccess(messages));

    yield put(getApplicationEmailMessages({ jobId, applicationId }));
  } catch (error) {
    yield put(cancelInterviewEventFailure(messages));
  }
}

function* saveClickWhatsAppLink(action) {
  const { jobId, applicationId } = action;
  try {
    const actionType = CurriculumHistoryActionTypes.whatsAppLink;

    yield InterviewEventService.saveClickWhatsAppLink({
      actionType,
      applicationId,
      jobId,
    });

    yield put({
      type: types.SAVE_CLICK_WHATSAPP_LINK_SUCCESS,
      jobId,
      applicationId,
    });
  } catch (error) {
    yield put({
      type: types.SAVE_CLICK_WHATSAPP_LINK_FAIL,
      error,
    });
  }
}

function* InterviewEventSagas() {
  yield takeLatest(types.CREATE_INTERVIEW_EVENT_REQUEST, createInterviewEvent);
  yield takeLatest(types.CREATE_INTERVIEW_EVENT_SUCCESS, updateInterviewEventsForCurrentUser);
  yield takeLatest(types.GET_SUGGEST_SPOTS_REQUEST, getSuggestSpots);
  yield takeLatest(types.GET_USERS_AVAILABILITY_REQUEST, getUsersAvailability);
  yield takeLatest(types.GET_POSSIBLE_PARTICIPANTS_REQUEST, getPossibleParticipants);
  yield takeLatest(types.CANCEL_INTERVIEW_EVENT, cancelInterviewEvent);
  yield takeLatest([
    JobApplicationActionTypes.INIT_CANDIDATE_SUCCESS,
    types.GET_INTERVIEW_EVENT,
  ], getInterviewEventByCandidate);
  yield takeLatest(types.SAVE_CLICK_WHATSAPP_LINK, saveClickWhatsAppLink);
}

export default InterviewEventSagas;
