import groupBy from 'lodash.groupby';
import flattenDeep from 'lodash.flattendeep';

export const selectAllowedRoutes = (policies, routes) => {
  const groupedRoutesByName = groupBy(routes, route => route.name);
  const groupedRoutes = Object
    .keys(groupedRoutesByName)
    .reduce((mapping, routeName) => {
      const permissions = groupedRoutesByName[routeName].map(route => route.permissions);
      return mapping.set(routeName, flattenDeep(permissions));
    }, new Map());

  let allRoutesAllowed = false;
  if (policies.ALL) {
    allRoutesAllowed = true;
  }

  const allowedRoutes = Array.from(groupedRoutes.keys())
    .reduce((mapping, routeName) => {
      const permissions = groupedRoutes
        .get(routeName)
        .filter((permission) => {
          if (allRoutesAllowed) {
            return true;
          }
          const policy = policies[permission.pattern];

          if (!policy) {
            return false;
          }

          return policy.includes(permission.permission) || policy.includes('ALL');
        });

      const permissionsName = permissions.map(permission => permission.name);

      if (permissionsName.length > 0) {
        mapping.set(routeName, permissionsName);
      }

      return mapping;
    }, new Map());

  return Object.fromEntries(allowedRoutes.entries());
};

export const groupPermissions = accessPolicyRoutes => (
  accessPolicyRoutes.reduce((accumulator, current) => {
    let currentVersion = accumulator[current.version];
    if (!currentVersion) currentVersion = { checked: false, collapsed: true, groups: {} };

    let currentGroup = currentVersion.groups[current.name];
    if (!currentGroup) {
      currentGroup = { checked: false, collapsed: true, isRoot: true, permissions: [] };
    }

    currentGroup.permissions = current.permissions.map(permission => ({
      ...permission,
      checked: false,
      group: current.name,
      version: current.version,
    })).concat(currentGroup.permissions);

    currentVersion.groups[current.name] = currentGroup;
    accumulator[current.version] = currentVersion;
    return accumulator;
  }, {}));

export const checkPermission = (dataSet, { version, group, pattern, permission }, checked) => {
  const newDataSet = { ...dataSet };
  newDataSet[version].groups[group].permissions = dataSet[version].groups[group].permissions
    .map((subPermission) => {
      if (subPermission.pattern === pattern && subPermission.permission === permission) {
        return { ...subPermission, checked };
      }
      return subPermission;
    });
  newDataSet[version].groups[group].checked = newDataSet[version].groups[group].permissions
    .every(subPermission => subPermission.checked === true);
  newDataSet[version].checked = Object.values(newDataSet[version].groups)
    .every(innerGroup => innerGroup.checked === true);
  return newDataSet;
};

export const isAllPermissionsChecked = dataSet => Object.values(dataSet)
  .flatMap(version => Object.values(version.groups))
  .flatMap(group => group.permissions)
  .every(permission => permission.checked === true);

export const toggleCollapse = (dataSet, version, categoryName) => {
  const newData = { ...dataSet };
  newData[version].groups[categoryName].collapsed = !dataSet[version].groups[categoryName]
    .collapsed;
  return newData;
};

export const toggleCollapseVersion = (dataSet, version) => {
  const newData = { ...dataSet };
  newData[version].collapsed = !dataSet[version].collapsed;
  if (newData[version].collapsed === true) {
    Object.keys(dataSet[version].groups).forEach((group) => {
      newData[version].groups[group].collapsed = true;
    });
  }
  return newData;
};

export const hasUserAnyPermissionsToCreateTokenWithAnAction = (
  permissions,
  apiAccessPolicy,
) => {
  const manageTokenPermissions = [
    'create_public_api_access_token',
    'send_email_to_candidate',
  ];

  const hasOnlyManageTokenPermissions = !Object.keys(permissions)
    .filter(permission => !manageTokenPermissions.includes(permission))
    .length;

  if (hasOnlyManageTokenPermissions) {
    return false;
  }

  const canSelectAnyPermission = apiAccessPolicy
    .map(policy => policy.permissions.find(permission => permission.canUserSelectPermission));

  return !!canSelectAnyPermission.length;
};

export const adaptAccessPermissionsDataToTable = (policies) => {
  const obj = {};
  Object.keys(policies).forEach((version) => {
    const currentVersion = policies[version];
    obj[version] = { ...currentVersion };

    Object.keys(currentVersion.groups).forEach((group) => {
      const disabled = currentVersion.groups[group].permissions
        .every(permission => !permission.canUserSelectPermission);

      const permissions = currentVersion.groups[group].permissions.map(permission => ({
        ...permission,
        disabled: !permission.canUserSelectPermission,
      }));

      obj[version].groups[group] = {
        ...currentVersion.groups[group],
        disabled,
        permissions,
      };
    });
  });
  return obj;
};
