import axios from 'axios';
import api from '../../api/DarthVaderClient';
import CookieManager from '../../managers/CookieManager';
import LocalstorageManager from '../../managers/LocalstorageManager';

export default {
  postSignin(data) {
    return api.post('/authentication/companies/users/signin', data);
  },

  getCurrentUser() {
    return api.get('/authentication/companies/users/current?expand=products');
  },

  setSecondaryToken(secondaryToken) {
    return api.post('/authentication/companies/users/set-token', {
      secondaryToken,
    });
  },

  getCurrentUserWithoutHandling() {
    const cookieManager = new CookieManager();
    const localStorageManager = new LocalstorageManager(window.localStorage);

    const headers = {
      'content-type': 'application/json',
    };

    if (cookieManager.hasLocale()) {
      headers['accept-language'] = cookieManager.getLocale();
    }

    if (localStorageManager.hasCompanyToken()) {
      headers.company_key_auth = localStorageManager.getCompanyToken();
    }

    return axios.get(`${process.env.REACT_APP_API_URL}/authentication/companies/users/current`, {
      headers,
      withCredentials: true,
    }).then(({ data }) => ({ body: data })).catch(() => ({}));
  },

  getCompanySummary(subdomain) {
    return api.get(`/career-pages/public/companies/summary?subdomain=${subdomain}`);
  },

  postSignout(payload) {
    return api.post('/authentication/company/sign-out', payload);
  },

  createSamlLoginRequest(subdomain, secondProvider = false) {
    return api.get(`/authentication/company/saml/sign-in?subdomain=${subdomain}&secondProvider=${secondProvider}`);
  },

  exchangeToken(idpToken, subdomain) {
    return axios.post(`${process.env.REACT_APP_API_AUTHENTICATION_URL}/v1/accounts/token/exchange`, {
      application: 'gupyCompanies',
      subdomain,
    }, {
      headers: {
        Authorization: `${idpToken}`,
      },
    });
  },
  getCompanyIdBySubdomain(subdomain) {
    return api.get(`/authentication/companies/?subdomain=${subdomain}`);
  },
  getSubdomainByToken(idpToken) {
    return axios.get(`${process.env.REACT_APP_API_AUTHENTICATION_URL}/v1/accounts/company`, {
      headers: {
        Authorization: `${idpToken}`,
      },
    });
  },
  defineLastSignInAt() {
    return api.post('/authentication/companies/users/define-last-sign-in');
  },
};
