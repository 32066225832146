import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from '@gupy/design-system';

import { formatInterviewDate } from '../../../helpers/Formatters';
import HomeListProfileButton from '../../Home/components/HomeGridList/HomeListProfileButton';
import './InterviewEventListDetails.scss';

const propTypes = {
  data: PropTypes.object.isRequired,
  clickAnalytics: PropTypes.object,
  history: PropTypes.object.isRequired,
};

const InterviewEventListDetails = ({
  data,
  clickAnalytics,
  history,
}) => {
  const {
    startAt,
    applicationId,
    job: { companyJobId: jobId, name: jobName },
    interviewee: {
      name: intervieweeName,
      lastName: intervieweeLastName,
    },
    location: { addressLine },
  } = data;

  const formattedStartAt = formatInterviewDate(startAt);
  const jobData = `${jobId} - ${jobName}`;
  const intervieweeFullName = intervieweeLastName
    ? `${intervieweeName} ${intervieweeLastName}` : intervieweeName;

  return (
    <React.Fragment>
      <Avatar
        name={intervieweeFullName}
        size="large"
      />
      <div className={'interview-events-list__item-content'}>
        <span className={'interview-events-list__name-label'}>
          {intervieweeFullName}
        </span>
        <span className={'interview-events-list__job-name-label'}>{jobData}</span>
        <span className={'interview-events-list__details-label'}>
          {`${formattedStartAt} ${addressLine ? '|' : ''} ${addressLine}`}
        </span>
      </div>
      <HomeListProfileButton
        className={'interview-events-list__profile-button root'}
        jobId={jobId}
        applicationId={applicationId}
        analytics={clickAnalytics}
        history={history}
      />
    </React.Fragment>
  );
};

InterviewEventListDetails.propTypes = propTypes;

export default InterviewEventListDetails;
