import userTypesEnum from '../../constants/UserTypesEnum';

export const selectIsUserOwner = (state) => {
  const { currentUser } = state.reducers.Authentication;
  if (!currentUser) {
    return false;
  }
  return currentUser.userType === userTypesEnum.owner;
};

export const selectUserConsentPermission = (state, isdiversityCompaniesEnable) => {
  const userConsent = { hasPermissions: false, isOwner: false };
  const { currentUser } = state.reducers.Authentication;

  if (!currentUser && !isdiversityCompaniesEnable) {
    return userConsent;
  }

  const isOwner = currentUser.userType === userTypesEnum.owner;
  const isRoot = currentUser.userType === userTypesEnum.root;
  const { permissions } = currentUser;
  const hasPermissions = (
    permissions.view_diversity_information
    || permissions.view_diversity_dashboard
  );

  return {
    isRoot,
    isOwner,
    hasPermissions,
  };
};
