import { combineReducers } from 'redux';
import { WhatsappBusinessCampaignActionTypes } from './Actions';

export const isSaveLoading = (state = false, action) => {
  switch (action.type) {
  case WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN:
    return true;
  case WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_SUCCESS:
  case WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_FAIL:
    return false;
  default:
    return state;
  }
};

export const hasErrorOnCreatingCampaign = (state = false, action) => {
  switch (action.type) {
  case WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_FAIL:
    return true;
  case WhatsappBusinessCampaignActionTypes.CLEAR_ERROR:
    return false;
  default:
    return state;
  }
};

export const showSuccessModal = (state = false, action) => {
  switch (action.type) {
  case WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_SUCCESS:
    return true;
  case WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN_FAIL:
  case WhatsappBusinessCampaignActionTypes.CLEAR_STATE:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  isSaveLoading,
  hasErrorOnCreatingCampaign,
  showSuccessModal,
});
