import { buildToast, ToastTypes } from '@gupy/front-commons';
import * as types from './RootSetupTypes';

export const saveFeatureFlagsRequest = (featureFlags, messages) => ({
  type: types.SAVE_FEATURE_FLAGS_REQUEST,
  payload: featureFlags,
  messages,
});

export const saveFeatureFlagsSuccess = (response, messages) => ({
  type: types.SAVE_FEATURE_FLAGS_SUCCESS,
  payload: response.body,
  toast: buildToast(messages.success, ToastTypes.success),
});

export const saveFeatureFlagsFailure = messages => ({
  type: types.SAVE_FEATURE_FLAGS_FAILURE,
  toast: buildToast(messages.failure, ToastTypes.error),
});

export const initFeatureFlags = featureFlags => ({
  type: types.INIT_FEATURE_FLAGS,
  payload: featureFlags,
});

export const addFeatureFlag = featureFlag => ({
  type: types.ADD_FEATURE_FLAG,
  payload: featureFlag,
});

export const removeFeatureFlag = featureFlag => ({
  type: types.REMOVE_FEATURE_FLAG,
  payload: featureFlag,
});
