import { BaseForm } from '@gupy/front-commons';

class JobStepCustomForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
          maxLength: 255,
        },
      },
      category: {
        validate: {
          notEmpty: true,
        },
      },
      type: {
        validate: {
          notEmpty: true,
        },
      },
      endDate: {
        validate: {
          requiredIf: {
            fieldName: 'hasEndDate',
            value: true,
          },
          validateDateIf: {
            fieldName: 'hasEndDate',
            value: true,
          },
        },
      },
      objectivesDescription: {
        validate: {
          notEmptyWithDependency: {
            dependencies: [
              {
                field: 'category',
                value: 'evaluation',
              },
            ],
          },
        },
      },
    });
  }
}

export default JobStepCustomForm;
