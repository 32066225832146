import { combineReducers } from 'redux';
import { JobTemplatesActionTypes } from './JobTemplatesAction';

export const templates = (state = [], action) => {
  switch (action.type) {
  case JobTemplatesActionTypes.INIT:
  case JobTemplatesActionTypes.SEARCH:
    return null;
  case JobTemplatesActionTypes.INIT_SUCCESS:
    return action.templates;
  case JobTemplatesActionTypes.SEARCH_SUCCESS:
    return action.templates.length === 0 ? null : action.templates;
  default:
    return state;
  }
};

const pagination = (state = {}, action) => {
  switch (action.type) {
  case JobTemplatesActionTypes.INIT_SUCCESS:
  case JobTemplatesActionTypes.SEARCH_SUCCESS:
    return action.pagination;
  default:
    return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobTemplatesActionTypes.SEARCH:
  case JobTemplatesActionTypes.INIT:
  case JobTemplatesActionTypes.DELETE:
  case JobTemplatesActionTypes.DUPLICATE:
    return true;
  case JobTemplatesActionTypes.SEARCH_SUCCESS:
  case JobTemplatesActionTypes.SEARCH_FAIL:
  case JobTemplatesActionTypes.INIT_SUCCESS:
  case JobTemplatesActionTypes.INIT_FAIL:
  case JobTemplatesActionTypes.DELETE_SUCCESS:
  case JobTemplatesActionTypes.DELETE_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  templates,
  pagination,
  isLoading,
});
