import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage, FormattedHTMLMessage, defineMessages, intlShape } from 'react-intl';

import { CustomQuestionTypes, debounce, Sortable } from '@gupy/front-commons';
import { Breadcrumb, BreadcrumbItem, FooterButtons, Spinner, Tip, Button } from '@gupy/design-system';

import { openConfirmDialog } from '../../../../actions/App/AppActions';
import { SetupGridLayout } from '../../CustomTestComponents';
import QuestionItem from './QuestionItem';
import CustomTestQuestionForm from '../../forms/CustomTestQuestionForm';
import {
  detailCompanyCustomTestQuestion,
  deleteCompanyCustomTestQuestion,
  createCompanyCustomTestQuestion,
  updateCompanyCustomTestQuestion,
  loadCustomTestQuestionModel,
  onCustomTestQuestionModelUpdate,
  customTestQuestionModelClear,
  swapCustomTestQuestions,
  saveSortedCompanyTestQuestions,
} from '../../redux/CustomTestQuestion/CustomTestQuestionActions';

import { TestsPath } from '../../CustomTestRoutes';

import './Questions.scss';

function scrollToElement(e) {
  const element = document.getElementById(e);
  if (element) {
    element.focus();
    window.scrollTo(0, 0);
  }
}

const propTypes = {
  CustomTestQuestion: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  requestOpenConfirmDialog: PropTypes.func.isRequired,
  requestOnModelUpdate: PropTypes.func.isRequired,
  requestGetDetails: PropTypes.func.isRequired,
  requestDeleteQuestion: PropTypes.func.isRequired,
  requestLoadModel: PropTypes.func.isRequired,
  requestSaveQuestion: PropTypes.func.isRequired,
  requestCreateQuestion: PropTypes.func.isRequired,
  requestClearModel: PropTypes.func.isRequired,
  requestSwapQuestion: PropTypes.func.isRequired,
  requestSaveSorted: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  permissions: PropTypes.object.isRequired,
};

const toLowerCase = (value = '') => value.toLowerCase();

const Questions = ({
  CustomTestQuestion,
  match,
  requestOpenConfirmDialog,
  requestOnModelUpdate,
  requestGetDetails,
  requestDeleteQuestion,
  requestLoadModel,
  requestSaveQuestion,
  requestCreateQuestion,
  requestClearModel,
  requestSwapQuestion,
  requestSaveSorted,
  intl,
  permissions,
}) => {
  const isViewOnlyMode = CustomTestQuestion.customTest.inUse;

  const hasDuplicatedAsnwers = useMemo(() => {
    if (CustomTestQuestion.modelForm.correctAnswers && CustomTestQuestion.modelForm.wrongAnswers) {
      const allAnswers = [
        ...CustomTestQuestion.modelForm.correctAnswers,
        ...CustomTestQuestion.modelForm.wrongAnswers,
      ].map(toLowerCase);

      const uniqueAnswers = Array.from(new Set(allAnswers));
      return uniqueAnswers.length < allAnswers.length;
    }

    return false;
  }, [CustomTestQuestion.modelForm.correctAnswers, CustomTestQuestion.modelForm.wrongAnswers]);

  useEffect(() => {
    requestGetDetails(match.params.id);
  }, []);

  const handleEditQuestion = (question, clone = false) => {
    requestLoadModel(question, clone);
    scrollToElement('text');
  };

  const handleDeleteQuestion = (question) => {
    const { id: questionId } = question;
    const { id: testId } = match.params;
    const messages = defineMessages({
      deleteQuestionTitle: {
        id: 'delete_question_title',
        defaultMessage: 'Excluir Pergunta',
      },
      deleteQuestionMessage: {
        id: 'delete_question_message',
        defaultMessage: 'Você confirma a exclusão da pergunta?',
      },
      deleteQuestionConfirm: {
        id: 'delete_question_confirm',
        defaultMessage: 'Excluir',
      },
      deleteQuestionCancel: {
        id: 'delete_question_cancel',
        defaultMessage: 'Cancelar',
      },
      deleteQuestionSuccess: {
        id: 'delete_question_success_message',
        defaultMessage: 'Pergunta excluída com sucesso.',
      },
      deleteQuestionError: {
        id: 'delete_question_error_message',
        defaultMessage: 'Erro ao excluir questão.',
      },
    });

    requestOpenConfirmDialog({
      title: intl.formatMessage(messages.deleteQuestionTitle),
      message: intl.formatMessage(messages.deleteQuestionMessage),
      confirmButtonText: intl.formatMessage(messages.deleteQuestionConfirm),
      cancelButtonText: intl.formatMessage(messages.deleteQuestionCancel),
      onConfirmClick: () => requestDeleteQuestion(
        questionId,
        testId,
        {
          success: intl.formatMessage(messages.deleteQuestionSuccess),
          error: intl.formatMessage(messages.deleteQuestionError),
        },
      ),
    });
  };

  const getSortableQuestions = () => {
    const {
      questions,
      rearrangedQuestions,
    } = CustomTestQuestion;

    return [...questions]
      .sort((questionA, questionB) => {
        const indexA = rearrangedQuestions[questionA.id] || questionA.index;
        const indexB = rearrangedQuestions[questionB.id] || questionB.index;
        return indexA - indexB;
      })
      .map(question => ({
        question,
        id: question.id.toString(),
      }));
  };

  /**
   * Essa função carrega os dados do teste e gera parâmetros de configuração
   * para o componente de DataTables
   * @returns {*}
   */
  const getQuestionDataTable = () => {
    const sortableQuestions = getSortableQuestions();
    if (!sortableQuestions || sortableQuestions.length === 0) return [];

    const formatQuestionType = (questionType, multiple) => {
      let textType = questionType;

      const texts = defineMessages({
        shortAnswer: {
          id: 'short_answer',
          defaultMessage: 'Resposta curta',
        },
        paragraph: {
          id: 'paragraph',
          defaultMessage: 'Parágrafo',
        },
        multipleChoice: {
          id: 'multiple_choice',
          defaultMessage: 'Múltipla escolha',
        },
        selectionBox: {
          id: 'selection_box',
          defaultMessage: 'Caixa de seleção',
        },
      });

      if (textType === CustomQuestionTypes.text) {
        textType = intl.formatMessage(texts.shortAnswer);
      } else if (textType === CustomQuestionTypes.textarea) {
        textType = intl.formatMessage(texts.paragraph);
      } else if (textType === CustomQuestionTypes.choices && !multiple) {
        textType = intl.formatMessage(texts.multipleChoice);
      } else if (textType === CustomQuestionTypes.choices && multiple) {
        textType = intl.formatMessage(texts.selectionBox);
      }

      return textType;
    };

    return sortableQuestions.map(({ id, question }) => {
      const actions = [
        { icon: 'edit', onClick: () => handleEditQuestion(question) },
      ];

      if (!isViewOnlyMode) {
        actions.push({ icon: 'copy', onClick: () => handleEditQuestion(question, true) });
        actions.push({ icon: 'trash', onClick: () => handleDeleteQuestion(question) });
      }

      return {
        id,
        text: QuestionItem({
          id: `question-item-${question.index}`, // question item id
          text: question.text,
          questionType: formatQuestionType(question.questionType, question.multipleAnswers),
          actions,
        }),
      };
    });
  };

  const handleClearModel = () => {
    requestClearModel();
  };

  const handleFieldUpdate = (event) => {
    const { name, value } = event.target;
    requestOnModelUpdate({ [name]: value });
  };

  const handleSaveQuestion = () => {
    if (hasDuplicatedAsnwers) return false;
    const {
      customTest,
      modelForm,
    } = CustomTestQuestion;

    const messages = defineMessages({
      updateQuestionSuccess: {
        id: 'update_question_success_message',
        defaultMessage: 'Pergunta atualizada com sucesso.',
      },
      updateQuestionError: {
        id: 'update_question_error_message',
        defaultMessage: 'Erro ao salvar pergunta.',
      },
      addQuestionSuccess: {
        id: 'add_question_success_message',
        defaultMessage: 'Pergunta adicionada com sucesso.',
      },
      addQuestionError: {
        id: 'add_question_error_message',
        defaultMessage: 'Erro ao adicionar pergunta.',
      },
    });

    if (modelForm.id) {
      return requestSaveQuestion(
        modelForm.id,
        customTest.id,
        modelForm,
        {
          success: intl.formatMessage(messages.updateQuestionSuccess),
          error: intl.formatMessage(messages.updateQuestionError),
        },
      );
    }

    return requestCreateQuestion(
      customTest.id,
      modelForm,
      {
        success: intl.formatMessage(messages.addQuestionSuccess),
        error: intl.formatMessage(messages.addQuestionError),
      },
    );
  };

  const handleSaveSorted = debounce(() => {
    const {
      customTest: { id },
      rearrangedQuestions,
    } = CustomTestQuestion;

    const messages = defineMessages({
      sortQuestionsSuccess: {
        id: 'sort_questions_success_message',
        defaultMessage: 'Reordenação realizada com sucesso.',
      },
      sortQuestionsError: {
        id: 'sort_questions_error_message',
        defaultMessage: 'Erro ao reordenar perguntas.',
      },
    });

    if (Object.keys(rearrangedQuestions).length === 0) return;

    requestSaveSorted(
      id,
      rearrangedQuestions,
      {
        success: intl.formatMessage(messages.sortQuestionsSuccess),
        error: intl.formatMessage(messages.sortQuestionsError),
      },
    );
  }, 1000);

  const handleSortQuestion = (dragIndex, hoverIndex) => {
    if (isViewOnlyMode) return;

    const questions = getSortableQuestions();

    const dragQuestion = questions[dragIndex].question;
    const hoverQuestion = questions[hoverIndex].question;

    requestSwapQuestion(dragQuestion, hoverQuestion);
    handleSaveSorted();
  };

  const messages = defineMessages({
    saveChanges: {
      id: 'tests_save_changes',
      defaultMessage: 'Salvar alterações',
    },
    add: {
      id: 'tests_add',
      defaultMessage: 'Adicionar',
    },
    cancel: {
      id: 'cancel',
      defaultMessage: 'Cancelar',
    },
    knowMoreLink: {
      id: 'custom_test_know_more_link',
      defaultMessage: 'Clique aqui para saber mais sobre testes customizados',
    },
    knowMoreLinkUrl: {
      id: 'custom_test_know_more_link_url',
      defaultMessage: 'https://support-companies.gupy.io/hc/pt-br/articles/11174752061851-Como-criar-e-utilizar-testes-customizados',
    },
  });

  const canEdit = permissions.create_custom_test || permissions.edit_custom_test;
  const { modelForm, isLoading, customTest } = CustomTestQuestion;
  const questionsDataTable = getQuestionDataTable();

  const questionButtons = [{
    id: 'cancel-button',
    onClick: handleClearModel,
    text: intl.formatMessage(messages.cancel),
    type: 'text',
  },
  {
    id: 'save-question-button',
    onClick: handleSaveQuestion,
    text: modelForm.id
      ? intl.formatMessage(messages.saveChanges)
      : intl.formatMessage(messages.add),
  }];

  const renderViewOnlyModeButtons = () => (
    <div className="toolbar__right">
      <Link to={'/companies/setup/tests?expanded=custom-tests'}>
        <Button
          id="back"
          type="text"
        >
          <FormattedMessage
            id="view_test_questions_back"
            defaultMessage="Voltar"
          />
        </Button>
      </Link>
      <Link to={`${TestsPath}/${CustomTestQuestion.customTest.id}/edit`}>
        <Button
          id="details"
        >
          <FormattedMessage
            id="view_test_questions_got_to_details"
            defaultMessage="Ver detalhes"
          />
        </Button>
      </Link>
    </div>
  );

  const renderEditModeButtons = () => (
    <Link
      className="button pull-right"
      to="/companies/setup/tests?expanded=custom-tests"
    >
      <FormattedMessage
        id="finish_test"
        defaultMessage="Finalizar teste"
      />
    </Link>
  );

  const renderButtons = () => {
    if (isViewOnlyMode) {
      return renderViewOnlyModeButtons();
    }

    return renderEditModeButtons();
  };

  return (
    <SetupGridLayout
      permissions={permissions}
      title={(
        <FormattedMessage
          id="create_questions_grid_content_title"
          defaultMessage="Registre as questões que irão compor o seu teste"
        />
      )}
    >
      <Breadcrumb fluid>
        <BreadcrumbItem
          label={(
            <FormattedMessage
              id="all_tests"
              defaultMessage="Todos os testes"
            />
          )}
          linkTo="/companies/setup/tests?expanded=custom-tests"
        />
        <BreadcrumbItem
          label={customTest.title}
          linkTo={`/companies/setup/custom-tests/${customTest.id}/edit`}
        />
        <li>
          <FormattedMessage
            id="edit_test_questions"
            defaultMessage="Editar questões"
          />
        </li>
      </Breadcrumb>
      {isViewOnlyMode && (
        <Tip
          className="custom-test-questions__view-only-tip"
          type="warning"
          showIcon
          text={(
            <div>
              <FormattedHTMLMessage
                id="prevent_in_use_custom_test_change_warning"
                defaultMessage={'<b>Esse teste está vinculado à uma vaga e não pode ser editado.</b><br/>Realizar edições nesse teste podem gerar inconsistências nas notas e prejuízos para a experiência das pessoas candidatas. Para realizar edições, é recomendado que esse teste seja duplicado.'}
              />
              <span> <a target="_blank" className="custom-tests-form__know-more-link" href={intl.formatMessage(messages.knowMoreLinkUrl)}>{intl.formatMessage(messages.knowMoreLink)}</a>.</span>
            </div>
          )}
        />
      )}
      {
        canEdit ? (
          <div className="questions row">
            <div className="col-xs-12">
              <CustomTestQuestionForm
                intl={intl}
                isSaving={false}
                model={modelForm}
                onFieldChange={handleFieldUpdate}
                validation={CustomTestQuestion.validation}
                isViewOnlyMode={isViewOnlyMode}
              />
              {!isViewOnlyMode && (
                <div className="toolbar toolbar--form">
                  <div className="toolbar__right">
                    <FooterButtons buttons={questionButtons} />
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : null
      }
      <div className="questions-list row">
        {isLoading ? (
          <Spinner color="secondary" />
        ) : null}
        {(questionsDataTable.length) ? (
          <div className="col-xs-12">
            <h6>
              <FormattedMessage
                id="questions"
                defaultMessage="Perguntas"
              />
            </h6>
            <Sortable
              id="questionsSortable"
              data={questionsDataTable}
              disabled
              onSort={(dragIndex, hoverIndex) => {
                handleSortQuestion(dragIndex, hoverIndex);
              }}
            />
            { renderButtons() }
          </div>
        ) : (
          <div className="emptyDatatable col-xs-12">
            <h4><i className="fa fa-file-text-o" /></h4>
            <p className="title">
              <FormattedMessage
                id="there_is_no_questions_yet_try_to_create_some"
                defaultMessage="Ainda não há questões a serem exibidas, tente cadastrar uma."
              />
            </p>
          </div>
        )}
      </div>
    </SetupGridLayout>
  );
};

Questions.propTypes = propTypes;

const mapStateToProps = ({ reducers }) => {
  const { CustomTestQuestion, Authentication: { currentUser } } = reducers;
  return ({
    CustomTestQuestion,
    permissions: currentUser && currentUser.permissions,
  });
};

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    requestOpenConfirmDialog: openConfirmDialog,
    requestOnModelUpdate: onCustomTestQuestionModelUpdate,
    requestGetDetails: detailCompanyCustomTestQuestion,
    requestDeleteQuestion: deleteCompanyCustomTestQuestion,
    requestLoadModel: loadCustomTestQuestionModel,
    requestSaveQuestion: updateCompanyCustomTestQuestion,
    requestCreateQuestion: createCompanyCustomTestQuestion,
    requestClearModel: customTestQuestionModelClear,
    requestSwapQuestion: swapCustomTestQuestions,
    requestSaveSorted: saveSortedCompanyTestQuestions,
  },
)(Questions)));
