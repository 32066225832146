import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@gupy/front-commons';
import { MenuButton } from '@gupy/design-system';

import './InterviewEventMenu.scss';

const propTypes = {
  options: PropTypes.array.isRequired,
  activeOption: PropTypes.number.isRequired,
};

const InterviewEventMenu = ({ activeOption, options }) => {
  const isMax = useMediaQuery(480);

  return (
    <div className="interview-event-menu">
      <span>{options[activeOption].title}</span>
      <MenuButton
        closeOnClick
        id="interview-event-menu"
        icon="Edit"
        options={options}
        label="Some label"
        anchorOrigin={{
          vertical: 'top',
          horizontal: isMax ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isMax ? 'left' : 'right',
        }}
      />
    </div>
  );
};

InterviewEventMenu.propTypes = propTypes;

export default InterviewEventMenu;
