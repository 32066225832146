import { BaseForm } from '@gupy/front-commons';

class ApplicationTagForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default ApplicationTagForm;
