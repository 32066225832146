import { FormattedMessage } from 'react-intl';
import React from 'react';
import { BaseForm } from '@gupy/front-commons';

class SocialMediaFormValidator extends BaseForm {
  constructor() {
    super({
      socialTitle: {
        validate: {
          notEmpty: true,
        },
      },
      socialDescription: {
        validate: {
          notEmpty: true,
          minLength: 100,
        },
      },
    });
  }

  static getRequiredFieldError() {
    return ({
      type: 'error',
      message: <FormattedMessage id="required_field" defaultMessage="Campo obrigatório" />,
    });
  }

  validateForm() {
    let errors = super.validateModel();

    const { socialPictureUrl, socialImageBase64 } = this.fields;

    if (
      (!socialPictureUrl || !socialPictureUrl.value)
      && (!socialImageBase64 || !socialImageBase64.value)
    ) {
      errors = {
        ...errors,
        socialImageBase64: SocialMediaFormValidator.getRequiredFieldError(),
      };
    }

    if (errors) {
      if (Object.keys(errors).length === 0 && errors.constructor === Object) {
        return null;
      }
    }

    return errors;
  }
}

export default SocialMediaFormValidator;
