import { combineReducers } from 'redux';

import * as ActionTypes from './ActionTypes';

export default combineReducers({
  isAnalyticsCalloutModalOpen: (state = false, action) => {
    switch (action.type) {
    case ActionTypes.OPEN_CALLOUT_MODAL:
      return true;
    case ActionTypes.CLOSE_CALLOUT_MODAL:
      return false;
    default:
      return state;
    }
  },

  analyticsCalloutJob: (state = {}, action) => {
    switch (action.type) {
    case ActionTypes.OPEN_CALLOUT_MODAL:
      return action.payload;
    default:
      return state;
    }
  },
});
