import api from '../../api/DarthVaderClient';

export default {
  async getCareerPagesPlansSummary() {
    return api.get('/plans-summary?config=career_pages');
  },
  async getCareerPagesV2() {
    return api.get('/v2/career-pages');
  },
  async getCareerPages() {
    return api.get('/career-pages');
  },
  async postCareerPage(payload) {
    return api.post('/career-pages', payload);
  },
  async patchCareerPage(payload) {
    const { id, ...payloadNoId } = payload;
    return api.patch(`/career-pages/${id}`, payloadNoId);
  },
};
