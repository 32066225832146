export const CREATE_INTERVIEW_EVENT_REQUEST = 'interviewEvent/CREATE_INTERVIEW_EVENT_REQUEST';
export const CREATE_INTERVIEW_EVENT_SUCCESS = 'interviewEvent/CREATE_INTERVIEW_EVENT_SUCCESS';
export const CREATE_INTERVIEW_EVENT_FAILURE = 'interviewEvent/CREATE_INTERVIEW_EVENT_FAILURE';
export const CREATE_INTERVIEW_EVENT_INVALID = 'interviewEvent/CREATE_INTERVIEW_EVENT_INVALID';

export const CANCEL_INTERVIEW_EVENT = 'interviewEvent/CANCEL_INTERVIEW_EVENT';
export const CANCEL_INTERVIEW_EVENT_SUCCESS = 'interviewEvent/CANCEL_INTERVIEW_EVENT_SUCCESS';
export const CANCEL_INTERVIEW_EVENT_FAILURE = 'interviewEvent/CANCEL_INTERVIEW_EVENT_FAILURE';

export const GET_INTERVIEW_EVENT = 'interviewEvent/GET_INTERVIEW_EVENT';
export const GET_INTERVIEW_EVENT_SUCCESS = 'interviewEvent/GET_INTERVIEW_EVENT_SUCCESS';
export const GET_INTERVIEW_EVENT_FAILURE = 'interviewEvent/GET_INTERVIEW_EVENT_FAILURE';

export const GET_SUGGEST_SPOTS_REQUEST = 'interviewEvent/GET_SUGGEST_SPOTS_REQUEST';
export const GET_SUGGEST_SPOTS_SUCCESS = 'interviewEvent/GET_SUGGEST_SPOTS_SUCCESS';
export const GET_SUGGEST_SPOTS_FAILURE = 'interviewEvent/GET_SUGGEST_SPOTS_FAILURE';

export const GET_USERS_AVAILABILITY_REQUEST = 'interviewEvent/GET_USERS_AVAILABILITY_REQUEST';
export const GET_USERS_AVAILABILITY_SUCCESS = 'interviewEvent/GET_USERS_AVAILABILITY_SUCCESS';
export const GET_USERS_AVAILABILITY_FAILURE = 'interviewEvent/GET_USERS_AVAILABILITY_FAILURE';
export const GET_USERS_AVAILABILITY_INVALID = 'interviewEvent/GET_USERS_AVAILABILITY_INVALID';

export const OPEN_INTERVIEW_EVENT_MODAL = 'interviewEvent/OPEN_INTERVIEW_EVENT_MODAL';
export const CLOSE_INTERVIEW_EVENT_MODAL = 'interviewEvent/CLOSE_INTERVIEW_EVENT_MODAL';
export const CLEAN_POSSIBLE_PARTICIPANTS = 'interviewEvent/CLEAN_POSSIBLE_PARTICIPANTS';

export const CHANGE_FORM = 'interviewEvent/CHANGE_FORM';

export const GET_POSSIBLE_PARTICIPANTS_REQUEST = 'interviewEvent/GET_POSSIBLE_PARTICIPANTS_REQUEST';
export const GET_POSSIBLE_PARTICIPANTS_SUCCESS = 'interviewEvent/GET_POSSIBLE_PARTICIPANTS_SUCCESS';
export const GET_POSSIBLE_PARTICIPANTS_FAILURE = 'interviewEvent/GET_POSSIBLE_PARTICIPANTS_FAILURE';

export const GET_CALENDAR_INTEGRATION_ENABLED = 'interviewEvent/GET_CALENDAR_INTEGRATION_ENABLED';

export const OPEN_INTERVIEW_EVENT_SUMMARY_MODAL = 'interviewEvent/OPEN_INTERVIEW_EVENT_SUMMARY_MODAL';
export const CLOSE_INTERVIEW_EVENT_SUMMARY_MODAL = 'interviewEvent/CLOSE_INTERVIEW_EVENT_SUMMARY_MODAL';

export const SAVE_CLICK_WHATSAPP_LINK = 'interviewEvent/SAVE_CLICK_WHATSAPP_LINK';
export const SAVE_CLICK_WHATSAPP_LINK_SUCCESS = 'interviewEvent/SAVE_CLICK_WHATSAPP_LINK_SUCCESS';
export const SAVE_CLICK_WHATSAPP_LINK_FAIL = 'interviewEvent/SAVE_CLICK_WHATSAPP_LINK_FAIL';
