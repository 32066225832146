import UserProfileActionTypes from './ActionTypes';
import * as CompanyUserInfoActionTypes from './ActionTypesCompanyUser';

export const patchUserProfile = payload => ({
  type: UserProfileActionTypes.PATCH,
  payload,
});

export const getUserProfile = () => ({
  type: UserProfileActionTypes.GET,
});

export const edit = payload => ({
  type: UserProfileActionTypes.EDIT,
  payload,
});

export const cancelEdit = payload => ({
  type: UserProfileActionTypes.CANCEL_EDIT,
  payload,
});

export const getCountriesSuccess = payload => ({
  type: CompanyUserInfoActionTypes.GET_COUNTRIES_SUCCESS,
  payload: payload.map(country => ({
    value: country.code,
    label: country.name,
  })),
});
