import api from '../../api/DarthVaderClient';

const emailTemplatesPath = '/communication/companies/users/current/email-templates';

export default {
  post(payload) {
    return api.post(emailTemplatesPath, payload);
  },

  patch(id, payload) {
    return api.patch(`${emailTemplatesPath}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${emailTemplatesPath}/${id}`);
  },

  get(id) {
    return api.get(`${emailTemplatesPath}/${id}`);
  },

  getAll({ searchString, publicOnly = false, attributes = [] } = {}) {
    const search = searchString ? `&search=${searchString}` : '';
    return api.get(`${emailTemplatesPath}?order=name${search}&publicOnly=${publicOnly}&page=0&perPage=9999${attributes.length ? `&attributes=${attributes}` : ''}`);
  },

  getAllByType(type) {
    return api.get(`${emailTemplatesPath}?order=name&page=0&perPage=9999&type=${type}`);
  },

  getTypes() {
    return api.get('/communication/public/email-templates/types?order=name&page=0&perPage=9999');
  },

  getVariables() {
    return api.get('/communication/public/email-templates/variables');
  },

  checkHasAssociatedJob(id) {
    return api.get(`/communication/email-template/${id}/has-associated-job`);
  },

  getTemplateAssociatedJobs(id, {
    offset = 0,
    limit = 50,
  } = {}) {
    return api.get(`/communication/email-template/${id}/jobs?offset=${offset}&limit=${limit}`);
  },

  unlinkTemplateAssociatedJobs(id, payload) {
    return api.patch(`/communication/email-template/${id}/job-step/unlink`, payload);
  },
};
