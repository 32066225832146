import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { injectIntl, defineMessages, intlShape } from 'react-intl';

import { Checkbox } from '@gupy/design-system-v2';
import { Button, IconEnum, RadioButton, Tip } from '@gupy/design-system';
import { RadioButtonGroup } from '@gupy/front-commons';

import './TestsCorrectionQuestion.scss';

const getCommonProps = (
  answerArr,
  correctAnswers,
  possibleAnswer,
  keyPrefix,
) => {
  const key = `${keyPrefix}-answer-${Math.random()}`;
  const isCandidateAnswer = answerArr && answerArr.includes(possibleAnswer);
  const isRightAnswer = correctAnswers.includes(possibleAnswer);

  return {
    key,
    id: key,
    name: key,
    checked: !!isCandidateAnswer,
    className: isRightAnswer ? 'answer--right' : 'answer--wrong',
  };
};

class TestsCorrectionQuestion extends React.Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    model: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
  };

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(value) {
    const { onChange, model } = this.props;
    onChange({
      model,
      value,
    });
  }

  render() {
    const {
      handleChange,
      props: {
        index,
        model: {
          answer,
          avaliationStatus,
          avaliator,
          text,
          type,
          urlImage,
          correctAnswers,
          wrongAnswers,
          multipleAnswers,
          scored,
        },
        intl,
      },
    } = this;

    const answerArr = JSON.parse(answer);
    const hasAnswer = !!answer;

    const isWaiting = avaliationStatus === 'waiting';
    const disableButtons = !isWaiting || !hasAnswer;

    const messages = defineMessages({
      questionCorrectedAutomatically: {
        id: 'question_corrected_automatically',
        defaultMessage: 'Corrigida automaticamente',
      },
      questionCorrectedBy: {
        id: 'question_corrected_by',
        defaultMessage: 'Corrigida por {avaliator}',
      },
      candidateDidNotAnswerTheQuestion: {
        id: 'candidate_did_not_answer_the_question',
        defaultMessage: 'Não respondida pelo candidato',
      },
      rigth_answer: {
        id: 'rigth_answer',
        defaultMessage: 'Correta',
      },
      wrong_answer: {
        id: 'wrong_answer',
        defaultMessage: 'Incorreta',
      },
      change_rigth_answer: {
        id: 'change_rigth_answer',
        defaultMessage: 'Alterar para correta',
      },
      change_wrong_answer: {
        id: 'change_wrong_answer',
        defaultMessage: 'Alterar para incorreta',
      },
    });

    let avaliationLabel;
    if (!hasAnswer) {
      avaliationLabel = intl.formatMessage(
        messages.candidateDidNotAnswerTheQuestion,
      );
    } else if (avaliator) {
      avaliationLabel = intl.formatMessage(messages.questionCorrectedBy, {
        avaliator,
      });
    } else {
      avaliationLabel = intl.formatMessage(
        messages.questionCorrectedAutomatically,
      );
    }

    let questionAnswerNode;
    if (type === 'choices') {
      const answers = [...correctAnswers, ...wrongAnswers];

      if (!multipleAnswers) {
        questionAnswerNode = (
          <RadioButtonGroup>
            {answers.map((possibleAnswer) => {
              const props = getCommonProps(
                answerArr,
                correctAnswers,
                possibleAnswer,
                'radio',
              );
              return (
                <RadioButton
                  label={possibleAnswer}
                  value={possibleAnswer}
                  {...props}
                />
              );
            })}
          </RadioButtonGroup>
        );
      } else {
        questionAnswerNode = answers.map((possibleAnswer) => {
          const props = getCommonProps(
            answerArr,
            correctAnswers,
            possibleAnswer,
            'check',
          );
          return <Checkbox {...props}>{possibleAnswer}</Checkbox>;
        });
      }
    } else if (answerArr) {
      questionAnswerNode = <p>{answerArr[0]}</p>;
    } else {
      questionAnswerNode = <p>-</p>;
    }

    let questionAnswerMessageButtons;
    if (disableButtons || scored === null) {
      questionAnswerMessageButtons = {
        correctAnswer: intl.formatMessage(messages.rigth_answer),
        incorrectAnswer: intl.formatMessage(messages.wrong_answer),
      };
    } else if (scored === 0) {
      questionAnswerMessageButtons = {
        correctAnswer: intl.formatMessage(messages.change_rigth_answer),
        incorrectAnswer: intl.formatMessage(messages.wrong_answer),
      };
    } else {
      questionAnswerMessageButtons = {
        correctAnswer: intl.formatMessage(messages.rigth_answer),
        incorrectAnswer: intl.formatMessage(messages.change_wrong_answer),
      };
    }

    return (
      <div className="custom-test-correction__body__question-block">
        <h6>{`${index + 1}) ${text}`}</h6>
        {urlImage && (
          <Fragment>
            <br />
            <img alt="custom-test-img" src={urlImage} />
          </Fragment>
        )}
        <div className="custom-test-correction__body__question-block__answer">
          {questionAnswerNode}
        </div>
        <div className="custom-test-correction__body__question-block__answer__toolbar tests-correction-question__container">
          <div
            className={classNames('tests-correction-question__buttons', {
              'tests-correction-question__buttons__reverse':
                !disableButtons && scored === 1,
            })}
          >
            {(!disableButtons || (disableButtons && scored === 0)) && (
              <Button
                className={classNames(
                  'tests-correction-question__answer-button',
                  {
                    'tests-correction-question__answer-button--incorrect':
                      scored === 0,
                  },
                )}
                type={scored !== null ? 'text' : 'outlined'}
                onClick={() => handleChange(false)}
                disabled={disableButtons}
                icon={IconEnum.Close}
              >
                {questionAnswerMessageButtons.incorrectAnswer}
              </Button>
            )}

            {(!disableButtons || (disableButtons && scored === 1)) && (
              <Button
                className={classNames(
                  'tests-correction-question__answer-button',
                  {
                    'tests-correction-question__answer-button--correct':
                      scored === 1,
                  },
                )}
                type={scored !== null ? 'text' : 'outlined'}
                onClick={() => handleChange(true)}
                disabled={disableButtons}
                icon={IconEnum.CheckCircle}
              >
                {questionAnswerMessageButtons.correctAnswer}
              </Button>
            )}

            {(!isWaiting || !hasAnswer) && (
              <Tip showIcon={false} text={avaliationLabel} type="normal" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(TestsCorrectionQuestion);
