import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { Spinner } from '@gupy/design-system';
import FeaturesFlagsEnum from './Authentication/FeaturesFlagsEnum';
import { keycloak, keycloakInitOptions } from './keycloak';

const IDPProvider = ({ children }) => {
  const { flagsReady } = useFlagsStatus();
  const isLegacySignOn = useFlag(FeaturesFlagsEnum.legacySignOn);

  const isSSOEnabled = !isLegacySignOn;

  if (!flagsReady) {
    return (
      <div className="companies-routes-spinner">
        <Spinner size={60} />
      </div>
    );
  }

  if (flagsReady && isSSOEnabled) {
    return (
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={keycloakInitOptions}
      >
        {children}
      </ReactKeycloakProvider>
    );
  }

  return children;
};

export default IDPProvider;
