import { call, put, takeLatest } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';

import { JobOfferTemplateActionTypes } from './Actions';
import JobOfferTemplateService from '../../../src/services/JobOfferTemplate/JobOfferTemplateService';
import JobOfferTemplateForm from '../../forms/JobOfferTemplate/JobOfferTemplateForm';

import history from '../../history';

const adaptVariables = variables => (
  Object.keys(variables).map(key => ({
    value: key,
    label: variables[`${key}`],
  }))
);

function* getAll({ publicOnly, options: { attributes = [] } = {} } = {}) {
  try {
    const responseVariables = yield JobOfferTemplateService.getVariables();
    const responseTemplates = yield JobOfferTemplateService.getAll({
      publicOnly,
      attributes,
    });

    yield put({
      type: JobOfferTemplateActionTypes.GET_ALL_SUCCESS,
      jobOfferTemplates: responseTemplates.body.map(
        jobOfferTemplate => ({ ...jobOfferTemplate, files: jobOfferTemplate.attachments || [] }),
      ),
      variables: adaptVariables(responseVariables.body),
    });
  } catch (error) {
    yield put({
      type: JobOfferTemplateActionTypes.GET_ALL_FAIL,
    });
  }
}

function* get(action) {
  const { id } = action;
  try {
    const responseTemplate = yield JobOfferTemplateService.get(id);
    const responseVariables = yield JobOfferTemplateService.getVariables();

    yield put({
      type: JobOfferTemplateActionTypes.GET_SUCCESS,
      modelForm: { ...responseTemplate.body, files: responseTemplate.body.attachments || [] },
      variables: adaptVariables(responseVariables.body),
    });
  } catch (error) {
    yield put({
      type: JobOfferTemplateActionTypes.GET_FAIL,
    });
  }
}

function* getVariables() {
  try {
    const response = yield JobOfferTemplateService.getVariables();

    yield put({
      type: JobOfferTemplateActionTypes.GET_VARIABLES_SUCCESS,
      variables: adaptVariables(response.body),
    });
  } catch (error) {
    yield put({
      type: JobOfferTemplateActionTypes.GET_VARIABLES_FAIL,
    });
  }
}

function* post(action) {
  const { payload, messages, redirectUrl } = action;
  const newPayload = {
    ...payload,
    files: null,
    attachments: (payload.files || []).map(
      ({ id, name, size, tempName }) => ({ id, name, size, tempName }),
    ),
  };
  const form = new JobOfferTemplateForm();

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobOfferTemplateActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield JobOfferTemplateService.post(newPayload);

      yield put({
        type: JobOfferTemplateActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield call(history.push, redirectUrl);
    } catch (error) {
      yield put({
        type: JobOfferTemplateActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* update(action) {
  const { payload, messages, redirectUrl } = action;
  const form = new JobOfferTemplateForm();

  const { id } = payload;
  const newPayload = {
    ...payload,
    id: undefined,
    files: null,
    attachments: (payload.files || []).map(
      ({ id: fileId, name, size, tempName }) => ({ id: fileId, name, size, tempName }),
    ),
  };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobOfferTemplateActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield JobOfferTemplateService.patch(id, newPayload);

      yield put({
        type: JobOfferTemplateActionTypes.PUT_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield call(history.push, redirectUrl);
    } catch (error) {
      yield put({
        type: JobOfferTemplateActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, messages } = action;

  try {
    yield JobOfferTemplateService.delete(id);

    yield put({
      type: JobOfferTemplateActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: JobOfferTemplateActionTypes.DELETE_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
      error,
    });
  }
}

function* search(action) {
  const { searchString } = action;

  try {
    const response = yield JobOfferTemplateService.getAll({ searchString });
    yield put({
      type: JobOfferTemplateActionTypes.SEARCH_SUCCESS,
      jobOfferTemplates: response.body,
    });
  } catch (error) {
    yield put({
      type: JobOfferTemplateActionTypes.SEARCH_FAIL,
      error,
    });
  }
}

function* jobOfferTemplateSaga() {
  yield takeLatest(JobOfferTemplateActionTypes.GET_ALL, getAll);
  yield takeLatest(JobOfferTemplateActionTypes.GET, get);
  yield takeLatest(JobOfferTemplateActionTypes.GET_VARIABLES, getVariables);
  yield takeLatest(JobOfferTemplateActionTypes.POST, post);
  yield takeLatest(JobOfferTemplateActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(JobOfferTemplateActionTypes.PUT, update);
  yield takeLatest(JobOfferTemplateActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(JobOfferTemplateActionTypes.DELETE, del);
  yield takeLatest(JobOfferTemplateActionTypes.DELETE_SUCCESS, getAll);
  yield takeLatest(JobOfferTemplateActionTypes.SEARCH, search);
}

export default jobOfferTemplateSaga;
