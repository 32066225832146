import {
  openHiringInformationModal,
  closeHiringInformationModal,
  changeForm,
  getHiringTypes,
  getVacancyCodes,
  HiringInformationActionTypes,
} from './HiringInformationAction';
import HiringInformationModal from './HiringInformationModal';
import HiringInformationReducer from './HiringInformationReducer';
import HiringInformationSaga from './HiringInformationSaga';

const HiringInformationAction = {
  openHiringInformationModal,
  closeHiringInformationModal,
  changeForm,
  getHiringTypes,
  getVacancyCodes,
  HiringInformationActionTypes,
};

export {
  HiringInformationModal,
  HiringInformationAction,
  HiringInformationReducer,
  HiringInformationSaga,
};
