import { combineReducers } from 'redux';

import JobClosureActionTypes from './JobClosureActionTypes';

export const isModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobClosureActionTypes.OPEN_JOB_CLOSURE_MODAL:
    return true;
  case JobClosureActionTypes.CLOSE_JOB_CLOSURE_MODAL:
    return false;
  default:
    return state;
  }
};

export const currentJobId = (state = null, action) => {
  switch (action.type) {
  case JobClosureActionTypes.OPEN_JOB_CLOSURE_MODAL:
    return action.jobId;
  case JobClosureActionTypes.CLOSE_JOB_CLOSURE_MODAL:
    return null;
  default:
    return state;
  }
};

export const currentStep = (state = 0, action) => {
  switch (action.type) {
  case JobClosureActionTypes.OPEN_JOB_CLOSURE_MODAL:
    return 0;
  case JobClosureActionTypes.SET_JOB_CLOSURE_MODAL_STEP:
    return action.step;
  default:
    return state;
  }
};

export const finalJobStepId = (state = null, action) => {
  switch (action.type) {
  case JobClosureActionTypes.GET_FINAL_JOB_STEP_ID:
    return null;
  case JobClosureActionTypes.GET_FINAL_JOB_STEP_ID_SUCCESS:
    return action.finalStepId;
  default:
    return state;
  }
};

export const templateEmails = (state = null, action) => {
  switch (action.type) {
  case JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS:
    return null;
  case JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS_SUCCESS:
    return action.templateEmails;
  default:
    return state;
  }
};

export const surveys = (state = null, action) => {
  switch (action.type) {
  case JobClosureActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS:
    return action.payload;
  case JobClosureActionTypes.GET_BIND_SURVEY_CAREER_PAGES_FAIL:
    return [];
  default:
    return state;
  }
};

export const markers = (state = [], action) => {
  switch (action.type) {
  case JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS:
    return [];
  case JobClosureActionTypes.GET_ALL_TEMPLATE_EMAILS_SUCCESS:
    return action.markers;
  default:
    return state;
  }
};

export const isFeedbackSent = (state = false, action) => {
  switch (action.type) {
  case JobClosureActionTypes.SEND_FEEDBACK_EMAILS:
    return true;
  case JobClosureActionTypes.SEND_FEEDBACK_EMAILS_FAIL:
  case JobClosureActionTypes.CLOSE_JOB_CLOSURE_MODAL:
    return false;
  default:
    return state;
  }
};

export const closeJobSuccess = (state = false, action) => {
  switch (action.type) {
  case JobClosureActionTypes.OPEN_JOB_CLOSURE_MODAL:
  case JobClosureActionTypes.CLOSE_JOB_CLOSURE_MODAL:
  case JobClosureActionTypes.CLOSE_JOB:
  case JobClosureActionTypes.CLOSE_JOB_FAIL:
    return false;
  case JobClosureActionTypes.CLOSE_JOB_SUCCESS:
    return true;
  default:
    return state;
  }
};

export const hasErrorClosingJob = (state = false, action) => {
  switch (action.type) {
  case JobClosureActionTypes.OPEN_JOB_CLOSURE_MODAL:
  case JobClosureActionTypes.CLOSE_JOB_CLOSURE_MODAL:
  case JobClosureActionTypes.CLOSE_JOB:
  case JobClosureActionTypes.CLOSE_JOB_SUCCESS:
    return false;
  case JobClosureActionTypes.CLOSE_JOB_FAIL:
    return true;
  default:
    return state;
  }
};

export default combineReducers({
  isModalOpen,
  currentJobId,
  currentStep,
  finalJobStepId,
  templateEmails,
  markers,
  isFeedbackSent,
  closeJobSuccess,
  hasErrorClosingJob,
  surveys,
});
