import { combineReducers } from 'redux';
import { CandidateTestsActionTypes } from './CandidateTestsAction';
import JobStepTestIds from '../../../../../constants/Job/JobStepTestIds';
import JobApplicationActionTypes from '../../../../../constants/Job/JobApplication/JobApplicationActionTypes';
import { GUPY_PROFILE_TEST_CODE } from '../../../JobStep/constants/ProfileTest';

export const profileTestResult = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT:
    return null;
  case CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT_SUCCESS:
    return action.profileTestResult;
  default:
    return state;
  }
};

export const hasProfileTestResultStep = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT:
    return false;
  case CandidateTestsActionTypes.GET_PROFILE_TEST_RESULT_SUCCESS:
    return action.jobSteps && action.jobSteps.some(step => step.testId === JobStepTestIds.profile
      || step.newTestId === GUPY_PROFILE_TEST_CODE);
  default:
    return state;
  }
};

export const hasError500 = (state = false, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS:
  case CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS:
    return false;
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_FAIL:
  case CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS_FAIL:
    return action.error.statusCode >= 500;
  default:
    return state;
  }
};

export const customTestsResult = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return {};
  case CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS_SUCCESS:
    return { ...state, [action.applicationId]: action.customTestsResult };
  default:
    return state;
  }
};

export const providerTestsMultipleResults = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return {};
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_SUCCESS:
    return { ...state, [action.applicationId]: action.multipleTestsResult };
  default:
    return state;
  }
};

export const testsResults = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
    return {};
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_SUCCESS:
    return { ...state, [action.applicationId]: action.testsResults };
  default:
    return state;
  }
};

export const expandedApplication = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.INIT:
  case CandidateTestsActionTypes.GET_APPLICATION_DETAILS:
    return null;
  case CandidateTestsActionTypes.GET_APPLICATION_DETAILS_SUCCESS:
    return action.application;
  default:
    return state;
  }
};

export const isLoadingApplication = (state = false, action) => {
  switch (action.type) {
  case CandidateTestsActionTypes.GET_APPLICATION_DETAILS:
    return true;
  case JobApplicationActionTypes.INIT:
  case CandidateTestsActionTypes.GET_APPLICATION_DETAILS_SUCCESS:
  case CandidateTestsActionTypes.GET_APPLICATION_DETAILS_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLoadingTests = (state = false, action) => {
  switch (action.type) {
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS:
  case CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS:
    return true;
  case JobApplicationActionTypes.INIT:
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_SUCCESS:
  case CandidateTestsActionTypes.INIT_CANDIDATE_TESTS_FAIL:
  case CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS_SUCCESS:
  case CandidateTestsActionTypes.GET_CUSTOM_TESTS_RESULTS_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  isLoadingTests,
  isLoadingApplication,
  hasError500,
  testsResults,
  profileTestResult,
  customTestsResult,
  expandedApplication,
  hasProfileTestResultStep,
  providerTestsMultipleResults,
});
