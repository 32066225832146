import React from 'react';
import PropTypes from 'prop-types';

import { MainSelectList as SelectList } from '@gupy/front-commons';

import './JobCustomFormTemplateConditionalFilter.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  filterOptions: PropTypes.array.isRequired,
  filterSelectedValue: PropTypes.string.isRequired,
  setFilterSelectedValue: PropTypes.func.isRequired,
};

const JobCustomFormTemplateConditionalFilter = ({
  messages,
  filterOptions,
  filterSelectedValue,
  setFilterSelectedValue,
}) => (
  <div id="conditional-filter" className="conditional-filter">
    <span className="conditional-filter__description">{messages.conditionalFields}</span>
    <SelectList
      id="conditional-filter-list"
      name="conditional-filter"
      className="conditional-filter__select"
      noResultsLabel={messages.noResultsLabel}
      filterLabel={messages.filterLabel}
      noItemsSelectedLabel={`${messages.conditionalFilterBy} ${messages.selectAnOption}`}
      oneItemSelectedLabel={`${messages.conditionalFilterBy} ${filterSelectedValue}`}
      value={filterSelectedValue}
      onChange={e => setFilterSelectedValue(e.target.value)}
      data={filterOptions}
      responsive
    />
  </div>
);

JobCustomFormTemplateConditionalFilter.propTypes = propTypes;

export default JobCustomFormTemplateConditionalFilter;
