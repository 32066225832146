import { BaseForm } from '@gupy/front-commons';

class ApprovalWorkflowFormValidator extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          required: true,
          maxLength: 50,
        },
      },
      approverIds: {
        validate: {
          notEmptyArray: true,
        },
      },
      requestMethod: {
        validate: {
          required: true,
        },
      },
    });
  }
}

export default ApprovalWorkflowFormValidator;
