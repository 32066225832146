import React, { Fragment, useState, useEffect } from 'react';
import { FormattedHTMLMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router-dom';

import { SchemaFormHelper } from '@gupy/front-commons';
import { Button, ConditionalResponsive, Tip } from '@gupy/design-system';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';

import Dialogs from '@gupy/design-system/Dialogs/index';
import JobCustomFormTemplateTable from './components/JobCustomFormTemplateTable';
import JobCustomFormTemplateCards from './components/JobCustomFormTemplateCards';
import JobCustomFormTemplateModal from './components/JobCustomFormTemplateModal';
import JobCustomFormTemplateSave from './components/JobCustomFormTemplateSave';

import { GoogleAnalyticsEventsEnum } from './GoogleAnalyticsEventsEnum';
import { ItemSortEnum } from './ItemSortEnum';

const propTypes = {
  closeModal: PropTypes.func.isRequired,
  formErrors: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
  onCancelCompanySchema: PropTypes.func.isRequired,
  onDeleteField: PropTypes.func.isRequired,
  onOpenGlobalConfirmDialog: PropTypes.func.isRequired,
  onSaveCompanySchema: PropTypes.func.isRequired,
  onSaveCompanyItem: PropTypes.func.isRequired,
  onSaveField: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  schema: PropTypes.object,
  selectedField: PropTypes.object,
};

const fieldTranslationEnum = {
  multiSelect: 'multiSelect',
  select: 'dropDownList',
  text: 'shortText',
  number: 'number',
  boolean: 'yesNo',
  time: 'time',
  date: 'date',
  attachment: 'attachment',
  conditional: 'conditional',
};

export const getFormDataFromSchema = (schema, messages) => {
  const schemaFormHelper = new SchemaFormHelper(schema);
  const getSelectItems = (obj) => {
    if (obj.options) {
      if (obj.options.enum) {
        return obj.options.enum;
      }

      if (obj.options.items && obj.options.items.enum) {
        return obj.options.items.enum;
      }
    }
    return [];
  };

  const getChild = child => ({
    fieldKey: child.key,
    fieldName: child.title,
    fieldType: child.uiType,
    fieldSelectItems: getSelectItems(child),
    dependentOption: child.dependentOption,
    required: child.required,
  });

  const groupChildren = children => children.reduce((group, current) => {
    const optionName = current.dependentOption[0];
    let optionGroup = group[optionName];
    optionGroup = optionGroup || [];
    optionGroup.push(current);

    return { ...group, [optionName]: optionGroup };
  }, {});

  return schemaFormHelper.fields.map((obj) => {
    const children = obj.children && obj.children.length ? obj.children.map(getChild) : [];
    const childrenByOption = groupChildren(children);

    return {
      key: obj.key,
      title: obj.title,
      dataType: messages[fieldTranslationEnum[obj.uiType]] || '',
      required: obj.required ? messages.requiredField : messages.optionalField,
      editableObj: {
        fieldKey: obj.key,
        fieldName: obj.title,
        fieldType: obj.uiType,
        fieldMandatory: obj.required,
        fieldSelectItems: getSelectItems(obj),
        children,
        childrenByOption,
      },
    };
  });
};

export const hasInMemoryUpdates = ({
  schema,
  originalSchema,
  items,
  originalItems,
}) => JSON.stringify(schema) !== JSON.stringify(originalSchema)
  || JSON.stringify(items) !== JSON.stringify(originalItems);

const JobCustomFormTemplate = ({
  closeModal,
  formErrors,
  isModalOpen,
  isSaving,
  messages,
  onCancelCompanySchema,
  onDeleteField,
  onOpenGlobalConfirmDialog,
  onSaveCompanySchema,
  onSaveCompanyItem,
  onSaveField,
  openModal,
  schema,
  selectedField,
}) => {
  const [items, setItems] = useState([]);
  const [itemsOrder, setItemsOrder] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [originalSchema, setOriginalSchema] = useState(null);
  const [unsavedChangesOpen, setUnsavedChangesOpen] = useState(false);

  const newSchema = () => (!schema
    ? null
    : { ...schema, uiSchema: { ...schema.uiSchema, 'ui:order': itemsOrder } });

  const handleSubmitModal = ({ fieldToAdd }) => {
    onSaveField({
      schema: newSchema(),
      fieldToAdd,
    });
  };

  const handleDeleteItem = (field) => {
    onOpenGlobalConfirmDialog({
      title: messages.confirmDeleteTitle,
      message: messages.confirmDeleteMessage,
      confirmButtonText: messages.confirmDeleteConfirmButton,
      cancelButtonText: messages.confirmDeleteCancelButton,
      onConfirmClick: () => {
        onDeleteField({
          schema: newSchema(),
          field,
        });
      },
    });
  };

  const handleEditItem = (item) => {
    openModal(item.editableObj);
  };

  const handleItemSort = (item, index, sortType) => {
    let nextCurrentPosition;
    switch (sortType) {
    case ItemSortEnum.MOVE_TO_TOP:
      nextCurrentPosition = 0;
      break;
    case ItemSortEnum.MOVE_UP:
      nextCurrentPosition = index - 1;
      break;
    case ItemSortEnum.MOVE_DOWN:
      nextCurrentPosition = index + 1;
      break;
    case ItemSortEnum.MOVE_TO_END:
      nextCurrentPosition = items.length - 1;
      break;
    default:
      nextCurrentPosition = index;
      break;
    }

    const COUNT_REPLACE = 1;
    const currentPosition = index;

    const newItemsOrdered = [...items];

    newItemsOrdered.splice(currentPosition, COUNT_REPLACE);
    newItemsOrdered.splice(nextCurrentPosition, 0, item);

    const newItemsOrderedKeys = newItemsOrdered.map(it => it.key);

    onSaveCompanyItem({ items: newItemsOrdered });
    setItems(newItemsOrdered);
    setItemsOrder(newItemsOrderedKeys);
  };

  const saveCompanySchema = () => {
    onSaveCompanySchema({
      schema: newSchema(),
      successMessage: (
        <FormattedHTMLMessage
          id="save_all_message_success"
          defaultMessage="As alterações de campos customizados foram <b>salvas com sucesso!</b>"
        />
      ),
      failMessage: (
        <FormattedHTMLMessage
          id="save_all_message_fail"
          defaultMessage="Não foi possível salvar as alterações de campos customizados! <b>Verifique sua conexão e tente novamente.</b>"
        />
      ),
    });

    GoogleAnalyticsHelper.sendEvent({
      category: GoogleAnalyticsEventsEnum.category,
      action: GoogleAnalyticsEventsEnum.saveSchema,
    });
  };

  useEffect(() => {
    if (!schema) return;
    const itemsUnsorted = getFormDataFromSchema(schema, messages);
    const schemaOrder = schema.uiSchema['ui:order'];
    const itemsSorted = schemaOrder.map(key => itemsUnsorted.find(itemUns => itemUns.key === key));
    const itemsSortedKeys = itemsSorted.map(item => item.key);

    setItemsOrder(itemsSortedKeys);
    setItems(itemsSorted);
    onSaveCompanyItem({ items: itemsSorted });
  }, [schema, selectedField]);

  useEffect(() => {
    if (!schema) return;
    const itemsUnsorted = getFormDataFromSchema(schema, messages);
    const schemaOrder = schema.uiSchema['ui:order'];
    const itemsSorted = schemaOrder.map(key => itemsUnsorted.find(itemUns => itemUns.key === key));

    setOriginalSchema(schema);
    setOriginalItems(itemsSorted);
    onSaveCompanyItem({ items: itemsSorted });
  }, []);

  const handleUnsavedChanges = () => {
    if (
      hasInMemoryUpdates({
        schema,
        originalSchema,
        items,
        originalItems,
      })
    ) {
      if (!unsavedChangesOpen) {
        setUnsavedChangesOpen(true);
        Dialogs.confirm({
          title: messages.leavingWithoutSaving,
          description: messages.saveQuestionBeforeLeaving,
          confirmButtonText: '',
          confirmButton: {
            label: messages.saveChanges,
            action: () => {
              saveCompanySchema();
              setUnsavedChangesOpen(false);
            },
          },
          cancelButton: {
            label: messages.cancelAndStay,
            action: () => {
              setUnsavedChangesOpen(false);
            },
          },
        });
      }
    }
  };

  const shouldOpenExitWarning = (e) => {
    if (e.toElement == null && e.relatedTarget == null) {
      handleUnsavedChanges();
    }
  };

  useEffect(() => {
    window.addEventListener('mouseout', shouldOpenExitWarning);
    return () => {
      window.removeEventListener('mouseout', shouldOpenExitWarning);
    };
  }, [shouldOpenExitWarning]);

  return (
    <Fragment>
      <Prompt
        when={hasInMemoryUpdates({
          schema,
          originalSchema,
          items,
          originalItems,
        })}
        message={() => messages.leavingWithoutSavingInMemoryUpdates}
      />
      <Button
        onClick={openModal}
        submit
        data-testid="create-new-custom-field-button"
      >
        {messages.createNewFieldButton}
      </Button>
      <hr />
      <Tip
        id="tip-new-reorder-info"
        className="job-custom-form-template__tip-reorder"
        type="hint"
        showIcon
        text={(
          <FormattedHTMLMessage
            id="job-custom-form-template-tip-conditional-field"
            defaultMessage={
              '<strong>Novidade!</strong> Agora você também pode criar <strong>campos condicionais</strong>.'
            }
          />
        )}
      />
      {hasInMemoryUpdates({
        schema,
        originalSchema,
        items,
        originalItems,
      }) && (
        <JobCustomFormTemplateSave
          messages={messages}
          onSaveCompanySchema={saveCompanySchema}
          onCancelCompanySchema={onCancelCompanySchema}
          isSaving={isSaving}
        />
      )}
      {schema ? (
        <ConditionalResponsive
          fullScreenComponent={(
            <JobCustomFormTemplateTable
              handleItemSort={handleItemSort}
              items={items}
              messages={messages}
              onDeleteItem={handleDeleteItem}
              onEditItem={handleEditItem}
            />
          )}
          responsiveComponent={(
            <JobCustomFormTemplateCards
              handleItemSort={handleItemSort}
              items={items}
              messages={messages}
              onDeleteItem={handleDeleteItem}
              onEditItem={handleEditItem}
            />
          )}
        />
      ) : (
        messages.noFieldDataFound
      )}
      {isModalOpen && (
        <JobCustomFormTemplateModal
          messages={messages}
          companySchemaField={selectedField}
          formErrors={formErrors}
          onSubmit={handleSubmitModal}
          onClose={closeModal}
        />
      )}
    </Fragment>
  );
};

JobCustomFormTemplate.propTypes = propTypes;
JobCustomFormTemplate.defaultProps = {
  schema: null,
};

export default injectIntl(JobCustomFormTemplate);
