import { updateIntl } from 'react-intl-redux';
import Globalization from '../../globalization/Globalization';
import AppActionTypes from '../../constants/App/AppActionTypes';

export const openConfirmDialog = options => ({
  type: AppActionTypes.OPEN_CONFIRM_DIALOG,
  options,
});

export const closeConfirmDialog = () => ({
  type: AppActionTypes.CLOSE_CONFIRM_DIALOG,
});

export const routeChanged = () => ({
  type: AppActionTypes.ROUTE_CHANGED,
});

export const updateGlobalization = (defaultLanguage, language) => (
  updateIntl(Globalization(defaultLanguage, language))
);

export const updateNetworkStatus = status => ({
  type: AppActionTypes.UPDATE_NETWORK_STATUS,
  status,
});
