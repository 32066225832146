import { call, put, takeLatest } from 'redux-saga/effects';

import { JobApplicationHistoryActionTypes } from './JobApplicationHistoryAction';
import JobService from '../../../../../services/Job/JobService';

export function* initJobApplicationHistory(action) {
  const { candidateId, currentJobId } = action;

  try {
    const jobApplicationsResponse = yield call(JobService.getJobApplicationHistory, candidateId);

    yield put({
      type: JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY_SUCCESS,
      jobApplicationsResult: jobApplicationsResponse.body,
      currentJobId,
      candidateId,
    });
  } catch (error) {
    yield put({
      type: JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY_FAIL,
      error,
    });
  }
}

export function* nextPageJobApplicationHistory(action) {
  const { candidateId, currentJobId, page } = action;

  try {
    const pagination = {
      page,
      perPage: 5,
    };

    const jobApplicationsResponse = yield call(
      JobService.getJobApplicationHistory,
      candidateId, { pagination },
    );

    yield put({
      type: JobApplicationHistoryActionTypes.NEXT_PAGE_JOB_APPLICATION_HISTORY_SUCCESS,
      jobApplicationsResult: jobApplicationsResponse.body,
      currentJobId,
    });
  } catch (error) {
    yield put({
      type: JobApplicationHistoryActionTypes.NEXT_PAGE__JOB_APPLICATION_HISTORY_FAIL,
      error,
    });
  }
}

function* JobApplicationHistorySaga() {
  yield takeLatest(
    JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY,
    initJobApplicationHistory,
  );
  yield takeLatest(
    JobApplicationHistoryActionTypes.NEXT_PAGE_JOB_APPLICATION_HISTORY,
    nextPageJobApplicationHistory,
  );
}

export default JobApplicationHistorySaga;
