import moment from 'moment';

export const expiredPublishedJobsStartDate = moment()
  .subtract(1, 'month')
  .format('YYYY-MM-DD');

export const candidatesWithoutFeedbackInClosedApplicationsStartDate = moment()
  .subtract(3, 'month')
  .format('YYYY-MM-DD');

export const endDate = moment().format('YYYY-MM-DD');
