import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  template: {
    id: 'job_status_template',
    defaultMessage: 'Template',
  },
  saveButton: {
    id: 'save',
    defaultMessage: 'Salvar',
  },
  cancelButton: {
    id: 'cancel',
    defaultMessage: 'Cancelar',
  },
  jobOfferTemplatesPageGridContentTitle: {
    id: 'job_offer_templates_page_grid_content_title',
    defaultMessage: 'Carta Oferta',
  },
  jobOfferTemplateName: {
    id: 'job_offer_template_name',
    defaultMessage: 'Nome da carta oferta',
  },
  noResultsFound: {
    id: 'no_results_found',
    defaultMessage: 'Nenhum resultado encontrado',
  },
  success: {
    id: 'job_offer_template_saved_success',
    defaultMessage: 'Template de carta oferta salvo com sucesso',
  },
  noJobOfferTemplateCreatedYet: {
    id: 'no_job_offer_template_created_yet',
    defaultMessage: 'Nenhum template de carta oferta disponível',
  },
  noJobOfferTemplateFoundTitle: {
    id: 'no_job_offer_template_found',
    defaultMessage: 'Nenhum template de carta oferta encontrado',
  },
  noJobOfferTemplateFoundDescription: {
    id: 'no_job_offer_template_found_description',
    defaultMessage: 'Reveja os termos usados e tente uma nova busca.',
  },
  noJobOfferTemplateCreatedYetDescription: {
    id: 'no_job_offer_template_created_yet_description',
    defaultMessage:
      'Os templates de carta oferta te ajudam a ter uma comunicação mais eficiente com os seus candidatos(as).',
  },
  noJobOfferTemplateCreatedYetAdmissionOnlyDescription: {
    id: 'no_job_offer_template_created_yet_admission_only_description',
    defaultMessage:
      'Os templates de carta oferta te ajudam a ter uma comunicação mais eficiente com as pessoas pré-colaboradoras',
  },
  createJobOfferTemplateButton: {
    id: 'create_job_offer_template_button',
    defaultMessage: 'Criar template de carta oferta',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'Excluir',
  },
  invalidImageExtension: {
    id: 'image_extension_error',
    defaultMessage:
      'Extensão não permitida! Por favor, selecione uma imagem .jpg .jpeg .gif ou .png.',
  },
  jobOfferSubjectIsTooLong: {
    id: 'job_offer_subject_is_too_long',
    defaultMessage: 'Limite de caracteres atingido',
  },
  jobOfferRepackingTitle: {
    id: 'job_offer_repacking_title',
    defaultMessage: 'Template de carta oferta',
  },
  JobOfferRepackingCardTitle: {
    id: 'job_offer_repacking_card_title',
    defaultMessage: 'Precisa de ajuda?',
  },
  JobOfferRepackingKnowMore: {
    id: 'job_offer_repacking_know_more',
    defaultMessage: 'Quero saber mais',
  },
});

const markupsMessages = defineMessages({
  candidateFullName: {
    id: 'job-offer-template-variables-candidate-full-name',
    defaultMessage: 'Nome completo do candidato(a)',
  },
  candidateFirstName: {
    id: 'job-offer-template-variables-candidate-first-name',
    defaultMessage: 'Primeiro nome do(a) candidato(a)',
  },
  jobName: {
    id: 'job-offer-template-variables-job-name',
    defaultMessage: 'jobName_jobOffer',
  },
  department: {
    id: 'job-offer-template-variables-department',
    defaultMessage: 'Área de trabalho',
  },
  typeJob: {
    id: 'job-offer-template-variables-type-job',
    defaultMessage: 'typeJob_jobOffer',
  },
  jobPosition: {
    id: 'job-offer-template-variables-job-position',
    defaultMessage: 'jobPosition_jobOffer',
  },
  jobPostingPWD: {
    id: 'job-offer-template-variables-job-posting-pwd',
    defaultMessage: 'Vaga PCD',
  },
  jobPostingCode: {
    id: 'job-offer-template-variables-job-posting-code',
    defaultMessage: 'Código da vaga',
  },
  numberPositions: {
    id: 'job-offer-template-variables-number-positions',
    defaultMessage: 'Quantidade de posições',
  },
  recruiterName: {
    id: 'job-offer-template-variables-recruiter-name',
    defaultMessage: 'Recrutador(a) da vaga',
  },
  managerName: {
    id: 'job-offer-template-variables-manager-name',
    defaultMessage: 'Gestor(a) da vaga',
  },
  hiringDate: {
    id: 'job-offer-template-variables-hiring-date',
    defaultMessage: 'Data da contratação',
  },
  typeContract: {
    id: 'job-offer-template-variables-type-contract',
    defaultMessage: 'Tipo de contratação',
  },
  monthlyWage: {
    id: 'job-offer-template-variables-monthly-wage',
    defaultMessage: 'Salário mensal',
  },
  todayDate: {
    id: 'job-offer-template-variables-today-date',
    defaultMessage: 'Data de Hoje',
  },
  companyName: {
    id: 'job-offer-template-variables-company-name',
    defaultMessage: 'Nome da empresa',
  },
  careerPageName: {
    id: 'job-offer-template-variables-carreer-page-name',
    defaultMessage: 'Nome da página de carreiras',
  },
  companyBranches: {
    id: 'job-offer-template-variables-company-branches',
    defaultMessage: 'Nome da Filial',
  },

});
const getMessages = intl => formatMessages(intl, messages);
const getMarkupsMessages = intl => formatMessages(intl, markupsMessages);

export {
  getMessages,
  getMarkupsMessages,
};
