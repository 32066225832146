import { combineReducers } from 'redux';
import * as ActionTypes from './ActionTypes';

export default combineReducers({
  curriculumInfo: (state = {}, action) => {
    switch (action.type) {
    case ActionTypes.GET_CURRICULUM_INFO_SUCCESS:
      return action.curriculumInfo;
    case ActionTypes.GET_CURRICULUM_INFO_FAIL:
    default:
      return state;
    }
  },
  isLoading: (state = true, action) => {
    switch (action.type) {
    case ActionTypes.GET_CURRICULUM_INFO_REQUEST:
      return true;
    case ActionTypes.GET_CURRICULUM_INFO_SUCCESS:
    case ActionTypes.GET_CURRICULUM_INFO_FAIL:
      return false;
    default:
      return state;
    }
  },
});
