const TestProviderStatusEnum = {
  inactive: 'inactive',
  active: 'active',
  toActivate: 'to_activate',
  activating: 'activating',
  invalid: 'invalid',
  recentlyActivated: 'recently_activated',
  error: 'error',
};

export default TestProviderStatusEnum;
