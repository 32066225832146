import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  injectIntl,
  FormattedHTMLMessage,
} from 'react-intl';
import { connect } from 'react-redux';

import CardCollapse from '@gupy/design-system/CardCollapse/CardCollapse';
import { Button, Dropdown, Tip } from '@gupy/design-system';

import * as actions from '../../JobDeletionAction';
import * as selectors from '../../Selectors';

import { StepEnum } from '../JobDeletionAfterPublicationModal';
import TemplateEmailPreview from '../../../../../components/TemplateEmailPreview/TemplateEmailPreview';

const propTypes = {
  messages: PropTypes.object.isRequired,
  setStep: PropTypes.func.isRequired,
  requestGetTemplateEmails: PropTypes.func.isRequired,
  templateEmails: PropTypes.arrayOf(PropTypes.object).isRequired,
  expanded: PropTypes.bool.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  setSelectedTemplate: PropTypes.func.isRequired,
  hasFeedbacksToSend: PropTypes.bool.isRequired,
  feedbackStepIsCompleted: PropTypes.bool.isRequired,
};

const FeedbackStep = ({
  messages,
  setStep,
  requestGetTemplateEmails,
  templateEmails,
  expanded,
  selectedTemplate,
  setSelectedTemplate,
  hasFeedbacksToSend,
  feedbackStepIsCompleted,
}) => {
  useEffect(() => {
    requestGetTemplateEmails();
  }, []);

  const handleChange = () => {
    setStep(StepEnum.feedback);
  };

  const handleSelectedTemplate = (newTemplateId) => {
    const selectedTemplateMail = templateEmails.find(template => template.id === newTemplateId);
    setSelectedTemplate(selectedTemplateMail || {});
  };

  const handleCurrentColor = () => {
    const gupyColorSteel = '#8C8C8C';
    const colorSuccess = '#13ce8b';
    if (!hasFeedbacksToSend || feedbackStepIsCompleted) {
      return colorSuccess;
    }
    return gupyColorSteel;
  };

  return (
    <div className="job-deletion-feedback">
      <CardCollapse
        id="job-deletion-feedback-expansion-panel"
        title={messages.feedbackStepTitle}
        description=""
        icon="CheckCircle"
        iconColor={handleCurrentColor()}
        expanded={expanded}
        onCollapse={handleChange}
      >
        <div className="job-deletion-reason__container">
          {!hasFeedbacksToSend
            ? (
              <Tip
                type="info"
                className="job-deletion__tip"
                text={messages.allReceivedFeedbackInfo}
                showIcon
              />
            ) : (
              <div>
                <Dropdown
                  id="job-deletion-feedback-template"
                  data-testid="job-deletion-feedback-template"
                  label={messages.selectEmailTemplate}
                  options={templateEmails.map(template => ({
                    label: template.name,
                    value: template.id,
                  }))}
                  selectedOptionValue={selectedTemplate.id}
                  nonSelectText={messages.selectATemplate}
                  onChange={handleSelectedTemplate}
                  emptyOptionsText={messages.noDiscontinuedJobPostingTemplate}
                  required
                />
                {!!selectedTemplate.id && (
                  <div className="job-deletion-feedback__template-preview">
                    <TemplateEmailPreview
                      templateEmail={selectedTemplate}
                    />
                  </div>
                )}
                <Tip
                  type="warning"
                  className="job-deletion__tip"
                  text={(
                    <FormattedHTMLMessage
                      id="job-deletion-feedback-tip"
                      defaultMessage="<strong>Atenção</strong>: O feedback selecionado só será enviado quando <strong>a exclusão da vaga for concluída.</strong> Essa comunicação será enviada para <strong>as pessoas inscritas que ainda não receberam o feedback,</strong> inclusive as que se inscreverem durante o período de solicitação de exclusão."
                    />
                  )}
                  showIcon
                />
              </div>
            )}
          <Button
            size="md"
            type="outlined"
            onClick={() => setStep(StepEnum.confirm)}
            className="job-deletion__button"
            disabled={!feedbackStepIsCompleted}
            data-testid="job-deletion-feedback-button"
          >
            {messages.reasonProceedButton}
          </Button>
        </div>
      </CardCollapse>
    </div>
  );
};

FeedbackStep.propTypes = propTypes;

const mapStateToProps = state => ({
  isLoading: selectors.getIsLoading(state),
  templateEmails: selectors.getTemplateEmails(state),
});

const mapDispatchToProps = dispatch => ({
  requestGetTemplateEmails: () => dispatch(actions.getTemplateEmails()),
});

export default injectIntl(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FeedbackStep),
);
