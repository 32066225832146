import React, { useEffect, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape, defineMessages, FormattedHTMLMessage } from 'react-intl';
import classNames from 'classnames';

import { Button, Spinner } from '@gupy/design-system';

import { withRouter } from 'react-router-dom';
import { useFlag } from '@unleash/proxy-client-react';
import Grid from '../../components/Grid/Grid';
import SetupGridSidebarMenu from '../../components/Grid/SetupGridSidebarMenu';
import GridContent from '../../components/Grid/GridContent';
import { openConfirmDialog } from '../../actions/App/AppActions';
import JobOfferTemplateBar from './components/JobOfferTemplateBar';
import JobOfferTemplateCard from './components/JobOfferTemplateCard';
import {
  getAllJobOfferTemplates,
  deleteJobOfferTemplate,
  searchJobOfferTemplates,
} from './Actions';
import { getMessages } from './Messages';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import { BlockedComponent } from '../../components/BlockedComponent';

const propTypes = {
  JobOfferTemplate: PropTypes.object.isRequired,
  getAllJobOfferTemplates: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  deleteJobOfferTemplate: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  searchJobOfferTemplates: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
};

function JobOfferTemplateContainer(props) {
  const [searchValue, setSearchValue] = useState('');

  const { JobOfferTemplate, permissions, intl, currentUser } = props;
  const { jobOfferTemplates, isLoading, searched } = JobOfferTemplate;
  const hasJobOfferTemplate = (jobOfferTemplates && jobOfferTemplates.length > 0) || searched;
  const messages = getMessages(intl);

  useEffect(() => {
    props.getAllJobOfferTemplates();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      props.searchJobOfferTemplates(searchValue);
    }, 400);

    return () => clearTimeout(timeout);
  }, [searchValue]);

  const handleCreateClick = useCallback(() => {
    props.history.push('/companies/setup/job-offer-template/new');
  }, [props.history]);

  const handleEditClick = useCallback((id) => {
    props.history.push(`/companies/setup/job-offer-template/${id}`);
  }, [props.history]);

  const handleDelete = useCallback((templateId) => {
    const confirmMessages = defineMessages({
      title: {
        id: 'job_offer_template_delete_confirmation_title',
        defaultMessage: 'Excluir template da carta oferta',
      },
      message: {
        id: 'job_offer_template_delete_confirmation_message',
        defaultMessage: 'Você confirma a exclusão do template da carta oferta ?',
      },
      confirmButtonText: {
        id: 'delete',
        defaultMessage: 'Excluir',
      },
      cancelButtonText: {
        id: 'cancel',
        defaultMessage: 'Cancelar',
      },
    });

    props.openConfirmDialog({
      title: intl.formatMessage(confirmMessages.title),
      message: intl.formatMessage(confirmMessages.message),
      confirmButtonText: intl.formatMessage(confirmMessages.confirmButtonText),
      cancelButtonText: intl.formatMessage(confirmMessages.cancelButtonText),
      onConfirmClick: () => {
        const messagesConfirm = defineMessages({
          success: {
            id: 'job_offer_template_delete_success_message',
            defaultMessage: 'Template de carta oferta excluído com sucesso.',
          },
        });
        props.deleteJobOfferTemplate(
          templateId,
          { success: intl.formatMessage(messagesConfirm.success) },
        );
      },
    });
  }, []);

  const handleDeleteClick = useCallback((event, templateId) => {
    event.stopPropagation();
    handleDelete(templateId);
  }, []);

  const noResultsNode = useMemo(() => (
    <div>
      <h3>{messages.noJobOfferTemplateFoundTitle}</h3>
      <p>{messages.noJobOfferTemplateFoundDescription}</p>
    </div>
  ), [
    messages.noJobOfferTemplateFoundTitle,
    messages.noJobOfferTemplateFoundDescription,
  ]);

  const emptyJobOfferTemplate = useMemo(() => (
    <div className="col-xs-12 col-sm-9 empty-job-offer-template">
      <div className="empty-job-offer-template__illustration">
        <img
          className="empty-job-offer-template__image"
          src={`${process.env.REACT_APP_ASSETS_URL}/email-template/illustration.png`}
          alt={messages.twoPeopleTalking}
        />
      </div>
      <div className="empty-job-offer-template__content">
        <h3 className="empty-job-offer-template__title">
          {messages.noJobOfferTemplateCreatedYet}
        </h3>
        <Button onClick={handleCreateClick}>
          {messages.createJobOfferTemplateButton}
        </Button>
      </div>
    </div>
  ), [
    handleCreateClick,
    messages.twoPeopleTalking,
    messages.noJobOfferTemplateCreatedYet,
    messages.noJobOfferTemplateCreatedYetAdmissionOnlyDescription,
    messages.noJobOfferTemplateCreatedYetDescription,
    messages.createJobOfferTemplateButton,
  ]);

  const resultsNode = useMemo(() => {
    if (jobOfferTemplates && jobOfferTemplates.length > 0) {
      return jobOfferTemplates.map(template => (
        <JobOfferTemplateCard
          key={template.id}
          jobOfferTemplate={template}
          onDeleteClick={handleDeleteClick}
          onEditClick={handleEditClick}
          canEditPublic={permissions.edit_job_offer_template}
          canEditPrivate={false}
          canDeletePublic={permissions.delete_job_offer_template}
          canDeletePrivate={false}
          messages={messages}
        />
      ));
    }

    return noResultsNode;
  }, [
    jobOfferTemplates,
    handleDeleteClick,
    handleEditClick,
    permissions.edit_job_offer_template,
    permissions.view_job_offer_template,
    permissions.delete_job_offer_template,
  ]);

  const isRepackagingEnabled = useFlag(featuresFlagsEnum.repackaging);
  const isJobOfferEnable = useFlag(featuresFlagsEnum.jobOffer);
  const isJobOfferBlocked = isRepackagingEnabled && !isJobOfferEnable;

  return (
    <Grid>
      <SetupGridSidebarMenu permissions={permissions} />
      <div className="col-sm-9">
        <BlockedComponent
          shouldBlock={isJobOfferBlocked}
          title={messages.jobOfferRepackingTitle}
          description={(
            <FormattedHTMLMessage
              id="job-offer-repacking-description"
              defaultMessage="<span>Encontrou o talento certo? Use a 'Carta Oferta' para criar templates de propostas editáveis com facilidade, personalizar as informações e enviar automaticamente a carta para a pessoa selecionada, proporcionando uma melhor experiência de contratação.</span>"
            />
          )}
          cardTitle={messages.JobOfferRepackingCardTitle}
          btnText={messages.JobOfferRepackingKnowMore}
          cardDescription={(
            <FormattedHTMLMessage
              id="repackaging-card-description"
              defaultMessage="<span>Aprenda com os nossos artigos na nossa Central de Ajuda! <a href='{url}' target='_blank'>Saiba mais</a></span>"
              values={{ url: 'https://support-companies.gupy.io/hc/pt-br/articles/11165979963035-Campos-customizados-o-que-s%C3%A3o-e-como-utiliz%C3%A1-los-nas-vagas' }}
            />
          )}
          currentUser={currentUser}
        >
          <GridContent
            className={classNames('card-list')}
            title={messages.jobOfferTemplatesPageGridContentTitle}
            hasSideBar={false}
          >
            {hasJobOfferTemplate || isLoading
              ? (
                <React.Fragment>
                  <JobOfferTemplateBar
                    onAddNewTemplateClick={handleCreateClick}
                    onSearchChange={event => setSearchValue(event.target.value)}
                    canCreate={permissions.create_job_offer_template}
                    searchValue={searchValue}
                  />
                  <hr />
                  <div className="card-list__grid row">
                    {isLoading ? (
                      <div className="job-offer-template__loading">
                        <Spinner color="secondary" />
                      </div>
                    ) : resultsNode}
                  </div>
                </React.Fragment>
              ) : emptyJobOfferTemplate
            }
          </GridContent>
        </BlockedComponent>
      </div>
    </Grid>
  );
}

JobOfferTemplateContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  JobOfferTemplate: state.reducers.JobOfferTemplate,
  Authentication: state.reducers.Authentication,
  currentUser: state.reducers.Authentication.currentUser,
});

export default injectIntl(withRouter(connect(
  mapStateToProps,
  {
    getAllJobOfferTemplates,
    deleteJobOfferTemplate,
    openConfirmDialog,
    searchJobOfferTemplates,
  },
)(JobOfferTemplateContainer)));
