import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export const WhatsappBusinessCampaignActionTypes = createActionTypes(
  'WhatsAppBusinessCampaign',
  asyncActionType('CREATE_CAMPAIGN'),
  'CLEAR_STATE',
);

export const createCampaign = campaign => ({
  type: WhatsappBusinessCampaignActionTypes.CREATE_CAMPAIGN,
  campaign,
});

export const clearState = () => ({
  type: WhatsappBusinessCampaignActionTypes.CLEAR_STATE,
});
