import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';

const propTypes = {
  currentUser: PropTypes.object,
  isFeatureFlagEnabled: PropTypes.bool,
};

const ZendeskInjector = ({ currentUser, isFeatureFlagEnabled }) => {
  const [isInjected, setIsInjected] = React.useState(false);

  React.useEffect(() => {
    if (isInjected || !isFeatureFlagEnabled || !currentUser.id) {
      return;
    }
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=f811c2a9-f234-4600-a2c5-064d92a9a086';
    script.type = 'text/javascript';
    script.id = 'ze-snippet';
    script.defer = true;
    script.onload = () => {
      if (window.zE) {
        window.zE('webWidget', 'helpCenter:setSuggestions', { search: 'Treinamento' });
        window.zESettings = {
          webWidget: {
            helpCenter: {
              chatButton: {
                'pt-br': 'Fale conosco',
                '*': 'Chat',
              },
            },
          },
        };
      }
    };
    document.body.appendChild(script);
    setIsInjected(true);
  }, [currentUser, isFeatureFlagEnabled]);

  return null;
};

ZendeskInjector.propTypes = propTypes;

const mapStateToProps = ({ reducers }) => ({
  currentUser: reducers.Authentication
    && reducers.Authentication.currentUser,
  isFeatureFlagEnabled: reducers.Authentication
    && reducers.Authentication.currentUser
    && reducers.Authentication.currentUser.featureFlags
    && reducers.Authentication.currentUser
      .featureFlags.includes(featuresFlagsEnum.widgetZendeskCompanies),
});

export default connect(
  mapStateToProps,
)(ZendeskInjector);
