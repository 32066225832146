import api from '../../api/DarthVaderClient';

const departmentsPath = '/company-management/companies/departments';
const similaritiesPath = '/company-management/companies/departments/similarities';

export default {
  post(payload) {
    return api.post(departmentsPath, payload);
  },

  put(id, payload) {
    return api.put(`${departmentsPath}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${departmentsPath}/${id}`);
  },

  getAll() {
    return api.get(`${departmentsPath}?order=name&page=0&perPage=9999`);
  },

  search(department) {
    return api.get(`${departmentsPath}?name=${department}&order=name&page=0&perPage=100`);
  },

  getAllSimilarities() {
    return api.get(`${similaritiesPath}?order=name&page=0&perPage=9999`);
  },
};
