import { notificationServiceClient } from '../../api/NotificationServiceClient';

const getAll = async (namespace) => {
  const result = await notificationServiceClient.get(`/api/v1/notifications/${namespace}`);
  return (result.body || []);
};

const setViewed = id => (
  notificationServiceClient.patch(`/api/v1/notifications/${id}/view`)
);

const removeNotification = id => (
  notificationServiceClient.delete(`/api/v1/notifications/${id}`)
);

export default {
  getAll,
  setViewed,
  removeNotification,
};
