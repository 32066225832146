import React from 'react';
import PropTypes from 'prop-types';
import { Stepper, Step, StepLabel, StepContent } from '@material-ui/core';
import { Button } from '@gupy/design-system';

const buttonItemTypes = PropTypes.shape({
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  styles: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
});

const stepItemTypes = PropTypes.shape({
  label: PropTypes.string,
  onTitleClick: PropTypes.func,
  content: PropTypes.string,
  buttons: PropTypes.arrayOf(buttonItemTypes),
  preChildren: PropTypes.node,
  postChildren: PropTypes.node,
});

const propTypes = {
  steps: PropTypes.arrayOf(stepItemTypes).isRequired,
  activeStep: PropTypes.number.isRequired,
};

const VerticalLinearStepper = ({ steps, activeStep }) => (
  <div>
    <Stepper className="vertical-linear-stepper" activeStep={activeStep} orientation="vertical">
      {steps.map(step => (
        <Step key={step.label}>
          <StepLabel>
            <span
              className="vertical-linear-stepper__label"
              role="presentation"
              onClick={step.onTitleClick}
            >
              {step.label}
            </span>
          </StepLabel>
          <StepContent className={'vertical-linear-stepper__content'}>
            <span>{step.content}</span>
            <div>
              <div className="vertical-linear-stepper__actions">
                {step.preChildren ? step.preChildren : null}
                <div className="vertical-linear-stepper__buttons">
                  {step.buttons ? step.buttons.map(button => (
                    <Button
                      className="vertical-linear-stepper__button"
                      key={button.label}
                      type={button.type}
                      onClick={button.onClick}
                      disabled={button.disabled}
                    >
                      {button.label}
                    </Button>
                  )) : null}
                </div>
                {step.postChildren ? step.postChildren : null}
              </div>
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  </div>
);

VerticalLinearStepper.propTypes = propTypes;

export default VerticalLinearStepper;
