import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import { Button, Divisor, Icon, IconButton, IconEnum, Infotip, H, Tip } from '@gupy/design-system';

import './DiversityProfile.scss';
import { FormattedHTMLMessage } from 'react-intl';

const propTypes = {
  messages: PropTypes.object.isRequired,
  diversityPanel: PropTypes.node,
};

const DiversityProfile = ({ messages, diversityPanel }) => {
  const [tooltip, setTooltip] = useState(messages.diversityTrainingLinkTooltip);

  const copyToClipboard = async () => {
    setTooltip(messages.diversityTrainingLinkCopied);
    setTimeout(() => {
      // The code returns tooltip to initial state after 3 seconds
      setTooltip(messages.diversityTrainingLinkTooltip);
    }, 3000);
    const link = 'https://gupy.academy/p/certificacao-clientes-diversidade-gupy';
    try {
      await navigator.clipboard.writeText(link);
    } catch (e) {
      // The code above only works on HTTPS
      // The gracefully degradation for local HTTP is printing the URL in console
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const redirectToDiversityTraining = () => {
    window.open(
      'https://gupy.academy/p/certificacao-clientes-diversidade-gupy',
      '_blank',
      'noopener',
      'noreferrer',
    );
  };

  return (
    <div className="diversity-profile">
      <div>
        <div className="diversity-profile__section">
          <H variant="4">{messages.title}</H>
          <Infotip alignment="top-center" id="diversity-info-tip" message={messages.tip}>
            <Icon icon={IconEnum.Error} fontSize="small" className="diversity-profile__icon"/>
          </Infotip>
        </div>
      </div>
      <Divisor />
      <Tip
        id="tip-hint"
        type="hint"
        showIcon
        text={(
          <FormattedHTMLMessage
            id="diversity-hint"
            defaultMessage={'<strong>Importante:</strong> todas as pessoas com esse perfil terão acesso aos recursos de Diversidade que forem selecionados. <a target="_blank" rel="noopener noreferrer" href=https://support-companies.gupy.io/hc/pt-br/articles/360007557353-O-que-%C3%A9-um-perfil-de-acesso><u>Saiba mais sobre permissionamento</u>.</a>'}
          />)}
      />
      <div>
        <div className="diversity-profile__title">
          <H variant="4">{messages.diversityTrainingTitle}</H>
        </div>
        <div className="diversity-profile__description">
          {messages.diversityTrainingDescription}
        </div>
        <div className="diversity-profile__section" >
          <Button type="outlined" size="md" onClick={redirectToDiversityTraining}>
            {messages.diversityTrainingButton}
          </Button>
          <div className="diversity-profile__icon">
            <div
              data-tip
              className="share-button root"
            >
              <IconButton
                icon={IconEnum.FileCopyOutlined}
                id="some-id-6"
                label="Some label"
                onClick={copyToClipboard}
                variant="outlined"
                size="md"
              />
            </div>

            <ReactTooltip effect="solid">
              <div className="share-button__tooltip">{tooltip}</div>
            </ReactTooltip>
          </div>
        </div>
      </div>
      <div className="user-access-profile__permission-nodes user-access-profile__permission-nodes--diversity">
        {diversityPanel}
      </div>
    </div>
  );
};

DiversityProfile.propTypes = propTypes;
export default DiversityProfile;
