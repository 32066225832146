import React from 'react';
import { Link } from 'react-router-dom';

const HeaderSimple = () => (
  <div className="navbar-responsive">
    <div className="navbar-responsive__brand">
      <Link to="/companies">
        <img src={`${process.env.REACT_APP_ASSETS_URL}/logo-new.svg`} alt="Gupy" />
      </Link>
    </div>
  </div>
);

export default HeaderSimple;
