import { BaseForm } from '@gupy/front-commons';

class SubsidiaryForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
          maxLength: 255,
        },
      },
      addressLine: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default SubsidiaryForm;
