import { useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import CookieManager from '../../managers/CookieManager';
import { useSubdomain } from './helpers';

export const useIDPAuthentication = ({
  companyId,
  exchangeToken,
  IDPSignout,
  isSignOut,
}) => {
  const { initialized, keycloak } = useKeycloak();
  const subdomain = useSubdomain();

  const url = new URL(window.location);
  const cookieManager = new CookieManager();

  const redirectUri = url.pathname.includes('/companies/signin') && !cookieManager.getRedirectUrl()
    ? `${url.origin}/companies`
    : url.href;

  const exchangeTokenError = cookieManager.getExchangeTokenError();

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      if (!isSignOut) {
        IDPSignout({ subdomain });
      }
      if (isSignOut) {
        const exchangeErrorParam = exchangeTokenError ? `&exchange_token_error=${exchangeTokenError}` : '';
        const companyIdParam = `&gupyCompanyId=${companyId}`;
        cookieManager.clearExchangeTokenError();
        window.location.replace(`${process.env.REACT_APP_KEYCLOAK_DISPATCH_URL}?redirect_uri=${redirectUri}${exchangeErrorParam}${companyIdParam}`);
      }
    } if (initialized && keycloak.authenticated) {
      exchangeToken(keycloak, subdomain);
    }
  }, [
    companyId,
    initialized,
    isSignOut,
    keycloak,
    keycloak.authenticated,
  ]);

  return {
    redirectUri,
  };
};
