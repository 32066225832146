import { combineReducers } from 'redux';
import * as types from './ActionTypes';

export const schema = (state = null, action) => {
  switch (action.type) {
  case types.GET_SUCCESS:
    return action.jobCustomFormTemplate.schema;
  case types.SAVE_COMPANY_SCHEMA_MEMORY:
    return action.schema;
  default:
    return state;
  }
};

export const items = (state = [], action) => {
  switch (action.type) {
  case types.SAVE_COMPANY_ITEMS:
    return action.items;
  default:
    return state;
  }
};

export const schemaReset = (state = false, action) => {
  switch (action.type) {
  case types.GET_SUCCESS:
  case types.GET_FAIL:
    return true;
  case types.SAVE_COMPANY_SCHEMA_MEMORY:
    return false;
  default:
    return state;
  }
};

export const isLoading = (state = true, action) => {
  switch (action.type) {
  case types.GET_SUCCESS:
  case types.GET_FAIL:
    return false;
  case types.GET:
    return true;
  default:
    return state;
  }
};

export const isModalOpen = (state = false, action) => {
  switch (action.type) {
  case types.OPEN_MODAL:
    return true;
  case types.CLOSE_MODAL:
  case types.SAVE_COMPANY_SCHEMA_SUCCESS:
  case types.SAVE_COMPANY_SCHEMA_MEMORY:
    return false;
  default:
    return state;
  }
};

export const selectedField = (state = null, action) => {
  switch (action.type) {
  case types.OPEN_MODAL:
    return action.field;
  case types.CLOSE_MODAL:
    return null;
  default:
    return state;
  }
};

const formErrors = (state = {}, action) => {
  switch (action.type) {
  case types.SAVE_COMPANY_SCHEMA_INVALID:
    return action.validation;
  case types.SAVE_COMPANY_SCHEMA:
  case types.SAVE_COMPANY_SCHEMA_MEMORY:
  case types.CLOSE_MODAL:
    return {};
  default:
    return state;
  }
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
  case types.SAVE_COMPANY_SCHEMA_SUCCESS:
  case types.SAVE_COMPANY_SCHEMA_FAIL:
    return false;
  case types.SAVE_COMPANY_SCHEMA:
    return true;
  default:
    return state;
  }
};

export default combineReducers({
  schema,
  schemaReset,
  items,
  isModalOpen,
  formErrors,
  isLoading,
  selectedField,
  isSaving,
});
