import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Chip, CardWithTitle, MenuButton } from '@gupy/design-system';
import './JobTemplatesCard.scss';

const propTypes = {
  jobTemplate: PropTypes.object.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onDuplicateClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  canDelete: PropTypes.bool.isRequired,
  messages: PropTypes.object.isRequired,
};

const JobTemplatesCard = ({
  jobTemplate,
  onDeleteClick,
  onDuplicateClick,
  onEditClick,
  canEdit,
  canDelete,
  messages,
}) => {
  const cardClassNames = classNames(
    'col-xs-12',
    'col-sm-6',
    'col-md-4',
    'col-lg-4',
    'job-template-cards',
  );
  const actionOptions = [{
    action: () => { onDuplicateClick(jobTemplate.id); },
    title: messages.duplicate,
  }];

  const onEditClickProp = {};
  if (canEdit) {
    onEditClickProp.onClick = () => {
      onEditClick(jobTemplate.id);
    };

    actionOptions.push({
      action: () => { onEditClick(jobTemplate.id); },
      title: messages.edit,
    });
  }

  if (canDelete) {
    actionOptions.push({
      action: () => { onDeleteClick(jobTemplate.id); },
      title: messages.delete,
    });
  }

  const body = (jobTemplate.jobType || jobTemplate.addressCity) ? (
    <div className="job-template-cards__body">
      {
        jobTemplate.jobType ? (
          <Chip color="blue">
            {messages[jobTemplate.jobType]}
          </Chip>
        ) : null
      }
      {
        jobTemplate.addressCity ? (
          <Chip color="blue">
            {jobTemplate.addressCity}
          </Chip>
        ) : null
      }
    </div>
  ) : null;

  return (
    <div className={cardClassNames}>
      <CardWithTitle
        title={(
          <div className="job-template-cards__title" {...onEditClickProp} tabIndex="0" role="button">
            {jobTemplate.name}
          </div>
        )}
        actionButton={(
          <div className="job-template-cards__title">
            <MenuButton
              id={`${jobTemplate.id}-job-template-actions`}
              options={actionOptions}
            />
          </div>
        )}
        body={body}
      />
    </div>
  );
};

JobTemplatesCard.propTypes = propTypes;

export default JobTemplatesCard;
