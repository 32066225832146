import { buildToast, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest, call, all } from 'redux-saga/effects';

import history from '../../history';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';
import JobActionTypes from '../../constants/Job/JobActionTypes';
import {
  getAllJobsSuccess,
  getJobSuccess,
  getJobDetailsSuccess,
  getCompanyCareerPagesSuccess,
} from '../../actions/Job/JobAction';
import JobService from '../../services/Job/JobService';
import RoleService from '../../services/Role/RoleService';
import DepartmentService from '../../services/Department/DepartmentService';
import JobForm from '../../forms/Job/JobDetailsForm';
import JobTemplateForm from '../../forms/Job/JobTemplateForm';

export function* initJobDetails(action) {
  const { jobId } = action;

  try {
    const [
      jobTypesResponse,
      rolesResponse,
      departmentsResponse,
    ] = yield all([
      JobService.getJobTypes(),
      RoleService.getAll(),
      DepartmentService.getAll(),
    ]);

    yield put({
      type: JobDetailsActionTypes.INIT_JOB_DETAILS_SUCCESS,
      jobTypes: jobTypesResponse.body.data,
      roles: rolesResponse.body.data,
      departments: departmentsResponse.body.data,
    });

    if (jobId) {
      const jobDetailsResponse = yield JobService.get(jobId);

      yield put(getJobSuccess(jobDetailsResponse.body));
      yield put(getJobDetailsSuccess(jobDetailsResponse.body));
    }
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobDetailsActionTypes.INIT_JOB_DETAILS_FAIL,
        error,
      });
    }
  }
}

function* getAllJobs(action) {
  try {
    const { summary, adaptedSummary, isAdvancedSearchDisabled } = action;
    const [
      jobResponse,
      filtersResponse,
    ] = yield all([
      JobService.getAll(isAdvancedSearchDisabled ? summary : adaptedSummary),
      JobService.getJobsFilters(),
    ]);

    yield put(getAllJobsSuccess(
      jobResponse.body,
      filtersResponse.body,
    ));
  } catch (error) {
    yield put({
      type: JobActionTypes.GET_ALL_FAIL,
      error,
    });
  }
}

export function* get(action) {
  try {
    const response = yield JobService.get(action.id);
    yield put(getJobSuccess(
      response.body,
    ));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobActionTypes.GET_FAIL,
      });
    }
  }
}

export function* getDetails(action) {
  const form = new JobForm();
  try {
    const response = yield JobService.get(action.id);
    yield put(getJobDetailsSuccess(
      response.body,
    ));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobDetailsActionTypes.GET_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* post(action) {
  const { payload, jobPath } = action;
  const form = new JobForm();

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobDetailsActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.post(payload);

      yield put({
        type: JobDetailsActionTypes.POST_SUCCESS,
        modelForm: response.body,
      });
      yield call(history.push, jobPath.replace(':id', response.body.id));
    } catch (error) {
      yield put({
        type: JobDetailsActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* postWithoutValidation(action) {
  const { payload, jobPath } = action;

  const form = new JobTemplateForm();

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobDetailsActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.post(payload);

      yield put({
        type: JobDetailsActionTypes.POST_NO_VALIDATION_SUCCESS,
        modelForm: response.body,
      });
      yield call(history.push, jobPath.replace(':id', response.body.id));
    } catch (error) {
      yield put({
        type: JobDetailsActionTypes.POST_NO_VALIDATION_FAIL,
      });
    }
  }
}

function* validateAndpatch(action) {
  const { payload, jobPath } = action;
  const form = new JobForm();

  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobDetailsActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.patch(payload);

      yield put({
        type: JobDetailsActionTypes.PATCH_SUCCESS,
        modelForm: response.body,
      });
      yield call(history.push, jobPath);
    } catch (error) {
      yield put({
        type: JobDetailsActionTypes.PATCH_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

export function* patchNameValidation(action) {
  const { payload, jobPath } = action;
  const form = new JobTemplateForm();
  form.populate(payload);

  const validation = form.validateModel();
  if (validation) {
    yield put({
      type: JobDetailsActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.patch(payload);

      yield put({
        type: JobDetailsActionTypes.PATCH_NAME_VALIDATION_SUCCESS,
        modelForm: response.body,
      });
      yield call(history.push, jobPath);
    } catch (error) {
      yield put({
        type: JobDetailsActionTypes.PATCH_NAME_VALIDATION_FAIL,
        validation: { alert: error.body },
      });
    }
  }
}

export function* patchWithoutValidation(action) {
  const { payload, jobPath } = action;
  try {
    const response = yield JobService.patch(payload);

    yield put({
      type: JobDetailsActionTypes.PATCH_NO_VALIDATION_SUCCESS,
      modelForm: response.body,
    });
    yield call(history.push, jobPath);
  } catch (error) {
    yield put({
      type: JobDetailsActionTypes.PATCH_NO_VALIDATION_FAIL,
      validation: { alert: error.body },
    });
  }
}

function* deleteJob(action) {
  const { jobId, summary, jobPath, messages } = action;

  try {
    const response = yield JobService.deleteJob(jobId);

    yield put({
      type: JobActionTypes.DELETE_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });

    if (summary) {
      yield getAllJobs({ summary });
    }

    if (jobPath) {
      yield call(history.push, jobPath);
    }
  } catch (error) {
    yield put({
      type: JobActionTypes.DELETE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* duplicateJob(action) {
  const { jobId, jobPath, messages } = action;

  try {
    const response = yield JobService.duplicateJob(jobId);
    yield put({
      type: JobActionTypes.DUPLICATE_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });
    yield call(history.push, jobPath.replace(':id', response.body.id));
  } catch (error) {
    yield put({
      type: JobActionTypes.DUPLICATE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* duplicateJobAsTemplate(action) {
  const { jobId, messages } = action;
  const fromTemplate = false;
  const asTemplate = true;

  try {
    const response = yield JobService.duplicateJob(jobId, fromTemplate, asTemplate);

    yield put({
      type: JobActionTypes.DUPLICATE_AS_TEMPLATE_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: JobActionTypes.DUPLICATE_AS_TEMPLATE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* patchJobStatus(action) {
  const { payload, summary, jobPath, messages } = action;

  try {
    yield JobService.patch(payload);

    yield put({
      type: JobActionTypes.PATCH_JOB_STATUS_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });

    if (summary) {
      yield getAllJobs({ summary });
    }

    if (jobPath) {
      yield call(history.push, jobPath);
    }
  } catch (error) {
    yield put({
      type: JobActionTypes.PATCH_JOB_STATUS_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* pinJob(action) {
  const { jobId, summary } = action;

  try {
    yield JobService.pinJob({
      jobId,
    });

    yield put({
      type: JobActionTypes.PIN_SUCCESS,
    });

    yield put({
      type: JobActionTypes.GET_ALL,
      summary,
    });
  } catch (error) {
    yield put({
      type: JobActionTypes.PIN_FAIL,
    });
  }
}

function* unpinJob(action) {
  const { jobId, summary } = action;

  try {
    yield JobService.unpinJob(
      jobId,
    );

    yield put({
      type: JobActionTypes.UNPIN_SUCCESS,
    });

    yield put({
      type: JobActionTypes.GET_ALL,
      summary,
    });
  } catch (error) {
    yield put({
      type: JobActionTypes.UNPIN_FAIL,
    });
  }
}

function* getCompanyCareerPages() {
  try {
    const response = yield JobService.getCompanyCareerPages();

    yield put(getCompanyCareerPagesSuccess(response.body));
  } catch (error) {
    yield put({
      type: JobActionTypes.GET_COMPANY_CAREER_PAGES_FAIL,
    });
  }
}

function* getJobAnalysis(action) {
  try {
    const { payload } = action;
    const response = yield JobService.getJobAnalysis(payload);

    yield put({
      type: JobActionTypes.GET_JOB_ANALYSIS_SUCCESS,
      response,
    });
  } catch (error) {
    yield put({
      type: JobActionTypes.GET_JOB_ANALYSIS_FAIL,
    });
  }
}

function* JobSaga() {
  yield takeLatest(JobActionTypes.PIN, pinJob);
  yield takeLatest(JobActionTypes.UNPIN, unpinJob);
  yield takeLatest(JobDetailsActionTypes.INIT_JOB_DETAILS, initJobDetails);
  yield takeLatest(JobActionTypes.GET_ALL, getAllJobs);
  yield takeLatest(JobActionTypes.CHANGE_SUMMARY, getAllJobs);
  yield takeLatest(JobActionTypes.GET, get);
  yield takeLatest(JobDetailsActionTypes.GET, getDetails);
  yield takeLatest(JobDetailsActionTypes.POST, post);
  yield takeLatest(JobDetailsActionTypes.POST_NO_VALIDATION, postWithoutValidation);
  yield takeLatest(JobDetailsActionTypes.PATCH, validateAndpatch);
  yield takeLatest(JobDetailsActionTypes.PATCH_NO_VALIDATION, patchWithoutValidation);
  yield takeLatest(JobDetailsActionTypes.PATCH_NAME_VALIDATION, patchNameValidation);
  yield takeLatest(JobActionTypes.DELETE, deleteJob);
  yield takeLatest(JobActionTypes.DUPLICATE, duplicateJob);
  yield takeLatest(JobActionTypes.DUPLICATE_AS_TEMPLATE, duplicateJobAsTemplate);
  yield takeLatest(JobActionTypes.PATCH_JOB_STATUS, patchJobStatus);
  yield takeLatest(JobActionTypes.GET_COMPANY_CAREER_PAGES, getCompanyCareerPages);
  yield takeLatest(JobActionTypes.GET_JOB_ANALYSIS, getJobAnalysis);
}

export default JobSaga;
