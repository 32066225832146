import { BaseForm } from '@gupy/front-commons';

class TestimonialForm extends BaseForm {
  static addValidationExtraFields(validation, extraFields) {
    return { ...validation, extraFields };
  }

  static validate(testimonials) {
    const validations = testimonials.map((testimonial) => {
      const testimonialForm = new TestimonialForm();
      testimonialForm.populate(testimonial);
      const validation = testimonialForm.validateModel();
      if (validation) {
        return TestimonialForm.addValidationExtraFields(validation, { listId: testimonial.listId });
      }
      return validation;
    });
    return validations.filter(validation => Boolean(validation));
  }

  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
      testimony: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default TestimonialForm;
