import api from '../../api/DarthVaderClient';

export default {
  get(searchString, page = 0) {
    let search = '';
    if (searchString) {
      search = `&search=${searchString}`;
    }
    return api.get(`/job-management/companies/jobs/templates?order=name${search}&page=${page}&perPage=30`);
  },

  post(payload) {
    return api.post('/jobs', payload);
  },

  delete(templateId) {
    return api.delete(`/jobs/${templateId}`);
  },

  duplicate(templateId) {
    return api.post(`/job-management/companies/jobs/${templateId}/duplicate?fromTemplate=true&asTemplate=true`);
  },
};
