import * as ActionTypes from './ActionTypes';

export const getApiAccessPolicyList = () => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST,
});
export const getApiAccessPolicyListSuccess = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_SUCCESS,
  payload,
});
export const getApiAccessPolicyListFail = error => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_FAIL,
  error,
});
export const getApiPolicyRoutesList = () => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_ROUTES,
});
export const getApiPolicyRoutesListSuccess = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_ROUTES_SUCCESS,
  payload,
});
export const getApiPolicyRoutesListFail = error => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_ROUTES_FAIL,
  error,
});
export const deleteApiAccessPolicy = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_DELETE,
  payload,
});
export const apiAccessPolicyDeleteModal = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_DELETE_MODAL,
  payload,
});
export const deleteApiAccessPolicySuccess = () => ({
  type: ActionTypes.API_ACCESS_POLICY_DELETE_SUCCESS,
});
export const deleteApiAccessPolicyFail = error => ({
  type: ActionTypes.API_ACCESS_POLICY_DELETE_FAIL,
  error,
});
export const apiAccessPolicyUpdateDescription = payload => ({
  type: ActionTypes.API_ACCESS_PERMISSIONS_UPDATE_DESCRIPTION,
  payload,
});

export const apiAccessPolicyListPermission = () => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_PERMISSION,
});
export const apiAccessPolicyListPermissionSuccess = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_PERMISSION_SUCCESS,
  payload,
});
export const apiAccessPolicyListPermissionFail = error => ({
  type: ActionTypes.API_ACCESS_POLICY_LIST_PERMISSION_FAIL,
  error,
});
export const apiAccessPolicyTogglePermissions = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_TOGGLE_PERMISSIONS,
  payload,
});
export const apiAccessPolicyToggleVersions = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_TOGGLE_VERSIONS,
  payload,
});
export const apiAccessPolicyToggleHeaderPermissions = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_TOGGLE_HEADER_PERMISSIONS,
  payload,
});
export const apiAccessPolicySetPolicy = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_SET_POLICY,
  payload,
});
export const apiAccessPolicyToggleCategory = payload => ({
  type: ActionTypes.API_ACCESS_POLICY_TOGGLE_CATEGORY,
  payload,
});
export const apiAccessPermissionsClear = () => ({
  type: ActionTypes.API_ACCESS_PERMISSIONS_CLEAR,
});

export const postApiAccessPolicyPermissions = payload => ({
  type: ActionTypes.POST_API_ACCESS_PERMISSIONS,
  payload,
});
export const postApiAccessPolicyPermissionsSuccess = payload => ({
  type: ActionTypes.POST_API_ACCESS_PERMISSIONS_SUCCESS,
  payload,
});
export const postApiAccessPolicyPermissionsFail = error => ({
  type: ActionTypes.POST_API_ACCESS_PERMISSIONS_FAIL,
  error,
});
