import { BaseForm } from '@gupy/front-commons';

class JobSummaryDisapproveJobForm extends BaseForm {
  constructor() {
    super({
      disapprovalReason: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default JobSummaryDisapproveJobForm;
