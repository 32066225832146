import moment from 'moment';
import NotificationTypes from '../enums/NotificationTypes';

const MAX_VISIBLE_NOTIFICATIONS = 50;

const adaptNotification = notification => ({
  viewed: false,
  ...notification,
  ...(notification.content && { data: JSON.parse(notification.content) }),
  ...(notification.userNotificationId && { id: notification.userNotificationId }),
  ...(notification.viewedAt && { viewed: true }),
  type: notification.type || NotificationTypes.userNotification,
  receivedAt: moment(notification.createdAt).format('HH:mm - DD/MM/YYYY'),
});

const adaptNotifications = notifications => (
  notifications.map(n => (adaptNotification(n)))
);

const sortedByDateTime = notifications => (
  notifications.sort((a, b) => (
    (new Date(a.createdAt).getTime() > new Date(b.createdAt).getTime() ? -1 : 1)
  ))
);

const groupNotificationsByType = notifications => adaptNotifications(notifications)
  .reduce((previousValue, notification) => {
    const previousNotificationTypeWithoutDuplicated = previousValue[notification.type] || []
      .filter(({ id }) => notification.id !== id);

    return {
      ...previousValue,
      [notification.type]: sortedByDateTime(
        [...previousNotificationTypeWithoutDuplicated, notification],
      ),
    };
  }, {
    [NotificationTypes.userNotification]: [],
    [NotificationTypes.whatsNew]: [],
  });

const filterNotificationByIdAndType = (state, data) => Object.keys(state)
  .reduce((previousValue, notificationType) => {
    if (notificationType !== data.type) return previousValue;

    return {
      ...previousValue,
      [notificationType]: state[notificationType]
        .filter(notification => notification.id !== data.id),
    };
  }, state);

const setNotificationAsViewed = (state, data) => Object.keys(state)
  .reduce((previousValue, notificationType) => {
    if (notificationType !== data.type) return previousValue;

    const notificationToUpdate = state[notificationType]
      .find(notification => notification.id === data.id);

    return {
      ...previousValue,
      [notificationType]: [
        ...state[notificationType].filter(notification => notification.id !== data.id),
        {
          ...notificationToUpdate,
          viewed: true,
        },
      ],
    };
  }, state);

const limitNotifications = notifications => (
  notifications ? notifications.slice(0, MAX_VISIBLE_NOTIFICATIONS) : []
);

export {
  sortedByDateTime,
  adaptNotification,
  adaptNotifications,
  limitNotifications,
  groupNotificationsByType,
  filterNotificationByIdAndType,
  setNotificationAsViewed,
};
