import { combineReducers } from 'redux';

import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';

const initialModelForm = {
  id: undefined,
  departmentId: undefined,
  departmentName: undefined,
  roleId: undefined,
  jobType: undefined,
  name: '',
  handicapped: true,
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobDetailsActionTypes.POST:
  case JobDetailsActionTypes.POST_NO_VALIDATION:
  case JobDetailsActionTypes.PATCH:
  case JobDetailsActionTypes.PATCH_NO_VALIDATION:
    return true;
  case JobDetailsActionTypes.PATCH_NO_VALIDATION_SUCCESS:
  case JobDetailsActionTypes.PATCH_NO_VALIDATION_FAIL:
  case JobDetailsActionTypes.POST_NO_VALIDATION_SUCCESS:
  case JobDetailsActionTypes.POST_NO_VALIDATION_FAIL:
  case JobDetailsActionTypes.POST_SUCCESS:
  case JobDetailsActionTypes.POST_FAIL:
  case JobDetailsActionTypes.PATCH_SUCCESS:
  case JobDetailsActionTypes.PATCH_FAIL:
    return false;
  default:
    return state;
  }
};

const jobTypes = (state = [], action) => {
  switch (action.type) {
  case JobDetailsActionTypes.INIT_JOB_DETAILS_SUCCESS:
    return action.jobTypes;
  default:
    return state;
  }
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case JobDetailsActionTypes.GET_SUCCESS:
  case JobDetailsActionTypes.POST_SUCCESS:
  case JobDetailsActionTypes.PATCH_SUCCESS: {
    return {
      id: action.modelForm.id,
      departmentId:
        action.modelForm.departmentId ? action.modelForm.departmentId.toString() : null,
      departmentName: action.modelForm.departmentName,
      roleId:
        action.modelForm.roleId ? action.modelForm.roleId.toString() : null,
      jobType: action.modelForm.jobType,
      name: action.modelForm.name,
      handicapped: action.modelForm.handicapped,
    };
  }
  case JobDetailsActionTypes.CLEAR_FORM:
  case JobDetailsActionTypes.INIT_JOB_DETAILS:
    return initialModelForm;
  case JobDetailsActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  case JobDetailsActionTypes.EDIT:
    return Object.assign(
      {},
      initialModelForm,
      action.modelForm,
    );
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case JobDetailsActionTypes.INIT_JOB_DETAILS:
  case JobDetailsActionTypes.POST:
  case JobDetailsActionTypes.PATCH:
  case JobDetailsActionTypes.EDIT:
  case JobDetailsActionTypes.GET_SUCCESS:
  case JobDetailsActionTypes.CLEAR_FORM:
    return {};
  case JobDetailsActionTypes.POST_FAIL:
  case JobDetailsActionTypes.PATCH_FAIL:
  case JobDetailsActionTypes.GET_ALL_FAIL:
  case JobDetailsActionTypes.PATCH_NAME_VALIDATION_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobDetailsActionTypes.INIT_JOB_DETAILS:
    return true;
  case JobDetailsActionTypes.INIT_JOB_DETAILS_SUCCESS:
  case JobDetailsActionTypes.INIT_JOB_DETAILS_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  jobTypes,
  validation,
  modelForm,
  isSaving,
  isLoading,
});
