import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobOfferTemplateActionTypes = createActionTypes(
  'JobOfferTemplate',
  asyncActionType('POST'),
  asyncActionType('PUT'),
  asyncActionType('DELETE'),
  asyncActionType('GET'),
  asyncActionType('GET_VARIABLES'),
  asyncActionType('GET_ALL'),
  asyncActionType('SEARCH'),
  'CLEAR',
);

const postJobOfferTemplate = (payload, redirectUrl, messages) => ({
  type: JobOfferTemplateActionTypes.POST,
  payload,
  redirectUrl,
  messages,
});

const putJobOfferTemplate = (payload, redirectUrl, messages) => ({
  type: JobOfferTemplateActionTypes.PUT,
  payload,
  redirectUrl,
  messages,
});

const deleteJobOfferTemplate = (id, messages) => ({
  type: JobOfferTemplateActionTypes.DELETE,
  id,
  messages,
});

const getAllJobOfferTemplates = ({ publicOnly } = {}) => ({
  type: JobOfferTemplateActionTypes.GET_ALL,
  publicOnly,
});

const getJobOfferTemplateVariables = () => ({
  type: JobOfferTemplateActionTypes.GET_VARIABLES,
});

const getJobOfferTemplate = id => ({
  type: JobOfferTemplateActionTypes.GET,
  id,
});

const searchJobOfferTemplates = searchString => ({
  type: JobOfferTemplateActionTypes.SEARCH,
  searchString,
});

const clearJobOfferTemplate = () => ({
  type: JobOfferTemplateActionTypes.CLEAR,
});

export {
  JobOfferTemplateActionTypes,
  postJobOfferTemplate,
  putJobOfferTemplate,
  deleteJobOfferTemplate,
  getAllJobOfferTemplates,
  getJobOfferTemplate,
  getJobOfferTemplateVariables,
  searchJobOfferTemplates,
  clearJobOfferTemplate,
};
