import api from '../../../../api/DarthVaderClient';
import {
  candidatesWithoutFeedbackInClosedApplicationsStartDate,
  endDate,
  expiredPublishedJobsStartDate,
} from './helpers';

export default {
  getExpiredPublishedJobs() {
    const DASHCARD_ID = 1203;
    const CARD_ID = 944;

    const filters = JSON.stringify({
      endDate,
      language: 'pt',
      startDate: expiredPublishedJobsStartDate,
      tabId: 'dashboard_job_postings',
      dashcard: DASHCARD_ID,
      card: CARD_ID,
    });

    return api.get(`/analytics/companies/dashboard/home-insights?data=${filters}`);
  },

  getCandidatesWithoutFeedbackInClosedJobs() {
    const DASHCARD_ID = 1370;
    const CARD_ID = 1034;

    const filters = JSON.stringify({
      endDate,
      language: 'pt',
      startDate: candidatesWithoutFeedbackInClosedApplicationsStartDate,
      tabId: 'dashboard_experience',
      dashcard: DASHCARD_ID,
      card: CARD_ID,
    });

    return api.get(`/analytics/companies/dashboard/home-insights?data=${filters}`);
  },
};
