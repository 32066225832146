export const GET_COMPANY_BRANCHES = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES';
export const GET_COMPANY_BRANCHES_SUCCESS = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_SUCCESS';
export const GET_COMPANY_BRANCHES_FAIL = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_FAIL';

export const GET_COMPANY_BRANCHES_CSV_TEMPLATE = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_CSV_TEMPLATE';
export const GET_COMPANY_BRANCHES_CSV_TEMPLATE_SUCCESS = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_CSV_TEMPLATE_SUCCESS';
export const GET_COMPANY_BRANCHES_CSV_TEMPLATE_FAIL = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_CSV_TEMPLATE_FAIL';

export const GET_COMPANY_BRANCHES_CURRENT_CSV = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_CURRENT_CSV';
export const GET_COMPANY_BRANCHES_CURRENT_CSV_SUCCESS = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_CURRENT_CSV_SUCCESS';
export const GET_COMPANY_BRANCHES_CURRENT_CSV_FAIL = 'SetupOrganizationalStructure/GET_COMPANY_BRANCHES_CURRENT_CSV_FAIL';

export const PATCH_COMPANY_BRANCH = 'SetupOrganizationalStructure/PATCH_COMPANY_BRANCH';
export const PATCH_COMPANY_BRANCH_SUCCESS = 'SetupOrganizationalStructure/PATCH_COMPANY_BRANCH_SUCCESS';
export const PATCH_COMPANY_BRANCH_FAIL = 'SetupOrganizationalStructure/PATCH_COMPANY_BRANCH_FAIL';

export const SET_NODE_MODAL_IS_OPEN = 'SetupOrganizationalStructure/SET_NODE_MODAL_IS_OPEN';
export const SET_DELETE_NODE_MODAL_IS_OPEN = 'SetupOrganizationalStructure/SET_DELETE_NODE_MODAL_IS_OPEN';

export const POST_COMPANY_BRANCHES_CSV = 'SetupOrganizationalStructure/POST_COMPANY_BRANCHES_CSV';
export const POST_COMPANY_BRANCHES_CSV_SUCCESS = 'SetupOrganizationalStructure/POST_COMPANY_BRANCHES_CSV_SUCCESS';
export const POST_COMPANY_BRANCHES_CSV_FAIL = 'SetupOrganizationalStructure/POST_COMPANY_BRANCHES_CSV_FAIL';

export const DELETE_COMPANY_BRANCH = 'SetupOrganizationalStructure/DELETE_COMPANY_BRANCH';
export const DELETE_COMPANY_BRANCH_SUCCESS = 'SetupOrganizationalStructure/DELETE_COMPANY_BRANCH_SUCCESS';
export const DELETE_COMPANY_BRANCH_FAIL = 'SetupOrganizationalStructure/DELETE_COMPANY_BRANCH_FAIL';
