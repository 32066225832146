import React from 'react';
import PropTypes from 'prop-types';
import { Icon, IconEnum } from '@gupy/design-system';

import UserNotificationCardContent from './UserNotificationCardContent';
import UserNotificationDeleted from './UserNotificationDeleted';
import { buildViewedCardClassName } from './helpers/buildViewedCardClassName';

const propTypes = {
  notification: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  onRemoveIconClick: PropTypes.func,
};

class UserNotificationCard extends React.Component {
  state = {
    deleted: false,
  };

  timeout;

  handleRemove = () => {
    this.setState({ deleted: true }, () => {
      this.timeout = setTimeout(() => {
        const { id, type } = this.props.notification;

        this.props.onRemoveIconClick(id, type);
      }, 4300);
    });
  };

  undoRemove = () => {
    clearTimeout(this.timeout);
    this.setState({ deleted: false });
  };

  deletedClassname = () => (this.state.deleted ? 'gdp-notifications-card-container--deleted' : '');

  handleCardClick = () => {
    const { onCardClick, notification } = this.props;

    if (onCardClick) {
      onCardClick(notification);
    }
  };

  render() {
    const { notification } = this.props;

    return (
      <div className={`gdp-notifications-card-container ${this.deletedClassname()}`}>
        <UserNotificationCardContent
          data={notification.data}
          createdAt={notification.createdAt}
          viewed={notification.viewed}
          onCardClick={this.handleCardClick}
        />
        {this.state.deleted && (
          <UserNotificationDeleted onUndoRemove={this.undoRemove} />
        )}
        {this.props.onRemoveIconClick ? (
          <div className={buildViewedCardClassName(notification.viewed, 'gdp-notifications-card__remove-icon-container')}>
            <Icon
              icon={IconEnum.Close}
              onClick={this.handleRemove}
              variant="text"
            />
          </div>
        ) : null}
      </div>
    );
  }
}

UserNotificationCard.propTypes = propTypes;

export default UserNotificationCard;
