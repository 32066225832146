import { put, takeLatest, call } from 'redux-saga/effects';

import ScrollHelper from '@gupy/front-helpers/src/helpers/ScrollHelper';
import { JobPictureActionTypes, getJobSuccess } from '../../../actions/Job/JobPictures/JobPicturesAction';
import JobService from '../../../services/Job/JobService';
import history from '../../../history';

import JobPicturesForm from '../../../forms/Job/JobPicturesForm';

function* get(action) {
  try {
    const response = yield JobService.get(action.jobId);

    yield put(getJobSuccess(
      response.body,
    ));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobPictureActionTypes.GET_FAIL,
      });
    }
  }
}

export function* saveMedia(action) {
  const { payload, jobPath } = action;

  const form = new JobPicturesForm();

  form.populate(payload);
  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobPictureActionTypes.SAVE_MEDIA_FAIL,
      validation,
    });
    ScrollHelper.scrollToValidation();
  } else {
    try {
      const response = yield JobService.saveMedia(payload);

      yield put({
        type: JobPictureActionTypes.SAVE_MEDIA_SUCCESS,
        modelForm: response.body,
      });
      yield call(history.push, jobPath);
    } catch (error) {
      yield put({
        type: JobPictureActionTypes.SAVE_MEDIA_FAIL,
        validation: form.validateErrorResponse(error),
      });
      ScrollHelper.scrollToTop();
    }
  }
}

function* JobPictureSaga() {
  yield takeLatest(JobPictureActionTypes.GET, get);
  yield takeLatest(JobPictureActionTypes.SAVE_MEDIA, saveMedia);
}

export default JobPictureSaga;
