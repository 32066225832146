import { BaseForm } from '@gupy/front-commons';

class EmailVerificationFormValidator extends BaseForm {
  constructor() {
    super({
      email: {
        validate: {
          email: true,
          required: true,
        },
      },
    });
  }
}

export default EmailVerificationFormValidator;
