/* eslint-disable react/no-array-index-key */
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import SidebarMenuItem from './SidebarMenuItem';

const propTypes = {
  content: PropTypes.array,
  isSidebarExpanded: PropTypes.bool,
  styles: PropTypes.object,
};

const MenuContent = ({ content, isSidebarExpanded, styles }) => {
  const hasAccessToSubItems = arr =>
    arr.some(subItem => subItem.hasAccess === true);

  return (
    <Fragment>
      {content.map(
        (item, index) =>
          item.hasAccess && (
            <SidebarMenuItem
              key={index}
              isSidebarExpanded={isSidebarExpanded}
              styles={styles}
              itemTitle={item.title}
              icon={item.iconSrc}
              linkUrl={item.linkUrl}
              linkLabel={item.linkLabel}
              expandableItem={
                item.isExpandable && hasAccessToSubItems(item.subItems)
              }
              subContent={item.subItems}
              isSpaLink={item.isSpaLink}
            />
          ),
      )}
    </Fragment>
  );
};

MenuContent.propTypes = propTypes;

export default MenuContent;
