const fileUploadUrl = `${process.env.REACT_APP_API_URL}/companies/tempFile`;

const getTimelineFileUploadUrl = `${process.env.REACT_APP_API_URL}/application-timeline/get-attachments-upload-url`;

const emailAttachmentUploadUrl = `${process.env.REACT_APP_API_URL}/communication/attachments`;

const imageUploadUrl = `${process.env.REACT_APP_COMMUNICATION_SERVICE_API_URL}/api/email-assets`;

export {
  fileUploadUrl,
  getTimelineFileUploadUrl,
  imageUploadUrl,
  emailAttachmentUploadUrl,
};
