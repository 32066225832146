import { CareerPageVisibilityEnum } from '@gupy/enums';
import PropTypes from 'prop-types';

const careerPagePropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
  visibility: PropTypes.oneOf([
    CareerPageVisibilityEnum.public,
    CareerPageVisibilityEnum.internal,
    CareerPageVisibilityEnum.confidential,
  ]).isRequired,
});

export default careerPagePropTypes;
