import {
  CREATE_COMPANY_EMPLOYEE,
  CREATE_COMPANY_EMPLOYEE_FULFILLED,
  CREATE_COMPANY_EMPLOYEE_REJECTED,
  DELETE_COMPANY_EMPLOYEES,
  DELETE_COMPANY_EMPLOYEES_FULFILLED,
  DELETE_COMPANY_EMPLOYEES_REJECTED,
  SET_COMPANY_EMPLOYEE_CHECKED,
  GET_COUNTRIES,
  GET_COUNTRIES_FULFILLED,
  LIST_EMPLOYEES,
  LIST_EMPLOYEES_FULFILLED,
  CREATE_BATCH_COMPANY_EMPLOYEES,
  CREATE_BATCH_COMPANY_EMPLOYEES_FULFILLED,
  COMPLETE_BATCH_INSERTION,
  CLEAN_BATCH_INSERTION_STATE,
  SET_EMPLOYEE_TO_UPDATE,
  UPDATE_EMPLOYEE,
  CLEAN_EDITING_COMPANY_EMPLOYEE,
  UPDATE_EMPLOYEE_FULFILLED,
  UPDATE_EMPLOYEE_REJECTED,
  CREATE_BATCH_COMPANY_EMPLOYEES_REJECTED,
  DELETE_BATCH_COMPANY_EMPLOYEES,
  DELETE_BATCH_COMPANY_EMPLOYEES_FULFILLED,
  CLEAN_BATCH_EXCLUSION_STATE,
  COMPLETE_BATCH_EXCLUSION,
  DELETE_BATCH_COMPANY_EMPLOYEES_REJECTED,
  CLEAN_COMPANY_EMPLOYEE_TOAST_STATE,
} from './ActionTypes';

export const createCompanyEmployee = companyEmployee => ({
  type: CREATE_COMPANY_EMPLOYEE,
  payload: {
    companyEmployee,
  },
});

export const createCompanyEmployeeFulfilled = companyEmployee => ({
  type: CREATE_COMPANY_EMPLOYEE_FULFILLED,
  payload: {
    companyEmployee,
  },
});

export const createCompanyEmployeeRejected = error => ({
  type: CREATE_COMPANY_EMPLOYEE_REJECTED,
  payload: {
    error,
  },
});

export const deleteCompanyEmployees = companyEmployeeIds => ({
  type: DELETE_COMPANY_EMPLOYEES,
  payload: {
    companyEmployeeIds,
  },
});

export const deleteCompanyEmployeesFulfilled = deleted => ({
  type: DELETE_COMPANY_EMPLOYEES_FULFILLED,
  payload: {
    deleted,
  },
});

export const deleteCompanyEmployeesRejected = error => ({
  type: DELETE_COMPANY_EMPLOYEES_REJECTED,
  payload: {
    error,
  },
});

export const setCompanyEmployeeChecked = ({ id, checked }) => ({
  type: SET_COMPANY_EMPLOYEE_CHECKED,
  payload: {
    id,
    checked,
  },
});

export const setSelectAllCompanyEmployeesChecked = ({ checked }) => ({
  type: SET_COMPANY_EMPLOYEE_CHECKED,
  payload: {
    checked,
  },
});

export const getCountries = () => ({
  type: GET_COUNTRIES,
  payload: {},
});

export const getCountriesFulfilled = countries => ({
  type: GET_COUNTRIES_FULFILLED,
  payload: {
    countries,
  },
});

export const listEmployees = ({ offset, search, column, order }) => ({
  type: LIST_EMPLOYEES,
  offset,
  search,
  column,
  order,
});

export const listEmployeesFulfilled = employeesList => ({
  type: LIST_EMPLOYEES_FULFILLED,
  payload: {
    employeesList,
  },
});

export const createBatchCompanyEmployees = data => ({
  type: CREATE_BATCH_COMPANY_EMPLOYEES,
  payload: {
    data,
  },
});

export const createBatchCompanyEmployeesFulfilled = persistedEmployees => ({
  type: CREATE_BATCH_COMPANY_EMPLOYEES_FULFILLED,
  payload: {
    persistedEmployees,
  },
});

export const completeBatchInsertion = () => ({
  type: COMPLETE_BATCH_INSERTION,
});

export const cleanBatchInsertionState = () => ({
  type: CLEAN_BATCH_INSERTION_STATE,
});

export const updateCompanyEmployee = companyEmployee => ({
  type: UPDATE_EMPLOYEE,
  payload: {
    companyEmployee,
  },
});

export const updateCompanyEmployeeFulfilled = () => ({
  type: UPDATE_EMPLOYEE_FULFILLED,
  payload: {},
});

export const updateCompanyEmployeeRejected = error => ({
  type: UPDATE_EMPLOYEE_REJECTED,
  payload: {
    error,
  },
});

export const setCompanyEmployeeToUpdate = companyEmployee => ({
  type: SET_EMPLOYEE_TO_UPDATE,
  payload: {
    companyEmployee,
  },
});

export const cleanEditingCompanyEmployee = () => ({
  type: CLEAN_EDITING_COMPANY_EMPLOYEE,
  payload: {},
});

export const createBatchCompanyEmployeesRejected = error => ({
  type: CREATE_BATCH_COMPANY_EMPLOYEES_REJECTED,
  payload: {
    error,
  },
});

export const deleteBatchCompanyEmployees = data => ({
  type: DELETE_BATCH_COMPANY_EMPLOYEES,
  payload: {
    data,
  },
});

export const deleteBatchCompanyEmployeesFulfilled = deletedEmployeesCount => ({
  type: DELETE_BATCH_COMPANY_EMPLOYEES_FULFILLED,
  payload: {
    deletedEmployeesCount,
  },
});

export const completeBatchExclusion = () => ({
  type: COMPLETE_BATCH_EXCLUSION,
});

export const cleanBatchExclusionState = () => ({
  type: CLEAN_BATCH_EXCLUSION_STATE,
});

export const deleteBatchCompanyEmployeesRejected = error => ({
  type: DELETE_BATCH_COMPANY_EMPLOYEES_REJECTED,
  payload: {
    error,
  },
});

export const cleanCompanyEmployeeToastState = () => ({
  type: CLEAN_COMPANY_EMPLOYEE_TOAST_STATE,
});
