const contextPrefix = 'COMPANY_EMPLOYEE';

export const CREATE_COMPANY_EMPLOYEE = `${contextPrefix}/CREATE`;
export const CREATE_COMPANY_EMPLOYEE_FULFILLED = `${CREATE_COMPANY_EMPLOYEE}/FULFILLED`;
export const CREATE_COMPANY_EMPLOYEE_REJECTED = `${CREATE_COMPANY_EMPLOYEE}/REJECTED`;
export const CLEAN_COMPANY_EMPLOYEE_TOAST_STATE = `${CREATE_COMPANY_EMPLOYEE}/CLEAN_TOAST`;

export const DELETE_COMPANY_EMPLOYEES = `${contextPrefix}/DELETE`;
export const DELETE_COMPANY_EMPLOYEES_FULFILLED = `${DELETE_COMPANY_EMPLOYEES}/FULFILLED`;
export const DELETE_COMPANY_EMPLOYEES_REJECTED = `${DELETE_COMPANY_EMPLOYEES}/REJECTED`;

export const SET_COMPANY_EMPLOYEE = `${contextPrefix}/SET`;
export const SET_COMPANY_EMPLOYEE_CHECKED = `${SET_COMPANY_EMPLOYEE}/CHECKED`;

export const LIST_EMPLOYEES = `${contextPrefix}/LIST_EMPLOYEES`;
export const LIST_EMPLOYEES_FULFILLED = `${LIST_EMPLOYEES}/FULFILLED`;

export const GET_COUNTRIES = `${contextPrefix}/GET_COUNTRIES`;
export const GET_COUNTRIES_FULFILLED = `${GET_COUNTRIES}/FULFILLED`;

export const CREATE_BATCH_COMPANY_EMPLOYEES = `${contextPrefix}/CREATE_BATCH`;
export const CREATE_BATCH_COMPANY_EMPLOYEES_FULFILLED = `${CREATE_BATCH_COMPANY_EMPLOYEES}/FULFILLED`;
export const COMPLETE_BATCH_INSERTION = `${CREATE_BATCH_COMPANY_EMPLOYEES}/COMPLETE`;
export const CLEAN_BATCH_INSERTION_STATE = `${COMPLETE_BATCH_INSERTION}/CLEAN`;
export const CREATE_BATCH_COMPANY_EMPLOYEES_REJECTED = `${CREATE_BATCH_COMPANY_EMPLOYEES}/REJECTED`;

export const UPDATE_EMPLOYEE = `${contextPrefix}/UPDATE_EMPLOYEE`;
export const UPDATE_EMPLOYEE_FULFILLED = `${UPDATE_EMPLOYEE}/FULFILLED`;
export const UPDATE_EMPLOYEE_REJECTED = `${UPDATE_EMPLOYEE}/REJECTED`;

export const SET_EMPLOYEE_TO_UPDATE = `${contextPrefix}/SET_EMPLOYEE_TO_UPDATE`;
export const CLEAN_EDITING_COMPANY_EMPLOYEE = `${contextPrefix}/CLEAN_EDITING_COMPANY_EMPLOYEE`;

export const DELETE_BATCH_COMPANY_EMPLOYEES = `${contextPrefix}/DELETE_BATCH`;
export const DELETE_BATCH_COMPANY_EMPLOYEES_FULFILLED = `${DELETE_BATCH_COMPANY_EMPLOYEES}/FULFILLED`;
export const COMPLETE_BATCH_EXCLUSION = `${DELETE_BATCH_COMPANY_EMPLOYEES}/COMPLETE`;
export const CLEAN_BATCH_EXCLUSION_STATE = `${COMPLETE_BATCH_EXCLUSION}/CLEAN`;
export const DELETE_BATCH_COMPANY_EMPLOYEES_REJECTED = `${DELETE_BATCH_COMPANY_EMPLOYEES}/REJECTED`;
