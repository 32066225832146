import { asyncActionType, createActionTypes, buildToast, ToastTypes } from '@gupy/front-commons';
import { formatDate } from '../../helpers/Formatters/date';
import DateFormats from '../../constants/DateFormats';
import { Times } from '../../constants/Times';

const InterviewScriptActionTypes = createActionTypes('InterviewScript_V2',
  'CLEAN_INTERVIEW_SCRIPT',
  asyncActionType('CREATE'),
  asyncActionType('CREATE_ANSWER'),
  asyncActionType('GET'),
  asyncActionType('GET_PREVIEW'),
  asyncActionType('LIST'),
  asyncActionType('DELETE'),
  asyncActionType('SIMPLE_LIST'),
  asyncActionType('LIST_STEPS'),
  asyncActionType('UPDATE'),
  asyncActionType('UPDATE_ANSWER'));

const normalizeAnswer = answer => ({
  ...answer,
  created_at: formatDate(answer.createdAt, DateFormats.brazilianDefault),
  created_by: answer && answer.user && answer.user.name,
  created_in: formatDate(answer.createdAt, DateFormats.brazilianTimeDefault),
});

const normalizeAnswers = (data, question) => ({
  ...question,
  answers: data && data.answers && data.answers.map(normalizeAnswer),
  id: question.id,
  describe: question.description,
});

const normalizeQuestions = payload => ({
  ...payload,
  data: {
    ...payload.data,
    schema: payload.data.schema.map(s => ({
      ...s,
      enum: s.enum.trim() === '' ? [] : s.enum.split('\n'),
    })),
  },
});

const countQuestions = (schema) => {
  if (!schema) return 0;

  return schema.filter(q => !q.deletedAt).length;
};

const cleanInterviewScript = () => ({
  type: InterviewScriptActionTypes.CLEAN_INTERVIEW_SCRIPT,
});

const createAnswer = payload => ({
  type: InterviewScriptActionTypes.CREATE_ANSWER,
  payload,
});

const createAnswerSuccess = (payload, script) => ({
  type: InterviewScriptActionTypes.CREATE_ANSWER_SUCCESS,
  payload: {
    instructions: script.instructions,
    name: script.name,
    questions: script.questions.map((question) => {
      const data = payload.data.find(answer => answer.questionId === question.id);
      return normalizeAnswers(data, question);
    }),
  },
});

const createInterviewScript = payload => ({
  type: InterviewScriptActionTypes.CREATE,
  payload: normalizeQuestions(payload),
});

const createInterviewScriptSuccess = ({ data, message }) => ({
  type: InterviewScriptActionTypes.CREATE_SUCCESS,
  payload: {
    ...data,
    created_at: formatDate(data.createdAt, DateFormats.brazilianDefault),
    created_by: data && data.user && data.user.name,
    numberOfQuestions: countQuestions(data.schema),
  },
  toast: buildToast(message, ToastTypes.success, {
    autoClose: Times.FIVE_SECONDS,
  }),
});

const getInterviewScript = payload => ({
  type: InterviewScriptActionTypes.GET,
  payload,
});

const getInterviewScriptSuccess = payload => ({
  type: InterviewScriptActionTypes.GET_SUCCESS,
  payload: {
    ...payload,
    schema: payload.schema.map(s => ({
      ...s,
      enum: s.enum ? s.enum.join('\n') : '',
    })),
  },
});

const deleteInterviewScript = payload => ({
  type: InterviewScriptActionTypes.DELETE,
  payload,
});

const deleteInterviewScriptSuccess = (scripts, id) => ({
  type: InterviewScriptActionTypes.DELETE_SUCCESS,
  payload: scripts.filter(script => script.id !== id),
});

const getInterviewScriptPreview = payload => ({
  type: InterviewScriptActionTypes.GET_PREVIEW,
  payload,
});

const getInterviewScriptPreviewSuccess = payload => ({
  type: InterviewScriptActionTypes.GET_PREVIEW_SUCCESS,
  payload: {
    instructions: payload.instructions,
    name: payload.name,
    questions: payload.schema.map((question) => {
      const data = payload.data.find(answer => answer.questionId === question.id);
      return normalizeAnswers(data, question);
    }),
  },
});

const listInterviewScript = () => ({
  type: InterviewScriptActionTypes.LIST,
});

const listInterviewScriptSuccess = payload => ({
  type: InterviewScriptActionTypes.LIST_SUCCESS,
  payload: payload.map(item => ({
    ...item,
    created_at: formatDate(item.createdAt, DateFormats.brazilianDefault),
    created_by: item && item.user && item.user.name,
    numberOfQuestions: countQuestions(item.schema),
  })),
});

const listInterviewScriptSteps = payload => ({
  type: InterviewScriptActionTypes.LIST_STEPS,
  payload,
});

const listSimpleInterviewScript = () => ({
  type: InterviewScriptActionTypes.SIMPLE_LIST,
});

const updateAnswer = payload => ({
  type: InterviewScriptActionTypes.UPDATE_ANSWER,
  payload,
});

const updateAnswerSuccess = (payload, script) => ({
  type: InterviewScriptActionTypes.UPDATE_ANSWER_SUCCESS,
  payload: {
    instructions: script.instructions,
    name: script.name,
    questions: script.questions.map((question) => {
      const data = payload.data.find(answer => answer.questionId === question.id);
      return normalizeAnswers(data, question);
    }),
  },
});

const updateInterviewScript = payload => ({
  type: InterviewScriptActionTypes.UPDATE,
  payload: normalizeQuestions(payload),
});

const updateInterviewScriptSuccess = ({ data, newScripts, message }) => {
  const newScriptIndex = newScripts.findIndex(s => s.id === data.id);
  if (newScriptIndex >= 0) {
    newScripts.splice(newScriptIndex, 1, {
      ...data,
      created_at: formatDate(data.createdAt, DateFormats.brazilianDefault),
      created_by: data && data.user && data.user.name,
      numberOfQuestions: countQuestions(data.schema),
    });
    return {
      type: InterviewScriptActionTypes.UPDATE_SUCCESS,
      payload: newScripts,
      toast: buildToast(message, ToastTypes.success, {
        autoClose: Times.FIVE_SECONDS,
      }),
    };
  }
  return {};
};

export {
  InterviewScriptActionTypes,
  cleanInterviewScript,
  createAnswer,
  createAnswerSuccess,
  createInterviewScript,
  createInterviewScriptSuccess,
  getInterviewScript,
  getInterviewScriptSuccess,
  getInterviewScriptPreview,
  getInterviewScriptPreviewSuccess,
  deleteInterviewScript,
  deleteInterviewScriptSuccess,
  listInterviewScript,
  listInterviewScriptSuccess,
  listInterviewScriptSteps,
  listSimpleInterviewScript,
  updateAnswer,
  updateAnswerSuccess,
  updateInterviewScript,
  updateInterviewScriptSuccess,
};
