import { combineReducers } from 'redux';

import ActionTypes from './TestCorrectionActionTypes';

const tests = (state = [], action) => {
  switch (action.type) {
  case ActionTypes.LIST_SUCCESS:
    return action.model;
  case ActionTypes.LIST:
  case ActionTypes.LIST_FAIL:
    return [];
  default:
    return state;
  }
};

export default combineReducers({
  tests,
});
