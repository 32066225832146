import React from 'react';
import { FormattedMessage } from 'react-intl';

const UserNotificationEmptyCard = () => (
  <div className="gdp-notifications-empty-card__container">
    <div className="gdp-notifications-empty-card__content">
      <div className="gdp-notifications-empty-card__title">
        <FormattedMessage
          id="no_notifications"
          defaultMessage="Sem notificações no momento!"
        />
      </div>
      <div className="gdp-notifications-empty-card__message">
        <FormattedMessage
          id="no_notifications_description"
          defaultMessage="Fique de olho, ações importantes nas suas vagas serão notificadas aqui"
        />
      </div>
    </div>
  </div>
);

export default UserNotificationEmptyCard;
