import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  logoProduct: PropTypes.string,
  linkUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const ListProducts = ({
  linkUrl,
  logoProduct,
  description,
}) => (
  (logoProduct) ? (
    <li>
      <a
        href={linkUrl}
        aria-label={description}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="-produtos"
          src={logoProduct}
          alt={description}
        />
      </a>
    </li>

  )
    : (
      <li>
        <a
          href={linkUrl}
          aria-label={description}
          target="_blank"
          rel="noopener noreferrer"
        >
          {description}
        </a>
      </li>

    )
);

ListProducts.propTypes = propTypes;

export default (ListProducts);
