import { BaseForm, createFieldValidation } from '@gupy/front-commons';

class TagModalForm extends BaseForm {
  createFieldValidation = createFieldValidation;

  validateModel() {
    const errors = {};
    let isValid = true;

    Object.keys(this.fields).forEach((fieldName) => {
      if (fieldName === 'tags') {
        const field = this.fields[fieldName];
        if (!field.value || field.value.length === 0) {
          errors.alert = this.createFieldValidation('Adicione pelo menos uma tag');
          isValid = false;
        }
      }
    });

    if (isValid) {
      return null;
    }

    return errors;
  }
}

export default TagModalForm;
