import { combineReducers } from 'redux';
import { JobRegistrationFormActionTypes } from './JobRegistrationFormActions';
import RegistrationSchemaHelper from '../helpers/RegistrationSchemaHelper';

const initialRegistrationSchemaState = {
  personalInfo: {},
  experience: {},
  additionalInfo: {},
};

const registrationSchema = (state = initialRegistrationSchemaState, action) => {
  switch (action.type) {
  case JobRegistrationFormActionTypes.GET_REGISTRATION_SCHEMA_SUCCESS:
    return action.schema;
  case JobRegistrationFormActionTypes.CHANGE_REGISTRATION_SCHEMA: {
    const { schema, key, value } = action;
    return RegistrationSchemaHelper.updateSchemaState(state, schema, key, value);
  }
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA_SUCCESS:
  case JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA_FAIL:
    return false;
  case JobRegistrationFormActionTypes.SAVE_REGISTRATION_SCHEMA:
    return true;
  default:
    return state;
  }
};

export default combineReducers({
  registrationSchema,
  isSaving,
});
