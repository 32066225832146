import { Card, Divisor, Tip } from '@gupy/design-system';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import LocalstorageConstants from '../../../../constants/LocalstorageConstants';
import LocalstorageManager from '../../../../managers/LocalstorageManager';
import { getMessages } from '../../Messages';

import './RscLinkedinIntegration.scss';

const RscLinkedinIntegration = ({
  intl,
  getChildApps,
  putRscErrorToast,
  token,
  children,
  isCurrentUserTypeRoot,
}) => {
  const messages = getMessages(intl);
  const localStorageManager = new LocalstorageManager(window.localStorage);

  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === LocalstorageConstants.rscLinkedinPopupClosedSuccess) {
      if (newValue === 'true') {
        getChildApps(
          {
            success: messages.rscChildAppCreatedSuccess,
            error: messages.rscChildAppCreatedError,
          },
          token,
        );
      } else if (newValue === 'false') {
        putRscErrorToast(messages.rscChildAppCreatedError);
      }
      localStorageManager.clearRSCIframeCloseFlag();
    }
  };

  useEffect(() => {
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);

  return (
    <div className="job-board-panel-rsc" data-testid="job-board-panel-rsc">
      <Divisor />
      <Card className="job-board-panel-rsc__card" padding="lg">
        <div className="job-board-panel-rsc__card--title">{messages.rscCardTitle}</div>
        { isCurrentUserTypeRoot ? (
          <Tip
            id="tip-info"
            className="job-board-panel-rsc__card--tip"
            type="warning"
            showIcon
            text={(
              <span>
                {messages.rscTipForUserOfTypeRoot}
              </span>
            )}
          />
        ) : (
          <Fragment>
            {children}
          </Fragment>
        ) }
      </Card>
    </div>
  );
};

RscLinkedinIntegration.propTypes = {
  intl: PropTypes.object.isRequired,
  getChildApps: PropTypes.func.isRequired,
  putRscErrorToast: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  children: PropTypes.node,
  isCurrentUserTypeRoot: PropTypes.bool.isRequired,
};

export default injectIntl(RscLinkedinIntegration);
