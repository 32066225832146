import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { Redirect } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import { EmailTemplateTypeEnum } from '@gupy/enums';
import { InputGroup, FooterButtons, SwitchButton } from '@gupy/design-system';

import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import { debounce, Dropdown, FileUploadStatus, HotjarHelper } from '@gupy/front-commons';

import { getMessages } from './Messages';
import {
  postEmailTemplate,
  putEmailTemplate,
  getEmailTemplate,
  getEmailTemplateVariables,
  getEmailTemplateTypes,
  clearEmailTemplate,
} from './Actions';
import { getFooterButtons } from './helpers/getFooterButtons';
import EmailTemplateBodyBuilder from './components/EmailTemplateBodyBuilder';
import Grid from '../../components/Grid/Grid';
import SetupGridSidebarMenu from '../../components/Grid/SetupGridSidebarMenu';
import GridContent from '../../components/Grid/GridContent';
import FormAlert from '../../components/Form/FormAlert';
import EmailTemplateSubjectBuilder from '../../containers/EmailTemplate/components/EmailTemplateSubjectBuilder';
import { EMAIL_TEMPLATE } from '../../components/SendEmailModal/constants';

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  EmailTemplate: PropTypes.object.isRequired,
  postEmailTemplate: PropTypes.func.isRequired,
  putEmailTemplate: PropTypes.func.isRequired,
  getEmailTemplate: PropTypes.func.isRequired,
  getEmailTemplateTypes: PropTypes.func.isRequired,
  getEmailTemplateVariables: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  match: PropTypes.object.isRequired,
  clearEmailTemplate: PropTypes.func.isRequired,
};

const EmailTemplateForm = ({
  Authentication,
  EmailTemplate,
  getEmailTemplate: getEmailTemplateAction,
  getEmailTemplateTypes: getEmailTemplateTypesAction,
  getEmailTemplateVariables: getEmailTemplateVariablesAction,
  intl,
  match,
  permissions,
  postEmailTemplate: postEmailTemplateAction,
  putEmailTemplate: putEmailTemplateAction,
  clearEmailTemplate: clearEmailTemplateAction,
}) => {
  const [redirect, setRedirect] = useState(null);
  const [allowReply, setAllowReply] = useState(EmailTemplate.modelForm.allowReply);
  const [isPublic, setIsPublic] = useState(EmailTemplate.modelForm.public);
  const [isAbleToChangeToPrivate] = useState(permissions.edit_email_template);
  const [form, setForm] = useState({
    body: '',
    createdAt: '',
    id: undefined,
    name: '',
    subject: '',
    type: '',
    userId: null,
    fileName: '',
    files: [],
  });
  const [uploadStatus, setUploadStatus] = useState('');
  const [eventSent, setEventSent] = useState(false);

  const messages = getMessages(intl);

  const getEmailData = () => {
    const emailData = localStorage.getItem(EMAIL_TEMPLATE);

    if (emailData) {
      const { emailBody, emailSubject, emailAttachments = [] } = JSON.parse(emailData);

      setForm({
        ...form,
        body: emailBody,
        subject: emailSubject,
        files: emailAttachments,
      });

      localStorage.removeItem(EMAIL_TEMPLATE);
    }
  };

  const filterCrmMarkers = (markers) => {
    const unavailableMarkers = ['[jobName]', '[jobLink]', '[careerPageName]', '[applicationLink]'];
    return markers.filter(marker => !unavailableMarkers.includes(marker.value));
  };

  const filterDiscontinuedJobPosting = (markers) => {
    const unavailableMarkers = ['[engagementSurveyLink]'];
    return markers.filter(marker => !unavailableMarkers.includes(marker.value));
  };

  const filterAdmissionOnlyMarkers = (markers) => {
    const availableMarkers = ['[candidateFirstName]', '[candidateFullName]', '[companyName]', '[userName]'];
    const filteredMarkers = markers.filter(marker => availableMarkers.includes(marker.value));
    return filteredMarkers.map((marker) => {
      const modifiedMarker = marker;
      if (marker.value === '[candidateFirstName]') {
        modifiedMarker.label = '[Primeiro nome do pré-colaborador]';
      } else if (marker.value === '[candidateFullName]') {
        modifiedMarker.label = '[Nome completo do pré-colaborador]';
      }
      return modifiedMarker;
    });
  };

  const filterMarkers = (type, markers, isAdmissionOnly) => {
    if (isAdmissionOnly) {
      return filterAdmissionOnlyMarkers(markers);
    }
    if (type === EmailTemplateTypeEnum.crm) {
      return filterCrmMarkers(markers);
    }
    if (type === EmailTemplateTypeEnum.discontinuedJobPosting) {
      return filterDiscontinuedJobPosting(markers);
    }
    return markers;
  };

  useEffect(() => {
    const { modelForm } = EmailTemplate;
    setForm({
      id: modelForm.id,
      name: modelForm.name,
      subject: modelForm.subject,
      body: modelForm.body,
      createdAt: modelForm.createdAt,
      type: modelForm.type,
      userId: modelForm.userId,
      fileName: modelForm.fileName,
      files: modelForm.files,
    });
    setAllowReply(modelForm.allowReply);
    setIsPublic(!isAbleToChangeToPrivate || modelForm.public);
  }, [EmailTemplate.modelForm]);

  useEffect(() => {
    const { id } = match.params;

    if (id) {
      getEmailTemplateAction(id);
    }

    const user = Authentication.currentUser;
    const { featureFlags = [] } = user;
    const isAdmissionOnly = featureFlags.includes('admission_only');

    if (!isAdmissionOnly) {
      getEmailTemplateTypesAction();
    }
    getEmailTemplateVariablesAction();
    getEmailData();

    return () => {
      clearEmailTemplateAction();
    };
  }, []);

  const handleFieldChange = ({ target }) => debounce(
    setForm({
      ...form,
      [target.name]: target.value,
    }), 1000,
  );

  const isUploadingFile = uploadStatus === FileUploadStatus.start;
  const handleFileUploadStatusChange = status => setUploadStatus(status);

  const handleCancelClick = () => setRedirect('/companies/setup/email-template');

  const handleFormSubmit = () => (form.id ? putEmailTemplateAction(
    { ...form, allowReply, public: isPublic },
    '/companies/setup/email-template',
    { success: messages.success },
  ) : postEmailTemplateAction(
    { ...form, allowReply, public: isPublic },
    '/companies/setup/email-template',
    { success: messages.success },
  ));

  useEffect(() => {
    if (isUploadingFile && !eventSent && window.hj) {
      HotjarHelper.triggerPool('attachment_file');
      GoogleAnalyticsHelper.sendEvent({
        category: 'ATTACHMENT_TEMPLATE_EMAIL',
        action: 'attachment_file',
      });
      setEventSent(true);
    }
  }, [isUploadingFile]);

  const {
    validation,
    isSaving,
    types,
    variables,
  } = EmailTemplate;

  const user = Authentication.currentUser;
  const { featureFlags = [] } = user;
  const isAdmissionOnly = featureFlags.includes('admission_only');

  if (isAdmissionOnly) {
    form.type = 'admission';
  }

  const isOwner = user.id === form.userId;
  const isNewItem = form.id === undefined;
  const shouldDisplayTurnTemplateVisible = (isOwner || isNewItem)
    && permissions.create_public_email_template;
  const canSave = !!permissions.edit_public_email_template
    || (permissions.edit_email_template && !EmailTemplate.modelForm.public)
    || isNewItem;

  const typesData = types.map(type => ({
    label: type.label,
    value: type.value,
  }));

  const buttons = getFooterButtons({
    messages,
    canSave,
    isSaving,
    isUploadingFile,
    onCancelClick: handleCancelClick,
    onSaveClick: handleFormSubmit,
  });

  const allowReplyCheckedLabel = allowReply
    ? messages.allowReply : messages.disallowReply;

  const turnTemplateVisibleCheckedLabel = isPublic
    ? messages.allowReply : messages.disallowReply;

  if (redirect) return <Redirect push to={redirect} />;

  return (
    <Grid>
      <SetupGridSidebarMenu permissions={permissions} />
      <GridContent
        title={messages.emailTemplatesPageGridContentTitle}
      >
        <div className="email_template">
          {validation && validation.alert && (
            <FormAlert
              validation={validation.alert}
            />
          )}
          <div className="row">
            <div className={`col-xs-12 ${!isAdmissionOnly ? 'col-sm-8' : ''} email-template__input-container`}>
              <InputGroup
                label={messages.emailTemplateName}
                id="name"
                value={form.name}
                validation={validation.name}
                disabled={isSaving}
                onChange={handleFieldChange}
                required
                block
              />
            </div>
            {!isAdmissionOnly && (
              <div className="col-xs-12 col-sm-4">
                <Dropdown
                  id="type"
                  name="type"
                  required
                  label={messages.emailType}
                  noResultsText={messages.noResultsFound}
                  data={typesData}
                  defaultValue={form.type}
                  value={form.type}
                  disabled={isSaving}
                  validation={validation.type}
                  onChange={handleFieldChange}
                  searchable
                />
              </div>
            )}
          </div>
          <hr />
          <div className="row">
            <div className="col-xs-12 email-template-subject__input-container">
              <EmailTemplateSubjectBuilder
                label={messages.emailTemplateSubject}
                id="subject"
                required
                name="subject"
                value={form.subject}
                variables={filterMarkers(form.type, variables, isAdmissionOnly)}
                validation={validation.subject}
                disabled={isSaving}
                onChange={handleFieldChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12">
              {
                form.type !== 'crm' ? (
                  <EmailTemplateBodyBuilder
                    id="body-builder"
                    name="body"
                    validation={validation.body}
                    body={form.body}
                    markups={filterMarkers(form.type, variables, isAdmissionOnly)}
                    disabled={isSaving}
                    onChange={handleFieldChange}
                    required
                    currentAttachedFile={form.fileName}
                    attachedFiles={form.files}
                    onFileUploadStatusChange={handleFileUploadStatusChange}
                  />
                ) : (
                  <EmailTemplateBodyBuilder
                    id="body-builder"
                    name="body"
                    validation={validation.body}
                    body={form.body}
                    markups={filterMarkers(form.type, variables, isAdmissionOnly)}
                    disabled={isSaving}
                    onChange={handleFieldChange}
                    required
                    isTemplateEditMode
                  />
                )
              }
            </div>
          </div>

          <div className="row">
            <div className="col-xs-12 col-sm-8">
              <div className="col-xs-12 align-center email-template-allow-reply">
                <span className="email-template-allow-reply__label">{isAdmissionOnly ? messages.allowReplyAdmissionOnlyLabel : messages.allowReplyLabel}</span>
                <SwitchButton
                  label={allowReplyCheckedLabel}
                  checked={allowReply}
                  disabled={isSaving}
                  color="primary"
                  id="email-template-form-allow-reply"
                  name="allowReply"
                  onChange={value => setAllowReply(value)}
                />
              </div>

              {shouldDisplayTurnTemplateVisible && (
                <div className="col-xs-12 align-center email-template-turn-template-visible">
                  <span className="email-template-turn-template-visible__label">{messages.turnTemplateVisibleToggle}</span>
                  <SwitchButton
                    label={turnTemplateVisibleCheckedLabel}
                    checked={isPublic}
                    disabled={isSaving || !isAbleToChangeToPrivate}
                    id="public"
                    name="public"
                    onChange={value => setIsPublic(value)}
                    errorMessage={validation.public && validation.public.message}
                  />
                </div>
              )}
            </div>

            <div className="col-xs-12 col-sm-4 align-center email-template-footer">
              <div className="align-right actions">
                <FooterButtons
                  buttons={buttons}
                  checked={false}
                  color="primary"
                  name="emailTemplateAllowReply"
                />
              </div>
            </div>

          </div>
        </div>
      </GridContent>
    </Grid>
  );
};

EmailTemplateForm.propTypes = propTypes;

const mapStateToProps = state => ({
  EmailTemplate: state.reducers.EmailTemplate,
  Authentication: state.reducers.Authentication,
});

export default compose(
  injectIntl,
  connect(
    mapStateToProps,
    {
      clearEmailTemplate,
      getEmailTemplate,
      getEmailTemplateTypes,
      getEmailTemplateVariables,
      postEmailTemplate,
      putEmailTemplate,
    },
  ),
)(EmailTemplateForm);
