import { combineReducers } from 'redux';
import {
  CREATE_COMPANY_EMPLOYEE,
  CREATE_COMPANY_EMPLOYEE_FULFILLED,
  CREATE_COMPANY_EMPLOYEE_REJECTED,
  DELETE_COMPANY_EMPLOYEES,
  DELETE_COMPANY_EMPLOYEES_FULFILLED,
  SET_COMPANY_EMPLOYEE_CHECKED,
  GET_COUNTRIES,
  GET_COUNTRIES_FULFILLED,
  LIST_EMPLOYEES_FULFILLED,
  CREATE_BATCH_COMPANY_EMPLOYEES_FULFILLED,
  CREATE_BATCH_COMPANY_EMPLOYEES,
  CLEAN_BATCH_INSERTION_STATE,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FULFILLED,
  SET_EMPLOYEE_TO_UPDATE,
  CLEAN_EDITING_COMPANY_EMPLOYEE,
  UPDATE_EMPLOYEE_REJECTED,
  CREATE_BATCH_COMPANY_EMPLOYEES_REJECTED,
  DELETE_BATCH_COMPANY_EMPLOYEES,
  DELETE_BATCH_COMPANY_EMPLOYEES_FULFILLED,
  CLEAN_BATCH_EXCLUSION_STATE,
  DELETE_BATCH_COMPANY_EMPLOYEES_REJECTED,
  CLEAN_COMPANY_EMPLOYEE_TOAST_STATE,
} from './ActionTypes';

import { TOAST_TYPES } from './constants';

const createErrors = (action) => {
  const regex = /already exists/g;
  const [error] = action.payload.error.body.errors;
  if (regex.test(error.message)) {
    return {
      identificationDocument: 'employee already exists',
    };
  }
  return {};
};

const companyEmployees = (state = {
  data: { results: [], totalPages: 0, totalResults: 0 },
  error: null,
  isLoading: true,
  isUpdating: false,
  editingCompanyEmployeeId: null,
  toastType: false,
}, action) => {
  switch (action.type) {
  case CREATE_COMPANY_EMPLOYEE_FULFILLED:
    return {
      ...state,
      error: null,
      isLoading: false,
      toastType: TOAST_TYPES.CREATE,
    };
  case CREATE_BATCH_COMPANY_EMPLOYEES:
    return {
      ...state,
      isLoading: true,
    };
  case UPDATE_EMPLOYEE:
    return {
      ...state,
      error: null,
      isUpdating: true,
    };
  case UPDATE_EMPLOYEE_FULFILLED:
    return {
      ...state,
      error: null,
      isUpdating: false,
      editingCompanyEmployeeId: null,
      toastType: TOAST_TYPES.UPDATE,
    };
  case UPDATE_EMPLOYEE_REJECTED:
    return {
      ...state,
      error: createErrors(action),
      isUpdating: false,
    };
  case LIST_EMPLOYEES_FULFILLED:
    return {
      ...state,
      error: null,
      isLoading: false,
      data: action.payload.employeesList,
    };
  case SET_COMPANY_EMPLOYEE_CHECKED: {
    const { id, checked } = action.payload;
    const results = [...state.data.results];
    const newResults = results.map((r) => {
      const result = { ...r };
      if (result.id === id || !id) {
        if (checked) { result.checked = true; } else { delete result.checked; }
      }
      return result;
    });
    return {
      ...state,
      data: {
        ...state.data,
        results: newResults,
      },
    };
  }
  case SET_EMPLOYEE_TO_UPDATE:
    return {
      ...state,
      error: null,
      editingCompanyEmployeeId: action.payload.companyEmployee.id,
    };
  case CLEAN_EDITING_COMPANY_EMPLOYEE:
    return {
      ...state,
      editingCompanyEmployeeId: null,
    };
  case CLEAN_COMPANY_EMPLOYEE_TOAST_STATE:
    return {
      ...state,
      toastType: null,
    };
  case DELETE_COMPANY_EMPLOYEES_FULFILLED:
    return {
      ...state,
      toastType: TOAST_TYPES.DELETE,
    };
  default:
    return state;
  }
};

const manualInsertion = (state = {
  errors: null,
  isLoading: false,
  openToast: null,
}, action) => {
  switch (action.type) {
  case CREATE_COMPANY_EMPLOYEE:
    return {
      ...state,
      isLoading: true,
      errors: null,
    };
  case CREATE_COMPANY_EMPLOYEE_FULFILLED:
    return {
      ...state,
      isLoading: false,
      errors: null,
      openToast: true,
    };
  case CREATE_COMPANY_EMPLOYEE_REJECTED:
    return {
      ...state,
      isLoading: false,
      errors: createErrors(action),
    };
  default:
    return state;
  }
};

const manualDeletion = (state = {
  errors: null,
  isLoading: false,
}, action) => {
  switch (action.type) {
  case DELETE_COMPANY_EMPLOYEES:
    return {
      ...state,
      isLoading: true,
    };
  case DELETE_COMPANY_EMPLOYEES_FULFILLED:
    return {
      ...state,
      isLoading: false,
    };
  default:
    return state;
  }
};

const countries = (state = {
  error: null,
  isLoading: false,
  data: [],
}, action) => {
  switch (action.type) {
  case GET_COUNTRIES:
    return {
      ...state,
      isLoading: true,
    };
  case GET_COUNTRIES_FULFILLED:
    return {
      ...state,
      isLoading: false,
      data: action.payload.countries,
    };
  default:
    return state;
  }
};

const batchInsertion = (state = {
  errors: null,
  isLoading: false,
  isCompleted: false,
  data: {},
}, action) => {
  switch (action.type) {
  case CREATE_BATCH_COMPANY_EMPLOYEES:
    return {
      ...state,
      isLoading: true,
      isCompleted: false,
    };
  case CREATE_BATCH_COMPANY_EMPLOYEES_FULFILLED:
    return {
      ...state,
      errors: null,
      isLoading: false,
      data: action.payload.persistedEmployees,
      isCompleted: true,
    };
  case CLEAN_BATCH_INSERTION_STATE:
    return {
      ...state,
      errors: null,
      isCompleted: false,
      isLoading: false,
      data: {},
    };
  case CREATE_BATCH_COMPANY_EMPLOYEES_REJECTED:
    return {
      ...state,
      errors: action.payload.error,
      isLoading: false,
      isCompleted: true,
    };
  default:
    return state;
  }
};

const batchExclusion = (state = {
  errors: null,
  isLoading: false,
  isCompleted: false,
  data: {},
}, action) => {
  switch (action.type) {
  case DELETE_BATCH_COMPANY_EMPLOYEES:
    return {
      ...state,
      isLoading: true,
      isCompleted: false,
    };
  case DELETE_BATCH_COMPANY_EMPLOYEES_FULFILLED:
    return {
      ...state,
      errors: null,
      isLoading: false,
      data: action.payload.deletedEmployeesCount,
      isCompleted: true,
    };
  case CLEAN_BATCH_EXCLUSION_STATE:
    return {
      ...state,
      errors: null,
      isCompleted: false,
      isLoading: false,
      data: {},
    };
  case DELETE_BATCH_COMPANY_EMPLOYEES_REJECTED:
    return {
      ...state,
      errors: action.payload.error,
      isLoading: false,
      isCompleted: true,
    };
  default:
    return state;
  }
};

const Reducer = combineReducers({
  companyEmployees,
  manualInsertion,
  manualDeletion,
  countries,
  batchInsertion,
  batchExclusion,
});

export default Reducer;
