const companiesPlansToKnowMoreLinks = {
  foundation: 'https://info.gupy.io/produto/rs/lm/scale',
  scale: 'https://info.gupy.io/produto/rs/lm/enterprise',
};

function getKnowMoreLinkForPlan(plan) {
  return companiesPlansToKnowMoreLinks[plan] || 'https://gupy.io';
}

export default getKnowMoreLinkForPlan;
