import api from '../../../../../../api/DarthVaderClient';

export default {
  geQuestionForm({ jobId, stepId }) {
    return api.get(`/jobs/${jobId}/steps/${stepId}/forms`);
  },
  postQuestion(payload) {
    return api.post('/custom-fields', payload);
  },
  postQuestionForm({ jobId, stepId }, payload) {
    return api.post(`/jobs/${jobId}/steps/${stepId}/forms`, payload);
  },
};
