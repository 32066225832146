import { put, takeLatest } from 'redux-saga/effects';
import SkillEvaluationService from './SkillEvaluationLegacyService';
import { SkillsEvaluationActionTypes } from './SkillEvaluationLegacyActions';
import JobApplicationActionTypes from '../../../../../constants/Job/JobApplication/JobApplicationActionTypes';

export function* getSkillsEvaluation({ applicationId, jobId }) {
  try {
    const response = yield SkillEvaluationService.get({ applicationId, jobId });
    yield put({
      type: SkillsEvaluationActionTypes.GET_SUCCESS,
      skillsEvaluations: response.body,
    });
  } catch (error) {
    yield put({
      type: SkillsEvaluationActionTypes.GET_FAIL,
      error,
    });
  }
}

export default function* SkillsEvaluationSaga() {
  yield takeLatest(SkillsEvaluationActionTypes.GET, getSkillsEvaluation);
  yield takeLatest(JobApplicationActionTypes.CHANGE_APPLICATION_LIKE_SUCCESS, getSkillsEvaluation);
  yield takeLatest(JobApplicationActionTypes.CHANGE_CRITERIA_RATING_SUCCESS, getSkillsEvaluation);
}
