import { combineReducers } from 'redux';
import { JobOverviewActionTypes } from './JobOverviewAction';

export const templates = (state = [], action) => {
  switch (action.type) {
  case JobOverviewActionTypes.INIT:
    return [];
  case JobOverviewActionTypes.INIT_SUCCESS:
    return action.templates;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobOverviewActionTypes.INIT:
    return true;
  case JobOverviewActionTypes.INIT_SUCCESS:
  case JobOverviewActionTypes.INIT_FAIL:
    return false;
  default:
    return state;
  }
};

const isCreatingJob = (state = false, action) => {
  switch (action.type) {
  case JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE:
    return true;
  case JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE_SUCCESS:
  case JobOverviewActionTypes.CREATE_JOB_FROM_TEMPLATE_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  templates,
  isCreatingJob,
});
