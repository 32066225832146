import { call, put, takeLatest } from 'redux-saga/effects';

import {
  HomeActionTypes,
  getCurrentUserEmailDeliverabilitySuccess,
  getCurrentUserEmailDeliverabilityFail,
} from './HomeAction';
import CommunicationService from '../../services/Communication/CommunicationService';

export function* getCurrentUserEmailDeliverability() {
  try {
    const {
      body: { isEmailDeliverable },
    } = yield call(CommunicationService.isCurrentUserEmailDeliverable);
    yield put(getCurrentUserEmailDeliverabilitySuccess({ isEmailDeliverable }));
  } catch (error) {
    yield put(getCurrentUserEmailDeliverabilityFail());
  }
}

function* HomeSaga() {
  yield takeLatest(HomeActionTypes.GET_CURRENT_USER_EMAIL_DELIVERABILITY,
    getCurrentUserEmailDeliverability);
}

export default HomeSaga;
