import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ConditionalResponsive, ModalFullScreen, MenuGroup } from '@gupy/design-system';
import UserNotificationsButton from './UserNotificationsButton';
import UserNotificationsCards from './UserNotificationsCards';
import './SidebarNotifications.scss';

const propTypes = {
  notifications: PropTypes.array,
  onViewNotification: PropTypes.func.isRequired,
  onRemoveNotification: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  fixedNotifications: PropTypes.array,
  isContainerExpanded: PropTypes.bool,
  classes: PropTypes.string,
};

const defaultProps = {
  notifications: [],
};

class UserNotifications extends Component {
  state = { panelVisible: false };

  constructor(props) {
    super(props);
    this.panel = undefined;
    this.icon = undefined;
  }

  openPanel = () => {
    this.setState({ panelVisible: true });
  };

  closePanel = () => {
    this.setState({ panelVisible: false });
  };

  handleCardClick = (notification) => {
    if (!notification.viewed) this.props.onViewNotification(notification);
    this.closePanel();
  };

  handleRemoveIconClick = (id, type) => {
    this.props.onRemoveNotification(id, type);
  };

  render() {
    const { notifications,
      messages,
      fixedNotifications,
      isContainerExpanded,
      classes = [] } = this.props;
    const { panelVisible } = this.state;
    const numberOfUnreadNotifications = notifications.filter(n => !n.viewed).length;

    return (
      <ConditionalResponsive
        breakpoint={'xs'}
        fullScreenComponent={(
          <MenuGroup
            classes={classes}
            isContainerExpanded={isContainerExpanded}
            width={350}
            avatar={(
              <UserNotificationsButton
                className="gdp-notifications__icon"
                iconClassName="gdp-notifications__icon_element"
                total={numberOfUnreadNotifications}
                label={messages.notificationsButtonLabel}
                tooltipMessage={messages.notificationsButton}
              />
            )}
            menuLabel={messages.notificationsButton}
            openOnlyOnClick
          >
            <UserNotificationsCards
              notifications={notifications}
              onCardClick={this.handleCardClick}
              onRemoveIconClick={this.handleRemoveIconClick}
              fixedNotifications={fixedNotifications}
            />
          </MenuGroup>
        )}
        responsiveComponent={(
          <Fragment>
            <div className="gdp-notifications-icon">
              <UserNotificationsButton
                total={numberOfUnreadNotifications}
                label={messages.notificationsButtonLabel}
                tooltipMessage={messages.notificationsButton}
                onClick={this.openPanel}
              />
            </div>
            <ModalFullScreen
              onClose={this.closePanel}
              open={panelVisible}
              title={(
                <FormattedMessage
                  id="notifications"
                  defaultMessage="Notificações"
                />
              )}
            >
              <UserNotificationsCards
                notifications={notifications}
                onCardClick={this.handleCardClick}
                onRemoveIconClick={this.handleRemoveIconClick}
              />
            </ModalFullScreen>
          </Fragment>
        )}
      />
    );
  }
}

UserNotifications.propTypes = propTypes;
UserNotifications.defaultProps = defaultProps;

export default UserNotifications;
