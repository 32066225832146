import styled, { css } from 'styled-components';
import SidebarMenuItem from './SidebarMenuItem';

const Sidebar = styled.div`
  font-family: ${({ fontFamily }) => fontFamily};
  height: ${({ height }) => height};
  position: ${({ position }) => position};
  left: ${({ left }) => left};
  z-index: ${({ zIndex }) => zIndex};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: ${({ padding }) => padding};
  width: ${({ width }) => width};
  transition: ${({ transition }) => transition};
  overflow-x: ${({ overflowX }) => overflowX};
  white-space: ${({ whiteSpace }) => whiteSpace};
  border-right: ${({ borderRight }) => borderRight};

  @media screen and (max-width: 1024px) {
    ${({ mobileStyles }) =>
      mobileStyles &&
      css`
      position: ${mobileStyles.position};
      width: ${mobileStyles.width};
      height: ${mobileStyles.height};
      display: ${mobileStyles.display};
      z-index: ${mobileStyles.zIndex};
  };`}
  }
`;

const Header = styled.div`
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  flex-direction: ${({ flexDirection }) => flexDirection};
  height: ${({ height }) => height};
`;

const NotificationsButton = styled.img`
  border: 2px solid #1666db;
  padding: 8px;
  border-radius: 50%;
  filter: invert(43%) sepia(65%) saturate(7032%) hue-rotate(208deg)
    brightness(90%) contrast(90%);
`;

const Profile = styled.div`
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  justify-content: ${({ justifyContent }) => justifyContent};
  margin: ${({ margin }) => margin};
  align-items: ${({ alignItems }) => alignItems};
`;

const ProfileInfo = styled.div`
  display: ${({ display }) => display};
  justify-content: ${({ justifyContent }) => justifyContent};
  gap: ${({ gap }) => gap};
  align-items: ${({ alignItems }) => alignItems};
`;

const Avatar = styled.div`
  img {
    height: ${({ height }) => height};
  }
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ color }) => color};
  display: ${({ display }) => display};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  overflow: ${({ overflow }) => overflow};
  position: ${({ position }) => position};
  font-weight: ${({ fontWeight }) => fontWeight};
`;

const Name = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  overflow: ${({ overflow }) => overflow};
  text-overflow: ${({ textOverflow }) => textOverflow};
`;

const Email = styled.p`
  font-weight: ${({ fontWeight }) => fontWeight};
  font-size: ${({ fontSize }) => fontSize};
  margin: ${({ margin }) => margin};
  max-width: ${({ maxWidth }) => maxWidth};
  overflow: ${({ overflow }) => overflow};
  text-overflow: ${({ textOverflow }) => textOverflow};
`;

const LogoutButton = styled(SidebarMenuItem)`
  align-self: flex-end;
`;

export default {
  Sidebar,
  Header,
  Profile,
  Avatar,
  Name,
  Email,
  NotificationsButton,
  LogoutButton,
  ProfileInfo,
};
