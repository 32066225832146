export const selectOffer = (state) => {
  const { jobOffers, selectedOfferId } = state.reducers.JobOffer;
  const selectedOffer = jobOffers.find(offer => offer.id === selectedOfferId);
  return selectedOffer || null;
};

export const selectJob = (state) => {
  const { job } = state.reducers.JobApplication;

  return {
    id: job.id,
    name: job.name,
    code: job.code,
    type: job.jobType,
    publishedAt: job.publishedAt,
    expiresAt: job.expiresAt,
    handicapped: job.handicapped,
    numVacancies: job.numVacancies,
    recruiter: {
      name: job.recruiter ? job.recruiter.name : '',
    },
    manager: {
      name: job.manager ? job.manager.name : '',
    },
    department: {
      name: job.department ? job.department.name : '',
    },
    role: {
      name: job.role ? job.role.name : '',
    },
    careerPage: {
      name: job.careerPage ? job.careerPage.name : '',
    },
    companyBranch: {
      name: job.companyBranches ? job.companyBranches.name : '',
    },
  };
};

export const selectCurrentUser = (state) => {
  const { currentUser } = state.reducers.Authentication;
  if (currentUser && currentUser.company) {
    return {
      name: currentUser.name,
      language: currentUser.language,
      company: currentUser.company,
      permissions: currentUser.permissions,
      userType: currentUser.userType,
      products: currentUser.products,
    };
  }
  return {};
};

export const selectPermissions = (state) => {
  const user = selectCurrentUser(state);
  return user.permissions;
};

export const selectCandidate = (state) => {
  const { application } = state.reducers.JobApplication;
  if (application.candidate && application.candidate.id) {
    return {
      id: application.candidate.id,
      name: application.candidate.name,
      lastName: application.candidate.lastName,
      fullName: application.candidate.fullName,
    };
  }
  return {};
};

export const selectCompany = (state) => {
  const { currentUser } = state.reducers.Authentication;
  if (currentUser && currentUser.company) {
    return {
      name: currentUser.company.name,
    };
  }
  return {};
};

export const selectReceiverOptions = (state) => {
  const job = selectJob(state);
  const company = selectCompany(state);
  const currentUser = selectCurrentUser(state);

  return [
    {
      label: currentUser.name,
      value: currentUser.name,
      type: 'current-user',
    },
    ...((company.name === job.careerPage.name) ? (
      [{
        label: company.name,
        value: company.name,
        type: 'company',
      }]
    ) : (
      [
        {
          label: company.name,
          value: company.name,
          type: 'company',
        },
        {
          label: job.careerPage.name,
          value: job.careerPage.name,
          type: 'career-page',
        },
      ]
    )),
  ];
};
