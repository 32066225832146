import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('JobClosure',
  'OPEN_JOB_CLOSURE_MODAL',
  'CLOSE_JOB_CLOSURE_MODAL',
  'SET_JOB_CLOSURE_MODAL_STEP',
  'DO_NOT_SEND_FEEDBACK_EMAIL',
  asyncActionType('CLOSE_JOB'),
  asyncActionType('GET_FINAL_JOB_STEP_ID'),
  asyncActionType('GET_ALL_TEMPLATE_EMAILS'),
  asyncActionType('SEND_FEEDBACK_EMAILS'),
  asyncActionType('GET_BIND_SURVEY_CAREER_PAGES'));
