import api from '../../api/DarthVaderClient';

const userAccessProfilePath = '/companies/users-access-profile';

export default {
  post(payload) {
    return api.post(userAccessProfilePath, payload);
  },

  patch(id, payload) {
    return api.patch(`${userAccessProfilePath}/${id}`, payload);
  },

  delete(id) {
    return api.delete(`${userAccessProfilePath}/${id}`);
  },

  get(id) {
    return api.get(`${userAccessProfilePath}/${id}`);
  },

  getStructure() {
    return api.get(`${userAccessProfilePath}/new`);
  },

  getTypes() {
    return api.get(`${userAccessProfilePath}/list`);
  },

  getAll(searchString, group) {
    const searchParams = [];

    if (searchString) {
      searchParams.push(`&search=${searchString}`);
    }

    if (group) {
      searchParams.push(`&groupTypes=${group}`);
    }

    return api.get(
      `${userAccessProfilePath}?order=name${searchParams.join('')}&page=0&perPage=9999`,
    );
  },
};
