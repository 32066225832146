import { BaseForm } from '@gupy/front-commons';

class SigninForm extends BaseForm {
  constructor() {
    super({
      email: {
        validate: {
          email: true,
          notEmpty: true,
        },
      },
      password: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default SigninForm;
