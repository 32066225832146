import { combineReducers } from 'redux';

import * as types from './ApprovalWorkflowActionTypes';

const isLoadingApprovalWorkflows = (state = false, action) => {
  switch (action.type) {
  case types.FETCH_APPROVAL_WORKFLOWS_REQUEST:
  case types.FETCH_APPROVAL_WORKFLOW_REQUEST:
    return true;
  case types.FETCH_APPROVAL_WORKFLOWS_SUCCESS:
  case types.FETCH_APPROVAL_WORKFLOWS_FAILURE:
  case types.FETCH_APPROVAL_WORKFLOW_SUCCESS:
  case types.FETCH_APPROVAL_WORKFLOW_FAILURE:
    return false;
  default:
    return state;
  }
};

const isLoadingApprovalWorkflow = (state = false, action) => {
  switch (action.type) {
  case types.FETCH_APPROVAL_WORKFLOW_REQUEST:
    return true;
  case types.FETCH_APPROVAL_WORKFLOW_SUCCESS:
  case types.FETCH_APPROVAL_WORKFLOW_FAILURE:
    return false;
  default:
    return state;
  }
};

const approvalWorkflows = (state = [], action) => {
  switch (action.type) {
  case types.FETCH_APPROVAL_WORKFLOWS_SUCCESS:
    return action.payload;
  case types.FETCH_APPROVAL_WORKFLOWS_REQUEST:
  case types.FETCH_APPROVAL_WORKFLOWS_FAILURE:
    return [];
  case types.DELETE_APPROVAL_WORKFLOW_SUCCESS:
    return state.filter(item => item.id !== action.workflowId);
  default:
    return state;
  }
};

const initialModelForm = {
  name: '',
  approver: '',
  approverIds: [],
  requestMethod: 'all_at_once',
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case types.CHANGE_FORM:
    return {
      ...state,
      ...action.modelForm,
    };
  case types.CLEAR_FORM:
  case types.SAVE_APPROVAL_WORKFLOW_SUCCESS:
    return initialModelForm;
  case types.FETCH_APPROVAL_WORKFLOW_SUCCESS:
    return {
      ...state,
      ...action.payload,
    };
  default:
    return state;
  }
};

const modelFormErrors = (state = {}, action) => {
  switch (action.type) {
  case types.SAVE_APPROVAL_WORKFLOW_FAILURE:
  case types.UPDATE_APPROVAL_WORKFLOW_FAILURE:
    return action.validation;
  case types.CLEAR_FORM:
  case types.SAVE_APPROVAL_WORKFLOW_REQUEST:
  case types.UPDATE_APPROVAL_WORKFLOW_REQUEST:
    return {};
  default:
    return state;
  }
};

const isLoadingApprovers = (state = true, action) => {
  switch (action.type) {
  case types.FETCH_APPROVERS_REQUEST:
    return true;
  case types.FETCH_APPROVERS_SUCCESS:
  case types.FETCH_APPROVERS_FAILURE:
    return false;
  default:
    return state;
  }
};

const approversList = (state = [], action) => {
  switch (action.type) {
  case types.FETCH_APPROVERS_REQUEST:
    return [];
  case types.FETCH_APPROVERS_SUCCESS:
    return action.payload;
  case types.FETCH_APPROVERS_FAILURE:
    return [];
  default:
    return state;
  }
};

const approversListError = (state = false, action) => {
  switch (action.type) {
  case types.FETCH_APPROVERS_FAILURE:
    return true;
  case types.FETCH_APPROVERS_REQUEST:
  case types.FETCH_APPROVERS_SUCCESS:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  isLoadingApprovalWorkflows,
  approvalWorkflows,
  modelForm,
  modelFormErrors,
  isLoadingApprovers,
  approversList,
  approversListError,
  isLoadingApprovalWorkflow,
});
