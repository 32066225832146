import React from 'react';
import PropTypes from 'prop-types';

import Grid from '../../../components/Grid/Grid';
import SetupGridSidebarMenu from '../../../components/Grid/SetupGridSidebarMenu';
import GridContent from '../../../components/Grid/GridContent';

const propTypes = {
  permissions: PropTypes.object.isRequired,
  title: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
  title: '',
};

function SetupGridLayout({ permissions, title, children }) {
  return (
    <Grid>
      <SetupGridSidebarMenu permissions={permissions} />
      <GridContent title={title} >
        {children}
      </GridContent>
    </Grid>
  );
}

SetupGridLayout.propTypes = propTypes;
SetupGridLayout.defaultProps = defaultProps;

export default SetupGridLayout;
