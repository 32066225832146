import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { DialogMaterial, Tip } from '@gupy/design-system';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';

import { getMessages } from './messages';

const propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  job: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    publishedAt: PropTypes.any,
  }).isRequired,
  intl: intlShape,
};

const defaultProps = {
  isOpen: false,
};

const AnalyticsCalloutModal = ({ isOpen, onClose, job, intl }) => {
  const messages = getMessages(intl);

  const handleSeeLater = () => {
    GoogleAnalyticsHelper.sendEvent({ category: 'ANALYTICS_ON_JOB_CLOSE', action: 'SEE_LATER' });
    onClose();
  };

  const handleSeeNow = () => {
    GoogleAnalyticsHelper.sendEvent({ category: 'ANALYTICS_ON_JOB_CLOSE', action: 'SEE_NOW' });
    const route = '/analytics/dashboards';
    const params = new URLSearchParams({
      jobs: JSON.stringify([job.id]),
      startDate: JSON.stringify(job.publishedAt),
    }).toString();
    const hash = 'applications-profile';
    window.open(`${route}?${params}#${hash}`);
    onClose();
  };

  return (
    <DialogMaterial
      id="jobs-dashboard-callout-modal"
      open={isOpen}
      maxWidth="sm"
      title={messages.title}
      footer={{
        buttons: [
          {
            id: 'button-see-later',
            text: messages.seeLater,
            onClick: handleSeeLater,
            type: 'text',
          },
          {
            id: 'button-see-now',
            text: messages.seeNow,
            onClick: handleSeeNow,
          },
        ],
      }}
    >
      <Tip
        type="info"
        text={messages.tip}
      />
      <br />
      <p><strong>{messages.subtitle({ jobName: job.name })}</strong></p>
      <br />
      <p>{messages.body}</p>
    </DialogMaterial>
  );
};

AnalyticsCalloutModal.propTypes = propTypes;
AnalyticsCalloutModal.defaultProps = defaultProps;

const mapStateToProps = ({ reducers }) => ({
  job: reducers.Analytics.analyticsCalloutJob,
});

export default injectIntl(connect(mapStateToProps)(AnalyticsCalloutModal));
