import { IntegrationsActionTypes } from './IntegrationsActionTypes';

const initIntegrations = (renderScreen, shouldDisplayRscLinkedinIntegration, token) => ({
  type: IntegrationsActionTypes.INIT,
  renderScreen,
  shouldDisplayRscLinkedinIntegration,
  token,
});

const initRscLinkedinIntegration = (shouldDisplayRscLinkedinIntegration, token) => ({
  type: IntegrationsActionTypes.INIT_RSC_LINKEDIN_INTEGRATION,
  shouldDisplayRscLinkedinIntegration,
  token,
});

const updateIntegrationsForm = integrations => ({
  type: IntegrationsActionTypes.UPDATE_INTEGRATIONS_FORM,
  integrations,
});

const saveIntegrations = (integrations, messages, renderScreen, jobBoardsCustomIds) => ({
  type: IntegrationsActionTypes.SAVE_INTEGRATIONS,
  integrations,
  messages,
  renderScreen,
  jobBoardsCustomIds,
});

const createRsclinkedinIntegrationChildApp = (messages, token) => ({
  type: IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
  messages,
  token,
});

const clearCreatedRsclinkedinIntegrationChildApp = () => ({
  type: IntegrationsActionTypes.CREATE_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CLEAR,
});

const saveJobBoardsCustomIds = jobBoardsCustomIds => ({
  type: IntegrationsActionTypes.SAVE_JOB_BOARDS_CUSTOM_IDS,
  jobBoardsCustomIds,
});

const getRscChildApps = ({ success, error }, token) => ({
  type: IntegrationsActionTypes.GET_RSC_LINKEDIN_INTEGRATION_CHILD_APPS,
  messages: {
    success,
    error,
  },
  token,
});

const bindRscLinkedinIntegrationChildAppCareerPages = (
  { childAppId, childAppName, childAppCareerPagesIds },
  user,
  { success, error, formValidationErrors },
  token,
) => ({
  type: IntegrationsActionTypes.BIND_RSC_LINKEDIN_INTEGRATION_CHILD_APP_CAREER_PAGES,
  childApp: {
    id: childAppId,
    name: childAppName,
    careerPagesIds: childAppCareerPagesIds,
  },
  user,
  messages: {
    success,
    error,
    formValidationErrors,
  },
  token,
});

const editRscLinkedinIntegrationChildApp = (
  { childAppId, childAppName, childAppCareerPagesIds },
  { success, error, formValidationErrors },
  token,
) => ({
  type: IntegrationsActionTypes.EDIT_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
  childApp: {
    id: childAppId,
    name: childAppName,
    careerPagesIds: childAppCareerPagesIds,
  },
  messages: {
    success,
    error,
    formValidationErrors,
  },
  token,
});

const setRscLinkedinIntegrationChildAppFormValidationError = (
  childApp,
  formValidationErrors,
) => ({
  type: IntegrationsActionTypes.SET_RSC_LINKEDIN_INTEGRATION_CHILD_APP_FORM_VALIDATION_ERROR,
  childApp,
  formValidationErrors,
});

const restartSyncRscLinkedinIntegrationChildApp = (
  { childAppId },
  user,
  { success, error },
  token,
) => ({
  type: IntegrationsActionTypes.RESTART_SYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
  childApp: {
    id: childAppId,
  },
  user,
  messages: {
    success,
    error,
  },
  token,
});

const desyncRscLinkedinIntegrationChildApp = (
  { childAppId },
  user,
  token,
) => ({
  type: IntegrationsActionTypes.DESYNC_RSC_LINKEDIN_INTEGRATION_CHILD_APP,
  childApp: {
    id: childAppId,
  },
  user,
  token,
});

const putRscErrorToast = errorMessage => ({
  type: IntegrationsActionTypes.PUT_RSC_LINKEDIN_INTEGRATION_ERROR_TOAST,
  errorMessage,
});

export {
  initIntegrations,
  initRscLinkedinIntegration,
  IntegrationsActionTypes,
  updateIntegrationsForm,
  saveIntegrations,
  saveJobBoardsCustomIds,
  getRscChildApps,
  bindRscLinkedinIntegrationChildAppCareerPages,
  editRscLinkedinIntegrationChildApp,
  setRscLinkedinIntegrationChildAppFormValidationError,
  restartSyncRscLinkedinIntegrationChildApp,
  desyncRscLinkedinIntegrationChildApp,
  createRsclinkedinIntegrationChildApp,
  clearCreatedRsclinkedinIntegrationChildApp,
  putRscErrorToast,
};
