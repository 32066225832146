import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button } from '@gupy/design-system';

const propTypes = {
  messages: PropTypes.object.isRequired,
  onCreate: PropTypes.func,
  className: PropTypes.string,
  shouldShowIllustration: PropTypes.bool,
};

const defaultProps = {
  className: 'col-sm-12',
  shouldShowIllustration: false,
};

const EmptyState = ({ messages, onCreate, className, shouldShowIllustration }) => (
  <div className={classNames('user-access-profile__empty-state', className)}>
    {shouldShowIllustration && (
      <div className="user-access-profile__illustration">
        <img src={`${process.env.REACT_APP_ASSETS_URL}/user-access-profile/illustration.png`} alt="" />
      </div>
    )}
    <p className="user-access-profile__empty-state-title">
      {messages.noAccessProfileFound}
    </p>
    <span className="user-access-profile__empty-state-subtitle">
      {messages.noAccessProfileFoundDescription}
    </span>
    {onCreate && (
      <Button
        onClick={onCreate}
      >
        {messages.createAccessProfile}
      </Button>
    )}
  </div>
);

EmptyState.propTypes = propTypes;
EmptyState.defaultProps = defaultProps;

export default EmptyState;
