import { useState, useEffect } from 'react';
import { loadNavbarWrapper, loadModalDiscoveryProducts, loadSidebarWrapper, loadFooterWrapper } from '../../../../../src/services/CentralTalentos/CentralTalentosService';

export const useLoadEcosystemNav = (currentUser) => {
  const [isEcosystemLoading, setIsEcosystemLoading] = useState(true);
  const [navbarData, setNavbarData] = useState();
  const [modalData, setModalData] = useState(null);
  const [sidebarData, setSidebarData] = useState();
  const [footerData, setFooterData] = useState();
  const [ecosystemLoadError, setIsEcosystemLoadError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navbarResponse = await loadNavbarWrapper(currentUser);
        const responseModal = await loadModalDiscoveryProducts(currentUser);
        const responseSidebar = await loadSidebarWrapper();
        const responseFooter = await loadFooterWrapper();

        const navbarResponseProducts = navbarResponse.links.products;
        const resProducts = navbarResponseProducts.filter(p => p.name !== 'navbar.recruitmentAndSelection');
        const currentProduct = navbarResponseProducts.find(p => p.name === 'navbar.recruitmentAndSelection');

        setNavbarData({
          products: resProducts,
          fixedProduct: currentProduct,
          styleNavbarHeader: navbarResponse.style.navbarHeader,
          styleNavbarProducts: navbarResponse.style.navbarProducts,
          styleProductDiv: navbarResponse.style.productDiv,
          styleProductsList: navbarResponse.style.productsList,
          styleFixedProduct: navbarResponse.style.fixedProduct,
          styleProductTitle: navbarResponse.style.productTitle,
          styleGupyProduct: navbarResponse.style.gupyProduct,
          styleFooterNavBar: navbarResponse.style.footerNavBar,
          styleButtonOpenSidebar: navbarResponse.style.buttonSidebar,
          styleMobileProductNav: navbarResponse.style.mobileProductNav,
          styleMobileProductNavHeader: navbarResponse.style.mobileProductNavHeader,
          styleMobileProductLink: navbarResponse.style.mobileProductLink,
          styleMobileNavMenuOverlay: navbarResponse.style.mobileNavMenuOverlay,
        });

        setModalData({
          titleModal: responseModal.titleModal,
          buttonModal: responseModal.buttonModal,
          tabsContent: responseModal.discoveryProducts,
          styleButtonOpenModal: responseModal.style.buttonOpenModal,
          styleModal: responseModal.style.modal,
          styleProductsList: responseModal.style.productsList,
          styleGupyProduct: responseModal.style.gupyProduct,
          styleModalContent: responseModal.style.modalContent,
          styleModalDiv: responseModal.style.modalDiv,
          styleModalContentList: responseModal.style.modalContentList,
          styleFooterModalContent: responseModal.style.footerModalContent,
        });

        setSidebarData({
          containerStyles: responseSidebar.sidebarContainerStyles,
          headerStyles: responseSidebar.sidebarHeader.styles,
          profileStyles: responseSidebar.sidebarProfile.styles,
          avatarStyles: responseSidebar.sidebarProfile.avatar.styles,
          userNameStyles: responseSidebar.sidebarProfile.userName.styles,
          userEmailStyles: responseSidebar.sidebarProfile.userEmail.styles,
          itemStyles: responseSidebar.sidebarProductItems.styles,
        });

        const responseFooterContent = responseFooter.footerContent.filter(f => f.name !== 'footer.copyrightInfo');
        const responseFooterCopyright = responseFooter.footerContent.find(f => f.name === 'footer.copyrightInfo');

        setFooterData({
          footerContent: responseFooterContent,
          footerCopyright: responseFooterCopyright,
          styleFooterComponent: responseFooter.style.styleFooterLayout,
          styleFooterLinkDiv: responseFooter.style.styleFooterLinkDiv,
          styleFooterLink: responseFooter.style.styleFooterLinks,
          styleCopyrightInfo: responseFooter.style.styleCopyrightInfo,
          styleFooterLogo: responseFooter.style.styleFooterLogo,
          styleFooterItens: responseFooter.style.styleFooterItens,
        });

        setIsEcosystemLoadError(false);
        setIsEcosystemLoading(false);
      } catch (error) {
        setIsEcosystemLoading(false);
        setIsEcosystemLoadError(true);
      }
    };

    fetchData();
  }, []);

  return {
    navbarData,
    modalData,
    sidebarData,
    footerData,
    isEcosystemLoading,
    ecosystemLoadError,
  };
};
