export const GET = 'JobCustomFormTemplate/GET';
export const GET_SUCCESS = 'JobCustomFormTemplate/GET_SUCCESS';
export const GET_FAIL = 'JobCustomFormTemplate/GET_FAIL';

export const SAVE_COMPANY_SCHEMA = 'JobCustomFormTemplate/SAVE_COMPANY_SCHEMA';
export const SAVE_COMPANY_SCHEMA_SUCCESS = 'JobCustomFormTemplate/SAVE_COMPANY_SCHEMA_SUCCESS';
export const SAVE_COMPANY_SCHEMA_FAIL = 'JobCustomFormTemplate/SAVE_COMPANY_SCHEMA_FAIL';
export const SAVE_COMPANY_SCHEMA_INVALID = 'JobCustomFormTemplate/SAVE_COMPANY_SCHEMA_INVALID';

export const SAVE_COMPANY_SCHEMA_MEMORY = 'JobCustomFormTemplate/SAVE_COMPANY_SCHEMA_MEMORY';
export const SAVE_COMPANY_ITEMS = 'JobCustomFormTemplate/SAVE_COMPANY_ITEMS';

export const OPEN_MODAL = 'JobCustomFormTemplate/OPEN_MODAL';
export const CLOSE_MODAL = 'JobCustomFormTemplate/CLOSE_MODAL';
