import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, intlShape, injectIntl, defineMessages } from 'react-intl';

import { Button, InputSearch } from '@gupy/design-system';

const propTypes = {
  onAddNewTemplateClick: PropTypes.func.isRequired,
  onSearchChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  canCreate: PropTypes.bool,
  searchValue: PropTypes.string.isRequired,
};

const defaultProps = {
  canCreate: false,
};

const JobOfferTemplateBar = ({
  onSearchChange,
  onAddNewTemplateClick,
  intl,
  canCreate,
  searchValue,
}) => {
  const messages = defineMessages({
    searchJobOfferTemplate: {
      id: 'search_job_offer_template',
      defaultMessage: 'Buscar template de carta oferta',
    },
  });
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-6">
        <div className="card-list__search job-offer-template-bar__input-container">
          <InputSearch
            onChange={onSearchChange}
            id="job-offer-template-search"
            name="jobOfferTemplateSearch"
            placeholder={intl.formatMessage(messages.searchJobOfferTemplate)}
            value={searchValue}
            inputLabel=""
            buttonLabel=""
            formLabel=""
            label=""
            block
          />
        </div>
      </div>
      {
        canCreate ? (
          <div className="col-xs-12 col-sm-6">
            <Button
              onClick={onAddNewTemplateClick}
            >
              <FormattedMessage
                id="create_new_job_offer_template"
                defaultMessage="Novo template de carta oferta"
              />
            </Button>
          </div>
        ) : null
      }
    </div>
  );
};

JobOfferTemplateBar.propTypes = propTypes;
JobOfferTemplateBar.defaultProps = defaultProps;

export default injectIntl(JobOfferTemplateBar);
