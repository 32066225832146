import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './FeedbackCopilot.scss';
import { feedbackCopilot } from '../../services/Copilot/CopilotService';

export const FeedbackEnum = {
  dislike: 'dislike',
  like: 'like',
  none: 'none',
};

const propTypes = {
  message: PropTypes.object.isRequired,
  answerId: PropTypes.number.isRequired,
  token: PropTypes.number,
};

const FeedbackCopilot = ({
  message,
  answerId,
  token,
}) => {
  const [likes, setLikes] = useState(0);
  const [dislikes, setDislikes] = useState(0);
  const [activeBtn, setActiveBtn] = useState('');

  const handleReaction = async (reaction) => {
    const isRelevant = reaction === FeedbackEnum.like;
    if (isRelevant) {
      setLikes(likes + 1);
    } else {
      setDislikes(dislikes + 1);
    }
    setActiveBtn(reaction);
    await feedbackCopilot(reaction, isRelevant, answerId, token);
  };

  return (
    <div className="buttons-feedback">

      <div className="like-dislike">
        <button
          className="like_btn"
          onClick={() => handleReaction(FeedbackEnum.like)}
        >
          {activeBtn === FeedbackEnum.like
            ? (
              <img
                alt={message.labelLiked}
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/likeActived.svg`}
              />
            )
            : (
              <img
                alt={message.labelLiked}
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/like.svg`}
              />
            )}

        </button>

        <button
          className="dislike_btn"
          onClick={() => handleReaction(FeedbackEnum.dislike)}
        >
          {activeBtn === FeedbackEnum.dislike
            ? (
              <img
                alt={message.labelDisliked}
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/dislikeActived.svg`}
              />
            )
            : (
              <img
                alt={message.labelDisliked}
                src={`${process.env.REACT_APP_ASSETS_URL}/copilot/dislike.svg`}
              />
            )}
        </button>
      </div>

    </div>
  );
};
FeedbackCopilot.propTypes = propTypes;
export default FeedbackCopilot;
