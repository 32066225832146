import api from '../../../api/DarthVaderClient';

export const replaceApprover = async ({ jobId, currentApproverId, newApproverId }) => {
  const {
    body,
  } = await api.post(
    `/job-management/jobs/${jobId}/approvers/${currentApproverId}/replace`,
    { newApproverId },
  );

  return body;
};

export const replaceApproverBulk = async ({ jobIds, currentApproverId, newApproverId }) => {
  const {
    body,
  } = await api.post(
    `/job-management/approvers/${currentApproverId}/replace`,
    { newApproverId, jobIds },
  );

  return body;
};

export const getJobsWaitingApprovalByApproverId = async ({ approverId }) => {
  const res = await api.get(`/job-management/jobs/waiting-approver/${approverId}`);
  return res.body;
};

export const addApprover = async ({ jobId, approverId }) => {
  const res = await api.post(`/job-management/jobs/${jobId}/approvers`, { approverId });
  return res.body;
};

export const removeApprover = async ({ jobId, approverId }) => {
  const res = await api.delete(`/job-management/jobs/${jobId}/approvers/${approverId}`);
  return res.body;
};

export const removeApproverBulk = async ({ jobIds, approverId }) => {
  const res = await api.delete(`/job-management/approvers/${approverId}`, { jobIds });
  return res.body;
};
