import React from 'react';
import PropTypes from 'prop-types';

import './GridSidebarMenu.scss';

const propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  description: PropTypes.oneOfType([
    PropTypes.element.isRequired,
    PropTypes.string.isRequired,
  ]),
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

const GridSidebar = ({
  title,
  description,
  children,
}) => (
  <div className="col-xs-12 col-sm-3 hidden-xs grid__sidebar">
    <div className="grid__sidebar-title">
      <h6>
        {title}
      </h6>
    </div>
    <div className="grid__sidebar__description-menu">
      {description}
    </div>
    <div>
      {children}
    </div>
  </div>
);

GridSidebar.propTypes = propTypes;
GridSidebar.defaultProps = defaultProps;

export default GridSidebar;
