import { combineReducers } from 'redux';

import ActionTypes from '../../constants/Inbound/InboundActionTypes';

const initialSegmentedLists = [];
const initialTalentsToLists = null;

const segmentedLists = (state = initialSegmentedLists, action) => {
  switch (action.type) {
  case ActionTypes.GET_SEGMENTED_LISTS_SUCCESS:
    return action.segmentedLists;
  default:
    return state;
  }
};

const talentsToLists = (state = initialTalentsToLists, action) => {
  switch (action.type) {
  case ActionTypes.POST_TALENTS_TO_LIST_SUCCESS:
    return action.data;
  case ActionTypes.CLEAR_TALENTS_TO_LIST:
    return initialTalentsToLists;
  default:
    return state;
  }
};

const isSavingTalentsToLists = (state = false, action) => {
  switch (action.type) {
  case ActionTypes.POST_TALENTS_TO_LIST:
    return true;
  case ActionTypes.POST_TALENTS_TO_LIST_SUCCESS:
  case ActionTypes.POST_TALENTS_TO_LIST_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  segmentedLists,
  talentsToLists,
  isSavingTalentsToLists,
});
