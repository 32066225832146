import { call, put, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import LocalstorageConstants from '../../../../constants/LocalstorageConstants';

import * as ActionTypes from './ActionTypes';
import { InsightsService } from './Service';

function* getInsights() {
  try {
    const response = yield call(InsightsService.getInsights);
    yield put({
      type: ActionTypes.GET_INSIGHTS_SUCCESS,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_INSIGHTS_FAIL,
      error,
    });
  }
}

function* getClosedModals() {
  const isClosedModalThisWeeklowApplicationJobs = (
    window.localStorage.getItem(LocalstorageConstants.lowApplicationJobsInsights)
  );
  const isClosedModalThisWeekDescriptionErrors = (
    window.localStorage.getItem(LocalstorageConstants.descriptionErrorsInsights)
  );

  const isSameWeek = closedDateStr => Boolean(closedDateStr && moment(closedDateStr).isSame(moment(), 'week'));

  yield put({
    type: ActionTypes.GET_INSIGHTS_SUCCESS,
    payload: {
      isClosedModalThisWeeklowApplicationJobs: isSameWeek(isClosedModalThisWeeklowApplicationJobs),
      isClosedModalThisWeekDescriptionErrors: isSameWeek(isClosedModalThisWeekDescriptionErrors),
    },
  });
}

function* handleCloseModalLowApplication() {
  localStorage.setItem(LocalstorageConstants.lowApplicationJobsInsights, new Date().toISOString());
  yield put({
    type: ActionTypes.CLOSE_LOW_APPLICATION_INSIGHTS_SUCCESS,
    payload: true,
  });

  GoogleAnalyticsHelper.sendEvent({ category: 'JobWeekInsights', action: 'close_low_application_jobs_card' });
}

function* handleCloseModalDescriptionErrors() {
  localStorage.setItem(LocalstorageConstants.descriptionErrorsInsights, new Date().toISOString());
  yield put({
    type: ActionTypes.CLOSE_DESCRIPTION_ERRORS_INSIGHTS_SUCCESS,
    payload: true,
  });

  GoogleAnalyticsHelper.sendEvent({ category: 'JobWeekInsights', action: 'close_error_description_jobs_card' });
}

function* getJobsWithQualifiedApplications() {
  try {
    const { body } = yield call(InsightsService.getJobsWithQualifiedApplications);
    const sliceData = body.data.slice(0, 500);
    const jobIds = sliceData.map(item => item.jobId);
    const lastClosed = (
      window.localStorage.getItem(LocalstorageConstants.jobsWithQualifiedApplicationsInsights)
    );

    const isSameDay = closedDateStr => Boolean(closedDateStr && moment(closedDateStr).isSame(moment(), 'day'));

    yield put({
      type: ActionTypes.GET_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS,
      payload: {
        jobs: jobIds,
        isClosed: isSameDay(lastClosed),
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_JOBS_WITH_QUALIFIED_APPLICATIONS_FAIL,
      error,
    });
  }
}

function* closeJobsWithQualifiedApplications() {
  localStorage.setItem(
    LocalstorageConstants.jobsWithQualifiedApplicationsInsights,
    new Date().toISOString(),
  );
  yield put({
    type: ActionTypes.CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS,
    payload: {
      isClosed: true,
    },
  });
}

function* getJobsCloseToExpire() {
  try {
    const { body } = yield call(InsightsService.getJobsCloseToExpire);
    const sliceData = body.data.slice(0, 500);
    const jobIds = sliceData.map(item => item.jobId);
    const lastClosed = (
      window.localStorage.getItem(LocalstorageConstants.jobsCloseToExpireInsights)
    );

    const isSameDay = closedDateStr => Boolean(closedDateStr && moment(closedDateStr).isSame(moment(), 'day'));

    yield put({
      type: ActionTypes.GET_JOBS_CLOSE_TO_EXPIRE_SUCCESS,
      payload: {
        jobs: jobIds,
        isClosed: isSameDay(lastClosed),
      },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_JOBS_CLOSE_TO_EXPIRE_FAIL,
      error,
    });
  }
}

function* closeJobsCloseToExpire() {
  localStorage.setItem(
    LocalstorageConstants.jobsCloseToExpireInsights,
    new Date().toISOString(),
  );
  yield put({
    type: ActionTypes.CLOSE_JOBS_CLOSE_TO_EXPIRE_SUCCESS,
    payload: {
      isClosed: true,
    },
  });
}

function* InsightsSaga() {
  yield takeLatest(ActionTypes.GET_INSIGHTS_REQUEST, getInsights);
  yield takeLatest(ActionTypes.GET_INSIGHTS_CLOSED_MODALS_REQUEST, getClosedModals);
  yield takeLatest(
    ActionTypes.CLOSE_LOW_APPLICATION_INSIGHTS_REQUEST,
    handleCloseModalLowApplication,
  );
  yield takeLatest(
    ActionTypes.CLOSE_DESCRIPTION_ERRORS_INSIGHTS_REQUEST,
    handleCloseModalDescriptionErrors,
  );
  yield takeLatest(
    ActionTypes.GET_JOBS_WITH_QUALIFIED_APPLICATIONS_REQUEST,
    getJobsWithQualifiedApplications,
  );
  yield takeLatest(
    ActionTypes.CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_REQUEST,
    closeJobsWithQualifiedApplications,
  );
  yield takeLatest(
    ActionTypes.GET_JOBS_CLOSE_TO_EXPIRE_REQUEST,
    getJobsCloseToExpire,
  );
  yield takeLatest(
    ActionTypes.CLOSE_JOBS_CLOSE_TO_EXPIRE_REQUEST,
    closeJobsCloseToExpire,
  );
}

export default InsightsSaga;
