import { combineReducers } from 'redux';

import { adaptGeolocationFromHere } from '@gupy/front-commons';
import * as ActionTypes from './ActionTypes';

export const addressSuggestions = (state = [], action) => {
  switch (action.type) {
  case ActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS: {
    if (!action.suggestions) {
      return [];
    }
    const formatedSuggestions = action.suggestions.map(
      item => adaptGeolocationFromHere(item),
    );
    return formatedSuggestions.slice(0, 5);
  }

  default:
    return state;
  }
};

export const countries = (state = [], action) => {
  switch (action.type) {
  case ActionTypes.GET_COUNTRIES_SUCCESS:
    return action.countries.map(country => ({
      value: country.code,
      label: country.name,
    }));
  default:
    return state;
  }
};

export default combineReducers({
  addressSuggestions,
  countries,
});
