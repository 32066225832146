import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  className: PropTypes.string,
  customNode: PropTypes.node,
  children: PropTypes.node,
  hasMenu: PropTypes.bool,
  isFeatured: PropTypes.bool,
  hasSideBar: PropTypes.bool,
  headerClassName: PropTypes.string,
};

const defaultProps = {
  title: undefined,
  className: '',
  customNode: undefined,
  children: undefined,
  hasMenu: false,
  isFeatured: false,
  hasSideBar: true,
  headerClassName: '',
};

function
GridContent({ title, className, customNode, children, hasMenu,
  hasSideBar, isFeatured, headerClassName }) {
  const hasMenuClass = hasMenu ? 'grid__main-content--menu' : '';
  const hasSideBarClass = hasSideBar ? 'col-sm-9' : 'col-sm-12';

  const titleElement = () => {
    if (!title) {
      return undefined;
    }

    if (React.isValidElement(title)) {
      return (<span className="grid__main-content__title">{title}</span>);
    }

    return (<h1 className="grid__main-content__title">{title}</h1>);
  };

  const headerClass = ['grid__main-content__header'];
  if (isFeatured) {
    headerClass.push('grid__main-content__header--featured');
  }
  headerClass.push(headerClassName);

  return (
    <main className={classNames(
      'col-xs-12',
      hasSideBarClass,
      'grid__main-content',
      hasMenuClass,
      className,
    )}
    >
      <div className={classNames(headerClass)}>
        {titleElement()}
        {customNode}
      </div>
      <div className="box">
        {children}
      </div>
    </main>
  );
}

GridContent.propTypes = propTypes;
GridContent.defaultProps = defaultProps;

export default GridContent;
