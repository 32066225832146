import React from 'react';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import TermsDocument from './TermsDocument';
import PrivacyDocument from './PrivacyDocument';
import { updateTermsConsent, updatePrivacyConsent } from './TermsAndPrivacyService';

const getTermsAndPrivacy = ({ messages, openConfirmDialog }) => {
  const handleTermsConfirmClick = () => {
    GoogleAnalyticsHelper.sendEvent({ category: 'TERMS_AND_PRIVACY', action: 'AGREE_TERMS' });
    updateTermsConsent();
  };

  const handlePrivacyConfirmClick = () => {
    GoogleAnalyticsHelper.sendEvent({ category: 'TERMS_AND_PRIVACY', action: 'AGREE_PRIVACY' });
    updatePrivacyConsent();
  };

  return {
    terms: {
      label: messages.termsLabel,
      onClick: () => {
        GoogleAnalyticsHelper.sendEvent({ category: 'TERMS_AND_PRIVACY', action: 'OPEN_TERMS' });
        openConfirmDialog({
          title: <strong>{messages.termsTitle}</strong>,
          message: <TermsDocument />,
          confirmButtonText: messages.termsConfirmButtonText,
          onConfirmClick: handleTermsConfirmClick,
        });
      },
    },
    privacy: {
      label: messages.privacyLabel,
      onClick: () => {
        GoogleAnalyticsHelper.sendEvent({ category: 'TERMS_AND_PRIVACY', action: 'OPEN_PRIVACY' });
        openConfirmDialog({
          title: <strong>{messages.privacyTitle}</strong>,
          message: <PrivacyDocument />,
          confirmButtonText: messages.privacyConfirmButtonText,
          onConfirmClick: handlePrivacyConfirmClick,
        });
      },
    },
  };
};

export default getTermsAndPrivacy;
