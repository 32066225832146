import { BaseForm } from '@gupy/front-commons';

class CustomTestValidation extends BaseForm {
  constructor() {
    super({
      title: {
        validate: {
          notEmpty: true,
        },
      },
      instructions: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default CustomTestValidation;
