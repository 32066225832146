import React from 'react';
import { bool } from 'prop-types';
import { injectIntl, defineMessages, intlShape } from 'react-intl';

const messages = defineMessages({
  thanksForReply: {
    defaultMessage: 'Agradecemos a validação da indicação! Sua resposta foi registrada e será analisada pelos responsáveis da vaga.',
    id: 'thanks-for-reply',
  },
  opsError: {
    defaultMessage: 'Sua resposta não foi registrada, tente novamente em alguns instantes.',
    id: 'ops-error',
  },
});

const SuccessFeedback = ({ hasError, intl }) => (
  hasError
    ? (
      <aside className="confirmation-card__feedback">
        <p>{intl.formatMessage(messages.opsError)}</p>
      </aside>
    ) : (
      <aside className="confirmation-card__feedback confirmation-card__feedback--error">
        <p>{intl.formatMessage(messages.thanksForReply)}</p>
      </aside>
    )
);

SuccessFeedback.propTypes = {
  intl: intlShape.isRequired,
  hasError: bool,
};

SuccessFeedback.defaultProps = {
  hasError: false,
};

export default injectIntl(SuccessFeedback);
