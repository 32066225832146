import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const InterviewEventActionTypes = createActionTypes(
  'InterviewEvent',
  'REDIRECT_TO_CANDIDATE_PROFILE',
  'CLEAR_INTERVIEW_EVENT_LIST',
  'START_FETCHING_INTERVIEW_EVENT_LIST',
  asyncActionType('GET_BY_INTERVIEWER_ID'),
);

function startAtSorter(i1, i2) {
  const s1 = i1.startAt;
  const s2 = i2.startAt;
  if (s1 < s2) return -1;
  if (s1 > s2) return 1;
  return 0;
}

const getByInterviewerId = ({ interviewerId, timezone, timetable }) => ({
  type: InterviewEventActionTypes.GET_BY_INTERVIEWER_ID,
  interviewerId,
  timezone,
  timetable,
});

const getByInterviewerIdSuccess = interviewEvents => ({
  type: InterviewEventActionTypes.GET_BY_INTERVIEWER_ID_SUCCESS,
  interviewEvents: interviewEvents.sort(startAtSorter),
});

const getByInterviewerIdFail = () => ({
  type: InterviewEventActionTypes.GET_BY_INTERVIEWER_ID_FAIL,
});

const clearInterviewEventList = () => ({
  type: InterviewEventActionTypes.CLEAR_INTERVIEW_EVENT_LIST,
});

const startFetchingInterviewEventList = () => ({
  type: InterviewEventActionTypes.START_FETCHING_INTERVIEW_EVENT_LIST,
});

const redirectToCandidateProfile = ({ applicationId, jobId }) => ({
  type: InterviewEventActionTypes.REDIRECT_TO_CANDIDATE_PROFILE,
  applicationId,
  jobId,
});

export {
  InterviewEventActionTypes,
  startFetchingInterviewEventList,
  getByInterviewerId,
  getByInterviewerIdSuccess,
  getByInterviewerIdFail,
  clearInterviewEventList,
  redirectToCandidateProfile,
};
