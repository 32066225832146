import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';

import childAppPropTypes from '../../../prop-types/childAppPropTypes';
import careerPagePropTypes from '../../../prop-types/careerPagePropTypes';

import ChildAppCareerPages from './components/ChildAppCareerPages';
import ChildAppName from './components/ChildAppName';
import ChildAppStatus from './components/ChildAppStatus';
import ChildAppActions from './components/ChildAppActions';
import ChildAppErrorTip from './components/ChildAppErrorTip';
import ChildAppEditModeOptions from './components/ChildAppEditModeOptions';

const ChildAppDefault = ({
  messages,
  childApp,
  rscLinkedinIntegrationCareerPages,
  handleChildAppSyncSubmit,
  handleChildAppEditSubmit,
  handleChildAppSyncRestartSubmit,
  handleChildAppDesyncSubmit,
}) => {
  const [editMode, setEditMode] = useState(
    childApp.isEditModeDefault() || childApp.hasFormValidationErrors(),
  );

  const [childAppName, setChildAppName] = useState(
    childApp.hasFormValidationErrors() ? childApp.nameWithFormValidationErrors : childApp.name,
  );

  const [childAppCareerPages, setChildAppCareerPages] = useState(
    childApp.hasFormValidationErrors()
      ? childApp.careerPagesWithFormValidationErrors
      : childApp.careerPages,
  );

  return (
    <Fragment>
      <tr className={`job-board-panel-rsc-child-apps__table-row ${childApp.status}`}>
        <td>
          <ChildAppName
            messages={messages}
            childApp={childApp}
            editMode={editMode}
            childAppName={childAppName}
            setChildAppName={setChildAppName}
          />
        </td>
        <td>
          <ChildAppCareerPages
            messages={messages}
            editMode={editMode}
            childApp={childApp}
            childAppCareerPages={childAppCareerPages}
            setChildAppCareerPages={setChildAppCareerPages}
            rscLinkedinIntegrationCareerPages={rscLinkedinIntegrationCareerPages}
          />
        </td>
        <td>
          <ChildAppStatus
            childApp={childApp}
          />
        </td>
        <td className="job-board-panel-rsc-child-apps__table-row-actions">
          <ChildAppActions
            messages={messages}
            childApp={childApp}
            editMode={editMode}
            setEditMode={setEditMode}
            handleChildAppDesyncSubmit={handleChildAppDesyncSubmit}
          />
        </td>
      </tr>
      {childApp.shouldShowTip() && (
        <ChildAppErrorTip
          childApp={childApp}
          childAppName={childAppName}
          handleChildAppSyncRestartSubmit={handleChildAppSyncRestartSubmit}
          handleChildAppDesyncRestartSubmit={handleChildAppDesyncSubmit}
        />
      )}
      {editMode && (
        <ChildAppEditModeOptions
          messages={messages}
          childApp={childApp}
          childAppName={childAppName}
          setChildAppName={setChildAppName}
          childAppCareerPages={childAppCareerPages}
          setChildAppCareerPages={setChildAppCareerPages}
          setEditMode={setEditMode}
          handleChildAppEditSubmit={handleChildAppEditSubmit}
          handleChildAppSyncSubmit={handleChildAppSyncSubmit}
        />
      )}
    </Fragment>
  );
};

ChildAppDefault.propTypes = {
  messages: PropTypes.object.isRequired,
  childApp: childAppPropTypes.isRequired,
  rscLinkedinIntegrationCareerPages: PropTypes.arrayOf(careerPagePropTypes).isRequired,
  handleChildAppSyncSubmit: PropTypes.func.isRequired,
  handleChildAppEditSubmit: PropTypes.func.isRequired,
  handleChildAppSyncRestartSubmit: PropTypes.func.isRequired,
  handleChildAppDesyncSubmit: PropTypes.func.isRequired,
};

export default ChildAppDefault;
