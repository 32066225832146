import { combineReducers } from 'redux';

import ProfileActionTypes from '../../constants/Profile/ProfileActionTypes';

const initialModelForm = {
  name: '',
  size: undefined,
  countryOfOrigin: '',
  companyType: undefined,
  language: undefined,
  email: '',
  telephone: '',
  celphone: '',
  subdomain: '',
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case ProfileActionTypes.PATCH:
    return true;
  case ProfileActionTypes.PATCH_SUCCESS:
  case ProfileActionTypes.PATCH_FAIL:
    return false;
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case ProfileActionTypes.INIT_SUCCESS:
    return {
      ...initialModelForm,
      name: action.profile.name,
      size: action.profile.size,
      countryOfOrigin: action.profile.countryOfOrigin,
      companyType: action.profile.companyType,
      language: action.profile.language,
      email: action.profile.email || '',
      telephone: action.profile.telephone || '',
      defaultTelephone: action.profile.telephone || '',
      celphone: action.profile.celphone || '',
      defaultCelphone: action.profile.celphone || '',
      subdomain: action.profile.subdomain,
    };
  case ProfileActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  default:
    return state;
  }
};

export const companySizes = (state = [], action) => {
  switch (action.type) {
  case ProfileActionTypes.INIT_SUCCESS:
    return action.companySizes;
  case ProfileActionTypes.INIT_FAIL:
    return [];
  default:
    return state;
  }
};

export const companyTypes = (state = [], action) => {
  switch (action.type) {
  case ProfileActionTypes.INIT_SUCCESS:
    return action.companyTypes;
  case ProfileActionTypes.INIT_FAIL:
    return [];
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case ProfileActionTypes.PATCH:
  case ProfileActionTypes.CLEAR_FORM:
    return {};
  case ProfileActionTypes.PATCH_FAIL:
  case ProfileActionTypes.GET_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  validation,
  companyTypes,
  companySizes,
});
