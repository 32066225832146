import api from '../../api/DarthVaderClient';

export default {
  getApiAccessPolicyList() {
    return api.get('/authorization/company/api-access-policy/token');
  },
  postApiAccessPolicy(payload) {
    return api.post('/authorization/company/api-access-policy/token', payload);
  },
  deleteApiAccessPolicy(id) {
    return api.delete(`/authorization/company/api-access-policy/token/${id}`);
  },
  listApiAccessRoutes() {
    return api.get('/authorization/company/api-access-policy/route');
  },
};
