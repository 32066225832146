import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('JobSteps',
  'CHANGE_FORM',
  asyncActionType('GET'),
  'GET_SUCCESS',
  asyncActionType('DELETE'),
  asyncActionType('PATCH'),
  asyncActionType('PATCH_JOB'),
  asyncActionType('REORDER'),
  'REORDER_SUCCESS',
  'REORDER_FAIL');
