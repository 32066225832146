import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  InterviewScriptActionTypes,
  createAnswerSuccess,
  createInterviewScriptSuccess,
  getInterviewScriptSuccess,
  deleteInterviewScriptSuccess,
  getInterviewScriptPreviewSuccess,
  listInterviewScriptSuccess,
  updateAnswerSuccess,
  updateInterviewScriptSuccess,
} from './InterviewScriptAction';
import InterviewScriptService from './InterviewScriptService';
import { getList, getObj } from './InterviewScriptSelectors';

function* create({ payload }) {
  try {
    const response = yield call(InterviewScriptService.create, payload.data);
    yield put(createInterviewScriptSuccess({
      data: response.body,
      message: payload.message,
    }));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.CREATE_FAIL,
      payload: error,
    });
  }
}

function* createAnswer({ payload }) {
  try {
    const params = { ...payload.params, payload: { value: payload.annotation } };
    const response = yield call(InterviewScriptService.createAnswer, params);
    const script = yield select(getObj);
    yield put(createAnswerSuccess(response.body, script));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.CREATE_ANSWER_FAIL,
      payload: error,
    });
  }
}

function* get({ payload }) {
  try {
    const response = yield call(InterviewScriptService.get, payload);
    yield put(getInterviewScriptSuccess(response.body));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.GET_FAIL,
      payload: error,
    });
  }
}

function* deleteScript({ payload }) {
  try {
    yield call(InterviewScriptService.delete, payload);
    const scripts = yield select(getList);
    yield put(deleteInterviewScriptSuccess(scripts, payload));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.DELETE_FAIL,
      payload: error,
    });
  }
}

function* getByApplicationAndStepId({ payload }) {
  try {
    const { applicationId, applicationStepId } = payload;
    const response = yield call(
      InterviewScriptService.getByApplicationAndStepId,
      applicationId,
      applicationStepId,
    );
    yield put(getInterviewScriptPreviewSuccess(response.body));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.GET_PREVIEW_FAIL,
      payload: error,
    });
  }
}

function* list() {
  try {
    const response = yield call(InterviewScriptService.list);
    yield put(listInterviewScriptSuccess(response.body));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.LIST_FAIL,
      payload: error,
    });
  }
}

function* listSteps({ payload }) {
  try {
    const response = yield call(InterviewScriptService.listByApplicationId, payload);
    yield put({
      type: InterviewScriptActionTypes.LIST_STEPS_SUCCESS,
      payload: response.body.steps,
    });
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.LIST_STEPS_FAIL,
      payload: error,
    });
  }
}

function* simpleList() {
  try {
    const response = yield call(InterviewScriptService.simpleList);
    yield put({
      type: InterviewScriptActionTypes.SIMPLE_LIST_SUCCESS,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.SIMPLE_LIST_FAIL,
      payload: error,
    });
  }
}

function* update({ payload }) {
  try {
    const response = yield call(InterviewScriptService.update, payload.data);
    const scripts = yield select(getList);
    const newScripts = [...scripts];
    yield put(updateInterviewScriptSuccess({
      data: response.body,
      newScripts,
      message: payload.message,
    }));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.UPDATE_FAIL,
      payload: error,
    });
  }
}

function* updateAnswer({ payload }) {
  try {
    const params = { ...payload.params, payload: { value: payload.annotation } };
    const response = yield call(InterviewScriptService.updateAnswer, params);
    const script = yield select(getObj);
    yield put(updateAnswerSuccess(response.body, script));
  } catch (error) {
    yield put({
      type: InterviewScriptActionTypes.UPDATE_ANSWER_FAIL,
      payload: error,
    });
  }
}

function* InterviewScriptSaga() {
  yield takeLatest(InterviewScriptActionTypes.CREATE, create);
  yield takeLatest(InterviewScriptActionTypes.CREATE_ANSWER, createAnswer);
  yield takeLatest(InterviewScriptActionTypes.GET, get);
  yield takeLatest(InterviewScriptActionTypes.DELETE, deleteScript);
  yield takeLatest(InterviewScriptActionTypes.GET_PREVIEW, getByApplicationAndStepId);
  yield takeLatest(InterviewScriptActionTypes.LIST, list);
  yield takeLatest(InterviewScriptActionTypes.LIST_STEPS, listSteps);
  yield takeLatest(InterviewScriptActionTypes.SIMPLE_LIST, simpleList);
  yield takeLatest(InterviewScriptActionTypes.UPDATE, update);
  yield takeLatest(InterviewScriptActionTypes.UPDATE_ANSWER, updateAnswer);
}

export {
  InterviewScriptSaga,
  create,
  createAnswer,
  get,
  deleteScript,
  getByApplicationAndStepId,
  list,
  listSteps,
  simpleList,
  update,
  updateAnswer,
};
