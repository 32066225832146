/* eslint-disable max-len */
import { useFlag } from '@unleash/proxy-client-react';
import featuresFlagsEnum from '../../containers/Authentication/FeaturesFlagsEnum';

const iconBaseURL = `${process.env.REACT_APP_API_CENTRAL_TALENTOS}/sidebar-icons`;

const MenuContentData = (messages, permissions, userFlags, userLanguage) => {
  const isAdmissionOnlyEnabled = useFlag(featuresFlagsEnum.admissionOnly);
  const isCoinEnabled = userFlags.includes('coin');
  const isSamlLoginEnabled = useFlag(featuresFlagsEnum.samlLogin);
  const isTalentAcquisitionModuleEnabled = useFlag(
    featuresFlagsEnum.talentAcquisitionModule,
  );
  const isTalentInboundConnectionEnabled = useFlag(
    featuresFlagsEnum.talentInboundConnection,
  );
  const isManageTalentPortalCompaniesEnabled = useFlag(
    featuresFlagsEnum.manageTalentPortalCompanies,
  );
  const isPermissionsEmpty = () =>
    Object.keys(permissions).length === 0 && permissions.constructor === Object;

  const isUsingChrome = navigator.userAgent.includes('Chrome');
  const isUsingMobile = navigator.userAgent.includes('Mobile');
  const isUsingAndroid = navigator.userAgent.includes('Android');

  const hasVacancyManagementAccess =
    !isAdmissionOnlyEnabled && !isPermissionsEmpty();

  const hasTalentPoolAccess =
    !isAdmissionOnlyEnabled && permissions.view_talent_pool;
  const hasTalentPortalAccess =
    hasTalentPoolAccess && isManageTalentPortalCompaniesEnabled;
  const hasTalentPoolConnectionAccess =
    hasTalentPoolAccess && isTalentInboundConnectionEnabled;
  const hasSegmentedListAccess =
    hasTalentPoolAccess && isTalentAcquisitionModuleEnabled;
  const hasCaptureFormAccess =
    hasTalentPoolAccess && isTalentAcquisitionModuleEnabled;
  const hasCommunicationAccess =
    hasTalentPoolAccess && isTalentAcquisitionModuleEnabled;

  const hasAnalyticsAccess =
    !isAdmissionOnlyEnabled &&
    (permissions.view_analytics || permissions.view_analytics_admission);
  const hasReportAccess = !isAdmissionOnlyEnabled && permissions.view_report;
  const hasCoinAccess = isCoinEnabled && permissions.create_kudo;
  const hasSetupAccess = !isPermissionsEmpty();
  const hasCommunityAccess = userLanguage === 'pt';
  const hasGupyRecruiterAccess =
    permissions.register_candidate_insertion &&
    !isSamlLoginEnabled &&
    !isAdmissionOnlyEnabled &&
    isUsingChrome &&
    !isUsingMobile &&
    !isUsingAndroid;

  return [
    {
      title: messages.menuOverview,
      hasAccess: true,
      iconSrc: `${iconBaseURL}/icon-home.svg`,
      linkUrl: '/companies',
      isSpaLink: true,
    },
    {
      title: messages.vacancyManagement,
      hasAccess: hasVacancyManagementAccess,
      iconSrc: `${iconBaseURL}/icon-work.svg`,
      linkUrl: '/companies/jobs',
      isSpaLink: true,
    },
    {
      title: messages.talentsManagement,
      hasAccess: hasTalentPoolAccess,
      iconSrc: `${iconBaseURL}/icon-groups.svg`,
      isExpandable: true,
      linkUrl: '/companies/talent-pool',
      isSpaLink: true,
      subItems: [
        {
          title: messages.talents,
          hasAccess: hasTalentPoolAccess && hasTalentPoolConnectionAccess,
          isSpaLink: true,
          linkUrl: '/companies/talent-pool',
        },
        {
          title: messages.talentsPortal,
          hasAccess: hasTalentPortalAccess,
          isSpaLink: true,
          linkUrl: '/companies/talent-pool/talent-portal',
        },
        {
          title: messages.gupyConnection,
          hasAccess: hasTalentPoolConnectionAccess,
          isSpaLink: true,
          linkUrl: '/companies/talent-pool/connection',
        },
        {
          title: messages.segmentedLists,
          hasAccess: hasSegmentedListAccess,
          isSpaLink: true,
          linkUrl: '/companies/talent-pool/segmented-list',
        },
        {
          title: messages.fundraisingPages,
          hasAccess: hasCaptureFormAccess,
          isSpaLink: true,
          linkUrl: '/companies/talent-pool/capture-form',
        },
        {
          title: messages.communications,
          hasAccess: hasCommunicationAccess,
          isSpaLink: true,
          linkUrl: '/companies/talent-pool/communication',
        },
      ],
    },
    {
      title: messages.dataAnalysis,
      hasAccess: hasAnalyticsAccess,
      iconSrc: `${iconBaseURL}/icon-insert-chart.svg`,
      linkUrl: '/analytics/dashboards',
    },
    {
      title: messages.reports,
      hasAccess: hasReportAccess,
      iconSrc: `${iconBaseURL}/icon-summarize.svg`,
      linkUrl: '/analytics/reports',
    },
    {
      title: messages.taskManagement,
      hasAccess: true,
      iconSrc: `${iconBaseURL}/icon-pending.svg`,
      linkUrl: '/companies/task',
      isSpaLink: true,
    },
    {
      title: messages.setup,
      hasAccess: hasSetupAccess,
      iconSrc: `${iconBaseURL}/icon-settings.svg`,
      linkUrl: '/companies/setup',
      isSpaLink: true,
    },
    {
      title: messages.gupyCommunity,
      hasAccess: hasCommunityAccess,
      iconSrc: `${iconBaseURL}/icon-community.svg`,
      linkUrl: 'https://gupy.comunitive.app/novidades',
    },
    {
      title: messages.gupyRecruiter,
      hasAccess: hasGupyRecruiterAccess,
      iconSrc: `${iconBaseURL}/icon-extension.svg`,
      linkUrl:
        'https://chromewebstore.google.com/detail/gupy-recruiter/gjgdbadgkbgkmajoombhibngfcbmkpml',
    },
    {
      title: messages.gupyCoin,
      hasAccess: hasCoinAccess,
      iconSrc: `${iconBaseURL}/new-icon-coin.svg`,
      isExpandable: true,
      subItems: [
        {
          title: messages.recognitions,
          hasAccess: true,
          linkUrl: '/coin',
        },
        {
          title: messages.store,
          hasAccess: true,
          linkUrl: '/coin/shop',
        },
        {
          title: messages.orders,
          hasAccess: true,
          linkUrl: '/coin/orders',
        },
      ],
    },
  ];
};

export default MenuContentData;
