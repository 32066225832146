import { call, put, takeLatest } from 'redux-saga/effects';
import {
  buildToast,
  ToastTypes,
} from '@gupy/front-commons';

import { SetupEngageSurveyActionTypes } from './SetupEngageSurveyAction';
import JobService from '../../services/Job/JobService';
import EngageSurveyService from '../../services/EngageSurvey/EngageSurvey';
import { SCENARIOS_CEE } from './StatusCode';

export function* getCareerPageOptionsList({ messages }) {
  try {
    const response = yield call(JobService.getCompanyCareerPages);
    yield put({
      type: SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST_SUCCESS,
      payload: {
        career_pages: response.body,
      },
    });
  } catch (error) {
    yield put({
      type: SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST_FAIL,
      payload: error,
      toast: buildToast(`⚠️ ${messages.error}`, ToastTypes.error),
    });
  }
}

export function* getSurveyOptionsList({ messages }) {
  try {
    const response = yield call(EngageSurveyService.getSurveys);
    yield put({
      type: SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST_SUCCESS,
      payload: {
        surveys: response.body,
      },
    });
  } catch (error) {
    yield put({
      type: SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST_FAIL,
      payload: error,
      toast: buildToast(`⚠️ ${messages.error}`, ToastTypes.error),
    });
  }
}

export function* saveSurveyCareerPageBind(action) {
  const { careerPages, surveys } = action.payload;
  const { messages } = action;

  try {
    yield call(
      EngageSurveyService.saveSurveyCareerPageBind,
      { careerPages, surveys },
    );

    yield put({
      type: SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES_SUCCESS,
      toast: buildToast(`✅ ${messages.success}`, ToastTypes.success, { autoClose: 5000 }),
    });

    yield put({
      type: SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
    });
  } catch (error) {
    yield put({
      type: SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES_FAIL,
      payload: error,
      toast: buildToast(
        error && error.body && error.body.message
          ? `⚠️ ${error.body.message}`
          : `⚠️ ${messages.error}`,
        ToastTypes.error,
      ),
    });
  }
}

export function* getSurveyCareerPageBinds({ messages }) {
  try {
    const response = yield call(EngageSurveyService.getSurveyCareerPageBinds);
    yield put({
      type: SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES_SUCCESS,
      payload: {
        binds: response.body,
      },
    });
  } catch (error) {
    yield put({
      type: SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES_FAIL,
      payload: error,
      toast: buildToast(
        error && error.body && error.body.message
          ? `⚠️ ${error.body.message}`
          : `⚠️ ${messages.error}`,
        ToastTypes.error,
      ),
    });
  }
}

export function* deleteSurveyCareerPageBind(action) {
  const { id } = action.payload;
  const { messages } = action;

  try {
    yield call(
      EngageSurveyService.deleteSurveyCareerPageBind,
      id,
    );

    yield put({
      type: SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES_SUCCESS,
      toast: buildToast(`✅ ${messages.success}`, ToastTypes.success, { autoClose: 5000 }),
    });

    yield put({
      type: SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
    });
  } catch (error) {
    yield put({
      type: SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES_FAIL,
      payload: error,
      toast: buildToast(
        error && error.body && error.body.message
          ? `⚠️ ${error.body.message}`
          : `⚠️ ${messages.error}`,
        ToastTypes.error,
      ),
    });
  }
}

export function* createSurvey({ messages, keycloak }) {
  try {
    yield call(EngageSurveyService.createSurvey, { bearerToken: keycloak.token });
    yield put({
      type: SetupEngageSurveyActionTypes.CREATE_SURVEY_SUCCESS,
    });
    yield put({
      type: SetupEngageSurveyActionTypes.SET_MODAL_OPEN,
      isOpen: false,
    });
    yield put({
      type: SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST,
    });
  } catch (error) {
    if (
      error.statusCode === SCENARIOS_CEE.NOT_OWNER_BUT_IS_EMPLOYEE
      || error.statusCode === SCENARIOS_CEE.NOT_OWNER_AND_NOT_IS_EMPLOYEE
    ) {
      yield put({
        type: SetupEngageSurveyActionTypes.CREATE_SURVEY_FAIL,
        payload: error,
      });
    } else {
      yield put({
        type: SetupEngageSurveyActionTypes.CREATE_SURVEY_FAIL,
        payload: error,
        toast: buildToast(
          error && error.body && error.body.message
            ? `⚠️ ${error.body.message}`
            : `⚠️ ${messages.error}`,
          ToastTypes.error,
        ),
      });
    }
  }
}

export function* createAccount(action) {
  try {
    yield call(EngageSurveyService.createAccount, {
      bearerToken: action.payload.keycloak.token,
      companyCnpj: action.payload.companyCnpj,
    });

    yield put({
      type: SetupEngageSurveyActionTypes.BIND_ACCOUNT_SUCCESS,
      messages: action.payload.messages,
      keycloak: action.payload.keycloak,
    });
  } catch (error) {
    const { messages } = action.payload;
    const message = error && error.body && error.body.message
      ? `⚠️ ${error.body.message}`
      : `⚠️ ${messages.formSendBindError}`;

    yield put({
      payload: error,
      toast: buildToast(message, ToastTypes.error),
      type: SetupEngageSurveyActionTypes.BIND_ACCOUNT_FAIL,
    });
    yield put({
      type: SetupEngageSurveyActionTypes.SET_MODAL_OPEN,
      isOpen: false,
    });
  }
}

export function* createClimateEngagementAccount(action) {
  const { messages, keycloak, companyCnpj } = action.payload;

  try {
    yield call(EngageSurveyService.createAccount, {
      bearerToken: keycloak.token,
      companyCnpj,
    });

    yield call(EngageSurveyService.createSurvey, { bearerToken: keycloak.token });

    yield put({
      type: SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT_SUCCESS,
    });

    yield put({
      type: SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST,
    });
  } catch (error) {
    const message = error && error.body && error.body.message
      ? error.body.message
      : messages.createAccountError;

    yield put({
      payload: error,
      message,
      type: SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT_FAIL,
    });
  }
}

function* SetupEngageSurveyContainerSaga() {
  yield takeLatest(
    SetupEngageSurveyActionTypes.GET_CAREER_PAGES_OPTIONS_LIST,
    getCareerPageOptionsList,
  );
  yield takeLatest(
    SetupEngageSurveyActionTypes.GET_SURVEY_OPTIONS_LIST,
    getSurveyOptionsList,
  );
  yield takeLatest(
    SetupEngageSurveyActionTypes.SAVE_BIND_SURVEY_CAREER_PAGES,
    saveSurveyCareerPageBind,
  );
  yield takeLatest(
    SetupEngageSurveyActionTypes.GET_BIND_SURVEY_CAREER_PAGES,
    getSurveyCareerPageBinds,
  );
  yield takeLatest(
    SetupEngageSurveyActionTypes.DELETE_BIND_SURVEY_CAREER_PAGES,
    deleteSurveyCareerPageBind,
  );
  yield takeLatest([
    SetupEngageSurveyActionTypes.CREATE_SURVEY,
    SetupEngageSurveyActionTypes.BIND_ACCOUNT_SUCCESS,
  ],
  createSurvey);
  yield takeLatest(
    SetupEngageSurveyActionTypes.BIND_ACCOUNT,
    createAccount,
  );
  yield takeLatest(
    SetupEngageSurveyActionTypes.CREATE_CLIMATE_ENGAGEMENT_ACCOUNT,
    createClimateEngagementAccount,
  );
}

export default SetupEngageSurveyContainerSaga;
