/* eslint-disable react/no-array-index-key */
import React, { createElement } from 'react';
import PropTypes from 'prop-types';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Card, Grid, Divisor } from '@gupy/design-system';

import HomeGrid from '../HomeGrid/HomeGrid';
import HomeListPlaceholder from './components/HomeListPlaceholder';
import './HomeList.scss';

const propTypes = {
  children: PropTypes.object.isRequired,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  emptyListImage: PropTypes.string.isRequired,
  emptyListMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.node.isRequired,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xl: PropTypes.number,
  xs: PropTypes.number,
};

const defaultProps = {
  isLoading: false,
};

const HomeList = (props) => {
  const {
    className,
    data,
    isLoading,
    title,
    emptyListMessage,
    emptyListImage,
    children,
    lg,
    md,
    sm,
    xl,
    xs,
  } = props;
  const shouldShowEvents = data.length > 0 && !isLoading;

  return shouldShowEvents
    ? (
      <Grid item lg={lg} md={md} sm={sm} xl={xl} xs={xs} className={`${className} home-list root`}>
        <div className="home-list__container">
          <div className="home-list__title">
            {title}
          </div>
          <div className="home-list__fixed-content">
            <Card className="home-list__resizable-content root">
              <div className="home-list__list">
                <List dense>
                  {data.map((value, key) => {
                    const isNotLastItem = key < data.length - 1;
                    const componentTemplateProps = React.Children.only(children).props;
                    const instance = createElement(
                      componentTemplateProps.component,
                      {
                        data: value,
                        ...componentTemplateProps,
                      },
                    );
                    return (
                      <React.Fragment key={key}>
                        <ListItem className={'home-list__list-item'} key={`item-${key}`}>
                          {instance}
                        </ListItem>
                        {isNotLastItem ? <Divisor key={`divider-${key}`}/> : null}
                      </React.Fragment>
                    );
                  })}
                </List>
              </div>
            </Card>
          </div>
        </div>
      </Grid>
    )
    : (
      <HomeGrid box lg={lg} md={md} sm={sm} xl={xl} xs={xs} title={title} className={className}>
        <HomeListPlaceholder
          isLoading={isLoading}
          emptyListMessage={emptyListMessage}
          emptyListImage={emptyListImage}
        />
      </HomeGrid>
    );
};

HomeList.propTypes = propTypes;
HomeList.defaultProps = defaultProps;

export default HomeList;
