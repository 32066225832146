import { BaseForm } from '@gupy/front-commons';

class JobTemplateForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default JobTemplateForm;
