import uuid from 'uuid/v4';
import { combineReducers } from 'redux';

import { createEmptySchema } from '@gupy/front-commons';
import {
  SAVE_CUSTOM_FORM_SCHEMA_SUCCESS,
  SAVE_CUSTOM_FORM_SCHEMA_FAILURE,
  GET_CUSTOM_FORM_LIST_SCHEMA_SUCCESS,
  GET_CUSTOM_FORM_LIST_SCHEMA,
} from './ActionTypes';

const emptyFormList = () => ({
  topic: null,
  name: uuid(),
  listSchemas: [],
  requiredSchemaIds: [],
});

export const questions = (state = createEmptySchema(), action) => {
  switch (action.type) {
  case GET_CUSTOM_FORM_LIST_SCHEMA:
    return createEmptySchema();
  case SAVE_CUSTOM_FORM_SCHEMA_SUCCESS:
  case GET_CUSTOM_FORM_LIST_SCHEMA_SUCCESS:
    return action.payload.listSchemas[0] || createEmptySchema();
  case SAVE_CUSTOM_FORM_SCHEMA_FAILURE:
    return {
      ...state,
    };
  default:
    return state;
  }
};

export const formList = (state = emptyFormList(), action) => {
  switch (action.type) {
  case GET_CUSTOM_FORM_LIST_SCHEMA:
    return emptyFormList();
  case SAVE_CUSTOM_FORM_SCHEMA_SUCCESS:
  case GET_CUSTOM_FORM_LIST_SCHEMA_SUCCESS:
    return action.payload;
  default:
    return state;
  }
};

export const getFormList = ({ reducers }) => reducers.CustomForms.formList;

export default combineReducers({ questions, formList });
