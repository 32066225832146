import { combineReducers } from 'redux';
import JobStepsActionTypes from '../../../../../constants/Job/JobStep/JobStepsActionTypes';
import RelocateCandidateActionTypes from './RelocateCandidateActionTypes';

export const hasDoneSearch = (state = false, action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.GET_AVAILABLE_JOBS:
    return false;
  case RelocateCandidateActionTypes.GET_AVAILABLE_JOBS_SUCCESS:
  case RelocateCandidateActionTypes.GET_AVAILABLE_JOBS_FAIL:
    return true;
  default:
    return state;
  }
};

export const availableJobSteps = (state = null, action) => {
  switch (action.type) {
  case JobStepsActionTypes.GET_SUCCESS:
    return action.steps;
  case JobStepsActionTypes.GET_FAIL:
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return {};
  default:
    return state;
  }
};

export const availableJobs = (state = [], action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.GET_AVAILABLE_JOBS_SUCCESS:
    return action.availableJobs;
  case RelocateCandidateActionTypes.GET_AVAILABLE_JOBS_FAIL:
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return [];
  default:
    return state;
  }
};

export const isModalOpen = (state = false, action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.OPEN_RELOCATE_CANDIDATE_MODAL:
    return true;
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return false;
  default:
    return state;
  }
};

export const isRelocateCandidateModalLoading = (state = false, action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES:
  case RelocateCandidateActionTypes.RELOCATE_SINGLE_CANDIDATE:
    return true;
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES_FAIL:
  case RelocateCandidateActionTypes.RELOCATE_SINGLE_CANDIDATE_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_SINGLE_CANDIDATE_FAIL:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_BY_TASK_MANAGER_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_FAIL:
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return false;
  default:
    return state;
  }
};

export const isRelocateCandidateModalSuccess = (state = false, action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_SUCCESS:
  case RelocateCandidateActionTypes.RELOCATE_SINGLE_CANDIDATE_SUCCESS:
    return true;
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return false;
  default:
    return state;
  }
};

export const isRelocateCandidateModalError = (state = false, action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.RELOCATE_SINGLE_CANDIDATE_FAIL:
  case RelocateCandidateActionTypes.RELOCATE_CANDIDATES_FAIL:
  case RelocateCandidateActionTypes.RELOCATE_ALL_CANDIDATES_FAIL:
    return true;
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return false;
  default:
    return state;
  }
};
export const candidatesToRelocate = (state = [], action) => {
  switch (action.type) {
  case RelocateCandidateActionTypes.OPEN_RELOCATE_CANDIDATE_MODAL:
    return action.selectedCandidates || [];
  case RelocateCandidateActionTypes.CLOSE_RELOCATE_CANDIDATE_MODAL:
    return [];
  default:
    return state;
  }
};

export default combineReducers({
  isModalOpen,
  hasDoneSearch,
  availableJobs,
  availableJobSteps,
  candidatesToRelocate,
  isRelocateCandidateModalError,
  isRelocateCandidateModalLoading,
  isRelocateCandidateModalSuccess,
});
