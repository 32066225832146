import { BaseForm } from '@gupy/front-commons';

class JobPicturesForm extends BaseForm {
  constructor() {
    super({
      videoUrl: {
        validate: {
          url: true,
        },
      },
    });
  }
}

export default JobPicturesForm;
