import JobPublicationType from '../../../constants/Job/JobPublicationType/JobPublicationType';

const adaptPublicationTypeFilter = (filters) => {
  let { publicationType } = filters;

  if (publicationType && publicationType.length) {
    const shouldAddAliases = publicationType.includes(JobPublicationType.unlisted)
      && !publicationType.includes(JobPublicationType.confidential);
    if (shouldAddAliases) {
      publicationType.push(JobPublicationType.confidential, JobPublicationType.internal);
    }
    const shouldRemoveAliases = !publicationType.includes(JobPublicationType.unlisted);
    if (shouldRemoveAliases) {
      publicationType = publicationType.filter(el => el !== JobPublicationType.confidential
        && el !== JobPublicationType.internal);
    }
  }

  return publicationType || [];
};

const adaptAdvancedSearchSummary = (summary, isAdvancedSearchDisabled) => {
  const { filter } = summary;
  const shouldReturnSummary = isAdvancedSearchDisabled
    || !filter
    || !(filter.locale || filter.publicationType);
  if (shouldReturnSummary) return summary;

  const adaptedFilters = {
    city: filter.locale,
    state: filter.locale,
  };

  const publicationType = adaptPublicationTypeFilter(summary.filter);
  delete filter.publicationType;
  if (publicationType.length) {
    adaptedFilters.publicationType = publicationType;
  }

  return {
    ...summary,
    filter: {
      ...summary.filter,
      ...adaptedFilters,
      search: summary.filter.search,
    },
  };
};

export { adaptAdvancedSearchSummary };
