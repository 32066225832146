import React from 'react';
import FooterWrapperStyles from './FooterWrapper.styles';

const FooterWrapper = ({
  data: {
    footerContent,
    footerCopyright,
    styleFooterComponent,
    styleFooterLinkDiv,
    styleFooterLink,
    styleCopyrightInfo,
    styleFooterLogo,
    styleFooterItens },
}) => {
  const { FooterComponent,
    FooterLinkDiv,
    FooterLink,
    CopyrightInfo,
    FooterGupyLogo,
    FooterItens,
  } = FooterWrapperStyles;

  return (
    styleFooterComponent ? (
      <FooterComponent {...styleFooterComponent} data-testid="footer-ecosystem">
        {footerContent && (
          <FooterItens {...styleFooterItens}>
            <div className="footerItemsLinks">
              {footerContent.map(item => (
                <FooterLinkDiv key={item.id} {...styleFooterLinkDiv}>
                  {item.link
                      && (
                        <FooterLink key={item.id} href={item.link} {...styleFooterLink}>
                          {item.title}
                        </FooterLink>
                      )}
                </FooterLinkDiv>

              ))}
            </div>
            <CopyrightInfo {...styleCopyrightInfo}>
              {footerCopyright && (footerCopyright.title)}
            </CopyrightInfo>

          </FooterItens>
        )}
        <FooterGupyLogo {...styleFooterLogo} src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/footer-icons/Logo.svg`} />
      </FooterComponent>
    ) : null
  );
};

export default FooterWrapper;
