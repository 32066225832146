import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('Department',
  'CHANGE_FORM',
  'CLEAR_FORM',
  'EDIT',
  asyncActionType('POST'),
  asyncActionType('PUT'),
  asyncActionType('DELETE'),
  asyncActionType('GET_ALL'),
  asyncActionType('GET_ALL_SIMILARITIES'));
