import { put, takeLatest, call } from 'redux-saga/effects';
import {
  getStepSuccess,
} from '../../../actions/Job/JobStep/JobStepsAction';

import history from '../../../history';
import JobStepsActionTypes from '../../../constants/Job/JobStep/JobStepsActionTypes';
import JobService from '../../../services/Job/JobService';

function* get(action) {
  try {
    const response = yield JobService.getJobSteps(action.id);

    yield put(getStepSuccess({ steps: response.body }));
  } catch (error) {
    yield put({
      type: JobStepsActionTypes.GET_FAIL,
    });
  }
}

function* del(action) {
  try {
    yield JobService.deleteStep(action.jobId, action.stepId);
    yield put({
      type: JobStepsActionTypes.DELETE_SUCCESS,
      step: { id: action.stepId },
    });
  } catch (error) {
    yield put({
      type: JobStepsActionTypes.DELETE_FAIL,
      error: { alert: error.body },
    });
  }
}

function* patch(action) {
  const { payload, path } = action;

  try {
    const response = yield JobService.patchSteps(payload);

    yield put({
      type: JobStepsActionTypes.PATCH_SUCCESS,
      jobSteps: response.body,
    });
    yield call(history.push, '/jobs', path);
  } catch (error) {
    yield put({
      type: JobStepsActionTypes.PATCH_FAIL,
    });
  }
}

function* patchJob(action) {
  const { modelForm } = action;

  try {
    const response = yield JobService.patch(modelForm);

    yield put({
      type: JobStepsActionTypes.PATCH_JOB_SUCCESS,
      modelForm: response.body,
    });
  } catch (error) {
    yield put({
      type: JobStepsActionTypes.PATCH_JOB_FAIL,
    });
  }
}

function* patchStepPosition({ jobId, steps }) {
  try {
    yield JobService.patchStepReOrder({ jobId, steps });

    yield put({
      type: JobStepsActionTypes.REORDER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: JobStepsActionTypes.REORDER_FAIL,
    });
  }
}

function* JobStepRegisterSaga() {
  yield takeLatest(JobStepsActionTypes.GET, get);
  yield takeLatest(JobStepsActionTypes.DELETE, del);
  yield takeLatest(JobStepsActionTypes.PATCH, patch);
  yield takeLatest(JobStepsActionTypes.CHANGE_FORM, patchJob);
  yield takeLatest(JobStepsActionTypes.REORDER, patchStepPosition);
}

export default JobStepRegisterSaga;
