import { Icon, IconType } from '@gupy/design-system-v2';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import SidebarMenuItemStyles from './SidebarMenuItem.styles';

const propTypes = {
  styles: PropTypes.object,
  itemTitle: PropTypes.string,
  linkUrl: PropTypes.string,
  linkLabel: PropTypes.string,
  icon: PropTypes.string,
  isSidebarExpanded: PropTypes.bool,
  expandableItem: PropTypes.bool,
  isSpaLink: PropTypes.bool,
};

const SidebarMenuItem = ({
  styles,
  itemTitle,
  linkUrl,
  linkLabel,
  icon,
  isSidebarExpanded,
  expandableItem,
  subContent,
  isSpaLink,
}) => {
  const { Item, ItemIcon, SubItem, LinkItem, LinkSubItem } =
    SidebarMenuItemStyles;
  const listSubItems = () =>
    subContent.map(subItem =>
      subItem.hasAccess && subItem.isSpaLink ? (
        <LinkSubItem to={subItem.linkUrl} {...styles}>
          {subItem.title}
        </LinkSubItem>
      ) : (
        <SubItem href={subItem.linkUrl} {...styles}>
          {subItem.title}
        </SubItem>
      ),
    );
  const [openSubItem, setOpenSubItem] = useState(false);

  if (expandableItem) {
    return (
      <Fragment>
        <Item
          onClick={() => setOpenSubItem(!openSubItem)}
          role="navigation"
          {...styles}
        >
          <ItemIcon src={icon} {...styles} />
          {isSidebarExpanded && itemTitle}
          {isSidebarExpanded && (
            <Icon
              type={openSubItem ? IconType.ExpandMore : IconType.ChevronRight}
            />
          )}
        </Item>
        {openSubItem && isSidebarExpanded && listSubItems()}
      </Fragment>
    );
  }

  if (isSpaLink) {
    return (
      <LinkItem
        to={linkUrl}
        aria-label={linkLabel}
        role="navigation"
        {...styles}
      >
        <ItemIcon src={icon} {...styles} />
        {isSidebarExpanded && itemTitle}
      </LinkItem>
    );
  }

  return (
    <Item href={linkUrl} aria-label={linkLabel} role="navigation" {...styles}>
      <ItemIcon src={icon} {...styles} />
      {isSidebarExpanded && itemTitle}
    </Item>
  );
};

SidebarMenuItem.propTypes = propTypes;

export default SidebarMenuItem;
