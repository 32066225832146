import { BaseForm } from '@gupy/front-commons';

class ShareForm extends BaseForm {
  constructor() {
    super({
      from: {
        validate: {
          notEmpty: true,
        },
      },
      destinations: {
        validate: {
          notEmpty: true,
        },
      },
      subject: {
        validate: {
          notEmpty: true,
        },
      },
      htmlMessage: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default ShareForm;
