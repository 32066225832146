import { buildToast, ToastTypes } from '@gupy/front-commons';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  openApproveJobModal,
  openDisapproveJobModal,
} from '../../containers/Job/JobSummary/Actions';
import history from '../../history';
import JobService from '../../services/Job/JobService';

import {
  getAllJobs as getAllJobsAction,
  getAllJobsSuccess,
  JobListActionTypes,
} from './Actions';

function* getAllJobs(action) {
  try {
    const { summary, adaptedSummary, isAdvancedSearchDisabled } = action;
    const [
      jobResponse,
      filtersResponse,
    ] = yield all([
      call(JobService.getAll, isAdvancedSearchDisabled ? summary : adaptedSummary),
      call(JobService.getJobsFilters),
    ]);
    yield put(getAllJobsSuccess(
      jobResponse.body,
      filtersResponse.body,
      summary.filter && summary.filter.publicationType,
      summary.filter && summary.filter.location,
    ));
  } catch (error) {
    yield put({
      type: JobListActionTypes.GET_ALL_FAIL,
      error,
    });
  }
}

function* pinJob(action) {
  const { jobId, summary, isAdvancedSearchDisabled } = action;
  try {
    yield JobService.pinJob({
      jobId,
    });
    yield put(getAllJobsAction(summary, isAdvancedSearchDisabled));
  } catch (error) {
    yield put({
      type: JobListActionTypes.PIN_FAIL,
    });
  }
}

function* unpinJob(action) {
  const { jobId, summary, isAdvancedSearchDisabled } = action;
  try {
    yield JobService.unpinJob(
      jobId,
    );
    yield put(getAllJobsAction(summary, isAdvancedSearchDisabled));
  } catch (error) {
    yield put({
      type: JobListActionTypes.UNPIN_FAIL,
    });
  }
}

function* deleteJob(action) {
  const { jobId, summary, jobPath, messages, isAdvancedSearchDisabled, payload } = action;
  try {
    const response = yield JobService.deleteJob(jobId, payload);
    yield put({
      type: JobListActionTypes.DELETE_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });

    if (summary) {
      yield put(getAllJobsAction(summary, isAdvancedSearchDisabled));
    }

    if (jobPath) {
      yield call(history.push, jobPath);
    }
  } catch (error) {
    yield put({
      type: JobListActionTypes.DELETE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* freezeJob(action) {
  const { summary, messages, isAdvancedSearchDisabled, payload } = action;
  const jobId = payload.id;

  try {
    const response = yield JobService.freezeJob(jobId);
    yield put({
      type: JobListActionTypes.FREEZE_JOB_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });

    if (summary) {
      yield put(getAllJobsAction(summary, isAdvancedSearchDisabled));
    }
  } catch (error) {
    yield put({
      type: JobListActionTypes.FREEZE_JOB_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* duplicateJob(action) {
  const { jobId, jobPath, messages } = action;
  try {
    const response = yield JobService.duplicateJob(jobId);
    yield put({
      type: JobListActionTypes.DUPLICATE_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });
    yield call(history.push, jobPath.replace(':id', response.body.id));
  } catch (error) {
    yield put({
      type: JobListActionTypes.DUPLICATE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* duplicateJobAsTemplate(action) {
  const { jobId, messages } = action;
  const fromTemplate = false;
  const asTemplate = true;
  try {
    const response = yield JobService.duplicateJob(jobId, fromTemplate, asTemplate);
    yield put({
      type: JobListActionTypes.DUPLICATE_AS_TEMPLATE_SUCCESS,
      modelForm: response.body,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: JobListActionTypes.DUPLICATE_AS_TEMPLATE_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* patchJobStatus(action) {
  const { payload, summary, jobPath, messages, isAdvancedSearchDisabled } = action;
  try {
    yield JobService.patch(payload);
    yield put({
      type: JobListActionTypes.PATCH_JOB_STATUS_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });

    if (summary) {
      yield put(getAllJobsAction(summary, isAdvancedSearchDisabled));
    }

    if (jobPath) {
      yield call(history.push, jobPath);
    }
  } catch (error) {
    yield put({
      type: JobListActionTypes.PATCH_JOB_STATUS_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* getJobToApprove({ payload }) {
  try {
    const res = yield call(JobService.get, payload);

    yield put(openApproveJobModal(res.body));
  } catch (error) {
    yield put({
      type: JobListActionTypes.GET_JOB_TO_APPROVE_FAIL,
      error,
    });
  }
}

function* getJobToDisapprove({ payload }) {
  try {
    const res = yield call(JobService.get, payload);

    yield put(openDisapproveJobModal(res.body));
  } catch (error) {
    yield put({
      type: JobListActionTypes.GET_JOB_TO_DISAPPROVE_FAIL,
      error,
    });
  }
}

function* cancelJob({ payload }) {
  try {
    const { jobId, messages, data, summary, isAdvancedSearchDisabled } = payload;
    yield call(JobService.cancelJob, jobId, data);
    yield put(getAllJobsAction(summary, isAdvancedSearchDisabled));
    yield put({
      type: JobListActionTypes.CANCEL_JOB_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      error,
      type: JobListActionTypes.CANCEL_JOB_FAIL,
      toast: buildToast(payload.messages.error, ToastTypes.error),
    });
  }
}

function* JobListSaga() {
  yield takeLatest(JobListActionTypes.PIN, pinJob);
  yield takeLatest(JobListActionTypes.UNPIN, unpinJob);
  yield takeLatest(JobListActionTypes.DELETE, deleteJob);
  yield takeLatest(JobListActionTypes.DUPLICATE, duplicateJob);
  yield takeLatest(JobListActionTypes.DUPLICATE_AS_TEMPLATE, duplicateJobAsTemplate);
  yield takeLatest(JobListActionTypes.PATCH_JOB_STATUS, patchJobStatus);
  yield takeLatest(JobListActionTypes.FREEZE_JOB, freezeJob);
  yield takeLatest(JobListActionTypes.GET_ALL, getAllJobs);
  yield takeLatest(JobListActionTypes.GET_JOB_TO_APPROVE, getJobToApprove);
  yield takeLatest(JobListActionTypes.GET_JOB_TO_DISAPPROVE, getJobToDisapprove);
  yield takeLatest(JobListActionTypes.CANCEL_JOB, cancelJob);
}

export {
  getAllJobs,
  getJobToApprove,
  getJobToDisapprove,
  cancelJob,
  freezeJob,
};
export default JobListSaga;
