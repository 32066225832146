import Cookies from 'universal-cookie';
import moment from 'moment';

import CookieHelper from '@gupy/front-helpers/src/helpers/CookieHelper';
import CookieConstants from '../constants/CookieConstants';

class CookieManager {
  constructor() {
    this.cookies = new Cookies();
    const domain = process.env.REACT_APP_COOKIE_DOMAIN;

    this.defaultConfig = {
      path: '/',
      expires: moment().add(1, 'years').toDate(),
      secure: CookieHelper.shouldUseSecureCookie(),
    };
    this.localeConfig = {
      path: '/',
      expires: moment().add(5, 'years').toDate(),
      secure: CookieHelper.shouldUseSecureCookie(),
    };
    this.lastAuthSubdomainConfig = {
      domain,
      path: '/',
      expires: moment().add(4, 'hours').toDate(),
      secure: CookieHelper.shouldUseSecureCookie(),
    };
    this.redirectUrlConfig = {
      path: '/',
      expires: moment().add(30, 'minutes').toDate(),
      secure: CookieHelper.shouldUseSecureCookie(),
    };
    this.exchangeTokenErrorConfig = {
      path: '/',
      expires: moment().add(30, 'minutes').toDate(),
      secure: CookieHelper.shouldUseSecureCookie(),
    };
  }

  setTopLevelUserSubdomain(subdomain) {
    const cookieConfig = { ...this.defaultConfig, domain: 'gupy.io' };
    this.cookies.set(
      CookieConstants.userSubdomain,
      subdomain,
      cookieConfig,
    );
  }

  setLastAuthSubdomain(subdomain) {
    this.cookies.set(
      CookieConstants.lastAuthSubdomain,
      subdomain,
      this.lastAuthSubdomainConfig,
    );
  }

  clearLastAuthSubdomain() {
    this.cookies.remove(
      CookieConstants.lastAuthSubdomain,
      this.lastAuthSubdomainConfig,
    );
  }

  getLastAuthSubdomain() {
    return this.cookies.get(CookieConstants.lastAuthSubdomain);
  }

  hasLocale() {
    return !!this.getLocale();
  }

  getLocale() {
    return this.cookies.get(CookieConstants.locale);
  }

  getCompanyUserToken() {
    return this.cookies.get(CookieConstants.companyUserToken);
  }

  setLocale(locale) {
    this.cookies.set(
      CookieConstants.locale,
      locale,
      this.localeConfig,
    );
  }

  clearLocale() {
    this.cookies.remove(
      CookieConstants.locale,
      this.localeConfig,
    );
  }

  clearAllCookies() {
    this.clearLastAuthSubdomain();
    this.clearSecondaryToken();
  }

  hasRedirectUrl() {
    return !!this.getRedirectUrl();
  }

  getRedirectUrl() {
    return this.cookies.get(CookieConstants.redirectUrl);
  }

  getExchangeTokenError() {
    return this.cookies.get(CookieConstants.exchangeTokenError);
  }

  setRedirectUrl(url) {
    this.cookies.set(
      CookieConstants.redirectUrl,
      url,
      this.redirectUrlConfig,
    );
  }

  clearRedirectUrl() {
    this.cookies.remove(
      CookieConstants.redirectUrl,
      this.redirectUrlConfig,
    );
  }

  setSecondaryToken(secondaryToken) {
    this.cookies.set(
      CookieConstants.secondaryToken,
      secondaryToken,
      this.defaultConfig,
    );
  }

  clearSecondaryToken() {
    this.cookies.remove(
      CookieConstants.secondaryToken,
      this.defaultConfig,
    );
  }

  setExchangeTokenError(error) {
    this.cookies.set(
      CookieConstants.exchangeTokenError,
      error,
      this.exchangeTokenErrorConfig,
    );
  }

  clearExchangeTokenError() {
    this.cookies.remove(
      CookieConstants.exchangeTokenError,
      this.exchangeTokenErrorConfig,
    );
  }
}

export default CookieManager;
