import { combineReducers } from 'redux';
import { CandidateInsertionActionTypes } from './CandidateInsertionAction';

const initialModelForm = {
  currentStepId: undefined,
  email: '',
  lastName: '',
  linkedinProfileUrl: undefined,
  mobileNumber: undefined,
  name: '',
  candidateInsertionSource: undefined,
  comment: undefined,
  isPrivate: false,

  applicationId: undefined,
  candidateId: undefined,
  fullName: undefined,
  isCandidateOnCurrentJob: false,
  isCandidateOnCurrentCompany: false,
  isNewCandidate: false,
  shouldSendEmailInvitation: false,
};

export const isCandidateInsertionModalOpen = (state = false, action) => {
  switch (action.type) {
  case CandidateInsertionActionTypes.OPEN_CANDIDATE_INSERTION_MODAL:
    return true;
  case CandidateInsertionActionTypes.CLOSE_CANDIDATE_INSERTION_MODAL:
    return false;
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case CandidateInsertionActionTypes.ADD_CANDIDATE:
  case CandidateInsertionActionTypes.RELOCATE_CANDIDATE:
  case CandidateInsertionActionTypes.VERIFY_EMAIL:
    return true;
  case CandidateInsertionActionTypes.ADD_CANDIDATE_SUCCESS:
  case CandidateInsertionActionTypes.ADD_CANDIDATE_FAIL:
  case CandidateInsertionActionTypes.RELOCATE_CANDIDATE_SUCCESS:
  case CandidateInsertionActionTypes.RELOCATE_CANDIDATE_FAIL:
  case CandidateInsertionActionTypes.VERIFY_EMAIL_SUCCESS:
  case CandidateInsertionActionTypes.VERIFY_EMAIL_FAIL:
    return false;
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case CandidateInsertionActionTypes.VERIFY_EMAIL_SUCCESS:
    return {
      ...state,
      ...action,
    };
  case CandidateInsertionActionTypes.OPEN_CANDIDATE_INSERTION_MODAL:
    return {
      ...initialModelForm,
      currentStepId: action.registerStepId,
    };
  case CandidateInsertionActionTypes.CLOSE_CANDIDATE_INSERTION_MODAL:
  case CandidateInsertionActionTypes.RESET_FORM:
    return initialModelForm;
  case CandidateInsertionActionTypes.CHANGE_FORM:
    return {
      ...state,
      ...action.modelForm,
    };
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case CandidateInsertionActionTypes.OPEN_CANDIDATE_INSERTION_MODAL:
  case CandidateInsertionActionTypes.VERIFY_EMAIL:
  case CandidateInsertionActionTypes.ADD_CANDIDATE:
    return {};
  case CandidateInsertionActionTypes.ADD_CANDIDATE_FAIL:
  case CandidateInsertionActionTypes.VERIFY_EMAIL_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const candidateInsertionSources = (state = [], action) => {
  switch (action.type) {
  case CandidateInsertionActionTypes.CANDIDATE_INSERTION_SOURCES_SUCCESS:
    return action.payload;
  case CandidateInsertionActionTypes.CANDIDATE_INSERTION_SOURCES_FAIL:
    return [];
  default:
    return state;
  }
};

export default combineReducers({
  isCandidateInsertionModalOpen,
  isSaving,
  modelForm,
  validation,
  candidateInsertionSources,
});
