import moment from 'moment';
import locale from 'locale';

import { commonsPt, commonsEn, commonsEs } from '@gupy/front-commons';

import pt from './pt.json';
import es from './es.json';
import en from './en.json';

import CookieManager from '../managers/CookieManager';

const Globalization = (defaultLanguage, language) => {
  const cookieManager = new CookieManager();

  const supportedLanguageAcronymList = ['es', 'pt', 'en'];
  const defaultLanguageAcronym = defaultLanguage || 'en';
  const cookieLanguageAcronym = cookieManager.getLocale();

  const messages = {
    pt: { ...commonsPt, ...pt },
    es: { ...commonsEs, ...es },
    en: { ...commonsEn, ...en },
  };

  let userLanguageAcronym;

  if (supportedLanguageAcronymList.includes(language)) {
    userLanguageAcronym = language;
    cookieManager.setLocale(language);
  } else if (cookieLanguageAcronym) {
    userLanguageAcronym = cookieLanguageAcronym;
  } else {
    const { languages } = navigator;
    userLanguageAcronym = languages && languages.length
      ? languages.join(',')
      : defaultLanguageAcronym;
  }

  const locales = new locale.Locales(userLanguageAcronym, defaultLanguageAcronym);
  const supported = new locale.Locales(supportedLanguageAcronymList);
  const definedLanguage = locales.best(supported).language;
  const htmlElement = document.querySelector('html');

  htmlElement.setAttribute('lang', language === 'pt' ? 'pt-br' : language);
  moment.locale(definedLanguage === 'pt' ? 'pt-br' : definedLanguage);

  return {
    locale: definedLanguage,
    messages: messages[definedLanguage],
  };
};

export default Globalization;
