import classNames from 'classnames';
import { Button, Icon, IconEnum, Drawer } from '@gupy/design-system';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import UserNotificationsContainerV2 from '../../../Notifications/UserNotificationsContainerV2';

import './SecondaryMenu.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  messages: PropTypes.object.isRequired,
  model: PropTypes.object.isRequired,
  mustSaveAndClose: PropTypes.bool,
  onExitClick: PropTypes.func,
  menuContent: PropTypes.element,
  isDisabled: PropTypes.bool,
};

const SecondaryMenu = ({
  children,
  messages,
  model,
  mustSaveAndClose,
  onExitClick,
  menuContent,
  isDisabled,
}) => {
  const [isDrawerOpen, updateIsDrawerOpen] = useState(false);

  const hasCloseButton = onExitClick && messages.menuClose;
  const closeMessage = (model.id && mustSaveAndClose)
    ? messages.menuCloseAndSave : messages.menuClose;

  const onMenuClick = () => {
    if (isDrawerOpen) {
      updateIsDrawerOpen(false);
    } else {
      updateIsDrawerOpen(true);
    }
  };

  return (
    <header className="new-secondary-menu">
      <div className="new-secondary-menu__wrapper">
        <Link
          aria-label={messages.menuHomeLink}
          className="new-secondary-menu__link"
          to="/"
        >
          <img
            alt=""
            className="new-secondary-menu__brand"
            src={`${process.env.REACT_APP_ASSETS_URL}/logo-new.svg`}
          />
        </Link>
        {children}
      </div>
      <div className="new-secondary-menu__wrapper">
        <UserNotificationsContainerV2 />
        {menuContent ? (
          <button
            className="new-secondary-menu__btn new-secondary-menu__btn--mobile"
            onClick={onMenuClick}
            aria-label={messages.accessNavMenu}
          >
            <Icon icon={IconEnum.Menu}/>
          </button>

        ) : null}
        {hasCloseButton ? (
          <button
            className={classNames(
              'new-secondary-menu__btn',
              { 'new-secondary-menu__btn--desktop': menuContent },
              { 'new-secondary-menu__btn--disabled': isDisabled },
            )}
            onClick={onExitClick}
          >
            {closeMessage}
          </button>
        ) : null}
      </div>
      {menuContent ? (
        <Drawer
          open={isDrawerOpen}
          onClose={onMenuClick}
          body={menuContent}
          footer={hasCloseButton ? (
            <div className="new-secondary-menu__drawer-footer">
              <Button type="text" onClick={onExitClick}>
                {closeMessage}
              </Button>
            </div>
          ) : null}
        />
      ) : null}
    </header>
  );
};

SecondaryMenu.propTypes = propTypes;

export default SecondaryMenu;
