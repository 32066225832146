import React, { lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';

import { Spinner } from '@gupy/design-system';
import { useFlagsStatus, useFlag } from '@unleash/proxy-client-react';

import permissionsEnum from './Authentication/PermissionsEnum';
import PrivateRouteLegacy from './Authentication/PrivateRoute';
import IDPPrivateRoute from './Authentication/IDPPrivateRoute';
import FeaturesFlagsEnum from './Authentication/FeaturesFlagsEnum';

import MainLayout from './App/components/layouts/MainLayout';
import SetupLayout from './App/components/layouts/SetupLayout';
import AuthenticationLayout from './App/components/layouts/AuthenticationLayout';
import SecondaryLayout from './App/components/layouts/SecondaryLayout';
import SimpleLayout from './App/components/layouts/SimpleLayout';
import Route from './App/components/Route';
import IDPProvider from './IDPProvider';

import './Routes.scss';

const AttachmentsContainer = lazy(() => import('./Attachments/AttachmentsContainer'));
const Auth = lazy(() => import('./Authentication/Auth'));

const Signin = lazy(() => import('./Authentication/Signin'));
const IDPSignIn = lazy(() => import('./Authentication/IDPSignIn'));
const Signout = lazy(() => import('./Authentication/Signout'));
const IDPSignout = lazy(() => import('./Authentication/IDPSignout'));
const SetPassword = lazy(() => import('./Authentication/SetPassword'));
const ForgotPassword = lazy(() => import('./Authentication/ForgotPassword'));
const AuthSocialCallback = lazy(() => import('./Authentication/AuthSocialCallback'));
const ConfirmIndicationContainer = lazy(() => import('./ConfirmIndication/ConfirmIndicationContainer'));
const Home = lazy(() => import('./Home/Home'));
const UserProfile = lazy(() => import('./UserProfile/UserProfile'));
const ProfileTest = lazy(() => import('./ProfileTest/ProfileTest'));
const CompanyUserProfileTestResult = lazy(() => import('./ProfileTest/CompanyUserProfileTestResult'));
const CompanyTestResultAverage = lazy(() => import('./ProfileTest/CompanyTestResultAverage'));
const CulturalFitMapping = lazy(() => import('./CulturalFitMapping'));
const ProfileTestCallback = lazy(() => import('./ProfileTest/ProfileTestCallback'));
const Error403 = lazy(() => import('./Error/403'));
const Error404 = lazy(() => import('./Error/404'));
const Error500 = lazy(() => import('./Error/500'));
const Profile = lazy(() => import('./Profile/Profile'));
const CareerPagesList = lazy(() => import('./CareerPage/Container'));
const CareerPagesFormSections = lazy(() => import('./CareerPage/CareerPageForm/Container'));
const CareerPagesForm = lazy(() => import('./CareerPage/components/form/GenericForm'));
const SocialMedias = lazy(() => import('./SocialMedias/Container'));
const Role = lazy(() => import('./Role/Role'));
const Subsidiary = lazy(() => import('./Subsidiary/Subsidiary'));
const Department = lazy(() => import('./Department/Department'));
const User = lazy(() => import('./User/User'));
const AdmissionSetup = lazy(() => import('./AdmissionSetup/Container'));

const JobList = lazy(() => import('../pages/JobList/JobList'));
const JobShare = lazy(() => import('../pages/JobShare/JobShare'));
const JobDetails = lazy(() => import('./Job/JobDetails'));
const JobWorkplaceOld = lazy(() => import('./Job/JobWorkplace'));
const JobWorkplace = lazy(() => import('./Job/JobWorkplace/JobWorkplace'));
const JobInternalData = lazy(() => import('./Job/JobInternalData/JobInternalData'));
const JobRequisition = lazy(() => import('./Job/JobRequisition/JobRequisition'));
const JobRequisitionSimplified = lazy(() => import('./Job/JobRequisitionSimplified/JobRequisitionSimplified'));
const JobDescriptionContainer = lazy(() => import('./Job/JobDescription/JobDescriptionContainer'));
const JobStepsList = lazy(() => import('./Job/JobStep/JobStepsList'));
const JobCandidatesClassifiers = lazy(() => import('./Job/JobCandidatesClassifiers/JobCandidatesClassifiers'));
const JobNotFound = lazy(() => import('./Job/JobNotFound/JobNotFound'));
const JobTalentRecommended = lazy(() => import('./Job/JobTalentRecommended/JobTalentRecommended'));
const JobStepRegister = lazy(() => import('./Job/JobStep/JobStepRegister'));
const JobStepCustom = lazy(() => import('./Job/JobStep/JobStepCustom/JobStepCustom'));
const JobStepFinal = lazy(() => import('./Job/JobStep/JobStepFinal'));
const JobRatingCriterias = lazy(() => import('./Job/JobRatingCriterias/JobRatingCriterias'));
const JobTaxonomySkills = lazy(() => import('./Job/JobTaxonomySkills/JobTaxonomySkills'));
const JobCulturalValues = lazy(() => import('./Job/JobCulturalValues/JobCulturalValues'));
const JobPictures = lazy(() => import('./Job/JobPictures/JobPictures'));
const JobPublicationType = lazy(() => import('./Job/JobPublicationType/JobPublicationType'));

const ExperimentalJobApplication = lazy(() => import('./Job/JobApplication/EasyApplyExperiment/ExperimentalJobApplication'));
const JobApplication = lazy(() => import('./Job/JobApplication/JobApplication'));
const SelectionProcessRedesign = lazy(() => import('../pages/SelectionProcess/SelectionProcess'));
const JobSummaryContainer = lazy(() => import('./Job/JobSummary/JobSummaryContainer'));
const ExperimentalJobSummaryContainer = lazy(() => import('./Job/EasyApplyExperiment/JobSumary/ExperimentalJobSummaryContainer'));

const JobApplicationCandidate = lazy(() => import('./Job/JobApplication/JobApplicationCandidate'));
const ExperimentalJobApplicationCandidate = lazy(() => import('./Job/JobApplication/EasyApplyExperiment/ExperimentalJobApplicationCandidate'));
const JobApplicationCandidateComponents = lazy(() => import('./Job/JobApplication/JobApplicationCandidateComponents'));

const TalentPool = lazy(() => import('./TalentPool/Container'));
const RecommendationTest = lazy(() => import('./TalentPool/RecommendationTest/RecommendationTest'));
const JobTemplatesContainer = lazy(() => import('./JobTemplates/JobTemplatesContainer'));
const UserAccessProfileContainer = lazy(() => import('./UserAccessProfile/UserAccessProfileContainer'));
const UserAccessProfileFormContainer = lazy(() => import('./UserAccessProfile/UserAccessProfileFormContainer'));
const WhatsAppBusinessContainer = lazy(() => import('./WhatsappBusinessTemplate/Container'));
const ApiAccessPolicyList = lazy(() => import('./ApiAccessPolicy/ApiAccessPolicyContainer'));
const ApiAccessPolicyCreate = lazy(() => import('./ApiAccessPolicy/ApiAccessPolicyCreateContainer'));

const EmailTemplateListContainer = lazy(() => import('./EmailTemplate/EmailTemplateListContainer'));
const EmailTemplateForm = lazy(() => import('./EmailTemplate/EmailTemplateForm'));
const EmailTemplateContainer = lazy(() => import('./EmailTemplate/EmailTemplateContainer'));

const JobOfferTemplateListContainer = lazy(() => import('./JobOfferTemplate/JobOfferTemplateListContainer'));
const JobOfferTemplateForm = lazy(() => import('./JobOfferTemplate/JobOfferTemplateForm'));
const JobOfferTemplateContainer = lazy(() => import('./JobOfferTemplate/JobOfferTemplateContainer'));

const JobOverviewContainer = lazy(() => import('./Job/JobOverview/JobOverviewContainer'));
const Integrations = lazy(() => import('./Integrations/Integrations'));
const RscLinkedinIntegrationIframe = lazy(() => import('./Integrations/components/RscLinkedinIntegration/RscLinkedinIntegrationIframe'));
const MarketplaceApps = lazy(() => import('./MarketplaceApps/MarketplaceApps'));
const MarketplaceAppDetails = lazy(() => import('./MarketplaceApps/MarketplaceAppDetails'));
const InterviewScriptListContainerV2 = lazy(() => import('./InterviewScripts/InterviewScriptListContainer'));
const TestSelection = lazy(() => import('../containers/TestSelection/TestSelection'));
const SetupOrganizationalStructureContainer = lazy(() => import('./SetupOrganizationalStructure/Container'));
const ApprovalWorkflowContainer = lazy(() => import('./ApprovalWorkflow/ApprovalWorkflowContainer'));
const ApprovalWorkflowFormContainer = lazy(() => import('./ApprovalWorkflow/ApprovalWorkflowFormContainer'));
const WorkflowListContainer = lazy(() => import('./Workflow/WorkflowList/WorkflowListContainer'));
const WorkflowFormContainer = lazy(() => import('./Workflow/WorkflowForm/WorkflowFormContainer'));
const RootSetupContainer = lazy(() => import('./RootSetup/RootSetupContainer'));
const JobCustomFormTemplateContainer = lazy(() => import('./JobCustomFormTemplate/Container'));
const TaskContainer = lazy(() => import('./Task/TaskContainer'));
const PreHireStepContainer = lazy(() => import('./PreHireStep/Container'));
const EmailDomainsContainer = lazy(() => import('./EmailDomains/EmailDomainsContainer'));
const SetupHomePageContainer = lazy(() => import('./SetupHomePage/Container'));
const WhatsAppSetupContainer = lazy(() => import('./WhatsAppSetup/Container'));
const CompanyEmployee = lazy(() => import('./CompanyEmployee/Container'));
const CurriculumDownloadContainer = lazy(() => import('./CurriculumDownload/Container'));
const AnalyticsRedirect = lazy(() => import('./Analytics/Redirect'));
const CustomTestPreview = lazy(() => import('./CustomTests/CustomTestsPreview'));
const SetupEngageSurveyContainer = lazy(() => import('./SetupEngageSurvey/SetupEngageSurveyContainer'));

const Community = lazy(() => import('./Community'));

const AdmissionForm = lazy(() => import('./Admission'));

const Questions = lazy(() => import('../external/CustomTest/containers/Questions/Questions'));
const TestsCorrection = lazy(() => import('../external/CustomTest/containers/TestsCorrection/TestsCorrection'));
const TestsCreate = lazy(() => import('../external/CustomTest/containers/TestsCreate/TestsCreate'));
const TestEdit = lazy(() => import('../external/CustomTest/containers/TestEdit/TestEdit'));

const CompanyPlanInformation = lazy(() => import('./CompanyPlanInformation/CompanyPlanInformationContainer'));

const CompaniesRoutesSpinner = () => (
  <div className="companies-routes-spinner">
    <Spinner size={60} />
  </div>
);

const propTypes = {
  featureFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Routes = ({ featureFlags }) => {
  const isAdmissionOnly = featureFlags.includes(FeaturesFlagsEnum.admissionOnly);
  const rootPathRedirect = isAdmissionOnly ? '/admission/companies' : '/companies';

  const isLegacySignOn = useFlag(FeaturesFlagsEnum.legacySignOn);
  const isEmailTemplateTabsEnabled = useFlag(FeaturesFlagsEnum.emailTemplateTabs);
  const isJobOfferTemplateTabsEnabled = useFlag(FeaturesFlagsEnum.jobOffer);
  const isCareerPageSectionsEnabled = useFlag(FeaturesFlagsEnum.careerPageSections);
  const isOldAddressXp = useFlag(FeaturesFlagsEnum.oldAddressXp);
  const isApprovalWorkflowsEnabled = useFlag(FeaturesFlagsEnum.approvalWorkflows);
  const isWorkflowEnabled = useFlag(FeaturesFlagsEnum.workflow);
  const isWorkflowSimplifiedEnabled = useFlag(FeaturesFlagsEnum.workflowSimplified);
  const isWorkflowSimplified = isWorkflowSimplifiedEnabled
    && !isWorkflowEnabled && !isApprovalWorkflowsEnabled;
  const isTalentRecommendationEnabled = useFlag(FeaturesFlagsEnum.showInboundTalentRecommendation);
  const isjobOfferEnabled = useFlag(FeaturesFlagsEnum.jobOffer);
  const isCulturalFitMappingEnable = useFlag(FeaturesFlagsEnum.culturalFitMapping);
  const isSkillsTaxonomyEnabled = useFlag(FeaturesFlagsEnum.skillsTaxonomy);
  const isRepackagingEnabled = useFlag(FeaturesFlagsEnum.repackaging);
  const isGenAiInterviewScriptEnabled = useFlag(FeaturesFlagsEnum.genAiInterviewScript);
  const isOrganizationalStructureEnabled = useFlag(FeaturesFlagsEnum.companyBranch);
  const isInternalRecruitmentEnabled = useFlag(FeaturesFlagsEnum.internalRecruitment);
  const isCompanyPlanInformationEnabled = useFlag(FeaturesFlagsEnum.planInformation);

  const redesignSelecao = useFlag(FeaturesFlagsEnum.redesignSelecao);
  const { flagsReady } = useFlagsStatus();

  if (!flagsReady) {
    return <CompaniesRoutesSpinner />;
  }

  const isSSOEnabled = !isLegacySignOn;
  const PrivateRoute = isSSOEnabled ? IDPPrivateRoute : PrivateRouteLegacy;

  return (
    <IDPProvider>
      <Suspense fallback={CompaniesRoutesSpinner}>
        <Switch>
          <Redirect exact path="/" to={rootPathRedirect} />

          {/* AUTH */}
          <Route path="/companies/auth/callback" component={AuthSocialCallback} />
          <Route path="/companies/auth" component={Auth} />
          {
            isSSOEnabled
              ? <Route path="/companies/signin" component={IDPSignIn} />
              : <Route path="/companies/signin" layout={AuthenticationLayout} component={Signin} />
          }

          {
            isSSOEnabled
              ? <Route path="/companies/signout" component={IDPSignout} />
              : <Route path="/companies/signout" component={Signout} />
          }

          <Route path="/companies/forgot-password" layout={AuthenticationLayout} component={ForgotPassword} />
          <Route path="/companies/define-password/:token" layout={AuthenticationLayout} component={SetPassword} />

          {/* CONFIRM INDICATION */}
          <Route path="/companies/confirm-indication/:token" component={ConfirmIndicationContainer} />

          {/* PROFILE */}
          <PrivateRoute layout={MainLayout} exact path="/companies" component={isAdmissionOnly ? CompaniesRoutesSpinner : Home} />
          <PrivateRoute layout={MainLayout} path="/companies/profile" component={UserProfile} />
          <PrivateRoute layout={MainLayout} exact path="/companies/user-profile-test" component={CompanyUserProfileTestResult} />
          <PrivateRoute layout={MainLayout} exact path="/companies/profile-test" component={ProfileTest} />
          <PrivateRoute layout={MainLayout} path="/companies/profile-test/:testProviderId/:testCode/callback" component={ProfileTestCallback} />

          {/* Talent pool */}
          <Route layout={SimpleLayout} exact path="/companies/talent-pool/communication/unsubscribe" component={TalentPool} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_talent_pool]} path="/companies/talent-pool" component={TalentPool} />
          <PrivateRoute layout={MainLayout} path="/companies/recommendation-test" component={RecommendationTest} />

          {/* Task */}
          <PrivateRoute layout={MainLayout} path="/companies/task" component={TaskContainer} />

          {/* Attachments */}
          <PrivateRoute exact path="/companies/communication/attachments/:hash" routeName="Attachment" component={AttachmentsContainer} />

          {/* Analytics */}
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_analytics]} path="/companies/jobs/dashboard" component={AnalyticsRedirect} />

          {/* Setup */}
          <PrivateRoute layout={MainLayout} exact path="/companies/setup/" component={SetupHomePageContainer} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.edit_company_profile]} path="/companies/setup/profile" component={Profile} />
          <Redirect exact path="/companies/setup/careers" to="/companies/setup/career-pages" />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.edit_company_career_page]} exact path="/companies/setup/career-pages" component={CareerPagesList} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.edit_company_career_page]} exact path="/companies/setup/career-pages/new" component={isCareerPageSectionsEnabled ? CareerPagesFormSections : CareerPagesForm} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.edit_company_career_page]} exact path="/companies/setup/career-pages/edit/:id" component={props => (isCareerPageSectionsEnabled ? <CareerPagesFormSections {...props} /> : <CareerPagesForm isEditMode {...props} />)} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.edit_company_social_media]} path="/companies/setup/social-medias" component={SocialMedias} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_department]} path="/companies/setup/departments" component={Department} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_role]} path="/companies/setup/roles" component={Role} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_subsidiary]} path="/companies/setup/subsidiaries" component={Subsidiary} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_company_user]} path="/companies/setup/users" component={User} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_job_template]} path="/companies/setup/templates" component={JobTemplatesContainer} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.view_whatsapp_business_template]} exact path="/companies/setup/whatsapp-business-template" component={WhatsAppBusinessContainer} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.create_public_api_access_token]} exact path="/companies/setup/api-access-policies" component={ApiAccessPolicyList} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.create_public_api_access_token]} exact path="/companies/setup/api-access-policies/new" component={ApiAccessPolicyCreate} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_email_template, permissionsEnum.view_public_email_template]} exact path="/companies/setup/email-template" component={EmailTemplateListContainer} />
          <PrivateRoute
            layout={MainLayout}
            renderPermissions={[
              permissionsEnum.create_email_template,
              permissionsEnum.create_public_email_template,
            ]}
            exact
            path="/companies/setup/email-template/new"
            component={EmailTemplateForm}
          />
          <PrivateRoute
            layout={MainLayout}
            renderPermissions={[
              permissionsEnum.edit_email_template,
              permissionsEnum.edit_public_email_template,
            ]}
            exact
            path="/companies/setup/email-template/:id"
            component={
              isEmailTemplateTabsEnabled
                ? EmailTemplateContainer
                : EmailTemplateForm
            }
          />

          <PrivateRoute
            layout={SetupLayout}
            path="/companies/setup/engage-survey"
            component={SetupEngageSurveyContainer}
            exact
          />

          {/* CARTA OFERTA */}
          {(isjobOfferEnabled || isRepackagingEnabled)
            && (
              <PrivateRoute
                layout={MainLayout}
                renderPermissions={[permissionsEnum.view_job_offer_template]}
                exact
                path="/companies/setup/job-offer-template"
                component={JobOfferTemplateListContainer}
              />
            )}
          {isjobOfferEnabled && (
            <PrivateRoute
              layout={MainLayout}
              renderPermissions={[permissionsEnum.view_job_offer_template]}
              exact
              path="/companies/setup/job-offer-template/new"
              component={JobOfferTemplateForm}
            />
          )}
          {isjobOfferEnabled && (
            <PrivateRoute
              layout={MainLayout}
              renderPermissions={[permissionsEnum.view_job_offer_template]}
              exact
              path="/companies/setup/job-offer-template/:id"
              component={
                isJobOfferTemplateTabsEnabled
                  ? JobOfferTemplateContainer
                  : JobOfferTemplateForm
              }
            />
          )}

          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.view_company_user_access_profile]} exact path="/companies/setup/user-access-profile" component={UserAccessProfileContainer} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.create_company_user_access_profile]} exact path="/companies/setup/user-access-profile/new" component={UserAccessProfileFormContainer} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.edit_company_user_access_profile]} exact path="/companies/setup/user-access-profile/:id" component={UserAccessProfileFormContainer} />
          <PrivateRoute renderPermissions={[permissionsEnum.view_company_integrations, permissionsEnum.edit_saml]} exact path="/companies/setup/integrations/rsc-linkedin-iframe/child-app/:childAppId/client/:clientId" component={RscLinkedinIntegrationIframe} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_company_integrations, permissionsEnum.edit_saml]} exact path="/companies/setup/integrations/:screen" component={Integrations} />
          <PrivateRoute layout={MainLayout} exact path="/companies/setup/marketplace-apps" component={MarketplaceApps} />
          <PrivateRoute layout={MainLayout} exact path="/companies/setup/marketplace-apps/:appId" component={MarketplaceAppDetails} />

          {!isGenAiInterviewScriptEnabled && (
            <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_interview_scripts]} exact path="/companies/setup/interview-scripts" component={InterviewScriptListContainerV2} />
          )}

          <PrivateRoute layout={MainLayout} exact path="/companies/setup/tests" component={TestSelection} />
          {(isOrganizationalStructureEnabled || isRepackagingEnabled) && <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_organizational_structure]} exact path="/companies/setup/organizational-structure" component={SetupOrganizationalStructureContainer} />}

          {/* Approval workflow */}
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_approval_workflows]} exact path="/companies/setup/approval-workflows" component={ApprovalWorkflowContainer} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.create_approval_workflows]} exact path="/companies/setup/approval-workflows/new" component={ApprovalWorkflowFormContainer} />
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.edit_approval_workflows]} exact path="/companies/setup/approval-workflows/:id" component={ApprovalWorkflowFormContainer} />

          {/* Workflow */}
          <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.view_approval_workflows]} exact path="/companies/setup/workflows" component={WorkflowListContainer} />
          {isWorkflowEnabled && <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.create_approval_workflows]} exact path="/companies/setup/workflows/new" component={WorkflowFormContainer} />}
          {isWorkflowEnabled && <PrivateRoute layout={MainLayout} renderPermissions={[permissionsEnum.edit_approval_workflows]} exact path="/companies/setup/workflows/:id" component={WorkflowFormContainer} />}

          <PrivateRoute layout={MainLayout} exact path="/companies/setup/custom-form-template" component={JobCustomFormTemplateContainer} />
          <PrivateRoute layout={MainLayout} exact path="/companies/setup/pre-hire-step" component={PreHireStepContainer} />
          <PrivateRoute layout={MainLayout} exact path="/companies/setup/root" component={RootSetupContainer} />
          <PrivateRoute layout={MainLayout} exact path="/companies/setup/profile-test-average" component={CompanyTestResultAverage} />
          <PrivateRoute layout={MainLayout} path="/companies/setup/admission" component={props => <AdmissionSetup {...props} />} />
          <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.manage_recommendation_email_domain]} exact path="/companies/setup/email-domains" component={EmailDomainsContainer} />
          <PrivateRoute layout={SetupLayout} exact path="/companies/setup/whatsapp-business-integration" component={WhatsAppSetupContainer} />
          {isCulturalFitMappingEnable && <PrivateRoute layout={SetupLayout} exact path="/companies/setup/cultural-fit-mapping" component={CulturalFitMapping} />}
          {/* Job */}
          <PrivateRoute layout={SecondaryLayout} exact path="/companies/jobs/create" component={JobOverviewContainer} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/create/details" component={JobDetails} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/overview" component={JobOverviewContainer} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/description" component={JobDescriptionContainer} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/details" component={JobDetails} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/workplace" component={isOldAddressXp ? JobWorkplaceOld : JobWorkplace} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/internal-data" component={JobInternalData} />
          {isWorkflowEnabled && <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/requisition" component={JobRequisition} />}
          {isWorkflowSimplified && <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/requisition" component={JobRequisitionSimplified} />}

          {isSkillsTaxonomyEnabled && <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/skills" component={JobTaxonomySkills} />}
          {isSkillsTaxonomyEnabled && <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/criterias" component={JobCulturalValues} />}
          {!isSkillsTaxonomyEnabled && <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/criterias" component={JobRatingCriterias} />}

          <PrivateRoute layout={MainLayout} path="/companies/jobs/not-found" component={JobNotFound} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/classifiers" component={JobCandidatesClassifiers} />
          {isTalentRecommendationEnabled && (
            <PrivateRoute
              layout={SecondaryLayout}
              renderPermissions={[permissionsEnum.manage_talent_recommendation]}
              exact
              path="/companies/jobs/edit/:id/talent-recommended"
              component={JobTalentRecommended}
            />
          )}
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/share" component={JobShare} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/publication" component={JobPublicationType} />
          <PrivateRoute layout={SecondaryLayout} path="/companies/jobs/edit/:id/pictures" component={JobPictures} />

          <PrivateRoute layout={SecondaryLayout} exact path="/companies/jobs/edit/:id/steps" component={JobStepsList} />
          <PrivateRoute layout={SecondaryLayout} exact path="/companies/jobs/edit/:jobId/steps/register/:stepId" component={JobStepRegister} />
          <PrivateRoute layout={SecondaryLayout} exact path="/companies/jobs/edit/:jobId/steps/final/:stepId" component={JobStepFinal} />
          <PrivateRoute layout={SecondaryLayout} exact path="/companies/jobs/edit/:jobId/steps/custom" component={JobStepCustom} />
          <PrivateRoute layout={SecondaryLayout} exact path="/companies/jobs/edit/:jobId/steps/custom/:stepId" component={JobStepCustom} />

          <PrivateRoute layout={MainLayout} exact path="/companies/jobs" component={JobList} />

          <PrivateRoute
            layout={MainLayout}
            exact
            path="/companies/jobs/:jobId/candidates/summary/experimental"
            component={ExperimentalJobSummaryContainer}
          />

          <PrivateRoute
            layout={MainLayout}
            exact
            path="/companies/jobs/:jobId/candidates/summary"
            component={JobSummaryContainer}
          />
          <PrivateRoute layout={MainLayout} exact path="/companies/jobs/:jobId/candidates/experimental" component={ExperimentalJobApplication} />

          <PrivateRoute layout={MainLayout} exact path="/companies/jobs/:jobId/candidates/:applicationId" component={JobApplicationCandidate} />
          <PrivateRoute layout={MainLayout} exact path="/companies/jobs/:jobId/candidates/:applicationId/experimental" component={ExperimentalJobApplicationCandidate} />
          <PrivateRoute layout={MainLayout} exact path="/companies/jobs/:jobId/candidates/:applicationId/:component" component={JobApplicationCandidateComponents} />

          <PrivateRoute
            layout={MainLayout}
            exact
            path="/companies/jobs/:jobId/candidates"
            component={!redesignSelecao ? JobApplication : SelectionProcessRedesign}
          />

          {/* Company Employee */}
          {isInternalRecruitmentEnabled && (
            <PrivateRoute layout={SetupLayout} renderPermissions={[permissionsEnum.view_company_employees, permissionsEnum.edit_company_employees]} exact path="/companies/setup/employees" component={CompanyEmployee} />
          )}

          {/* CURRICULUM DOWNLOAD */}
          <PrivateRoute exact path="/companies/jobs/:jobId/candidates/:applicationId/curriculum/pdf" component={CurriculumDownloadContainer} />

          {/* COMMUNITY */}
          <PrivateRoute
            layout={featureFlags.includes(FeaturesFlagsEnum.showMainHeaderOnCommunity)
              ? MainLayout
              : SecondaryLayout
            }
            exact
            path="/companies/community"
            component={Community}
          />

          {/* Admission */}
          <PrivateRoute exact path="/companies/news" component={AdmissionForm} />

          {/* Custom Tests */}
          <PrivateRoute
            layout={MainLayout}
            renderPermissions={[permissionsEnum.create_custom_test]}
            key="testsCreat"
            exact
            path="/companies/setup/custom-tests/create"
            component={TestsCreate}
          />

          <PrivateRoute
            layout={MainLayout}
            renderPermissions={[permissionsEnum.edit_custom_test]}
            key="testsEditT"
            exact
            path="/companies/setup/custom-tests/:id/edit"
            component={TestEdit}
          />

          <PrivateRoute
            layout={MainLayout}
            renderPermissions={[
              permissionsEnum.create_custom_test,
              permissionsEnum.edit_custom_test,
            ]}
            key="testsShowQ"
            exact
            path="/companies/setup/custom-tests/:id/questions"
            component={Questions}
          />

          <PrivateRoute
            layout={MainLayout}
            key="testsJobT"
            path="/companies/jobs/:jobId/candidates/:applicationId/tests/:testId"
            exact
            component={TestsCorrection}
          />

          {isCompanyPlanInformationEnabled
          && (
            <PrivateRoute
              layout={SetupLayout}
              key="companyPlanInformation"
              renderPermissions={[permissionsEnum.view_company_plan_resources_comsumption]}
              path="/companies/setup/company-plan-information"
              exact
              component={CompanyPlanInformation}
            />
          )}

          <PrivateRoute path="/companies/403" component={Error403} />
          <PrivateRoute path="/companies/404" component={Error404} />
          <PrivateRoute path="/companies/500" component={Error500} />

          <PrivateRoute layout={MainLayout} exact path="/companies/custom-tests/:customTestId/preview" component={CustomTestPreview} />,

          <PrivateRoute layout={MainLayout} component={Error404} />
        </Switch>
      </Suspense>
    </IDPProvider>
  );
};

Routes.propTypes = propTypes;

export default Routes;
