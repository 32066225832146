import { ApiClient } from '@gupy/api-client';

const communicationServiceClient = new ApiClient({
  tokenHeaderParam: 'company_key_auth',
  tokenCookieName: process.env.REACT_APP_AUTH_TOKEN_NAME || 'company_token',
  signInUrl: process.env.REACT_APP_SIGNIN_PATH || '/companies/signin',
  urlBase: process.env.REACT_APP_COMMUNICATION_SERVICE_API_URL || '',
});

export {
  communicationServiceClient,
};
