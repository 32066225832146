import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';

import { Button, Empty, InputSearch, Spinner } from '@gupy/design-system';
import { PaginationClean } from '@gupy/front-commons';

import Grid from '../../components/Grid/Grid';
import SetupGridSidebarMenu from '../../components/Grid/SetupGridSidebarMenu';
import GridContent from '../../components/Grid/GridContent';
import jobStatusEnum from '../../constants/Job/jobStatusEnum';
import { openConfirmDialog } from '../../actions/App/AppActions';
import { initJobTemplates, deleteJobTemplate, updateSearch, duplicateJobTemplate } from './JobTemplatesAction';
import JobTemplatesCard from './components/JobTemplatesCard';
import { getJobTemplatesMessages } from './Messages';
import './JobTemplates.scss';

const propTypes = {
  history: PropTypes.object.isRequired,
  JobTemplate: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  initJobTemplates: PropTypes.func.isRequired,
  updateSearch: PropTypes.func.isRequired,
  deleteJobTemplate: PropTypes.func.isRequired,
  duplicateJobTemplate: PropTypes.func.isRequired,
  openConfirmDialog: PropTypes.func.isRequired,
  permissions: PropTypes.object.isRequired,
};

class JobTemplates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      create: false,
      searchValue: '',
    };
    this.messages = getJobTemplatesMessages(props.intl);
  }

  componentDidMount = () => {
    this.props.initJobTemplates(this.state.searchValue);
  };

  handleSearch = (event) => {
    const searchValue = event.target.value;
    this.setState({
      searchValue,
    });

    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.props.updateSearch(searchValue);
    }, 400);
  };

  handleCreateTemplate = () => {
    this.setState({ create: true });
  };

  handleEditClick = (templateId) => {
    this.props.history.push(`/companies/jobs/edit/${templateId}/details`);
  };

  handleDeleteClick = (templateId) => {
    const { JobTemplate: { pagination: { page } } } = this.props;
    this.props.openConfirmDialog({
      title: this.messages.title,
      message: this.messages.message,
      confirmButtonText: this.messages.confirmButtonText,
      cancelButtonText: this.messages.cancelButtonText,
      onConfirmClick: () => {
        this.props.deleteJobTemplate(
          templateId,
          { success: this.messages.success },
          page,
        );
      },
    });
  };

  handleDuplicateClick = (templateId) => {
    const jobPath = '/companies/jobs/edit/:id/details';
    this.props.duplicateJobTemplate(
      templateId,
      { success: this.messages.duplicateSuccess },
      jobPath,
    );
  };

  handlePageChange = (page) => {
    this.props.initJobTemplates(this.state.searchValue, page);
  };

  render() {
    if (this.state.create) {
      return <Redirect push to={`/companies/jobs/create/details?${jobStatusEnum.template}`} />;
    }

    const { permissions, JobTemplate } = this.props;
    const { templates, pagination, isLoading } = JobTemplate;
    const searchHasNoResults = JobTemplate.templates === null;
    const searchIsNotEmpty = !searchHasNoResults && JobTemplate.templates.length > 0;

    const gridClassNames = classNames(
      'card-list__grid',
      'row',
    );

    const templateCardsNode = templates && templates.length > 0
      ? templates.map(t => (
        <JobTemplatesCard
          key={t.id}
          jobTemplate={t}
          onDeleteClick={this.handleDeleteClick}
          onDuplicateClick={this.handleDuplicateClick}
          onEditClick={this.handleEditClick}
          canEdit={permissions.edit_job_template}
          canDelete={permissions.delete_job_template}
          messages={this.messages}
        />
      )) : (
        <Fragment>
          <div className="job-templates__row">
            <Empty
              describe={searchHasNoResults
                ? ''
                : this.messages.noJobTemplateDescription
              }
              img={`${process.env.REACT_APP_ASSETS_URL}/illustrations/coffee.svg`}
              title={searchHasNoResults
                ? this.messages.noJobTemplateFound
                : this.messages.noJobTemplateAvaiable
              }
            />
          </div>
          {!searchHasNoResults && permissions.create_job_template
            ? (
              <div className="job-templates__row">
                <Button onClick={this.handleCreateTemplate}>
                  {this.messages.createNewTemplate}
                </Button>
              </div>
            )
            : null
          }
        </Fragment>
      );

    return (
      <Grid className="job-templates">
        <SetupGridSidebarMenu permissions={permissions} />
        <GridContent
          className={classNames('card-list')}
          title={this.messages.jobGridTitle}
        >
          {(searchHasNoResults || searchIsNotEmpty) && (
            <Fragment>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="job-templates__block">
                    <InputSearch
                      id="job-template-search"
                      name="jobTemplateSearch"
                      buttonLabel={this.messages.searchJobTemplate}
                      formLabel={this.messages.searchJobTemplate}
                      inputLabel={this.messages.searchJobTemplate}
                      onChange={this.handleSearch}
                      placeholder={this.messages.searchJobTemplate}
                      value={this.state.searchValue}
                      showButton={false}
                      block
                    />
                  </div>
                </div>
                {
                  permissions.create_job_template ? (
                    <div className="col-xs-12 col-sm-6 job-templates__create-button">
                      <Button onClick={this.handleCreateTemplate}>
                        {this.messages.createNewTemplate}
                      </Button>
                    </div>
                  ) : null
                }
              </div>
              <hr />
            </Fragment>
          )}
          <div className={gridClassNames}>
            {isLoading ? (
              <div className="job-templates__loading">
                <Spinner color="secondary" />
              </div>
            ) : templateCardsNode}
          </div>
          {
            !isLoading && pagination.pageCount > 1 && (
              <PaginationClean
                page={pagination.page}
                pageCount={pagination.pageCount}
                onPageChange={this.handlePageChange}
              />
            )
          }
        </GridContent>
      </Grid>
    );
  }
}

JobTemplates.propTypes = propTypes;

const mapStateToProps = state => ({
  JobTemplate: state.reducers.JobTemplate,
});

export default injectIntl(connect(
  mapStateToProps,
  {
    updateSearch,
    initJobTemplates,
    deleteJobTemplate,
    duplicateJobTemplate,
    openConfirmDialog,
  },
)(JobTemplates));
