import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  title: {
    id: 'copilot_title_chat',
    defaultMessage: 'Gupy IA',
  },
  labelInitialQuestion: {
    id: 'copilot_gupy_question_initial',
    defaultMessage: 'Texto enviado pela inteligência artificial: Olá! Sou a Inteligência Artificial da Gupy! Você pode começar uma conversa comigo fazendo uma pergunta sobre as funcionalidades do Gupy Recrutamento & Seleção. Como eu posso te ajudar?',
  },
  userMessageSugestionPrimary: {
    id: 'copilot_primary_message_suggestion',
    defaultMessage: 'Como divulgar vagas da Gupy no Linkedin?',
  },
  userMessageSugestionSecundary: {
    id: 'copilot_secundary_message_suggestion',
    defaultMessage: 'Como editar uma vaga na Gupy?',
  },
  footerDescription: {
    id: 'copilot_footer_description',
    defaultMessage: 'Está é uma funcionalidade BETA e pode apresentar respostas imprecisas por ainda estar em desenvolvimento.',
  },
  send: {
    id: 'copilot_send_message',
    defaultMessage: 'Enviar Pergunta',
  },
  inputPlaceHolder: {
    id: 'copilot_place_holder',
    defaultMessage: 'Escreva a sua pergunta',
  },
  labelOpenCopilot: {
    id: 'copilot_label_open_chat',
    defaultMessage: 'Abrir Copilot FAQ  Inteligencia Artificial',
  },
  labelHeader: {
    id: 'copilot_label_header',
    defaultMessage: 'Gupy Inteligência Artificial em fase beta',
  },
  labelCloseBtn: {
    id: 'btn_close',
    defaultMessage: 'Fechar Copilot',
  },
  labelSugestionPrimary: {
    id: 'copilot_primary_message_label',
    defaultMessage: 'Enviar pergunta pré-definida: Como enviar feedback em massa?',
  },
  labelSugestionSecundary: {
    id: 'copilot_secundary_message_label',
    defaultMessage: 'Enviar pergunta pré-definida: Como encerrar uma vaga?',
  },
  labelFooterDescription: {
    id: 'copilot_footer_description_label',
    defaultMessage: 'Aviso: Está é uma funcionalidade BETA e pode apresentar respostas imprecisas por ainda estar em desenvolvimento.',
  },
  newRequest: {
    id: 'copilot_new_request',
    defaultMessage: 'Quero outra resposta',
  },
  labelLiked: {
    id: 'copilot_liked_label',
    defaultMessage: 'Gostei da resposta',
  },
  labelDislike: {
    id: 'copilot_dislike_label',
    defaultMessage: 'Não gostei da resposta',
  },
  helloCopilot: {
    id: 'copilot_dialog',
    defaultMessage: 'Está com dúvidas?',
  },
  helloCopilotHelp: {
    id: 'copilot_dialog_help',
    defaultMessage: 'A Gupy IA pode te ajudar!',
  },

});

export const getMessages = intl => formatMessages(intl, messages);
