import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { FormattedContent } from '@gupy/front-commons';
import { buildViewedCardClassName } from './helpers/buildViewedCardClassName';

const cardClassName = 'gdp-notifications-card';

const UserNotificationCardContent = ({
  viewed,
  createdAt = null,
  data,
  onCardClick,
}) => {
  const formattedCreatedAt = createdAt && moment(createdAt).fromNow();

  return (
    <div tabIndex={0} role="button" onClick={onCardClick} className={buildViewedCardClassName(viewed, cardClassName)}>
      <div className="gdp-notifications-card__message">
        <div className="gdp-notifications-card__message-content">
          <FormattedContent
            contentList={data.title}
            className={buildViewedCardClassName(viewed, 'gdp-notifications-card__message-title')}
          />
          <FormattedContent
            contentList={data.body}
            className="gdp-notifications-card__message-body"
          />
        </div>
        {formattedCreatedAt ? (
          <div className="gdp-notifications-card__time-container">
            <span className="gdp-notifications-card__time-label">{formattedCreatedAt}</span>
          </div>
        ) : null}
      </div>
    </div>
  );
};

UserNotificationCardContent.propTypes = {
  onCardClick: PropTypes.func,
  viewed: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  createdAt: PropTypes.string,
};

export default UserNotificationCardContent;
