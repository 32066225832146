const CurriculumHistoryActionTypes = {
  application: 'application',
  approved: 'approved',
  bookmarked: 'bookmarked',
  criteriaChange: 'criteria-change',
  criteriaDelete: 'criteria-exclusion',
  disapproval: 'disapproval',
  emailSending: 'email-sending',
  giveUp: 'give_up',
  postComment: 'post-comment',
  postTag: 'post-tag',
  postLike: 'post-like',
  deleteLike: 'delete-like',
  removeTag: 'remove-tag',
  removeFromBookmarks: 'remove-from-bookmarks',
  statusChange: 'status-change',
  stepChange: 'step-change',
  automaticStepChange: 'automatic-step-change',
  relocatedFrom: 'relocated-from',
  relocatedTo: 'relocated-to',
  postAttach: 'post-attach',
  receivedFeedback: 'received-feedback',
  postHiringInformation: 'post-hiring-information',
  manuallyInserted: 'manually-inserted',
  indicationCommentary: 'is-indicated-status',
  whatsAppLink: 'whatsapp_link',
};

export default CurriculumHistoryActionTypes;
