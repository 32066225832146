import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobStepRegisterActionTypes = createActionTypes('JobStepRegister',
  'CHANGE_FORM',
  asyncActionType('GET'),
  asyncActionType('PATCH'));

const changeForm = modelForm => ({
  type: JobStepRegisterActionTypes.CHANGE_FORM,
  modelForm,
});

const patchJobStepRegister = (payload, successCallback) => ({
  type: JobStepRegisterActionTypes.PATCH,
  payload,
  successCallback,
});

const getJobStepRegister = (jobId, stepId) => ({
  type: JobStepRegisterActionTypes.GET,
  jobId,
  stepId,
});

export {
  JobStepRegisterActionTypes,
  changeForm,
  patchJobStepRegister,
  getJobStepRegister,
};
