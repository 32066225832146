import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  createTestSuccess: {
    id: 'create_custom_test_success_message',
    defaultMessage: 'Teste criado com sucesso. Agora insira as questões do seu teste.',
  },
  createTestError: {
    id: 'create_custom_test_error_message',
    defaultMessage: 'Erro ao criar teste.',
  },
  saveButton: {
    id: 'save',
    defaultMessage: 'Salvar',
  },
  createTestGridContentTitle: {
    id: 'create_test_grid_content_title',
    defaultMessage: 'Crie um teste personalizado para avaliar o conhecimento e habilidades dos candidatos',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
