import { BaseForm } from '@gupy/front-commons';

class JobApplicationEmailForm extends BaseForm {
  constructor() {
    super({
      from: {
        validate: {
          notEmpty: true,
        },
      },
      subject: {
        validate: {
          notEmpty: true,
        },
      },
      cc: {
        validate: {
          emailArray: true,
        },
      },
      htmlMessage: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default JobApplicationEmailForm;
