import styled from 'styled-components';

export const Ecosystem = styled.div`
  position: fixed;
  z-index: 150;
  padding-block-end: 3%;
  @media print {
    display: none !important;
  }
`;

export const MainLayoutSpinner = styled.div`
  width: 100%;
  min-height: 80vh;
  margin-top: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MobileSidebarMenuOverlay = styled.div`
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  right: 0;
  background-color: #000000;
  opacity: 0.5;
  z-index: 99;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    display: none;
    z-index: 0;
  }
`;
