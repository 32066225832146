import { put, takeLatest } from 'redux-saga/effects';

import CompanyTypesActionTypes from '../../constants/CompanyTypes/CompanyTypesActionTypes';
import CompanyService from '../../services/Company/CompanyService';

function* getAll() {
  try {
    const response = yield CompanyService.getTypes();
    yield put({
      type: CompanyTypesActionTypes.GET_ALL_SUCCESS,
      companyTypes: response.body.data,
    });
  } catch (error) {
    yield put({
      type: CompanyTypesActionTypes.GET_ALL_FAIL,
    });
  }
}

function* CompanyTypesSaga() {
  yield takeLatest(CompanyTypesActionTypes.GET_ALL, getAll);
}

export default CompanyTypesSaga;
