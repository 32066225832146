import { createActionTypes } from '@gupy/front-commons';

export default createActionTypes('SocialMedias',
  'CHANGE_FORM',
  'SET_SELECTED',
  'GET_ALL_REQUEST',
  'GET_ALL_SUCCESS',
  'GET_ALL_FAIL',
  'PATCH_CAREER_PAGE_SOCIAL_MEDIA_REQUEST',
  'PATCH_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS',
  'PATCH_CAREER_PAGE_SOCIAL_MEDIA_FAIL',
  'GET_CAREER_PAGES_EXPANDED_REQUEST',
  'PUT_CAREER_PAGE_SOCIAL_MEDIA_REQUEST',
  'PUT_CAREER_PAGE_SOCIAL_MEDIA_SUCCESS',
  'PUT_CAREER_PAGE_SOCIAL_MEDIA_FAIL');
