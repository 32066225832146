import React from 'react';
import PropTypes from 'prop-types';

import { DialogInfo } from '@gupy/design-system';

import './GroupTypeNewDialog.scss';

const propTypes = {
  messages: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};

const GroupTypeNewDialog = ({
  messages,
  isModalOpen,
  handleCloseModal,
}) => {
  const dialogTitle = <span className="group-type-new-dialog__title">{messages.newDialogTitle}</span>;
  return (
    <DialogInfo
      title={dialogTitle}
      description={(
        <span className="group-type-new-dialog">
          {messages.newDialogDescription}
          <li className="group-type-new-dialog__description-item group-type-new-dialog__description-item--first">
            <span className="group-type-new-dialog__description-topic">
              {messages.newDialogManagementGroup}
            </span>
            {' '}
            {messages.newDialogManagementDescription}
          </li>
          <li className="group-type-new-dialog__description-item">
            <span className="group-type-new-dialog__description-topic">
              {messages.newDialogRecruitmentGroup}
            </span>
            {' '}
            {messages.newDialogRecruitmentDescription}
          </li>
        </span>
      )}
      open={isModalOpen}
      confirmButton={{
        label: messages.okButton,
        action: handleCloseModal }}
      imageUrl={`${process.env.REACT_APP_ASSETS_URL}/job/no-jobs-to-list.png`}
    />
  );
};

GroupTypeNewDialog.propTypes = propTypes;

export default GroupTypeNewDialog;
