import moment from 'moment';

const defaultDateFormat = 'YYYY-MM-DD';

const participantsToArray = participants => participants.map(participant => +participant.value);

const adaptDateWithSpecificStringTime = (date, time) => {
  const preparedDate = moment(date).format(defaultDateFormat);
  const [hours, minutes] = time.split(':');
  return moment(`${preparedDate}T${hours}:${minutes}`);
};

const adaptModalObjectToApiPayload = interviewEvent => ({
  candidateId: interviewEvent.candidatesIds[0],
  interviewersIds: participantsToArray(interviewEvent.participants),
  jobStepId: interviewEvent.jobStepId,
  title: interviewEvent.title,
  description: interviewEvent.description,
  attachments: interviewEvent.files ? interviewEvent.files.map(
    ({ id, name, size, tempName }) => ({ id, name, size, tempName }),
  ) : [],
  location: {
    addressLine: interviewEvent.location,
    latitude: 0,
    longitude: 0,
  },
  startAt: adaptDateWithSpecificStringTime(interviewEvent.date, interviewEvent.startTime),
  endAt: adaptDateWithSpecificStringTime(interviewEvent.date, interviewEvent.endTime),
  timezone: interviewEvent.timezone,
  template: interviewEvent.template,
  status: interviewEvent.status,
  allowReply: interviewEvent.allowReply,
  interviewLink: interviewEvent.interviewLink ? interviewEvent.interviewLink : '',
  format: interviewEvent.format ? interviewEvent.format : '',
  useInterviewGupyLink: interviewEvent.useInterviewGupyLink
    ? interviewEvent.useInterviewGupyLink
    : false,
});

const adaptApiToModalObject = interviewEvent => ({
  participants: interviewEvent.interviewers.map(interviewer => ({
    value: interviewer.id.toString(),
    label: `${interviewer.name} (${interviewer.email})`,
  })),
  jobStepId: interviewEvent.jobStepId,
  title: interviewEvent.title,
  description: interviewEvent.description,
  location: interviewEvent.location ? interviewEvent.location.addressLine : null,
  date: moment(interviewEvent.startAt),
  startTime: moment(interviewEvent.startAt).format('HH:mm'),
  endTime: moment(interviewEvent.endAt).format('HH:mm'),
  timezone: interviewEvent.timezone,
  status: interviewEvent.status,
  integrationConfLink: interviewEvent.integrationConfLink,
  format: interviewEvent.format,
  interviewLink: interviewEvent.interviewLink,
  useInterviewGupyLink: interviewEvent.useInterviewGupyLink,
});

const adaptApiToSuggestedSpots = suggestedSpots => (
  suggestedSpots.spots.map(spot => ({
    text: `${moment(spot.startAt).format('llll')} - ${moment(spot.endAt).format('LT')}`,
    value: `${moment(spot.startAt).format('X')}-${moment(spot.endAt).format('X')}`,
    subtitle: 'Todos estão disponíveis',
  }))
);

const adaptApiToPossibleParticipants = possibleParticipants => (
  possibleParticipants.map(participant => ({
    label: `${participant.name} (${participant.email})`,
    value: participant.id.toString(),
  }))
);

const adaptAvailability = ({ usersAvailability }) => {
  const availableUsersMap = {};
  usersAvailability.forEach(({ id, isAvailable }) => {
    availableUsersMap[id] = isAvailable;
  });
  return availableUsersMap;
};

export {
  adaptModalObjectToApiPayload,
  adaptApiToModalObject,
  adaptApiToSuggestedSpots,
  adaptAvailability,
  participantsToArray,
  adaptApiToPossibleParticipants,
  adaptDateWithSpecificStringTime,
};
