import { defineMessages } from 'react-intl';

import { ApplicationStepStatus } from '@gupy/front-commons';

import testsTypeEnum from './TestsTypeEnum';

const formatScoreValue = (value) => {
  if (value === null || value === undefined) {
    return null;
  }

  return Math.round((value) * 10) / 10;
};

const adaptCustomTestResult = ({ customTestsResult, application }) => {
  const currentJobStep = application && application.jobStep;
  return customTestsResult.map((ctr) => {
    const totalScore = formatScoreValue(ctr.total_score);
    const averageScore = formatScoreValue(ctr.average_score);
    const { status, test_id: testId } = ctr;
    let differenceClass = '';
    let differenceMessage = '';
    let differenceValue = 0;
    const isComplete = !!ctr.test_conclusion_date;
    const isTestInCurrentStep = currentJobStep
      && parseInt(currentJobStep.testId, 10) === testId;

    const testCompleted = totalScore !== null && isComplete
      && (status !== ApplicationStepStatus.notStarted
        || (status === ApplicationStepStatus.notStarted && !isTestInCurrentStep));

    const waitingCorrection = totalScore === null && isComplete
      && (status !== ApplicationStepStatus.notStarted
        || (status === ApplicationStepStatus.notStarted && !isTestInCurrentStep));

    if ((totalScore > 0 || totalScore === 0) && (averageScore > 0 || averageScore === 0)) {
      differenceValue = formatScoreValue(totalScore - averageScore);

      const confirmMessages = defineMessages({
        averageMessage: {
          id: 'curriculum-tests-result-average-message',
          defaultMessage: 'Este candidato está na média!',
        },
        aboveMessage: {
          id: 'curriculum-tests-result-above-message',
          defaultMessage: 'Este candidato está <strong>{total} pontos</strong> acima da média!',
        },
        belowMessage: {
          id: 'curriculum-tests-result-below-message',
          defaultMessage: 'Este candidato está <strong>{total} pontos</strong> abaixo da média!',
        },
      });

      if (differenceValue === 0) {
        differenceMessage = confirmMessages.averageMessage;
      } else if (differenceValue > 0) {
        differenceMessage = confirmMessages.aboveMessage;
        differenceClass = 'success';
      } else {
        differenceMessage = confirmMessages.belowMessage;
        differenceClass = 'fail';
        differenceValue *= -1;
      }
    }

    return {
      testId: ctr.test_id,
      testTitle: ctr.test_title,
      order: ctr.order,
      type: testsTypeEnum.customTest,
      totalScore,
      averageScore,
      status,
      testCompleted,
      waitingCorrection,
      differenceClass,
      differenceMessage,
      differenceValues: {
        total: differenceValue,
      },
    };
  });
};

export {
  adaptCustomTestResult,
};
