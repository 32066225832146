import { createActionTypes, asyncActionType } from '@gupy/front-commons';

const OnboardingGaiaActionTypes = createActionTypes('OnboardingGaia',
  asyncActionType('GET_ONBOARDING_GAIA_STATUS'),
  asyncActionType('CHANGE_ONBOARDING_GAIA_STATUS'),
  'SET_REF_POSITION_GAIA',
  'SET_REF_MOVE_APPLICATION_BTN',
  'HOVER_REF_POSITION_GAIA');

const getOnboardingGaiaStatus = userId => ({
  type: OnboardingGaiaActionTypes.GET_ONBOARDING_GAIA_STATUS,
  userId,
});

const setRefAffinityGaia = el => ({
  type: OnboardingGaiaActionTypes.SET_REF_POSITION_GAIA,
  payload: { ref: el },
});

const setRefMoveApplicationBtn = el => ({
  type: OnboardingGaiaActionTypes.SET_REF_MOVE_APPLICATION_BTN,
  payload: { ref: el },
});

const setHoverRefAffinityGaia = (isHover = true) => ({
  type: OnboardingGaiaActionTypes.HOVER_REF_POSITION_GAIA,
  payload: { isHover },
});

const changeOnboardingGaiaStatus = (userId, status) => ({
  type: OnboardingGaiaActionTypes.CHANGE_ONBOARDING_GAIA_STATUS,
  userId,
  status,
});

export {
  OnboardingGaiaActionTypes,
  getOnboardingGaiaStatus,
  setRefAffinityGaia,
  setRefMoveApplicationBtn,
  setHoverRefAffinityGaia,
  changeOnboardingGaiaStatus,
};
