import { communicationServiceClient as api } from '../../api/CommunicationServiceClient';

export default {
  createTemplate(payload) {
    return api.post('/api/whatsapp-template', payload);
  },
  updateTemplate(payload) {
    return api.put(`/api/whatsapp-template/${payload.id}`, payload);
  },
  getTemplates() {
    return api.get('/api/whatsapp-template');
  },
};
