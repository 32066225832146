import moment from 'moment';

export const formatInterviewDate = (date) => {
  const momentObj = moment(date);
  const formats = {
    'pt-br': hour => `DD/MM [à${hour === 1 ? '' : 's'}] HH[h]mm`,
    es: hour => `DD/MM [a la${hour === 1 ? '' : 's'}] HH[h]mm`,
    en: () => 'MM/DD [at] HH[h]mm',
  };
  const hour = momentObj.hour();
  const format = formats[moment.locale()](hour);

  return momentObj.format(format);
};
