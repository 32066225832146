const PartnerTypes = {
  catho: 'catho',
  facebookJobs: 'facebook_jobs',
  glassdoor: 'glassdoor',
  googleForJobs: 'google_for_jobs',
  gupyOpportunities: 'gupy_opportunities',
  gupyOpportunitiesRecommended: 'gupy_opportunities_recommended',
  gupyPublicPage: 'gupy_public_page',
  gupyPublicPageEvent: 'gupy_public_page_event',
  indeed: 'indeed',
  indeedSponsored: 'indeed_sponsored',
  jooble: 'jooble',
  linkedin: 'linkedin',
  recrutaSimples: 'recruta_simples',
  riovagas: 'riovagas',
  netvagas: 'netvagas',
  jobBoard99hunters: '99hunters',
  trampos: 'trampos',
  gupyInternalJobs: 'gupy_internal_jobs',
  sharingFacebook: 'sharing_facebook',
  sharingTwitter: 'sharing_twitter',
  sharingLinkedin: 'sharing_linkedin',
  sharingWhatsapp: 'sharing_whatsapp',
  empregosRn: 'empregos_rn',
  portal: 'gupy_portal',
  talent: 'talent',
  pcdonline: 'pcd_online',
  pcdEmFoco: 'pcd_em_foco',
  espm: 'espm',
  careerJet: 'career_jet',
  shareLink: 'share_link',
  emailJobRecommendation: 'email_job_recommendation',
  yduqs: 'yduqs',
  carreiraFashion: 'carreira_fashion',
  jobbol: 'jobbol',
  remotar: 'remotar',
  agrou: 'agrou',
};

export default PartnerTypes;
