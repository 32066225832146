import { BaseForm } from '@gupy/front-commons';

class EmailDomainModalValidator extends BaseForm {
  constructor() {
    super({
      emailDomain: {
        validate: {
          required: true,
          domain: true,
          maxLength: 250,
        },
      },
    });
  }
}

export default EmailDomainModalValidator;
