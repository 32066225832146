export default function openRscLinkedinIframeInPopup({ id, clientId, customParams }) {
  const defaultParams = {
    scrollbars: 'yes',
    resizable: 'no',
    status: 'no',
    location: 'no',
    toolbar: 'no',
    menubar: 'no',
    width: 640,
    height: 800,
    left: window.screenLeft || 0,
    top: window.screenTop || 0,
  };

  const params = Object.entries({ ...defaultParams, ...customParams })
    .map(([key, value]) => `${key}=${value}`)
    .join(',');

  window.open(
    `${process.env.REACT_APP_COMPANIES_PUBLIC_URL}/setup/integrations/rsc-linkedin-iframe/child-app/${id}/client/${clientId}`,
    'popup',
    params,
  );
}
