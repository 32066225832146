import 'core-js';
import 'regenerator-runtime/runtime';

import { datadogRum } from '@datadog/browser-rum';
import React from 'react';
import ReactDOM from 'react-dom';
import { addLocaleData } from 'react-intl';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';

import es from 'react-intl/locale-data/es';
import pt from 'react-intl/locale-data/pt';

import 'moment/locale/pt-br';
import 'moment/locale/es';

import chmln from '@chamaeleonidae/chmln';
import configureStore from './stores';
import Root from './containers/Root';
import { initializeStorages } from './storage/initialize';

(async () => {
  try {
    datadogRum.init({
      applicationId: process.env.REACT_APP_DATADOG_COMPANIES_APPLICATION_ID,
      clientToken: process.env.REACT_APP_DATADOG_COMPANIES_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'ats-companies',
      env: process.env.REACT_APP_DATADOG_ENV,
      version: process.env.REACT_APP_GIT_COMMIT,
      sessionSampleRate: 50,
      sessionReplaySampleRate: 0, // do not remove, defaults to 100 and it is expensive
      trackInteractions: true,
      trackLongTasks: true,
      trackResources: true,
      allowedTracingUrls: [(url) => url.startsWith("https://private-api.gupy.io")],
      beforeSend: (event) => {
        if (event.type === 'error') {
          const errorEvent = event;
          const ignoreResourceUrl = [
            /stats\.g\.doubleclick\.net/i,
            /www\.google-analytics\.com/i,
            /bam\.nr-data\.net/i,
            /platform-lookaside\.fbsbx\.com/i,
            /in\.hotjar\.com/i,
            /ws4\.hotjar\.com/i,
            /discover\.search\.hereapi\.com/i,
            /www\.indeed\.com/i,
          ];
          const ignoreErrorMessage = [
            /The user aborted a request/i,
            /cancelado/i,
            /Failed to fetch/i,
            /XHR error/i,
          ];

          const shouldIgnore = errorEvent.error.resource
          && errorEvent.error.resource.url
          && ignoreResourceUrl.some(regex => regex.test(errorEvent.error.resource.url));
          if (shouldIgnore) return false;
          if (ignoreErrorMessage.some(regex => regex.test(errorEvent.error.message))) return false;
        }

        return true;
      },
    });
  } catch (e) {
  // eslint-disable-next-line no-console
    console.error('DATADOG ERROR :: ', JSON.stringify(e));
  }

  addLocaleData([...pt, ...es]);

  await initializeStorages();
  const store = configureStore();

  GoogleAnalyticsHelper.init('G-XCSYYD2PRC');

  // Initializing chameleon.io
  chmln.init(process.env.REACT_APP_CHAMELEON_TOKEN, {
    fastUrl: 'https://fast.chameleon.io/',
    forceOverride: true,
  });

  window.companiesContainer = document.getElementById('companies-root');

  ReactDOM.render(
    <Root store={store} />,
    document.getElementById('companies-root'),
  );
})();
