import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const CandidateInsertionActionTypes = createActionTypes(
  'CandidateInsertion',
  'CHANGE_FORM',
  'CLOSE_CANDIDATE_INSERTION_MODAL',
  'OPEN_CANDIDATE_INSERTION_MODAL',
  'RESET_FORM',
  asyncActionType('ADD_CANDIDATE'),
  asyncActionType('RELOCATE_CANDIDATE'),
  asyncActionType('SEND_EMAIL_INVITATION'),
  asyncActionType('VERIFY_EMAIL'),
  asyncActionType('CANDIDATE_INSERTION_SOURCES'),
);

const addCandidate = ({
  payload,
  jobId,
  currentStep,
  pagination,
  messages,
}) => {
  const adaptedPayload = { ...payload };
  adaptedPayload.name = adaptedPayload.name.trim();
  adaptedPayload.lastName = adaptedPayload.lastName.trim();
  adaptedPayload.linkedinProfileUrl = adaptedPayload.linkedinProfileUrl || undefined;
  adaptedPayload.mobileNumber = adaptedPayload.mobileNumber || undefined;
  adaptedPayload.currentStepId = adaptedPayload.currentStepId
    ? parseInt(adaptedPayload.currentStepId, 10)
    : null;

  return {
    type: CandidateInsertionActionTypes.ADD_CANDIDATE,
    payload: adaptedPayload,
    jobId,
    currentStep,
    pagination,
    messages,
  };
};

const changeForm = modelForm => ({
  type: CandidateInsertionActionTypes.CHANGE_FORM,
  modelForm,
});

const closeCandidateInsertionModal = () => ({
  type: CandidateInsertionActionTypes.CLOSE_CANDIDATE_INSERTION_MODAL,
});

const openCandidateInsertionModal = registerStepId => ({
  type: CandidateInsertionActionTypes.OPEN_CANDIDATE_INSERTION_MODAL,
  registerStepId,
});

const resetForm = () => ({
  type: CandidateInsertionActionTypes.RESET_FORM,
});

const relocateCandidate = (jobId, candidateId) => ({
  type: CandidateInsertionActionTypes.RELOCATE_CANDIDATE,
  jobId,
  candidateId,
});

const sendEmailInvitation = ({
  jobId,
  candidateId,
  currentStepId,
  applicationId,
  messages,
}) => ({
  type: CandidateInsertionActionTypes.SEND_EMAIL_INVITATION,
  jobId,
  candidateId,
  currentStepId,
  applicationId,
  messages,
});

const verifyEmail = (payload, jobId) => {
  const adaptedPayload = { ...payload };
  adaptedPayload.email = adaptedPayload.email.trim().toLowerCase();

  return {
    type: CandidateInsertionActionTypes.VERIFY_EMAIL,
    payload: adaptedPayload,
    jobId,
  };
};

const verifyEmailSuccess = (email, response) => {
  const { candidateId, applicationId, fullName } = response;

  const isCandidateOnCurrentJob = !!applicationId && !!fullName && !!candidateId;
  const isCandidateOnCurrentCompany = !!candidateId && !!fullName && !applicationId;

  return {
    type: CandidateInsertionActionTypes.VERIFY_EMAIL_SUCCESS,
    applicationId,
    candidateId,
    fullName,
    email,
    isCandidateOnCurrentJob,
    isCandidateOnCurrentCompany,
    isNewCandidate: !isCandidateOnCurrentJob && !isCandidateOnCurrentCompany,
  };
};

export {
  CandidateInsertionActionTypes,
  addCandidate,
  changeForm,
  closeCandidateInsertionModal,
  openCandidateInsertionModal,
  relocateCandidate,
  resetForm,
  sendEmailInvitation,
  verifyEmail,
  verifyEmailSuccess,
};
