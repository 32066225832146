import { all, call, put, takeLatest } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';
import * as JobSummaryService from './Service';
import JobService from '../../../services/Job/JobService';
import JobSummaryDisapproveJobForm from '../../../forms/Job/JobSummary/JobSummaryDisapproveJobForm';
import { JobSummaryActionTypes } from './ActionTypes';
import {
  initJobSummarySuccess,
  initJobSummary as initJobSummaryAction,
} from './Actions';
import history from '../../../history';

export function* replaceApprover(action) {
  const { newApprover, currentApprover, jobId, messages } = action;

  try {
    const result = yield call(JobSummaryService.replaceApprover, {
      jobId,
      currentApproverId: currentApprover.id,
      newApproverId: newApprover.id,
    });
    const successMessage = messages.successApproverReplace
      .replace('originalApproverName', result.originalApproverName)
      .replace('newApproverName', result.newApproverName);

    yield put({
      type: JobSummaryActionTypes.REPLACE_APPROVER_SUCCESS,
      payload: result,
      toast: buildToast(successMessage, ToastTypes.success),
    });
    yield put(initJobSummaryAction(jobId));
  } catch (err) {
    const errorMessage = messages.errorApproverReplace
      .replace('approverName', currentApprover.name);

    yield put({
      type: JobSummaryActionTypes.REPLACE_APPROVER_FAIL,
      toast: buildToast(errorMessage, ToastTypes.error),
    });
    yield put(initJobSummaryAction(jobId));
  }
}

function* replaceApproverBulk(action) {
  const { newApprover, currentApprover, jobIds, originJobId, messages } = action;

  const errorMessage = originalApprover => messages.errorApproverReplaceBulk(
    { originalApproverName: originalApprover.name },
  );

  try {
    const result = yield call(JobSummaryService.replaceApproverBulk, {
      jobIds,
      currentApproverId: currentApprover.id,
      newApproverId: newApprover.id,
    });
    const successesCount = result.successes.length;

    if (successesCount > 0) {
      const message = messages.successApproverReplaceBulk({
        originalApproverName: currentApprover.name,
        newApproverName: newApprover.name,
        numberOfOtherJobs: successesCount - 1,
      });

      yield put({
        type: JobSummaryActionTypes.REPLACE_APPROVER_BULK_SUCCESS,
        payload: result,
        toast: buildToast(message, ToastTypes.success),
      });
    } else {
      yield put({
        type: JobSummaryActionTypes.REPLACE_APPROVER_BULK_FAIL,
        toast: buildToast(errorMessage(currentApprover), ToastTypes.error),
      });
    }
  } catch (err) {
    yield put({
      type: JobSummaryActionTypes.REPLACE_APPROVER_BULK_FAIL,
      toast: buildToast(errorMessage(currentApprover), ToastTypes.error),
    });
  }

  yield put(initJobSummaryAction(originJobId));
}

function* initJobSummary(action) {
  try {
    const { jobId } = action;

    const [
      jobResponse,
      jobTypesResponse,
      classifiersListResponse,
      stepsResponse,
    ] = yield all([
      JobService.get(jobId),
      JobService.getJobTypes(jobId),
      JobService.getClassifiersList(jobId),
      JobService.getJobSteps(jobId),
    ]);

    yield put(initJobSummarySuccess({
      job: jobResponse.body,
      jobTypes: jobTypesResponse.body,
      classifiersList: classifiersListResponse.body,
      steps: stepsResponse.body,
    }));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobSummaryActionTypes.INIT_JOB_SUMMARY_FAIL,
        error,
      });
    }
  }
}

function* patchApproveJob(action) {
  const { payload, messages, approvalWorkflowEnabled } = action;
  const { job, workflow } = payload;
  const { id: jobId, ...payloadWithoutId } = workflow;

  try {
    yield JobService.updateApprovalWorkflow(jobId, payloadWithoutId);
    if (!approvalWorkflowEnabled) {
      yield JobService.patch(job);
    }

    yield put({
      type: JobSummaryActionTypes.PATCH_APPROVE_JOB_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });

    yield put(initJobSummaryAction(jobId));
  } catch (error) {
    yield put({
      type: JobSummaryActionTypes.PATCH_APPROVE_JOB_FAIL,
      error,
    });
  }
}

function* patchDisapproveJob(action) {
  const { payload, messages, approvalWorkflowEnabled } = action;
  const { job, workflow } = payload;
  const form = new JobSummaryDisapproveJobForm();

  form.populate(job);
  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_FAIL,
      validation,
    });
  } else {
    try {
      const { id: jobId, ...payloadWithoutId } = workflow;
      yield JobService.updateApprovalWorkflow(jobId, payloadWithoutId);
      if (!approvalWorkflowEnabled) {
        yield JobService.patch(job);
      }
      yield put({
        type: JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield put(initJobSummaryAction(jobId));
    } catch (error) {
      yield put({
        type: JobSummaryActionTypes.PATCH_DISAPPROVE_JOB_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* getAllJobsByApprover(action) {
  const { approverId } = action;
  try {
    const res = yield JobSummaryService.getJobsWaitingApprovalByApproverId({ approverId });

    yield put({
      type: JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID_SUCCESS,
      jobs: res.data,
    });
  } catch (error) {
    yield put({
      type: JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID_FAIL,
      error,
    });
  }
}

function* addApprover(action) {
  const { approver, jobId, messages } = action;

  try {
    const result = yield JobSummaryService.addApprover({ jobId, approverId: approver.id });

    const successMessage = messages.addApproverSuccess({
      approverName: approver.name,
    });

    yield put({
      type: JobSummaryActionTypes.ADD_APPROVER_SUCCESS,
      toast: buildToast(successMessage, ToastTypes.success),
      payload: result,
    });
  } catch (error) {
    const errorMessage = messages.addApproverError({
      approverName: approver.name,
    });

    yield put({
      type: JobSummaryActionTypes.ADD_APPROVER_FAIL,
      error,
      toast: buildToast(errorMessage, ToastTypes.success),
    });
  }

  yield put(initJobSummaryAction(jobId));
}

function* removeApprover(action) {
  const { approver, jobId, messages } = action;

  try {
    const result = yield JobSummaryService.removeApprover({ jobId, approverId: approver.id });

    const successMessage = messages.removeApproverSuccess({
      approverName: approver.name,
    });

    yield put({
      type: JobSummaryActionTypes.REMOVE_APPROVER_SUCCESS,
      toast: buildToast(successMessage, ToastTypes.success),
      payload: result,
    });
  } catch (error) {
    const errorMessage = messages.removeApproverError({
      approverName: approver.name,
    });

    yield put({
      type: JobSummaryActionTypes.REMOVE_APPROVER_FAIL,
      error,
      toast: buildToast(errorMessage, ToastTypes.success),
    });
  }

  yield put(initJobSummaryAction(jobId));
}

function* removeApproverBulk(action) {
  const { currentApprover, jobIds, originJobId, messages } = action;

  try {
    const result = yield call(JobSummaryService.removeApproverBulk, {
      jobIds,
      approverId: currentApprover.id,
    });
    const successesCount = result.successes.length;

    if (successesCount > 0) {
      const successMessage = messages.removeApproverSuccess({
        approverName: currentApprover.name,
        numberOfOtherJobs: successesCount - 1,
      });

      yield put({
        type: JobSummaryActionTypes.REMOVE_APPROVER_BULK_SUCCESS,
        payload: result,
        toast: buildToast(successMessage, ToastTypes.success),
      });
    } else {
      const errorsCount = result.errors.length;

      const errorMessage = originalApprover => messages.removeApproverError(
        { approverName: originalApprover.name,
          numberOfOtherJobs: errorsCount - 1,
        },
      );

      yield put({
        type: JobSummaryActionTypes.REMOVE_APPROVER_BULK_FAIL,
        toast: buildToast(errorMessage(currentApprover), ToastTypes.error),
      });
    }
  } catch (err) {
    const errorsCount = jobIds.length;

    const errorMessage = originalApprover => messages.removeApproverError(
      { approverName: originalApprover.name,
        numberOfOtherJobs: errorsCount - 1,
      },
    );

    yield put({
      type: JobSummaryActionTypes.REMOVE_APPROVER_BULK_FAIL,
      toast: buildToast(errorMessage(currentApprover), ToastTypes.error),
    });
  }

  yield put(initJobSummaryAction(originJobId));
}

function* JobSummarySaga() {
  yield takeLatest(JobSummaryActionTypes.INIT_JOB_SUMMARY, initJobSummary);
  yield takeLatest(JobSummaryActionTypes.REPLACE_APPROVER, replaceApprover);
  yield takeLatest(JobSummaryActionTypes.REPLACE_APPROVER_BULK, replaceApproverBulk);
  yield takeLatest(JobSummaryActionTypes.PATCH_APPROVE_JOB, patchApproveJob);
  yield takeLatest(JobSummaryActionTypes.PATCH_DISAPPROVE_JOB, patchDisapproveJob);
  yield takeLatest(JobSummaryActionTypes.GET_ALL_JOBS_BY_APPROVER_ID, getAllJobsByApprover);
  yield takeLatest(JobSummaryActionTypes.ADD_APPROVER, addApprover);
  yield takeLatest(JobSummaryActionTypes.REMOVE_APPROVER, removeApprover);
  yield takeLatest(JobSummaryActionTypes.REMOVE_APPROVER_BULK, removeApproverBulk);
}

export default JobSummarySaga;
