import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

const propTypes = {
  locale: PropTypes.string.isRequired,
};

export const TermsContent = ({ locale }) => (
  <div>
    <h2 className="term__title">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-title-01"
        defaultMessage="Termos de uso da solução de diversidade da plataforma recrutamento e seleção"
      />
    </h2>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-01"
        defaultMessage="Estes termos de uso têm por objeto definir as regras a serem seguidas pelas empresas clientes (“<b>contratante</b>”) para a utilização da Solução de Diversidade da Plataforma Recrutamento e Seleção de propriedade da <b>Gupy Tecnologia em Recrutamento LTDA</b>., (“<b>Gupy</b>”), sem prejuízo da aplicação da legislação vigente (“<b>Termos de uso</b>”)."
      />
    </p>
    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-02"
        defaultMessage="<b>Ao aceitar e utilizar a solução de diversidade, você automaticamente concorda com estes termos de uso em nome da contratante, sendo esta integralmente responsável por todos e quaisquer atos praticados por você e pelas pessoas a que der acesso à solução. caso não concorde com qualquer dos termos e condições abaixo estabelecidos ou não tenha poderes para tanto, você não deve aceitar os termos, utilizar a solução de diversidade e nem liberar o acesso para outros usuários</b>"
      />
    </p>

    <h3 className="term__subtitle">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-title-02"
        defaultMessage="1. das definições"
      />
    </h3>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-04"
        defaultMessage="<b>1.1.</b> Além dos demais termos definidos ao longo do documento, para os fins de interpretação dos <b>termos de uso</b>, as palavras abaixo terão os seguintes significados:"
      />
    </p>

    <ol type="i" className="term__list">
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-list-item-01"
            defaultMessage="<b>partes. contratante</b> e <b>Gupy</b> quando atuarem conjuntamente;"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-list-item-02"
            defaultMessage="<b>Plataforma de recrutamento e seleção</b>. A Plataforma Recrutamento e Seleção tem como objetivo proporcionar a automatização e digitalização do processo de recrutamento e seleção de Candidatos (termo abaixo definido), pelo gerenciamento de etapas e qualificação inteligente destes;"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-list-item-03"
            defaultMessage="<b>candidato</b>. Qualquer pessoa que realize o cadastro na Plataforma Recrutamento e Seleção para a participação em processos seletivos; e"
          />
        </p>
      </li>
      {locale === 'pt' && (
        <li>
          <p className="diversity-term__paragraph">
            <FormattedHTMLMessage
              tagName="span"
              id="terms-list-item-04"
              defaultMessage="<b>LGPD</b>. Lei Geral de Proteção de Dados Pessoais nº 13.709/18, que regula as atividades de tratamento de dados pessoais."
            />
          </p>
        </li>
      )}
    </ol>

    <h3 className="term__subtitle">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-title-03"
        defaultMessage="2. Da Solução de Diversidade"
      />
    </h3>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-05"
        defaultMessage="<b>2.1.</b> A <b>contratante</b> resolve contratar, a título gratuito, a Solução de Diversidade, da <b>plataforma recrutamento e seleção</b>, que permite que:"
      />
    </p>

    <ol type="i" className="term__list">
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-list-item-05"
            defaultMessage="a <b>Gupy</b> compartilhe com a <b>contratante</b> dados de diversidade (“Dados de Diversidade”) como naturalidade, cor/raça, orientação sexual, identidade de gênero, nome social se aplicável, coletados dos <b>candidatos</b> que se inscreveram em seus processos seletivos, caso eles tenham optado por compartilhá-las; e"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-list-item-06"
            defaultMessage="a <b>contratante</b> faça a gestão de tais informações, seja por filtros, sempre voltados para a promoção da diversidade, seja por dashboards."
          />
        </p>
      </li>
    </ol>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-06"
        defaultMessage="<b>2.2.</b> Por se tratar de dados sensíveis, os Dados de Diversidade são coletados dos <b>candidatos</b> pela <b>Gupy</b> de forma não obrigatória e sempre mediante consentimento, o qual poderá ser retirado a qualquer tempo. "
      />
    </p>
    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-07"
        defaultMessage="<b>2.3.</b> Para o adequado uso da Solução, a <b>contratante</b> se compromete a:"
      />
    </p>

    <ol type="i" className="term__list">
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-text-08"
            defaultMessage="utilizá-la com o estrito fim de promover a diversidade em seu ambiente de trabalho, realizando ações afirmativas e buscando contratar pessoas pertencentes a grupos de diversidade;"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-text-09"
            defaultMessage="não a utilizar, em nenhuma hipótese, para fins discriminatórios ou em descumprimento à lei ou outras normas aplicáveis;"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-text-10"
            defaultMessage="não a utilizar com vistas a facilitar a contratação de pessoas não pertencentes a grupos de diversidade;"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-text-11"
            defaultMessage="não utilizar os filtros ou as identificações de pessoas de grupos de diversidade para excluí-las de processos seletivos; e"
          />
        </p>
      </li>
      <li>
        <p className="diversity-term__paragraph">
          <FormattedHTMLMessage
            tagName="span"
            id="terms-text-12"
            defaultMessage="disponibilizar acesso aos Dados de Diversidade apenas àquelas pessoas que tenham necessidade de acessá-los, pela sua atividade."
          />
        </p>
      </li>
    </ol>
    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-13"
        defaultMessage="<b>2.4.</b> Com relação à disponibilização do acesso à Solução de Diversidade por seus usuários, a <b>contratante</b> deverá, outrossim, garantir que, antes do acesso à funcionalidade, o(a) usuário(a) faça os treinamentos disponibilizados pela <b>Gupy</b> sobre Diversidade e Inclusão e sobre o uso adequado da Solução, responsabilizando-se integralmente pelo uso adequado da Solução de Diversidade pelos seus usuários, perante os <b>candidatos</b>, perante a <b>Gupy</b> e perante terceiros."
      />
    </p>
    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-14"
        defaultMessage="<b>2.4.1.</b> Identificando o uso inadequado da Solução ou dos Dados de Diversidade por um de seus empregados, a <b>contratante</b> deverá (i) comunicar à <b>Gupy</b>; e (ii) tirar o acesso à Solução do(a) usuário(a)."
      />
    </p>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-15"
        defaultMessage="<b>2.5.</b> Os Dados de Diversidade são meramente informativos e não são considerados no ranqueamento de <b>candidatos</b> pela inteligência artificial da <b>Gupy</b>, sendo apenas armazenados pela GUPY e disponibilizados à <b>contratante</b>, que será, por sua vez, integralmente responsável pelo uso desses dados no processo seletivo. Assim, em nenhuma hipótese a <b>Gupy</b> será responsável pelo uso inadequado ou discriminatório dos Dados de Diversidade no processo seletivo."
      />
    </p>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-16"
        defaultMessage="<b>2.6.</b> Para utilização da Solução de Diversidade se aplicam as demais disposições acordadas entre as <b>partes</b>, incluindo, mas não se limitando, as relacionadas à Proteção de Dados e LGPD."
      />
    </p>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-17"
        defaultMessage="<b>2.6.1</b> As <b>partes</b> reconhecem que os Dados de Diversidade, por serem considerados Dados Pessoais Sensíveis pela <b>LGPD</b>, estão sujeitos a um maior rigor legal e, portanto, exigem maior proteção técnica e organizacional. Assim, as <b>partes</b> declaram que possuem medidas implementadas para garantir as proteções técnicas apropriadas, aptas a manter a integridade, confidencialidade e segurança destas informações."
      />
    </p>

    <h3 className="term__subtitle">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-title-04"
        defaultMessage="3. do aceite"
      />
    </h3>

    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-18"
        defaultMessage="<b>3.1.</b> A <b>contratante</b> por meio do aceite digital, declara para todos os fins de direito que leu, compreendeu e aceita todas as regras e normas instituídas por meio dos presentes <b>termos de uso</b>, declarando que a pessoa que aceita o presente termo é seu representante legal devidamente constituído."
      />
    </p>
    <p className="diversity-term__paragraph">
      <FormattedHTMLMessage
        tagName="span"
        id="terms-text-19"
        defaultMessage="<b>3.2.</b> Este termo pode e irá ser atualizado periodicamente pela <b>Gupy</b>, que se resguarda no direito de alteração, sem qualquer tipo de aviso prévio e comunicação. É importante que a <b>contratante</b> confira sempre abaixo se e quando houve atualização."
      />
    </p>

    <div className="diversity-term__footer">
      <p className="diversity-term__paragraph">
        <FormattedHTMLMessage
          tagName="span"
          id="terms-text-20"
          defaultMessage="Início da vigência:"
        />
        {' '}05/04/2023
      </p>
      <p className="diversity-term__paragraph">
        <FormattedHTMLMessage
          tagName="span"
          id="terms-text-21"
          defaultMessage="Última atualização:"
        />
        {' '}05/04/2023
      </p>
    </div>
  </div>
);

TermsContent.propTypes = propTypes;
