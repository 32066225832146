import { combineReducers } from 'redux';

import ErrorActionTypes from '../../constants/Error/ErrorActionTypes';

export const hasError500 = (state = false, action) => {
  switch (action.type) {
  case ErrorActionTypes.SET_ERROR_500:
    return true;
  default:
    return state;
  }
};

export default combineReducers({
  hasError500,
});
