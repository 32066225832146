import { combineReducers } from 'redux';

import { JobPictureActionTypes } from '../../../actions/Job/JobPictures/JobPicturesAction';
import JobActionTypes from '../../../constants/Job/JobActionTypes';

const initialModelform = {
  id: undefined,
  status: '',
  name: '',
  publicationType: '',
  pictureFileName: '',
  pictureBase64: '',
  urlPicture: '',
  socialPictureFileName: '',
  socialPictureBase64: '',
  urlSocialPicture: '',
  smartphonePictureFileName: '',
  smartphonePictureBase64: '',
  urlSmartphonePicture: '',
  permissions: undefined,
  careerPageId: '',
  videoSectionTitle: '',
  videoUrl: '',
};

export const modelForm = (state = initialModelform, action) => {
  switch (action.type) {
  case JobPictureActionTypes.GET_SUCCESS:
  case JobPictureActionTypes.SAVE_MEDIA_SUCCESS: {
    const newState = {
      id: action.modelForm.id,
      status: action.modelForm.status,
      name: action.modelForm.name,
      publicationType: action.modelForm.publicationType,
      pictureFileName: action.modelForm.pictureFileName,
      pictureBase64: action.modelForm.pictureBase64,
      urlPicture: action.modelForm.urlPicture,
      socialPictureFileName: action.modelForm.socialPictureFileName,
      socialPictureBase64: action.modelForm.socialPictureBase64,
      urlSocialPicture: action.modelForm.urlSocialPicture,
      smartphonePictureFileName: action.modelForm.smartphonePictureFileName,
      smartphonePictureBase64: action.modelForm.smartphonePictureBase64,
      urlSmartphonePicture: action.modelForm.urlSmartphonePicture,
      permissions: action.modelForm.permissions,
      careerPageId: action.modelForm.careerPageId,
      careerPage: action.modelForm.careerPage,
      videoSectionTitle: action.modelForm.videoSectionTitle || '',
      videoUrl: action.modelForm.videoUrl || '',
    };
    return newState;
  }
  case JobPictureActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case JobPictureActionTypes.SAVE_MEDIA:
    return {};
  case JobPictureActionTypes.SAVE_MEDIA_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobPictureActionTypes.SAVE_MEDIA:
    return true;
  case JobPictureActionTypes.SAVE_MEDIA_SUCCESS:
  case JobPictureActionTypes.SAVE_MEDIA_FAIL:
    return false;
  default:
    return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobPictureActionTypes.GET:
    return true;
  case JobPictureActionTypes.GET_SUCCESS:
  case JobPictureActionTypes.GET_FAIL:
    return false;
  default:
    return state;
  }
};

export const uploadingImageList = (state = [], action) => {
  if (action.type === JobPictureActionTypes.START_UPLOAD_IMAGE) {
    return [...state, action.name];
  }

  if (action.type === JobPictureActionTypes.FINISH_UPLOAD_IMAGE) {
    return state.filter(s => s !== action.name);
  }
  return state;
};

const canEditJobCareerPageSubdomain = (state = false, action) => {
  switch (action.type) {
  case JobPictureActionTypes.GET:
  case JobPictureActionTypes.GET_FAIL:
    return false;
  case JobPictureActionTypes.GET_SUCCESS:
    return action.canEditJobCareerPageSubdomain;
  default:
    return state;
  }
};

const defaultCareerPageId = (state = '', action) => {
  switch (action.type) {
  case JobActionTypes.GET_COMPANY_CAREER_PAGES:
  case JobActionTypes.GET_COMPANY_CAREER_PAGES_FAIL:
    return '';
  case JobActionTypes.GET_COMPANY_CAREER_PAGES_SUCCESS:
    return action.defaultCareerPageId;
  default:
    return state;
  }
};

export default combineReducers({
  validation,
  modelForm,
  isSaving,
  isLoading,
  uploadingImageList,
  canEditJobCareerPageSubdomain,
  defaultCareerPageId,
});
