import * as actions from './Actions';
import EmailTemplateListContainer from './EmailTemplateListContainer';
import EmailTemplateForm from './EmailTemplateForm';
import EmailTemplateReducer from './Reducer';
import EmailTemplateSaga from './Saga';

const { EmailTemplateActionTypes, ...EmailTemplateActions } = actions;

export {
  EmailTemplateActions,
  EmailTemplateListContainer,
  EmailTemplateForm,
  EmailTemplateReducer,
  EmailTemplateSaga,
};
