import api from '../../../api/DarthVaderClient';

export default {
  post(jobId, payload) {
    return api.post(`/job-management/companies/jobs/${jobId}/criterias`, payload);
  },

  patch(criteriaId, jobId, payload) {
    return api.patch(`/job-management/companies/jobs/${jobId}/criterias/${criteriaId}`, payload);
  },

  delete(criteriaId, jobId) {
    return api.delete(`/job-management/companies/jobs/${jobId}/criterias/${criteriaId}`);
  },

  getAll(jobId) {
    return api.get(`/job-management/companies/jobs/${jobId}/criterias?order=name&perPage=9999`);
  },

  generateSkillsFromGaia(jobId) {
    return api.post(`/job-management/companies/jobs/${jobId}/skills/fill-with-gaia-suggestions`);
  },

  search(searchTerm) {
    return api.get(`/job-management/skills?search=${searchTerm}`);
  },
};
