import ActionTypes from './TestCorrectionActionTypes';

export const changeTestCorrection = questions => ({
  type: ActionTypes.CHANGE_SCORE,
  model: {
    questions,
  },
});

export const readTestCorrection = (applicationId, jobId, testId) => ({
  applicationId,
  testId,
  type: ActionTypes.READ,
});

export const saveTestCorrection = ({
  applicationId,
  jobId,
  testId,
  payload,
}) => ({
  type: ActionTypes.SAVE,
  applicationId,
  jobId,
  testId,
  payload,
});
