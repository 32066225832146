import { BaseForm } from '@gupy/front-commons';

class CareerPagePublicForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
      subdomain: {
        validate: {
          notEmpty: true,
          maxLength: 32,
        },
      },
      about: {
        validate: {
          notEmpty: true,
        },
      },
      companyDescriptionTitle: {
        validate: {
          notEmpty: true,
        },
      },
      numVacanciesSectionTitle: {
        validate: {
          notEmpty: true,
        },
      },
      urlSite: {
        validate: {
          url: true,
        },
      },
      urlFacebook: {
        validate: {
          facebookUrl: true,
        },
      },
      urlLinkedin: {
        validate: {
          url: true,
        },
      },
      urlInstagram: {
        validate: {
          url: true,
        },
      },
      urlGlassdoor: {
        validate: {
          url: true,
        },
      },
      urlVideo1: {
        validate: {
          url: true,
        },
      },
      urlVideo2: {
        validate: {
          url: true,
        },
      },
    });
  }
}

export default CareerPagePublicForm;
