import api from '../../../../../api/DarthVaderClient';

const path = '/selection-process/skills-evaluation';

export default {

  get({ applicationId, jobId }) {
    return api.get(`${path}/job/${jobId}/application/${applicationId}`);
  },
};
