import { combineReducers } from 'redux';

import { InterviewEventActionTypes } from './InterviewEventAction';

const initialState = {
  interviewEvents: [],
  isFetchingInterviewEvent: false,
};

export const InterviewEvents = (state = initialState, action) => {
  switch (action.type) {
  case InterviewEventActionTypes.START_FETCHING_INTERVIEW_EVENT_LIST: {
    return {
      ...state,
      isFetchingInterviewEvent: true,
    };
  }
  case InterviewEventActionTypes.GET_BY_INTERVIEWER_ID_SUCCESS: {
    return {
      ...state,
      interviewEvents: action.interviewEvents || [],
      isFetchingInterviewEvent: false,
    };
  }
  case InterviewEventActionTypes.CLEAR_INTERVIEW_EVENT_LIST: {
    return {
      ...state,
      interviewEvents: [],
      isFetchingInterviewEvent: false,
    };
  }
  default:
    return state;
  }
};

export default combineReducers({
  InterviewEvents,
});
