export const GET_ALL_REQUEST_V2 = 'notifications/GET_ALL_REQUEST_V2';
export const GET_ALL_SUCCESS_V2 = 'notifications/GET_ALL_SUCCESS_V2';
export const GET_ALL_FAILURE_V2 = 'notifications/GET_ALL_FAILURE_V2';

export const REMOVE_REQUEST_V2 = 'notifications/REMOVE_REQUEST_V2';
export const REMOVE_SUCCESS_V2 = 'notifications/REMOVE_SUCCESS_V2';
export const REMOVE_FAILURE_V2 = 'notifications/REMOVE_FAILURE_V2';

export const VIEW_REQUEST_V2 = 'notifications/VIEW_REQUEST_V2';
export const VIEW_SUCCESS_V2 = 'notifications/VIEW_SUCCESS_V2';
export const VIEW_FAILURE_V2 = 'notifications/VIEW_FAILURE_V2';
