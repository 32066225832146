import { put, takeLatest, call } from 'redux-saga/effects';

import JobService from '../../../../../services/Job/JobService';
import JobStepRegisterForm from '../../../../../forms/Job/JobStep/JobStepRegisterForm';
import { JobStepRegisterActionTypes } from './JobStepRegisterAction';

function* getJobStepRegister(action) {
  const form = new JobStepRegisterForm();

  try {
    const response = yield JobService.getJobStep(action.jobId, action.stepId);

    yield put({
      type: JobStepRegisterActionTypes.GET_SUCCESS,
      modelForm: response.body,
    });
  } catch (error) {
    yield put({
      type: JobStepRegisterActionTypes.GET_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* patchJobStepRegister(action) {
  const { payload, successCallback } = action;
  const form = new JobStepRegisterForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobStepRegisterActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.patchStep(payload);

      yield put({
        type: JobStepRegisterActionTypes.PATCH_SUCCESS,
        modelForm: response.body,
      });
      if (successCallback) {
        yield call(successCallback);
      }
    } catch (error) {
      yield put({
        type: JobStepRegisterActionTypes.PATCH_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* JobStepRegisterSaga() {
  yield takeLatest(JobStepRegisterActionTypes.GET, getJobStepRegister);
  yield takeLatest(JobStepRegisterActionTypes.PATCH, patchJobStepRegister);
}

export default JobStepRegisterSaga;
