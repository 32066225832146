import api from '../../api/DarthVaderClient';

const apiPath = '/task-manager/company/task';

export default {
  get() {
    return api.get(apiPath);
  },
  delete({ taskId }) {
    return api.delete(`${apiPath}/${taskId}`);
  },
};
