const JobStepTypes = {
  register: 'register',
  online: 'online',
  offline: 'offline',
  preHire: 'pre_hire',
  final: 'final',
  internal: 'internal',
};

export default JobStepTypes;
