import JobStepsActionTypes from '../../../constants/Job/JobStep/JobStepsActionTypes';

import JobStepTypes from '../../../constants/Job/JobStep/JobStepTypes';

export const getSteps = id => ({
  type: JobStepsActionTypes.GET,
  id,
});

export const getStepSuccess = ({ steps }) => ({
  type: JobStepsActionTypes.GET_SUCCESS,
  steps: {
    initial: steps.filter(step => step.type === JobStepTypes.register),
    custom: steps
      .filter(
        step => step.type === JobStepTypes.offline
        || step.type === JobStepTypes.online
        || step.type === JobStepTypes.internal,
      ),
    final: steps
      .filter(step => step.type === JobStepTypes.final || step.type === JobStepTypes.preHire)
      .map(step => ({
        ...step,
        isValidStep: step.type === JobStepTypes.final && !!step.endDate,
      })),
  },
});

export const patchSteps = payload => ({
  type: JobStepsActionTypes.PATCH,
  payload,
});

export const changeForm = modelForm => ({
  type: JobStepsActionTypes.CHANGE_FORM,
  modelForm,
});

export const deleteStep = (jobId, stepId) => ({
  type: JobStepsActionTypes.DELETE,
  jobId,
  stepId,
});

export const changePositionStep = ({ actual, destination, steps, jobId }) => {
  const newSteps = steps;
  const [removed] = steps.custom.splice(actual, 1);
  steps.custom.splice(destination, 0, removed);

  newSteps.custom = steps.custom.map((step, index) => ({
    ...step,
    order: index + 1,
  }));

  const concatSteps = newSteps.initial.concat(newSteps.custom).concat(newSteps.final);

  return {
    type: JobStepsActionTypes.REORDER,
    steps: concatSteps.map(step => ({ id: step.id, order: step.order, type: step.type })),
    jobId,
  };
};
