import { combineReducers } from 'redux';
import { InterviewScriptActionTypes } from './InterviewScriptAction';

export const isLoading = (state = false, { type }) => {
  switch (type) {
  case InterviewScriptActionTypes.CREATE:
  case InterviewScriptActionTypes.CREATE_ANSWER:
  case InterviewScriptActionTypes.LIST:
  case InterviewScriptActionTypes.DELETE:
  case InterviewScriptActionTypes.GET:
  case InterviewScriptActionTypes.GET_PREVIEW:
  case InterviewScriptActionTypes.LIST_STEPS:
  case InterviewScriptActionTypes.UPDATE:
  case InterviewScriptActionTypes.SIMPLE_LIST:
  case InterviewScriptActionTypes.UPDATE_ANSWER:
    return true;
  case InterviewScriptActionTypes.CREATE_ANSWER_SUCCESS:
  case InterviewScriptActionTypes.CREATE_ANSWER_FAIL:
  case InterviewScriptActionTypes.CREATE_SUCCESS:
  case InterviewScriptActionTypes.CREATE_FAIL:
  case InterviewScriptActionTypes.GET_SUCCESS:
  case InterviewScriptActionTypes.GET_FAIL:
  case InterviewScriptActionTypes.GET_PREVIEW_SUCCESS:
  case InterviewScriptActionTypes.GET_PREVIEW_FAIL:
  case InterviewScriptActionTypes.LIST_STEPS_SUCCESS:
  case InterviewScriptActionTypes.LIST_STEPS_FAIL:
  case InterviewScriptActionTypes.LIST_SUCCESS:
  case InterviewScriptActionTypes.LIST_FAIL:
  case InterviewScriptActionTypes.DELETE_SUCCESS:
  case InterviewScriptActionTypes.DELETE_FAIL:
  case InterviewScriptActionTypes.UPDATE_SUCCESS:
  case InterviewScriptActionTypes.UPDATE_FAIL:
  case InterviewScriptActionTypes.SIMPLE_LIST_FAIL:
  case InterviewScriptActionTypes.SIMPLE_LIST_SUCCESS:
  case InterviewScriptActionTypes.UPDATE_ANSWER_SUCCESS:
  case InterviewScriptActionTypes.UPDATE_ANSWER_FAIL:
    return false;
  default:
    return state;
  }
};
export const isLoadingSteps = (state = false, { type }) => {
  switch (type) {
  case InterviewScriptActionTypes.LIST_STEPS:
    return true;
  case InterviewScriptActionTypes.LIST_STEPS_SUCCESS:
  case InterviewScriptActionTypes.LIST_STEPS_FAIL:
    return false;
  default:
    return state;
  }
};

export const interviewScript = (state = {}, action) => {
  switch (action.type) {
  case InterviewScriptActionTypes.CREATE_ANSWER_SUCCESS:
  case InterviewScriptActionTypes.GET_SUCCESS:
  case InterviewScriptActionTypes.GET_PREVIEW_SUCCESS:
  case InterviewScriptActionTypes.UPDATE_ANSWER_SUCCESS:
    return action.payload;
  case InterviewScriptActionTypes.LIST:
  case InterviewScriptActionTypes.CREATE:
  case InterviewScriptActionTypes.UPDATE:
  case InterviewScriptActionTypes.LIST_STEPS:
  case InterviewScriptActionTypes.CLEAN_INTERVIEW_SCRIPT:
    return {};
  case InterviewScriptActionTypes.GET:
  case InterviewScriptActionTypes.GET_PREVIEW:
  default:
    return state;
  }
};

export const interviewScripts = (state = [], action) => {
  switch (action.type) {
  case InterviewScriptActionTypes.CREATE_SUCCESS:
    return [
      ...state,
      action.payload,
    ];
  case InterviewScriptActionTypes.LIST_SUCCESS:
  case InterviewScriptActionTypes.UPDATE_SUCCESS:
  case InterviewScriptActionTypes.DELETE_SUCCESS:
    return action.payload;
  case InterviewScriptActionTypes.LIST:
  default:
    return state;
  }
};

export const steps = (state = [], action) => {
  switch (action.type) {
  case InterviewScriptActionTypes.LIST_STEPS_SUCCESS:
    return action.payload;
  case InterviewScriptActionTypes.LIST_STEPS:
  default:
    return state;
  }
};

export const interviewScriptsV2 = (state = [], action) => {
  switch (action.type) {
  case InterviewScriptActionTypes.SIMPLE_LIST_SUCCESS:
    return action.payload;
  case InterviewScriptActionTypes.SIMPLE_LIST:
  default:
    return state;
  }
};

export default combineReducers({
  isLoading,
  isLoadingSteps,
  interviewScript,
  interviewScripts,
  interviewScriptsV2,
  steps,
});
