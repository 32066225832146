import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';

import { InputPhoneDictionaryFactory, phoneTypes, PhoneNumber, TextEditor } from '@gupy/front-commons';
import { Button, Divisor, Grid, SwitchButton, Dropdown, InputGroup, InputLabel } from '@gupy/design-system';

import { ActionGAEnum, CategoryGAEnum } from '../../../../../../../src/constants/Job/JobApplication/JobApplicationGAEnum';
import getLabels from '../CandidateInsertion.labels';
import { withSendGAEvent } from '../../../../../../helpers/withSendGAEvent';

const propTypes = {
  candidateInsertionSources: PropTypes.array.isRequired,
  disabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  jobSteps: PropTypes.array.isRequired,
  modelForm: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,

  onAddCandidate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeDropdown: PropTypes.func.isRequired,
};

class BasicInformationForm extends Component {
  labels = {};

  constructor(props) {
    super(props);

    this.labels = getLabels(props.intl);
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    withSendGAEvent(
      CategoryGAEnum.jobApplicationsAddCandidate,
      ActionGAEnum.jobApplicationsConfirmAddCandidate,
      () => this.props.onAddCandidate(),
    )();
  }

  render() {
    const {
      disabled,
      jobSteps,
      candidateInsertionSources,
      modelForm,
      onChange,
      onChangeDropdown,
      validation,
      intl,
    } = this.props;

    const phoneDictionary = InputPhoneDictionaryFactory(defineMessages, intl);

    return (
      <form onSubmit={this.handleFormSubmit} noValidate className="candidate-insertion-modal__form">
        <h5 className="candidate-insertion-modal__form__title">
          {this.labels.basicInformation}
        </h5>
        <Grid container spacing={40}>
          <Grid item xs={12} sm={12} md={6}>
            <Dropdown
              id="currentStepId"
              label={this.labels.chooseStep}
              nonSelectText={this.labels.chooseStep}
              onChange={value => onChangeDropdown({ name: 'currentStepId', value })}
              required
              selectedOptionValue={modelForm.currentStepId}
              options={jobSteps}
            />
          </Grid>
          <Grid item xs={12}>
            <Divisor />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputGroup
              id="name"
              label={this.labels.name}
              placeholder={this.labels.name}
              value={modelForm.name}
              onChange={onChange}
              block
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputGroup
              id="lastName"
              label={this.labels.lastName}
              placeholder={this.labels.lastName}
              value={modelForm.lastName}
              onChange={onChange}
              block
              required
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputGroup
              id="email"
              label={this.labels.email}
              disabled
              value={modelForm.email}
              block
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputGroup
              id="linkedinProfileUrl"
              label="LinkedIn"
              placeholder="LinkedIn"
              value={modelForm.linkedinProfileUrl}
              onChange={onChange}
              disabled={disabled}
              block
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <InputLabel className="phone-label">{this.labels.mobileNumber}</InputLabel>
            <PhoneNumber
              name={phoneTypes.mobile}
              dictionary={phoneDictionary}
              value={modelForm.mobileNumber || ''}
              onChange={onChange}
              validation={validation.mobileNumber}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Dropdown
              id="candidateInsertionSource"
              label={this.labels.candidateInsertionSource}
              nonSelectText={this.labels.candidateInsertionSource}
              onChange={value => onChangeDropdown({ name: 'candidateInsertionSource', value })}
              required
              selectedOptionValue={modelForm.candidateInsertionSource}
              options={candidateInsertionSources}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <TextEditor
              name="comment"
              id="comment"
              label={this.labels.candidateInsertionComment}
              value={modelForm.comment}
              disabled={disabled}
              onChange={onChange}
            />
            <Grid item xs={12} container>
              <Grid item xs={6} sm={6} md={6}>
                <p className="candidate-insertion-modal__form__comment-info root">
                  {this.labels.hideAnnotationInfo}
                </p>
              </Grid>
              <Grid item xs={6} sm={6} md={6} container justify="flex-end">
                <SwitchButton
                  id="is-private"
                  name="isPrivate"
                  labelClassName="candidate-insertion-modal__form__comment-toggle root"
                  label={this.labels.hideAnnotation}
                  onChange={value => onChange({ target: { name: 'isPrivate', value } })}
                  checked={modelForm.isPrivate}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <SwitchButton
              name="shouldSendEmailInvitation"
              id="shouldSendEmailInvitation"
              label={this.labels.sendInviteEmailToCandidate}
              checked={modelForm.shouldSendEmailInvitation}
              disabled={disabled}
              onChange={value => onChange({ target: { name: 'shouldSendEmailInvitation', value } })}
            />
          </Grid>
          <Grid item xs={12} container justify="center">
            <Button
              disabled={disabled}
              submit
            >
              {this.labels.addCandidate}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

BasicInformationForm.propTypes = propTypes;

export default BasicInformationForm;
