import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobApplicationHistoryActionTypes = createActionTypes('JobApplicationHistory',
  asyncActionType('INIT_JOB_APPLICATION_HISTORY'),
  asyncActionType('NEXT_PAGE_JOB_APPLICATION_HISTORY'),
  'TOGGLE_COLLAPSE');

const initJobApplicationHistory = ({ candidateId, currentJobId }) => ({
  type: JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY,
  candidateId,
  currentJobId,
});

const nextPageJobApplicationHistory = ({ candidateId, currentJobId, page }) => ({
  type: JobApplicationHistoryActionTypes.NEXT_PAGE_JOB_APPLICATION_HISTORY,
  candidateId,
  currentJobId,
  page,
});

const toggleCollapse = ({ expanded, candidateId, jobId, applicationId, currentTab, job }) => ({
  type: JobApplicationHistoryActionTypes.TOGGLE_COLLAPSE,
  expanded,
  expandedOn: {
    candidateId,
    jobId,
    applicationId,
    tab: currentTab,
    job,
  },
});
export {
  JobApplicationHistoryActionTypes,
  initJobApplicationHistory,
  nextPageJobApplicationHistory,
  toggleCollapse,
};
