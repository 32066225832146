import api from '../../api/DarthVaderClient';

const apiPath = '/job-management/companies/jobs/custom-form-templates';

export default {
  get() {
    return api.get(apiPath);
  },
  patch(newValue) {
    return api.patch(apiPath, newValue);
  },
};
