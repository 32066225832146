import { combineReducers } from 'redux';

import DepartmentActionTypes from '../../constants/Department/DepartmentActionTypes';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';

const initialModelForm = {
  id: undefined,
  name: '',
  similarity: undefined,
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case DepartmentActionTypes.POST:
  case DepartmentActionTypes.PUT:
    return true;
  case DepartmentActionTypes.POST_SUCCESS:
  case DepartmentActionTypes.POST_FAIL:
  case DepartmentActionTypes.PUT_SUCCESS:
  case DepartmentActionTypes.PUT_FAIL:
    return false;
  default:
    return state;
  }
};

const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case DepartmentActionTypes.POST_SUCCESS:
  case DepartmentActionTypes.PUT_SUCCESS:
  case DepartmentActionTypes.CLEAR_FORM:
    return initialModelForm;
  case DepartmentActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  case DepartmentActionTypes.EDIT:
    return Object.assign(
      {},
      initialModelForm,
      action.modelForm,
    );
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case DepartmentActionTypes.POST:
  case DepartmentActionTypes.PUT:
  case DepartmentActionTypes.EDIT:
  case DepartmentActionTypes.CLEAR_FORM:
    return {};
  case DepartmentActionTypes.POST_FAIL:
  case DepartmentActionTypes.PUT_FAIL:
  case DepartmentActionTypes.GET_ALL_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const departments = (state = [], action) => {
  switch (action.type) {
  case DepartmentActionTypes.GET_ALL_SUCCESS:
  case JobDetailsActionTypes.INIT_JOB_DETAILS_SUCCESS:
    return action.departments;
  case DepartmentActionTypes.GET_ALL_FAIL:
    return [];
  default:
    return state;
  }
};

const similarities = (state = [], action) => {
  switch (action.type) {
  case DepartmentActionTypes.GET_ALL_SIMILARITIES_SUCCESS:
    return action.similarities;
  case DepartmentActionTypes.GET_ALL_SIMILARITIES_FAIL:
    return [];
  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  validation,
  departments,
  similarities,
});
