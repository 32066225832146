import { buildToast, ToastTypes } from '@gupy/front-commons';
import { put, takeLatest } from 'redux-saga/effects';
import JobService from '../../services/Job/JobService';
import JobShareService from '../../services/JobShare/JobShareService';
import ProfileService from '../../services/Profile/ProfileService';
import { initSuccess, JobShareActionTypes } from './Actions';
import JobShareForm from './forms/JobShareForm';

function* sendEmail(action) {
  const { payload, messages } = action;
  const form = new JobShareForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobShareActionTypes.SEND_EMAIL_FAIL,
      validation,
    });
  } else {
    try {
      const destinations = payload.destinations.split(';');
      const data = Object.assign({}, payload, { destinations });

      yield JobShareService.sendEmail(data);

      yield put({
        type: JobShareActionTypes.SEND_EMAIL_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: JobShareActionTypes.SEND_EMAIL_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* updatePublishingChannels(action) {
  const { payload } = action;
  const form = new JobShareForm();

  try {
    const response = yield JobShareService.updatePublishingChannels(payload);
    yield put({
      type: JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS_SUCCESS,
      modelForm: response.body,
      id: response.body.id,
      jobId: response.body.id,
      jobBoards: response.body.jobBoards,
    });
  } catch (error) {
    yield put({
      type: JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* getJobBoards() {
  try {
    const response = yield JobShareService.getJobBoards();
    yield put({
      type: JobShareActionTypes.GET_JOB_BOARDS_SUCCESS,
      boards: response.body.data,
    });
  } catch (error) {
    yield put({
      type: JobShareActionTypes.GET_JOB_BOARDS_FAIL,
    });
  }
}

function* init(action) {
  const { jobId, htmlMessage } = action;

  try {
    const jobBoardsResponse = yield JobShareService.getJobBoards();
    const profileResponse = yield ProfileService.get();
    const jobResponse = yield JobService.get(jobId);
    const careerPageResponse = yield JobService.getCareerPageSubdomain(jobId);

    yield put(
      initSuccess(
        jobBoardsResponse.body.data,
        profileResponse.body,
        htmlMessage,
        jobResponse.body,
        careerPageResponse.body.subdomain,
      ),
    );
  } catch (error) {
    yield put({
      type: JobShareActionTypes.INIT_FAIL,
    });
  }
}

function* ShareSaga() {
  yield takeLatest(JobShareActionTypes.GET_JOB_BOARDS, getJobBoards);
  yield takeLatest(JobShareActionTypes.SEND_EMAIL, sendEmail);
  yield takeLatest(JobShareActionTypes.INIT, init);
  yield takeLatest(
    JobShareActionTypes.UPDATE_PUBLISHING_CHANNELS,
    updatePublishingChannels,
  );
}

export default ShareSaga;
