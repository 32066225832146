import '../_styles.scss';

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@gupy/design-system';
import { withStyles } from '@material-ui/core/styles';
import DSDialog from '@material-ui/core/Dialog';
import DSDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { steps, stepsOrder } from '../constants';
import { IntroContent } from './IntroContent';
import { TermsContent } from './TermsContent';

const Dialog = withStyles(theme => ({
  paper: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
      height: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: '16px',
      marginRight: '16px',
    },
  },
}))(({ children, classes, ...props }) => (
  <DSDialog {...props} classes={classes}>
    {children}
  </DSDialog>
));

const DialogActions = withStyles(() => ({
  root: {
    margin: 0,
    padding: '24px',
  },
}))(({ classes, children }) => (
  <DSDialogActions classes={classes}>
    {children}
  </DSDialogActions>
));

const propTypes = {
  initialStep: PropTypes.string.isRequired,
  messages: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
};

const DiversityLegalTermsModal = (({ onClose, messages, initialStep, locale }) => {
  const [currentStep, setCurrentStep] = useState(initialStep);
  const handleClick = useCallback(() => {
    if (currentStep === steps.signedTerm) {
      onClose();
      return;
    }
    const currentStepIndex = stepsOrder.indexOf(currentStep);
    const nextStep = stepsOrder[`${currentStepIndex + 1}`];
    setCurrentStep(nextStep);
  }, [currentStep, setCurrentStep]);

  return (
    <Dialog
      open
      onClose={onClose}
      disableBackdropClick
      aria-labelledby="diversity-term-title"
      aria-describedby="diversity-term-description"
    >
      <DialogContent>
        {currentStep === steps.intro && <IntroContent messages={messages} />}

        {currentStep === steps.signedTerm && (
          <TermsContent messages={messages} locale={locale} />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          className="diversity-term__button"
          onClick={handleClick}
        >
          {currentStep === steps.signedTerm
            ? messages.confirmButtonTextContent
            : messages.nextButtonTextContent
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
});

DiversityLegalTermsModal.propTypes = propTypes;

export default DiversityLegalTermsModal;
