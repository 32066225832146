import React from 'react';
import PropTypes from 'prop-types';

import { InputGroup } from '@gupy/design-system';
import { injectIntl, intlShape } from 'react-intl';
import { getMessages } from '../Messages';

const propTypes = {
  variables: PropTypes.array,
  value: PropTypes.string,
  validation: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
};

const defaultProps = {
  variables: [],
  required: false,
  disabled: false,
  validation: undefined,
  value: undefined,
};

const MAX_LENGTH_EMAIL_SUBJECT = 255;

class EmailTemplateSubjectBuilder extends React.Component {
  translateToView = (text) => {
    let translatedText = text;
    this.props.variables.forEach((markup) => {
      translatedText = translatedText.split(markup.value).join(markup.label);
    });
    return translatedText;
  };

  translateToModel = (text) => {
    let translatedText = text;
    if (!translatedText) { return ''; }
    this.props.variables.forEach((markup) => {
      translatedText = translatedText.split(markup.label).join(markup.value);
    });
    return translatedText;
  };

  handleChange = name => (event) => {
    const newEvent = event;
    if (newEvent && newEvent.target) {
      newEvent.target.value = this.translateToModel(newEvent.target.value);
    }
    newEvent.target.name = name;
    this.props.onChange(newEvent);
  }

  isSubjectTooLong = (value) => {
    if (value.length >= MAX_LENGTH_EMAIL_SUBJECT) {
      return true;
    }
    return false;
  }

  render() {
    const { validation, value, id, name, label, disabled, required } = this.props;
    this.messages = getMessages(this.props.intl);
    return (
      <InputGroup
        id={id}
        label={label}
        required={required}
        validation={validation}
        disabled={disabled}
        maxLength={MAX_LENGTH_EMAIL_SUBJECT}
        tipMessage={this.isSubjectTooLong(value) ? this.messages.emailSubjectIsTooLong : validation}
        tipType={this.isSubjectTooLong(value) ? 'warning' : validation}
        value={this.translateToView(value)}
        onChange={this.handleChange(name)}
        block
      />
    );
  }
}

EmailTemplateSubjectBuilder.propTypes = propTypes;
EmailTemplateSubjectBuilder.defaultProps = defaultProps;

export default (injectIntl(EmailTemplateSubjectBuilder));
