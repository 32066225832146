import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';

import { Button, Grid } from '@gupy/design-system';

import getLabels from '../CandidateInsertion.labels';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  intl: intlShape.isRequired,
  leftButtonText: PropTypes.string.isRequired,
  rightButtonText: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleHighlight: PropTypes.string.isRequired,

  onLeftButtonClick: PropTypes.func.isRequired,
  onRightButtonClick: PropTypes.func.isRequired,
};

class ActionBody extends Component {
  labels = {};

  constructor(props) {
    super(props);

    this.labels = getLabels(props.intl);
  }

  render() {
    const {
      disabled,
      leftButtonText,
      onLeftButtonClick,
      onRightButtonClick,
      rightButtonText,
      title,
      titleHighlight,
    } = this.props;

    return (
      <React.Fragment>
        <Grid container justify="center">
          <h5 className="candidate-insertion-modal__form__info">
            <span className="highlighted-text">{titleHighlight}</span>
            {` ${title}`}
          </h5>
        </Grid>
        <Grid container justify="center" className="candidate-insertion-modal__form__action-buttons">
          <Button
            disabled={disabled}
            onClick={onLeftButtonClick}
            type="outlined"
          >
            {leftButtonText}
          </Button>
          <Button
            disabled={disabled}
            onClick={onRightButtonClick}
            type="outlined"
          >
            {rightButtonText}
          </Button>
        </Grid>
      </React.Fragment>
    );
  }
}

ActionBody.propTypes = propTypes;

export default ActionBody;
