import { CareerPageVisibility } from '../../Constants';

export const validationInitialValues = {
  userTestimonials: [],
  candidateTestimonials: [],
};

const initialValues = {
  id: null,
  name: '',
  subdomain: '',
  about: '',
  testimonialCandidateDescription: '',
  testimonialUserDescription: '',
  numVacanciesDescription: '',
  videoDescription: '',
  urlVideo1: '',
  urlVideo2: '',
  urlLinkedin: '',
  urlFacebook: '',
  urlInstagram: '',
  urlGlassdoor: '',
  urlSite: '',
  companyDescriptionTitle: '',
  numVacanciesSectionTitle: '',
  candidatesTestimonySectionTitle: '',
  employeesTestimonySectionTitle: '',
  videoSectionTitle: '',
  knowMoreSectionTitle: '',
  userTestimonials: [],
  candidateTestimonials: [],
  disableTaxpayerRegistryRequirement: false,
  language: '',
  visibility: CareerPageVisibility.confidential,
  urlInstitutionalImageTotalToShow: '3',
  employeeTestimonialDescription: '',
  candidateTestimonialDescription: '',
  employeeTestimonialTitle: '',
  candidateTestimonialTitle: '',
  userTestimonialsIdReference: 0,
  candidateTestimonialsIdReference: 0,
  jobBoardsName: '',
  institutionalImage1TempPath: '',
  institutionalImage2TempPath: '',
  institutionalImage3TempPath: '',
  mainImageTempPath: '',
  logoTempPath: '',
};

export default initialValues;
