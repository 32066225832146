import dayjs from 'dayjs';

export const JobOfferAdapter = {
  toFormValues(offer) {
    return {
      salary: offer.salaryAmount,
      hiringType: offer.admissionType,
      salaryCurrencyType: offer.salaryCurrency,
      hiringDate: offer.admissionDate && dayjs(offer.admissionDate),
    };
  },
};
