import { takeLatest, put, call, all } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';

import MarketplaceAppsService from '../../services/MarketPlaceApps/Service';

import * as ActionTypes from './ActionTypes';

export function* getAppList() {
  try {
    const { data } = yield call(MarketplaceAppsService.getAppsList);
    yield put({
      type: ActionTypes.GET_APP_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_APP_LIST_FAIL,
    });
  }
}

export function* getAppById({ payload: { appId } }) {
  try {
    const [
      { data: appDetails },
      { data: companyAppSettings },
    ] = yield all([
      call(MarketplaceAppsService.getAppById, appId),
      call(MarketplaceAppsService.getCompanyAppSettings, appId),
    ]);
    yield put({
      type: ActionTypes.GET_APP_SUCCESS,
      payload: { appDetails, companyAppSettings },
    });
  } catch (error) {
    yield put({
      type: ActionTypes.GET_APP_FAIL,
    });
  }
}

export function* putCompanyAppSettings({ payload: { appId, data, messages } }) {
  try {
    yield call(MarketplaceAppsService.putCompanyAppSettings, appId, data);
    yield put({
      type: ActionTypes.PUT_APP_OPTIONS_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: ActionTypes.PUT_APP_OPTIONS_FAIL,
      toast: buildToast(messages.error, ToastTypes.error),
    });
  }
}

function* CompanyAppsSagas() {
  yield takeLatest(ActionTypes.GET_APP_LIST_REQUEST, getAppList);
  yield takeLatest(ActionTypes.GET_APP_REQUEST, getAppById);
  yield takeLatest(ActionTypes.PUT_APP_OPTIONS_REQUEST, putCompanyAppSettings);
}

export default CompanyAppsSagas;
