/* eslint-disable class-methods-use-this */
import api from '../../api/DarthVaderClient';

class Service {
  get() {
    return api.get('/authentication/companies/users/current/profile');
  }

  patch(payload) {
    return api.patch('/authentication/companies/users/current/profile', payload);
  }

  getCountries() {
    return api.get('/address-service/public/country');
  }
}

export default Service;
