import JobStepCustomActionTypes from '../../../constants/Job/JobStep/JobStepCustomActionTypes';

const changeForm = modelForm => ({
  type: JobStepCustomActionTypes.CHANGE_FORM,
  modelForm,
});

const clearForm = () => ({
  type: JobStepCustomActionTypes.CLEAR_FORM,
});

const postJobStepCustom = payload => ({
  type: JobStepCustomActionTypes.POST,
  payload,
});

const getJobStepCustom = (jobId, stepId) => ({
  type: JobStepCustomActionTypes.GET,
  jobId,
  stepId,
});

const patchJobStepCustom = payload => ({
  type: JobStepCustomActionTypes.PATCH,
  payload,
});

const validateJobStepCustom = (payload, jobPath) => ({
  type: JobStepCustomActionTypes.VALIDATE_JOB_STEP_CUSTOM,
  payload,
  jobPath,
});

const getTestProviders = () => ({
  type: JobStepCustomActionTypes.GET_TEST_PROVIDERS,
});

const getTestProvidersSuccess = (testProviders) => {
  const providers = testProviders
    ? testProviders.filter(provider => provider.isRegistered && provider.isActiveToCompany)
    : [];
  const testProvidersObj = {};
  providers.forEach((provider) => {
    testProvidersObj[provider.uuid] = {
      uuid: provider.uuid,
      name: provider.name,
      isLoading: false,
      hasError: false,
      tests: [],
    };
  });

  return {
    type: JobStepCustomActionTypes.GET_TEST_PROVIDERS_SUCCESS,
    testProviders: testProvidersObj,
  };
};

const getTestsFromProvider = (testProvider, language) => ({
  type: JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER,
  testProvider: { ...testProvider, isLoading: true, hasError: false },
  language,
});

const getTestsFromProviderSuccess = (testsFromProvider, testProvider) => ({
  type: JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER_SUCCESS,
  testProvider: { ...testProvider, isLoading: false, tests: [...testsFromProvider] },
});

const getTestsFromProviderFail = (error, testProvider) => ({
  type: JobStepCustomActionTypes.GET_TESTS_FROM_PROVIDER_FAIL,
  testProvider: { ...testProvider, isLoading: false, hasError: true, tests: [] },
  error,
});

export {
  changeForm,
  clearForm,
  postJobStepCustom,
  getJobStepCustom,
  patchJobStepCustom,
  validateJobStepCustom,
  getTestProviders,
  getTestProvidersSuccess,
  getTestsFromProvider,
  getTestsFromProviderSuccess,
  getTestsFromProviderFail,
};
