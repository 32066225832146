import { all, put, select, takeLatest } from 'redux-saga/effects';
import JobService from '../../../../../services/Job/JobService';
import JobOfferService from '../../../../JobOffer/JobOfferService';
import { HiringInformationActionTypes } from './HiringInformationAction';
import { selectCandidate, selectJob } from './HiringInformationSelectors';

function* getJobOffers() {
  try {
    const [job, candidate] = yield all([select(selectJob), select(selectCandidate)]);
    const response = yield JobOfferService.get({ job, candidate });
    const { offers } = response.body;

    yield put({
      type: HiringInformationActionTypes.GET_JOB_OFFERS_SUCCESS,
      payload: { offers },
    });
  } catch (error) {
    yield put({
      type: HiringInformationActionTypes.GET_JOB_OFFERS_FAIL,
      error,
    });
  }
}

function* getHiringTypes() {
  try {
    const response = yield JobService.getHiringTypes();

    yield put({
      type: HiringInformationActionTypes.GET_HIRING_TYPES_SUCCESS,
      hiringTypes: response.body.data,
    });
  } catch (error) {
    yield put({
      type: HiringInformationActionTypes.GET_HIRING_TYPES_FAIL,
      error,
    });
  }
}

function* getVacancyCodes(action) {
  const { jobId, applicationId } = action;

  try {
    const response = yield JobService.getAvailableJobVacancyCodes(jobId, applicationId);

    yield put({
      type: HiringInformationActionTypes.GET_VACANCY_CODES_SUCCESS,
      vacancyCodes: response.body.data,
    });
  } catch (error) {
    yield put({
      type: HiringInformationActionTypes.GET_VACANCY_CODES_FAIL,
      error,
    });
  }
}

function* HiringInformationSaga() {
  yield takeLatest(HiringInformationActionTypes.GET_JOB_OFFERS, getJobOffers);
  yield takeLatest(HiringInformationActionTypes.GET_HIRING_TYPES, getHiringTypes);
  yield takeLatest(HiringInformationActionTypes.GET_VACANCY_CODES, getVacancyCodes);
}

export default HiringInformationSaga;
