import { combineReducers } from 'redux';

import UserProfileActionTypes from './ActionTypes';

import * as CompanyUserInfoActionTypes from './ActionTypesCompanyUser';

const initialModelForm = {
  name: '',
  email: '',
  currentPassword: '',
  newPassoword: '',
  urlProfilePicture: '',
  profilePictureBase64: '',
  profilePictureFileName: '',
  userId: null,
  answers: { 'Interesses com RH': [], Perfil: '' },
  selectedCountry: { label: '', value: '' },
  taxpayerRegistry: '',
};

const initialCountriesState = {
  originCountries: [{ label: 'Brasil', value: 'BR' }],
};

export const countries = (state = initialCountriesState, action) => {
  switch (action.type) {
  case CompanyUserInfoActionTypes.GET_COUNTRIES_SUCCESS:
    return {
      ...state, originCountries: [...action.payload],
    };
  default:
    return state;
  }
};

const formatSelectedCountry = (countryCode, countriesArray) => {
  if (!countryCode) { return initialModelForm.selectedCountry; }
  const countryRaw = countriesArray.find(country => country.code === countryCode);
  return { value: countryRaw.code, label: countryRaw.name };
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case UserProfileActionTypes.GET_SUCCESS:
  case UserProfileActionTypes.PATCH_SUCCESS: {
    const newState = {
      answers: state.answers,
      name: action.modelForm.name,
      email: action.modelForm.email,
      urlProfilePicture: action.modelForm.urlProfilePicture || '',
      language: action.modelForm.language,
      selectedCountry: formatSelectedCountry(action.modelForm.countryOfOrigin, action.countries),
      taxpayerRegistry: action.modelForm.taxpayerRegistry || initialModelForm.taxpayerRegistry,
    };
    return newState;
  }

  case CompanyUserInfoActionTypes.PATCH_COUNTRIES_SUCCESS:
    return {
      ...state, selectedCountry: { ...action.payload.selectedCountry },
    };
  case CompanyUserInfoActionTypes.TAXPAYER_REGISTRY_SUCCESS:
    return {
      ...state, taxpayerRegistry: action.payload.taxpayerRegistry,
    };
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case UserProfileActionTypes.PATCH:
  case UserProfileActionTypes.CANCEL_EDIT:
    return {};
  case UserProfileActionTypes.PATCH_FAIL:
  case UserProfileActionTypes.GET_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case UserProfileActionTypes.PATCH:
    return true;
  case UserProfileActionTypes.PATCH_SUCCESS:
  case UserProfileActionTypes.PATCH_FAIL:
    return false;
  default:
    return state;
  }
};

const initialIsEditing = {
  name: false,
  password: false,
  language: false,
  picture: false,
  selectedCountry: false,
  taxpayerRegistry: false,
};

export const isEditing = (state = initialIsEditing, action) => {
  switch (action.type) {
  case UserProfileActionTypes.EDIT:
    return { ...state, [action.payload]: true };
  case UserProfileActionTypes.CANCEL_EDIT:
    return { ...state, [action.payload]: false };
  case UserProfileActionTypes.PATCH_SUCCESS: {
    const newState = { ...state };
    Object.keys(action.payload).forEach((key) => {
      if (key === 'newPassword') {
        newState.password = false;
      } else {
        newState[key] = false;
      }
    });
    return newState;
  }
  case UserProfileActionTypes.PATCH_FAIL: {
    if ('taxpayerRegistry' in action.validation) {
      return {
        ...state,
        taxpayerRegistry: true,
      };
    }
    return { ...state };
  }

  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  validation,
  isSaving,
  isEditing,
  countries,
});
