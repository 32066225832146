const CookieConstants = {
  locale: 'locale',
  companyToken: 'company_token',
  lastAuthSubdomain: 'last_company_user_auth_subdomain',
  redirectUrl: 'company_redirect_url',
  userSubdomain: 'company_user_subdomain',
  companyUserToken: 'company_user_token',
  secondaryToken: 'secondaryToken',
  exchangeTokenError: 'exchange_token_error',
};

export default CookieConstants;
