import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { CardWithTitle } from '@gupy/design-system';

import JobCustomFormTemplateMenuButton from './JobCustomFormTemplateMenuButton';

import './JobCustomFormTemplateCards.scss';

const propTypes = {
  handleItemSort: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  messages: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
};

const JobCustomFormTemplateCards = ({
  handleItemSort,
  items,
  messages,
  onDeleteItem,
  onEditItem,
}) => {
  const itemsCount = items.length;
  const dataCards = items.map((item, index) => (
    <Fragment key={item.key}>
      <CardWithTitle
        id={item.key}
        title={(
          <div data-testid={`title-data-row-${index}`}>
            {itemsCount > 1 ? <span className="job-custom-form-template-cards__index">{index + 1}. </span> : null}
            { item.title}
          </div>
        )}
        description={item.dataType}
        body={item.required}
        actionButton={(
          <JobCustomFormTemplateMenuButton
            handleItemSort={handleItemSort}
            index={index}
            item={item}
            itemsCount={itemsCount}
            messages={messages}
            onDeleteItem={onDeleteItem}
            onEditItem={onEditItem}
          />
        )}
      />
    </Fragment>
  ));

  return (
    <div>
      {dataCards}
    </div>
  );
};

JobCustomFormTemplateCards.propTypes = propTypes;

export default JobCustomFormTemplateCards;
