import { call, put, takeLatest } from 'redux-saga/effects';

import { OnboardingGaiaActionTypes } from './OnboardingGaiaAction';
import UserService from '../../../../../services/User/UserService';

export function* getOnboardingGaiaStatus({ userId }) {
  try {
    const response = yield call(UserService.getOnboardingGaiaStatus, userId);
    yield put({
      type: OnboardingGaiaActionTypes.GET_ONBOARDING_GAIA_STATUS_SUCCESS,
      userId,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: OnboardingGaiaActionTypes.GET_ONBOARDING_GAIA_STATUS_FAIL,
      payload: error,
    });
  }
}

export function* changeOnboardingGaiaStatus({ userId, status }) {
  try {
    yield call(UserService.changeOnboardingGaiaStatus, userId, status);
    yield put({
      type: OnboardingGaiaActionTypes.CHANGE_ONBOARDING_GAIA_STATUS_SUCCESS,
      userId,
      payload: { statusGaiaOnboarding: status },
    });
  } catch (error) {
    yield put({
      type: OnboardingGaiaActionTypes.CHANGE_ONBOARDING_GAIA_STATUS_FAIL,
      payload: error,
    });
  }
}

function* OnboardingGaiaSaga() {
  yield takeLatest(
    OnboardingGaiaActionTypes.GET_ONBOARDING_GAIA_STATUS,
    getOnboardingGaiaStatus,
  );
  yield takeLatest(
    OnboardingGaiaActionTypes.CHANGE_ONBOARDING_GAIA_STATUS,
    changeOnboardingGaiaStatus,
  );
}

export default OnboardingGaiaSaga;
