import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export const AuthenticationActionTypes = createActionTypes('Authentication',
  'SET_AUTHENTICATION', 'SET_SSO_LOGIN');

export const SetPasswordActionTypes = createActionTypes('SetPassword',
  asyncActionType('POST'),
  asyncActionType('VALIDATE_TOKEN'),
  asyncActionType('INIT_COMPANY_LANGUAGE'),
  'POST_VALIDATION_FAIL');

export const SigninActionTypes = createActionTypes('Signin',
  asyncActionType('INIT_SIGNIN'),
  asyncActionType('POST'),
  'POST_VALIDATION_FAIL',
  asyncActionType('SIGNIN_WITH_COOKIE'),
  asyncActionType('RELOAD_CURRENT_USER'),
  asyncActionType('SIGNOUT'),
  asyncActionType('SIGNIN_WITH_SOCIAL_OR_SAML'),
  asyncActionType('CREATE_SAML_LOGIN_REQUEST'),
  asyncActionType('EXCHANGE_TOKEN'),
  asyncActionType('GET_COMPANY_ID_BY_SUBDOMAIN'),
  asyncActionType('IDP_SIGNOUT'),
  asyncActionType('KEEP_OR_CHANGE_COMPANY'),
  asyncActionType('DEFINE_LAST_SIGN_IN_AT'));

export const ForgotPasswordActionTypes = createActionTypes('ForgotPassword',
  asyncActionType('INIT_FORGOT_PASSWORD'),
  asyncActionType('POST'));
