export const getSubdomain = () => {
  const staticDevelopmentSubdomain = process.env.REACT_APP_LOCAL_STATIC_SUBDOMAIN;
  const isLocalEnabled = staticDevelopmentSubdomain && process.env.NODE_ENV === 'development';
  if (isLocalEnabled) {
    // eslint-disable-next-line no-console
    console.debug('Subdomain local fixo habilitado para:', staticDevelopmentSubdomain);
    return staticDevelopmentSubdomain;
  }
  const { host } = window.location;
  return host.substring(0, host.indexOf('.'));
};
