import { BaseForm } from '@gupy/front-commons';

class JobCandidatesClassifiersForm extends BaseForm {
  constructor() {
    super({
      userId: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default JobCandidatesClassifiersForm;
