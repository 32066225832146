import { BaseForm } from '@gupy/front-commons';

class AddressForm extends BaseForm {
  constructor() {
    super({
      addressCountryShortName: {
        validate: {
          notEmpty: true,
        },
      },
      addressState: {
        validate: {
          notEmpty: true,
          letters: true,
        },
      },
      addressCity: {
        validate: {
          notEmpty: true,
          letters: true,
        },
      },
      addressStreet: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default AddressForm;
