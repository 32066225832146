import { put, takeLatest } from 'redux-saga/effects';

import ScrollHelper from '@gupy/front-helpers/src/helpers/ScrollHelper';
import JobService from '../../../../../services/Job/JobService';
import JobAdditionalQuestionsForm from '../../../../../forms/Job/JobAdditionalQuestions/JobAdditionalQuestionsForm';
import { JobAdditionalQuestionsActionTypes } from './JobAdditionalQuestionsAction';

function* getAll(action) {
  const form = new JobAdditionalQuestionsForm();
  try {
    const response = yield JobService.getAdditionalQuestions(action.jobId, action.jobStepId);

    yield put({
      type: JobAdditionalQuestionsActionTypes.GET_ALL_SUCCESS,
      questions: response.body.data,
    });
  } catch (error) {
    yield put({
      type: JobAdditionalQuestionsActionTypes.GET_ALL_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* post(action) {
  const { payload } = action;
  const form = new JobAdditionalQuestionsForm();
  const { jobId, stepId } = payload;
  const modalBodyId = 'job-step-register-additional-modal-body';

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobAdditionalQuestionsActionTypes.POST_FAIL,
      validation,
    });
    ScrollHelper.scrollToTopOfDiv(modalBodyId);
  } else {
    try {
      yield JobService.postAdditionalQuestion(payload);

      yield put({
        type: JobAdditionalQuestionsActionTypes.POST_SUCCESS,
        jobId,
        jobStepId: stepId,
      });
    } catch (error) {
      yield put({
        type: JobAdditionalQuestionsActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
      ScrollHelper.scrollToTopOfDiv(modalBodyId);
    }
  }
}

function* update(action) {
  const { payload } = action;
  const form = new JobAdditionalQuestionsForm();

  const { id, jobId, stepId } = payload;
  const newPayload = { ...payload, id: undefined };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: JobAdditionalQuestionsActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield JobService.putAdditionalQuestions(jobId, id, newPayload);

      yield put({
        type: JobAdditionalQuestionsActionTypes.PUT_SUCCESS,
        jobId,
        id,
        jobStepId: stepId,
      });
    } catch (error) {
      yield put({
        type: JobAdditionalQuestionsActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, jobId, stepId } = action;

  try {
    yield JobService.deleteAdditionalQuestions(jobId, id);

    yield put({
      type: JobAdditionalQuestionsActionTypes.DELETE_SUCCESS,
      jobId,
      id,
      jobStepId: stepId,
    });
  } catch (error) {
    const form = new JobAdditionalQuestionsForm();
    yield put({
      type: JobAdditionalQuestionsActionTypes.DELETE_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* jobAdditionalQuestionsSaga() {
  yield takeLatest(JobAdditionalQuestionsActionTypes.GET_ALL, getAll);
  yield takeLatest(JobAdditionalQuestionsActionTypes.POST, post);
  yield takeLatest(JobAdditionalQuestionsActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(JobAdditionalQuestionsActionTypes.PUT, update);
  yield takeLatest(JobAdditionalQuestionsActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(JobAdditionalQuestionsActionTypes.DELETE, del);
  yield takeLatest(JobAdditionalQuestionsActionTypes.DELETE_SUCCESS, getAll);
}

export default jobAdditionalQuestionsSaga;
