import api from '../../api/DarthVaderClient';

const apiCompanyBranchesPath = '/company-management/companies/branches';

export default {
  get({ level = 0, branchLabel }) {
    const query = new URLSearchParams(`withAddress=true&level=${level + 1}`);
    if (branchLabel) query.append('prefix', branchLabel);
    return api.get(`${apiCompanyBranchesPath}?${query.toString()}`);
  },
  getCSVTemplate() {
    return api.get(`${apiCompanyBranchesPath}/getmodel`);
  },
  getCurrentCSV() {
    return api.get(`${apiCompanyBranchesPath}/getcurrent`);
  },
  updateCompanyBranches(payload) {
    return api.put(`${apiCompanyBranchesPath}/update`, payload);
  },
  postCompanyBranchesCSV(base64) {
    const payload = { data: base64 };
    return api.post(`${apiCompanyBranchesPath}/import`, payload);
  },
  deleteCompanyBranch(payload) {
    return api.delete(`${apiCompanyBranchesPath}/delete`, payload);
  },
};
