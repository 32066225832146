import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('CustomTestQuestion',
  'CHANGE_FORM',
  'MODEL_CLEAR',
  'LOAD_QUESTION',
  'CLONE_QUESTION',
  'SWAP_QUESTION',
  asyncActionType('DETAIL'),
  asyncActionType('CREATE'),
  asyncActionType('READ'),
  asyncActionType('UPDATE'),
  asyncActionType('DELETE'),
  asyncActionType('VALIDATE_QUESTION_CUSTOM'),
  asyncActionType('SAVE_SORTED'));
