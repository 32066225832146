import api from '../../api/DarthVaderClient';

export default {

  patch(payload) {
    return api.patch('/companies/current', payload);
  },

  get() {
    return api.get('/companies/current');
  },

  getAllCompanySizes() {
    return api.get('/company-management/public/company-sizes');
  },

};
