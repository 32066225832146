import CustomTest from './redux/CustomTest/CustomTestReducer';
import CustomTestQuestion from './redux/CustomTestQuestion/CustomTestQuestionReducer';
import TestCorrection from './redux/TestCorrection/TestCorrectionReducer';
import TestCorrectionList from './redux/TestCorrection/TestCorrectionListReducer';

export default {
  CustomTest,
  CustomTestQuestion,
  TestCorrection,
  TestCorrectionList,
};
