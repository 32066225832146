import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { DialogConfirm } from '@gupy/design-system/Dialogs/DialogConfirm/DialogConfirm';
import JobDeletionModalAfterPublication from './JobDeletionAfterPublicationModal/JobDeletionAfterPublicationModal';

const propTypes = {
  isJobAtFinalSteps: PropTypes.bool.isRequired,
  isJobDeleting: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onConfirmButtonClick: PropTypes.func.isRequired,
  messages: PropTypes.object.isRequired,
  job: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const defaultProps = {
  isJobDeleting: false,
  job: null,
};

const JobDeletionModal = ({
  job,
  isJobAtFinalSteps,
  isJobDeleting,
  onModalClose,
  onConfirmButtonClick,
  messages,
  isOpen,
}) => {
  const handleConfirm = () => {
    onConfirmButtonClick(job.id);
  };

  return (
    <div data-testid="job-deletion-modal">
      {isJobAtFinalSteps
        ? (
          <JobDeletionModalAfterPublication
            job={job}
            onModalClose={onModalClose}
            messages={messages}
            isOpen={isOpen}
            onConfirmButtonClick={onConfirmButtonClick}
          />
        )
        : (
          <DialogConfirm
            id="job-deletion-modal-before-published"
            data-testid="job-deletion-modal-before-published"
            open={isOpen}
            title={messages.deleteJob}
            description={messages.message}
            cancelButton={{
              label: messages.cancel,
              action: onModalClose,
            }}
            confirmButton={{
              label: messages.remove,
              action: handleConfirm,
              disabled: isJobDeleting,
            }}
          />
        )}
    </div>
  );
};

JobDeletionModal.propTypes = propTypes;
JobDeletionModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  isJobDeleting: state.reducers.JobDeletionReducer.isJobDeleting,
});

export default withRouter(injectIntl(connect(
  mapStateToProps,
)(JobDeletionModal)));
