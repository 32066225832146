import * as actions from './Actions';
import JobOfferTemplateListContainer from './JobOfferTemplateListContainer';
import JobOfferTemplateForm from './JobOfferTemplateForm';
import JobOfferTemplateReducer from './Reducer';
import JobOfferTemplateSaga from './Saga';

const { JobOfferTemplateActionTypes, ...JobOfferTemplateActions } = actions;

export {
  JobOfferTemplateActions,
  JobOfferTemplateListContainer,
  JobOfferTemplateForm,
  JobOfferTemplateReducer,
  JobOfferTemplateSaga,
};
