import JobRatingCriteriasActionTypes from '../../../constants/Job/JobRatingCriterias/JobRatingCriteriasActionTypes';
import JobPermissionsManager from '../../../managers/JobPermissionsManager';

export const changeForm = modelForm => ({
  type: JobRatingCriteriasActionTypes.CHANGE_FORM,
  modelForm,
});

export const clearForm = () => ({
  type: JobRatingCriteriasActionTypes.CLEAR_FORM,
});

export const postJobRatingCriteria = (jobId, payload) => ({
  type: JobRatingCriteriasActionTypes.POST,
  jobId,
  payload,
});

export const patchJobRatingCriteria = (criteriaId, jobId, payload) => ({
  type: JobRatingCriteriasActionTypes.PATCH,
  criteriaId,
  jobId,
  payload,
});

export const deleteJobRatingCriteria = (criteriaId, jobId) => ({
  type: JobRatingCriteriasActionTypes.DELETE,
  criteriaId,
  jobId,
});

export const editJobRatingCriteria = modelForm => ({
  type: JobRatingCriteriasActionTypes.EDIT,
  modelForm,
});

export const initJobRatingCriterias = jobId => ({
  type: JobRatingCriteriasActionTypes.INIT,
  jobId,
});

export const initJobRatingCriteriasSuccess = (ratingCriterias, job) => ({
  type: JobRatingCriteriasActionTypes.INIT_SUCCESS,
  ratingCriterias,
  job: JobPermissionsManager.adaptJobPermissions(job),
});

export const generateSkillsFromGaia = jobId => ({
  type: JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA,
  jobId,
});

export const getSkillsSuccess = suggestedSkills => ({
  type: JobRatingCriteriasActionTypes.SEARCH_SUCCESS,
  suggestedSkills,
});

export const getSkills = search => ({
  type: JobRatingCriteriasActionTypes.SEARCH,
  search,
});
