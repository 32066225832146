export const GET_CAREER_PAGES_REQUEST = 'CareerPage/GET_CAREER_PAGES_REQUEST';
export const GET_CAREER_PAGES_SUCCESS = 'CareerPage/GET_CAREER_PAGES_SUCCESS';
export const GET_CAREER_PAGES_FAIL = 'CareerPage/GET_CAREER_PAGES_FAIL';
export const FINISH_IMAGE_UPLOAD = 'CareerPage/FINISH_IMAGE_UPLOAD';
export const START_IMAGE_UPLOAD = 'CareerPage/START_IMAGE_UPLOAD';
export const POST = 'CareerPage/POST';
export const POST_V2 = 'CareerPage/POST_V2';
export const POST_FAIL = 'CareerPage/POST_FAIL';
export const POST_SUCCESS = 'CareerPage/POST_SUCCESS';
export const PATCH = 'CareerPage/PATCH';
export const PATCH_V2 = 'CareerPage/PATCH_V2';
export const PATCH_FAIL = 'CareerPage/PATCH_FAIL';
export const PATCH_SUCCESS = 'CareerPage/PATCH_SUCCESS';
export const POPULATE_CAREER_PAGE_MODEL = 'CareerPage/POPULATE_CAREER_PAGE_MODEL';
export const UNSET_CAREER_PAGE_MODEL = 'CareerPage/UNSET_CAREER_PAGE_MODEL';
export const ADD_TEMP_IMAGE = 'CareerPage/ADD_TEMP_IMAGE';
export const UPDATE_FORM = 'CareerPage/UPDATE_FORM';
export const CREATE_EMPLOYEE_TESTIMONIAL = 'CareerPage/CREATE_EMPLOYEE_TESTIMONIAL';
export const UPDATE_EMPLOYEE_TESTIMONIAL = 'CareerPage/UPDATE_EMPLOYEE_TESTIMONIAL';
export const DELETE_EMPLOYEE_TESTIMONIAL = 'CareerPage/DELETE_EMPLOYEE_TESTIMONIAL';
export const CREATE_CANDIDATE_TESTIMONIAL = 'CareerPage/CREATE_CANDIDATE_TESTIMONIAL';
export const UPDATE_CANDIDATE_TESTIMONIAL = 'CareerPage/UPDATE_CANDIDATE_TESTIMONIAL';
export const DELETE_CANDIDATE_TESTIMONIAL = 'CareerPage/DELETE_CANDIDATE_TESTIMONIAL';
export const CLEAR_CAREER_PAGE = 'CareerPage/CLEAR_CAREER_PAGE';
export const SET_HOTJAR_POOL = 'CareerPage/SET_HOTJAR_POOL';
