import api from '../../api/DarthVaderClient';

export default {
  async deleteEmailDomain(id) {
    return api.delete(`/company-management/email-domains/${id}`);
  },
  async getEmailDomains() {
    return api.get('/company-management/email-domains');
  },
  async createEmailDomain(payload) {
    return api.post('/company-management/email-domains', payload);
  },
};
