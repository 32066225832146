import React from 'react';
import {
  IconEnum, Button } from '@gupy/design-system';
import PropTypes from 'prop-types';
import openRscLinkedinIframeInPopup from '../../../helpers/openRscLinkedinIframeInPopup';
import childAppPropTypes from '../../../prop-types/childAppPropTypes';
import isClickOrEnterKeyEvent from '../../../helpers/isClickOrEnterKeyEvent';

import '../RscLinkedinIntegrationChildAppsItem';

const ChildAppReadyToConnect = ({
  messages,
  childApp,
}) => {
  const handleOpenRscLinkedinIframeInPopup = ({ keyCode, type }) => {
    if (!isClickOrEnterKeyEvent({ keyCode, type })) return;

    openRscLinkedinIframeInPopup({
      id: childApp.id,
      clientId: childApp.clientId,
    });
  };

  return (
    <tr className={'job-board-panel-rsc-child-apps__table-row READY_TO_CONNECT_WITH_LINKEDIN'}>
      <td className="actions" colSpan="4">
        <span className="rsc_integration_child_app_ready_to_connect_text">
          {`${messages.rscIntegrationChildAppReadyToConnectText}`}
        </span>
        <Button
          className="rsc_integration_child_app_ready_to_connect_button"
          icon={IconEnum.Loop}
          type="outlined"
          color="primary"
          onClick={handleOpenRscLinkedinIframeInPopup}
        >
          {messages.rscIntegrationChildAppReadyToConnectButton}
        </Button>
      </td>
    </tr>
  );
};

ChildAppReadyToConnect.propTypes = {
  messages: PropTypes.object.isRequired,
  childApp: childAppPropTypes.isRequired,
};

export default ChildAppReadyToConnect;
