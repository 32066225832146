import React from 'react';
import { intlShape } from 'react-intl';
import { getMessages } from './Logo.messages';

const propTypes = {
  intl: intlShape,
};

export const Logo = ({ intl }) => {
  const message = getMessages(intl);
  return (
    <img
      className="companies-authentication__logo"
      src={`${process.env.REACT_APP_ASSETS_URL}/gupy-logo/gupy-logo.svg`}
      alt={message.logo}
    />
  );
};

Logo.propTypes = propTypes;
