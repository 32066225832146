import React from 'react';
import { FormattedMessage } from 'react-intl';

export const convertErrorPasswordObject = (errorFromApi) => {
  const shouldNotAlert = {
    'Current password doesn\'t match': true,
    'A senha atual não confere com a senha cadastrada': true,
    'La contraseña actual no corresponde con la contraseña registrada': true,
  };

  if ('alert' in errorFromApi && 'message' in errorFromApi.alert && shouldNotAlert[errorFromApi.alert.message]) {
    return { currentPassword: { message: <FormattedMessage id="password-error" defaultMessage="A senha atual não confere com a senha cadastrada"/>, type: 'error' } };
  }
  return errorFromApi;
};
