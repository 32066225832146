import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelHeader } from '@gupy/front-commons';
import { useFlag } from '@unleash/proxy-client-react';
import { PermissionEnum } from '@gupy/enums';
import { Tip } from '@gupy/design-system';
import FeaturesFlagsEnum from '../../Authentication/FeaturesFlagsEnum';
import './PermissionPanel.scss';

const propTypes = {
  branchName: PropTypes.string.isRequired,
  branch: PropTypes.object.isRequired,
  globalization: PropTypes.object.isRequired,
  indeterminate: PropTypes.bool.isRequired,
  handleChangeTreeBranch: PropTypes.func.isRequired,
  handleChangeTreeGroup: PropTypes.func.isRequired,
  branchGroups: PropTypes.object.isRequired,
  hasInfoTip: PropTypes.bool,
  tipMessage: PropTypes.string,
  showGroupTip: PropTypes.object,
  branchTipMessage: PropTypes.string,
};

const defaultProps = {
  hasInfoTip: false,
  tipMessage: undefined,
  tipBranch: undefined,
  showGroupTip: {},
  branchTipMessage: undefined,
};

const PermissionPanel = ({
  branchName,
  branch,
  globalization,
  indeterminate,
  handleChangeTreeBranch,
  handleChangeTreeGroup,
  branchGroups,
  hasInfoTip,
  tipMessage,
  showGroupTip,
  branchTipMessage,
}) => {
  const [isExpanded, setIsExpanded] = useState({});

  const isTalentRecommendationEnabled = useFlag(FeaturesFlagsEnum.showInboundTalentRecommendation);

  return (
    <div className="user-access-profile__column-grid">
      {showGroupTip && showGroupTip[branchName] && (
        <Tip
          id={`tip-${branchName}`}
          className="permission-panel_tip"
          type="info"
          showIcon
          text={branchTipMessage}
        />
      )}

      <div className="user-access-profile__column-content">
        <ExpansionPanelHeader
          title={globalization[branchName] || branchName}
          checked={branch.checked}
          checkIndeterminate={indeterminate}
          hasCheckbox
          onCheckboxChange={() => {
            handleChangeTreeBranch(branchName, !branch.checked);
          }}
          hasInfoTip={hasInfoTip}
          tipMessage={tipMessage}
        />
        {Object.keys(branchGroups).map(group => (
          <ExpansionPanel
            hasCheckbox
            key={`${branchName}-${group}`}
            checked={branchGroups[group].checked}
            title={globalization[group] || group}
            onExpand={(c, expanded) => {
              setIsExpanded({
                ...isExpanded,
                [group]: expanded,
              });
            }}
            expanded={isExpanded[group]}
            onCheckboxChange={() => {
              handleChangeTreeGroup(
                branchName,
                group,
                !branchGroups[group].checked,
              );
            }}
          >
            <ul key={`ul-${branchName}-${group}`}>
              {branch.groups[group].permissions
                .filter(permission => (
                  !isTalentRecommendationEnabled
                    ? permission !== PermissionEnum.manage_talent_recommendation
                    : permission
                ))
                .map(permission => (
                  <li key={`${branchName}-${group}-${permission}`}>{globalization[permission] || permission}</li>
                ))}
            </ul>
          </ExpansionPanel>
        ))}
      </div>
    </div>
  );
};

PermissionPanel.propTypes = propTypes;
PermissionPanel.defaultProps = defaultProps;

export default PermissionPanel;
