import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import './TermsAndPrivacy.scss';

const TermsDocument = () => (
  <div className="terms-and-privacy">
    <FormattedHTMLMessage
      id="terms-html"
      defaultMessage='
  <p>
      Olá!
  </p>
  <p>
      Estes Termos e Condições de Uso (“<u>Termos</u>”) aplicam-se ao uso da plataforma
      e dos serviços oferecidos pela GUPY TECNOLOGIA EM RECRUTAMENTO LTDA.,
      gestora do site <a href="www.gupy.com.br">www.gupy.com.br</a> e do aplicativo GUPY (em conjunto
      “<u>Plataforma GUPY</u>”) e prestadora dos serviços objeto destes Termos
      (“<u>Serviços</u>”).
  </p>
  <br/>
  <p class="bold">
      1. Definições
  </p>
  <br/>
  <p>
      Nestes Termos, usamos em diversos momentos o termo USUÁRIOS. Quando o
      fizermos, estaremos nos referindo a Você, Usuário EMPRESA, e aos
      Candidatos(as), pessoas que se candidataram às vagas abertas por Você e por
      outros Usuários da sua EMPRESA.
  </p>
  <br/>
  <p>
      As EMPRESAS são empresas que usam a Plataforma GUPY para divulgar
      oportunidades de vagas ou para captar um banco de talentos qualificados
      para identificar entre eles potenciais talentos, selecionando-os para
      integrar sua equipe.
  </p>
  <br/>
  <p class="bold">
      2. Alterações
  </p>
  <br/>
  <p>
      Estes Termos podem ser alterados de tempos em tempos por razões válidas.
      Sempre que houver alteração relevante, a GUPY adotará seus melhores
      esforços para comunicar Você. No entanto, solicitamos que Você visite esta
      página periodicamente para checar os Termos mais recentes estabelecidos
      para uso da Plataforma GUPY.
  </p>
  <br/>
  <p class="bold">
      3. Aplicação destes Termos
  </p>
  <br/>
  <p>
      Estes Termos serão aplicáveis a Você assim que Você inicie o uso da
      Plataforma GUPY mediante a criação de conta de usuário e apenas deixará de
      ser aplicável se e quando a sua relação com a Gupy se encerrar, seja em
      razão do seu desligamento da EMPRESA, seja em razão de redistribuição de
      papéis e responsabilidade dentro da EMPRESA, seja em razão do término do
      contrato entre a Gupy e a EMPRESA. Se sua conta for, posteriormente,
      reativada, estes Termos voltarão a ser aplicáveis a Você, em sua versão
      vigente.
  </p>
  <br/>
  <p>
      A GUPY poderá cancelar contas e tomar todas as medidas legais cabíveis em
      caso de violação destes Termos pelos USUÁRIOS.
  </p>
  <br/>
  <p class="bold">
      4. Serviços prestados pela GUPY
  </p>
  <br/>
  <p>
      A GUPY oferece os seguintes Serviços para as EMPRESAS Clientes:
  </p>
  <br/>
  <ul>
      <li>
          <p>
              mapeamento do perfil das EMPRESAS;
          </p>
      </li>
      <li>
          <p>
              mapeamento do perfil dos Candidatos(as), potencial e conhecimentos,
              por meio da realização de testes disponibilizados pela GUPY ou por
              terceiros;
          </p>
      </li>
      <li>
          <p>
              combinação do perfil das EMPRESAS buscando Candidatos(as) e de
              Candidatos(as) em busca de nova posição de trabalho, com a
              participação em processo seletivo a ser realizado quando da
              disponibilidade de vagas;
          </p>
      </li>
      <li>
          <p>
              banco de talentos para as empresas com os Candidatos(as) que já
              participaram de seus processos seletivos.
          </p>
      </li>
  </ul>
  <br/>
  <p class="bold">
      5. A criação de seu perfil de USUÁRIO EMPRESA
  </p>
  <br/>
  <p>
      <strong>Cadastro</strong>. Seu cadastro será feito pelo administrador da Plataforma Gupy
      indicado pela sua empresa. Caso queira fazer qualquer alteração no seu
      cadastro, acesse a tela do seu perfil ou entre em contato com o
      administrador.
  </p>
  <br/>
  <p>
      <strong>Conta de acesso</strong>. Para utilização da Plataforma GUPY, a GUPY disponibilizará
      a Você uma conta, que será acessada por meio de e-mail (login) e senha. É
      proibido informar a terceiros esses dados e Você será integralmente
      responsável pelo uso que deles seja feito. Em caso de uso não autorizado de
      sua conta, Você deverá nos informar imediatamente, por meio do
      <a href="mailto:suporte-empresa@gupy.com.br">suporte-empresa@gupy.com.br</a>, assumindo todos os riscos decorrentes de
      eventual acesso não autorizado.
  </p>
  <br/>
  <p>
      <strong>Conta vinculada a plataforma de gestão da EMPRESA</strong>. Caso sua conta de
      usuário seja vinculada, via single sign on, com plataforma de gestão da
      EMPRESA (SAML), a GUPY não terá acesso a seus dados nem poderá modificá-los
      dentro da Plataforma Gupy. Quaisquer alterações que se fizerem necessárias,
      inclusive alterações de senha, deverão ser feitas mediante contato com o
      administrador da Plataforma Gupy na EMPRESA e/ou o Departamento de TI da
      EMPRESA.
  </p>
  <br/>
  <p class="bold">
      6. O mapeamento do perfil da sua EMPRESA
  </p>
  <br/>
  <p>
      Você poderá ser convidado(a) a fazer um teste de perfil da EMPRESA caso
      esta queira usar essa funcionalidade da Plataforma GUPY. Esse teste, no
      entanto, não será obrigatório. O teste não gera um resultado individual
      para cada um dos colaboradores que o fizeram, mas sim um resultado geral
      sobre a EMPRESA.
  </p>
  <br/>
  <p class="bold">
      7. A abertura de vagas
  </p>
  <br/>
  <p>
      Por meio da Plataforma GUPY, a EMPRESA poderá divulgar processos seletivos
      para vagas em aberto, selecionando as etapas que deseja incluir, dentre
      realização de testes, gravação de vídeo e realização de entrevistas, bem
      como com a inclusão de perguntas adicionais, que devem ser incluídas por
      conta e risco da EMPRESA. A EMPRESA poderá utilizar os testes que são
      disponibilizados pela GUPY e poderá criar seus próprios testes, adequados
      às suas necessidades.
  </p>
  <br/>
  <p>
      Fica vedada a inclusão de testes e/ou perguntas discriminatórias, de cunho
      preconceituoso ou imoral. Caso identificadas, a GUPY poderá excluir os
      testes e as perguntas unilateralmente, independentemente de aviso prévio à
      EMPRESA.
  </p>
  <br/>
  <p>
      A EMPRESA poderá escolher em que job boards deseja a divulgação da vaga e
      poderá, outrossim, escolher divulgá-la apenas interna ou externamente. A
      publicação de vagas, externas ou internas, e seu conteúdo são de
      responsabilidade da EMPRESA, inclusive no que diz respeito ao modelo de
      contratação.
  </p>
  <br/>
  <p class="bold">
      8. O cadastro feito pelos Candidatos(as)
  </p>
  <br/>
  <p>
      <strong>Cadastro decorrente de processo seletivo</strong>. O cadastro dos Candidatos(as) na
      Plataforma GUPY é feito com relação a um processo seletivo específico. O
      cadastro do(a) Candidato(a) é único e será utilizado em todos os processos
      seletivos em que ele se inscrever.
  </p>
  <br/>
  <p>
      <strong>Indicação para a vaga</strong>. Caso, quando do cadastro em determinado processo
      seletivo, o(a) Candidato(a) informar que está sendo indicado(a) para a vaga
      por atual colaborador(a) da EMPRESA que abriu o processo seletivo, a
      indicação deverá ser validada, por meio da Plataforma GUPY, por quem o(a)
      indicou.
  </p>
  <br/>
  <p>
      <strong>Compartilhamento dos dados</strong>. Os dados dos Candidatos(as) que se inscreveram
      para o processo seletivo de vaga aberta por Você ou por sua EMPRESA serão
      tratados e armazenados no banco de dados da GUPY em ambiente seguro,
      observadas as tecnologias disponíveis e serão compartilhados com Você e com
      sua EMPRESA. Você e sua EMPRESA não poderão compartilhar seus dados com
      terceiros fora dos propósitos do processo seletivo – caso essa regra seja
      violada, sua EMPRESA poderá responder civil e penalmente por qualquer dano
      que venha a causar aos Candidatos(as). Exceção à regra ocorre (i) para o
      compartilhamento de dados com sistemas internos da EMPRESA, necessários no
      contexto do processo seletivo e (ii) em casos em que sua EMPRESA seja
      agência de recrutamento responsável pela sua colocação e/ou identificação
      de talentos para empresa terceira, caso em que os dados serão
      compartilhados com a empresa que detenha a vaga.
  </p>
  <br/>
  <p class="bold">
      9. Conteúdo do USUÁRIO
  </p>
  <br/>
  <p>
      Todas as informações, dados, textos, programas, músicas, sons, fotografias,
      gráficos, vídeos, propagandas, mensagens e outros materiais enviados,
      inseridos ou exibidos pelo(a) Candidato(a) ou pela EMPRESA são de única
      responsabilidade do autor de tal conteúdo.
  </p>
  <br/>
  <p>
      A GUPY poderá tomar qualquer ação que julgar necessária ou apropriada a
      respeito de Conteúdo de USUÁRIOS caso acredite que tal conteúdo possa criar
      responsabilidades para a GUPY, difamar sua marca ou imagem pública ou
      causar perdas de USUÁRIOS, ou ainda de seus provedores de Internet ou de
      outros fornecedores (em partes ou por completo).
  </p>
  <br/>
  <p class="bold">
      10. Mapeamento de Perfil e Testes realizados pelos Candidatos(as)
  </p>
  <br/>
  <p>
      Na Plataforma GUPY, os Candidatos(as) realizarão testes online, usados para
      mapear seu perfil, potencial e conhecimentos gerais. As regras sobre
      “Compartilhamento de dados” previstas no item 8 acima também se aplicam a
      esses dados.
  </p>
  <br/>
  <p class="bold">
      11. Etapa de Gravação de Vídeo
  </p>
  <br/>
  <p>
      A gravação de vídeo, para os casos em que o processo seletivo o solicitar,
      será feita pelo(a) Candidato(a) por meio da Plataforma GUPY ou pelo upload
      de vídeo na plataforma Youtube.
  </p>
  <br/>
  <p>
      Os vídeos serão gravados de acordo com parâmetros e orientações
      pré-estabelecidos pela GUPY, conforme “Diretrizes de Conteúdo para Gravação
      de Vídeo” disponibilizadas para visualização e aceite pelo Candidato em
      momento prévio à gravação do vídeo.
  </p>
  <br/>
  <p>
      A GUPY removerá da plataforma qualquer vídeo que, de acordo com seus
      critérios, contenha conteúdo impróprio, ilegal ou que desabone as empresas
      parceiras da GUPY, hipótese em que o(a) Candidato(a) responsável pela
      gravação terá seu cadastro excluído, além de estar sujeito(a) a responder
      civil e criminalmente por eventuais danos que o conteúdo do vídeo venha a
      causar a terceiros.
  </p>
  <br/>
  <p>
      Ao contrário dos testes, os vídeos serão usados apenas para o processo
      seletivo para o qual ele é gravado e não serão aproveitados para futuros
      processos seletivos.
  </p>
  <br/>
  <p>
      Os vídeos, bem como quaisquer comentários ou recomendações feitos a
      respeito dos vídeos pela EMPRESA só terão acesso permitido por Você e pelos
      demais Usuários indicados pela EMPRESA para uso da Plataforma Gupy, se
      houver. As regras sobre “Compartilhamento de dados” previstas no item 8
      acima também se aplicam a esses dados.
  </p>
  <br/>
  <p class="bold">
      12. Agendamento de entrevistas
  </p>
  <br/>
  <p>
      Caso a EMPRESA selecione essa opção, poderá ser enviado e-mail ao(à)
      Candidato(a) com indicação de datas disponíveis para agendamento de
      entrevista. Compete à EMPRESA, e não à GUPY, a confirmação do agendamento.
  </p>
  <br/>
  <p>
      Essa etapa ocorre apenas entre EMPRESA e Candidato(a) e a GUPY não se
      responsabiliza pelo comparecimento das partes ou pelo feedback sobre a
      entrevista realizada.
  </p>
  <br/>
  <p class="bold">
      13. Gupy Recruiter
  </p>
  <br/>
  <p>
      O Gupy Recruiter é um plugin do Google Chrome, que localiza informações de
      contato do(a) potencial Candidato(a) em seu usuário do Linkedin e, com o
      comando do recrutador, adiciona essas informações automaticamente à conta
      da EMPRESA na Gupy. O plugin também permite o envio automático de um e-mail
      por parte da EMPRESA, convidando a pessoa a participar do seu processo
      seletivo.
  </p>
  <br/>
  <p>
      Só poderão ser obtidos os dados de pessoas que deixem seu e-mail visível no
      perfil do Linkedin. Além disso, o plugin só estará disponível quando o
      Google Chrome for usado como navegador e apenas pessoas com “permissão para
      inserir em vaga” poderão recrutar dessa maneira.
  </p>
  <br/>
  <p class="bold">
      14. Suporte
  </p>
  <br/>
  <p>
      USUÁRIOS podem entrar em contato com o Suporte da Gupy pelo e-mail
      <a href="mailto:suporte-empresa@gupy.com.br">suporte-empresa@gupy.com.br</a>. Os contatos serão respondidos também por
      e-mail, de segunda a sexta, das 9h às 18h, exceto feriados.
  </p>
  <br/>
  <p>
      Você também pode encontrar diversas informações em nossa Central de Ajuda:
      <a href="https://gupy-support.zendesk.com/hc/pt-br">
          https://gupy-support.zendesk.com/hc/pt-br
      </a>
      .
  </p>
  <br/>
  <p class="bold">
      15. Relatórios
  </p>
  <br/>
  <p>
      A fim de disponibilizar às empresas informações que auxiliem no
      monitoramento de indicadores para uma melhor performance, a Gupy permite
      que sejam extraídos relatórios em formato .xlsx a respeito de suas vagas e
      processos seletivos, na aba “Analisar Dados” ou a partir da API pública. O
      acesso aos dados da empresa são controlados por permissionamento e/ou token
      de segurança e é de total responsabilidade da EMPRESA a utilização
      responsável das informações extraídas.
  </p>
  <br/>
  <p class="bold">
      16. Diretrizes Gerais do USUÁRIO EMPRESA
  </p>
  <br/>
  <p>
      É proibido ao USUÁRIO EMPRESA:
  </p>
  <br/>
  <ul>
      <li>
          <p>
              violar/tentar violar a segurança da GUPY como tentativa de
              investigar, copiar e testar a vulnerabilidade do sistema, rede,
              falha de segurança ou medidas de autenticação sem autorização;
          </p>
      </li>
      <li>
          <p>
              forjar cabeçalhos TCP/IP ou qualquer parte da informação do
              cabeçalho em qualquer e-mail ou postagens em grupos de notícias;
          </p>
      </li>
      <li>
          <p>
              agregar, copiar ou duplicar partes da plataforma GUPY (site e
              aplicativo), incluindo oportunidades de trabalho expiradas;
          </p>
      </li>
      <li>
          <p>
              adaptar ou disponibilizar o link para qualquer Conteúdo GUPY ou
              informações disponíveis no Site ou no Aplicativo, salvo se
              permitido por este Termo;
          </p>
      </li>
      <li>
          <p>
              postar conteúdo ou materiais que promovam ou endossem informações
              falsas/enganosas ou atividades ilegais, bem como informações que
              ofereçam instruções para atividades ilegais ou proibidas de acordo
              com o presente Termo;
          </p>
      </li>
      <li>
          <p>
              acessar dados de terceiros, servidores ou contas que não é
              autorizado a acessar;
          </p>
      </li>
      <li>
          <p>
              publicar conteúdos que contenham páginas com acesso restrito ou
              senha, assim como páginas ou imagens escondidas;
          </p>
      </li>
      <li>
          <p>
              assediar, incitar ou defender quaisquer tipos de assédio de
              qualquer grupo, empresa ou indivíduo;
          </p>
      </li>
      <li>
          <p>
              tentar interferir nos serviços para USUÁRIOS, administrador ou
              rede, incluindo, mas não se limitando a métodos de envio de vírus
              para o site, sobrecarga, acúmulo de mensagens sem conteúdo, spam,
              “e-mails bomba” ou panes;
          </p>
      </li>
      <li>
          <p>
              utilizar a Plataforma GUPY de forma ilícita para qualquer atividade
              ilegal, incluir ou submeter qualquer tipo de conteúdo, currículo ou
              vaga de empregos difamatória, acusatória, implicitamente ou
              explicitamente ofensiva, vulgar, obscena, ameaçadora, abusiva,
              raivosa, racista, discriminatória, e que pode causar irritação,
              inconveniência, humilhação, ansiedade e ser considerada como
              assédio. Também não são permitidos links para sites de conteúdo
              pornográfico, indecentes ou com material de sexo explícito de
              qualquer tipo; como determinado a critério da GUPY.
          </p>
      </li>
  </ul>
  <br/>
  <p>
      Violações à segurança do sistema e da rede poderão resultar em processos
      civis e/ou criminais. A GUPY investigará ocorrências que possam resultar em
      violações, incluindo, mas não se limitando às citadas acima, e poderá se
      envolver e cooperar com as autoridades e órgãos de segurança pública na
      punição de USUÁRIOS que pratiquem atos de violação.
  </p>
  <br/>
  <p>
      A GUPY reserva-se ao direito de remover quaisquer divulgações de
      oportunidades de vagas ou conteúdo da Plataforma GUPY que, de acordo com
      seus critérios, não estejam de acordo com este item ou estes Termos, bem
      como conteúdo que não se encaixem no propósito da Plataforma GUPY.
  </p>
  <br/>
  <p>
      Caso seja identificada falsidade ideológica ou fraude relacionada à
      natureza das atividades do USUÁRIO na Plataforma GUPY, a GUPY poderá, a seu
      critério, encerrar sua relação com o USUÁRIO.
  </p>
  <br/>
  <p class="bold">
      17. Registro do Histórico de Navegação e Interação dos Usuários
  </p>
  <br/>
  <p>
      Podemos registrar seu histórico de navegação e interação na Plataforma
      GUPY, como forma de entender suas preferências para melhorar a plataforma e
      criar novos produtos, possibilitar a oferta de melhor conteúdo, assim como
      atender a necessidades legais e administrativas em caso de fraude ou ordem
      judicial.
  </p>
  <br/>
  <p>
      Além disso, também registramos operações de inclusão, alteração e exclusão
      de dados na plataforma em logs de auditoria, que permitem verificar que
      ações foram tomadas por qual usuário e em que momento. Tais logs serão
      armazenados por 5 (cinco) anos, em observância a obrigações legais.
  </p>
  <br/>
  <p class="bold">
      18. Responsabilidade da GUPY
  </p>
  <br/>
  <p>
      A GUPY se responsabiliza por:
  </p>
  <br/>
  <p>
      a) atuar como intermediária entre as EMPRESAS que publicam oportunidades de
      vagas ou de bancos de talentos e Candidatos(as) que publicam currículos,
      constroem perfis, buscam ofertas de vagas, realizam os testes
      disponibilizados pela ferramenta e avaliam, anonimamente ou não, as
      empresas onde trabalham ou trabalharam;
  </p>
  <br/>
  <p>
      b) oferecer o sistema de qualificação de currículos para os requisitos da
      oferta de vagas apresentada pela EMPRESA e buscadas pelos Candidatos(as)
      com o uso de sua tecnologia. A qualificação é feita por algoritmos
      matemáticos que usam as informações disponibilizadas pelos USUÁRIOS, razão
      pela qual a GUPY somente se responsabiliza pelos resultados da ferramenta
      se as informações publicadas forem verdadeiras.
  </p>
  <br/>
  <p>
      A GUPY <strong>NÃO</strong> se responsabiliza por:
  </p>
  <br/>
  <p class="bold">
      a) Vagas abertas pela EMPRESAS
  </p>
  <p>
      A publicação de vagas é de exclusiva responsabilidade da EMPRESA, não sendo
      a GUPY responsável pela qualidade, segurança ou legalidade das vagas
      ofertadas, a veracidade ou a precisão das listagens e habilidade das
      empresas em oferecer oportunidades de vagas para Candidatos(as).
  </p>
  <p>
      A depender do plano contratado, a EMPRESA poderá, a seu exclusivo critério,
      utilizar a metodologia desenvolvida pela GUPY para auxiliar na criação de
      um modelo de redação de descrição de Vaga.
  </p>
  <p>
      Referida metodologia está em constante aprimoramento e considera, entre
      outros fatores, descrições de Vagas anteriormente utilizadas pela EMPRESA.
      Sendo assim, a EMPRESA declara que possui pleno conhecimento que a redação
      da descrição da Vaga apresentada na Plataforma Recrutamento e Seleção é uma
      mera sugestão e que o modelo deverá ser revisado pela Contratante antes de
      qualquer publicação. O conteúdo de toda e qualquer publicação realizada é de
      exclusiva responsabilidade da Contratante, ainda que derivado do modelo
      sugerido na Plataforma Recrutamento e Seleção.
  </p>
  <br/>
  <p class="bold">
      b) Dados informados pelos Candidatos(as)
  </p>
  <p>
      Os dados informados pelos Candidatos(as) são de exclusiva responsabilidade
      destes, não podendo a GUPY ser responsabilizada por equívoco no
      preenchimento dos dados ou não veracidade destes e pelas consequências que
      possam decorrer disso, como a não seleção do(a) Candidato(a) para
      determinado processo seletivo, desqualificação posterior de processo
      seletivo em que estava participando ou impossibilidade de contato com o(a)
      Candidato(a) pela EMPRESA pelo fato de que informações de contato direto
      (telefone e e-mail) do(a) Candidato(a) estejam ocultas ou incompletas.
  </p>
  <br/>
  <p class="bold">
      c) Escolha dos Candidatos(as) para qualquer etapa do processo seletivo
  </p>
  <p>
      A efetiva escolha dos Candidatos(as) que seguirão no processo seletivo e
      que serão, ao final, contratados, é de responsabilidade da EMPRESA.
  </p>
  <br/>
  <p class="bold">
      d) Eliminação de Candidatos(as) no processo seletivo
  </p>
  <p>
      Pelas razões já indicadas acima, a GUPY também não se responsabiliza pela
      reprovação de Candidatos(as) no processo de recrutamento da empresa.
  </p>
  <br/>
  <p class="bold">
      e) Negociação Direta entre Candidato e EMPRESA
  </p>
  <p>
      A GUPY não se envolve na negociação entre EMPRESAS e Candidatos(as) e não é
      responsável pela seleção ou não de Candidatos(as) ou por demora no processo
      seletivo. A GUPY não se responsabiliza por qualquer informação trocada no
      contato direto entre EMPRESA e Candidato via e-mail ou telefone.
  </p>
  <br/>
  <p class="bold">
      f) Links para outros sites
  </p>
  <p>
      A Plataforma GUPY contém links para sites de terceiros, fornecidos para sua
      conveniência e não como endosso, pela GUPY, do conteúdo presente nesses
      sites. A GUPY não tem controle e não se responsabiliza pelo conteúdo,
      políticas de privacidade ou práticas de sites de terceiros e não se
      pronunciará sobre o conteúdo ou precisão de matérias de terceiros. Caso
      acesse o link, o Candidato o fará por conta e risco próprios.
  </p>
  <br/>
  <p class="bold">
      g) Integração com outros sistemas
  </p>
  <p>
      A Plataforma GUPY não se responsabiliza por integrações que a EMPRESA
      Cliente realiza com seus sistemas. A GUPY apenas disponibiliza uma API
      pública e webhooks, a fim de permitir que Você conecte a Plataforma GUPY
      com outros sistemas.
  </p>
  <br/>
  <p>
      A Gupy não se responsabiliza pelo compartilhamento interno do token de
      integração na EMPRESA e eventuais vazamentos de dados decorrentes da
      utilização inadequada da API feita pela EMPRESA.
  </p>
  <br/>
  <p class="bold">
      19. Aceite
  </p>
  <br/>
  <p>
      AO CONCORDAR COM ESTES TERMOS E INICIAR O USO DA PLATAFORMA GUPY, VOCÊ SE
      COMPROMETE A RESPEITAR AS CONDIÇÕES EXPOSTAS DE FORMA IRRETRATÁVEL E
      IRREVOGÁVEL, BEM COMO A UTILIZAR DE MODO PROVEITOSO E LEGAL OS SERVIÇOS A
      ELE OFERECIDOS PELA PLATAFORMA DA GUPY.
  </p>
  <br/>
  <p class="bold">
      20. Foro
  </p>
  <br/>
  <p>
      Conflitos decorrentes destes Termos deverão ser resolvidos no Foro Central
      da Comarca de São Paulo/SP.
  </p>
  <br/>
  <p class="text-right">
      Atualizado pela última vez em 07 de junho de 2023
  </p>
  '
    />
  </div>
);

export default TermsDocument;
