import React from 'react';
import PropTypes from 'prop-types';
import { MainSelectList } from '@gupy/front-commons';

import careerPagePropTypes from '../../../../prop-types/careerPagePropTypes';
import childAppPropTypes from '../../../../prop-types/childAppPropTypes';
import rscChildAppStatusEnum from '../../../../enums/RscChildAppStatusEnum';

import './ChildAppCareerPages.scss';

const ChildAppCareerPages = ({
  editMode,
  childApp,
  childAppCareerPages,
  setChildAppCareerPages,
  rscLinkedinIntegrationCareerPages,
  messages,
}) => {
  const shouldRenderOnEditMode = editMode
    && childApp.status === rscChildAppStatusEnum.READY_TO_SYNC_DATA;

  const renderOnEditMode = () => {
    const getAvailableRscLinkedinIntegrationCareerPages = () => rscLinkedinIntegrationCareerPages
      .filter(({ isAvailable }) => isAvailable)
      .map(({ name, id }) => ({
        label: name,
        value: id.toString(),
      }));

    const getAdaptedChildAppCareerPagesIds = () => childAppCareerPages
      .map(({ careerPageId }) => careerPageId.toString());

    const formatChildAppCareerPagesToModel = careerPages => careerPages
      .map(careerPage => ({
        careerPageId: Number(careerPage),
      }));

    return (
      <span className="job-board-panel-rsc-child-apps__career-pages-on-edit-mode">
        <MainSelectList
          id={`child-app-${childApp.id}-career-pages`}
          name={`child-app-${childApp.id}-career-pages`}
          noItemsSelectedLabel={messages.rscIntegrationChildAppsCareerPageNoItemsSelectedLabel}
          selectAllLabel={messages.rscIntegrationChildAppsCareerPageSelectAllLabel}
          value={getAdaptedChildAppCareerPagesIds()}
          data={getAvailableRscLinkedinIntegrationCareerPages()}
          onChange={e => setChildAppCareerPages(formatChildAppCareerPagesToModel(e.target.value))}
          multiSelect
          responsive
        />
      </span>
    );
  };

  const renderOnReadMode = () => {
    const renderCareerPagesSeparetedByCommas = childAppCareerPages
      .map(({ careerPageId }) => {
        const careerPage = rscLinkedinIntegrationCareerPages.find(({ id }) => id === careerPageId);
        return careerPage ? careerPage.name : '';
      })
      .filter(name => !!name)
      .join(', ');

    return (
      <span className="job-board-panel-rsc-child-apps__career-pages-on-read-mode">
        {renderCareerPagesSeparetedByCommas}
      </span>
    );
  };

  return (
    shouldRenderOnEditMode ? renderOnEditMode() : renderOnReadMode()
  );
};

ChildAppCareerPages.propTypes = {
  editMode: PropTypes.bool.isRequired,
  childApp: childAppPropTypes.isRequired,
  childAppCareerPages: PropTypes.arrayOf(PropTypes.shape({
    careerPageId: PropTypes.number.isRequired,
  })).isRequired,
  setChildAppCareerPages: PropTypes.func.isRequired,
  rscLinkedinIntegrationCareerPages: PropTypes.arrayOf(careerPagePropTypes).isRequired,
  messages: PropTypes.object.isRequired,
};

export default ChildAppCareerPages;
