import { combineReducers } from 'redux';
import { DiversityLegalTermActionTypes } from './ActionTypes';

export const userShouldView = (state = false, action) => {
  switch (action.type) {
  case DiversityLegalTermActionTypes.GET_COMPANY_USER_CONSENT_SUCCESS:
  case DiversityLegalTermActionTypes.GET_COMPANY_USER_CONSENT_FAIL:
  case DiversityLegalTermActionTypes.SAVE_COMPANY_USER_CONSENT_SUCCESS:
    return action.payload.userShouldView;
  default:
    return state;
  }
};

export default combineReducers({
  userShouldView,
});
