import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import DefaultAppFooter from '../AppFooter';
import { isInsideIframe } from '../helpers/isInsideIframe';

const propTypes = {
  children: PropTypes.any.isRequired,
};

const SimpleLayout = ({
  children,
}) => {
  const shouldRenderHeaderAndFooter = !isInsideIframe();

  const AppFooter = () => shouldRenderHeaderAndFooter
    && <DefaultAppFooter/>;

  return (
    <Fragment>
      {children}
      <AppFooter/>
    </Fragment>
  );
};

SimpleLayout.propTypes = propTypes;

export default withRouter(SimpleLayout);
