import { combineReducers } from 'redux';

import JobInternalDataActionTypes from '../../constants/Job/JobInternalDataActionTypes';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';

const initialModelForm = {
  id: undefined,
  name: '',
  status: undefined,
  approvers: undefined,
  recruiterId: undefined,
  managerId: undefined,
  reason: undefined,
  code: '',
  observation: '',
  company: {},
  permissions: {},
  publicationType: undefined,
  numVacancies: 0,
  jobVacanciesCodes: [],
  salaryFrom: '',
  salaryTo: '',
  isSalaryRange: true,
  salaryCurrencyType: undefined,
  workflowRequestMethod: '',
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.POST:
  case JobInternalDataActionTypes.PATCH:
    return true;
  case JobInternalDataActionTypes.POST_SUCCESS:
  case JobInternalDataActionTypes.POST_FAIL:
  case JobInternalDataActionTypes.PATCH_SUCCESS:
  case JobInternalDataActionTypes.PATCH_FAIL:
    return false;
  default:
    return state;
  }
};

const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.GET:
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA:
    return true;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  case JobInternalDataActionTypes.GET_SUCCESS:
  case JobInternalDataActionTypes.GET_FAIL:
    return false;
  default:
    return state;
  }
};

export const jobApproversList = (state = [], action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    return action.jobApproversList;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  default:
    return state;
  }
};

export const jobRecruitersList = (state = [], action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    return action.jobRecruitersList;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  default:
    return state;
  }
};

export const jobManagersList = (state = [], action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    return action.jobManagersList;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  default:
    return state;
  }
};

export const onlyDefaultRecruiters = (state = false, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    return action.onlyDefaultRecruiters;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  default:
    return state;
  }
};

export const onlyDefaultManagers = (state = false, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    return action.onlyDefaultManagers;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  default:
    return state;
  }
};

export const reasonTypes = (state = [], action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
    return action.jobReasonTypes;
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_SUCCESS:
  case JobInternalDataActionTypes.POST_SUCCESS:
  case JobInternalDataActionTypes.PATCH_SUCCESS: {
    const newState = {
      approvers: action.modelForm.approvers,
      workflowRequestMethod: action.modelForm.workflowRequestMethod,
      careerPage: action.modelForm.careerPage,
      code: action.modelForm.code,
      company: action.modelForm.company,
      creatorId: action.modelForm.creatorId,
      id: action.modelForm.id,
      isFromTemplate: action.modelForm.isFromTemplate,
      isSalaryRange: !!action.modelForm.salaryTo,
      jobType: action.modelForm.jobType,
      jobVacanciesCodes: action.modelForm.jobVacanciesCodes,
      managerId: action.modelForm.managerId,
      name: action.modelForm.name,
      numVacancies: action.modelForm.numVacancies,
      observation: action.modelForm.observation || '',
      permissions: action.modelForm.permissions,
      publicationType: action.modelForm.publicationType,
      reason: action.modelForm.reason,
      recruiterId: action.modelForm.recruiterId,
      salaryCurrencyType: action.modelForm.salaryCurrencyType || state.salaryCurrencyType,
      salaryFrom: action.modelForm.salaryFrom,
      salaryTo: action.modelForm.salaryTo,
      status: action.modelForm.status,
      customFormSchema: action.modelForm.customFormSchema,
      customFormData: action.modelForm.customFormData,
      // eslint-disable-next-line dot-notation
      metadata: action.modelForm['_metadata'],
    };

    return newState;
  }
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA:
  case JobInternalDataActionTypes.CLEAR_FORM:
    return initialModelForm;
  case JobInternalDataActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  case JobInternalDataActionTypes.EDIT:
    return Object.assign(
      {},
      initialModelForm,
      action.modelForm,
    );
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.POST:
  case JobInternalDataActionTypes.PATCH:
  case JobInternalDataActionTypes.EDIT:
  case JobInternalDataActionTypes.CLEAR_FORM:
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA:
    return {};
  case JobInternalDataActionTypes.POST_FAIL:
  case JobInternalDataActionTypes.PATCH_FAIL:
  case JobInternalDataActionTypes.INIT_JOB_INTERNAL_DATA_FAIL:
  case JobDetailsActionTypes.PATCH_NO_VALIDATION_FAIL:
    return action.validation;
  default:
    return state;
  }
};

const vacanciesCodesIsOpen = (state = false, action) => {
  switch (action.type) {
  case JobInternalDataActionTypes.TOGGLE_VACANCIES_CODES_IS_OPEN:
    return !state;
  default:
    return state;
  }
};

export default combineReducers({
  jobApproversList,
  jobRecruitersList,
  jobManagersList,
  reasonTypes,
  validation,
  modelForm,
  isSaving,
  isLoading,
  vacanciesCodesIsOpen,
  onlyDefaultRecruiters,
  onlyDefaultManagers,
});
