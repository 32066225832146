import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import ModalDiscoveryProductsStyles from './ModalDiscoveryProductsWrapper.styles';

const propTypes = {
  currentContent: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  styleModalContent: PropTypes.styleModalContent,
  styleModalDiv: PropTypes.styleModalDiv,
  styleModalContentList: PropTypes.styleModalContentList,
  styleFooterModalContent: PropTypes.styleFooterModalContent,
};
const {
  ModalContent,
  ModalContentList,
  FooterModalContent,
} = ModalDiscoveryProductsStyles;
const ContentModal = ({
  currentContent,
  data,
  styleModalContent,
  styleModalDiv,
  styleModalContentList,
  styleFooterModalContent,
}) => (
  <Fragment>
    <ModalContent {...styleModalContent}>
      <div className="text-content" {...styleModalDiv}>

        <img
          id="title"
          alt={currentContent.altTitleText}
          src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/${currentContent.name}-logo.svg`}
        />

        {currentContent.title && (<h4 className="sub-title">{currentContent.title}</h4>)}

        <ModalContentList {...styleModalContentList}>

          {currentContent.description1 && (
            <div className="conteudo-modal">
              <img style={{ width: '24px', height: '24px' }} src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/arrow-right.svg`} alt="" className="" />
              <p>
                {currentContent.description1}
              </p>
            </div>
          )}
          {currentContent.description2 && (
            <div className="conteudo-modal">
              <img style={{ width: '24px', height: '24px' }} src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/arrow-right.svg`} alt="" className="" />
              <p>
                {currentContent.description2}
              </p>
            </div >
          )}
          {currentContent.description3 && (
            <div className="conteudo-modal">
              <img style={{ width: '24px', height: '24px' }} src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/arrow-right.svg`} alt="" className="" />
              <p>
                {currentContent.description3}
              </p>
            </div >
          )}

        </ModalContentList>
      </div>
      <FooterModalContent {...styleFooterModalContent}>

        <img
          id="title"
          alt={currentContent.altTitleText}
          src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/${currentContent.name}-product.svg`}
        />
        {currentContent.link && (
          <Button
            text={currentContent.buttonModal}
            aria-label={currentContent.buttonModal}
            href={currentContent.link}
            rel="noreferrer"
            size="md"
            target="_blank"
          > {data.buttonModal}</Button>)
        }
      </FooterModalContent>

    </ModalContent>
  </Fragment >
);
ContentModal.prototypes = propTypes;
export default ContentModal;
