import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as service from './Service';
import {
  cleanBatchInsertionState,
  createBatchCompanyEmployeesFulfilled,
  createCompanyEmployeeFulfilled,
  createCompanyEmployeeRejected,
  deleteCompanyEmployeesFulfilled,
  deleteCompanyEmployeesRejected,
  getCountriesFulfilled,
  listEmployeesFulfilled,
  updateCompanyEmployeeFulfilled,
  updateCompanyEmployeeRejected,
  createBatchCompanyEmployeesRejected,
  deleteBatchCompanyEmployeesFulfilled,
  cleanBatchExclusionState,
  deleteBatchCompanyEmployeesRejected,
  cleanCompanyEmployeeToastState,
} from './Actions';
import {
  COMPLETE_BATCH_INSERTION,
  CREATE_BATCH_COMPANY_EMPLOYEES,
  CREATE_COMPANY_EMPLOYEE,
  DELETE_COMPANY_EMPLOYEES,
  UPDATE_EMPLOYEE,
  GET_COUNTRIES,
  LIST_EMPLOYEES,
  DELETE_BATCH_COMPANY_EMPLOYEES,
  COMPLETE_BATCH_EXCLUSION,
  CLEAN_BATCH_EXCLUSION_STATE,
} from './ActionTypes';

function* listEmployees({ offset, search, column, order }) {
  try {
    const response = yield call(service.listEmployees, offset, search, column, order);
    yield put(listEmployeesFulfilled(response.body));
  } catch (error) {
    const emptyResult = { results: [], totalPages: 0, totalResults: 0 };
    yield put(listEmployeesFulfilled(emptyResult));
  }
}

function* createCompanyEmployee(action) {
  try {
    const response = yield service.create({
      employees: [action.payload.companyEmployee],
    });
    const [companyEmployee] = response.body;
    yield put(createCompanyEmployeeFulfilled(companyEmployee));
    yield listEmployees({ offset: 0 });
  } catch (error) {
    yield put(createCompanyEmployeeRejected(error));
  }
}

function* deleteCompanyEmployees(action) {
  try {
    const response = yield service.remove({
      employees: action.payload.companyEmployeeIds,
    });
    const deleted = response.body;
    yield put(deleteCompanyEmployeesFulfilled(deleted));
    yield listEmployees({ offset: 0 });
  } catch (error) {
    yield put(deleteCompanyEmployeesRejected(error));
  }
}

function* getCountries() {
  const response = yield call(service.getCountries);
  yield put(getCountriesFulfilled(response.body));
}

function* createBatchCompanyEmployees({ payload }) {
  try {
    const response = yield call(service.createBatch, payload);
    yield put(createBatchCompanyEmployeesFulfilled(response.body));
  } catch (error) {
    const errors = (error.body && error.body.errors) || error.statusCode;
    yield put(createBatchCompanyEmployeesRejected(errors));
  }
}

function* completeBatchInsertion() {
  yield put(cleanBatchInsertionState());
  yield listEmployees({ offset: 0 });
}

function* updateEmployee({ payload }) {
  try {
    yield service.update(payload.companyEmployee);
    yield put(updateCompanyEmployeeFulfilled());
    yield listEmployees({ offset: 0 });
  } catch (error) {
    yield put(updateCompanyEmployeeRejected(error));
  }
}

function* deleteBatchCompanyEmployees({ payload }) {
  try {
    const response = yield call(service.deleteBatch, payload);
    yield put(deleteBatchCompanyEmployeesFulfilled(response.body));
  } catch (error) {
    const errors = (error.body && error.body.errors) || error.statusCode;
    yield put(deleteBatchCompanyEmployeesRejected(errors));
  }
}

function* completeBatchExclusion() {
  yield put(cleanBatchExclusionState());
  yield listEmployees({ offset: 0 });
}

function* completeManualInsertion() {
  yield put(cleanCompanyEmployeeToastState());
}

function* Saga() {
  yield all([
    takeLatest(CREATE_COMPANY_EMPLOYEE, createCompanyEmployee),
    takeLatest(DELETE_COMPANY_EMPLOYEES, deleteCompanyEmployees),
    takeLatest(UPDATE_EMPLOYEE, updateEmployee),
    takeLatest(LIST_EMPLOYEES, listEmployees),
    takeLatest(GET_COUNTRIES, getCountries),
    takeLatest(CREATE_BATCH_COMPANY_EMPLOYEES, createBatchCompanyEmployees),
    takeLatest(COMPLETE_BATCH_INSERTION, completeBatchInsertion),
    takeLatest(DELETE_BATCH_COMPANY_EMPLOYEES, deleteBatchCompanyEmployees),
    takeLatest(COMPLETE_BATCH_EXCLUSION, completeBatchExclusion),
    takeLatest(CLEAN_BATCH_EXCLUSION_STATE, completeManualInsertion),
  ]);
}

export default Saga;
