import { takeLatest, put } from 'redux-saga/effects';
import ProfileService from '../../services/Profile/ProfileService';
import * as types from './ActionTypes';

export function* getProfile() {
  try {
    const payload = yield ProfileService.get();
    const { enablePreHireStep } = payload.body;
    yield put({
      type: types.PRE_HIRE_STEP_PROFILE_SUCCESS,
      payload: enablePreHireStep,
    });
  } catch (error) {
    yield put({
      type: types.PRE_HIRE_STEP_PROFILE_FAIL,
    });
  }
}

export function* enablePreHire({ payload }) {
  try {
    const response = yield ProfileService.patch({ enablePreHireStep: payload });
    const { enablePreHireStep } = response.body;

    yield put({
      type: types.PRE_HIRE_STEP_ENABLE_SUCCESS,
    });
    yield put({
      type: types.PRE_HIRE_STEP_PROFILE_SUCCESS,
      payload: enablePreHireStep,
    });
  } catch (error) {
    yield put({
      type: types.PRE_HIRE_STEP_ENABLE_FAIL,
    });
  }
}

function* PreHireSaga() {
  yield takeLatest(types.PRE_HIRE_STEP_PROFILE, getProfile);
  yield takeLatest(types.PRE_HIRE_STEP_ENABLE, enablePreHire);
}

export default PreHireSaga;
