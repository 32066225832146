import api from '../../../../api/DarthVaderClient';

export const InsightsService = {
  async getInsights() {
    return api.get('/analytics/companies/week-job-insights');
  },
  async getJobsWithQualifiedApplications() {
    return api.get('/analytics/companies/jobs-insights/jobs-with-qualified-applications?limit=100');
  },
  async getJobsCloseToExpire() {
    return api.get('/analytics/companies/jobs-insights/jobs-close-to-expire?limit=100');
  },
};
