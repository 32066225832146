import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('JobDetails',
  'CHANGE_FORM',
  'CLEAR_FORM',
  asyncActionType('INIT_JOB_DETAILS'),
  asyncActionType('GET'),
  asyncActionType('POST'),
  asyncActionType('PATCH'),
  asyncActionType('PATCH_NO_VALIDATION'),
  asyncActionType('PATCH_NAME_VALIDATION'),
  asyncActionType('POST_NO_VALIDATION'));
