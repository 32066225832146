import { put, takeLatest, call } from 'redux-saga/effects';

import { buildToast, ToastTypes } from '@gupy/front-commons';
import { ApplicationPartnerNameEnum } from '@gupy/enums';

import history from '../../../../../history';
import JobService from '../../../../../services/Job/JobService';
import CandidateService from '../../../../../services/Candidate/CandidateService';
import BasicInformationFormValidator from './components/BasicInformationFormValidator';
import EmailVerificationFormValidator from './components/EmailVerificationFormValidator';

import {
  CandidateInsertionActionTypes,
  verifyEmailSuccess,
} from './CandidateInsertionAction';

import {
  initJobApplication,
  getApplicationEmailMessages,
} from '../../../../../actions/Job/JobApplication/JobApplicationAction';

export function* addCandidate(action) {
  const {
    payload,
    jobId,
    currentStep,
    pagination,
  } = action;

  const form = new BasicInformationFormValidator();
  form.populate(payload);
  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: CandidateInsertionActionTypes.ADD_CANDIDATE_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield call(JobService.addCandidate, jobId, payload);

      if (payload.shouldSendEmailInvitation) {
        yield JobService.sendEmailInvitation(
          jobId, response.body.candidateId, payload.currentStepId,
        );
      }

      yield put({
        type: CandidateInsertionActionTypes.ADD_CANDIDATE_SUCCESS,
        response,
      });

      yield put(initJobApplication(jobId, currentStep, pagination));
    } catch (error) {
      yield put({
        type: CandidateInsertionActionTypes.ADD_CANDIDATE_FAIL,
        validation: form.validateErrorResponse(error),
        error,
      });
    }

    yield put({
      type: CandidateInsertionActionTypes.CLOSE_CANDIDATE_INSERTION_MODAL,
    });
  }
}

export function* relocateCandidate(action) {
  const { jobId, candidateId } = action;
  const partnerName = ApplicationPartnerNameEnum.manualInsertionAlreadyInTalentPool;

  try {
    const response = yield call(
      JobService.candidateInsertionRelocateCandidate,
      jobId,
      candidateId,
      partnerName,
    );

    yield put({
      type: CandidateInsertionActionTypes.RELOCATE_CANDIDATE_SUCCESS,
    });

    yield call(history.push, `candidates/${response.body.applicationId}`);
  } catch (error) {
    yield put({
      type: CandidateInsertionActionTypes.RELOCATE_CANDIDATE_FAIL,
      error,
    });
  }

  yield put({
    type: CandidateInsertionActionTypes.CLOSE_CANDIDATE_INSERTION_MODAL,
  });
}

export function* sendEmailInvitation(action) {
  const {
    jobId,
    candidateId,
    currentStepId,
    applicationId,
    messages,
  } = action;

  try {
    yield call(JobService.sendEmailInvitation, jobId, candidateId, currentStepId);

    yield put({
      type: CandidateInsertionActionTypes.SEND_EMAIL_INVITATION_SUCCESS,
      toastUpdate: buildToast(messages.success, ToastTypes.success),
    });

    yield put(getApplicationEmailMessages({ jobId, applicationId }));
  } catch (error) {
    yield put({
      type: CandidateInsertionActionTypes.SEND_EMAIL_INVITATION_FAIL,
      toastUpdate: buildToast(messages.error, ToastTypes.error),
      error,
    });
  }
}

function* verifyEmail(action) {
  const { payload, jobId } = action;
  const form = new EmailVerificationFormValidator();

  form.populate(payload);
  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: CandidateInsertionActionTypes.VERIFY_EMAIL_FAIL,
      validation,
    });
  } else {
    try {
      const response = yield JobService.verifyCandidate(payload.email, jobId);

      yield put(verifyEmailSuccess(payload.email, response.body));
    } catch (error) {
      yield put({
        type: CandidateInsertionActionTypes.VERIFY_EMAIL_FAIL,
        error,
      });
    }
  }
}

export function* getCandidateInsertionSources() {
  try {
    const response = yield call(CandidateService.getCandidateInsertionSources);

    yield put({
      type: CandidateInsertionActionTypes.CANDIDATE_INSERTION_SOURCES_SUCCESS,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: CandidateInsertionActionTypes.CANDIDATE_INSERTION_SOURCES_FAIL,
      error,
    });
  }
}

function* CandidateInsertionSaga() {
  yield takeLatest(CandidateInsertionActionTypes.ADD_CANDIDATE, addCandidate);
  yield takeLatest(CandidateInsertionActionTypes.RELOCATE_CANDIDATE, relocateCandidate);
  yield takeLatest(CandidateInsertionActionTypes.SEND_EMAIL_INVITATION, sendEmailInvitation);
  yield takeLatest(CandidateInsertionActionTypes.VERIFY_EMAIL, verifyEmail);
  yield takeLatest(CandidateInsertionActionTypes.VERIFY_EMAIL_SUCCESS,
    getCandidateInsertionSources);
}

export default CandidateInsertionSaga;
