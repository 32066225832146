import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import UserNotificationCard from './UserNotificationCard';
import UserNotificationEmptyCard from './UserNotificationEmptyCard';

const propTypes = {
  notifications: PropTypes.array.isRequired,
  onCardClick: PropTypes.func,
  onRemoveIconClick: PropTypes.func,
  fixedNotifications: PropTypes.array,
};

const NotificationCards = withRouter(({
  history,
  notifications,
  fixedNotifications,
  onCardClick,
  onRemoveIconClick,
}) => (
  <React.Fragment>
    {
      fixedNotifications ? fixedNotifications.map(n => (
        <UserNotificationCard
          key={n.id}
          notification={n}
          onCardClick={null}
          onRemoveIconClick={null}
        />
      )) : null}
    {
      notifications ? notifications.map(n => (
        <UserNotificationCard
          key={n.id}
          notification={n}
          history={history}
          onCardClick={onCardClick}
          onRemoveIconClick={onRemoveIconClick}
        />
      )) : null
    }
  </React.Fragment>
));

const UserNotificationsCards = ({
  notifications, onCardClick,
  onRemoveIconClick, fixedNotifications = [] }) => (
  notifications && notifications.length === 0
  && fixedNotifications && fixedNotifications.length === 0
    ? <UserNotificationEmptyCard />
    : (
      <div className="gdp-notifications-panel__content" data-testid="notification-cards-panel">
        <div className="gdp-notifications-panel__fixed-content">
          <div className="gdp-notifications-panel__resizable-content">
            <NotificationCards
              notifications={notifications}
              fixedNotifications={fixedNotifications}
              onCardClick={onCardClick}
              onRemoveIconClick={onRemoveIconClick}
            />
          </div>
        </div>
      </div>
    )
);

UserNotificationsCards.propTypes = propTypes;

export default UserNotificationsCards;
