import { call, put, takeLatest } from 'redux-saga/effects';

import JobCustomFormTemplateService from './Service';
import * as types from './ActionTypes';
import {
  getSchema,
  saveCompanySchemaSuccess,
  saveCompanySchemaFail,
} from './Action';

function* get() {
  try {
    const response = yield call(JobCustomFormTemplateService.get);

    yield put({
      type: types.GET_SUCCESS,
      jobCustomFormTemplate: response.body,
    });
  } catch (error) {
    if (error.statusCode === 404) {
      yield put({
        type: types.GET_SUCCESS,
        jobCustomFormTemplate: { schema: null },
      });
      return;
    }
    yield put({
      type: types.GET_FAIL,
      error,
    });
  }
}

function* patch(action) {
  const { schema, successMessage, failMessage } = action;
  try {
    yield JobCustomFormTemplateService.patch({ schema });
    yield put(saveCompanySchemaSuccess(successMessage));
    yield put(getSchema());
  } catch (error) {
    yield put(saveCompanySchemaFail(failMessage));
  }
}

function* JobCustomFormTemplateSaga() {
  yield takeLatest(types.GET, get);
  yield takeLatest(types.SAVE_COMPANY_SCHEMA, patch);
}

export { JobCustomFormTemplateSaga, get, patch };
