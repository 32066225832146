import { call, put, takeLatest } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';
import WhatsAppActionTypes from './ActionTypes';
import WhatsSetupService from './WhatsAppSetupService';

export function* getWhatsAppSetup(action) {
  const { payload: { companyEmail }, messages } = action;

  try {
    const response = yield call(WhatsSetupService.getIntegration, companyEmail);
    yield put({
      type: WhatsAppActionTypes.GET_WHATSAPP_SETUP_SUCCESS,
      payload: response.body.data,
    });
  } catch (error) {
    yield put({
      type: WhatsAppActionTypes.GET_WHATSAPP_SETUP_FAIL,
      error,
      toast: buildToast(
        messages.error,
        ToastTypes.error,
        { autoClose: 5000 },
      ),
    });
  }
}

export function* saveWhatsAppSetup(action) {
  const { payload, messages } = action;

  try {
    const response = yield call(WhatsSetupService.upsertIntegration, payload);
    yield put({
      type: WhatsAppActionTypes.SAVE_WHATSAPP_SETUP_SUCCESS,
      payload: response.body.data,
      toast: buildToast(
        messages.success,
        ToastTypes.success,
        { autoClose: 5000 },
      ),
    });
  } catch (error) {
    yield put({
      type: WhatsAppActionTypes.SAVE_WHATSAPP_SETUP_FAIL,
      error,
      toast: buildToast(
        messages[error.message] || messages.error,
        ToastTypes.error,
        { autoClose: 5000 },
      ),
    });
  }
}

function* WhatsAppSetupSaga() {
  yield takeLatest(WhatsAppActionTypes.GET_WHATSAPP_SETUP, getWhatsAppSetup);
  yield takeLatest(WhatsAppActionTypes.SAVE_WHATSAPP_SETUP, saveWhatsAppSetup);
}

export default WhatsAppSetupSaga;
