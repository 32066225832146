import React, { } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import careerPagePropTypes from '../../prop-types/careerPagePropTypes';
import childAppPropTypes from '../../prop-types/childAppPropTypes';
import { getMessages } from '../../Messages';

import rscChildAppStatusEnum from '../../enums/RscChildAppStatusEnum';

import './RscLinkedinIntegrationChildAppsItem.scss';

import ChildAppDefault from './ChildAppItem/ChildAppDefault';
import ChildAppReadyToConnect from './ChildAppItem/ChildAppReadyToConnect';
import ChildAppReadyEnablingFeatures from './ChildAppItem/ChildAppEnablingFeatures';

const RscLinkedinIntegrationChildAppsItem = ({
  intl,
  childApp,
  rscLinkedinIntegrationCareerPages,
  handleChildAppSyncSubmit,
  handleChildAppEditSubmit,
  handleChildAppSyncRestartSubmit,
  handleChildAppDesyncSubmit,
}) => {
  const messages = getMessages(intl);

  if (childApp.status === rscChildAppStatusEnum.READY_TO_CONNECT_WITH_LINKEDIN) {
    return (
      <ChildAppReadyToConnect
        messages={messages}
        childApp={childApp}
      />
    );
  }

  if (childApp.status === rscChildAppStatusEnum.ENABLING_LINKEDIN_FEATURES) {
    return (
      <ChildAppReadyEnablingFeatures
        messages={messages}
      />
    );
  }

  return (
    <ChildAppDefault
      messages={messages}
      childApp={childApp}
      rscLinkedinIntegrationCareerPages={rscLinkedinIntegrationCareerPages}
      handleChildAppEditSubmit={handleChildAppEditSubmit}
      handleChildAppSyncRestartSubmit={handleChildAppSyncRestartSubmit}
      handleChildAppSyncSubmit={handleChildAppSyncSubmit}
      handleChildAppDesyncSubmit={handleChildAppDesyncSubmit}
    />
  );
};

RscLinkedinIntegrationChildAppsItem.propTypes = {
  intl: intlShape,
  childApp: childAppPropTypes.isRequired,
  rscLinkedinIntegrationCareerPages: PropTypes.arrayOf(careerPagePropTypes).isRequired,
  handleChildAppSyncSubmit: PropTypes.func.isRequired,
  handleChildAppEditSubmit: PropTypes.func.isRequired,
  handleChildAppSyncRestartSubmit: PropTypes.func.isRequired,
  handleChildAppDesyncSubmit: PropTypes.func.isRequired,
};

export default injectIntl(RscLinkedinIntegrationChildAppsItem);
