import { defineMessages } from 'react-intl';
import formatMessages from '@gupy/front-helpers/src/helpers/formatMessages';

const messages = defineMessages({
  seeApplication: {
    id: 'see-application',
    defaultMessage: 'Ver perfil',
  },
});

export const getMessages = intl => formatMessages(intl, messages);
