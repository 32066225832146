import api from '../../../../api/DarthVaderClient';

export default {
  detail(id) {
    return api.get(`/custom-test/companies/${id}/detail`);
  },
  create(testId, payload) {
    return api.post(`/custom-test/companies/${testId}/questions`, payload);
  },
  read(id, testId) {
    return api.get(`/custom-test/companies/${testId}/questions/${id}`);
  },
  update(id, testId, payload) {
    return api.put(`/custom-test/companies/${testId}/questions/${id}`, payload);
  },
  delete(id, testId) {
    return api.delete(`/custom-test/companies/${testId}/questions/${id}`);
  },
  saveSorted(testId, payload) {
    return api.put(`/custom-test/companies/${testId}/sort`, payload);
  },
};
