import { BaseForm } from '@gupy/front-commons';

class EmailTemplateForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
          maxLength: 50,
        },
      },
      subject: {
        validate: {
          notEmpty: true,
          maxLength: 254,
        },
      },
      body: {
        validate: {
          notEmptyHtml: true,
        },
      },
      type: {
        validate: {
          notEmpty: true,
        },
      },
      attachments: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default EmailTemplateForm;
