import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from 'react-intl';
import { ApplicationPartnerNameEnum } from '@gupy/enums';

import Dialog from '@gupy/design-system/Dialogs/Dialog/Dialog';
import { ModalFullscreenItem } from '@gupy/front-commons';

import { ActionGAEnum, CategoryGAEnum } from '../../../../../../src/constants/Job/JobApplication/JobApplicationGAEnum';
import ActionBody from './components/ActionBody';
import BasicInformationForm from './components/BasicInformationForm';
import EmailVerificationForm from './components/EmailVerificationForm';

import {
  addCandidate,
  changeForm,
  closeCandidateInsertionModal,
  relocateCandidate,
  resetForm,
  verifyEmail,
} from './CandidateInsertionAction';

import getLabels from './CandidateInsertion.labels';
import renderStepTypeName from '../../helpers/renderStepTypeName';
import { withSendGAEvent } from '../../../../../helpers/withSendGAEvent';

const propTypes = {
  addCandidate: PropTypes.func.isRequired,
  CandidateInsertion: PropTypes.object.isRequired,
  changeForm: PropTypes.func.isRequired,
  closeCandidateInsertionModal: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  intl: intlShape.isRequired,
  isOpen: PropTypes.bool,
  jobCode: PropTypes.string,
  jobId: PropTypes.number,
  jobName: PropTypes.string,
  jobSteps: PropTypes.array,
  onCloseClick: PropTypes.func,
  relocateCandidate: PropTypes.func.isRequired,
  resetForm: PropTypes.func,
  verifyEmail: PropTypes.func.isRequired,
  currentStep: PropTypes.object.isRequired,
  pagination: PropTypes.object.isRequired,
};

const defaultProps = {
  isOpen: false,
  jobCode: '',
  jobId: null,
  jobName: '',
  jobSteps: [],
  onCloseClick: () => {},
};

const adaptCandidateInsertionSources = (sources, labels) => {
  const { chromeExtension, linkedinRsc, ...sourcesForCandidateInsertion } = sources;

  return Object
    .keys(sourcesForCandidateInsertion)
    .map(key => ({ label: labels[key], value: key }));
};

class CandidateInsertionModal extends Component {
  labels = {};

  constructor(props) {
    super(props);

    this.labels = getLabels(this.props.intl);
  }

  handleCloseModal = () => {
    withSendGAEvent(
      CategoryGAEnum.jobApplicationsAddCandidate,
      ActionGAEnum.jobApplicationsCancelAddCandidate,
      () => this.props.onCloseClick(),
    )();
  }

  handleFieldChange = (event) => {
    const { target } = event;

    this.props.changeForm({
      [target.name]: target.value,
    });
  }

  handleDropdownChange = ({ name, value }) => {
    this.props.changeForm({
      [name]: value,
    });
  }

  handleVerifyEmail = () => {
    this.props.verifyEmail(this.props.CandidateInsertion.modelForm, this.props.jobId);
  }

  handleAddCandidate = () => {
    const {
      jobId,
      currentStep,
      pagination,
      CandidateInsertion,
    } = this.props;

    this.props.addCandidate({
      payload: {
        ...CandidateInsertion.modelForm,
        partnerName: ApplicationPartnerNameEnum.manualInsertion,
      },
      jobId,
      currentStep,
      pagination,
      messages: { success: this.labels.candidateAddSuccess, error: this.labels.candidateAddError },
    });
  }

  handleEmailRetype = () => {
    this.props.resetForm();
  }

  handleApplicationRedirect = () => {
    const { modelForm } = this.props.CandidateInsertion;

    this.props.history.push(`candidates/${modelForm.applicationId}`);
    this.props.closeCandidateInsertionModal();
  }

  handleRelocateCandidate = () => {
    const { modelForm } = this.props.CandidateInsertion;

    this.props.relocateCandidate(this.props.jobId, modelForm.candidateId);
  }

  renderModalItemBody = () => {
    const {
      isSaving,
      modelForm,
      validation,
    } = this.props.CandidateInsertion;

    const candidateInsertionSources = adaptCandidateInsertionSources(
      this.props.CandidateInsertion.candidateInsertionSources,
      this.labels,
    );

    let commonProps = {
      disabled: isSaving,
      intl: this.props.intl,
      leftButtonText: this.labels.retypeAnotherEmail,
      onLeftButtonClick: this.handleEmailRetype,
      titleHighlight: modelForm.fullName,
    };

    if (modelForm.isCandidateOnCurrentJob) {
      return (
        <ActionBody
          {...commonProps}
          onRightButtonClick={this.handleApplicationRedirect}
          rightButtonText={this.labels.viewCandidateProfile}
          title={this.labels.candidateIsOnCurrentJob}
        />
      );
    }

    if (modelForm.isCandidateOnCurrentCompany) {
      return (
        <ActionBody
          {...commonProps}
          onRightButtonClick={this.handleRelocateCandidate}
          rightButtonText={this.labels.relocateJob}
          title={this.labels.candidateIsOnCurrentCompany}
        />
      );
    }

    commonProps = {
      disabled: isSaving,
      intl: this.props.intl,
      modelForm,
      onChange: this.handleFieldChange,
      onChangeDropdown: this.handleDropdownChange,
      validation,
    };

    if (modelForm.isNewCandidate) {
      const jobSteps = this.props.jobSteps.map(js => ({
        label: renderStepTypeName(this.props.intl, js.name),
        value: js.id,
      }));

      return (
        <BasicInformationForm
          {...commonProps}
          jobSteps={jobSteps}
          candidateInsertionSources={candidateInsertionSources}
          onAddCandidate={this.handleAddCandidate}
        />
      );
    }

    return (
      <EmailVerificationForm
        {...commonProps}
        onVerifyEmail={this.handleVerifyEmail}
      />
    );
  }

  render() {
    const {
      isOpen,
      jobCode,
      jobName,
    } = this.props;

    return (
      <Dialog
        id="insertionModal"
        fullScreen
        closeOnEsc
        title={(
          <div className="candidate-insertion-modal__job-title">
            <div className="box">
              <hr/>
              <h6>
                {this.labels.candidateManualInsertion}
              </h6>
              <div className="subtitle">
                {jobCode ? <span>{jobCode}</span> : null}
                <h4>{jobName}</h4>
              </div>
            </div>
          </div>
        )}
        open={isOpen}
        onClose={this.handleCloseModal}
        className="candidate-insertion-modal"
      >
        <div className="candidate-insertion-modal__item">
          <span className="candidate-insertion-modal__hint">{this.labels.candidateManualInsertionHint}</span>
          <ModalFullscreenItem>
            {this.renderModalItemBody()}
          </ModalFullscreenItem>
        </div>
      </Dialog>
    );
  }
}

CandidateInsertionModal.propTypes = propTypes;
CandidateInsertionModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  CandidateInsertion: state.reducers.CandidateInsertionReducer,
});

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    addCandidate,
    changeForm,
    closeCandidateInsertionModal,
    verifyEmail,
    relocateCandidate,
    resetForm,
  },
)(CandidateInsertionModal)));
