import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';

import { Button } from '@gupy/design-system';

import {
  openConfirmDialog,
} from '../../../../actions/App/AppActions';

import Grid from '../../../../components/Grid/Grid';
import GridContent from '../../../../components/Grid/GridContent';

import * as TestCorrectionActions from '../../redux/TestCorrection/TestCorrectionActions';

import TestsCorrectionQuestion from './TestsCorrectionQuestion';

class TestsCorrection extends React.Component {
  static propTypes = {
    Authentication: PropTypes.object.isRequired,
    questions: PropTypes.array.isRequired,
    history: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    test: PropTypes.object.isRequired,
    candidate: PropTypes.object.isRequired,
    hasError: PropTypes.bool.isRequired,
    changeTestCorrection: PropTypes.func.isRequired,
    readTestCorrection: PropTypes.func.isRequired,
    saveTestCorrection: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleTestCorrection = this.handleTestCorrection.bind(this);
    this.handleTestCorrectionSave = this.handleTestCorrectionSave.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { applicationId, jobId, testId },
      },
      readTestCorrection,
    } = this.props;
    readTestCorrection(applicationId, jobId, testId);
  }

  handleCancel() {
    const {
      history,
      match: {
        params: { applicationId, jobId },
      },
    } = this.props;
    history.push(`/companies/jobs/${jobId}/candidates/${applicationId}`);
  }

  handleTestCorrection(event) {
    const { model, value } = event;
    const { questions, changeTestCorrection } = this.props;

    const payload = questions.map(question => (
      question.answerId !== model.answerId
        ? question
        : {
          ...question,
          scored: value ? 1 : 0,
        }
    ));

    changeTestCorrection(payload);
  }

  handleTestCorrectionSave() {
    const {
      questions,
      saveTestCorrection,
      match: {
        params: { applicationId, jobId, testId },
      },
    } = this.props;
    const changed = questions.filter(question => (question.scored !== null));
    const payload = changed.map(question => ({
      id: question.answerId,
      scored: question.scored,
    }));

    saveTestCorrection({
      applicationId,
      jobId,
      testId,
      payload,
    });
  }

  render() {
    const {
      handleCancel,
      handleTestCorrection,
      handleTestCorrectionSave,
      props: { Authentication, questions, test, candidate, hasError },
    } = this;

    if (!test.title) return null;

    const candidateName = (
      candidate && candidate.name ? (
        <div className="custom-test-correction__candidate-name">
          {`${candidate.name} ${candidate.lastName}`}
        </div>
      ) : null
    );

    return (
      <Grid>
        <GridContent
          Authentication={Authentication}
          title={test.title}
          hasSideBar={false}
          headerClassName="custom-test-correction__header"
          customNode={candidateName}
        >
          <div className="custom-test-correction">
            <div className="col-sm-12 col-md-10 col-lg-8 custom-test-correction__body">
              {
                questions.map((question, index) => (
                  <TestsCorrectionQuestion
                    index={index}
                    key={`question-correction-${question.id.toString()}`}
                    model={question}
                    onChange={handleTestCorrection}
                  />
                ))
              }
            </div>
            {
              test.score === null ? (
                <div className="toolbar custom-test-correction__toolbar">
                  <div className="custom-test-correction__toolbar--right">
                    <Button
                      type="text"
                      onClick={handleCancel}
                    >
                      <FormattedMessage
                        id="back"
                        defaultMessage="Voltar"
                      />
                    </Button>
                    <Button
                      onClick={handleTestCorrectionSave}
                      disabled={!!questions.filter(q => q.answer && q.scored === null).length}
                    >
                      <FormattedMessage
                        id="save"
                        defaultMessage="Salvar"
                      />
                    </Button>
                  </div>
                  {hasError && (
                    <div className="custom-test-correction__error">
                      <FormattedMessage
                        id="something_went_wrong_try_again_later"
                        defaultMessage="Aconteceu algum imprevisto, já estamos de olho. Tente de novo mais tarde"
                      />
                    </div>
                  )}
                </div>
              ) : null
            }
          </div>
        </GridContent>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
  ...state.reducers.TestCorrection,
});

export default withRouter(injectIntl(connect(
  mapStateToProps,
  {
    openConfirmDialog,
    changeTestCorrection: TestCorrectionActions.changeTestCorrection,
    readTestCorrection: TestCorrectionActions.readTestCorrection,
    saveTestCorrection: TestCorrectionActions.saveTestCorrection,
  },
)(TestsCorrection)));
