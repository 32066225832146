import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import './TermsAndPrivacy.scss';

const PrivacyDocument = () => (
  <div className="terms-and-privacy">
    <FormattedHTMLMessage
      id="privacy-html"
      defaultMessage='
      <p>
          Nós da <strong>Gupy</strong> temos um forte compromisso com a proteção da privacidade e dos
          dados pessoais. Por isso, apresentamos esta Política de Privacidade, que
          possui todas as informações para você, USUÁRIO EMPRESA, a respeito da
          utilização, pela <strong>Gupy</strong>, de dados pessoais coletados na Plataforma Gupy. Vale
          destacar que, para os fins desta Política, Dados Pessoais são dados
          relacionados à pessoa natural identificada ou identificável – ou seja, não
          se aplica a dados de pessoa jurídica.
      </p>
      <br/>
      <p>
          Ao se cadastrar na Plataforma na qualidade de USUÁRIO EMPRESA, será
          solicitado seu aceite a esta Política de Privacidade, que indica que você
          está ciente e de acordo com a obtenção e o tratamento dos seus dados pela
          <strong>Gupy</strong>.
      </p>
      <br/>
      <p class="bold">
          QUE DADOS SÃO COLETADOS? E COMO?
      </p>
      <br/>
      <p>
          Podemos coletar dados como seu nome, país de origem, documento de
          identificação, e-mail e idioma para que criemos seu perfil de recrutador ou
          gestor, para que você possa utilizar a Plataforma GUPY segundo as
          permissões que lhe foram concedidas. Você ou sua EMPRESA também podem nos
          informar área, cargo e filial em que você atua e, se quiser, você pode nos
          informar quais são seus interesses na Gupy e qual é seu papel na
          Plataforma, bem como incluir uma foto em seu perfil. Por fim, você pode ser
          convidado a fazer testes para nos ajudar a determinar o perfil da EMPRESA.
          Coletamos apenas dados essenciais para nossa atividade e sob o fundamento
          legal do cumprimento do contrato firmado entre a EMPRESA e a GUPY para
          utilização da Plataforma GUPY para a realização de processos seletivos da
          EMPRESA.
      </p>
      <br/>
      <p>
          Os dados podem ser coletados das seguintes formas:
      </p>
      <ol type="i">
          <li>
              <p>
                  <strong>Dados pessoais inseridos por você na Plataforma:</strong> para viabilizar a
                  sua utilização da ferramenta, podemos solicitar a você e à EMPRESA
                  seus dados. Alguns dados serão fornecidos diretamente por você e
                  outros, pela EMPRESA. Os dados fornecidos pela EMPRESA serão de
                  responsabilidade desta e você deverá solicitar eventuais alterações
                  ao administrador da conta.
              </p>
          </li>
          <li>
              <p>
                  <strong>Dados decorrentes da utilização ordinária do site:</strong> também podemos
                  coletar dados por meio de cookies e outras tecnologias similares,
                  durante sua navegação na Plataforma Gupy. Os dados coletados desse
                  modo podem envolver informações a respeito de seu dispositivo,
                  dados de geolocalização, registros de acesso a aplicação de
                  internet (como IP, data e hora), tempo de uso da plataforma,
                  duração de acesso, cliques e termos buscados.
              </p>
          </li>
      </ol>
      <br/>
      <p class="bold">
          PARA QUE UTILIZAMOS OS DADOS PESSOAIS COLETADOS?
      </p>
      <p>
          Utilizamos os dados pessoais coletados para:
      </p>
      <ul>
          <li>
              <p>
                  Permitir sua utilização da Plataforma GUPY;
              </p>
          </li>
          <li>
              <p>
                  Entrar em contato com você, em razão de andamento de processo
                  seletivo pelo qual você seja responsável;
              </p>
          </li>
          <li>
              <p>
                  Analisar erros ou falhas em nossas operações por meio da
                  verificação dos nossos serviços;
              </p>
          </li>
          <li>
              <p>
                  Controlar permissionamento e ocultar informações sensíveis, de
                  acordo com o seu perfil;
              </p>
          </li>
          <li>
              <p>
                  Assegurar um cadastro único e seguro de usuários empresas dentro da
                  Gupy;
              </p>
          </li>
          <li>
              <p>
                  Reaproveitar dados de usuários empresas como usuários candidatos ou
                  vice-versa (exemplo: resultado de testes de perfil);
              </p>
          </li>
          <li>
              <p>
                  Possibilitar a integração da Gupy com outros softwares de gestão de
                  pessoas utilizados pelas empresas;
              </p>
          </li>
          <li>
              <p>
                  Vincular dados de uso do produto com dados hierárquicos das
                  empresas (exemplo: indicadores de recrutamento e seleção
                  específicos por área da empresa);
              </p>
          </li>
          <li>
              <p>
                  Guiar sua experiência na plataforma, de acordo com o que faz mais
                  sentido para suas necessidades;
              </p>
          </li>
          <li>
              <p>
                  Encaminhar dúvidas ou requerimentos ao suporte;
              </p>
          </li>
          <li>
              <p>
                  Sugerir novos produtos da Gupy para você;
              </p>
          </li>
          <li>
              <p>
                  Monitorar nosso atendimento e nossas interações com você.
              </p>
          </li>
      </ul>
      <p>
          Nunca venderemos seus dados para fins de marketing e não usaremos seus
          dados para fins diferentes dos indicados acima sem o seu consentimento!
      </p>
      <br/>
      <p class="bold">
          COM QUEM COMPARTILHAMOS OS DADOS COLETADOS?
      </p>
      <p>
          Seus dados serão compartilhados (e apenas se estritamente necessário) com
          alguns prestadores de serviços que contratamos para nos ajudar a exercer
          nossas atividades, quais sejam:
      </p>
      <ul>
          <li>
              <p>
                  Amazon Web Services: armazenamento em nuvem
              </p>
          </li>
          <li>
              <p>
                  Primeira Escolha: testes
              </p>
          </li>
          <li>
              <p>
                  MailGun: envio de e-mails
              </p>
          </li>
          <li>
              <p>
                  Zendesk: suporte
              </p>
          </li>
          <li>
              <p>
                  Metabase: análise de comportamento de usuários empresas
              </p>
          </li>
          <li>
              <p>
                  New Relic: monitoramento da aplicação
              </p>
          </li>
          <li>
              <p>
                  Google Analytics: utilização dos dados de navegação para análises
              </p>
          </li>
          <li>
              <p>
                  Hotjar: pesquisas no produto
              </p>
          </li>
      </ul>
      <p>
          Tais prestadores foram selecionados cuidadosamente, possuem estritos
          acordos de confidencialidade e obrigações relacionadas à proteção dos seus
          dados e poderão tratar seus dados apenas e tão somente para os fins de
          prestar o serviço contratado pela Gupy.
      </p>
      <p>
          Outras demandas legais específicas podem levar ao compartilhamento de dados
          pessoais, inclusive para eventual defesa dos nossos direitos e interesses
          em quaisquer tipos de conflitos ou para o atendimento de determinações de
          autoridades competentes.
      </p>
      <br/>
      <p class="bold">
          MEDIDAS DE SEGURANÇA DA INFORMAÇÃO
      </p>
      <br/>
      <p>
          Suas informações são armazenadas na nuvem, na Amazon Web Services,
          certificada com o certificado Privacy Shield, que atesta a mais alta
          qualidade em armazenamento e segurança da informação.
      </p>
      <p>
          Atuamos no sentido de implementar medidas de segurança que protejam nossos
          sistemas e bases de dados com as melhores tecnologias disponíveis no
          mercado. Dentre as medidas de segurança que adotamos, estão a utilização de
          criptografia dos dados, o controle de acesso de informações, a utilização
          de firewalls e a implementação de política interna de segurança da
          informação, arquitetura da solução de software com prevenção a invasão e
          utilização de HTTPS.
      </p>
      <p>
          No entanto, apesar de nossos esforços, considerando a natureza e
          arquitetura da internet, o que inclui elementos que não estão sob nosso
          controle, é impossível garantir que agentes mal-intencionados não
          conseguirão ter acesso ou fazer uso indevido de dados pessoais, pois se
          trata de um risco inerente à utilização de sistemas informatizados.
      </p>
      <br/>
      <p class="bold">
          POR QUANTO TEMPO MANTEREMOS SEUS DADOS?
      </p>
      <br/>
      <p>
          Seus dados pessoais serão mantidos apenas enquanto (i) você for apontado
          pela EMPRESA como um usuário da Plataforma GUPY e (ii) a GUPY e a EMPRESA
          tiverem uma relação comercial vigente. Uma vez que tais condições deixem de
          existir, seus dados serão excluídos.
      </p>
      <br/>
      <p class="bold">
          QUAIS SÃO OS SEUS DIREITOS RELATIVOS À PRIVACIDADE DOS SEUS DADOS?
      </p>
      <br/>
      <p>
          Você pode a qualquer tempo:
      </p>
      <br/>
      <ul>
          <li>
              <p>
                  Acessar seus dados: você tem acesso a seus dados no seu perfil da
                  Plataforma Gupy;
              </p>
          </li>
          <li>
              <p>
                  Corrigir seus dados: em caso de dados incorretos, você mesmo poderá
                  realizar a alteração de alguns deles em seu perfil. Caso a
                  alteração esteja bloqueada, converse com o administrador da sua
                  conta;
              </p>
          </li>
          <li>
              <p>
                  Excluir sua conta: sua conta é necessária para a utilização da
                  Plataforma GUPY, por isso sua exclusão será feita nas condições
                  mencionadas acima;
              </p>
          </li>
          <li>
              <p>
                  Portabilidade: Você pode solicitar a portabilidade de seus dados
                  armazenados na plataforma, o que significa que, caso haja esse tipo
                  de requerimento, a Gupy entregará a Você cópia dos seus dados
                  pessoais em formato de leitura comum, não podendo ser garantida a
                  compatibilidade entre tal formato e as configurações técnicas de
                  outras plataformas de recrutamento online.
              </p>
          </li>
      </ul>
      <p>
          Se sentir qualquer dificuldade em exercer seus direitos previstos nesta
          Política de Privacidade, entre em contato com o nosso departamento de
          Proteção de Dados via e-mail:
          <a href="mailto:renata.benjamin@gupy.com.br">
              renata.benjamin@gupy.com.br
          </a>
          . Será um prazer ajudar!
      </p>
      <p>
          Essa Política de Privacidade pode passar por atualizações. Desta forma,
          recomendamos visitar periodicamente esta página para que tenha conhecimento
          sobre as modificações. Caso sejam feitas alterações relevantes que
          necessitem de novas autorizações da sua parte, entraremos em contato com
          você via e-mail.
      </p>
      <br/>
      <p class="text-right">
          Atualizado pela última vez em 24 de agosto de 2020
      </p>
      '
    />
  </div>
);

export default PrivacyDocument;
