import api from '../../api/DarthVaderClient';

export default {
  patchCareerPage(id, payload) {
    return api.patch(`/career-pages/${id}/social-media`, payload);
  },
  getAll() {
    return api.get('/career-pages/social-media');
  },
  getAllCareerPages() {
    return api.get('/v2/career-pages?expand=sharing-metadata');
  },
  putCareerPage(id, payload) {
    return api.put(`/v2/career-pages/${id}/sharing-metadata`, payload);
  },
};
