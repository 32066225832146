import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};

const defaultProps = {
  title: undefined,
};

function Error404({ title }) {
  return (
    <div className="error-page">
      <span className="error-page__number">
        &nbsp;
      </span>
      <img
        className="error-page__image"
        src={`${process.env.REACT_APP_ASSETS_URL}/error/404.png`}
        alt="404"
        width="600"
      />
      <h6>
        {title || (
          <FormattedMessage
            id="error-404"
            defaultMessage="Ops! Não encontramos a página que você está procurando."
          />
        )}
      </h6>
    </div>
  );
}

Error404.propTypes = propTypes;
Error404.defaultProps = defaultProps;

export default Error404;
