import { combineReducers } from 'redux';

import { JobDeletionActionTypes } from './JobDeletionAction';
import JobActionTypes from '../../../constants/Job/JobActionTypes';
import { JobListActionTypes } from '../../../pages/JobList/Actions';
import JobApplicationActionTypes from '../../../constants/Job/JobApplication/JobApplicationActionTypes';

export const isDeletionModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobDeletionActionTypes.OPEN_JOB_DELETION_MODAL:
    return true;
  case JobDeletionActionTypes.CLOSE_JOB_DELETION_MODAL:
  case JobActionTypes.DELETE_SUCCESS:
  case JobActionTypes.DELETE_FAIL:
  case JobListActionTypes.DELETE_SUCCESS:
  case JobListActionTypes.DELETE_FAIL:
    return false;
  default:
    return state;
  }
};

export const currentJob = (state = null, action) => {
  switch (action.type) {
  case JobDeletionActionTypes.OPEN_JOB_DELETION_MODAL:
    return action.job;
  case JobDeletionActionTypes.CLOSE_JOB_DELETION_MODAL:
    return null;
  default:
    return state;
  }
};

export const isJobDeleting = (state = false, action) => {
  switch (action.type) {
  case JobActionTypes.DELETE:
    return true;
  case JobActionTypes.DELETE_SUCCESS:
  case JobActionTypes.DELETE_FAIL:
    return false;
  default:
    return state;
  }
};

const finalApplicationsCount = (state = null, action) => {
  switch (action.type) {
  case JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT:
    return null;
  case JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT_SUCCESS:
    return action.finalApplicationsCount;
  case JobApplicationActionTypes.GET_FINAL_APPLICATIONS_COUNT_FAIL:
    return -1;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobDeletionActionTypes.GET_CANCEL_REASONS_SUCCESS:
  case JobDeletionActionTypes.GET_CANCEL_REASONS_FAIL:
  case JobDeletionActionTypes.SEND_EMAIL_SUCCESS:
  case JobDeletionActionTypes.SEND_EMAIL_FAIL: {
    return false;
  }
  case JobDeletionActionTypes.GET_CANCEL_REASONS:
  case JobDeletionActionTypes.SEND_EMAIL:
    return true;
  default:
    return state;
  }
};

export const templateEmails = (state = [], action) => {
  switch (action.type) {
  case JobDeletionActionTypes.GET_TEMPLATE_EMAILS_SUCCESS: {
    return [
      ...action.payload,
    ];
  }
  case JobDeletionActionTypes.GET_TEMPLATE_EMAILS_FAIL:
    return [];
  default:
    return state;
  }
};

export const applicationsToSendFeedback = (state = [], action) => {
  switch (action.type) {
  case JobDeletionActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_SUCCESS: {
    return [
      ...action.payload,
    ];
  }
  case JobDeletionActionTypes.GET_APPLICATIONS_TO_SEND_EMAIL_FAIL:
    return [];
  default:
    return state;
  }
};

export default combineReducers({
  isDeletionModalOpen,
  currentJob,
  isJobDeleting,
  finalApplicationsCount,
  isLoading,
  templateEmails,
  applicationsToSendFeedback,
});
