import { put, takeLatest, call } from 'redux-saga/effects';
import { buildToast, ToastTypes } from '@gupy/front-commons';

import { UserAccessProfileActionTypes } from './Actions';
import UserAccessProfileService from '../../../src/services/UserAccessProfile/UserAccessProfileService';
import UserAccessProfileForm from '../../forms/UserAccessProfile/UserAccessProfileForm';

import history from '../../history';

function* getAll() {
  try {
    const userAccessProfiles = yield UserAccessProfileService.getAll();
    yield put({
      type: UserAccessProfileActionTypes.GET_ALL_SUCCESS,
      userAccessProfiles: userAccessProfiles.body.data,
    });
  } catch (error) {
    yield put({
      type: UserAccessProfileActionTypes.GET_ALL_FAIL,
    });
  }
}

function* get(action) {
  const { id } = action;
  try {
    const userAccessProfile = yield UserAccessProfileService.get(id);
    yield put({
      type: UserAccessProfileActionTypes.GET_SUCCESS,
      modelForm: userAccessProfile.body,
    });
  } catch (error) {
    yield put({
      type: UserAccessProfileActionTypes.GET_FAIL,
    });
  }
}

function* getStructure() {
  try {
    const userAccessProfile = yield UserAccessProfileService.getStructure();
    yield put({
      type: UserAccessProfileActionTypes.GET_STRUCTURE_SUCCESS,
      modelForm: userAccessProfile.body,
    });
  } catch (error) {
    yield put({
      type: UserAccessProfileActionTypes.GET_STRUCTURE_FAIL,
    });
  }
}

function* getTypes() {
  try {
    const userAccessProfile = yield UserAccessProfileService.getTypes();
    yield put({
      type: UserAccessProfileActionTypes.GET_TYPES_SUCCESS,
      types: userAccessProfile.body,
    });
  } catch (error) {
    yield put({
      type: UserAccessProfileActionTypes.GET_TYPES_FAIL,
    });
  }
}

function* post(action) {
  const { payload, messages, redirectUrl } = action;
  const form = new UserAccessProfileForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: UserAccessProfileActionTypes.POST_FAIL,
      validation,
    });
  } else {
    try {
      yield UserAccessProfileService.post(payload);

      yield put({
        type: UserAccessProfileActionTypes.POST_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield call(history.push, redirectUrl);
    } catch (error) {
      yield put({
        type: UserAccessProfileActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* update(action) {
  const { payload, messages, redirectUrl } = action;
  const form = new UserAccessProfileForm();

  const { id } = payload;
  const newPayload = { ...payload, id: undefined };

  form.populate(newPayload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: UserAccessProfileActionTypes.PUT_FAIL,
      validation,
    });
  } else {
    try {
      yield UserAccessProfileService.patch(id, newPayload);

      yield put({
        type: UserAccessProfileActionTypes.PUT_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });

      yield call(history.push, redirectUrl);
    } catch (error) {
      yield put({
        type: UserAccessProfileActionTypes.PUT_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* del(action) {
  const { id, messages } = action;

  try {
    yield UserAccessProfileService.delete(id);

    yield put({
      type: UserAccessProfileActionTypes.DELETE_SUCCESS,
      toast: buildToast(messages.success, ToastTypes.success),
    });
  } catch (error) {
    yield put({
      type: UserAccessProfileActionTypes.DELETE_FAIL,
      toast: buildToast(error.body.message, ToastTypes.error),
      error,
    });
  }
}

function* search(action) {
  const { searchString, group } = action;
  try {
    const response = yield UserAccessProfileService.getAll(searchString, group);
    yield put({
      type: UserAccessProfileActionTypes.SEARCH_SUCCESS,
      userAccessProfiles: response.body.data,
    });
  } catch (error) {
    yield put({
      type: UserAccessProfileActionTypes.SEARCH_FAIL,
      error,
    });
  }
}

function* emailTemplateSaga() {
  yield takeLatest(UserAccessProfileActionTypes.GET_ALL, getAll);
  yield takeLatest(UserAccessProfileActionTypes.GET, get);
  yield takeLatest(UserAccessProfileActionTypes.GET_TYPES, getTypes);
  yield takeLatest(UserAccessProfileActionTypes.GET_STRUCTURE, getStructure);
  yield takeLatest(UserAccessProfileActionTypes.POST, post);
  yield takeLatest(UserAccessProfileActionTypes.POST_SUCCESS, getAll);
  yield takeLatest(UserAccessProfileActionTypes.PUT, update);
  yield takeLatest(UserAccessProfileActionTypes.PUT_SUCCESS, getAll);
  yield takeLatest(UserAccessProfileActionTypes.DELETE, del);
  yield takeLatest(UserAccessProfileActionTypes.DELETE_SUCCESS, getAll);
  yield takeLatest(UserAccessProfileActionTypes.SEARCH, search);
}

export default emailTemplateSaga;
