import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import GupyThemeProvider from '@gupy/design-system/themes/GupyThemeProvider';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';

import { useFlag, useUnleashContext } from '@unleash/proxy-client-react';
import chmln from '@chamaeleonidae/chmln';

import { UserTypeEnum } from '@gupy/enums';
import Routes from '../Routes';
import { getRecruitmentAndSelectionPlan } from './helpers/getCompanyPlan';

import {
  closeConfirmDialog as closeConfirmDialogAction,
  updateGlobalization as updateGlobalizationAction,
} from '../../actions/App/AppActions';
import AppToast from './components/AppToast';
import DefaultAppConfirmDialog from './components/AppConfirmDialog';
import featuresFlagsEnum from '../Authentication/FeaturesFlagsEnum';
import permissionsEnum from '../Authentication/PermissionsEnum';

const propTypes = {
  AppReducer: PropTypes.object.isRequired,
  Authentication: PropTypes.object.isRequired,
  closeConfirmDialog: PropTypes.func.isRequired,
  updateGlobalization: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  currentCompanyId: PropTypes.number,
};

const App = ({
  AppReducer,
  Authentication,
  closeConfirmDialog,
  updateGlobalization,
  currentUserId,
  currentCompanyId,
}) => {
  const { isConfirmDialogOpened, confirmDialogOptions } = AppReducer;
  const { currentUser } = Authentication;
  const { featureFlags } = currentUser || {};
  const { company, products } = currentUser || {};
  const companySize = company && company.size ? company.size : '';
  const companyPlan = (products && products.length > 0)
    ? getRecruitmentAndSelectionPlan(products)
    : null;

  const updateContext = useUnleashContext();
  const hasGuidedOnboardingFeatureFlag = useFlag(featuresFlagsEnum.guidedOnboardingChameleon);

  useEffect(() => {
    if (!currentUser) {
      return;
    }

    if (!hasGuidedOnboardingFeatureFlag) {
      return;
    }

    const hasPermission = permission => currentUser.permissions
      && currentUser.permissions[permission] === true;
    const userHasPublishPermissions = hasPermission(permissionsEnum.move_job_approved_to_published)
      || hasPermission(permissionsEnum.move_job_disapproved_to_published)
      || hasPermission(permissionsEnum.move_job_draft_to_published)
      || hasPermission(permissionsEnum.move_job_frozen_to_published)
      || hasPermission(permissionsEnum.move_job_waiting_approval_to_published);

    const isUserOwner = currentUser.userType === UserTypeEnum.owner;

    if (!userHasPublishPermissions && !isUserOwner) {
      return;
    }

    chmln.identify(currentUser.id, {
      uid_hash: currentUser.chameleonHash,
      email: currentUser.email,
      name: currentUser.name,
      role: currentUser.userType,
      permissions: Object.keys(currentUser.permissions),
      company: {
        uid: currentUser.companyId,
        name: currentUser.company.name,
        featureFlags: currentUser.featureFlags,
        countryOfOrigin: currentUser.company.countryOfOrigin,
        language: currentUser.company.language,
        size: currentUser.company.size,
      },
    });
  }, [currentUser]);

  useEffect(() => {
    updateGlobalization(AppReducer.defaultLanguage);
  }, []);

  useEffect(() => {
    updateContext({
      companyId: currentCompanyId,
      userId: currentUserId,
      companySize,
      ...(companyPlan && { companyPlan }),
    });
  }, [currentUserId, currentCompanyId, companySize, companyPlan]);

  useEffect(() => {
    if (currentUserId) {
      GoogleAnalyticsHelper.setUserId(currentUserId.toString());
    }
  }, [currentUserId]);

  const AppConfirmDialog = () => isConfirmDialogOpened && (
    <DefaultAppConfirmDialog
      isOpen={isConfirmDialogOpened}
      onCancelClick={closeConfirmDialog}
      onConfirmClick={closeConfirmDialog}
      options={confirmDialogOptions}
    />
  );

  return (
    <GupyThemeProvider>
      <Routes featureFlags={featureFlags || []} />
      <AppConfirmDialog />
      <AppToast />
    </GupyThemeProvider>
  );
};

App.propTypes = propTypes;

const mapStateToProps = (state) => {
  const { company } = state.reducers.Signin;
  const { currentUser } = state.reducers.Authentication;
  const currentUserId = currentUser && currentUser.id ? currentUser.id : null;

  return {
    AppReducer: state.reducers.App,
    Authentication: state.reducers.Authentication,
    currentUserId,
    currentCompanyId: company.id,
  };
};

export default withRouter(connect(
  mapStateToProps,
  {
    updateGlobalization: updateGlobalizationAction,
    closeConfirmDialog: closeConfirmDialogAction,
  },
)(App));
