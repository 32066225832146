import React from 'react';
import PropTypes from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';
import { connect } from 'react-redux';
import { compose } from 'redux';

const propTypes = {
  WrappedComponent: PropTypes.node.isRequired,
};

const WithKeycloak = WrappedComponent => (props) => {
  const { useSsoLogin } = props;

  if (useSsoLogin) {
    // TODO: FRONT-75 - Regra de hook quebrada
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const keycloakCredentials = useKeycloak();

    return (
      <WrappedComponent {...props} keycloakCredentials={keycloakCredentials} />
    );
  }

  return (
    <WrappedComponent {...props} />
  );
};

WithKeycloak.propTypes = propTypes;

const mapStateToProps = ({ reducers }) => ({
  useSsoLogin: reducers.Authentication && reducers.Authentication.useSsoLogin,
});

export default compose(
  connect(mapStateToProps, {}),
  WithKeycloak,
);
