export const GET_INSIGHTS_REQUEST = 'jobWeekInsights/GET_INSIGHTS_REQUEST';
export const GET_INSIGHTS_SUCCESS = 'jobWeekInsights/GET_INSIGHTS_SUCCESS';
export const GET_INSIGHTS_FAIL = 'jobWeekInsights/GET_INSIGHTS_FAIL';
export const GET_INSIGHTS_CLOSED_MODALS_REQUEST = 'jobWeekInsights/GET_CLOSED_MODALS_REQUEST';
export const GET_INSIGHTS_CLOSED_MODALS_SUCCESS = 'jobWeekInsights/GET_CLOSED_MODALS_SUCCESS';
export const CLOSE_LOW_APPLICATION_INSIGHTS_REQUEST = 'jobWeekInsights/CLOSE_LOW_APPLICATION_INSIGHTS_REQUEST';
export const CLOSE_LOW_APPLICATION_INSIGHTS_SUCCESS = 'jobWeekInsights/CLOSE_LOW_APPLICATION_INSIGHTS_SUCCESS';
export const CLOSE_DESCRIPTION_ERRORS_INSIGHTS_REQUEST = 'jobWeekInsights/CLOSE_DESCRIPTION_ERRORS_INSIGHTS_REQUEST';
export const CLOSE_DESCRIPTION_ERRORS_INSIGHTS_SUCCESS = 'jobWeekInsights/CLOSE_DESCRIPTION_ERRORS_INSIGHTS_SUCCESS';

export const GET_JOBS_WITH_QUALIFIED_APPLICATIONS_REQUEST = 'jobWeekInsights/GET_JOBS_WITH_QUALIFIED_APPLICATIONS_REQUEST';
export const GET_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS = 'jobWeekInsights/GET_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS';
export const GET_JOBS_WITH_QUALIFIED_APPLICATIONS_FAIL = 'jobWeekInsights/GET_JOBS_WITH_QUALIFIED_APPLICATIONS_FAIL';

export const CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_REQUEST = 'jobWeekInsights/CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_REQUEST';
export const CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS = 'jobWeekInsights/CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_SUCCESS';
export const CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_FAIL = 'jobWeekInsights/CLOSE_JOBS_WITH_QUALIFIED_APPLICATIONS_FAIL';

export const GET_JOBS_CLOSE_TO_EXPIRE_REQUEST = 'jobWeekInsights/GET_JOBS_CLOSE_TO_EXPIRE_REQUEST';
export const GET_JOBS_CLOSE_TO_EXPIRE_SUCCESS = 'jobWeekInsights/GET_JOBS_CLOSE_TO_EXPIRE_SUCCESS';
export const GET_JOBS_CLOSE_TO_EXPIRE_FAIL = 'jobWeekInsights/GET_JOBS_CLOSE_TO_EXPIRE_FAIL';

export const CLOSE_JOBS_CLOSE_TO_EXPIRE_REQUEST = 'jobWeekInsights/CLOSE_JOBS_CLOSE_TO_EXPIRE_REQUEST';
export const CLOSE_JOBS_CLOSE_TO_EXPIRE_SUCCESS = 'jobWeekInsights/CLOSE_JOBS_CLOSE_TO_EXPIRE_SUCCESS';
export const CLOSE_JOBS_CLOSE_TO_EXPIRE_FAIL = 'jobWeekInsights/CLOSE_JOBS_CLOSE_TO_EXPIRE_FAIL';
