import React, { useEffect } from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { Card, Grid, InputGroup, IconButton, IconEnum, Button } from '@gupy/design-system';
import { MainSelectList as SelectList } from '@gupy/front-commons';

import { getMessages } from '../../Messages';

import './CustomIdCard.scss';

const CustomIdCard = ({
  intl,
  keyId,
  customId,
  displayErrors,
  selectedCareerPages,
  jobBoardId,
  handleSetJobBoardCustomIdCareerPages,
  handleSetJobBoardCustomIdOnChange,
  setCustomIdRemoveModal,
  publicCareerPages,
  jobBoardsCustomIds,
}) => {
  const messages = getMessages(intl);

  const [optionsDisabled, setOptionsDisabled] = React.useState([]);
  const [showRemoveButton, setShowRemoveButton] = React.useState(null);

  const getAdaptedPublicCareerPages = publicCareerPagesParam => publicCareerPagesParam
    .filter(({ id }) => !optionsDisabled.includes(id.toString()))
    .map(({ name, id }) => ({
      label: name,
      value: id.toString(),
    }));

  const getOptionsDisabled = (jobBoardsCustomIdsParam) => {
    const { customIds } = jobBoardsCustomIdsParam
      .find(jobBoardCustomId => jobBoardCustomId.jobBoardId === jobBoardId);
    return customIds
      .filter(c => c.keyId !== keyId)
      .reduce((acc, { careerPageIds }) => acc.concat(careerPageIds), []);
  };

  const getShowRemoveButton = (jobBoardsCustomIdsParam) => {
    const { customIds } = jobBoardsCustomIdsParam
      .find(jobBoardCustomId => jobBoardCustomId.jobBoardId === jobBoardId);
    return customIds.length > 1;
  };

  const getManyItemsSelectedLabel = (
    publicCareerPagesParam,
    selectedCareerPagesParam,
  ) => publicCareerPagesParam
    .filter(({ id }) => selectedCareerPagesParam.includes(id.toString()))
    .map(({ name }) => name)
    .join(', ');

  const getAllItemsSelectedLabel = (
    publicCareerPagesParam,
    selectedCareerPagesParam,
  ) => (
    publicCareerPagesParam.length === selectedCareerPagesParam.length
      ? messages.jobBoardsCustomIdsFormCareerPagesAllItemsSelectedLabel
      : getManyItemsSelectedLabel(publicCareerPagesParam, selectedCareerPagesParam)
  );

  const getCustomIdInputError = () => (displayErrors && !customId ? {
    type: 'error',
    message: messages.jobBoardsCustomIdsFormLinkedinCustomIdEmptyError,
  } : {});

  const getCustomIdDropdownError = () => (displayErrors && !selectedCareerPages.length ? {
    type: 'error',
    message: messages.jobBoardsCustomIdsFormCareerPagesEmptyError,
  } : {});

  useEffect(() => {
    setOptionsDisabled(getOptionsDisabled(jobBoardsCustomIds));
    setShowRemoveButton(getShowRemoveButton(jobBoardsCustomIds));
  }, [jobBoardsCustomIds]);

  return (
    <Card className="custom-id-card">
      <Grid className="custom-id-card__container" container spacing={16}>
        <Grid className="custom-id-card__id-column" item xs={12} lg md>
          <InputGroup
            id={`custom-id-${keyId}`}
            name={`custom-id-${keyId}`}
            className="custom-id-card__id-field"
            block
            required
            label={messages.jobBoardsCustomIdsFormLinkedinCustomIdLabel}
            placeholder={messages.jobBoardsCustomIdsFormLinkedinCustomIdPlaceholder}
            value={customId}
            onChange={e => handleSetJobBoardCustomIdOnChange(jobBoardId, keyId, e.target.value)}
            validation={getCustomIdInputError()}
            tipMessage={(
              <span>
                {messages.jobBoardsCustomIdsFormLinkedinCustomIdTipToSearch}.{' '}
                <a
                  className="custom-id-card__how-to-find-custom-id-hyperlink"
                  href="https://www.linkedin.com/help/linkedin/answer/a415420/associate-your-linkedin-company-id-with-the-linkedin-job-board-faqs?lang=pt"
                  target="_blank"
                >
                  {messages.jobBoardsCustomIdsFormLinkedinCustomIdTipToSearchHrefText}
                </a>.
              </span>
            )}
            tipType="normal"
          />
        </Grid>
        <Grid className="custom-id-card__career-pages-column" item xs={12} lg md>
          <SelectList
            id={`career-pages-${keyId}`}
            name={`career-pages-${keyId}`}
            className="custom-id-card__career-pages-field"
            label={messages.jobBoardsCustomIdsFormCareerPagesLabel}
            noItemsSelectedLabel={messages.jobBoardsCustomIdsFormCareerPagesPlaceholder}
            selectAllLabel={messages.jobBoardsCustomIdsFormCareerPagesSelectAllLabel}
            manyItemsSelectedLabel={
              getManyItemsSelectedLabel(publicCareerPages, selectedCareerPages)
            }
            allItemsSelectedLabel={
              getAllItemsSelectedLabel(publicCareerPages, selectedCareerPages)
            }
            onChange={e => handleSetJobBoardCustomIdCareerPages(jobBoardId, keyId, e.target.value)}
            value={selectedCareerPages}
            data={getAdaptedPublicCareerPages(publicCareerPages)}
            validation={getCustomIdDropdownError()}
            multiSelect
            responsive
          />
        </Grid>
        {showRemoveButton && (
          <Grid className="custom-id-card__remove-btn-column" item xs={12} lg="auto" md="auto">
            <IconButton
              className={classNames('custom-id-card__remove-btn-desktop', 'btn')}
              icon={IconEnum.Delete}
              variant="text"
              onClick={() => setCustomIdRemoveModal({
                isOpen: true,
                jobBoardId,
                keyId,
              })}
            />
            <Button
              className={classNames('custom-id-card__remove-btn-mobile', 'btn')}
              type="text"
              size="lg"
              onClick={() => setCustomIdRemoveModal({
                isOpen: true,
                jobBoardId,
                keyId,
              })}
              icon={IconEnum.Delete}
            >
              {messages.jobBoardsCustomIdsFormRemoveBtn}
            </Button>
          </Grid>
        )}
      </Grid>
    </Card>
  );
};

CustomIdCard.propTypes = {
  intl: PropTypes.object.isRequired,
  keyId: PropTypes.string.isRequired,
  customId: PropTypes.string.isRequired,
  displayErrors: PropTypes.bool.isRequired,
  selectedCareerPages: PropTypes.arrayOf(PropTypes.string),
  jobBoardId: PropTypes.number.isRequired,
  handleSetJobBoardCustomIdCareerPages: PropTypes.func.isRequired,
  handleSetJobBoardCustomIdOnChange: PropTypes.func.isRequired,
  setCustomIdRemoveModal: PropTypes.func.isRequired,
  publicCareerPages: PropTypes.array.isRequired,
  jobBoardsCustomIds: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  publicCareerPages: state.reducers.Integrations.publicCareerPages,
  jobBoardsCustomIds: state.reducers.Integrations.jobBoardsCustomIds,
});

export default injectIntl(connect(
  mapStateToProps,
)(CustomIdCard));
