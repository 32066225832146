import { BaseForm } from '@gupy/front-commons';

class JobPublicationTypeForm extends BaseForm {
  constructor() {
    super({
      publicationType: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }

  customValidateModel(registerStepId, exceededQuestions) {
    let validation = this.validateModel();
    if (exceededQuestions) {
      validation = {
        ...validation,
        alert: {
          type: 'error',
          message: 'max_additional_questions',
          link: `/companies/jobs/edit/${this.fields.id.value}/steps/register/${registerStepId}?error=questions`,
        },
        error: validation && validation.error ? validation.error : {},
      };
    }

    return validation;
  }
}

export default JobPublicationTypeForm;
