import api from '../../api/DarthVaderClient';

const usersPath = '/companies/users';

export default {
  delete(id) {
    return api.delete(`${usersPath}/${id}`);
  },

  getAll(filter, page, perPage) {
    const filterQueryString = filter ? Object
      .keys(filter)
      .filter(key => filter[key])
      .map(key => `${key}=${filter[key]}&`)
      .join('') : '';
    return api.get(`${usersPath}?${filterQueryString}order=name&page=${page}&perPage=${perPage}`);
  },

  sendRegisterInvite(userIds) {
    return api.post('/company-management/companies/users/send-invite', { userIds });
  },

  sendProfileTestInvite(userIds) {
    return api.post('/profile-test/companies/profiles/tests/send-invite', { userIds });
  },

  getCompanyBranches(search) {
    return api.get(`/company-management/companies/branches?search=${search}`);
  },

  getOnboardingGaiaStatus(userId) {
    return api.get(`/selection-process/user/${userId}/gaia-onboarding`);
  },

  changeOnboardingGaiaStatus(userId, status) {
    return api.patch(`/selection-process/user/${userId}/gaia-onboarding`, {
      statusGaiaOnboarding: status,
    });
  },
};
