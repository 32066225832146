import { BaseForm } from '@gupy/front-commons';

class SetPasswordForm extends BaseForm {
  constructor(props = {}) {
    super({
      newPassword: {
        validate: {
          mustHaveAtLeast1Letter: props.newPassword,
          mustHaveAtLeast1SpecialCharacter: props.newPassword,
          mustHaveAtLeast1Number: props.newPassword,
          notEmpty: true,
          minLength: 8,
        },
      },
      newPasswordConfirmation: {
        validate: {
          mustHaveAtLeast1Letter: props.newPasswordConfirmation,
          mustHaveAtLeast1SpecialCharacter: props.newPasswordConfirmation,
          mustHaveAtLeast1Number: props.newPasswordConfirmation,
          notEmpty: true,
          minLength: 8,
        },
      },
      token: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default SetPasswordForm;
