const DateFormats = {
  brazilianDefault: 'DD/MM/YYYY',
  brazilianTimeDefault: 'HH:mm',
  brazilianDateAndTime: 'DD/MM/YYYY HH:mm',
  dayOfWeekExtense: 'dddd',
  dayOfMonth: 'DD',
  fullYear: 'YYYY',
  hour: 'HH',
  minutes: 'mm',
  monthExtense: 'MMMM',
  isoDate: 'YYYY-MM-DD',
};

export default DateFormats;
