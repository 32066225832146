export const GET_APP_REQUEST = 'marketplaceApps/GET_APP_REQUEST';
export const GET_APP_SUCCESS = 'marketplaceApps/GET_APP_SUCCESS';
export const GET_APP_FAIL = 'marketplaceApps/GET_APP_FAIL';

export const GET_APP_LIST_REQUEST = 'marketplaceApps/GET_APP_LIST_REQUEST';
export const GET_APP_LIST_SUCCESS = 'marketplaceApps/GET_APP_LIST_SUCCESS';
export const GET_APP_LIST_FAIL = 'marketplaceApps/GET_APP_LIST_FAIL';

export const PUT_APP_OPTIONS_REQUEST = 'marketplaceApps/PUT_APP_OPTIONS_REQUEST';
export const PUT_APP_OPTIONS_SUCCESS = 'marketplaceApps/PUT_APP_OPTIONS_SUCCESS';
export const PUT_APP_OPTIONS_FAIL = 'marketplaceApps/PUT_APP_OPTIONS_FAIL';
