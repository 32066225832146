import { useMutation } from '@tanstack/react-query';
import IntegrationsService from '../../../services/Integrations/IntegrationsService';
import { IntegrationsQueryKeyEnum } from './IntegrationsQueryKeyEnum';

export const useSaveChildAppDeleteEmail = ({ onSuccess, onError }) => {
  const { mutate } = useMutation({
    mutationFn: ({ childAppId, token }) => IntegrationsService
      .saveRscChildAppWithDeleteEmail(childAppId, token),
    mutationKey: [IntegrationsQueryKeyEnum.saveRscChildAppWithDeleteEmail],
    onSuccess: () => {
      if (onSuccess) {
        onSuccess();
      }
    },
    onError: () => {
      if (onError) {
        onError();
      }
    },
  });

  return { saveChildAppDeleteEmail: mutate };
};
