import { Dialog, Tabs } from '@gupy/design-system';
import { Button } from '@gupy/design-system-v2';
import styled from 'styled-components';

const ButtonOpenModal = styled(Button)`
  padding-right: ${({ paddingRight }) => paddingRight};
  padding-left: ${({ paddingLeft }) => paddingLeft};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ fontColor }) => fontColor};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ margin }) => margin};

  :hover{
    color: ${({ fontColorHover }) => fontColorHover};
  }

  @media screen and (max-width: 1024px) {
   display: ${({ displayMobile }) => displayMobile};
  }

`;

const Modal = styled(Dialog)`

.gupy-dialog-lg {
  border-radius: ${({ borderRadiusModal }) => borderRadiusModal};
}

#modal-discovery-products-header{
  background-color:  ${({ backgroundColorHeader }) => backgroundColorHeader};

  h3{
    color: ${({ colorTitleHeader }) => colorTitleHeader};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: ${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    line-height: ${({ lineHeight }) => lineHeight};
    text-align: ${({ textAlign }) => textAlign};
  }
}

#modal-discovery-products-close-button{
  background-color:  ${({ backgroundColorBtnClose }) => backgroundColorBtnClose};
}

@media screen and (max-width: 1024px) {
  display: ${({ displayMobile }) => displayMobile}
};
`;

const ProductsList = styled.ul`
  padding: ${({ padding }) => padding};
  display: ${({ display }) => display};
  margin: ${({ margin }) => margin};
  align-items: ${({ alignItems }) => alignItems};
  border-bottom: ${({ borderBottom }) => borderBottom};
`;

const GupyProduct = styled.li`

  display: ${({ display }) => display};
  width: ${({ width }) => width};
  padding:${({ padding }) => padding};

  @media screen and (max-width: 1024px) {
    display: ${({ displayMobile }) => displayMobile};
    width: ${({ widthMobile }) => widthMobile};
    padding: ${({ paddingMobile }) => paddingMobile};
    flex-direction: ${({ flexDirectionMobile }) => flexDirectionMobile};
    align-items: ${({ alignItemsMobile }) => alignItemsMobile};
  }

  ul{
    overflow: ${({ overflow }) => overflow};
    border:${({ border }) => border};

  >li {
    background-color: ${({ backgroundColorTabs }) => backgroundColorTabs};
    border:${({ borderTabs }) => borderTabs};
    height: ${({ heightTabs }) => heightTabs};
    display: ${({ displayTabs }) => displayTabs};
    padding:${({ paddingTabs }) => paddingTabs};
    justify-content: ${({ justifyContentTabs }) => justifyContentTabs};

    :focus{
    outline: ${({ outlineFocus }) => outlineFocus};
    outline-width:${({ outlineWidthFocus }) => outlineWidthFocus};
    border:${({ borderTabsfocus }) => borderTabsfocus};
    color:${({ colorFocus }) => colorFocus};
    box-shadow:  ${({ boxShadowFocus }) => boxShadowFocus};
    border-radius:${({ borderRadiusFocus }) => borderRadiusFocus};
    }

    div {
      width:${({ widthDiv }) => widthDiv};
      padding: ${({ paddingDiv }) => paddingDiv};
      text-align:${({ textAlign }) => textAlign};
      font-family: ${({ fontFamily }) => fontFamily};
      font-size: ${({ fontSize }) => fontSize};
      font-weight: ${({ fontWeight }) => fontWeight};
      line-height: ${({ lineHeight }) => lineHeight};
      border: ${({ borderDiv }) => borderDiv};
      color: ${({ color }) => color};
      outline: ${({ outline }) => outline};

      div{
        padding:${({ paddingTitle }) => paddingTitle};
        margin:${({ marginTitle }) => marginTitle};
      }

    }

   }
  }
`;

const ProductTitleTab = styled(Tabs)`


`;
const ModalContent = styled.div`

  display: ${({ display }) => display};
  padding: ${({ padding }) => padding};
  align-items: ${({ alignItems }) => alignItems};
  gap: ${({ gap }) => gap};
  padding-left: ${({ paddingLeft }) => paddingLeft};

  @media screen and (max-width: 1024px) {
    display: ${({ displayMobile }) => displayMobile};
    flex-direction: ${({ flexDirectionMobile }) => flexDirectionMobile};
    align-items: ${({ alignItemsMobile }) => alignItemsMobile};
  }

  .text-content{
    padding-left: ${({ paddingLeftText }) => paddingLeftText};

    @media screen and (max-width: 1024px) {
      padding-left: ${({ paddingLeftTextMobile }) => paddingLeftTextMobile};
      width: ${({ widthTextMobile }) => widthTextMobile};    }

    #title{
      padding: ${({ paddingTitle }) => paddingTitle};
    }
  }

  .sub-title{
  padding-left: ${({ paddingLeftSub }) => paddingLeftSub};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  text-align: ${({ textAlign }) => textAlign};
  color: ${({ color }) => color};
  }
`;

const ModalDiv = styled.div`
display: ${({ display }) => display};
flex-direction: ${({ flexDirection }) => flexDirection};
gap: ${({ gap }) => gap};
`;

const ModalContentList = styled.ul`
display: ${({ display }) => display};
flex-direction: ${({ flexDirection }) => flexDirection};
  list-style: ${({ listStyle }) => listStyle};
  padding-top:${({ paddingTop }) => paddingTop};
  padding-left:${({ paddingLeft }) => paddingLeft};
  margin: ${({ margin }) => margin};

.conteudo-modal{
  display: ${({ displayModal }) => displayModal};
  flex-direction: ${({ flexDirectionModal }) => flexDirectionModal};
  align-items: ${({ alignItemsModal }) => alignItemsModal};
  padding: ${({ paddingModal }) => paddingModal};

  > p {
    padding-left: ${({ paddingLeftDescription }) => paddingLeftDescription};
    display: ${({ displayDescription }) => displayDescription};
    align-items: ${({ alignItems }) => alignItems};
    padding: ${({ padding }) => padding};
    margin: ${({ marginDescription }) => marginDescription};
    letter-spacing: ${({ letterSpacing }) => letterSpacing};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size:${({ fontSize }) => fontSize};
    line-height: ${({ lineHeight }) => lineHeight};
    text-align: ${({ textAlign }) => textAlign};
  }
}
`;

const FooterModalContent = styled.div`
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection};
  align-items: ${({ alignItems }) => alignItems};
  justify-content: ${({ justifyContent }) => justifyContent};
  padding:${({ padding }) => padding};

  @media screen and (max-width: 1024px) {
    width: ${({ widthMobile }) => widthMobile};   }

  img {
    padding: ${({ paddingImg }) => paddingImg};

    @media screen and (max-width: 1024px) {
      display: ${({ displayMobileImg }) => displayMobileImg};
     }
  }

  > a{
    margin:${({ margin }) => margin};
    font-family: ${({ fontFamily }) => fontFamily};
    font-size:${({ fontSize }) => fontSize};
    font-weight: ${({ fontWeight }) => fontWeight};
    text-align: ${({ textAlign }) => textAlign};
    background-color: ${({ backgroundColor }) => backgroundColor};
    width:${({ width }) => width};
    padding:${({ paddingButton }) => paddingButton};
    color: ${({ color }) => color};
    border-radius: ${({ borderRadius }) => borderRadius};
      &:hover {
        background-color: ${({ backgroundColorHover }) => backgroundColorHover};
      }
  }

`;

export default {
  ButtonOpenModal,
  Modal,
  ProductsList,
  GupyProduct,
  ProductTitleTab,
  ModalContent,
  ModalDiv,
  FooterModalContent,
  ModalContentList,
};
