import React, { useEffect } from 'react';
import { Button } from '@gupy/design-system';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { colorGupyBlueSecondary, colorGupyBlue, colorTranslucentBlack1 } from '@gupy/design-system/Colors/Colors';
import { getSubdomain } from '../../helpers/subdomainHelper';
import AuthenticationLayout from '../App/components/layouts/AuthenticationLayout';
import { getMessages } from './Messages';
import { Logo } from './components/Header/Logo';

const propTypes = {
  intl: intlShape.isRequired,
  logout: PropTypes.func,
  subdomain: PropTypes.string,
  email: PropTypes.string,
};

const KeepOrChangeCompany = ({ intl, logout, subdomain, email }) => {
  useEffect(() => {
    GoogleAnalyticsHelper.sendEvent({ category: 'keep_or_change_company', action: 'loading_page' });
  }, []);

  const messages = getMessages(intl);
  const loginWithOtherEmail = () => {
    GoogleAnalyticsHelper.sendEvent({ category: 'keep_or_change_company', action: 'login_with_other_email' });
    logout();
  };

  const keepWithTheSameEmail = () => {
    GoogleAnalyticsHelper.sendEvent({ category: 'keep_or_change_company', action: 'keep_with_the_same_email' });
    window.location.href = window.location.href.replace(getSubdomain(), subdomain);
  };

  const domain = process.env.REACT_APP_COOKIE_DOMAIN;

  return (
    <AuthenticationLayout>
      <div className="companies-authentication__container">
        <Logo intl={intl} />
        <h5 className="companies-authentication__title">
          {messages.keepOrChangeCompanyTitle}
        </h5>
        <p className="companies-authentication__subtitle">
          {messages.keepOrChangeCompanySubtitle1}<strong>{email}</strong>
          {messages.keepOrChangeCompanySubtitle2}<strong>{`https://${subdomain}${domain}.`}</strong>
        </p>
        <p className="companies-authentication__subtitle">
          {messages.keepOrChangeCompanySubtitle3}<strong>{`https://${getSubdomain()}${domain}`}</strong>
          {messages.keepOrChangeCompanySubtitle4}
        </p>
        <div className="companies-authentication__buttons">
          <Button
            onClick={keepWithTheSameEmail}
            type="outlined"
            className="companies-authentication__button"
            customColor={{
              color: colorGupyBlueSecondary,
              hoverBackgroundColor: colorTranslucentBlack1,
            }}
          >
            {messages.keepOrChangeCompanyButton1}
          </Button>
          <Button
            onClick={loginWithOtherEmail}
            customColor={{
              backgroundColor: colorGupyBlueSecondary,
              hoverBackgroundColor: colorGupyBlue,
            }}
          >
            {messages.keepOrChangeCompanyButton2}
          </Button>

        </div>
      </div>
    </AuthenticationLayout>
  );
};

KeepOrChangeCompany.propTypes = propTypes;
export default injectIntl(KeepOrChangeCompany);
