import api from '../../api/DarthVaderClient';

export default {
  getConfirmIndication(token) {
    return api.get(`/companies/application/${token}/confirm-indication`);
  },
  postConfirmIndication(token, body) {
    return api.post(`/companies/application/${token}/confirm-indication`, body);
  },
  getConfirmIndicationSecure(token) {
    return api.get(`/companies/application/v2/${token}/confirm-indication`);
  },
  postConfirmIndicationSecure(token, body) {
    return api.post(`/companies/application/v2/${token}/confirm-indication`, body);
  },
};
