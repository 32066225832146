import { BaseForm } from '@gupy/front-commons';

class JobDetailsForm extends BaseForm {
  constructor() {
    super({
      name: {
        validate: {
          notEmpty: true,
        },
      },
      roleId: {
        validate: {
          notEmpty: true,
        },
      },
      jobType: {
        validate: {
          notEmpty: true,
        },
      },
      departmentId: {
        validate: {
          notEmpty: true,
        },
      },
      code: {
        validate: {
          maxLength: 255,
        },
      },
    });
  }
}

export default JobDetailsForm;
