import { combineReducers } from 'redux';
import JobWorkplaceActionTypes from '../../constants/Job/JobWorkplaceActionTypes';
import JobDetailsActionTypes from '../../constants/Job/JobDetailsActionTypes';
import AddressActionTypes from '../../constants/Address/AddressActionTypes';
import AddressStatus from '../../constants/Address/AddressStatus';

const initialModelForm = {
  id: undefined,
  addressLine: '',
  addressLatitude: null,
  addressLongitude: null,
  remoteWorking: false,
  companyBranchLabel: '',
  companyBranchId: null,
};
const emptyAddress = {
  addressCountry: '',
  addressCountryShortName: '',
  addressCity: '',
  addressStreet: '',
  addressStateShortName: '',
  addressState: '',
  addressZipCode: '',
  addressNumber: '',
  addressLongitude: null,
  addressLatitude: null,
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobWorkplaceActionTypes.POST:
  case JobWorkplaceActionTypes.PATCH:
    return true;
  case JobWorkplaceActionTypes.POST_SUCCESS:
  case JobWorkplaceActionTypes.POST_FAIL:
  case JobWorkplaceActionTypes.PATCH_SUCCESS:
  case JobWorkplaceActionTypes.PATCH_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobWorkplaceActionTypes.GET:
  case JobWorkplaceActionTypes.INIT:
    return true;
  case JobWorkplaceActionTypes.INIT_SUCCESS:
  case JobDetailsActionTypes.GET_SUCCESS:
  case JobWorkplaceActionTypes.GET_SUCCESS:
  case JobWorkplaceActionTypes.INIT_FAIL:
  case JobWorkplaceActionTypes.GET_FAIL:
    return false;
  default:
    return state;
  }
};

export const subsidiaries = (state = [], action) => {
  switch (action.type) {
  case JobWorkplaceActionTypes.INIT_SUCCESS:
    return action.subsidiaries.map(item => ({ value: item.id.toString(), label: item.name }));
  case JobWorkplaceActionTypes.INIT_FAIL:
    return [];
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case JobWorkplaceActionTypes.POST_SUCCESS:
  case JobWorkplaceActionTypes.PATCH_SUCCESS:
  case JobWorkplaceActionTypes.INIT_SUCCESS: {
    const newState = {
      id: action.modelForm.id,
      addressCountry: action.modelForm.addressCountry || '',
      companyBranchLabel: action.modelForm.companyBranchLabel || '',
      companyBranchId: action.modelForm.companyBranchId || null,
      addressState: action.modelForm.addressState || '',
      addressStateShortName: action.modelForm.addressStateShortName || '',
      addressCity: action.modelForm.addressCity || '',
      addressZipCode: action.modelForm.addressZipCode || '',
      addressLine: action.modelForm.addressLine || '',
      addressCountryShortName: action.modelForm.addressCountryShortName || '',
      addressStreet: action.modelForm.addressStreet || '',
      addressNumber: action.modelForm.addressNumber || '',
      addressLongitude: action.modelForm.addressLongitude || null,
      addressLatitude: action.modelForm.addressLatitude || null,
      addressStatus: action.modelForm.addressLine && action.modelForm.addressLine !== ''
        ? AddressStatus.complete : AddressStatus.empty,
      remoteWorking: action.modelForm.remoteWorking || false,
      workplaceType: action.modelForm.workplaceType,
    };
    return newState;
  }

  case AddressActionTypes.CHANGE_FORM_ADDRESS_BLUR: {
    if (state.addressStatus === AddressStatus.editing) {
      return {
        ...state,
        addressLine: '',
        addressStatus: AddressStatus.empty,
      };
    }

    return state;
  }
  case AddressActionTypes.CLOSE_ADDRESS_MODAL:
    return {
      ...state,
      addressLine: '',
      addressStatus: AddressStatus.empty,
    };
  case AddressActionTypes.SAVE_ADDRESS_MODAL_SUCCESS: {
    const line = [
      action.modelForm.addressStreet,
      action.modelForm.addressCity,
      action.modelForm.addressState,
      action.modelForm.addressCountry,
    ];

    return {
      ...state,
      ...action.modelForm,
      addressLine: line.join(', '),
      addressStatus: AddressStatus.complete,
    };
  }
  case JobWorkplaceActionTypes.CLEAR_FORM:
    return initialModelForm;
  case JobWorkplaceActionTypes.CHANGE_FORM: {
    let addressModel = null;

    if (action.modelForm.addressLine) {
      addressModel = {
        ...emptyAddress,
        addressStatus: AddressStatus.editing,
      };
    }

    return {
      ...state,
      ...action.modelForm,
      ...addressModel,
    };
  }
  case JobWorkplaceActionTypes.EDIT:
    return Object.assign(
      {},
      initialModelForm,
      action.modelForm,
    );
  case JobWorkplaceActionTypes.CLEAR_COMPANY_BRANCH_ON_CHANGE: {
    if (action.companyBranchId) {
      return {
        ...state,
        companyBranchId: null,
      };
    }
    return state;
  }
  case JobWorkplaceActionTypes.ON_COMPANY_BRANCH_BLUR: {
    if (!action.companyBranchId) {
      return {
        ...state,
        companyBranchLabel: '',
      };
    }
    return state;
  }
  default:
    return state;
  }
};

const companyBranchSuggestions = (state = [], action) => {
  switch (action.type) {
  case JobWorkplaceActionTypes.GET_COMPANY_BRANCHES_LEAVES_SUCCESS:
    return action.companyBranchSuggestions;
  case JobWorkplaceActionTypes.GET_COMPANY_BRANCHES_LEAVES_FAIL:
    return [];
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case JobWorkplaceActionTypes.POST:
  case JobWorkplaceActionTypes.PATCH:
  case JobWorkplaceActionTypes.INIT:
  case JobWorkplaceActionTypes.INIT_SUCCESS:
    return {};
  case JobWorkplaceActionTypes.POST_FAIL:
  case JobWorkplaceActionTypes.PATCH_FAIL:
  case JobWorkplaceActionTypes.INIT_FAIL:
    return action.error;
  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  isLoading,
  validation,
  subsidiaries,
  companyBranchSuggestions,
});
