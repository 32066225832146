import SocialMediasActionTypes from './ActionTypes';
import DropdownLabelHelper from '../../helpers/DropdownLabelHelper';

export const changeForm = modelForm => ({
  type: SocialMediasActionTypes.CHANGE_FORM,
  modelForm,
});

export const setSelected = modelForm => ({
  type: SocialMediasActionTypes.SET_SELECTED,
  modelForm,
});

export const getAllSocialMedias = () => ({
  type: SocialMediasActionTypes.GET_ALL_REQUEST,
});

export const getAllCareerPagesExpanded = () => ({
  type: SocialMediasActionTypes.GET_CAREER_PAGES_EXPANDED_REQUEST,
});

export const getAllSocialMediasSuccess = (socialMediaList, selectedId) => {
  let selected = socialMediaList[0];
  if (selectedId) {
    selected = socialMediaList.find(socialMedia => socialMedia.id === selectedId);
  }

  return {
    type: SocialMediasActionTypes.GET_ALL_SUCCESS,
    modelForm: selected,
    socialMediaList: socialMediaList.map(DropdownLabelHelper.getCareerPageModelWithValueAndLabel),
  };
};

export const patchCareerPageSocialMedia = (payload, messages) => ({
  type: SocialMediasActionTypes.PATCH_CAREER_PAGE_SOCIAL_MEDIA_REQUEST,
  payload,
  messages,
});

export const putCareerPageSocialMedia = (payload, messages) => ({
  type: SocialMediasActionTypes.PUT_CAREER_PAGE_SOCIAL_MEDIA_REQUEST,
  payload,
  messages,
});
