import api from '../../api/DarthVaderClient';

export default {
  getSuggestions(text) {
    return api.get(`/companies/addresses?input=${text}&inputType=address`);
  },
  getDetails(placeId) {
    return api.get(`/companies/addresses/details?placeId=${placeId}`);
  },
  getCountries() {
    return api.get('/address-service/public/country');
  },
  getCities(text) {
    return api.get(`/companies/addresses?input=${text}&inputType=(cities)`);
  },
};
