import React, { Fragment, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ContainerErrorBoundary } from '@gupy/front-commons';
import { useKeycloak } from '@react-keycloak/web';

import { Spinner } from '@gupy/design-system';
import Error403 from '../Error/403';
import featuresFlagsEnum from './FeaturesFlagsEnum';
import {
  exchangeToken as exchangeTokenAction,
  postIDPSignout as postIDPSignoutAction,
  setSsoLogin as setSsoLoginAction,
  signinWithCookie as signinWithCookieAction,
} from './Actions';

import './PrivateRoute.scss';
import { useIDPAuthentication } from './useIDPAuthentication';

import KeepOrChangeCompany from './KeepOrChangeCompany';

export const isAdmissionOnlyAllowedPath = (path = '') => {
  const pathWithoutParam = path.split('?')[0];
  const fixedPath = /[/#]$/.test(pathWithoutParam) ? pathWithoutParam.slice(0, -1) : pathWithoutParam;

  const admissionOnlyAllowedPaths = [
    '/companies/auth',
    '/companies/auth/callback',
    '/companies/forgot-password',
    '/admission/companies',
    '/companies/community',
    '/companies/signout',
    '/companies/signin',
    '/companies/profile',
    '/companies/setup',
    '/companies/setup/profile',
    '/companies/setup/roles',
    '/companies/setup/departments',
    '/companies/setup/subsidiaries',
    '/companies/setup/user-access-profile',
    '/companies/setup/user-access-profile/new',
    '/companies/setup/users',
    '/companies/setup/admission/documents',
    '/companies/setup/admission/custom-fields',
    '/companies/setup/admission/templates',
    '/companies/setup/admission/templates/new',
    '/companies/setup/email-template',
    '/companies/setup/email-template/new',
    '/companies/setup/admission/workflow',
  ];

  const admissionOnlyAllowedPathsRegex = [
    /\/companies\/define-password\/[\w-]+/,
    /\/companies\/setup\/admission\/documents\/[\w-]+/,
    /\/companies\/setup\/admission\/templates\/[\w-]+/,
    /\/companies\/setup\/admission\/integrations\/[\w-]+/,
    /\/companies\/setup\/admission\/workflow\/edit\/[\w-]+/,
    /\/companies\/setup\/user-access-profile\/[\w-]+/,
    /\/companies\/setup\/email-template\/[\w-]+/,
    /\/admission\/companies\/profile\/[\w-]+/,
    /\/admission\/companies\/profile\/[\w-]+\/[\w-]+\/document\/[\w-]+/,
  ];

  return admissionOnlyAllowedPaths.includes(fixedPath)
    || admissionOnlyAllowedPathsRegex.some(regex => regex.test(fixedPath));
};

const propTypes = {
  Authentication: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]).isRequired,
  company: PropTypes.object.isRequired,
  exchangeToken: PropTypes.func.isRequired,
  IDPSignout: PropTypes.func.isRequired,
  layout: PropTypes.any,
  renderPermissions: PropTypes.array,
  setSsoLogin: PropTypes.func.isRequired,
  signinWithCookie: PropTypes.func.isRequired,
  SignOut: PropTypes.object.isRequired,
};

const defaultProps = {
  renderPermissions: [],
  layout: Fragment,
};

const IDPPrivateRoute = ({
  Authentication,
  company,
  component: Component,
  exchangeToken,
  IDPSignout,
  layout: Layout,
  renderPermissions,
  setSsoLogin,
  signinWithCookie,
  signinWithCookieResult,
  SignOut,
  ...rest
}) => {
  const { currentUser, exchangedToken, isAuthenticated, keepOrChangeCompany } = Authentication;
  const { permissions, featureFlags = [] } = currentUser || {};
  const { isSignOut } = SignOut;

  useLayoutEffect(() => {
    const pathname = rest.history.location.pathname || rest.path;

    if (
      featureFlags.includes(featuresFlagsEnum.admissionOnly)
      && !isAdmissionOnlyAllowedPath(pathname)
    ) {
      window.location.replace('/admission/companies');
    }
  }, []);

  let canRender = true;
  if (permissions && renderPermissions.length) {
    canRender = renderPermissions.reduce((can, renderPermission) => (
      can || permissions[renderPermission]
    ), false);
  }

  const { initialized, keycloak } = useKeycloak();

  useIDPAuthentication({
    companyId: company.id,
    exchangeToken,
    IDPSignout,
    isSignOut,
  });

  useEffect(() => {
    if (exchangedToken) {
      signinWithCookie();
    }
  }, [exchangedToken]);

  useEffect(() => {
    setSsoLogin(true);
  }, []);

  const shouldDisplaySpinner = !initialized
    || !keycloak.authenticated
    || !isAuthenticated;

  if (keepOrChangeCompany.should) {
    return (
      <KeepOrChangeCompany
        logout={() => keycloak.logout()}
        subdomain={keepOrChangeCompany.subdomain}
        email={keepOrChangeCompany.email}
      />
    );
  }

  if (shouldDisplaySpinner) {
    return (
      <div className="companies-routes-spinner">
        <Spinner size={60}/>
      </div>
    );
  }

  return (
    <Route
      {...rest}
      render={props => (
        <React.Fragment>
          {
            canRender ? (
              <Layout permissions={permissions}>
                <ContainerErrorBoundary>
                  <Component
                    {...props}
                    permissions={permissions}
                    featureFlags={featureFlags || []}
                  />
                </ContainerErrorBoundary>
              </Layout>
            ) : (
              <Error403 />
            )
          }
        </React.Fragment>
      )}
    />
  );
};

IDPPrivateRoute.propTypes = propTypes;
IDPPrivateRoute.defaultProps = defaultProps;

const mapStateToProps = state => ({
  Authentication: state.reducers.Authentication,
  signinWithCookieResult: state.reducers.Signin.signinWithCookieResult,
  company: state.reducers.Signin.company,
  SignOut: state.reducers.SignOut,
});

export default withRouter(connect(
  mapStateToProps,
  {
    exchangeToken: exchangeTokenAction,
    IDPSignout: postIDPSignoutAction,
    setSsoLogin: setSsoLoginAction,
    signinWithCookie: signinWithCookieAction,
  },
)(IDPPrivateRoute));
