import Keycloak from 'keycloak-js';

const keycloakConfig = {
  clientId: 'gupy-front',
  realm: 'gupy',
  url: process.env.REACT_APP_KEYCLOAK_AUTH,
};

export const keycloakInitOptions = {
  onLoad: 'check-sso',
  silentCheckSsoRedirectUri: `${location.origin}/companies/silent-check-sso.html`,
  enableLogging: true,
};

export const keycloak = new Keycloak(keycloakConfig);
