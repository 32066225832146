import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';

import UserNotifications from './components/UserNotifications/UserNotifications';

import { limitNotifications } from './helpers/adapters';

import {
  viewNotification as viewNotificationDispatch,
  removeNotification as removeNotificationDispatch,
  getAllNotifications as getAllNotificationsDispatch,
} from './NotificationsActionsV2';

import NotificationTypes from './enums/NotificationTypes';
import { getNotificationsMessages } from './Messages';

const propTypes = {
  authentication: PropTypes.object,
  notifications: PropTypes.object,
  viewNotification: PropTypes.func.isRequired,
  removeNotification: PropTypes.func.isRequired,
  getAllNotifications: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  isContainerExpanded: PropTypes.bool,
  classes: PropTypes.string,
};

const UserNotificationsContainer = ({
  authentication,
  notifications,
  viewNotification,
  removeNotification,
  getAllNotifications,
  intl,
  isContainerExpanded,
  classes,
}) => {
  useEffect(() => {
    const companyId = authentication.currentUser.company.id;
    getAllNotifications(`user-from-company-${companyId}`);
  }, []);

  const featureFlags = authentication.currentUser && authentication.currentUser.featureFlags;
  const isAdmissionOnly = featureFlags && featureFlags.includes('admission_only');
  const messages = isAdmissionOnly ? {} : getNotificationsMessages(intl);

  const handleViewNotification = useCallback((notification) => {
    viewNotification(notification);
  }, []);

  const handleRemoveNotification = useCallback((id, type) => {
    removeNotification(id, type);
  }, []);

  const userNotifications = notifications
    ? notifications.notifications[NotificationTypes.default]
    : [];
  const limitedNotifications = limitNotifications(userNotifications);

  const isShowNotifications = authentication.isAuthenticated && !isAdmissionOnly;

  return isShowNotifications ? (
    <UserNotifications
      notifications={limitedNotifications}
      onViewNotification={handleViewNotification}
      onRemoveNotification={handleRemoveNotification}
      messages={messages}
      isContainerExpanded={isContainerExpanded}
      classes={classes}
    />
  ) : null;
};

UserNotificationsContainer.propTypes = propTypes;

const mapStateToProps = state => ({
  authentication: state.reducers.Authentication,
  notifications: state.reducers.NotificationsV2,
});

export default connect(mapStateToProps, {
  getAllNotifications: getAllNotificationsDispatch,
  viewNotification: viewNotificationDispatch,
  removeNotification: removeNotificationDispatch,
})(injectIntl(UserNotificationsContainer));
