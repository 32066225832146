import get from 'lodash.get';
import featuresFlagsEnum from '../../containers/Authentication/FeaturesFlagsEnum';

const samlLoginFeature = get(featuresFlagsEnum, 'samlLogin', '');
const admission = get(featuresFlagsEnum, 'admission', '');
const admissionOnly = get(featuresFlagsEnum, 'admissionOnly', '');
const admissionWorkflow = get(featuresFlagsEnum, 'admissionWorkflow', '');
const admissionFieldMapping = get(featuresFlagsEnum, 'admissionFieldMapping', '');
const admissionContractsV3 = get(featuresFlagsEnum, 'admissionContractsV3', '');
const legacyProfileTest = get(featuresFlagsEnum, 'legacyProfileTest', '');
const confidentialJobs = get(featuresFlagsEnum, 'confidentialJobs', '');
const admissionContracts = get(featuresFlagsEnum, 'admissionContracts', '');
const admissionPremiumClient = get(featuresFlagsEnum, 'admissionPremiumClient', '');


const featuresEnabledSelector = (state, feature) => {
  const featureFlags = get(state, 'reducers.Authentication.currentUser.featureFlags', false);
  return Boolean(featureFlags) && featureFlags.includes(feature);
};

export default state => ({
  features: {
    samlLogin: featuresEnabledSelector(state, samlLoginFeature),
    admission: featuresEnabledSelector(state, admission),
    admissionOnly: featuresEnabledSelector(state, admissionOnly),
    admissionWorkflow: featuresEnabledSelector(state, admissionWorkflow),
    admissionFieldMapping: featuresEnabledSelector(state, admissionFieldMapping),
    admissionContractsV3: featuresEnabledSelector(state, admissionContractsV3),
    legacyProfileTest: featuresEnabledSelector(state, legacyProfileTest),
    confidentialJobs: featuresEnabledSelector(state, confidentialJobs),
    admissionContracts: featuresEnabledSelector(state, admissionContracts),
    admissionPremiumClient: featuresEnabledSelector(state, admissionPremiumClient),
  },
  currentUser: state.reducers.Authentication.currentUser,
});
