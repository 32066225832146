import { BaseForm } from '@gupy/front-commons';

class JobInternalDataForm extends BaseForm {
  constructor() {
    super({
      numVacancies: {
        validate: {
          greaterOrEqualTo: 1,
        },
      },
    });
  }
}

export default JobInternalDataForm;
