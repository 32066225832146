import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DatePicker, DialogMaterial, Dropdown, Spinner, TextArea } from '@gupy/design-system';
import { useFlag } from '@unleash/proxy-client-react';

import featuresFlagsEnum from '../../../../Authentication/FeaturesFlagsEnum';
import SalaryRange from '../../../components/SalaryRange';
import * as selectors from './HiringInformationSelectors';
import * as actions from './HiringInformationAction';

import CookieManager from '../../../../../managers/CookieManager';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import 'dayjs/locale/pt-br';

import './HiringInformationModal.scss';

const propTypes = {
  changeForm: PropTypes.func.isRequired,
  jobId: PropTypes.string.isRequired,
  onCancelButtonClick: PropTypes.func.isRequired,
  onSaveButtonClick: PropTypes.func.isRequired,
  getHiringTypes: PropTypes.func.isRequired,
  getVacancyCodes: PropTypes.func.isRequired,
  featureFlags: PropTypes.array,
  messages: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  getJobOffers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  hiringTypes: PropTypes.array.isRequired,
  vacancyCodes: PropTypes.array.isRequired,
};

const HiringInformationModal = (props) => {
  const isVacancyCodeEnabled = useFlag(featuresFlagsEnum.vacancyCode);
  const metadata = props.values.metadata || {};
  const salary = metadata.salary || {};
  const { forbidden: hiddenSalary, readonly: readonlySalary } = salary;
  const hasObservationsFeatureFlag = props.featureFlags
    .includes(featuresFlagsEnum.applicationsHiringObservations);

  const isVacancyCodeFlagEnabled = React.useMemo(() => {
    if (props.featureFlags && props.featureFlags.length) {
      const isValidateEnabled = props.featureFlags.includes(featuresFlagsEnum.validateVacancyCode);
      return isVacancyCodeEnabled || isValidateEnabled;
    }
    return false;
  }, [props.featureFlags]);

  const cookieManager = new CookieManager();
  const locale = cookieManager.getLocale();

  const isAdmissionFlagEnabled = props.featureFlags
    .includes(featuresFlagsEnum.admission);

  const handleFieldChange = (event) => {
    const { target } = event;
    props.changeForm({
      [target.name]: target.value,
    });
  };

  const handleDropdownChange = (name, value) => {
    props.changeForm({
      [name]: value,
    });
  };

  React.useEffect(() => {
    props.getJobOffers();
    props.getHiringTypes();
    props.getVacancyCodes(props.jobId, props.values.applicationId);
  }, []);

  return (
    <DialogMaterial
      open
      className="hiring-information-modal"
      onClose={props.onCancelButtonClick}
      title={props.messages.hiringInformation}
      footer={{
        buttons: [
          {
            id: 'hiring-information-modal-button-cancel',
            onClick: props.onCancelButtonClick,
            text: props.messages.cancel,
            type: 'text',
          },
          {
            id: 'hiring-information-modal-button-save',
            onClick: props.onSaveButtonClick,
            text: isAdmissionFlagEnabled
              ? props.messages.saveAndSendToAdmission
              : props.messages.save,
            disabled: props.isSaving,
            isLoading: props.isSaving,
          },
        ],
      }}
      disableEnforceFocus
    >
      {props.isLoading ? (
        <div className="hiring-information-modal__loading">
          <Spinner color="secondary" />
        </div>
      ) : (
        <div>
          <DatePicker
            id="hiringDate"
            name="hiringDate"
            value={props.values.hiringDate}
            disabled={props.isSaving}
            onChange={e => handleFieldChange({ target: { name: 'hiringDate', value: e } })}
            label={props.messages.employeeHiringDate}
            responsive
            locale={locale}
          />
          <div className="form-group">
            <Dropdown
              id="hiringType"
              label={props.messages.hiringType}
              onChange={value => handleDropdownChange(
                'hiringType',
                value,
              )}
              options={props.hiringTypes}
              selectedOptionValue={props.values.hiringType}
              nonSelectText={props.messages.select}
              size={'large'}
              disabled={props.isSaving}
            />
          </div>
          {isVacancyCodeFlagEnabled && props.vacancyCodes.length ? (
            <Dropdown
              id="jobVacancyCodeId"
              label={props.messages.vacancyCode}
              onChange={value => handleDropdownChange(
                'jobVacancyCodeId',
                value,
              )}
              options={props.vacancyCodes}
              nonSelectText={props.messages.select}
              selectedOptionValue={props.values.jobVacancyCodeId}
            />
          ) : null}
          <div className="hiring-information-modal__salary-range">
            <SalaryRange
              idFrom="salary"
              nameFrom="salary"
              idTo="salaryTo"
              nameTo="salaryTo"
              nameRange="isSalaryRange"
              salaryFrom={props.values.salary ? String(props.values.salary) : null}
              salaryCurrencyType={props.values.salaryCurrencyType}
              onSalaryChange={handleDropdownChange}
              onValueChange={handleFieldChange}
              hideToggleRange
              disabled={props.isSaving || readonlySalary || hiddenSalary}
              readonlySalary={readonlySalary}
              hiddenSalary={hiddenSalary}
              context="hiringInformation"
            />
          </div>
          {hasObservationsFeatureFlag && (
            <TextArea
              rows="8"
              id="observations"
              name="observations"
              value={props.values.observations}
              onChange={handleFieldChange}
              label={props.messages.observationField}
              disabled={props.isSaving}
            />
          )}
        </div>
      )}
    </DialogMaterial>
  );
};

HiringInformationModal.propTypes = propTypes;

const mapStateToProps = state => ({
  values: selectors.selectFormValues(state),
  isSaving: selectors.selectIsSaving(state),
  isLoading: selectors.selectIsLoading(state),
  hiringTypes: selectors.selectHiringTypes(state),
  vacancyCodes: selectors.selectVacancyCodes(state),
});

const mapActionsToProps = {
  changeForm: actions.changeForm,
  getJobOffers: actions.getJobOffers,
  getHiringTypes: actions.getHiringTypes,
  getVacancyCodes: actions.getVacancyCodes,
};

export default withRouter(
  connect(mapStateToProps, mapActionsToProps)(HiringInformationModal),
);
