import { asyncActionType, createActionTypes } from '@gupy/front-commons';

const JobTemplatesActionTypes = createActionTypes('JobTemplates',
  asyncActionType('INIT'),
  asyncActionType('DELETE'),
  asyncActionType('DUPLICATE'),
  asyncActionType('CHANGE_SEARCH'),
  asyncActionType('SEARCH'));

const updateSearch = searchString => ({
  type: JobTemplatesActionTypes.CHANGE_SEARCH,
  searchString,
});

const initJobTemplates = (searchString, page = 0) => ({
  type: JobTemplatesActionTypes.INIT,
  searchString,
  page,
});

const deleteJobTemplate = (templateId, messages, page = 0) => ({
  type: JobTemplatesActionTypes.DELETE,
  templateId,
  messages,
  page,
});

const duplicateJobTemplate = (templateId, messages, jobPath) => ({
  type: JobTemplatesActionTypes.DUPLICATE,
  payload: {
    templateId,
    messages,
    jobPath,
  },
});

export {
  updateSearch,
  initJobTemplates,
  deleteJobTemplate,
  duplicateJobTemplate,
  JobTemplatesActionTypes,
};
