import { centralTalentos } from '../../api/CentralTalentos';

export const loadNavbarWrapper = async (currentUser) => {
  const {

    view_all_admission: viewAllAdmission = false,
    view_branch_admission: viewBranchAdmission = false,
    admission_view_admission_not_confidential: admissionViewAdmissionNotConfidential = false,
    admission_view_branch_admission_not_confidential:
    admissionViewBranchAdmissionNotConfidential = false,
    has_pulses_engage_access: hasPulsesEngageAccess = false,
    has_pulses_performance_access: hasPulsesPerformanceAccess = false,
    has_niduu_access: hasNiduuAccess = false,
    admission_view_only: admissionViewOnly = false,
  } = currentUser.permissions;

  const permitions = {
    viewAllAdmission,
    viewBranchAdmission,
    admissionViewAdmissionNotConfidential,
    admissionViewBranchAdmissionNotConfidential,
    hasPulsesEngageAccess,
    hasPulsesPerformanceAccess,
    hasNiduuAccess,
    admissionViewOnly,
  };

  const res = await centralTalentos.post(
    '/v1/navbar-wrapper/produtos:batchGet', permitions, {
      extraHeaders: {
        acceptLanguage: currentUser.language || 'pt',
      },
    },
  );
  return res.body;
};

export const loadModalDiscoveryProducts = async (currentUser) => {
  const {

    view_all_admission: viewAllAdmission = false,
    view_branch_admission: viewBranchAdmission = false,
    admission_view_admission_not_confidential: admissionViewAdmissionNotConfidential = false,
    admission_view_branch_admission_not_confidential:
    admissionViewBranchAdmissionNotConfidential = false,
    has_pulses_engage_access: hasPulsesEngageAccess = false,
    has_pulses_performance_access: hasPulsesPerformanceAccess = false,
    has_niduu_access: hasNiduuAccess = false,
    admission_view_only: admissionViewOnly = false,
  } = currentUser.permissions;

  const permitions = {
    viewAllAdmission,
    viewBranchAdmission,
    admissionViewAdmissionNotConfidential,
    admissionViewBranchAdmissionNotConfidential,
    hasPulsesEngageAccess,
    hasPulsesPerformanceAccess,
    hasNiduuAccess,
    admissionViewOnly,
  };

  const res = await centralTalentos.post(
    '/modal-wrapper', permitions, {
      extraHeaders: {
        acceptLanguage: currentUser.language || 'pt',
      },
    },
  );
  return res.body;
};

export const loadFooterWrapper = async () => {
  const res = await centralTalentos.get(
    '/footer/produtos/wrapper', {}, {},
  );
  return res.body;
};

export const loadSidebarWrapper = async () => {
  const res = await centralTalentos.get(
    '/sidebar/produtos/wrapper', {}, {},
  );
  return res.body;
};
