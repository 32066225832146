export const getFooterButtons = ({
  canSave,
  isSaving,
  isUploadingFile,
  messages,
  onCancelClick,
  onSaveClick,
}) => {
  const buttons = [{
    id: 'cancel-form-email-template',
    onClick: onCancelClick,
    type: 'text',
    text: messages.cancelButton,
  }];

  if (canSave) {
    return [
      ...buttons, {
        id: 'save-form-email-template',
        disabled: isSaving || isUploadingFile,
        onClick: onSaveClick,
        text: messages.saveButton,
      }];
  }

  return buttons;
};
