import { put, all, takeLatest } from 'redux-saga/effects';

import { buildToast, ToastTypes } from '@gupy/front-commons';
import ProfileActionTypes from '../../constants/Profile/ProfileActionTypes';
import ProfileService from '../../services/Profile/ProfileService';
import CompanyService from '../../services/Company/CompanyService';
import ProfileForm from '../../forms/Profile/ProfileForm';

function* initProfile() {
  try {
    const [
      profileResponse,
      companySizesResponse,
      companyTypesResponse,
    ] = yield all([
      ProfileService.get(),
      ProfileService.getAllCompanySizes(),
      CompanyService.getTypes(),
    ]);

    yield put({
      type: ProfileActionTypes.INIT_SUCCESS,
      profile: profileResponse.body,
      companySizes: companySizesResponse.body.data,
      companyTypes: companyTypesResponse.body.data,
    });
  } catch (error) {
    yield put({
      type: ProfileActionTypes.GET_FAIL,
      error,
    });
  }
}

function* get() {
  const form = new ProfileForm();

  try {
    const response = yield ProfileService.get();
    yield put({
      type: ProfileActionTypes.GET_SUCCESS,
      profile: response.body,
    });
  } catch (error) {
    yield put({
      type: ProfileActionTypes.GET_FAIL,
      validation: form.validateErrorResponse(error),
    });
  }
}

function* patch(action) {
  const { payload, messages } = action;
  const form = new ProfileForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: ProfileActionTypes.PATCH_FAIL,
      validation,
    });
  } else {
    try {
      yield ProfileService.patch(payload);

      yield put({
        type: ProfileActionTypes.PATCH_SUCCESS,
        toast: buildToast(messages.success, ToastTypes.success),
      });
    } catch (error) {
      yield put({
        type: ProfileActionTypes.PATCH_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* ProfileSaga() {
  yield takeLatest(ProfileActionTypes.INIT, initProfile);
  yield takeLatest(ProfileActionTypes.GET, get);
  yield takeLatest(ProfileActionTypes.PATCH, patch);
}

export default ProfileSaga;
