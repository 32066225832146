export const OrdableAttributes = {
  affinity: 'affinity',
  name: 'name',
  createdAt: 'created_at',
};

export const ModifierType = {
  ASC: 'asc',
  DESC: 'desc',
};
