import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('JobWorkplace',
  'CHANGE_FORM',
  'CLEAR_FORM',
  'CLEAR_COMPANY_BRANCH_ON_CHANGE',
  'ON_COMPANY_BRANCH_BLUR',
  asyncActionType('INIT'),
  asyncActionType('POST'),
  asyncActionType('PATCH'),
  asyncActionType('GET_COMPANY_BRANCHES_LEAVES'));
