import { copilotClient } from '../../api/CopilotClient';

export const loadCopilot = async (message, token) => {
  const response = await copilotClient.post('/questions',
    { text: message },
    { extraHeaders: { 'access-token': token } });

  const data = response.body;
  return {
    data,
  };
};

export const feedbackCopilot = async (reaction, isRelevant, answerId, token) => {
  const responseFeedback = await copilotClient.post(`/answers/${answerId}/feedback`,
    { text: reaction, isAnswerRelevant: isRelevant },
    { extraHeaders: { 'access-token': token } });

  const feedbackData = responseFeedback.body;
  return {
    feedbackData,
  };
};
