import { combineReducers } from 'redux';
import { JobApplicationHistoryActionTypes } from './JobApplicationHistoryAction';

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY:
    return true;
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY_SUCCESS:
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY_FAIL:
    return false;
  default:
    return state;
  }
};

export const jobApplicationsResult = (state = [], action) => {
  switch (action.type) {
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY:
    return [];
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY_SUCCESS:
    return action.jobApplicationsResult.data.filter(({ job }) => job.id !== action.currentJobId);
  case JobApplicationHistoryActionTypes.NEXT_PAGE_JOB_APPLICATION_HISTORY_SUCCESS:
    return [
      ...state,
      ...action.jobApplicationsResult.data.filter(({ job }) => job.id !== action.currentJobId),
    ];
  default:
    return state;
  }
};

export const jobApplicationsSummary = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY:
    return {};
  case JobApplicationHistoryActionTypes.INIT_JOB_APPLICATION_HISTORY_SUCCESS:
  case JobApplicationHistoryActionTypes.NEXT_PAGE_JOB_APPLICATION_HISTORY_SUCCESS:
    return {
      ...action.jobApplicationsResult.summary,
      candidateId: action.candidateId,
    };
  default:
    return state;
  }
};

export const collapse = (state = {}, action) => {
  switch (action.type) {
  case JobApplicationHistoryActionTypes.TOGGLE_COLLAPSE:
    return action;
  default:
    return state;
  }
};

export default combineReducers({
  isLoading,
  jobApplicationsResult,
  jobApplicationsSummary,
  collapse,
});
