import React, { Fragment, useEffect, useState, useRef } from 'react';
import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import NavBarWrapperStyles from './NavBarWrapper.styles';
import ModalDiscoveryProducts from '../ModalDiscoveryProductsWrapper/ModalDiscoveryProductsWrapper';

const NavbarWrapper = (NavbarWrapperTag) => {
  const { data, modalData, setMobileSidebarOpen } = NavbarWrapperTag;

  const {
    NavBarHeader,
    NavbarProducts,
    ProductDiv,
    ProductsList,
    FixedProduct,
    ProductTitle,
    GupyProduct,
    FooterNavBar,
    ButtonOpenSidebar,
    MobileProductNav,
    MobileProductNavHeader,
    MobileProductLink,
    MobileNavMenuOverlay,
  } = NavBarWrapperStyles;

  const [mobileProductNavDisplay, setMobileProductNavDisplay] = useState('none');
  const sendGAEvent = (eventAction) => {
    GoogleAnalyticsHelper.sendEvent({
      category: 'HEADER_GLOBAL',
      action: eventAction,
    });
  };

  const ProductsModal = () => {
    if (window.innerWidth < 1024 || (data.products != null && data.products.length < 3)) {
      return (
        <FooterNavBar {...data.styleFooterNavBar}>
          <ModalDiscoveryProducts
            modalData={modalData}
            mobileProductNavDisplay={mobileProductNavDisplay}
            setMobileProductNavDisplay={setMobileProductNavDisplay}
          />
        </FooterNavBar>
      );
    }
    return false;
  };

  const toggleSidebar = () => {
    setMobileSidebarOpen(prevState => !prevState);
  };

  const mobileProductNavRef = useRef();
  useEffect(() => {
    const checkIfClickedOutsideMobileProductNav = (e) => {
      if (mobileProductNavDisplay === 'block' && mobileProductNavRef.current && !mobileProductNavRef.current.contains(e.target)) {
        setMobileProductNavDisplay('none');
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutsideMobileProductNav);
  });

  return (
    data && data.styleNavbarHeader ? (
      <header>
        <NavBarHeader {...data.styleNavbarHeader} data-testid="navbar-ecosystem">
          <ButtonOpenSidebar
            variant="text"
            size="sm"
            onClick={toggleSidebar}
            aria-label="Menu"
            alt="Menu"
            icon={<img alt="" src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/icon-open-sidebar.svg`}/>}
            data-testid="navbar-ecosystem"
          />
          <img src={`${process.env.REACT_APP_ASSETS_URL}/sidebar/recrutamento-logo.svg`} alt="Logo" className="rs-logo" />
          <NavbarProducts {...data.styleNavbarProducts}>
            <ProductDiv {...data.styleProductDiv}>
              <a href="#" onClick={() => sendGAEvent('click_logotipo_gupy')}>
                <img src={`${process.env.REACT_APP_ASSETS_URL}/gupy-logo/gupy-logo.svg`} alt="Logo" className="navbar-logo" />
              </a>
              <ProductsList {...data.styleProductsList} >
                {data.products && (
                  <Fragment>
                    <FixedProduct {...data.styleFixedProduct}>
                      <ProductTitle
                        href="#"
                        {...data.styleProductTitle}
                        rel="noreferrer"
                        target="_blank"
                        onClick={() => sendGAEvent(data.fixedProduct.eventAction)}
                      >
                        {data.fixedProduct.title}
                      </ProductTitle>
                    </FixedProduct>
                    {data.products.map(product => (
                      <GupyProduct
                        key={product.id}
                        {...data.styleGupyProduct}
                        onClick={() => sendGAEvent(product.eventAction)}
                      >
                        <ProductTitle
                          href={product.link}
                          {...data.styleProductTitle}
                          rel="noreferrer"
                          target="_blank"
                        >
                          {product.title}
                        </ProductTitle>
                      </GupyProduct>
                    ))}
                  </Fragment>
                )}
              </ProductsList>
            </ProductDiv>
            {data.products && (
              <MobileProductNav
                {...data.styleMobileProductNav}
                style={{ display: `${mobileProductNavDisplay}` }}
                ref={mobileProductNavRef}
              >
                <MobileProductNavHeader
                  {...data.styleMobileProductNavHeader}
                >
                  <img style={{ marginRight: '8px', height: '16px', width: '16px' }} alt="" src={`${process.env.REACT_APP_API_CENTRAL_TALENTOS}/modal-icons/icon-dragDrop.svg`} />
                  <span>Produtos</span>
                </MobileProductNavHeader>
                <hr style={{ margin: 'auto', width: '90%' }}/>
                <ul style={{ paddingTop: '8px', listStyleType: 'none' }}>
                  <li>
                    <MobileProductLink
                      {...data.styleMobileProductLink}
                      href={data.fixedProduct.link}
                    >
                      {data.fixedProduct.title}
                    </MobileProductLink>
                  </li>
                  {data.products.map(product => (
                    <li key={product.id} eventAction={product.eventAction}>
                      <MobileProductLink href={product.link}>
                        {product.title}
                      </MobileProductLink>
                    </li>
                  ))}
                </ul>
              </MobileProductNav>
            )}
          </NavbarProducts>
          <ProductsModal/>
        </NavBarHeader>
        <MobileNavMenuOverlay
          {...data.styleMobileNavMenuOverlay}
          style={{ display: `${mobileProductNavDisplay}` }}
        />
      </header>
    ) : null
  );
};

export default NavbarWrapper;
