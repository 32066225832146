import { HomeActionTypes } from './HomeAction';

const initialState = {
  isCurrentUserEmailDeliverable: true,
};

export const Home = (state = initialState, action) => {
  switch (action.type) {
  case HomeActionTypes.GET_CURRENT_USER_EMAIL_DELIVERABILITY_SUCCESS: {
    return {
      ...state,
      isCurrentUserEmailDeliverable: action.payload,
    };
  }
  default:
    return state;
  }
};

export default Home;
