import * as RealtimeActionTypes from './RealtimeActionTypes';

const realtimeConnect = () => ({
  type: RealtimeActionTypes.REALTIME_CONNECT,
});

const realtimeDisconnect = () => ({
  type: RealtimeActionTypes.REALTIME_DISCONNECT,
});

export {
  realtimeConnect,
  realtimeDisconnect,
};
