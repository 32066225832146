import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@gupy/front-commons';
import { Checkbox } from '@gupy/design-system-v2';
import { Input, RadioButton, Tip } from '@gupy/design-system';
import { FormattedHTMLMessage } from 'react-intl';

const propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  data: PropTypes.array.isRequired,
  allAnswers: PropTypes.array.isRequired,
  maxAnswers: PropTypes.number,
  onChange: PropTypes.func,
  isCheckbox: PropTypes.bool,
  disabled: PropTypes.bool,
};

const QuestionsCreateAnswerInput = ({
  id = null,
  name,
  multiple = false,
  data = [],
  allAnswers = [],
  maxAnswers = 10,
  onChange = () => {},
  isCheckbox = true,
  disabled = false,
}) => {
  const emitChange = (value) => {
    const target = {
      name,
      value,
    };
    const change = {
      target,
    };
    onChange(change);
  };

  const isDuplicated = (answer = '') => allAnswers
    .map(a => a.toLowerCase())
    .filter(a => a === answer.toLowerCase()).length > 1;

  const deleteAnswer = (dataId) => {
    const newData = data.filter((_, idx) => idx !== dataId);
    emitChange(newData);
  };

  const updateAnswer = (dataId, newVal, removeEmpty = false) => {
    const empty = newVal === '';
    if (empty && !data[dataId]) return;
    if (empty && removeEmpty) {
      deleteAnswer(dataId);
      return;
    }
    const newData = [
      ...data,
    ];
    newData[dataId] = newVal;
    emitChange(newData);
  };

  const calcTabIndex = () => {
    const tabIndex = data.length + 2;
    return tabIndex;
  };

  const renderInput = (dataId) => {
    const answerId = `answer-${name}-${dataId}`;
    const answers = data;
    const answersLen = answers.length;
    const answersEof = answersLen - 1;
    const value = answers[dataId] || '';
    const isAnswerDuplicated = isDuplicated(value);

    return (
      <div className="questions-create__wrapper">
        <div
          className="questions-create__answer-input"
          key={dataId.toString()}
        >
          <div
            className="job-step-additional-options__column job-step-additional-options__column--check"
          >
            {isCheckbox ? (
              <Checkbox
                checked={false}
                isDisabled
                id={`additional-option-checkbox-${answerId}`}
                name={`additional-option-checkbox-${answerId}`}
              />
            ) : (
              <RadioButton
                onChange={() => { }}
                checked={false}
                id={`additional-option-checkbox-${answerId}`}
                name={`additional-option-checkbox-${answerId}`}
              />
            )}
          </div>
          <div
            className="job-step-additional-options__column job-step-additional-options__column--input"
          >
            <Input
              id={`${answerId}-input`}
              name={answerId}
              value={value}
              tabIndex={calcTabIndex()}
              onChange={(event) => {
                const newVal = event.target.value;
                const eof = dataId === answersEof;
                updateAnswer(dataId, newVal, eof);
              }}
              onBlur={(event) => {
                const newVal = event.target.value;
                if (dataId < 0 || newVal !== '') return;
                deleteAnswer(dataId);
              }}
              block
              status={isAnswerDuplicated ? 'invalid' : 'normal'}
              disabled={disabled}
            />
          </div>
          {(!multiple || dataId > answersEof) ? null : (
            <div className="job-step-additional-options__column">
              <Button
                id={`${id}-button`}
                className="questions-create__answer-input__remove-button"
                iconRight="fa-remove"
                disabled={disabled}
                onClick={() => {
                  deleteAnswer(dataId);
                }}
              />
            </div>
          )}
        </div>
        {
          isAnswerDuplicated && (
            <Tip
              id="tip-hint"
              type="danger"
              showIcon
              inverted
              text={(
                <FormattedHTMLMessage
                  id="custom_test_duplicfated_answer_error"
                  defaultMessage="Não é permitido criar alternativas iguais. <a href='{url}' target='_blank'>Saiba mais</a>"
                  values={{ url: 'https://support-companies.gupy.io/hc/pt-br/articles/11174752061851-Como-criar-e-utilizar-testes-customizados' }}
                />
              )}
            />
          )
        }
      </div>
    );
  };

  const answersLen = data.length;
  const buttons = [];

  data.forEach((answer, key) => buttons.push(renderInput(key)));

  if (!disabled && answersLen <= maxAnswers) {
    buttons.push(renderInput(answersLen));
  }

  return (
    <div id={id || name}>
      {multiple ? buttons : renderInput(0)}
    </div>
  );
};

QuestionsCreateAnswerInput.propTypes = propTypes;

export default QuestionsCreateAnswerInput;
