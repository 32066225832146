import React from 'react';
import PropTypes from 'prop-types';

import { MenuButton } from '@gupy/design-system';

import { ItemSortEnum } from '../ItemSortEnum';

import './JobCustomFormTemplateMenuButton.scss';

const propTypes = {
  handleItemSort: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  item: PropTypes.object.isRequired,
  itemsCount: PropTypes.number.isRequired,
  messages: PropTypes.object.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  onEditItem: PropTypes.func.isRequired,
};

const JobCustomFormTemplateMenuButton = ({
  handleItemSort,
  index,
  item,
  itemsCount,
  messages,
  onDeleteItem,
  onEditItem,
}) => {
  const reorderMenuOptions = [];

  const isFirstPosition = index === 0;
  const isLastPosition = (index + 1) === itemsCount;

  if (!isFirstPosition) {
    reorderMenuOptions.push(
      {
        action: () => handleItemSort(item, index, ItemSortEnum.MOVE_TO_TOP),
        title: messages.moveToTop,
      },
      {
        action: () => handleItemSort(item, index, ItemSortEnum.MOVE_UP),
        title: messages.moveUp,
      },
    );
  }

  if (!isLastPosition) {
    reorderMenuOptions.push({
      action: () => handleItemSort(item, index, ItemSortEnum.MOVE_DOWN),
      title: messages.moveDown,
    },
    {
      action: () => handleItemSort(item, index, ItemSortEnum.MOVE_TO_END),
      title: messages.moveToEnd,
    });
  }

  return (
    <div className={'job-custom-form-template-menu-button'}>
      {itemsCount > 1 && (
        <div className="job-custom-form-template-menu-button__btn">
          <MenuButton
            id={`form-template-reorder-${index}`}
            data-testid={`form-template-reorder-${index}`}
            icon="SwapVert"
            options={reorderMenuOptions}
            closeOnClick
          />
        </div>
      )}
      <div className={'job-custom-form-template-menu-button__btn'}>
        <MenuButton
          id={`form-template-menu-${index}`}
          data-testid={`form-template-menu-${index}`}
          options={[
            {
              action: () => { onEditItem(item); },
              title: messages.edit,
            },
            {
              action: () => { onDeleteItem(item); },
              title: messages.exclude,
            },
          ]}
          closeOnClick
        />
      </div>
    </div>
  );
};

JobCustomFormTemplateMenuButton.propTypes = propTypes;

export default JobCustomFormTemplateMenuButton;
