import { asyncActionType, createActionTypes } from '@gupy/front-commons';

export default createActionTypes('Job',
  asyncActionType('GET'),
  asyncActionType('GET_ALL'),
  asyncActionType('DELETE'),
  asyncActionType('DUPLICATE'),
  asyncActionType('DUPLICATE_AS_TEMPLATE'),
  asyncActionType('PATCH_JOB_STATUS'),
  'CHANGE_SUMMARY',
  'CHANGE_SUMMARY_WITHOUT_REQUEST',
  'CLEAR_SUMMARY',
  'CLEAR_FORM',
  asyncActionType('PIN'),
  asyncActionType('UNPIN'),
  asyncActionType('GET_COMPANY_CAREER_PAGES'),
  asyncActionType('GET_JOB_ANALYSIS'),
  'CLEAR_JOB_ANALYSIS',
  'OPEN_JOB_FIELD_FILLED_MODAL',
  'CLOSE_JOB_FIELD_FILLED_MODAL');
