import React from 'react';
import { ToastContainer } from 'react-toastify';

const AppToast = () => (
  <div aria-live="assertive">
    <ToastContainer
      position="bottom-left"
      type="default"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      pauseOnHover
      style={{
        // Need this to adjust above everything else
        // for temporary microfrontends
        zIndex: 1201,
      }}
    />
  </div>
);

export default AppToast;
