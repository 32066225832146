import { combineReducers } from 'redux';
import { TestProvidersActionTypes } from './TestProvidersActions';

export const availableProviders = (state = [], action) => {
  switch (action.type) {
  case TestProvidersActionTypes.GET:
  case TestProvidersActionTypes.GET_FAIL:
    return [];
  case TestProvidersActionTypes.SAVE_TOKEN:
  case TestProvidersActionTypes.SET_PROVIDER_STATUS:
  case TestProvidersActionTypes.GET_SUCCESS:
  case TestProvidersActionTypes.SAVE_TOKEN_SUCCESS:
    return action.availableProviders;
  default:
    return state;
  }
};

export default combineReducers({
  availableProviders,
});
