import api from '../../api/DarthVaderClient';

export default {
  getSurveys() {
    return api.get('/selection-process/surveys');
  },

  saveSurveyCareerPageBind(payload) {
    return api.post('/selection-process/survey/integrations', payload);
  },

  getSurveyCareerPageBinds() {
    return api.get('/selection-process/survey/integrations');
  },

  deleteSurveyCareerPageBind(id) {
    return api.delete(`/selection-process/survey/integrations/${id}`);
  },

  createSurvey(payload) {
    return api.post('/selection-process/engage-surveys', payload);
  },

  createAccount(payload) {
    return api.post('/selection-process/surveys/client', payload);
  },
};
