import api from '../../api/DarthVaderClient';

export default {
  sendEmail(payload) {
    return api.post('/communication/companies/send/email', payload);
  },

  getJobBoards() {
    return api.get('/job-boards/available');
  },

  getJobBoardsByJob(jobId) {
    return api.get(`/job-boards/jobs/${jobId}`);
  },

  updatePublishingChannels(payload) {
    const { id, ...payloadNoId } = payload;
    return api.post(
      `/job-publication/companies/jobs/${id}/publishing-channels`,
      payloadNoId,
    );
  },
};
