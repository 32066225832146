import React from 'react';
import PropTypes from 'prop-types';

import './PermissionDiversityConfirmDialog.scss';
import { Tip, Dialog } from '@gupy/design-system';
import { FormattedHTMLMessage } from 'react-intl';

const propTypes = {
  messages: PropTypes.object.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
};

const PermissionDiversityConfirmDialog = ({
  messages,
  isModalOpen,
  handleCancel,
  handleOk,
}) => (
  <Dialog
    id="permission-diversity-confirm-dialog"
    title={messages.diversityPermissionDialogTitle}
    open={isModalOpen}
    onClose={handleCancel}
    footer={{
      primaryButton: {
        label: messages.diversityPermissionDialogConfirmButton,
        action: handleOk,
      },
      secondaryButton: {
        label: messages.diversityPermissionDialogBackButton,
        action: handleCancel,
      },
    }}
  >
    <Tip
      id="permission-diversity-confirm-dialog-tip-warning"
      type="warning"
      showIcon
      className="permission-diversity-confirm-dialog-tip"
      text={messages.diversityPermissionDialogWarningTip}
    />
    <div className="permission-diversity-confirm-dialog-paragraph">
      {messages.diversityPermissionDialogParagraphOne}
    </div>
    <div className="permission-diversity-confirm-dialog-paragraph">
      <FormattedHTMLMessage
        id="diversity_permission_dialog_paragraph_two"
        defaultMessage={'<strong>Lembre-se:</strong> qualquer usuário com a permissão de gerenciar usuários poderá atrelar um novo usuário a esse perfil e liberar as ferramentas de diversidade.'}
      />
    </div>
  </Dialog>
);

PermissionDiversityConfirmDialog.propTypes = propTypes;

export default PermissionDiversityConfirmDialog;
