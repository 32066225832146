import { BaseForm } from '@gupy/front-commons';

class CustomTestQuestionValidation extends BaseForm {
  constructor() {
    super({
      text: {
        validate: {
          notEmpty: true,
        },
      },
      questionType: {
        validate: {
          notEmpty: true,
        },
      },
    });
  }
}

export default CustomTestQuestionValidation;
