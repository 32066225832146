import { put, takeLatest, call } from 'redux-saga/effects';

import { JobStatusEnum } from '@gupy/enums';
import { buildToast, ToastTypes } from '@gupy/front-commons';
import JobPublicationTypeActionTypes from '../../../constants/Job/JobPublicationType/JobPublicationTypeActionTypes';
import { getJobSuccess } from '../../../actions/Job/JobPublicationType/JobPublicationTypeAction';
import JobService from '../../../services/Job/JobService';
import JobPublicationTypeForm from '../../../forms/Job/JobPublicationType/JobPublicationTypeForm';
import history from '../../../history';
import { CareerPageVisibility } from '../../../containers/CareerPage/Constants';
import JobPublicationTypeEnum from '../../../constants/Job/JobPublicationType/JobPublicationType';

function* get({ payload }) {
  const form = new JobPublicationTypeForm();

  try {
    const { body } = yield JobService.get(payload.id);

    body.publicationType = body.careerPage
      && body.careerPage.visibility === CareerPageVisibility.confidential
      ? CareerPageVisibility.confidential
      : body.publicationType;

    yield put(getJobSuccess(body));
  } catch (error) {
    if (error.statusCode === 404) {
      yield call(history.push, '/companies/jobs/not-found');
    } else {
      yield put({
        type: JobPublicationTypeActionTypes.GET_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* publishJob(action) {
  const { payload, registerStepId, exceededQuestions, templateMessage } = action;

  payload.publicationType = payload.publicationType === CareerPageVisibility.confidential
    ? JobPublicationTypeEnum.external
    : payload.publicationType;

  const form = new JobPublicationTypeForm();

  form.populate(payload);

  const validation = form.customValidateModel(registerStepId, exceededQuestions);
  if (validation) {
    yield put({
      type: JobPublicationTypeActionTypes.PUBLISH_FAIL,
      validation,
    });
  } else {
    try {
      const response = payload.status === JobStatusEnum.template ? yield JobService.patch(payload)
        : yield JobService.publishJob(payload);

      yield put({
        type: JobPublicationTypeActionTypes.PUBLISH_SUCCESS,
        modelForm: response.body,
        toast: payload.status === JobStatusEnum.template
          ? buildToast(templateMessage, ToastTypes.success) : null,
      });
      const redirectPath = payload.status === JobStatusEnum.template
        ? '/companies/setup/templates'
        : `/companies/jobs/edit/${payload.id}/share`;

      yield call(history.push, redirectPath);
    } catch (error) {
      yield put({
        type: JobPublicationTypeActionTypes.PUBLISH_FAIL,
        validation: form.validateErrorResponse(error),
        error,
      });
    }
  }
}

function* JobPublicationTypeSaga() {
  yield takeLatest(JobPublicationTypeActionTypes.PUBLISH, publishJob);
  yield takeLatest(JobPublicationTypeActionTypes.GET, get);
}

export default JobPublicationTypeSaga;
