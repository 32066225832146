import { combineReducers } from 'redux';

import JobCandidatesClassifiersActionTypes from '../../../constants/Job/JobCandidatesClassifiers/JobCandidatesClassifiersActionTypes';

export const usersList = (state = [], action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.GET_USERS_LIST_SUCCESS:
    return action.usersList;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST:
    return true;
  case JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST_SUCCESS:
  case JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST_FAIL:
    return false;
  default:
    return state;
  }
};

export const classifiersList = (state = [], action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.GET_CLASSIFIERS_LIST_SUCCESS:
    return action.classifiersList;
  case JobCandidatesClassifiersActionTypes.CHANGE_CLASSIFIERS_LIST: {
    return state.map((classifier) => {
      if (classifier.id === action.classifierId) {
        return {
          ...classifier,
          classifierType: action.classifierType,
          hasChanged: true,
        };
      }

      return classifier;
    });
  }
  default:
    return state;
  }
};

const initialModelForm = {
  user: {},
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.POST_SUCCESS:
    return initialModelForm;
  case JobCandidatesClassifiersActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.POST:
    return true;
  case JobCandidatesClassifiersActionTypes.POST_SUCCESS:
  case JobCandidatesClassifiersActionTypes.POST_FAIL:
    return false;
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.POST:
    return {};
  case JobCandidatesClassifiersActionTypes.POST_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export const isSavingClassifiersToJobs = (state = false, action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.POST_TO_JOBS:
    return true;
  case JobCandidatesClassifiersActionTypes.POST_TO_JOBS_SUCCESS:
  case JobCandidatesClassifiersActionTypes.POST_TO_JOBS_FAIL:
    return false;
  default:
    return state;
  }
};

export const isMultiSelectClassifiersModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobCandidatesClassifiersActionTypes.OPEN_CLASSIFIERS_MODAL:
    return true;
  case JobCandidatesClassifiersActionTypes.CLOSE_CLASSIFIERS_MODAL:
  case JobCandidatesClassifiersActionTypes.POST_TO_JOBS_SUCCESS:
  case JobCandidatesClassifiersActionTypes.POST_TO_JOBS_FAIL:
    return false;
  default:
    return state;
  }
};

export default combineReducers({
  usersList,
  classifiersList,
  modelForm,
  isSaving,
  isLoading,
  validation,
  isSavingClassifiersToJobs,
  isMultiSelectClassifiersModalOpen,
});
