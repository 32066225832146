import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Button } from '@gupy/design-system';
import { withStyles } from '@material-ui/core/styles';
import DSDialog from '@material-ui/core/Dialog';
import DSDialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';

import { getMessages } from '../../Messages';

import './CustomIdRemoveModal.scss';

const Dialog = withStyles(theme => ({
  paper: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: '360px',
      height: 'auto',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '25px',
    },
  },
}))(({ children, classes, ...props }) => (
  <DSDialog {...props} classes={classes}>
    {children}
  </DSDialog>
));

const DialogActions = withStyles(() => ({
  root: {
    flexDirection: 'column',
    margin: 0,
    padding: '24px',
    paddingTop: 0,
  },
}))(({ classes, children }) => (
  <DSDialogActions classes={classes}>{children}</DSDialogActions>
));

const CustomIdRemoveModal = ({
  intl,
  customIdRemoveModal,
  setCustomIdRemoveModal,
  handleRemoveJobBoardCustomId,
}) => {
  const messages = getMessages(intl);
  const { isOpen, jobBoardId, keyId } = customIdRemoveModal;

  const exitModal = () => {
    setCustomIdRemoveModal({
      isOpen: false,
      jobBoardId: null,
      keyId: null,
    });
  };

  const handleCancelClick = () => {
    exitModal();
  };

  const handleConfirmClick = () => {
    handleRemoveJobBoardCustomId(jobBoardId, keyId);
    exitModal();
  };

  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      className="custom-id-remove-modal"
    >
      <DialogContent>
        <div>
          <p className="custom-id-remove-modal__dialog-content-title">
            {messages.jobBoardsCustomIdsRemoveModalDialogContentTitle}
          </p>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          id="custom-id-remove-modal-confirm"
          className="custom-id-remove-modal__dialog-actions-confirm-btn"
          onClick={handleConfirmClick}
          type="contained"
          block
        >
          {messages.jobBoardsCustomIdsRemoveModalDialogActionsBtnConfirm}
        </Button>
        <Button
          id="custom-id-remove-modal-cancel"
          className="custom-id-remove-modal__dialog-actions-cancel-btn"
          onClick={handleCancelClick}
          type="text"
          block
        >
          {messages.jobBoardsCustomIdsRemoveModalDialogActionsBtnCancel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CustomIdRemoveModal.propTypes = {
  intl: PropTypes.object.isRequired,
  customIdRemoveModal: PropTypes.object.isRequired,
  setCustomIdRemoveModal: PropTypes.func.isRequired,
  handleRemoveJobBoardCustomId: PropTypes.func.isRequired,
};

export default injectIntl(CustomIdRemoveModal);
