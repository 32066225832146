import React from 'react';
import { injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import JobBoardPanel from './JobBoardPanel';

import './JobBoardsCustomIds.scss';

const JobBoardsCustomIds = ({
  jobBoardsCustomIds,
  handleAddJobBoardCustomId,
  handleSetJobBoardCustomIdCareerPages,
  handleSetJobBoardCustomIdOnChange,
  setCustomIdRemoveModal,
}) => (
  <div className="job-boards-custom-ids">
    <div className="job-boards-custom-ids__panels-list">
      {jobBoardsCustomIds.map(jobBoard => (
        <JobBoardPanel
          key={jobBoard.jobBoardId}
          jobBoard={jobBoard}
          handleAddJobBoardCustomId={handleAddJobBoardCustomId}
          handleSetJobBoardCustomIdCareerPages={handleSetJobBoardCustomIdCareerPages}
          handleSetJobBoardCustomIdOnChange={handleSetJobBoardCustomIdOnChange}
          setCustomIdRemoveModal={setCustomIdRemoveModal}
        />
      ))}
    </div>
  </div>);

JobBoardsCustomIds.propTypes = {
  jobBoardsCustomIds: PropTypes.array.isRequired,
  handleAddJobBoardCustomId: PropTypes.func.isRequired,
  handleSetJobBoardCustomIdCareerPages: PropTypes.func.isRequired,
  handleSetJobBoardCustomIdOnChange: PropTypes.func.isRequired,
  setCustomIdRemoveModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  jobBoardsCustomIds: state.reducers.Integrations.jobBoardsCustomIds,
});

export default injectIntl(connect(
  mapStateToProps,
  {},
)(JobBoardsCustomIds));
