import React, { useState, Fragment } from 'react';
import {
  Button,
  Dialog,
  Icon,
  Infotip,
  IconEnum } from '@gupy/design-system';
import PropTypes from 'prop-types';
import { useFlag } from '@unleash/proxy-client-react';

import DeleteInLinkedinDialog from './DeleteInLinkedinDialog';

import childAppPropTypes from '../../../../prop-types/childAppPropTypes';
import featuresFlagsEnum from '../../../../../Authentication/FeaturesFlagsEnum';

import './ChildAppActions.scss';

export const EditButton = ({ setEditMode, messages, id, disabled }) => (
  <button
    onClick={() => setEditMode(true)}
    type="button"
    {...(disabled && { disabled: true })}
  >
    <Infotip
      id={`edit-button-child-app-${id}`}
      message={messages.rscIntegrationChildAppsEditButton}
      width={50}
    >
      <Icon
        alt={messages.rscIntegrationChildAppsEditButton}
        icon={IconEnum.Create}
      />
    </Infotip>
  </button>
);

EditButton.propTypes = {
  messages: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const DeleteButton = ({
  messages,
  id,
  handleChildAppDesync,
  disabled,
}) => (
  <button
    type="button"
    onClick={handleChildAppDesync}
    {...(disabled && { disabled: true })}
  >
    <Infotip
      id={`delete-button-child-app-${id}`}
      message={messages.rscIntegrationChildAppsDeleteButton}
      width={50}
    >
      <Icon
        alt={messages.rscIntegrationChildAppsDeleteButton}
        icon={IconEnum.Delete}
      />
    </Infotip>
  </button>
);

DeleteButton.propTypes = {
  messages: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  handleChildAppDesync: PropTypes.func,
  disabled: PropTypes.bool,
};

export const DeleteInLinkedinButton = ({
  onClick,
  label,
  tipText,
  disabled,
}) => {
  const deleteButton = (
    <Button
      type="outlined"
      onClick={onClick}
      disabled={disabled}
      size="sm"
    >
      {label}
    </Button>
  );

  if (disabled) {
    return (
      <Infotip
        message={tipText}
        center
        width={165}
      >
        {deleteButton}
      </Infotip>
    );
  }

  return deleteButton;
};

DeleteInLinkedinButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  tipText: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
};

const ChildAppActions = ({
  messages,
  childApp,
  editMode,
  setEditMode,
  handleChildAppDesyncSubmit,
}) => {
  const isLinkedinRscDeleteChildAppEnabled = useFlag(featuresFlagsEnum.linkedinRscDeleteChildApp);

  const [isChildAppDesyncModalOpen, setIsChildAppDesyncModalOpen] = useState(false);

  const [isLinkedinExclusionDialogOpen, setIsLinkedinExclusionDialogOpen] = useState(false);

  const handleChildAppDesyncModalOpen = open => setIsChildAppDesyncModalOpen(open);

  const handleChildAppDesync = () => {
    handleChildAppDesyncModalOpen(true);
  };

  return (
    <Fragment>
      {childApp.isDeleteInLinkedinButtonVisible() ? (
        <Fragment>
          <DeleteInLinkedinButton
            onClick={() => { setIsLinkedinExclusionDialogOpen(true); }}
            label={messages.rscIntegrationChildAppDeleteInLinkedinButton}
            tipText={messages.rscIntegrationChildAppsDeleteLinkedinButtondTipText}
            disabled={!childApp.isDeleteInLinkedinButtonEnabled()}
          />
          <DeleteInLinkedinDialog
            messages={messages}
            childApp={childApp}
            open={isLinkedinExclusionDialogOpen}
            setOpen={setIsLinkedinExclusionDialogOpen}
          />
        </Fragment>
      ) : (
        <Fragment>
          <EditButton
            setEditMode={setEditMode}
            messages={messages}
            id={`child-app-${childApp.id}-edit-button`}
            {...(!childApp.isEditButtonEnabled() || editMode ? { disabled: true } : {})}
          />
          {isLinkedinRscDeleteChildAppEnabled && (
            <Fragment>
              <DeleteButton
                messages={messages}
                id={`child-app-${childApp.id}-delete-button`}
                handleChildAppDesync={handleChildAppDesync}
                {...(!childApp.isDeleteButtonEnabled() ? { disabled: true } : {})}
              />
              <Dialog
                id={`child-app-${childApp.id}-desync-modal`}
                className="child-app-desync-modal"
                title={messages.rscIntegrationChildAppDesyncModalTitle}
                maxWidth="sm"
                onClose={() => handleChildAppDesyncModalOpen(false)}
                open={isChildAppDesyncModalOpen}
                footer={{
                  secondaryButton: {
                    label: messages.rscIntegrationChildAppDesyncModalCancelButton,
                    action: () => handleChildAppDesyncModalOpen(false),
                  },
                  primaryButton: {
                    label: messages.rscIntegrationChildAppDesyncModalConfirmButton,
                    action: () => {
                      handleChildAppDesyncSubmit(childApp.id);
                      handleChildAppDesyncModalOpen(false);
                    },
                  },
                }}
              >
                <section id={`child-app-${childApp.id}-desync-modal-body`}>
                  <p>
                    <Icon icon={IconEnum.Info} fontSize="small" />
                    {messages.rscIntegrationChildAppDesyncModalAlertText}
                  </p>
                  <p>{messages.rscIntegrationChildAppDesyncModalP1}</p>
                  <p>{messages.rscIntegrationChildAppDesyncModalP2}</p>
                  <p>{messages.rscIntegrationChildAppDesyncModalP3}</p>
                  <p>{messages.rscIntegrationChildAppDesyncModalP4}</p>
                </section>
              </Dialog>
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

ChildAppActions.propTypes = {
  messages: PropTypes.object.isRequired,
  childApp: childAppPropTypes.isRequired,
  editMode: PropTypes.bool.isRequired,
  setEditMode: PropTypes.func.isRequired,
  handleChildAppDesyncSubmit: PropTypes.func.isRequired,
};

export default ChildAppActions;
