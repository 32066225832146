import React, { useEffect } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import '@gupy/design-system/Styles/base/_table.scss';

import { Infotip, IconEnum, Icon } from '@gupy/design-system';
import RscLinkedinIntegrationChildAppsItem from './RscLinkedinIntegrationChildAppsItem';

import careerPagePropTypes from '../../prop-types/careerPagePropTypes';
import childAppPropTypes from '../../prop-types/childAppPropTypes';
import { getMessages } from '../../Messages';
import './RscLinkedinIntegrationChildApps.scss';

const RscLinkedinIntegrationChildApps = ({
  intl,
  childApps,
  rscLinkedinIntegrationCareerPages,
  handleChildAppSyncSubmit,
  handleChildAppEditSubmit,
  handleChildAppSyncRestartSubmit,
  handleChildAppDesyncSubmit,
  handleChildAppsPollingRequest,
}) => {
  const messages = getMessages(intl);

  useEffect(() => {
    let timeoutId;

    const ENABLING_LINKEDIN_FEATURES_STATUS_TIMER = 3000;

    const shouldPollingChildApps = childApps.some(
      childApp => childApp.canStatusToBeUpdatedInServerSide(),
    );

    if (shouldPollingChildApps) {
      timeoutId = setTimeout(
        () => handleChildAppsPollingRequest(),
        ENABLING_LINKEDIN_FEATURES_STATUS_TIMER,
      );
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [childApps]);

  return (
    <table className="job-board-panel-rsc-child-apps__table gupy-table gupy-table-100 gupy-table--with-actions">
      <thead>
        <tr>
          <th>
            <span className="label-column-name">
              {messages.rscIntegrationChildAppsLinkedinPageLabel}
              <Infotip
                className="label-column-name__infotip-rsc-integration-column-name"
                message={messages.rscIntegrationColumnNameLabelInfotip}
                width={150}
                center
              >
                <Icon icon={IconEnum.Info} fontSize="small" />
              </Infotip>
            </span>
          </th>
          <th>{messages.rscIntegrationChildAppsCareerPageLabel}</th>
          <th>{messages.rscIntegrationChildAppsSyncStatusLabel}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {childApps
          .map(childApp => (
            <RscLinkedinIntegrationChildAppsItem
              key={childApp.id}
              childApp={childApp}
              rscLinkedinIntegrationCareerPages={rscLinkedinIntegrationCareerPages}
              handleChildAppSyncSubmit={handleChildAppSyncSubmit}
              handleChildAppEditSubmit={handleChildAppEditSubmit}
              handleChildAppSyncRestartSubmit={handleChildAppSyncRestartSubmit}
              handleChildAppDesyncSubmit={handleChildAppDesyncSubmit}
            />
          ))}
      </tbody>
    </table>
  );
};

RscLinkedinIntegrationChildApps.propTypes = {
  intl: intlShape,
  childApps: PropTypes.arrayOf(childAppPropTypes).isRequired,
  rscLinkedinIntegrationCareerPages: PropTypes.arrayOf(careerPagePropTypes).isRequired,
  handleChildAppSyncSubmit: PropTypes.func.isRequired,
  handleChildAppEditSubmit: PropTypes.func.isRequired,
  handleChildAppSyncRestartSubmit: PropTypes.func.isRequired,
  handleChildAppDesyncSubmit: PropTypes.func.isRequired,
  handleChildAppsPollingRequest: PropTypes.func.isRequired,
};

export default injectIntl(RscLinkedinIntegrationChildApps);
