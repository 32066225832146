/* global google */

import { put, takeLatest } from 'redux-saga/effects';

import { GoogleAnalyticsHelper } from '@gupy/front-helpers';
import AddressActionTypes from '../../constants/Address/AddressActionTypes';
import AddressPlaceId from '../../constants/Address/AddressPlaceId';
import AddressService from '../../services/Address/AddressService';
import AddressForm from '../../forms/Address/AddressForm';

// TODO: Should be removed once address context is migrated to container context (@atrat/@otm)
// Why: There is no need to have Address outside of each use (subsidiary, workplace, filters)

function* getAddressSuggestions(action) {
  const { input } = action;

  try {
    const suggestionsResponse = yield AddressService.getSuggestions(input);

    yield put({
      type: AddressActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_SUCCESS,
      suggestions: suggestionsResponse,
    });
  } catch (error) {
    yield put({
      type: AddressActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS_FAIL,
      error,
    });
  }
}

function* selectAddressSuggestion(action) {
  const { payload } = action;

  if (payload.placeId === AddressPlaceId.other) {
    yield put({
      type: AddressActionTypes.CHANGE_FORM_ADDRESS_SELECT_SUGGESTION_SUCCESS,
      geocoders: [],
    });
  } else {
    try {
      const geocoder = new google.maps.Geocoder();

      const geocoderResponse = yield new Promise((resolve, reject) => {
        GoogleAnalyticsHelper.sendEvent({
          category: 'ADDRESS',
          action: 'selectAddressSuggestion',
        });

        geocoder.geocode(payload, (response, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(response);
          } else {
            reject(new Error(`Geocoder error: ${status}`));
          }
        });
      });

      yield put({
        type: AddressActionTypes.CHANGE_FORM_ADDRESS_SELECT_SUGGESTION_SUCCESS,
        geocoders: geocoderResponse,
      });
    } catch (error) {
      yield put({
        type: AddressActionTypes.CHANGE_FORM_ADDRESS_SELECT_SUGGESTION_FAIL,
        error,
      });
    }
  }
}

function* getCountries() {
  try {
    const countriesResponse = yield AddressService.getCountries();

    yield put({
      type: AddressActionTypes.GET_COUNTRIES_SUCCESS,
      countries: countriesResponse.body,
    });
  } catch (error) {
    yield put({
      type: AddressActionTypes.GET_COUNTRIES_FAIL,
      error,
    });
  }
}

function* saveAddressModal(action) {
  const { modelForm } = action;
  const form = new AddressForm();

  form.populate(modelForm);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: AddressActionTypes.SAVE_ADDRESS_MODAL_FAIL,
      validation,
    });
  } else {
    yield put({
      type: AddressActionTypes.SAVE_ADDRESS_MODAL_SUCCESS,
      modelForm,
    });
  }
}

function* addressSaga() {
  yield takeLatest(AddressActionTypes.GET_COUNTRIES, getCountries);
  yield takeLatest(AddressActionTypes.SAVE_ADDRESS_MODAL, saveAddressModal);
  yield takeLatest(
    AddressActionTypes.CHANGE_FORM_ADDRESS_SUGGESTIONS,
    getAddressSuggestions,
  );
  yield takeLatest(
    AddressActionTypes.CHANGE_FORM_ADDRESS_SELECT_SUGGESTION,
    selectAddressSuggestion,
  );
}

export default addressSaga;
