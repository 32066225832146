export const currencies = [
  { value: 'R$', label: 'R$' },
  { value: '$', label: '$' },
  { value: '€', label: '€' },
  { value: '£', label: '£' },
  { value: 'US-$', label: 'US-$' },
  { value: 'ARS-$', label: 'ARS-$' },
  { value: 'B/.', label: 'B/.' },
  { value: 'Bs', label: 'Bs' },
  { value: 'BZ$', label: 'BZ$' },
  { value: '$b', label: '$b' },
  { value: 'CLP-$', label: 'CLP-$' },
  { value: 'COP-$', label: 'COP-$' },
  { value: '₡', label: '₡' },
  { value: 'Gs', label: 'Gs' },
  { value: 'GYD-$', label: 'GYD-$' },
  { value: 'L', label: 'L' },
  { value: 'MXN-$', label: 'MXN-$' },
  { value: '₱', label: '₱' },
  { value: 'Q', label: 'Q' },
  { value: 'S/.', label: 'S/.' },
  { value: 'SRD-$', label: 'SRD-$' },
  { value: 'SVC-$', label: 'SVC-$' },
  { value: '$U', label: '$U' },
  { value: 'RD$', label: 'RD$' },
  { value: 'J$', label: 'J$' },
  { value: 'Bds$', label: 'Bds$' },
  { value: 'TT$', label: 'TT$' },
  { value: 'ECD$', label: 'ECD$' },
  { value: 'C$', label: 'C$' },
  { value: 'Rp', label: 'Rp' },
  { value: '₩', label: '₩' },
  { value: '¥', label: '¥' },
  { value: '₹', label: '₹' },
  { value: 'Bs.S', label: 'Bs.S' },
  { value: '₺', label: '₺' },
  { value: 'R', label: 'R' },
  { value: 'kr', label: 'kr' },
  { value: 'RM', label: 'RM' },
  { value: '₪', label: '₪' },
  { value: 'د.إ', label: 'د.إ' },
  { value: 'A$', label: 'A$' },
  { value: 'CHF', label: 'CHF' },
];
