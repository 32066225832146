import { combineReducers } from 'redux';

import { EmailTemplateActionTypes } from './Actions';

const initialModelForm = {
  allowReply: false,
  body: '',
  createdAt: '',
  id: undefined,
  name: '',
  public: false,
  subject: '',
  type: '',
  fileName: '',
  files: [],
  userId: null,
  associatedJob: null,
  associatedJobs: [],
};

export const isSaving = (state = false, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET:
  case EmailTemplateActionTypes.POST:
  case EmailTemplateActionTypes.PUT:
    return true;
  case EmailTemplateActionTypes.GET_SUCCESS:
  case EmailTemplateActionTypes.GET_FAIL:
  case EmailTemplateActionTypes.POST_SUCCESS:
  case EmailTemplateActionTypes.POST_FAIL:
  case EmailTemplateActionTypes.PUT_SUCCESS:
  case EmailTemplateActionTypes.PUT_FAIL:
    return false;
  default:
    return state;
  }
};

export const searched = (state = false, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.SEARCH:
    return true;
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.POST_SUCCESS:
  case EmailTemplateActionTypes.PUT_SUCCESS:
    return initialModelForm;
  case EmailTemplateActionTypes.GET_SUCCESS:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  case EmailTemplateActionTypes.CLEAR:
    return initialModelForm;
  default:
    return state;
  }
};

export const validation = (state = {}, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.POST:
  case EmailTemplateActionTypes.PUT:
    return {};
  case EmailTemplateActionTypes.POST_FAIL:
  case EmailTemplateActionTypes.PUT_FAIL:
  case EmailTemplateActionTypes.GET_ALL_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_ALL:
  case EmailTemplateActionTypes.GET:
  case EmailTemplateActionTypes.TYPE_SEARCH:
  case EmailTemplateActionTypes.SEARCH:
  case EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB:
  case EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS:
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS:
    return true;
  case EmailTemplateActionTypes.GET_ALL_SUCCESS:
  case EmailTemplateActionTypes.GET_SUCCESS:
  case EmailTemplateActionTypes.GET_ALL_FAIL:
  case EmailTemplateActionTypes.GET_FAIL:
  case EmailTemplateActionTypes.SEARCH_SUCCESS:
  case EmailTemplateActionTypes.SEARCH_FAIL:
  case EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_SUCCESS:
  case EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_FAIL:
  case EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS_SUCCESS:
  case EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS_FAIL:
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_SUCCESS:
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_FAIL:
    return false;
  default:
    return state;
  }
};

export const emailTemplates = (state = [], action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_ALL:
  case EmailTemplateActionTypes.SEARCH:
  case EmailTemplateActionTypes.GET_ALL_FAIL:
  case EmailTemplateActionTypes.GET_ALL_BY_TYPE_FAIL:
    return [];
  case EmailTemplateActionTypes.GET_ALL_SUCCESS:
  case EmailTemplateActionTypes.GET_ALL_BY_TYPE_SUCCESS:
    return action.emailTemplates;
  case EmailTemplateActionTypes.SEARCH_SUCCESS:
    return action.emailTemplates.length > 0 ? action.emailTemplates : [];
  default:
    return state;
  }
};

export const types = (state = [], action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_TYPES_SUCCESS:
    return action.types;
  case EmailTemplateActionTypes.GET_TYPES_FAIL:
    return [];
  default:
    return state;
  }
};

export const variables = (state = [], action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_ALL_SUCCESS:
  case EmailTemplateActionTypes.GET_ALL_BY_TYPE_SUCCESS:
  case EmailTemplateActionTypes.GET_VARIABLES_SUCCESS:
    return action.variables;
  case EmailTemplateActionTypes.GET_ALL_FAIL:
  case EmailTemplateActionTypes.GET_ALL_BY_TYPE_FAIL:
  case EmailTemplateActionTypes.GET_VARIABLES_FAIL:
    return [];
  default:
    return state;
  }
};

export const isLoadedSelectedTemplate = (state = false, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_SUCCESS:
  case EmailTemplateActionTypes.GET_FAIL:
    return true;
  default:
    return state;
  }
};

export const associatedJob = (state = false, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_SUCCESS:
    return action.associatedJob;
  case EmailTemplateActionTypes.CHECK_HAS_ASSOCIATED_JOB_CLEAR:
    return initialModelForm.associatedJob;
  default:
    return state;
  }
};

export const associatedJobs = (state = [], action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_SUCCESS:
    return action.associatedJobs;
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_FAIL:
    return [];
  case EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS_SUCCESS:
    return state.filter(
      job => !action.stepIds.includes(job.jobSteps.id),
    );
  case EmailTemplateActionTypes.UNLINK_TEMPLATE_ASSOCIATED_JOBS_FAIL:
    return state;
  default:
    return state;
  }
};

export const totalAssociatedJobs = (state = 0, action) => {
  switch (action.type) {
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_SUCCESS:
    return action.totalAssociatedJobs;
  case EmailTemplateActionTypes.GET_ASSOCIATED_JOBS_FAIL:
    return 0;
  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  isLoading,
  searched,
  validation,
  emailTemplates,
  types,
  variables,
  isLoadedSelectedTemplate,
  associatedJob,
  associatedJobs,
  totalAssociatedJobs,
});
