import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card,
  FooterButtons,
  Icon,
  IconEnum,
  InputGroup,
  TextAreaWithChip,
} from '@gupy/design-system';
import { MainSelectList as SelectList } from '@gupy/front-commons';

import './JobCustomFormTemplateConditionalForm.scss';
import classNames from 'classnames';

const propTypes = {
  messages: PropTypes.object.isRequired,
  answerOptions: PropTypes.array.isRequired,
  fieldTypes: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isEditing: PropTypes.bool,
  editFormData: PropTypes.object,
  fieldIndex: PropTypes.number,
  fieldKey: PropTypes.string,
};

const JobCustomFormTemplateConditionalForm = ({
  messages,
  answerOptions,
  fieldTypes,
  onSave,
  onCancel,
  isEditing,
  editFormData,
  fieldIndex,
  fieldKey,
}) => {
  const [answerOptionSelected, setAnswerOptionSelected] = useState(isEditing ? editFormData.selectedOption : '');
  const [fieldName, setFieldName] = useState(isEditing ? editFormData.fieldName : '');
  const [fieldType, setFieldType] = useState(isEditing ? editFormData.fieldType.value : '');
  const oldSelectedOption = isEditing ? editFormData.selectedOption : '';

  let selectItems = [];
  if (isEditing) {
    selectItems = editFormData.selectedItems || [];
  }
  const [selectedItems, setSelectedItems] = useState(isEditing ? selectItems : []);

  const isSelect = fieldType === 'select' || fieldType === 'multiSelect';
  const isSelectedItemsEmpty = isSelect && selectedItems.filter(Boolean).length === 0;
  const disabledSave = !answerOptionSelected || !fieldName || !fieldType || isSelectedItemsEmpty;

  const formatAnswerOptions = () => answerOptions.map(
    option => ({ value: option, label: option }),
  );

  const allowedFieldTypes = fieldTypes.filter(type => type.value !== 'attachment' && type.value !== 'conditional');

  return (
    <Card id={'conditional-form'} className="conditional-form" >
      <h3 className="conditional-form__title">{ isEditing ? messages.editConditionalField : messages.addConditionalField}</h3>
      <SelectList
        id="conditional-answer-option"
        name="conditional-answer-option"
        className="conditional-form__field"
        label={<span className="conditional-form__label">{messages.ifTheAnswer}</span>}
        noItemsSelectedLabel={messages.selectAnOption}
        noResultsLabel={messages.noResultsLabel}
        filterLabel={messages.filterLabel}
        value={answerOptionSelected}
        onChange={e => setAnswerOptionSelected(e.target.value)}
        data={formatAnswerOptions()}
        responsive
        disableClearValue
      />
      <div className="conditional-form__field conditional-form__field--second">
        <InputGroup
          id="conditional-field-name"
          value={fieldName}
          label={<span className="conditional-form__label">{messages.jobCustomFormTemplateFieldName}</span>}
          onChange={e => setFieldName(e.target.value)}
          placeholder={messages.enterFieldName}
          block
        />
      </div>
      <SelectList
        id="conditional-field-type"
        name="conditional-field-type"
        className="conditional-form__field"
        label={<span className="conditional-form__label">{messages.fieldTypeTableHeader}</span>}
        noItemsSelectedLabel={messages.jobCustomFormTemplateSelectFieldType}
        noResultsLabel={messages.noResultsLabel}
        filterLabel={messages.filterLabel}
        value={fieldType}
        onChange={e => setFieldType(e.target.value)}
        data={allowedFieldTypes}
        responsive
        disableClearValue
      />
      {isSelect
      && (
        <div className={classNames(
          'conditional-form__field',
          'conditional-form__field--last',
          'job-custom-form-template-modal__textarea',
          { 'job-custom-form-template-modal__textarea--placeholder': isSelectedItemsEmpty },
        )}
        >
          <TextAreaWithChip
            id="conditional-select-items"
            label={messages.jobCustomFormTemplateFieldSelectItems}
            selectedOptions={selectedItems}
            handleSelectedOptions={value => setSelectedItems(value)}
            helperText={(
              <span className="job-custom-form-template-modal__textarea-helper">
                <Icon icon={IconEnum.InfoOutlined} fontSize="small" />
                <span className="job-custom-form-template-modal__textarea-helper-text">
                  {messages.jobCustomFormTemplateFieldSelectItemsInfo}
                </span>
              </span>
            )}
            required
            placeholder={messages.jobCustomFormTemplateFieldSelectItemsPlaceholder}
          />
        </div>
      )
      }
      <FooterButtons
        buttons={[
          {
            id: 'cancel-conditional-field',
            onClick: () => onCancel(),
            type: 'text',
            text: messages.confirmDeleteCancelButton,
          },
          {
            onClick: () => onSave({
              selectedOption: answerOptionSelected,
              fieldName,
              fieldType,
              selectedItems,
              fieldIndex,
              fieldKey,
              oldSelectedOption,
            }),
            type: isEditing ? 'outlined' : '',
            id: 'save-conditional-field',
            text: isEditing ? messages.saveConditionalUpdates : messages.addField,
            disabled: disabledSave,
          },
        ]}
      />
    </Card>
  );
};

JobCustomFormTemplateConditionalForm.propTypes = propTypes;

export default JobCustomFormTemplateConditionalForm;
