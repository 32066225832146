import JobWorkplaceActionTypes from '../../constants/Job/JobWorkplaceActionTypes';
import JobPermissionsManager from '../../managers/JobPermissionsManager';

export const changeForm = modelForm => ({
  type: JobWorkplaceActionTypes.CHANGE_FORM,
  modelForm,
});

export const clearForm = () => ({
  type: JobWorkplaceActionTypes.CLEAR_FORM,
});

export const postJobWorkplace = (payload, jobPath) => ({
  type: JobWorkplaceActionTypes.POST,
  payload,
  jobPath,
});

export const patchJobWorkplace = (payload, jobPath) => ({
  type: JobWorkplaceActionTypes.PATCH,
  payload,
  jobPath,
});

export const getJobWorkplace = id => ({
  type: JobWorkplaceActionTypes.INIT,
  id,
});

export const getJobDetailsSuccess = (subsidiaries, job) => ({
  type: JobWorkplaceActionTypes.INIT_SUCCESS,
  subsidiaries,
  modelForm: JobPermissionsManager.adaptJobPermissions(job),
});

export const getCompanyBranchesLeaves = search => ({
  type: JobWorkplaceActionTypes.GET_COMPANY_BRANCHES_LEAVES,
  search,
});

export const getCompanyBranchesLeavesSuccess = suggestions => ({
  type: JobWorkplaceActionTypes.GET_COMPANY_BRANCHES_LEAVES_SUCCESS,
  companyBranchSuggestions: suggestions,
});

export const clearCompanyBranchOnChange = companyBranchId => ({
  type: JobWorkplaceActionTypes.CLEAR_COMPANY_BRANCH_ON_CHANGE,
  companyBranchId,
});

export const onCompanyBranchBlur = companyBranchId => ({
  type: JobWorkplaceActionTypes.ON_COMPANY_BRANCH_BLUR,
  companyBranchId,
});
