import { ApiClient } from '@gupy/api-client';

const inboundClient = new ApiClient({
  signInUrl: process.env.REACT_APP_SIGNIN_URL || 'http://gupy.test.gupy.io:3000/companies/signin',
  tokenHeaderParam: 'company_key_auth',
  tokenCookieName: process.env.REACT_APP_AUTH_TOKEN_NAME || 'company_token',
  urlBase: process.env.REACT_APP_INBOUND_API_URL || '',
});

export {
  inboundClient,
};
