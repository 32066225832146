import { call, put, takeLatest } from 'redux-saga/effects';

import { updateGlobalization } from '../../actions/App/AppActions';
import SetPasswordForm from '../../forms/Authentication/SetPasswordForm';
import SigninForm from '../../forms/Authentication/SigninForm';
import CookieManager from '../../managers/CookieManager';
import LocalstorageManager from '../../managers/LocalstorageManager';
import AuthenticationService from '../../services/Authentication/AuthenticationService';
import SetPasswordService from '../../services/Authentication/ResetPasswordService';
import {
  realtimeConnect,
  realtimeDisconnect,
} from '../Realtime/RealtimeActions';
import UserProfileActionTypes from '../UserProfile/ActionTypes';
import {
  postSetPasswordSuccess,
  postSigninSuccess,
  postSignoutSuccess,
  reloadCurrentUserSuccess,
  signinWithCookieSuccess,
  signinWithSocialOrSamlSuccess,
} from './Actions';
import { SetPasswordActionTypes, SigninActionTypes } from './ActionTypes';
import * as TermsAndPrivacyService from '../TermsAndPrivacy/TermsAndPrivacyService';
import { getShowNewTermsAndPrivacy } from '../TermsAndPrivacy/TermsAndPrivacyUpdateNotice/TermsAndPrivacyUpdateNoticeActions';
import * as RealtimeActionTypes from '../Realtime/RealtimeActionTypes';
import * as RealtimeEvents from './RealtimesEvents';
import { getSubdomain } from '../../helpers/subdomainHelper';

const SigninErrorsEnum = {
  invalidGrecaptchaScoreError: 'invalid_grecaptcha_score_error',
  invalidEmailOrPassword: 'invalid_email_or_password',
};

function formatError(error, message) {
  return {
    ...error,
    body: {
      ...error.body,
      message,
    },
  };
}

function setUserSubdomainCookie() {
  const subdomain = getSubdomain();
  const cookieManager = new CookieManager();
  cookieManager.setTopLevelUserSubdomain(subdomain);
}

function* reloadCurrentUserFromService(successActionCreatorFn) {
  const currentUserResponse = yield AuthenticationService.getCurrentUser();
  const currentUser = currentUserResponse.body;

  yield put(successActionCreatorFn(currentUser));
  yield put(
    updateGlobalization(currentUser.company.language, currentUser.language),
  );
  yield put(getShowNewTermsAndPrivacy());
  setUserSubdomainCookie();
}

function* initSignin(action) {
  try {
    const { subdomain } = action;

    const companySummaryResponse = yield AuthenticationService.getCompanySummary(
      subdomain,
    );

    yield put({
      type: SigninActionTypes.INIT_SIGNIN_SUCCESS,
      companySummary: companySummaryResponse.body,
    });
    yield put(updateGlobalization(companySummaryResponse.body.language));
  } catch (error) {
    yield put({
      type: SigninActionTypes.INIT_SIGNIN_FAIL,
      error,
    });
  }
}

function* postSignin(action) {
  const { payload, messages } = action;
  const form = new SigninForm();

  form.populate(payload);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: SigninActionTypes.POST_VALIDATION_FAIL,
      validation,
    });
  } else {
    try {
      const signinResponse = yield AuthenticationService.postSignin(
        action.payload,
      );

      const { token } = signinResponse.body;

      const localstorageManager = new LocalstorageManager(window.localStorage);

      localstorageManager.setCompanyToken(token);

      yield reloadCurrentUserFromService(postSigninSuccess);
      yield put(realtimeConnect());
    } catch (error) {
      if (error.body.message === SigninErrorsEnum.invalidGrecaptchaScoreError) {
        yield put({
          type: SigninActionTypes.POST_FAIL,
          validation: form.validateErrorResponse(
            formatError(error, messages.invalidGrecaptchaScoreLowError),
          ),
        });
      } else if (
        error.body.message === SigninErrorsEnum.invalidEmailOrPassword
      ) {
        yield put({
          type: SigninActionTypes.POST_FAIL,
          validation: form.validateErrorResponse(
            formatError(error, messages.invalidEmailOrPassword),
          ),
        });
      } else {
        yield put({
          type: SigninActionTypes.POST_FAIL,
          validation: form.validateErrorResponse(error),
        });
      }
    }
  }
}

function* postSigninWithCookie() {
  try {
    yield reloadCurrentUserFromService(signinWithCookieSuccess);
    yield put(realtimeConnect());
  } catch (error) {
    yield put({
      type: SigninActionTypes.SIGNIN_WITH_COOKIE_FAIL,
      error,
    });
  }
}

function* postSignInWithSocialOrSaml({ token }) {
  const localstorageManager = new LocalstorageManager(window.localStorage);

  localstorageManager.setCompanyToken(token);

  try {
    yield reloadCurrentUserFromService(signinWithSocialOrSamlSuccess);
  } catch (error) {
    yield put({
      type: SigninActionTypes.SIGNIN_WITH_SOCIAL_OR_SAML_FAIL,
      error,
    });
  }
}

function* postSignout({ payload }) {
  const { subdomain } = payload;

  yield put(realtimeDisconnect());
  try {
    const { body } = yield AuthenticationService.postSignout({ subdomain });

    if (body && body.context) {
      window.location = body.context;
    }
    yield put(postSignoutSuccess());
  } catch (error) {
    yield put({
      type: SigninActionTypes.SIGNOUT_FAIL,
      error,
    });
  } finally {
    const cookies = new CookieManager();
    cookies.clearAllCookies();

    const localStorage = new LocalstorageManager(window.localStorage);
    localStorage.clearAllLocalstorage();
  }
}

function* getReloadCurrentUser() {
  try {
    yield reloadCurrentUserFromService(reloadCurrentUserSuccess);
  } catch (error) {
    yield put({
      type: SigninActionTypes.RELOAD_CURRENT_USER_FAIL,
      error,
    });
  }
}

function* createSamlLoginRequest({ payload }) {
  const { subdomain, secondProvider } = payload;
  try {
    const {
      body: request,
    } = yield AuthenticationService.createSamlLoginRequest(subdomain, secondProvider);

    yield put({
      type: SigninActionTypes.CREATE_SAML_LOGIN_REQUEST_SUCCESS,
      request,
    });
  } catch (error) {
    yield put({
      type: SigninActionTypes.CREATE_SAML_LOGIN_REQUEST_FAIL,
      error,
    });
  }
}

function* initCompanyLanguage(action) {
  const { subdomain } = action;

  try {
    const response = yield AuthenticationService.getCompanySummary(subdomain);
    yield put({
      type: SetPasswordActionTypes.INIT_COMPANY_LANGUAGE_SUCCESS,
    });
    yield put(updateGlobalization('pt', response.body.language));
  } catch (error) {
    yield put({
      type: SetPasswordActionTypes.INIT_COMPANY_LANGUAGE_FAIL,
    });
  }
}

function* validateToken(action) {
  try {
    const response = yield SetPasswordService.validateToken(
      action.token,
      action.userId,
    );
    yield put({
      type: SetPasswordActionTypes.VALIDATE_TOKEN_SUCCESS,
      isTokenValid: response.body.valid,
    });
  } catch (error) {
    yield put({
      type: SetPasswordActionTypes.VALIDATE_TOKEN_FAIL,
    });
  }
}

export function* post(action) {
  const { modelForm, messages } = action;
  const form = new SetPasswordForm(modelForm);

  form.populate(modelForm);

  const validation = form.validateModel();

  if (validation) {
    yield put({
      type: SetPasswordActionTypes.POST_FAIL,
      validation,
    });
  } else if (modelForm.newPassword !== modelForm.newPasswordConfirmation) {
    yield put({
      type: SetPasswordActionTypes.POST_FAIL,
      validation: {
        newPasswordConfirmation: {
          message: messages.passwordDoNotMatch,
          type: 'error',
        },
      },
    });
  } else {
    try {
      const data = yield SetPasswordService.postReset({
        ...modelForm,
        confirmNewPassword: modelForm.newPasswordConfirmation,
      });
      const { token } = data.body;
      const localstorageManager = new LocalstorageManager(window.localStorage);

      localstorageManager.setCompanyToken(token);

      const currentUserResponse = yield AuthenticationService.getCurrentUser();
      const currentUser = currentUserResponse.body;

      yield TermsAndPrivacyService.updatePrivacyConsent();
      yield TermsAndPrivacyService.updateTermsConsent();

      yield put(postSetPasswordSuccess(currentUser));
    } catch (error) {
      yield put({
        type: SetPasswordActionTypes.POST_FAIL,
        validation: form.validateErrorResponse(error),
      });
    }
  }
}

function* postReloadUser({ data }) {
  if (data.type === RealtimeEvents.RELOAD_PERMISSION_USER) {
    const currentUserResponse = yield AuthenticationService.getCurrentUser();
    const currentUser = currentUserResponse.body;
    yield put(postSigninSuccess(currentUser));
  }
}

function* getCompanyIdBySubdomain(action) {
  try {
    const { subdomain } = action;
    const response = yield AuthenticationService.getCompanyIdBySubdomain(
      subdomain,
    );
    yield put({
      type: SigninActionTypes.GET_COMPANY_ID_BY_SUBDOMAIN_SUCCESS,
      payload: response.body,
    });
  } catch (error) {
    yield put({
      type: SigninActionTypes.GET_COMPANY_ID_BY_SUBDOMAIN_FAIL,
      error,
    });
  }
}
function* keepOrChangeCompany(action) {
  const { keycloak } = action;
  try {
    const {
      data: { subdomain },
    } = yield AuthenticationService.getSubdomainByToken(keycloak.token);

    yield put({
      type: SigninActionTypes.KEEP_OR_CHANGE_COMPANY_SUCCESS,
      subdomain,
      email: keycloak.tokenParsed.email,
    });
  } catch (error) {
    if (keycloak) {
      keycloak.logout();
    }

    yield put({
      type: SigninActionTypes.KEEP_OR_CHANGE_COMPANY_FAIL,
      error,
    });
  }
}

export function* defineLastSignInAt() {
  try {
    yield call(AuthenticationService.defineLastSignInAt);
    yield put({
      type: SigninActionTypes.DEFINE_LAST_SIGN_IN_AT_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: SigninActionTypes.DEFINE_LAST_SIGN_IN_AT_FAIL,
    });
  }
}

function* exchangeToken(action) {
  const { subdomain, keycloak } = action;
  const localstorageManager = new LocalstorageManager(window.localStorage);
  const cookieManager = new CookieManager();

  try {
    const hasCompanyToken = localstorageManager.hasCompanyToken();
    let shouldExchangeToken = true;

    if (hasCompanyToken) {
      const currentUserResponse = yield AuthenticationService.getCurrentUserWithoutHandling();
      const currentUser = currentUserResponse.body;
      shouldExchangeToken = !currentUser;
    }

    if (shouldExchangeToken) {
      const {
        data: { token: companyToken, secondaryToken },
      } = yield AuthenticationService.exchangeToken(keycloak.token, subdomain);

      localstorageManager.setCompanyToken(companyToken);
      yield AuthenticationService.setSecondaryToken(secondaryToken);

      yield call(defineLastSignInAt);
    }

    yield put({
      type: SigninActionTypes.EXCHANGE_TOKEN_SUCCESS,
    });
  } catch (error) {
    if (error.message.includes('401')) {
      yield call(keepOrChangeCompany, { keycloak });
      return;
    }

    cookieManager.setExchangeTokenError('internal_server_error');

    if (keycloak) {
      keycloak.logout();
    }

    yield put({
      type: SigninActionTypes.EXCHANGE_TOKEN_FAIL,
      error,
    });
  }
}

function* postIDPSignout(action) {
  const { payload } = action;
  const { subdomain } = payload;

  try {
    yield AuthenticationService.postSignout({ subdomain });
    yield put({
      type: SigninActionTypes.IDP_SIGNOUT_SUCCESS,
    });
  } finally {
    const cookies = new CookieManager();
    cookies.clearAllCookies();

    const localStorage = new LocalstorageManager(window.localStorage);
    localStorage.clearCompanyToken();
  }
}

function* authenticationSaga() {
  yield takeLatest(SigninActionTypes.INIT_SIGNIN, initSignin);
  yield takeLatest(SigninActionTypes.POST, postSignin);
  yield takeLatest(SigninActionTypes.SIGNIN_WITH_COOKIE, postSigninWithCookie);
  yield takeLatest(
    SigninActionTypes.SIGNIN_WITH_SOCIAL_OR_SAML,
    postSignInWithSocialOrSaml,
  );
  yield takeLatest(SigninActionTypes.SIGNOUT, postSignout);
  yield takeLatest(
    SigninActionTypes.CREATE_SAML_LOGIN_REQUEST,
    createSamlLoginRequest,
  );
  yield takeLatest(
    SigninActionTypes.GET_COMPANY_ID_BY_SUBDOMAIN,
    getCompanyIdBySubdomain,
  );
  yield takeLatest(SigninActionTypes.EXCHANGE_TOKEN, exchangeToken);
  yield takeLatest(SetPasswordActionTypes.VALIDATE_TOKEN, validateToken);
  yield takeLatest(SetPasswordActionTypes.POST, post);
  yield takeLatest(
    SetPasswordActionTypes.INIT_COMPANY_LANGUAGE,
    initCompanyLanguage,
  );
  yield takeLatest(UserProfileActionTypes.PATCH_SUCCESS, getReloadCurrentUser);
  yield takeLatest(RealtimeActionTypes.REALTIME_DIGEST, postReloadUser);
  yield takeLatest(SigninActionTypes.IDP_SIGNOUT, postIDPSignout);
}

export default authenticationSaga;
