import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './SectionItemWithoutActiveClass.scss';

const propTypes = {
  children: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
};

const SectionItemWithoutActiveClass = ({
  children,
  link,
}) => (
  <NavLink
    to={link}
    className="section-item"
  >
    {children}
  </NavLink>
);

SectionItemWithoutActiveClass.propTypes = propTypes;

export default SectionItemWithoutActiveClass;
