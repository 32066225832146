export const adaptToGetSocialMedia = careerPagesData => careerPagesData.careerPages.map(
  careerPage => ({
    id: careerPage.careerPage && careerPage.careerPage.id,
    name: careerPage.careerPage && careerPage.careerPage.name,
    subdomain: careerPage.careerPage && careerPage.careerPage.subdomain,
    socialTitle: (careerPage.expand
      && careerPage.expand.sharingMetadata
      && careerPage.expand.sharingMetadata.title) || '',
    socialDescription: (careerPage.expand
      && careerPage.expand.sharingMetadata
      && careerPage.expand.sharingMetadata.description) || '',
    socialPictureUrl: (careerPage.expand
      && careerPage.expand.sharingMetadata
      && careerPage.expand.sharingMetadata.imageUrl) || '',
    socialPictureStoragePath: '',
  }),
);

export const adaptToPutSocialMedia = socialMedia => ({
  careerPageSharingMetadata: {
    title: socialMedia.socialTitle,
    description: socialMedia.socialDescription,
    imageUrl: socialMedia.socialPictureUrl,
  },
});
