import { communicationServiceClient as api } from '../../api/CommunicationServiceClient';

export default {
  async upsertIntegration(payload) {
    return api.post('/api/company-sender', payload);
  },
  async getIntegration(companyEmail) {
    return api.get(`/api/company-sender/${companyEmail}`);
  },
};
