import jobStatusEnum from '../../constants/Job/jobStatusEnum';

const isJobStatusValidForEdit = jobStatus => (
  jobStatus !== jobStatusEnum.published
  && jobStatus !== jobStatusEnum.frozen
  && jobStatus !== jobStatusEnum.closed
  && jobStatus !== jobStatusEnum.canceled
);

const isTemplateValidForEdit = jobStatus => [
  jobStatusEnum.published,
  jobStatusEnum.closed,
  jobStatusEnum.template,
  jobStatusEnum.canceled,
  jobStatusEnum.frozen,
].includes(jobStatus);

export {
  isJobStatusValidForEdit,
  isTemplateValidForEdit,
};
