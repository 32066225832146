import ProfileTestActionTypes from '../../constants/ProfileTest/ProfileTestActionTypes';

export const getProfileTestQuestions = () => ({
  type: ProfileTestActionTypes.GET,
});

export const topicGoNext = () => ({
  type: ProfileTestActionTypes.TOPIC_GO_NEXT,
});

export const goPrevious = () => ({
  type: ProfileTestActionTypes.GO_PREVIOUS,
});

export const changeAnswer = (currentStep, answerIndex, answerValue) => ({
  type: ProfileTestActionTypes.CHANGE_ANSWER,
  currentStep,
  answerIndex,
  answerValue,
});

export const saveQuestion = payload => ({
  type: ProfileTestActionTypes.SAVE_QUESTION,
  payload,
});

export const finishTest = () => ({
  type: ProfileTestActionTypes.FINISH_TEST,
});

export const closeProfileTest = ({ testProviderId, testCode, groupName, groupKey }) => ({
  type: ProfileTestActionTypes.CLOSE_TEST,
  payload: { testProviderId, testCode, groupName, groupKey },
});

export const getProfileTestAverage = payload => ({
  type: ProfileTestActionTypes.GET_AVERAGE,
  payload,
});

export const getCandidateMatching = payload => ({
  type: ProfileTestActionTypes.GET_MATCHING,
  payload,
});

export const getCompanyUserProfileTest = () => ({
  type: ProfileTestActionTypes.GET_USER_TEST,
});

export const getProfileTestDiagnostic = ({ groupKey, groupName }) => ({
  type: ProfileTestActionTypes.GET_DIAGNOSTIC,
  payload: { groupKey, groupName },
});

export const getProfileTestStatistics = () => ({
  type: ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS,
});
export const getProfileTestStatisticsSuccess = payload => ({
  type: ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS_SUCCESS,
  payload,
});
export const getProfileTestStatisticsFail = error => ({
  type: ProfileTestActionTypes.GET_PROFILE_TEST_STATISTICS_FAIL,
  error,
});

export const switchProfileTestCompatibilityStatus = enabled => ({
  type: ProfileTestActionTypes.SWITCH_COMPATIBILITY_STATUS,
  payload: { enabled },
});

export const changeGroup = ({ target: { value } }) => ({
  type: ProfileTestActionTypes.CHANGE_GROUP,
  payload: { group: value },
});

export const changeGroupItem = groupItem => ({
  type: ProfileTestActionTypes.CHANGE_GROUP_ITEM,
  payload: { groupItem },
});
