import React from 'react';
import PropTypes from 'prop-types';

import { Card, Grid } from '@gupy/design-system';
import { GridProps } from '@material-ui/core/Grid';

import './HomeGrid.scss';

const propTypes = {
  className: PropTypes.string,
  title: PropTypes.node.isRequired,
  children: PropTypes.any.isRequired,
  box: PropTypes.bool,
  lg: PropTypes.number,
  md: PropTypes.number,
  sm: PropTypes.number,
  xl: PropTypes.number,
  xs: PropTypes.number,
} && GridProps;

const HomeGrid = ({
  className,
  title,
  children,
  box,
  lg,
  md,
  sm,
  xl,
  xs,
  ...props
}) => (box
  ? (
    <Grid item lg={lg} md={md} sm={sm} xl={xl} xs={xs} className={['home-grid', className].join(' ')}>
      <div className="home-grid__title">
        {title}
      </div>
      <Card className="home-grid__card">
        {children}
      </Card>
    </Grid>
  )
  : <Grid lg={lg} md={md} sm={sm} xl={xl} xs={xs} className={className} {...props}>{children}</Grid>
);

HomeGrid.propTypes = propTypes;

export default HomeGrid;
