import { combineReducers } from 'redux';
import JobRatingCriteriasActionTypes from '../../../constants/Job/JobRatingCriterias/JobRatingCriteriasActionTypes';

import { isJobStatusValidForEdit } from '../../../containers/Job/JobHelper';

const initialModelForm = {
  id: undefined,
  jobId: undefined,
  name: '',
  type: 'LEGACY_CRITERIA',
  isMandatory: false,
};

export const suggestedSkills = (state = [], action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.SEARCH_SUCCESS:
    return action.suggestedSkills;
  case JobRatingCriteriasActionTypes.SEARCH_FAIL:
    return [];
  default:
    return state;
  }
};

export const ratingCriterias = (state = [], action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.INIT_SUCCESS:
    return action.ratingCriterias;
  case JobRatingCriteriasActionTypes.INIT_FAIL:
    return [];
  default:
    return state;
  }
};

export const isGaiaSkillsLoadingModalOpen = (state = false, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.OPEN_GAIA_SKILLS_LOADING_MODAL:
  case JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA:
    return true;
  case JobRatingCriteriasActionTypes.CLOSE_GAIA_SKILLS_LOADING_MODAL:
  case JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_SUCCESS:
  case JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_FAIL:
    return false;
  default:
    return state;
  }
};

export const isDeleteSkillsLoading = (state = false, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.DELETE:
    return true;
  case JobRatingCriteriasActionTypes.DELETE_SUCCESS:
  case JobRatingCriteriasActionTypes.DELETE_FAIL:
    return false;
  default:
    return state;
  }
};

export const isGenerateSkillsErrorModalOpen = (
  state = {
    isOpen: false,
    hasError: false,
  }, action,
) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.OPEN_GENERATE_SKILLS_ERROR_MODAL:
  case JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_SUCCESS:
    if (!action.skills.length) {
      return {
        isOpen: true,
        hasError: false,
      };
    }
    return {
      isOpen: false,
      hasError: false,
    };
  case JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_FAIL:
    return {
      isOpen: true,
      hasError: true,
    };
  case JobRatingCriteriasActionTypes.CLOSE_GENERATE_SKILLS_ERROR_MODAL:
    return {
      isOpen: false,
      hasError: false,
    };
  default:
    return state;
  }
};

export const previewType = (state = null, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.OPEN_TAXONOMY_SKILLS_PREVIEW_MODAL:
    return action.previewType;
  default:
    return state;
  }
};

export const job = (state = {}, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.INIT_SUCCESS:
    return action.job;
  case JobRatingCriteriasActionTypes.INIT_FAIL:
    return {};
  default:
    return state;
  }
};

export const isLoading = (state = false, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.INIT:
    return true;
  case JobRatingCriteriasActionTypes.INIT_SUCCESS:
  case JobRatingCriteriasActionTypes.INIT_FAIL:
    return false;
  default:
    return state;
  }
};

export const isLoadingAddJobRatingCriteria = (state = false, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.POST:
    return true;
  case JobRatingCriteriasActionTypes.INIT_SUCCESS:
  case JobRatingCriteriasActionTypes.INIT_FAIL:
    return false;
  default:
    return state;
  }
};

export const hasAddedNewSkillsResults = (state = false, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.POST_SUCCESS:
    return true;
  case JobRatingCriteriasActionTypes.GENERATE_SKILLS_FROM_GAIA_SUCCESS:
    if (action.skills.length) {
      return true;
    }
    return false;
  case JobRatingCriteriasActionTypes.CLOSE_GENERATE_SKILLS_SUCCESS_TOAST:
    return false;
  default:
    return state;
  }
};

export const modelForm = (state = initialModelForm, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.POST_SUCCESS:
  case JobRatingCriteriasActionTypes.PATCH_SUCCESS:
  case JobRatingCriteriasActionTypes.CLEAR_FORM:
    return initialModelForm;
  case JobRatingCriteriasActionTypes.CHANGE_FORM:
    return Object.assign(
      {},
      state,
      action.modelForm,
    );
  case JobRatingCriteriasActionTypes.EDIT:
    return Object.assign(
      {},
      initialModelForm,
      action.modelForm,
    );
  default:
    return state;
  }
};

const showForm = (state = true, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.INIT_SUCCESS:
    return isJobStatusValidForEdit(action.job.status);
  case JobRatingCriteriasActionTypes.EDIT:
  case JobRatingCriteriasActionTypes.PATCH_FAIL:
  case JobRatingCriteriasActionTypes.POST_SUCCESS:
  case JobRatingCriteriasActionTypes.POST_FAIL:
    return true;
  case JobRatingCriteriasActionTypes.PATCH_SUCCESS:
  case JobRatingCriteriasActionTypes.CLEAR_FORM:
    return false;
  default:
    return state;
  }
};

const isSaving = (state = false, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.POST:
  case JobRatingCriteriasActionTypes.PATCH:
    return true;
  case JobRatingCriteriasActionTypes.POST_SUCCESS:
  case JobRatingCriteriasActionTypes.POST_FAIL:
  case JobRatingCriteriasActionTypes.PATCH_SUCCESS:
  case JobRatingCriteriasActionTypes.PATCH_FAIL:
    return false;
  default:
    return state;
  }
};

const validation = (state = {}, action) => {
  switch (action.type) {
  case JobRatingCriteriasActionTypes.POST:
  case JobRatingCriteriasActionTypes.PATCH:
  case JobRatingCriteriasActionTypes.EDIT:
  case JobRatingCriteriasActionTypes.CLEAR_FORM:
  case JobRatingCriteriasActionTypes.GET:
    return {};
  case JobRatingCriteriasActionTypes.POST_FAIL:
  case JobRatingCriteriasActionTypes.PATCH_FAIL:
  case JobRatingCriteriasActionTypes.INIT_FAIL:
    return action.validation;
  default:
    return state;
  }
};

export default combineReducers({
  modelForm,
  isSaving,
  validation,
  ratingCriterias,
  showForm,
  job,
  isLoading,
  isGaiaSkillsLoadingModalOpen,
  isGenerateSkillsErrorModalOpen,
  previewType,
  suggestedSkills,
  isDeleteSkillsLoading,
  isLoadingAddJobRatingCriteria,
  hasAddedNewSkillsResults,
});
