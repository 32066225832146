import { GoogleAnalyticsHelper } from '@gupy/front-helpers';

/**
 * A higher-order function that sends a Google Analytics event and calls a callback function.
 *
 * @param {string} category - The category of the event.
 * @param {string} action - The action of the event.
 * @param {function} callback - The callback function to be called after sending the event.
 * @return {function} - A function that takes any number of arguments and sends the Google
 *                      Analytics event before calling the callback function.
 */
export const withSendGAEvent = (category, action, callback) => (...args) => {
  GoogleAnalyticsHelper.sendEvent({
    category,
    action,
  });

  callback(...args);
};
