import { put, takeLatest } from 'redux-saga/effects';

import * as types from './HiringCelebrationActionTypes';
import HiringCelebrationService from './HiringCelebrationService';
import { saveFeedbackRequestSuccess, saveFeedbackRequestFailure } from './HiringCelebrationActions';

function* saveFeedbackRequest({ context, feedbackType, answer, messages }) {
  const { candidates } = context;
  try {
    yield HiringCelebrationService.saveFeedbackRequest({
      data: {
        context, feedbackType, answer,
      },
    });
    yield put(saveFeedbackRequestSuccess({ messages, candidates }));
  } catch (error) {
    yield put(saveFeedbackRequestFailure());
  }
}

function* HiringCelebrationSagas() {
  yield takeLatest(types.SAVE_FEEDBACK_REQUEST, saveFeedbackRequest);
}

export default HiringCelebrationSagas;
