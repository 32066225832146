const rscChildAppStatusEnum = {
  READY_TO_CONNECT_WITH_LINKEDIN: 'READY_TO_CONNECT_WITH_LINKEDIN',
  ENABLING_LINKEDIN_FEATURES: 'ENABLING_LINKEDIN_FEATURES',
  READY_TO_SYNC_DATA: 'READY_TO_SYNC_DATA',
  SYNC_PENDING: 'SYNC_PENDING',
  SYNC_SUCCESS: 'SYNC_SUCCESS',
  SYNC_FAIL: 'SYNC_FAIL',
  DESYNC_PREPARING: 'DESYNC_PREPARING',
  DESYNC_PENDING: 'DESYNC_PENDING',
  DESYNC_SUCCESS: 'DESYNC_SUCCESS',
  DESYNC_FAIL: 'DESYNC_FAIL',
};

export default rscChildAppStatusEnum;
