import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BaseForm } from '@gupy/front-commons';

import AdditionalQuestionTypes from '../../../constants/Job/AdditionalQuestionTypes';

const emptyQuestionDescription = (
  <FormattedMessage
    id="additional_question_empty_description"
    defaultMessage="Preencha o campo de pergunta"
  />
);

const emptyAnswerOption = (
  <FormattedMessage
    id="additional_question_empty_option"
    defaultMessage="Preencha todas as opções de resposta"
  />
);

const noEliminatoryOption = (
  <FormattedMessage
    id="additional_question_no_eliminatory"
    defaultMessage="Selecione pelo menos uma resposta eliminatória"
  />
);

const onlyEliminatoryOptions = (
  <FormattedMessage
    id="additional_question_only_eliminatory"
    defaultMessage="Todas as respostas são eliminatórias"
  />
);

class JobAdditionalQuestionsForm extends BaseForm {
  constructor() {
    super({
      questionType: {
        validate: {
          notEmpty: true,
        },
      },
      description: {
        validate: {
          notEmpty: { msg: emptyQuestionDescription },
        },
      },
      required: {
        validate: {
          boolean: true,
        },
      },
      options: {
        validate: {
          even: (options, fields) => {
            const questionType = fields.questionType ? fields.questionType.value : null;
            const eliminatory = fields.eliminatory ? fields.eliminatory.value : false;
            const optionTypes = [
              AdditionalQuestionTypes.checkAnswer,
              AdditionalQuestionTypes.choiceAnswer,
            ];
            let hasEliminatoryOption = false;
            let allOptionIsEliminatory = true;

            if (questionType && optionTypes.indexOf(questionType) > -1) {
              if (options.length < 2) {
                return 'Adicione pelo menos 2 opções de resposta.';
              }

              for (let i = 0; i < options.length; i += 1) {
                if (!options[i].description || options[i].description.trim() === '') {
                  return emptyAnswerOption;
                }

                if (options[i].eliminatory) {
                  hasEliminatoryOption = true;
                } else {
                  allOptionIsEliminatory = false;
                }
              }

              if (eliminatory) {
                if (!hasEliminatoryOption) {
                  return noEliminatoryOption;
                }

                if (allOptionIsEliminatory) {
                  return onlyEliminatoryOptions;
                }
              }
            }

            return null;
          },
        },
      },
    });
  }
}

export default JobAdditionalQuestionsForm;
